 const datosA= {
  "10089533127787942": {
    "atributos": {
      "Correo": "jose_fuentes0710@hotmail.com",
      "Gestion_numero": "Necesito hablar con un asesor",
      "Gestion_problema": "Necesito informacion para renovar una empresa mercantil",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Jose fuentes",
      "Telefono": "56304702",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=10089533127787942&width=1024&ext=1697058176&hash=AeTIZxT0HB2U5f-TQWI",
      "fb_username": "José Fuentes",
      "fecha_ingreso": "2023-09-08 17:30:03.251861",
      "ultima_fecha": 1694466176
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "1314261675292478": {
    "atributos": {
      "Gestion_tipo": "Atienden mañana 14 agosto",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=1314261675292478&width=1024&ext=1694567046&hash=AeSCWLS9qwRvPFuUwsA",
      "fb_username": "Abner García",
      "fecha_ingreso": "2023-08-13 19:01:53.711691",
      "intentos": 1,
      "ultima_fecha": 1691975049
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "226518713872046": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/85215299_479381239411958_7755129104415850496_n.jpg?stp=dst-jpg_p200x200&_nc_cat=1&ccb=1-7&_nc_sid=e8c781&_nc_ohc=pbr7DW0pDg8AX88Jbsg&_nc_ht=scontent-ord5-2.xx&edm=ADjcXCEEAAAA&oh=00_AfDmO-dQS6A3d6m6IZ_ooJPANhaHw_oPAgP2sDqy7ZIw6A&oe=65CCD3D6",
      "fb_username": "Guest 3032",
      "fecha_ingreso": "2024-01-15 12:09:35.595003",
      "ultima_fecha": 1705342179
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "23875134618769301": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=23875134618769301&width=1024&ext=1694909575&hash=AeSEriqvqmzRW53aIBc",
      "fb_username": "Gina Echigoyen",
      "fecha_ingreso": "2023-08-17 18:12:14.953140",
      "ultima_fecha": 1692317577
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "23916265724638549": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=23916265724638549&width=1024&ext=1693628980&hash=AeS67pFbX2w0EKwo8B0",
      "fb_username": "Janeth BA",
      "fecha_ingreso": "2023-08-02 21:52:33.160878",
      "intentos": 0,
      "ultima_fecha": 1691036982
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "23929315313350936": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=23929315313350936&width=1024&ext=1699630296&hash=AeQ4K6FXnw2pgAUW1Ro",
      "fb_username": "Keilla López",
      "fecha_ingreso": "2023-10-11 09:29:21.852526",
      "ultima_fecha": 1697038300
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "23949600231305433": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=T1s2hGEaBOsAX90aNMM&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfDkv4J2xzBzdtwChCa9ARIvl3jnswkghZ9-axPijgK19A&oe=649ED5D9",
      "fb_username": "Walter Ajbal",
      "fecha_ingreso": "2023-05-31 08:32:18.422078",
      "ultima_fecha": 1685543543
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "23951481711166327": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHAj6WdCbGmyZBsMSG5DyU7LEb98eIASFR2VILIu_QzVZ4Rfty1yV0sF6-b5nRprY4l6L-_W0tn&psid=23951481711166327&width=1024&ext=1699810967&hash=AeSNVFoaBqOSUyyIJ1M",
      "fb_username": "Brian Morales",
      "fecha_ingreso": "2023-10-13 11:42:23.498166",
      "ultima_fecha": 1697218969
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "23967766046203819": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHHa9OP-CDnO5XfWvGPU57MjsWWhcquQeDxvcuvtG3qZVyZMUeIWXesrzUs-gfbamE--EnIN6rW&psid=23967766046203819&width=1024&ext=1701291010&hash=AeRkiRlg84ElbWn2fFI",
      "fb_username": "Analu Cabria",
      "fecha_ingreso": "2023-10-30 14:49:59.674372",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1698699012
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "24002662182681791": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHasP9XlJCBJFdzAeaQ99iWHJdqcFdDY89xkRF8PYFrX9n9swzzp8ondDDmzjW_yWQ2DWRd0-iv&psid=24002662182681791&width=1024&ext=1701386998&hash=AeTKgxZ5zrZLkJwl8nk",
      "fb_username": "Guisela Archila",
      "fecha_ingreso": "2023-10-31 17:29:55.063986",
      "ultima_fecha": 1698795002
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "24043790921886054": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "71337-2023",
      "Gestion_problema": "No lo han operado desde el 24 julio",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Maria Castañon",
      "Telefono": "56995693",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=24043790921886054&width=1024&ext=1693502055&hash=AeQ9uf9d77vxWDTRbvI",
      "fb_username": "Maria Castañon",
      "fecha_ingreso": "2023-07-17 08:18:25.340142",
      "ultima_fecha": 1690910055
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24057189127263261": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=24057189127263261&width=1024&ext=1689294324&hash=AeSOpXzeZ8k54xhVMXs",
      "fb_username": "Ls Fernanda",
      "fecha_ingreso": "2023-06-13 18:20:04.388374",
      "intentos": 0,
      "ultima_fecha": 1686702327
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "24074220325509760": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=24074220325509760&width=1024&ext=1697137136&hash=AeQyPg3UjhxIgLkFU6A",
      "fb_username": "Carolina Culajay D Pérez",
      "fecha_ingreso": "2023-09-12 12:35:42.865111",
      "ultima_fecha": 1694545139
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "24095999413380071": {
    "atributos": {
      "Gestion_numero": "Contestwn los telwfonos por favor, desde las 10:00am estoy llamando al pbx y al call center y no contestan",
      "Gestion_problema": "Tendra daño la linra??",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFKnxR8_YUyNj8mU9MSS9QbUda5rJfsl2-X0Q7NUSejPwUi7cuXUZaRUjgovte08lUxgRnO43Jb&psid=24095999413380071&width=1024&ext=1701891097&hash=AeR2INNJWy02Ss4M-js",
      "fb_username": "Jennifer Urizar",
      "fecha_ingreso": "2023-10-30 08:40:22.370381",
      "ultima_fecha": 1699299098
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "24117972367846074": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE2L_mpz-af7iMUPTBY6pQjBLf3AXsYI0pznOUmwvZ4Ig1It8jYI6BEQkb3CIg7UVKTJwxy9AOh&psid=24117972367846074&width=1024&ext=1703087792&hash=AeRY_CKleSoaiyUpgIg",
      "fb_username": "Giovanna Chajon",
      "fecha_ingreso": "2023-11-20 09:56:04.784278",
      "ultima_fecha": 1700495794
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "24134534319467004": {
    "atributos": {
      "Correo": "35680710",
      "Gestion_numero": "Necesito hablar con alguien",
      "Gestion_problema": "Necesito hablar con alguien",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "😒😒",
      "Telefono": "35680710",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHaR3qagx1z6mPYI6lGINdWPjtlDTLjMNjO3NviahmmnB5KY_5OGv1jQBDF4RL-l_t2fKCdEPmC&psid=24134534319467004&width=1024&ext=1703712174&hash=AeTt4Zqm6u0UROEpKX0",
      "fb_username": "Giss Giss",
      "fecha_ingreso": "2023-07-14 10:58:59.821889",
      "ultima_fecha": 1701120174
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24204774732515784": {
    "atributos": {
      "Correo": "Edithrodrialar@gmail.com",
      "Gestion_numero": "NÚMERO DE TRÁMITE: 32749792",
      "Gestion_problema": "Solo quiero saber si mañana estarán abiertos para ir a dejar la papelería",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Edith Rodríguez",
      "Telefono": "55780262",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHsDIY56pMUOis3whlhBU1rjLXeKUDyD_-e4wP5hZTublDWl3sVSBrb0od8fh1ZChEynXiaRSw5&psid=24204774732515784&width=1024&ext=1701288663&hash=AeSLaLqNvTP-P-irKIA",
      "fb_username": "Edith Rodriguez",
      "fecha_ingreso": "2023-03-08 09:17:20.035697",
      "intentos": 0,
      "ultima_fecha": 1698696663
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24259472617029879": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXECRYt1yPmP8RJAYXlcFiTVYDBfEzobw1TttaI-J0HfAMfH2BfUHN5mmxIqm_mChMIsRwikGlXU&psid=24259472617029879&width=1024&ext=1705202763&hash=Afo0_lqWakxoapNlSgGFpltEqCaAnvO9Rj0IYwDsrxpw6Q",
      "fb_username": "Saucedo Alonzo",
      "fecha_ingreso": "2023-12-14 21:24:29.047123",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1702610764
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "24293173616934639": {
    "atributos": {
      "Correo": "mdizela@yahoo.com",
      "Gestion_numero": "22954-1992",
      "Gestion_problema": "Rechazo por 2da vez y no dice porqué. Necesito escribirle o llamar a Marcelo Delgado",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Izela Mazariegos",
      "Telefono": "30672194",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFldgbYGlDZKTjlG8kMPKjGgeUx-DPy2Twd52MvGPwunJfs_y1lZlVZsZtFtVBLAm-jMF-HvWp1&psid=24293173616934639&width=1024&ext=1700151589&hash=AeSnfmHnu6ZaOQ8IMnY",
      "fb_username": "Rosa Izela Mazariegos Diaz",
      "fecha_ingreso": "2023-10-17 10:17:19.973968",
      "ultima_fecha": 1697559590
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24308743868771733": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHIp8atHh-Y28UF86zQKJ4FphgqTJJX5c3FS3lyVBERAzSqnsO2c_fU2OdyPVGCfJz4VqnoWHR3&psid=24308743868771733&width=1024&ext=1705080542&hash=AfqyuD3xXeQsFRM9A2aGSMr3RA2KLKzUbCQGP_78narw7Q",
      "fb_username": "Luis Orellana",
      "fecha_ingreso": "2023-12-13 11:29:00.416667"
    }
  },
  "24336498855948780": {
    "atributos": {
      "Correo": "leohernancaal@gmail.com",
      "Gestion_numero": "No teng9",
      "Gestion_problema": "Como recuperar el patente de comercio",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Gabriel chen",
      "Telefono": "58460317",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGqLotg3c5718sFFDZbONpPaVQqkqrvtvTjPw3X3az1rKWhkskx6_7PgMp-WlXM2nhj4pht-LK5&psid=24336498855948780&width=1024&ext=1700944430&hash=AeSR8IGH6fKj2mcH-JA",
      "fb_username": "RH Caal",
      "fecha_ingreso": "2023-10-26 14:30:01.775118",
      "intentos": 0,
      "ultima_fecha": 1698352430
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24348557671425533": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGn1mbleNNUlE-O4c10dCRutm-nodKWMB-wyo3mCHyIjDLZ5gz6-Qze0rg6XCYcgqY1WEli8Bxx&psid=24348557671425533&width=1024&ext=1704911119&hash=AfpjuNaiG1iUAc-M8e_WP0Oj4a6D3UPWbo8BUCB2N5o5NQ",
      "fb_username": "Herbert Tercero",
      "fecha_ingreso": "2023-12-11 12:24:55.594987",
      "ultima_fecha": 1702319123
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "24366648132933793": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF7TTHREzohtIjwm9N7kloDnYNH_vQAGgBzK5ZsWRt6YRZWYlyagpF3mJHrYCZgWU4BygSayXfO&psid=24366648132933793&width=1024&ext=1700064138&hash=AeRA5LM6DL5eCZ99eLI",
      "fb_username": "Alex Alonzo",
      "fecha_ingreso": "2023-10-16 10:01:14.689969",
      "ultima_fecha": 1697472139
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "24379018448378153": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEA1I4l9c60mSFPS9FXVyP2DCZOai21lE8O1f9Ud9UGZFE82K2Op6brJnWGQAi5E680bBnpdFiq&psid=24379018448378153&width=1024&ext=1701883653&hash=AeSldb7bolOgxrNEL4A",
      "fb_username": "Kalua Coate Alf",
      "fecha_ingreso": "2023-11-06 11:25:20.962413",
      "ultima_fecha": 1699291655
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "24389073884072963": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJORVPmEOylvJOLap72BZ-p-1wH2FLyfW_GYIOBH_hqN0JSGVaRpTclVUKs5yiV669ncCvDyH6&psid=24389073884072963&width=1024&ext=1705765805&hash=Afoly1e1FoxIDS3akWFpsrkKXc1DB5M3DNjHOLb4-4CRAg",
      "fb_username": "Amílcar Miranda",
      "fecha_ingreso": "2023-12-20 15:07:38.349502",
      "intentos": 1,
      "ultima_fecha": 1703173807
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "24403723345941234": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH6FcSy33FsxceYIfqy3_fqriaqziwfCnL8V23h7jRonFmn78EQke33gqkgq24e-PrLxdwNuli0&psid=24403723345941234&width=1024&ext=1704915043&hash=AfoaG1XPI5u99Du7mpijbD9r1YTc67uZcUJkDJhWprcgpA",
      "fb_username": "Damián Vásquez",
      "fecha_ingreso": "2023-12-11 13:30:25.121182",
      "ultima_fecha": 1702323046
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "24414628484818672": {
    "atributos": {
      "Correo": "yolandasuriano@gmail.com",
      "Gestion_numero": "NÚMERO DE TRÁMITE: 32770627\nNÚMERO DE SOLICITUD: 177330",
      "Gestion_problema": "Me lo rechazan porque dice que no tiene firma electrónica, y sí tiene firma electrónica, como siempre los he realizado anteriormente.",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Yolanda Suriano",
      "Telefono": "50586320",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEV4OTbdwMihz0I7Fs3gPb_MJhQJkq2wmt_ackayvgzB8CTBRQEuQ1SwHTpH6rm0LVG72jCM55-&psid=24414628484818672&width=1024&ext=1704660601&hash=AfqVp0jQfOwoJ2b_rY0X_hNN_NtLtRtgGFhxRik4mK8Vww",
      "fb_username": "Yolanda Suriano",
      "fecha_ingreso": "2023-12-06 09:38:04.653271",
      "ultima_fecha": 1702068607
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "24418390581107629": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXENs01036aZXp_TVBxT7Kp00rHiKSiX6AYSbcvKlLgylkh6nM-gClUiaE4_-yykqLDS0kgjAUiA&psid=24418390581107629&width=1024&ext=1702312876&hash=AeSmAyL5iWZq9MdvkbQ",
      "fb_username": "Ariel Saucedo",
      "fecha_ingreso": "2023-11-11 10:41:04.237875",
      "ultima_fecha": 1699720879
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "24420976444214134": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFdKE4WLiQMteAnJl6PodhnkrR86vfg8o9uINYn8zUgJozODmTs3Xz4FDVMQIbdrlQ8ctRdy-ZR&psid=24420976444214134&width=1024&ext=1708042068&hash=AfoweKJibI5zjY1aHI_q00i7EuGn249nRx4cVdIT9Nm30g",
      "fb_username": "Josue Aguilar",
      "fecha_ingreso": "2024-01-16 18:07:48.124352"
    }
  },
  "24466684902947137": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXElEKTz4-pWx0M9dgwVuLdzmXZnGcVz_4SHB0BlQNagmLrRZm_QQKLkeORaW5B1u5niAFNkMxvN&psid=24466684902947137&width=1024&ext=1707354454&hash=AfrvJbw8u8mONmCciEqkX0xv3GbelENWQ4QHa83g3ppIVA",
      "fb_username": "Franches Victoria",
      "fecha_ingreso": "2024-01-08 19:07:34.091282",
      "ultima_fecha": 1704762456
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "24497849266465316": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGSxMs_59VSi150MFFsNM1QsPgT4cSeVi8x3GUZKTcevAyYxDPFTyVX6FbkuRmfSK-mKG26f1sO&psid=24497849266465316&width=1024&ext=1702662286&hash=AeRmbHF7s2x2UBrXwrY",
      "fb_username": "Fjavier CF",
      "fecha_ingreso": "2023-11-15 11:43:54.412550",
      "ultima_fecha": 1700070288
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "24508291368784674": {
    "atributos": {
      "Correo": "marielacastro2711@gmail.com",
      "Gestion_numero": "Negativa de patente",
      "Gestion_problema": "El tramite es para SAT que opcion tengo que marcar ya que me desglosa varias de negativa",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Hugo Loy",
      "Telefono": "41769388",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHIvYBrlpGIm_WIH0Q7b0wLjYAfzLR2NV4B6890XqO0R6Q6ytVnly1m1kkjoDOgq1RKWBT8IQKj&psid=24508291368784674&width=1024&ext=1703450505&hash=AeSQtpfgvQdXPMYOUWQ",
      "fb_username": "Julia Castro",
      "fecha_ingreso": "2023-11-24 11:00:13.237495",
      "intentos": 0,
      "ultima_fecha": 1700858506
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24530855333196833": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF8tss5vZhLnVuro-aLZyPtpuSNWBziwklfHB3WJWV9OBMfb6gkykAq3ot6TKvm7rNgy6olVNMU&psid=24530855333196833&width=1024&ext=1705691864&hash=AfqUP5kk3ET6THOtC9xCQ5HDG92H6fQdRANQoOo934vG2Q",
      "fb_username": "Jorge Salazar",
      "fecha_ingreso": "2023-12-20 13:16:46.535340",
      "ultima_fecha": 1703099866
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "24538291275786549": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHRw3karb31iezpRrFMZGf7wL5_DT2MQYF_b6tAKKaatxSWXsGiJJ_7Yvmbhf_wkFqz-jv5PnQp&psid=24538291275786549&width=1024&ext=1706748242&hash=AfqflIvzMX1ZiDwvzpHJpWJtAKYqlYMzNJtRtlMtf-2jMg",
      "fb_username": "Noemi Ramos",
      "fecha_ingreso": "2024-01-01 18:43:48.057136",
      "ultima_fecha": 1704156245
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "24539870832294873": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHJAKQJGsLr4Ie89op2mWWcj18DtceUDlOfA6VN1IVOHn5IxaFtmeauoMyE9G15byegm3-Ipzy-&psid=24539870832294873&width=1024&ext=1707352308&hash=AfphgAIXMjeSbfBOJK5-XfGNY3QP7mcQhHWdnuMK9Gzv7Q",
      "fb_username": "Liss Carcamo",
      "fecha_ingreso": "2024-01-08 18:31:48.564109",
      "intentos": 1,
      "ultima_fecha": 1704760313
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "24596760039939777": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJS-zRMKPQiJVHsbaErluHeBqmYd_LQjCP2kketih0ENQ5zWkNNGB7kj6SJbBFTIj9Y54MUOF-&psid=24596760039939777&width=1024&ext=1708715441&hash=Afq_0o3Ye1nkUdxNoz6BcWA7jLcpuED9NOpRIL6CgoJTlA",
      "fb_username": "Cesar Rodolofo Yax",
      "fecha_ingreso": "2024-01-24 13:10:06.241750",
      "ultima_fecha": 1706123444
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "24635377656076130": {
    "atributos": {
      "Correo": "Angelafrac89@gmail.com",
      "Gestion_numero": "32774525",
      "Gestion_problema": "Patente reciente,puedo cambiar el nombre comercial sin pagar",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Rosmery morataya",
      "Telefono": "44708147",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGamoTmZWYDjGyY7me4qhkB0PMiPMHyKtrqAAQQhqCFVjlx8HJvYT9tir8MRh6iLBz_GEVGH8Xe&psid=24635377656076130&width=1024&ext=1705512173&hash=AfruAmK4hwL_CjZ2Ceg5kMB5iD9-6qJqef2GdSGsCM-ziA",
      "fb_username": "Angela Franco",
      "fecha_ingreso": "2023-12-18 11:13:09.467475",
      "intentos": 0,
      "ultima_fecha": 1702920174
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24647428351538031": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGRkzPDQ4PJrIuzTUpT-PzR_x4OpbVTVjWnOLsh6MK_2XhCujIBFqFzAtL4MNaf9lapzU5tC9l2&psid=24647428351538031&width=1024&ext=1704394171&hash=AfoVJZ8NhuA08fMfp839pK66j07g7O5oI65M8mQr1pMRqg",
      "fb_username": "López Elizabeth",
      "fecha_ingreso": "2023-12-05 12:47:15.851799",
      "ultima_fecha": 1701802175
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "24691873903745374": {
    "atributos": {
      "Gestion_tipo": "e-Portal",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=24691873903745374&width=1024&ext=1697039047&hash=AeQXAnuizJe53Dd2Vaw",
      "fb_username": "Karla Orellana",
      "fecha_ingreso": "2023-09-11 08:27:47.808235",
      "intentos": 0,
      "ultima_fecha": 1694447048
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "24709768672002853": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHaZlMUO4N2uV9W7eEGAIaAAuobzOl2ET4ImmKKSy6HIVCUQQqzF0vaqu4OoOrrXXGj2aB3CwJt&psid=24709768672002853&width=1024&ext=1708637246&hash=AfpIabEERjQcrAhSItYrZ8lM5jdN7VjXwzd_kp7tPC9NJg",
      "fb_username": "Pablo H. Del Cid",
      "fecha_ingreso": "2024-01-23 13:22:40.879560",
      "ultima_fecha": 1706045247
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "24758541200426496": {
    "atributos": {
      "Correo": "jaquelinetobar1829@gmail.com",
      "Gestion_numero": "1193",
      "Gestion_problema": "Ir a recoger la patente",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Jaqueline Tobar",
      "Telefono": "42096772",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXErNrTFXRJ2nEnFVYOkRiOis6ZcwLrwjTefcdQ8y-BtJWkDaHOqbhz2vD6WYzQG1STTcBRFffrB&psid=24758541200426496&width=1024&ext=1707931957&hash=AfqAc3QwZC-d8XiNK0jqyCZTWOr27_Qbp5jWd7iWNe962Q",
      "fb_username": "Jaquii Tobar",
      "fecha_ingreso": "2024-01-15 07:54:28.538043",
      "ultima_fecha": 1705339957
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24774351222178700": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFAFCGZzblB9cnOV-ypQyTTeZZlDQctJ1uZSSKC-ZqBQHFK8T4orRSzjOlF_vi7EYf0uUFLPRM7&psid=24774351222178700&width=1024&ext=1703183567&hash=AeSS7gdT6Qec0j4cr7Q",
      "fb_username": "Walter Diaz",
      "fecha_ingreso": "2023-11-21 12:29:02.605052",
      "ultima_fecha": 1700591568
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "24813547411626253": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFTDcjIoLTow4k6MuRq_2ksRa6HqXhqqiCtCrzDhEbclZOaGfMeXMzar0tT37ljYroYAk6ubsnx&psid=24813547411626253&width=1024&ext=1711843017&hash=AfrADS6I5W9UV3-kihQJMXWb5o8GYXfjV4j1Bu9oSYtEkA",
      "fb_username": "Daniel Hernandez",
      "fecha_ingreso": "2024-02-29 17:56:57.229262",
      "ultima_fecha": 1709251021
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "24833855012866527": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEIOLDsdAV4Lar5JEqncoFaUOVsakHqeCX2hCIDs18T69UuHNa0YKGOAorh58sRiWBFusUFG6Kd&psid=24833855012866527&width=1024&ext=1707397710&hash=AfoU2vqQX19zLlmRdPemKkVE_IGaFE4Tu-TDWoN5b9J-gg",
      "fb_username": "Karla Gmst",
      "fecha_ingreso": "2024-01-09 07:06:08.576447",
      "ultima_fecha": 1704805711
    },
    "user_posicion": {
      "bloque": "InscripcionSociedad",
      "posicion": 1
    }
  },
  "24849806391301471": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEGX3ed_ukfQFw-7eHahQs_diQT1OBgyC5yi5p70FLisKhtTW7zu5y0HDaFwypGgONopPgJ3oAS&psid=24849806391301471&width=1024&ext=1704987399&hash=AfpzHIkLT8uz2otzlNHWOiZtgyKHUS9Lw_rexqYJ6ICysQ",
      "fb_username": "Ernesto Pacheco",
      "fecha_ingreso": "2023-12-12 09:36:20.878012",
      "ultima_fecha": 1702395402
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "24857959690486469": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEzI-kGq0_FkpM8lqI_in3XzOpYbHz2_6pY0bzB0x-hBFJFHybQmncM6TbV7bGZm_hWy40g2tTw&psid=24857959690486469&width=1024&ext=1711598613&hash=AfpjnM5ZkUdmDe8bZuOCqy95g6lF7_cux0PpE6547fIJhQ",
      "fb_username": "Miriam Bamaca",
      "fecha_ingreso": "2024-02-26 10:06:53.973357",
      "ultima_fecha": 1709006615
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "24914480981470434": {
    "atributos": {
      "Correo": "No.",
      "Gestion_numero": "No tengo",
      "Gestion_problema": "Quiero saber si atenderán en horario normal",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Karla Córdova",
      "Telefono": "54144251",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGA_TduEjE3agHS5KskGIGvtt8reUNMTYFjbI3i8BEvbmfH4GqDRQtT6OC_7SmJrqJ_67rwNQPo&psid=24914480981470434&width=1024&ext=1705596975&hash=Afp9zP0RlbDscnR-EBiCMlwMhpRIu2FuI8OkmEAM4yzuRA",
      "fb_username": "Karla Cordova",
      "fecha_ingreso": "2023-12-19 09:50:35.521584",
      "intentos": 0,
      "ultima_fecha": 1703004975
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "24929949449983769": {
    "atributos": {
      "Correo": "abigailovalle23@outlook.es",
      "Gestion_numero": "32804309",
      "Gestion_problema": "Lleva 30 días ingresado y aun lo han movido de operador",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Abigail ovalle",
      "Telefono": "54716835",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFVr1yeaxXx3vmSMInDl9rzeBhIBN9KEa12v3CYWqpXA2yjte6nVEEWzg95kW-63CujNEJSbZsE&psid=24929949449983769&width=1024&ext=1713044048&hash=AfqG3qUNfnvgdQOESomynBCHbbQ38VfAK5ymDxEygAW-gQ",
      "fb_username": "Abby Ovalle",
      "fecha_chatcenter": 1709731583414,
      "fecha_ingreso": "2024-03-06 07:21:22.174534",
      "leido": false,
      "log_chatcenter": "-NsIwOMjK9vUViVGV8hD",
      "msg_noleido": 0,
      "ultima_fecha": 1710452048
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "24998080183116304": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG5-h1EiQ4OrgkjkwyBcw1bF8b6in8POcEEUxD-AgLrvDX1r0-2Yeokf2Lr1RpGNnthiG0wg553&psid=24998080183116304&width=1024&ext=1709153520&hash=Afpd-GagEKisDjLAWMVQoPpRoq3I5Je4AF8JqgVxFRGY3w",
      "fb_username": "Luis Pedro Recopachi",
      "fecha_ingreso": "2024-01-29 14:51:16.808487",
      "ultima_fecha": 1706561521
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "24998142786499567": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHEip6EXGGu-k2O4azSZCET_Kzrt4IXsglp_4z88onx0s69wIkRgj3YW9nPi-lA2HHuJZauyLcL&psid=24998142786499567&width=1024&ext=1712168777&hash=Afpt0F9Uvd1beIKwOI2dmGSL5FNYS_e6yKX4TiI26hTxSw",
      "fb_username": "Miguel Poz",
      "fecha_ingreso": "2024-03-04 12:25:09.123701",
      "ultima_fecha": 1709576781
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "25022978467348291": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFoZtm8lYY6Kc9p8sfiPokiWAbFISIoXprG6gpedtjF1Z_dAGSaWcIlwnzjLTk9gTYOkd1mwPl2&psid=25022978467348291&width=1024&ext=1713733046&hash=AfrVD0cSGGaBIBYwbc50oi3STuW-Nmztml370vbJP83vAA",
      "fb_username": "Henrry Omar Toroc",
      "fecha_ingreso": "2024-03-22 14:57:26.663044"
    }
  },
  "25029291070019642": {
    "atributos": {
      "Correo": "emilsah10@gmail.com",
      "Gestion_numero": "Número: 1001-200296",
      "Gestion_problema": "No he recibido respuesta sigue en 35% y el tramite lo realice desde el lunes",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Vilma Rosario de León Peruch",
      "Telefono": "55297142",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEqllpVY6WU-F5bxdwvmdti-xtUnLObRiTJUfPYfyLOTut_BKAYBXgB0oax5_qJsYq6WzUTr-wJ&psid=25029291070019642&width=1024&ext=1713044532&hash=AfqU3yjOqTw5N5-2cPUKvTLAHSLO6vOtJXR1VpzlsfAgdA",
      "fb_username": "Emilsa Pastor",
      "fecha_ingreso": "2024-03-14 15:39:33.125994",
      "ultima_fecha": 1710452532
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "25041057388875542": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEPViqkUEO2PuIWwqOfQFImABPaPxL0D9FME1OXF5k9Ltk__Pc5vCKvSeQWXSONEUCbfQ8Bh5DE&psid=25041057388875542&width=1024&ext=1713366027&hash=AfpDgDJgPvDJFEax_-UuZVEhbevz1govS6zLMNN5uIs14Q",
      "fb_username": "Licda Maria Virginia Gallardo Hernandez",
      "fecha_ingreso": "2024-03-18 09:00:13.269787",
      "ultima_fecha": 1710774030
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 2
    }
  },
  "25057835510526983": {
    "atributos": {
      "Correo": "vielmanmoralesg@yahoo.com.mx",
      "Gestion_numero": "030",
      "Gestion_problema": "Coloque mal mi correo electrónico para recibir certificación",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Rosa ruiz",
      "Telefono": "47531608",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGCBc3xCaGd_iCM_Kz6I2B8qsVgn_qFOSpRqmShna3ISEgP95T-26grWs7OmEdcObhpi1quSRA2&psid=25057835510526983&width=1024&ext=1712769044&hash=AfrZX0pmdrbQUEgDSPZsUXtDPyDgXs9igJZ9fqHCIzelhA",
      "fb_username": "Gustavo Adolfo Vielman Morales",
      "fecha_chatcenter": 1710174545522,
      "fecha_ingreso": "2024-03-11 10:24:00.753655",
      "leido": false,
      "log_chatcenter": "-NsiL9PkSFitp-kqygJu",
      "msg_noleido": 0,
      "ultima_fecha": 1710177045
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "25092430897037159": {
    "atributos": {
      "Gestion_numero": "Quisiera ver si me pueden proporcionar el número de teléfono de la central y el de Quetzaltenango porque los que aparecen en la página a ninguno contesta",
      "Gestion_problema": "Me pueden dar el número de teléfono talvez así me entienden",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHXAN1qSdMq3Gok7fkk3VWdf7rZ43XVqGm7RTfMMQJYvILm4i7GI0R0rzpybhCXjPO4a8iPiM6e&psid=25092430897037159&width=1024&ext=1712176060&hash=AfpP_PR8U7xDqVDQ87_BbdkBykxCvthvy5_LX9wL8cZ98A",
      "fb_username": "Rosina Lopz",
      "fecha_ingreso": "2024-03-04 14:20:27.995262",
      "intentos": 0,
      "ultima_fecha": 1709584061
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "25107317452192735": {
    "atributos": {
      "Correo": "rodriguezayalalesliemaria@gmail.com",
      "Gestion_numero": "54062024",
      "Gestion_problema": "Solicito hablar con William Benjamin Hernández",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Leslie Rodríguez",
      "Telefono": "50161898",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE4IiEOqtlgddTzdbAmcNZZy_YSSBHAW2AlzPoo44-xfN55_ws76AgBwa07C74zBQ2ztf7I0rIr&psid=25107317452192735&width=1024&ext=1708880626&hash=AfrkHFMzUC9_kez16QRadzxOeFZb2q_erbW1He8sKbrTgQ",
      "fb_username": "Les Rod",
      "fecha_chatcenter": 1706277901587,
      "fecha_ingreso": "2024-01-26 08:02:45.743918",
      "leido": false,
      "log_chatcenter": "-Np54dK4uAxT9BSyF3EL",
      "msg_noleido": 0,
      "ultima_fecha": 1706288626
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "25121077150871286": {
    "atributos": {
      "Correo": "Buenas tardes",
      "Gestion_numero": "Solo es una pregunta",
      "Gestion_problema": "Optar a una vacante",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Buenas tardes",
      "Telefono": "Buenas tardes",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFIC_s1po0EqSd0_5w9JfJT8MKPC5Ctmgw6O4OdziTL4JQh8lgAMxI_IKbjtcDCvFyqmk7srTri&psid=25121077150871286&width=1024&ext=1714846938&hash=AfqAYIkvbBFSQjx8IVx9EX9_95mj-CAwhS-fVGrdS7RjIg",
      "fb_username": "Rosalinda De Leon Balan",
      "fecha_ingreso": "2024-04-03 15:54:41.432104",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1712254941
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "25140703612239739": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF-xPLPvL-DzXuYZ2TXU0JF75l9lq7U6AJ1O8tCgnmOtHCFNcDKOmL6IXAUTL2uSwqu11KzFVK0&psid=25140703612239739&width=1024&ext=1714071987&hash=AfoJ6kTo46shW-dQJmws8604RsUXrZSQKiPyDKyKz7-xjw",
      "fb_username": "Priscila Elizabeth",
      "fecha_ingreso": "2024-03-26 13:04:02.047269",
      "ultima_fecha": 1711479988
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "25148998388032266": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFaylWg9zPbhHuDx59mkzz41Ixrn1zmgQus2HdB-p6B7CmhIBDFCo-HWp5p_3xgB_7poxVJ-2hn&psid=25148998388032266&width=1024&ext=1705187962&hash=Afr1O6DQA8-XPI2-9NMvfykBepSGR8mvpNw7A4n17AhMTA",
      "fb_username": "Brenda Roxana Diaz",
      "fecha_ingreso": "2023-12-14 17:15:02.473107",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1702595963
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "25166282352971396": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE3rtT7VzGiqXN95F9MwqaWq2jhjedfWQGz3-0UO-ITpcqNhIt4HtlHb0PBDkGJb4vo0w4KEEo4&psid=25166282352971396&width=1024&ext=1714170197&hash=AfpfrP4JVN4GYpl-l4LQS9mk7mNHq-P6qkWTpKdV7N83NQ",
      "fb_username": "Luffy Leonel",
      "fecha_ingreso": "2023-11-25 11:06:32.130596",
      "ultima_fecha": 1711578200
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "25221221920802452": {
    "atributos": {
      "Correo": "meraligalindo@gmail.com",
      "Gestion_numero": "Auxiliares de comercio",
      "Gestion_problema": "Equivocacion en el pago de la boleta",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Cindy Galindo",
      "Telefono": "42204122",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE4Mvrt7dfSbbrKIa3jUJE5ppP4DtY2442Q9jMCfeupWprH67H97sG_sWN8MwNZl888ralmGS9F&psid=25221221920802452&width=1024&ext=1709152800&hash=Afpd2nnNBNWVcoZVo0QJ-iaoAkVekzRzo_lNY8o7y2lkwQ",
      "fb_username": "Cindy Galindo",
      "fecha_chatcenter": 1706560380816,
      "fecha_ingreso": "2024-01-29 14:30:49.267005",
      "leido": false,
      "log_chatcenter": "-NpLvCywp6KXAkXbxgoj",
      "msg_noleido": 0,
      "ultima_fecha": 1706560800
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "25383331231265852": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHsInYuA43_gxuwjmN4srjPHuP6PZhdNnmbCo8s197X9wYMIJj950Xm7uMFgpeiDVgKvQzuA32B&psid=25383331231265852&width=1024&ext=1707349185&hash=AfqopRkNBmfrQKm08McjDXz59YOc0-USOtwKwV9c24faxw",
      "fb_username": "Ricardo Gramajo",
      "fecha_ingreso": "2024-01-08 17:35:54.658359",
      "intentos": 0,
      "ultima_fecha": 1704757187
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "25394002720184048": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJuofZX9TLDJwPtIfrCMn6h6UREtRqSpv_piEgKgLI741EvGe8H61cL4gmGA5VRxPNl9Pa0j6q&psid=25394002720184048&width=1024&ext=1713733389&hash=AfpKWpKJV785tScoc3zMyT2Q6yJcwzRkgHQPQs4c9dRymQ",
      "fb_username": "Dania María",
      "fecha_ingreso": "2024-03-22 15:02:46.784263",
      "ultima_fecha": 1711141378
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "25464407816506624": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJOMTPnE8B0Uvn2-bxltGHiHtsJSk-tQzlDtw89JFGLPCC_RPmc57J-9nwO5juoFVVj5sO2d-j&psid=25464407816506624&width=1024&ext=1714665715&hash=Afo5j36elkGR1JMFmJe8p78iUit9ZT2kb-5KR3nRNrTwZw",
      "fb_username": "Josué Emanuel Choy",
      "fecha_ingreso": "2024-04-01 16:12:52.366093",
      "ultima_fecha": 1712073716
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "25686246814299547": {
    "atributos": {
      "Correo": "Dentuquer@gmail.com",
      "Gestion_numero": "16397-2024",
      "Gestion_problema": "Buena tardes quisiera consultarles si en relación a la imagen adjunta ya no es necesario que se lleven los timbres de las patentes de sociedad y empresa para el sello que ponía el Registro Mercantil?",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Dennis Tuquer",
      "Telefono": "49386754",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFZiGifraurPknfooRakbSCYFMzm5_RqsGF_q75SacSbJs71crA6oqzOn7AhGl-ho4WR7bVhdD3&psid=25686246814299547&width=1024&ext=1711911547&hash=Afr6qRw3v09hXX_OkiLn8GpyO0Z8GcGASo0Zzq5YX3FPgg",
      "fb_username": "Dennis Tuquer",
      "fecha_ingreso": "2024-02-16 09:40:26.816557",
      "intentos": 0,
      "ultima_fecha": 1709319548
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "25689846700614671": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE-Ssj2bH2UKDfcRDzXrbASDDfqKvRh3gqVZwb7bYb3SNMTTgLmah_1BgIlBWUTbh2UK2kRlAPh&psid=25689846700614671&width=1024&ext=1711123546&hash=Afo-x_LkdWOs8wvmNoWee607_sIhFTt_eUrwWv-4KIKxPA",
      "fb_username": "Charly Alfa",
      "fecha_ingreso": "2024-02-21 09:57:57.353745",
      "intentos": 3,
      "ultima_fecha": 1708531549
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "4668526179939190": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=4668526179939190&width=1024&ext=1676127990&hash=AeTiCF9h0yjIxoZYmE0",
      "fb_username": "Andrea Sincuir",
      "fecha_ingreso": "2023-01-12 08:10:27.590205",
      "ultima_fecha": 1673532775
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "4720063778117445": {
    "atributos": {
      "Correo": "francisco.absoluto09@gmail.com",
      "Gestion_numero": "Necesito el sello en mis patentes",
      "Gestion_problema": "La secretaria de Peten no me atiende",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Francisco Javier López Duarte",
      "Telefono": "42266118",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=4720063778117445&width=1024&ext=1690134500&hash=AeQfsF0E8l_RJPhs328",
      "fb_username": "Francisco Duarte",
      "fecha_ingreso": "2023-06-23 11:46:29.744787",
      "intentos": 0,
      "ultima_fecha": 1687542500
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "4858268930964184": {
    "atributos": {
      "Gestion_tipo": "Pregunta",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=4858268930964184&width=1024&ext=1695915943&hash=AeS1R1AYGlxuCxnstKg",
      "fb_username": "Spor Line Toto",
      "fecha_ingreso": "2023-08-29 09:45:27.596568",
      "intentos": 0,
      "ultima_fecha": 1693323947
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "4942334392558402": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=4942334392558402&width=1024&ext=1685659972&hash=AeSigpgZmcVvF8Y-zjQ",
      "fb_username": "Cory Cardona",
      "fecha_ingreso": "2023-05-02 16:51:59.258785",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1683067973
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "5005951256136511": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5005951256136511&width=1024&ext=1682955463&hash=AeQNWw624IkFMl4AQAE",
      "fb_username": "Marco Lopez",
      "fecha_ingreso": "2023-02-17 13:55:57.655717",
      "ultima_fecha": 1680359660
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "5065766960193572": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5065766960193572&width=1024&ext=1674401499&hash=AeQAy6Pfxp_Ve799MIU",
      "fb_username": "Jefry L. López",
      "fecha_ingreso": "2022-12-23 08:36:01.205981",
      "ultima_fecha": 1671806428
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5079866195449548": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5079866195449548&width=1024&ext=1677434827&hash=AeRqEC4EtkqSahwesek",
      "fb_username": "Rene Contreras",
      "fecha_ingreso": "2023-01-27 11:09:35.592302",
      "ultima_fecha": 1674839489
    },
    "user_posicion": {
      "bloque": "Guias",
      "posicion": 1
    }
  },
  "5098240066966298": {
    "atributos": {
      "Correo": "vegaevelyn61@gmail.com",
      "Gestion_numero": "queja",
      "Gestion_problema": "llevo 1 hora con 30 minutos dentro de las instalaciones del Registro Mercantil esperando que me atiendan, me llamaron una vez y por no tener una fotocopia no me atendieron, fui a sacar fotocopias y bueno me dijeron que tengo que esperar a que me llamen de nuevo, muy bien, sin embargo veo que el mismo operador que me atendió a mí, atendió a otra persona y le dió oportunidad de ir a sacar fotocopias y ella olvido folder y le dió la oportunidad de ir a comprar folder, luego veo como el señor de la misma ventanilla se le acerca una persona y luego pide el número de esa persona y la atiende, no veo porque tenerme a mí esperando cuando pudo hacer una excepción como a la otra persona y pode sacar mi fotocopia, de verdad es una impotencia que lo traten de esa forma a uno, no entiendo como este nuevo sistema se da para hacer eso y sigo esperando ya que solo hay 3 ventanillas atendiendo y la persona de la ventanilla que me atendió a mí sigue llamando a personas que se le acercan y a mí no me puede llamar.",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Evelyn Vega",
      "Telefono": "57998472",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGEv9Denim3oWKmRvu6p1kxqUnRRuCj6WuIxRu7cIQUL_DFIOk58jV6ua4R4ArBsUPyInCVRDJ-&psid=5098240066966298&width=1024&ext=1701036376&hash=AeQPl3ReCaFzH4faKKI",
      "fb_username": "Evelyn Vega",
      "fecha_ingreso": "2023-10-27 12:46:30.166426",
      "ultima_fecha": 1698444377
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5125531107550000": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5125531107550000&width=1024&ext=1678466789&hash=AeQ0VMJMjf8Cpxj6RhY",
      "fb_username": "Arturo López",
      "fecha_ingreso": "2023-02-08 09:48:43.992247",
      "ultima_fecha": 1675871351
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5209497199150102": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5209497199150102&width=1024&ext=1677862052&hash=AeTbVGLy-LSBrxNHyJ0",
      "fb_username": "Erick Rodas",
      "fecha_ingreso": "2023-02-01 09:50:16.387348",
      "ultima_fecha": 1675266672
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5227091647390310": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5227091647390310&width=1024&ext=1676577483&hash=AeSOyMhbUjy00KiIMrU",
      "fb_username": "Verä Lucia Rivera-c",
      "fecha_ingreso": "2023-01-17 13:00:52.876917",
      "ultima_fecha": 1673982227
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "5228506073919610": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5228506073919610&width=1024&ext=1678911934&hash=AeRDs8n_TrkLXi3RpWM",
      "fb_username": "Ervin Mercar",
      "fecha_ingreso": "2023-02-13 13:26:49.025616",
      "ultima_fecha": 1676316451
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "5252228811544253": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5252228811544253&width=1024&ext=1676482380&hash=AeSpwjSQa5CMrKBJZMs",
      "fb_username": "Erick Palacios Chavez",
      "fecha_ingreso": "2023-01-16 10:37:47.623431",
      "ultima_fecha": 1673887134
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5255296874572422": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5255296874572422&width=1024&ext=1676749928&hash=AeSYHclZdLTpRmKeSEg",
      "fb_username": "Sheily Berrios",
      "fecha_ingreso": "2023-01-19 12:55:40.771225",
      "ultima_fecha": 1674154656
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5273739709396175": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5273739709396175&width=1024&ext=1684766709&hash=AeQ90t4liXhpbilEuzY",
      "fb_username": "Robert Morales",
      "fecha_ingreso": "2023-03-15 10:35:56.309797",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1682174716
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5304457033012401": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5304457033012401&width=1024&ext=1699197893&hash=AeStYOMSL2z0YXglfq4",
      "fb_username": "Ana Zamora",
      "fecha_ingreso": "2023-10-06 09:24:40.753431",
      "ultima_fecha": 1696605896
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5339280936174738": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5339280936174738&width=1024&ext=1684965421&hash=AeSzACvyK3RrDYetcQE",
      "fb_username": "Chejo O Mac",
      "fecha_ingreso": "2023-04-24 15:57:01.608902",
      "ultima_fecha": 1682373426
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5374050659385810": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEJezq5LROyY61HCZrQPkp5eXmzdRaw5NEw40wJauxGJT_8FhsblA8K8epRhWf4VpPWh6d64uJH&psid=5374050659385810&width=1024&ext=1707398680&hash=Afq_QH0I8333Ww65qCpCXyEW_EUIvs_AnGlTvRNelR_pQw",
      "fb_username": "Hector Carrillo",
      "fecha_ingreso": "2024-01-09 07:23:53.183804",
      "ultima_fecha": 1704806681
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "5388339507936684": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5388339507936684&width=1024&ext=1677175592&hash=AeTkwKWx2kvWB3ZeNgc",
      "fb_username": "Katy Nimatuj",
      "fecha_ingreso": "2023-01-24 11:04:43.521860",
      "ultima_fecha": 1674580279
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "5391696460930207": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5391696460930207&width=1024&ext=1681421318&hash=AeQu77HG6CAmWEPUUJo",
      "fb_username": "Tracy White",
      "fecha_ingreso": "2023-03-14 14:25:54.271588",
      "intentos": 1,
      "ultima_fecha": 1678825625
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "5411793032254047": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5411793032254047&width=1024&ext=1679865300&hash=AeSarNS8KwwmJWFc5M4",
      "fb_username": "Noel Morataya",
      "fecha_ingreso": "2023-02-24 13:59:52.057902",
      "intentos": 0,
      "ultima_fecha": 1677269724
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5412408855529667": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5412408855529667&width=1024&ext=1676823640&hash=AeQru01dBWa_M2y6jgc",
      "fb_username": "Francis De Ayala",
      "fecha_ingreso": "2023-01-20 09:23:39.454155",
      "ultima_fecha": 1674228363
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5436448153128334": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5436448153128334&width=1024&ext=1676571075&hash=AeQbdRXfiB9EwIeS2sE",
      "fb_username": "Linda Morales",
      "fecha_ingreso": "2022-12-30 12:26:50.531363",
      "ultima_fecha": 1673975820
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "5439136476211189": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5439136476211189&width=1024&ext=1699553100&hash=AeSu69xCsQmO-5A9IIw",
      "fb_username": "Josselin Chacon",
      "fecha_ingreso": "2023-10-10 12:00:55.697951",
      "intentos": 0,
      "ultima_fecha": 1696961101
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5465382223567835": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5465382223567835&width=1024&ext=1678306898&hash=AeS3a7eDuev9q8q02Bk",
      "fb_username": "Wendy Conóz",
      "fecha_ingreso": "2023-02-06 12:26:09.455201",
      "ultima_fecha": 1675711476
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "5503759816391071": {
    "atributos": {
      "fb_picture": "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=slcUwpaplZcAX_KQRjI&_nc_ht=scontent-dfw5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfDCPQ9_qZLz-1LCDkaNXcFR_MxhThpy9Ziv5pXF6GMykQ&oe=644BFF99",
      "fb_username": "Hylse Hocrab",
      "fecha_ingreso": "2023-03-29 13:56:38.121512",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680120165
    },
    "user_posicion": {
      "bloque": "ReposicionPatente",
      "posicion": 1
    }
  },
  "5510365489070130": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHgQU_RsB2iwN4Ya6Brewy6Z84faMiMuh96Y1BnoYezymCxbGgmvT2YXI5xEvO-kHvfv8Rjphha&psid=5510365489070130&width=1024&ext=1709231892&hash=AfrWCYzHVIUt7GIj6s8H17ndzXfeIYMVFE0Y0wXTvUmFCQ",
      "fb_username": "Danielita R. Vasquez Carrillo",
      "fecha_ingreso": "2023-03-08 13:33:21.532389",
      "ultima_fecha": 1706639895
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "5524266087664308": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5524266087664308&width=1024&ext=1674062274&hash=AeSchhSFfjijxSdf9JY",
      "fb_username": "Avi Dg",
      "fecha_ingreso": "2022-12-03 06:44:35.175167",
      "ultima_fecha": 1671470806
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5544032912365087": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5544032912365087&width=1024&ext=1687706119&hash=AeRIiRTI-55JylnD-1I",
      "fb_username": "Jossué Escobar",
      "fecha_ingreso": "2023-04-25 09:53:59.262627",
      "ultima_fecha": 1685114121
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "5545518592225002": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5545518592225002&width=1024&ext=1676393276&hash=AeSWzKbqrgeuhbnky_w",
      "fb_username": "Gloria Orellana",
      "fecha_ingreso": "2023-01-15 09:53:57.189521",
      "ultima_fecha": 1673798042
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5563086957127197": {
    "atributos": {
      "Correo": "Karina291228@gmail.com",
      "Gestion_numero": "32724584",
      "Gestion_problema": "Rechazo",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Gaby mazariegos",
      "Telefono": "43722703",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5563086957127197&width=1024&ext=1697310923&hash=AeS-gLRNSR1oi0mkG18",
      "fb_username": "Karina Mejia",
      "fecha_ingreso": "2023-06-30 09:50:49.993605",
      "intentos": 0,
      "ultima_fecha": 1694718924
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5575123199264344": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5575123199264344&width=1024&ext=1681053216&hash=AeQ9iXubr-6VUHVbhGQ",
      "fb_username": "Marie Pineda",
      "fecha_ingreso": "2023-01-11 14:18:54.281613",
      "intentos": 0,
      "ultima_fecha": 1678457550
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "5576364672467614": {
    "atributos": {
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5576364672467614&width=1024&ext=1682696688&hash=AeTgx0JY2aPJj6qX_bA",
      "fb_username": "Freddy Muñoz",
      "fecha_ingreso": "2023-03-29 08:21:55.745058",
      "intentos": 1,
      "ultima_fecha": 1680100901
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5577680525691744": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5577680525691744&width=1024&ext=1676694053&hash=AeQPAmakmrb3u_MlM10",
      "fb_username": "Alejandra Larios",
      "fecha_ingreso": "2023-01-18 21:11:36.693970",
      "ultima_fecha": 1674098786
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "5588587591252500": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGJ2KN8gTEDpCY1t8A221ESCc4qySCWo7S6dPvGbGu3dWDlyRduuc5oAnUHMEPI3N47D7uUnchF&psid=5588587591252500&width=1024&ext=1709231233&hash=AfrMBLEP-z4Vg00jDQlwsy8GCblQZ_HsZn5mRsSdQ2ZBQw",
      "fb_username": "Angel Gómez",
      "fecha_ingreso": "2023-01-04 11:53:31.009310",
      "intentos": 0,
      "ultima_fecha": 1706639234
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5590583977710803": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5590583977710803&width=1024&ext=1689438058&hash=AeSUUFJatYn95tSsgKY",
      "fb_username": "Maggi Pirir",
      "fecha_ingreso": "2023-06-15 10:17:10.751539",
      "intentos": 1,
      "ultima_fecha": 1686846067
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "5592202984225119": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5592202984225119&width=1024&ext=1679518451&hash=AeRFSP59RhKEKhnwjMY",
      "fb_username": "Cesar Geronimo",
      "fecha_ingreso": "2023-02-20 13:54:51.740804",
      "ultima_fecha": 1676922910
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5594361243995981": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5594361243995981&width=1024&ext=1676045071&hash=AeSxoa02wrnzDaNx8KQ",
      "fb_username": "Oscar Ramírez",
      "fecha_ingreso": "2023-01-11 09:11:01.465669",
      "ultima_fecha": 1673449865
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5595375717255066": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5595375717255066&width=1024&ext=1677099831&hash=AeTY8T_DAcrUlqwWbxE",
      "fb_username": "Zonia Cante",
      "fecha_ingreso": "2023-01-09 12:53:00.514078",
      "ultima_fecha": 1674504525
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "5598467036928788": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5598467036928788&width=1024&ext=1677870297&hash=AeSM9bJUxBM5NxMscwY",
      "fb_username": "Ceci Alfaro",
      "fecha_ingreso": "2023-02-01 11:16:14.026363",
      "ultima_fecha": 1675274918
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5602141803228315": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5602141803228315&width=1024&ext=1677264321&hash=AeSalhjZB6NaX3JmyRA",
      "fb_username": "Diana Morales",
      "fecha_ingreso": "2023-01-25 11:46:13.351510",
      "ultima_fecha": 1674669001
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5604299496347679": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5604299496347679&width=1024&ext=1678036149&hash=AeTGPEsIYCiagptrj5o",
      "fb_username": "Mscoso Alna",
      "fecha_ingreso": "2023-02-03 10:12:04.916172",
      "ultima_fecha": 1675440754
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5612557095515022": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5612557095515022&width=1024&ext=1677693234&hash=AeTTUYIJfPj8X150zR4",
      "fb_username": "Ramirzz Alexx",
      "fecha_ingreso": "2023-01-30 10:56:55.117293",
      "ultima_fecha": 1675097870
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "5637179606381640": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5637179606381640&width=1024&ext=1688769067&hash=AeRcAcCIv138p8eD55E",
      "fb_username": "Josue Daniel García",
      "fecha_ingreso": "2023-06-07 16:30:05.231570",
      "ultima_fecha": 1686177068
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5654328401319729": {
    "atributos": {
      "asesor_id": "GgTCycoVfSe0IHASf4V4Om5zCzl2",
      "asesor_name": "KSanti Torres",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5654328401319729&width=1024&ext=1672670991&hash=AeT5ewU_nNPrKxBrsHc",
      "fb_username": "Brynner Vargas",
      "fecha_ingreso": "2022-12-03 08:00:43.049212",
      "ultima_fecha": 1670079031213
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5655381461228995": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5655381461228995&width=1024&ext=1686346277&hash=AeR69Z_gbScfu_nm7gc",
      "fb_username": "Aura Revolorio",
      "fecha_ingreso": "2023-05-10 15:31:17.239451",
      "ultima_fecha": 1683754280
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "5655647284547395": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5655647284547395&width=1024&ext=1681424690&hash=AeSLiwmRzkaCMYnerD8",
      "fb_username": "Hermides Florian",
      "fecha_ingreso": "2023-03-14 15:16:38.723015",
      "intentos": 2,
      "ultima_fecha": 1678828997
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5656402747799061": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5656402747799061&width=1024&ext=1686244089&hash=AeR2XO_PefHequhHPKw",
      "fb_username": "Yue Castillo",
      "fecha_ingreso": "2023-05-09 11:07:13.489624",
      "ultima_fecha": 1683652092
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "5678201622288413": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5678201622288413&width=1024&ext=1695413087&hash=AeTgOdP1FDyYg0aV390",
      "fb_username": "Omar Seco Interiano",
      "fecha_ingreso": "2023-02-23 11:25:34.903879",
      "intentos": 0,
      "ultima_fecha": 1692821089
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5678217342268024": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5678217342268024&width=1024&ext=1673303497&hash=AeT6zqK1ipBYg4czguk",
      "fb_username": "Joel Rodríguez",
      "fecha_ingreso": "2022-12-10 15:41:56.855633",
      "ultima_fecha": 1670708520
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5679974678738415": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5679974678738415&width=1024&ext=1676583267&hash=AeRH2BEYwAg49aD7y3g",
      "fb_username": "Antonio Zahui",
      "fecha_ingreso": "2023-01-17 14:36:01.807890",
      "ultima_fecha": 1673988010
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "5687151864715569": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5687151864715569&width=1024&ext=1676560978&hash=AeSPQJr5zwxCOxIAGXQ",
      "fb_username": "Vivian López",
      "fecha_ingreso": "2023-01-17 08:22:07.456605",
      "ultima_fecha": 1673965726
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5689207597855419": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5689207597855419&width=1024&ext=1676229060&hash=AeTDNRfy_xzkTwtdH3o",
      "fb_username": "Paola Salinas",
      "fecha_ingreso": "2023-01-13 12:13:37.594481",
      "ultima_fecha": 1673633837
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5691174250983441": {
    "atributos": {
      "Gestion_numero": "Inicio",
      "Gestion_problema": "Regresar",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5691174250983441&width=1024&ext=1688666170&hash=AeRgRbCuGMqP2Zfxl7k",
      "fb_username": "Mejia Josa",
      "fecha_ingreso": "2023-06-06 11:29:52.953636",
      "ultima_fecha": 1686074171
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "5698031693645638": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5698031693645638&width=1024&ext=1676057727&hash=AeSLjQ7_PUU_JQ1PvSo",
      "fb_username": "Lucky Godinez",
      "fecha_ingreso": "2023-01-11 12:41:21.166795",
      "ultima_fecha": 1673462518
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5699194783520151": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5699194783520151&width=1024&ext=1683823089&hash=AeSCncoeftPFn4a0Jlw",
      "fb_username": "Edgar Velasquez",
      "fecha_ingreso": "2023-04-11 09:32:31.360576",
      "intentos": 0,
      "ultima_fecha": 1681227221
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5699353280162420": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHmIsDRCApTflVmI2_bhq7jTfSV33zLtgsN8XsF4sTYs4Ph2GlldLKkapWRKYpJGOUoZ80J8eQ0&psid=5699353280162420&width=1024&ext=1699805546&hash=AeTdnyCYSOg1cGxoPl4",
      "fb_username": "Abraham Segura",
      "fecha_ingreso": "2023-01-30 11:49:01.058547",
      "ultima_fecha": 1697213548
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5701441659958070": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5701441659958070&width=1024&ext=1686924877&hash=AeT8kkeZ2U3lHYGlAY4",
      "fb_username": "Claudia Gonzalez",
      "fecha_ingreso": "2023-05-17 08:12:24.511441",
      "ultima_fecha": 1684332883
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5706495962732037": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-5706495962732037",
      "fecha_ingreso": "2023-02-08 14:42:50.167708",
      "ultima_fecha": 1675107097
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5710988412338395": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5710988412338395&width=1024&ext=1678659684&hash=AeQnGJOXw521kazxla4",
      "fb_username": "David Smith",
      "fecha_ingreso": "2023-02-10 13:10:01.542796",
      "ultima_fecha": 1676064228
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5712477098810834": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5712477098810834&width=1024&ext=1675634293&hash=AeSwxtsp5UHirqccgqI",
      "fb_username": "Josseline Perez",
      "fecha_ingreso": "2023-01-06 14:57:43.365551",
      "ultima_fecha": 1673039121
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "5712687868851055": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5712687868851055&width=1024&ext=1677081190&hash=AeSAS-xEFqqIRyZ3lIs",
      "fb_username": "Elizabeth Del Cid",
      "fecha_ingreso": "2023-01-23 08:55:45.739846",
      "ultima_fecha": 1674485886
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5712904282140445": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5712904282140445&width=1024&ext=1674257256&hash=AeQfYwg9Lyb-2oCPo08",
      "fb_username": "Oscar Cardenas Murga",
      "fecha_ingreso": "2022-12-21 16:31:46.580437",
      "ultima_fecha": 1671662198
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5715863375190297": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5715863375190297&width=1024&ext=1679775838&hash=AeQuKlrH2sv78B9w-N0",
      "fb_username": "María Fernanda Luna",
      "fecha_ingreso": "2023-02-23 13:23:20.285763",
      "ultima_fecha": 1677180272
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "5716312835164013": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5716312835164013&width=1024&ext=1699547609&hash=AeRXVyPOiIHemzzZTfM",
      "fb_username": "David CM",
      "fecha_ingreso": "2023-03-01 08:58:02.336513",
      "intentos": 0,
      "ultima_fecha": 1696955613
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5716492795142902": {
    "atributos": {
      "Correo": "coinnbitss@gmail.com",
      "Gestion_numero": "BUEN DIA",
      "Gestion_problema": "no me deja descargar la patente en EPORTAL, solo visualiza 85% ya complete presentar solicitud y todo en ventanilla,",
      "Gestion_tipo": "Electrónico",
      "Nombre": "CRUZ XUJUR CHIC",
      "Telefono": "45673821",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGm4Lu4WyRztzSWdO3OAm2mHBZ0Eqa78jgqWVfQBocXhTh2_RCiBX-WOSgDgJVsMBzPgdXlOv6P&psid=5716492795142902&width=1024&ext=1699881384&hash=AeRrY9Xyw9_N28lU1Wk",
      "fb_username": "Dexter Xujur",
      "fecha_ingreso": "2023-02-03 12:14:02.897660",
      "ultima_fecha": 1697289384
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5717832721596492": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5717832721596492&width=1024&ext=1673203072&hash=AeQ_YTfDTy708uyP4e8",
      "fb_username": "Anne LeOn",
      "fecha_ingreso": "2022-12-09 11:43:57.423453",
      "ultima_fecha": 1670608100
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5719001621556001": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5719001621556001&width=1024&ext=1680277984&hash=AeT97zfaeJ2JSTW84zQ",
      "fb_username": "Aury L Arrazola",
      "fecha_ingreso": "2023-03-01 08:48:01.100486",
      "intentos": 0,
      "ultima_fecha": 1677682368
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5719035064867243": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5719035064867243&width=1024&ext=1688656686&hash=AeQ2VkEqaE-RoLgxtAo",
      "fb_username": "George Morales",
      "fecha_ingreso": "2023-06-06 09:17:41.009877",
      "ultima_fecha": 1686064689
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5721418307956449": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5721418307956449&width=1024&ext=1679607680&hash=AeQzGYq5-H511Yediis",
      "fb_username": "Brenda Martinez",
      "fecha_ingreso": "2023-02-16 14:30:39.258133",
      "ultima_fecha": 1677012129
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5723226777746708": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5723226777746708&width=1024&ext=1676614217&hash=AeSau9ozImQFvjPZIbk",
      "fb_username": "Marroquin Mar Míïssddeell",
      "fecha_ingreso": "2023-01-17 23:15:42.730527",
      "ultima_fecha": 1674018959
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5723850037650665": {
    "atributos": {
      "fb_picture": "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=hhmQGg9hNGYAX9tFd7Q&_nc_ht=scontent-dfw5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAgscJawfD53mzQSqmjsuU8v3zDwIt3VWqM8tH2BnhVgQ&oe=63CBAD99",
      "fb_username": "José David",
      "fecha_ingreso": "2022-12-22 05:22:58.714968",
      "ultima_fecha": 1671708254
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5724582074313518": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5724582074313518&width=1024&ext=1682777298&hash=AeR7RzdzNJA2ZIUjykE",
      "fb_username": "Mario Giron",
      "fecha_ingreso": "2023-03-23 11:02:33.180184",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680181507
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5732332223554227": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5732332223554227&width=1024&ext=1677346439&hash=AeRBWaEfF9XcWab2lgM",
      "fb_username": "Katty Moya",
      "fecha_ingreso": "2023-01-05 07:49:51.668762",
      "ultima_fecha": 1674751109
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5739614382832525": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5739614382832525&width=1024&ext=1680892461&hash=AeThDUSdnrdtZkJduZY",
      "fb_username": "Ana Beatriz Tello",
      "fecha_ingreso": "2023-03-08 11:33:09.484824",
      "intentos": 0,
      "ultima_fecha": 1678296804
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5740117782709859": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5740117782709859&width=1024&ext=1677086157&hash=AeS9PV-DdEf2KpFkmmY",
      "fb_username": "Emma Reyes",
      "fecha_ingreso": "2023-01-23 09:53:52.335004",
      "ultima_fecha": 1674490852
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5751603534932509": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "12345",
      "Gestion_problema": "prueba",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "avi dg",
      "Telefono": "12345678",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXENqf1KSVHtsoMYbY5Ry1dY1v1REQt5JFBzAHEMBJuP6CTEycE8tDCWJs1pn8FYy87gqUq-0Feq&psid=5751603534932509&width=1024&ext=1715279434&hash=AfpPhXLUTdPhEmHZwYhHhPDwlsO0c47Wy0uWU_5Q1E0xfQ",
      "fb_username": "Maggie Ann",
      "fecha_chatcenter": 1680024256265,
      "fecha_ingreso": "2022-12-19 16:01:33.877160",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-NRdTM5UFjHNZKscYcsh",
      "msg_noleido": 0,
      "ultima_fecha": 1712687437
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5752214418208289": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5752214418208289&width=1024&ext=1692479270&hash=AeTHwXXcNISkRid_oq0",
      "fb_username": "Wilson Barrios",
      "fecha_ingreso": "2023-02-07 11:45:48.227687",
      "ultima_fecha": 1689887271
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5754452964610500": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5754452964610500&width=1024&ext=1686349616&hash=AeSOJfthV4H_2KFKy6c",
      "fb_username": "Bombaket Arenales",
      "fecha_ingreso": "2023-01-10 19:24:14.821124",
      "intentos": 2,
      "ultima_fecha": 1683757621
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5754512144662164": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5754512144662164&width=1024&ext=1675971942&hash=AeTW_1BLCGJLN4Ie8go",
      "fb_username": "Giovanna Alviani",
      "fecha_ingreso": "2023-01-10 09:30:14.221988",
      "ultima_fecha": 1673376742
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5762913013803304": {
    "atributos": {
      "asesor_id": "GgTCycoVfSe0IHASf4V4Om5zCzl2",
      "asesor_name": "KSanti Torres",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5762913013803304&width=1024&ext=1682537597&hash=AeQedvRTuyZZfjyJocM",
      "fb_username": "Santi Torres",
      "fecha_ingreso": "2022-12-29 12:44:24.370563",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1679941825
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5763445400409331": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFODFyoIFZwIJFEbfyJkokp_Sw2FaubyojYUIutkq5HPCrWGy7EcIOairN-xnS-ngnLEfDPl_Vw&psid=5763445400409331&width=1024&ext=1708908023&hash=AfrSr-07ZjNIDyisrM5BborXYly9UWlzdgKZMIz4XdkZ1A",
      "fb_username": "Pedro Alcantara",
      "fecha_ingreso": "2023-01-12 12:39:42.260236",
      "intentos": 0,
      "ultima_fecha": 1706316024
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "5766523260135467": {
    "atributos": {
      "Correo": "jpccpp@gmail.com",
      "Gestion_numero": "18",
      "Gestion_problema": "No deja validar boleta de pago",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Juan Ramírez",
      "Telefono": "48485839",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5766523260135467&width=1024&ext=1693419775&hash=AeR6A5vmRGHZF3RuA08",
      "fb_username": "Juan Pablo Ramirez Melgar",
      "fecha_ingreso": "2023-01-11 09:40:53.901907",
      "ultima_fecha": 1690827775
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5779937038742102": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5779937038742102&width=1024&ext=1678639152&hash=AeSpSqQsUMRt_LTrnLw",
      "fb_username": "Juan Pablo R Asociados",
      "fecha_ingreso": "2023-02-10 09:39:26.809278",
      "ultima_fecha": 1676043695
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5780954788683893": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5780954788683893&width=1024&ext=1681396692&hash=AeSgyRtz1xO_0cotfP0",
      "fb_username": "Tc Walter",
      "fecha_ingreso": "2023-02-13 14:26:18.669573",
      "ultima_fecha": 1678800999
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5782028141924407": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5782028141924407&width=1024&ext=1680904711&hash=AeTJUXgEwgePi5exO_E",
      "fb_username": "Lis Godoy",
      "fecha_ingreso": "2023-03-08 14:57:30.976168",
      "intentos": 1,
      "ultima_fecha": 1678309056
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5789466117819811": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXETIexrydoawM_eClrzMwSkZ6mGTmVWW8AuMsTDuGqvzq48LzeB_ug28awdQtkaGh9QnciyH7P3&psid=5789466117819811&width=1024&ext=1707407287&hash=Afp0SDOAidJip9HqZmG8BiLrN_RRjNO3MdMzKG8YETffRg",
      "fb_username": "González Alexander",
      "fecha_ingreso": "2023-05-04 10:15:03.474250",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1704815290
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5790736701009020": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5790736701009020&width=1024&ext=1677783225&hash=AeR1_TP9KEkXB82Gmg0",
      "fb_username": "Otto Vasquez",
      "fecha_ingreso": "2023-01-31 11:57:20.288682",
      "ultima_fecha": 1675187853
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5795488860546852": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5795488860546852&width=1024&ext=1678568482&hash=AeTxxVq3WSU8KUFpsW8",
      "fb_username": "Eduardo Eduardo",
      "fecha_ingreso": "2023-02-09 14:03:30.143357",
      "ultima_fecha": 1675973033
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "5797725463598445": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5797725463598445&width=1024&ext=1676578635&hash=AeTaOpHrr4A3tG5sjwY",
      "fb_username": "Juanito Min Usac",
      "fecha_ingreso": "2023-01-17 13:22:29.940321",
      "ultima_fecha": 1673983380
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5800168833378838": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5800168833378838&width=1024&ext=1673203540&hash=AeSw9b4Bxn-L1stQ8f0",
      "fb_username": "Josue Orozco Rodriguez",
      "fecha_ingreso": "2022-12-09 11:55:44.061368",
      "ultima_fecha": 1670608569
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5800824186692978": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5800824186692978&width=1024&ext=1685113143&hash=AeQBGTi_zAzmLY2QLgc",
      "fb_username": "DiFran Salazar",
      "fecha_ingreso": "2023-04-26 08:58:49.210490",
      "ultima_fecha": 1682521146
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5802159466519365": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5802159466519365&width=1024&ext=1677950634&hash=AeSo9ld0_dfasYEMgJI",
      "fb_username": "Vivian Morales",
      "fecha_ingreso": "2023-02-02 10:12:24.164566",
      "ultima_fecha": 1675355247
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5802348336529903": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5802348336529903&width=1024&ext=1678392121&hash=AeQAQQKlICa-rRP0PbI",
      "fb_username": "Ricardo Morales",
      "fecha_ingreso": "2023-02-07 13:04:16.306400",
      "ultima_fecha": 1675796688
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "5803912903062789": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5803912903062789&width=1024&ext=1679243463&hash=AeR2plnUzPye2jkRWLQ",
      "fb_username": "Rebekita Pineda",
      "fecha_ingreso": "2023-02-16 07:12:53.755102",
      "ultima_fecha": 1676647948
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5806759416069734": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5806759416069734&width=1024&ext=1676049356&hash=AeSFJ9hEVLJQWVNbf2o",
      "fb_username": "Ninive Ovando",
      "fecha_ingreso": "2023-01-11 10:19:09.087687",
      "ultima_fecha": 1673454149
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "5809535632486437": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5809535632486437&width=1024&ext=1686264726&hash=AeSwo3zmZOT_Y4MIBXU",
      "fb_username": "Joyeria Fiorella",
      "fecha_ingreso": "2023-05-09 15:45:52.256541",
      "intentos": 0,
      "ultima_fecha": 1683672728
    },
    "user_posicion": {
      "bloque": "Sucursal_Inscripcion2A",
      "posicion": 1
    }
  },
  "5810311975732221": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5810311975732221&width=1024&ext=1677352218&hash=AeQGfWU3F7wstMjEDrI",
      "fb_username": "Eduardo Villatoro Power",
      "fecha_ingreso": "2023-01-26 12:12:18.828376",
      "ultima_fecha": 1674756886
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5811263302302948": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5811263302302948&width=1024&ext=1681665270&hash=AeTxupKv9S4MP_pWnLE",
      "fb_username": "Iuiu Castro",
      "fecha_ingreso": "2023-03-09 10:14:10.817853",
      "ultima_fecha": 1679069559
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "5813474042064790": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5813474042064790&width=1024&ext=1675807101&hash=AeSa_C0oFXEB4axpFwg",
      "fb_username": "Jenny Paredes",
      "fecha_ingreso": "2023-01-08 15:02:13.679725",
      "ultima_fecha": 1673211913
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5814401815303271": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5814401815303271&width=1024&ext=1677679299&hash=AeSA6RN2vh6PJepqlbI",
      "fb_username": "Luis Alberto Morales",
      "fecha_ingreso": "2023-01-30 06:59:05.588320",
      "ultima_fecha": 1675083936
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5815593341879912": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5815593341879912&width=1024&ext=1680893188&hash=AeQ92jhUbqk0qX034VQ",
      "fb_username": "Obed Socorec Scrs",
      "fecha_ingreso": "2023-03-08 09:11:18.816092",
      "intentos": 0,
      "ultima_fecha": 1678297530
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5815735785186342": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHMxtu0vUDaat_HSwYpW5wRjher8Nmw6xMRxMsHt-QMVCjjiHQt86LWycpg92Th546dmaSlA4bo&psid=5815735785186342&width=1024&ext=1702070649&hash=AeR5NPaP5tZ-lorxtlk",
      "fb_username": "Julio Villagran",
      "fecha_ingreso": "2023-01-27 06:34:31.841731",
      "ultima_fecha": 1699478650
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "5818401781584279": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5818401781584279&width=1024&ext=1676732125&hash=AeRSgKWtDOozM1rZDYk",
      "fb_username": "Mirna Aldana",
      "fecha_ingreso": "2023-01-19 08:00:07.873516",
      "ultima_fecha": 1674136855
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "5821659701264199": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5821659701264199&width=1024&ext=1678394423&hash=AeSBdw17BE_b3OPwYy8",
      "fb_username": "Dulce Gabrielita Juares Ortega",
      "fecha_ingreso": "2023-02-07 13:42:18.431746",
      "ultima_fecha": 1675798991
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5829746487124932": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5829746487124932&width=1024&ext=1693089426&hash=AeT8outiH-NJKStfjkk",
      "fb_username": "Elizabeth Ortega",
      "fecha_ingreso": "2023-07-27 16:34:07.713209",
      "intentos": 0,
      "ultima_fecha": 1690497427
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "5831019233665722": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5831019233665722&width=1024&ext=1690041981&hash=AeTf_SoYMFhHqRwcq7M",
      "fb_username": "Carlos Arturo Perito Contador",
      "fecha_ingreso": "2023-06-22 10:05:40.971297",
      "ultima_fecha": 1687449982
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "5834781956606552": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5834781956606552&width=1024&ext=1675536260&hash=AeQ2rh5L7oLd-46946w",
      "fb_username": "Mandy's Purple Hope",
      "fecha_ingreso": "2023-01-05 11:48:40.733749",
      "ultima_fecha": 1672941095
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5834844869927928": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5834844869927928&width=1024&ext=1677361440&hash=AeRxJKvwyIDLvPGgBhg",
      "fb_username": "Krmncita Najera",
      "fecha_ingreso": "2023-01-26 14:44:26.837976",
      "ultima_fecha": 1674766109
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5837959889663940": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5837959889663940&width=1024&ext=1677790602&hash=AeTiuHU4D2FlAjU0C_Y",
      "fb_username": "Edwin Portillo",
      "fecha_ingreso": "2023-01-31 14:00:01.588880",
      "ultima_fecha": 1675195229
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "5839888362726909": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5839888362726909&width=1024&ext=1676660048&hash=AeRZenVHoNogrZ_17aM",
      "fb_username": "René Bojórquez",
      "fecha_ingreso": "2023-01-18 11:56:35.093315",
      "ultima_fecha": 1674064785
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5840509279361381": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5840509279361381&width=1024&ext=1688563002&hash=AeRjyWlIVe6qXHZ7jZg",
      "fb_username": "Johana De López",
      "fecha_ingreso": "2023-01-11 13:07:32.225064",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1685971004
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "5840983696011031": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5840983696011031&width=1024&ext=1684951319&hash=AeSvOC8VFvmZFDeYBBQ",
      "fb_username": "Shayli Ochoa",
      "fecha_ingreso": "2023-04-22 00:28:12.685524",
      "intentos": 2,
      "ultima_fecha": 1682359324
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5844672902277115": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5844672902277115&width=1024&ext=1675045468&hash=AeRWXAscYeUm8pj5yHw",
      "fb_username": "Fernanda Corona",
      "fecha_ingreso": "2022-12-30 19:32:23.153977",
      "ultima_fecha": 1672450348
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5846099125444670": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5846099125444670&width=1024&ext=1693778718&hash=AeTfU08IJy93x6fk6ho",
      "fb_username": "Carlos Cap",
      "fecha_ingreso": "2023-02-13 11:14:02.829928",
      "intentos": 0,
      "ultima_fecha": 1691186721
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "5848925355155434": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHNgz5OFP3ycNN-0iPPhvP5gV9dMh70wB9I92XZ4_tImUOtAhF9HIlp_eFFcprw_jW_qYBP2m6r&psid=5848925355155434&width=1024&ext=1713391248&hash=AfqkDsix8IxbK_2Wa-LEXOUNIPngWQrWXPwHtEMdGzu41Q",
      "fb_username": "SuSy SoSa",
      "fecha_ingreso": "2023-11-23 08:29:03.088487",
      "ultima_fecha": 1710799249
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "5853077241408685": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5853077241408685&width=1024&ext=1679854087&hash=AeT5o4YPzDTdC9bXB6o",
      "fb_username": "Avi Dg",
      "fecha_ingreso": "2023-02-24 11:01:39.213176",
      "ultima_fecha": 1677258514
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5856224537831247": {
    "atributos": {
      "Correo": "lilianorozcogomez@hotmail.com",
      "Gestion_numero": "418034",
      "Gestion_problema": "Cancelación de patente",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Lilian Orozco",
      "Telefono": "58560251",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFheuCl7tEE6obqW9ZldwMGQKnuVC2adGiNGRZMEp3cNprl7vK1z7JYF_B_b3MppjTdrFXI0L1t&psid=5856224537831247&width=1024&ext=1711046436&hash=AfrapULd5v3sULWxY6hVpBjIjzp4zW6NuxN-ErNGqSKoDg",
      "fb_username": "Lily Orozco",
      "fecha_chatcenter": 1708451982575,
      "fecha_ingreso": "2023-03-13 09:25:21.285751",
      "leido": false,
      "log_chatcenter": "-Nr6f5on_w1notZWmmDG",
      "msg_noleido": 0,
      "ultima_fecha": 1708454436
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "5858159800970011": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5858159800970011&width=1024&ext=1687538462&hash=AeR5ZvYWzOSUSg_XGsk",
      "fb_username": "Allan Alvarez",
      "fecha_ingreso": "2023-02-13 08:58:11.953518",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1684946468
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5858765217557786": {
    "atributos": {
      "Correo": "alexanderaagarcia@gmail.com",
      "Gestion_numero": "32824983",
      "Gestion_problema": "Estado de trámite",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Abisaí Abrigo",
      "Telefono": "47586262",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEoMyL92o3Ec2UKZoBw0RrZkYSYTYSzrF1Xp5fiMB9TmPWrcvIslkmoH9LFfJruHNIduRYI012u&psid=5858765217557786&width=1024&ext=1712857074&hash=AfqfSps95Vjt1f8ySq4fuWfD8dE5Ynn9fswl_h2YGYSsSA",
      "fb_username": "Abisaí Abrigo",
      "fecha_chatcenter": 1710257348265,
      "fecha_ingreso": "2023-08-10 11:35:00.960037",
      "leido": false,
      "log_chatcenter": "-NsnH0vc3Jp7TL1OUzfX",
      "msg_noleido": 1,
      "ultima_fecha": 1710265074
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "5863005263767056": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5863005263767056&width=1024&ext=1691702598&hash=AeTV8DI_fv13rRwxzMM",
      "fb_username": "Gerson Mijangos",
      "fecha_ingreso": "2023-01-03 07:51:28.687304",
      "ultima_fecha": 1689110599
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "5863631163757821": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5863631163757821&width=1024&ext=1679514121&hash=AeROziBMYkQdDuqef38",
      "fb_username": "Karen Koy",
      "fecha_ingreso": "2023-02-20 12:40:47.392935",
      "ultima_fecha": 1676918579
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "5865353316881334": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-5865353316881334",
      "fecha_ingreso": "2023-11-08 10:33:18.493331",
      "intentos": 0,
      "ultima_fecha": 1699461201
    },
    "user_posicion": {
      "bloque": "CambioDireccionFiscalSociedad",
      "posicion": 1
    }
  },
  "5867570666693391": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5867570666693391&width=1024&ext=1680190212&hash=AeR6S9hcr077uJiWgeA",
      "fb_username": "Dulce Mejía",
      "fecha_ingreso": "2023-02-28 08:26:08.805871",
      "ultima_fecha": 1677594606
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5869139499870267": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5869139499870267&width=1024&ext=1678122026&hash=AeQ48B0T7U9eMMvr9qo",
      "fb_username": "Jennifer Rivas",
      "fecha_ingreso": "2023-02-04 10:00:50.799864",
      "ultima_fecha": 1675526622
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5874985839296719": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5874985839296719&width=1024&ext=1680797198&hash=AeRYQRLYvxiRCkGLw08",
      "fb_username": "Kareen Ml",
      "fecha_ingreso": "2023-03-07 09:03:42.932581",
      "ultima_fecha": 1678201546
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "5879906598783028": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5879906598783028&width=1024&ext=1677975693&hash=AeSw_zqzYwNdApo5F68",
      "fb_username": "Ajpu Cristina Conde",
      "fecha_ingreso": "2023-02-02 17:23:29.192829",
      "ultima_fecha": 1675380301
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5881500838572599": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5881500838572599&width=1024&ext=1676513370&hash=AeSa4ALJzYQId0zSgGg",
      "fb_username": "Viviaan Martinez",
      "fecha_ingreso": "2023-01-16 19:04:25.398202",
      "ultima_fecha": 1673918120
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5881748325285848": {
    "atributos": {
      "Gestion_tipo": "Pregunta",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5881748325285848&width=1024&ext=1688224390&hash=AeTEwF3HXPDQPKqlIrc",
      "fb_username": "Han-andre George CY",
      "fecha_ingreso": "2023-06-01 09:10:51.682318",
      "intentos": 0,
      "ultima_fecha": 1685632393
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5882807208453490": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5882807208453490&width=1024&ext=1678393493&hash=AeRT4Ghx64xATQKElvA",
      "fb_username": "Jorge Canel",
      "fecha_ingreso": "2023-02-07 13:26:29.060724",
      "ultima_fecha": 1675798061
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5883675558388024": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5883675558388024&width=1024&ext=1678382637&hash=AeTrz_yBj8i1RkG0Ztc",
      "fb_username": "Alex Barrera",
      "fecha_ingreso": "2023-02-07 10:26:32.905091",
      "ultima_fecha": 1675787205
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5883907328367982": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5883907328367982&width=1024&ext=1679176125&hash=AeQI8PO0vdMpPwG6K0s",
      "fb_username": "Stuardo D León",
      "fecha_ingreso": "2023-02-16 14:50:15.455266",
      "ultima_fecha": 1676580618
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5887238401370670": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5887238401370670&width=1024&ext=1679779582&hash=AeTB0oDqp-2kbdgM-HQ",
      "fb_username": "Pablo Toxcon",
      "fecha_ingreso": "2023-02-23 14:21:17.005654",
      "intentos": 0,
      "ultima_fecha": 1677184016
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5891851284234007": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5891851284234007&width=1024&ext=1679152482&hash=AeTNvirTYNYYasUO2NI",
      "fb_username": "Luis García Díaz",
      "fecha_ingreso": "2023-02-15 16:59:45.317018",
      "ultima_fecha": 1676556978
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5894957127260505": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5894957127260505&width=1024&ext=1679065087&hash=AeSQv7_Adnz0kGgV4Wc",
      "fb_username": "Desy Champet",
      "fecha_ingreso": "2023-02-15 07:58:06.130778",
      "ultima_fecha": 1676469592
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5895069570613847": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5895069570613847&width=1024&ext=1682618108&hash=AeSoaFcM7gIa5KnA8T0",
      "fb_username": "Brenda Caballeros",
      "fecha_ingreso": "2023-03-28 10:51:04.829567",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680022329
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5896401567114978": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5896401567114978&width=1024&ext=1681654844&hash=AeRvFb9RFZbtJpA3YAc",
      "fb_username": "Alexander Chamalé",
      "fecha_ingreso": "2023-02-24 09:51:50.509805",
      "intentos": 0,
      "ultima_fecha": 1679059134
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "5897606787004251": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5897606787004251&width=1024&ext=1684595508&hash=AeQ4xjpNHmKmZ0A9M-8",
      "fb_username": "Ana Baten",
      "fecha_ingreso": "2023-04-20 09:11:04.841957",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1682003511
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5898192970278049": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5898192970278049&width=1024&ext=1678289421&hash=AeRKd636kGZ9CcWg1mc",
      "fb_username": "Bayron Alfonso",
      "fecha_ingreso": "2023-02-06 08:32:08.995328",
      "ultima_fecha": 1675693999
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5901513363275833": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5901513363275833&width=1024&ext=1677775521&hash=AeR1e5eUpHC9KHczU6w",
      "fb_username": "Heidy Cocón",
      "fecha_ingreso": "2023-01-31 09:46:08.001409",
      "ultima_fecha": 1675180149
    },
    "user_posicion": {
      "bloque": "Guias",
      "posicion": 1
    }
  },
  "5902805923171499": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5902805923171499&width=1024&ext=1682008598&hash=AeTnHDYo1uyuQbmKLWU",
      "fb_username": "Ingrid Perez",
      "fecha_ingreso": "2023-03-21 09:29:11.601051",
      "intentos": 0,
      "ultima_fecha": 1679412862
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5903900383044542": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5903900383044542&width=1024&ext=1694703730&hash=AeRolES4CgiKrHPG0IA",
      "fb_username": "Fran Hernandez",
      "fecha_ingreso": "2023-08-15 09:00:01.928303",
      "ultima_fecha": 1692111732
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "5905230109568982": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=zZn668PpBQQAX9ngzTe&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfDpm7hjdqEpYVlotiyYt6a0rY9AIIsVeZVkM8BX3TexiQ&oe=640CEFD9",
      "fb_username": "Esmeralda Mairen",
      "fecha_ingreso": "2023-02-08 12:47:19.844253",
      "ultima_fecha": 1675984136
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "5905955076168132": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5905955076168132&width=1024&ext=1682804252&hash=AeTaRAPCOSmJjgqvDSs",
      "fb_username": "Montenegro Lulu",
      "fecha_ingreso": "2023-03-30 14:34:01.262489",
      "ultima_fecha": 1680208461
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5908158529303439": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5908158529303439&width=1024&ext=1697303121&hash=AeSCIx40xxdTNr_ZpTc",
      "fb_username": "Rony Ovalle Barrera",
      "fecha_ingreso": "2023-03-18 18:31:01.288798",
      "intentos": 0,
      "ultima_fecha": 1694711078
    },
    "user_posicion": {
      "bloque": "CambioNombreComercial",
      "posicion": 1
    }
  },
  "5913718145412138": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5913718145412138&width=1024&ext=1681363155&hash=AeRi8yYQI0dcHG6kA9I",
      "fb_username": "Sol Azuaje",
      "fecha_ingreso": "2023-03-13 22:17:43.135246",
      "intentos": 1,
      "ultima_fecha": 1678767470
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5917359341680711": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5917359341680711&width=1024&ext=1678565081&hash=AeTN84jwwu6nrNduoTs",
      "fb_username": "Alejandro Abal",
      "fecha_ingreso": "2023-02-09 13:03:43.218358",
      "ultima_fecha": 1675969631
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5917580398342453": {
    "atributos": {
      "Correo": "j.morenogarcia@hotmail.com",
      "Gestion_numero": "Cambio direccion y amplicacion del objeto de una empresa comercial",
      "Gestion_problema": "si se paga aracel por cambio",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Jose Moreno",
      "Telefono": "49362950",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5917580398342453&width=1024&ext=1689788689&hash=AeRYdU04rUNCil4knW8",
      "fb_username": "Luis Moreno",
      "fecha_ingreso": "2023-06-19 11:04:30.268333",
      "ultima_fecha": 1687196689
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5918587008242711": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5918587008242711&width=1024&ext=1692203590&hash=AeR204mXNtLVLzYb6ss",
      "fb_username": "Brayan Cuá",
      "fecha_ingreso": "2023-07-17 10:30:14.807122",
      "ultima_fecha": 1689611592
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5919851518090423": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5919851518090423&width=1024&ext=1680402554&hash=AeT7nzbL7j8YbWyJxwI",
      "fb_username": "María Eugenia González",
      "fecha_ingreso": "2023-03-02 19:27:39.927267",
      "intentos": 0,
      "ultima_fecha": 1677806926
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5921539441293898": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5921539441293898&width=1024&ext=1678377996&hash=AeTrSv-DbhWURi8izNk",
      "fb_username": "Melody Diaz",
      "fecha_ingreso": "2023-02-07 09:03:11.101106",
      "ultima_fecha": 1675782568
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "5927708377309965": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5927708377309965&width=1024&ext=1683997184&hash=AeTy9ycKBuvoVOd51uA",
      "fb_username": "Bufete Quality",
      "fecha_ingreso": "2023-03-06 13:28:27.997290",
      "intentos": 0,
      "ultima_fecha": 1681401305
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5929611943791558": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5929611943791558&width=1024&ext=1677769497&hash=AeQeduAqvnN-C94A7OE",
      "fb_username": "Marllori Soto",
      "fecha_ingreso": "2023-01-31 08:08:44.614738",
      "ultima_fecha": 1675174129
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5931945246841376": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5931945246841376&width=1024&ext=1678586611&hash=AeQMBDZRhIz0nxctbHU",
      "fb_username": "Ade Ovalle",
      "fecha_ingreso": "2023-02-09 19:03:40.977799",
      "ultima_fecha": 1675991161
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5933523816702126": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5933523816702126&width=1024&ext=1677101191&hash=AeSyap1XshCqC4uNbyc",
      "fb_username": "Madlin Hrnandzz",
      "fecha_ingreso": "2023-01-23 14:31:02.443702",
      "ultima_fecha": 1674505884
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "5934788406621596": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5934788406621596&width=1024&ext=1695312468&hash=AeSYUFVx-bC7heKNnQw",
      "fb_username": "Pablo Hernández",
      "fecha_ingreso": "2023-08-22 10:07:01.580960",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1692720470
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "5935455266531067": {
    "atributos": {
      "Correo": "maynor@mayora-mayora.com",
      "Gestion_numero": "1004-196397",
      "Gestion_problema": "La firma en la razón tarda demasiado, se válido desde las 9:30 am",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Maynor Gutiérrez",
      "Telefono": "49731269",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG2U72g6mLReDbqBsAuHo-iNMRuzC_HNguYOmIIb4HDkWIClnYNp9wV19aLiECyqLAhu6_hNE-d&psid=5935455266531067&width=1024&ext=1711659251&hash=AfpDNgDVJ3lgeQM8psf4VtSxmdSprIwaAKcRecZU3y834g",
      "fb_username": "Gt Lando",
      "fecha_chatcenter": 1709067066919,
      "fecha_ingreso": "2023-03-02 13:27:37.546990",
      "leido": true,
      "log_chatcenter": "-NrgKStfCHFofZ-xrfJh",
      "msg_noleido": 0,
      "ultima_fecha": 1709067251
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "5935484786490131": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5935484786490131&width=1024&ext=1674321356&hash=AeRNfqWmGzCBS_TNoGo",
      "fb_username": "Brenda Alfaro",
      "fecha_ingreso": "2022-12-22 10:23:26.451094",
      "ultima_fecha": 1671726294
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5935861699863169": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGhnA25eHDqLPa-Dc-tbeRtJa8GAggCBChUgQnavSv3_Rmm_VIS3AwnYiZ0P1qYLX8l9NNrUaaV&psid=5935861699863169&width=1024&ext=1713027606&hash=AfqAoZMpK8tlBmwvBPIr5XEYV1zLs6XN7v1XInG7NWBoYA",
      "fb_username": "Sara Letty Herrera",
      "fecha_ingreso": "2023-03-13 09:04:22.286475",
      "intentos": 0,
      "ultima_fecha": 1710435608
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5936112059790150": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5936112059790150&width=1024&ext=1679843032&hash=AeSIs4Vl_wZQnE7inkc",
      "fb_username": "Carol E. Cham",
      "fecha_ingreso": "2023-02-24 07:35:36.612299",
      "ultima_fecha": 1677247461
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5939233692799381": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5939233692799381&width=1024&ext=1675531254&hash=AeQei3jWTv6jjfSvobQ",
      "fb_username": "Paola Chaj Mejía",
      "fecha_ingreso": "2023-01-05 10:25:51.820189",
      "ultima_fecha": 1672936091
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5941976565920683": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5941976565920683&width=1024&ext=1680184723&hash=AeSQLwsKS3BwAuwBTx4",
      "fb_username": "Maria Elizabeth Reyes Moreno",
      "fecha_ingreso": "2023-02-28 06:58:34.430364",
      "intentos": 1,
      "ultima_fecha": 1677589121
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5946318138760897": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=zZn668PpBQQAX9ngzTe&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAxReGM-Gfw_nHoRg2_iyQBDX93sxkCJ4kud1MnV_-PIA&oe=640C7F59",
      "fb_username": "Francisco Portillo",
      "fecha_ingreso": "2023-02-09 09:14:13.979157",
      "ultima_fecha": 1675955976
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "5946406285455925": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5946406285455925&width=1024&ext=1683911444&hash=AeSvbMv1wG4Dhe7iTH8",
      "fb_username": "Beggui Chavez Clemente",
      "fecha_ingreso": "2023-04-12 09:41:23.558412",
      "intentos": 0,
      "ultima_fecha": 1681315571
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "5952803371448160": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5952803371448160&width=1024&ext=1678922695&hash=AeRNi23F5K3VTQKOLBU",
      "fb_username": "Lau Raquel",
      "fecha_ingreso": "2023-02-09 17:08:27.687971",
      "ultima_fecha": 1676327213
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5953451461390350": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5953451461390350&width=1024&ext=1681076108&hash=AeSzh0euSdfpI53GIzA",
      "fb_username": "Ana Beatriz Camacho",
      "fecha_ingreso": "2023-03-10 14:33:07.696035",
      "intentos": 0,
      "ultima_fecha": 1678480439
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "5955005561235572": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5955005561235572&width=1024&ext=1683151415&hash=AeTE90ocipdm2B7Bz58",
      "fb_username": "Reyes Santos",
      "fecha_ingreso": "2023-04-03 14:56:34.020499",
      "ultima_fecha": 1680555599
    },
    "user_posicion": {
      "bloque": "ReposicionPatenteSociedad",
      "posicion": 1
    }
  },
  "5956195484442193": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5956195484442193&width=1024&ext=1677090670&hash=AeQQfwW6UggN1blTBhU",
      "fb_username": "Sswua Recinos",
      "fecha_ingreso": "2023-01-23 11:33:32.153104",
      "ultima_fecha": 1674495364
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5956988951088201": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5956988951088201&width=1024&ext=1679683863&hash=AeRu58-n4LKpKaAJuL4",
      "fb_username": "Lucy Andres",
      "fecha_ingreso": "2023-02-22 09:20:39.939474",
      "intentos": 0,
      "ultima_fecha": 1677088306
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5957730127640688": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5957730127640688&width=1024&ext=1680460423&hash=AeQfwwxo8ki9xdw4QyY",
      "fb_username": "Nancy García",
      "fecha_ingreso": "2023-03-03 11:32:21.509522",
      "ultima_fecha": 1677864789
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "5957797064304472": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5957797064304472&width=1024&ext=1681397658&hash=AeQ4s67V5fG0VxYg1kc",
      "fb_username": "Anneliese Reyes Bran",
      "fecha_ingreso": "2023-03-14 07:51:23.614205",
      "ultima_fecha": 1678801966
    },
    "user_posicion": {
      "bloque": "Acciones_inscripciones",
      "posicion": 1
    }
  },
  "5957886624290949": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5957886624290949&width=1024&ext=1679246870&hash=AeQmB-M5_4jSXJ3rDHY",
      "fb_username": "Edgar Masin Junior",
      "fecha_ingreso": "2023-02-17 10:29:13.316829",
      "ultima_fecha": 1676651357
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5959732230748025": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5959732230748025&width=1024&ext=1681939678&hash=AeQgQ3UbmWMpfRBsCV8",
      "fb_username": "Jason Tejada",
      "fecha_ingreso": "2023-03-20 14:23:22.437094",
      "intentos": 0,
      "ultima_fecha": 1679343950
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5959851274051016": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5959851274051016&width=1024&ext=1679235236&hash=AeQAiErBrM2uxHFvr0w",
      "fb_username": "Marvin Reyes",
      "fecha_ingreso": "2023-02-17 07:14:18.850694",
      "ultima_fecha": 1676639723
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "5962594367150456": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5962594367150456&width=1024&ext=1680448402&hash=AeTrvHkymLXGQFoqHv0",
      "fb_username": "Souaiboi Bahfinan",
      "fecha_ingreso": "2023-03-03 08:12:48.227153"
    }
  },
  "5969193023134098": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=GCswlcCrBvYAX9aUvtT&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfBHCNOfQDryUeYU04xN9_zTPJB6b8Epik6upeY3peZILQ&oe=64529719",
      "fb_username": "Diana Galilea",
      "fecha_ingreso": "2023-04-03 10:45:15.004269",
      "intentos": 0,
      "ultima_fecha": 1680540341
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5971336622935579": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5971336622935579&width=1024&ext=1679948080&hash=AeQWF0aJSEvNlX7eCD8",
      "fb_username": "Domingosjose Meque",
      "fecha_ingreso": "2023-02-25 13:14:55.152436",
      "ultima_fecha": 1677352498
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "5972654512843103": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5972654512843103&width=1024&ext=1690956912&hash=AeS0rVqdPsPSD2VzENo",
      "fb_username": "Mardo Cutzal",
      "fecha_ingreso": "2023-05-18 21:06:58.927950",
      "intentos": 0,
      "ultima_fecha": 1688364915
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5974440655984239": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5974440655984239&width=1024&ext=1680113745&hash=AeTuYxK2i44qwQvxEps",
      "fb_username": "Alex Trujillo",
      "fecha_ingreso": "2023-02-27 11:14:50.980084",
      "ultima_fecha": 1677518144
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5974459216002195": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5974459216002195&width=1024&ext=1678380751&hash=AeQZeSfJkjZ6j9MgUwU",
      "fb_username": "Mel Mendoza",
      "fecha_ingreso": "2023-02-07 08:29:42.039355",
      "ultima_fecha": 1675785323
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5976866472368409": {
    "atributos": {
      "Correo": "julisfabiana@hotmail.com",
      "Gestion_numero": "Reposición de patente de comercio por pérdida del mismo",
      "Gestion_problema": "Reposición patente de comercio",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Julissa Ventura",
      "Telefono": "42690241",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5976866472368409&width=1024&ext=1685133263&hash=AeT86k9ctN7yPQk9K_8",
      "fb_username": "Uly VF",
      "fecha_ingreso": "2023-04-26 13:46:57.705359",
      "ultima_fecha": 1682541263
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "5978014505631898": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5978014505631898&width=1024&ext=1696722713&hash=AeSByYO-6rt1urXqAuo",
      "fb_username": "Luis Castañeda",
      "fecha_ingreso": "2023-09-07 17:49:38.404196",
      "ultima_fecha": 1694130715
    },
    "user_posicion": {
      "bloque": "InscripcionSociedad",
      "posicion": 1
    }
  },
  "5980097292047716": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5980097292047716&width=1024&ext=1699641217&hash=AeTxCZlzcqJpk9VFjho",
      "fb_username": "Maira Santana",
      "fecha_ingreso": "2023-01-24 08:51:08.870350",
      "ultima_fecha": 1697049140
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "5981087381968888": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5981087381968888&width=1024&ext=1685208823&hash=AeSJ4Ri8zcrKuHxdjPY",
      "fb_username": "Selvin Chavez",
      "fecha_ingreso": "2023-04-27 11:33:21.201633",
      "ultima_fecha": 1682616827
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5981588451953659": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5981588451953659&width=1024&ext=1686783630&hash=AeQuEDy34lmydhbYf48",
      "fb_username": "Abner J. Lopez Smit",
      "fecha_ingreso": "2023-05-15 16:59:40.202993",
      "ultima_fecha": 1684191631
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "5983435575081754": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5983435575081754&width=1024&ext=1677694974&hash=AeRSx6ATInGLY1IkM-w",
      "fb_username": "Rickyn López",
      "fecha_ingreso": "2023-01-30 11:22:54.302202",
      "ultima_fecha": 1675099609
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "5983671961655272": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5983671961655272&width=1024&ext=1677435938&hash=AeQUr4Bdo-6kt-3z3X0",
      "fb_username": "Flor De María Marroquín",
      "fecha_ingreso": "2023-01-27 11:29:12.577158",
      "ultima_fecha": 1674840599
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5986024628111516": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5986024628111516&width=1024&ext=1684360683&hash=AeSClID6Qnl9-cOrOjU",
      "fb_username": "Mirna Garcia",
      "fecha_ingreso": "2023-04-17 15:57:26.032911",
      "ultima_fecha": 1681768685
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "5986356894810129": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5986356894810129&width=1024&ext=1684525756&hash=AeQxWGCvnGHY13TvkjQ",
      "fb_username": "Vanniia Beaa Hernández",
      "fecha_ingreso": "2023-04-19 13:47:53.309358",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681933757
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "5991015330946814": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5991015330946814&width=1024&ext=1682272041&hash=AeQQpWs4nyjEPpgFG8M",
      "fb_username": "Ronaldo Ramírez",
      "fecha_ingreso": "2023-03-24 10:43:43.141416",
      "intentos": 0,
      "ultima_fecha": 1679676291
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "5991474244234260": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5991474244234260&width=1024&ext=1679017677&hash=AeQte4PVeyNlf3a6bns",
      "fb_username": "Luz Clarita",
      "fecha_ingreso": "2023-02-14 18:42:34.539225",
      "ultima_fecha": 1676422183
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "5992477477480163": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5992477477480163&width=1024&ext=1681402844&hash=AeRQzG6mG4K0VG9lju4",
      "fb_username": "Isa Oliva",
      "fecha_ingreso": "2023-01-25 10:25:00.631270",
      "ultima_fecha": 1678807155
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "5995559707163957": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5995559707163957&width=1024&ext=1686169169&hash=AeSqLV_GbLdyAokZCbY",
      "fb_username": "Cristian Vasquez",
      "fecha_ingreso": "2023-05-08 13:36:16.219262",
      "ultima_fecha": 1683577170
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "5998509483543090": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5998509483543090&width=1024&ext=1677769117&hash=AeRa05pKkP5nIoskc98",
      "fb_username": "Magdy MoVe",
      "fecha_ingreso": "2023-01-31 07:57:54.831367",
      "ultima_fecha": 1675173745
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "5998710710182436": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5998710710182436&width=1024&ext=1685051756&hash=AeQOqs9bxaoJv02jl5Y",
      "fb_username": "Kevin Rivera",
      "fecha_ingreso": "2023-04-25 15:54:32.244519",
      "ultima_fecha": 1682459758
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "5999363416786360": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5999363416786360&width=1024&ext=1679070633&hash=AeQVjZq8SEozrchJMU0",
      "fb_username": "Alejandro Pérez",
      "fecha_ingreso": "2023-02-11 20:31:51.712316",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1676475137
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "5999464496834590": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=5999464496834590&width=1024&ext=1681492122&hash=AeTet38MLQha9k-8XFg",
      "fb_username": "Alida Castillo",
      "fecha_ingreso": "2023-03-15 08:32:47.063213",
      "intentos": 0,
      "ultima_fecha": 1678896424
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "6000791920037220": {
    "atributos": {
      "Correo": "emerson.zacarias.nava@gmail.com",
      "Gestion_numero": "32807738",
      "Gestion_problema": "MOTIVO DE RECHAZO: ESTIMADO USUARIO HABLAR CON OPERADOR.",
      "Gestion_tipo": "Electrónico",
      "Nombre": "EMERSON ZACARIAS",
      "Telefono": "42124539",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFNb7WjG-EyZEDyp5f0bQrCnVSvOSV34lJ_2eUaMxHbaRx2StOIRCHNOanKCwXP_BApN1P8sbV3&psid=6000791920037220&width=1024&ext=1710000437&hash=AfrxkFs1rg-1CSprVHEzRLYuKZbqDpOAKa5AsFtWHXFSEQ",
      "fb_username": "Emerson Zacarias",
      "fecha_chatcenter": 1707407518864,
      "fecha_ingreso": "2023-04-13 09:52:57.851724",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-Nq8Pmmzoo0qdDeXusHh",
      "msg_noleido": 0,
      "ultima_fecha": 1707408437
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "6003981636307657": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6003981636307657&width=1024&ext=1677457599&hash=AeSRWMCbgt4mw-Zn9BY",
      "fb_username": "Antonio Acabal",
      "fecha_ingreso": "2023-01-27 17:30:56.593678",
      "ultima_fecha": 1674862260
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6005249036264980": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6005249036264980&width=1024&ext=1690497408&hash=AeTrIka4qqBSfB4L3M0",
      "fb_username": "Jaqui Vásquez",
      "fecha_ingreso": "2023-06-27 16:35:47.722583",
      "ultima_fecha": 1687905410
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6005450302834437": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6005450302834437&width=1024&ext=1678912161&hash=AeT-jO0hVzNQvYkntG0",
      "fb_username": "Ismar Gerardo Garcia Rivera",
      "fecha_ingreso": "2023-02-13 13:27:36.963218",
      "ultima_fecha": 1676316679
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6007312109398576": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6007312109398576&width=1024&ext=1689180465&hash=AeTofQ9ZtZ0Hh0RG6ls",
      "fb_username": "Nytzia Avila",
      "fecha_ingreso": "2023-06-12 10:33:10.711926",
      "intentos": 0,
      "ultima_fecha": 1686588468
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6008140329283076": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=S7G7zlblfxcAX_Ze-rb&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfBuiJknLAv1TYJIPRTgPO2Y8TzvQwLCDX-PdpmGzs1hkQ&oe=64A99A19",
      "fb_username": "Lucía López",
      "fecha_ingreso": "2023-06-08 11:24:15.787914",
      "ultima_fecha": 1686245106
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "6008510929210593": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=B_h6Wmar5DwAX-Lobeg&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAiJA86l1w80Wva3H4rGfZkMPpZAyuj2XTXC5qU0tS4xA&oe=66218DD9",
      "fb_username": "Cc Marely",
      "fecha_ingreso": "2023-02-15 14:37:40.456758",
      "intentos": 0,
      "ultima_fecha": 1710889662
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6012093012219597": {
    "atributos": {
      "Correo": "info_akamstore@icloud.com",
      "Gestion_numero": "54859-2023",
      "Gestion_problema": "No puedo descargar la patente",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Myrna Amesquita",
      "Telefono": "57890738",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6012093012219597&width=1024&ext=1688057191&hash=AeRzXPCg6AuXaUJM3y4",
      "fb_username": "Mirna Amezquita",
      "fecha_ingreso": "2023-05-30 10:40:58.689416",
      "intentos": 0,
      "ultima_fecha": 1685465192
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6015826315138226": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6015826315138226&width=1024&ext=1683047277&hash=AeT85r674sdK41H1QtM",
      "fb_username": "Melany Orellana",
      "fecha_ingreso": "2023-04-02 10:04:06.613466",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680451468
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6017215788386297": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6017215788386297&width=1024&ext=1685225982&hash=AeSAdRbO8XB34gf6hDg",
      "fb_username": "Milton Gomez",
      "fecha_ingreso": "2023-04-27 13:22:43.952117",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1682633876
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6019831481450699": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6019831481450699&width=1024&ext=1698502404&hash=AeSp7V6PAPESzppS_5w",
      "fb_username": "Eugenia Velasquez M",
      "fecha_ingreso": "2023-09-28 08:12:54.419337",
      "intentos": 0,
      "ultima_fecha": 1695910407
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6020152204708818": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6020152204708818&width=1024&ext=1678287248&hash=AeT_LYb4FCABOckr38c",
      "fb_username": "Leonel E Pineda C",
      "fecha_ingreso": "2023-02-06 07:56:30.455404",
      "ultima_fecha": 1675691829
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6024095674278250": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6024095674278250&width=1024&ext=1690753353&hash=AeQMKFApNPA6LM_bir0",
      "fb_username": "Pablo Urias Johnson",
      "fecha_ingreso": "2022-12-19 18:10:29.963250",
      "ultima_fecha": 1688161355
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6028486537233449": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6028486537233449&width=1024&ext=1679248939&hash=AeQeFBIfi2QmofA_Lms",
      "fb_username": "Salome Chamorro",
      "fecha_ingreso": "2023-02-17 10:57:06.131228",
      "ultima_fecha": 1676653424
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "6033741720053420": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6033741720053420&width=1024&ext=1679613527&hash=AeR7hoB7jfgF_KAuHGg",
      "fb_username": "Isabel Oliva",
      "fecha_ingreso": "2023-02-21 16:18:22.046456",
      "intentos": 0,
      "ultima_fecha": 1677017977
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6035671606515740": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6035671606515740&width=1024&ext=1680705612&hash=AeTJtFaXefyRHxMg15k",
      "fb_username": "Adrean Gonzales",
      "fecha_ingreso": "2023-03-06 07:38:54.427689",
      "intentos": 0,
      "ultima_fecha": 1678109965
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6036252846464719": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6036252846464719&width=1024&ext=1681204349&hash=AeTWOdj8tBrq-vfJuZM",
      "fb_username": "Justice Sall Bokoum",
      "fecha_ingreso": "2023-03-12 02:11:08.410672",
      "intentos": 1,
      "ultima_fecha": 1678608675
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6037747272951215": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "Asesor",
      "Gestion_problema": "La Acta de Destrucción de inventario que precio tiene",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Gaby Paiz",
      "Telefono": "54611658",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6037747272951215&width=1024&ext=1694469942&hash=AeRtMRywqDZUS1CrIS8",
      "fb_username": "Gaby Paiz",
      "fecha_ingreso": "2023-01-18 09:28:48.552564",
      "intentos": 0,
      "ultima_fecha": 1691877943
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6043724969080320": {
    "atributos": {
      "Correo": "edificadordevida@gmail.com",
      "Gestion_numero": "352852023 patente de comercio",
      "Gestion_problema": "No tengo la patente y la ingresamos el dia viernes",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Jason Patton",
      "Telefono": "23339369",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6043724969080320&width=1024&ext=1683144045&hash=AeQrVxbO47ZMZYBRYZY",
      "fb_username": "Edificadores de Vida",
      "fecha_ingreso": "2023-04-03 10:41:40.587190",
      "ultima_fecha": 1680548226
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6051398531621857": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6051398531621857&width=1024&ext=1680968474&hash=AeTrwuNEltk7bYUh1h8",
      "fb_username": "Ivan Canahuí",
      "fecha_ingreso": "2023-03-09 08:32:31.928332",
      "ultima_fecha": 1678372811
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6054918447887648": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6054918447887648&width=1024&ext=1696810284&hash=AeQlEaQGftaaSvoa37E",
      "fb_username": "Luis Santos",
      "fecha_ingreso": "2023-02-03 10:55:56.590665",
      "ultima_fecha": 1694218288
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6055011497912686": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=O7CR_V32jMkAX8mWDWx&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfC-70YDDCkT7Gm34E21jUGzUBK_WhA35bXG2So-yT5a-A&oe=64D7BE99",
      "fb_username": "Cindy Gabriela Sanchez Diaz",
      "fecha_ingreso": "2023-03-13 07:14:35.866050",
      "intentos": 0,
      "ultima_fecha": 1689281984
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6055134167927419": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6055134167927419&width=1024&ext=1684748861&hash=AeR0ACUIMo91zmjcSGo",
      "fb_username": "Mario Contreras",
      "fecha_ingreso": "2023-04-22 03:47:41.483339",
      "intentos": 1,
      "ultima_fecha": 1682156867
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6066608850068043": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6066608850068043&width=1024&ext=1676157284&hash=AeRgR0xWLzgLFuFoODk",
      "fb_username": "Virginia Godínez",
      "fecha_ingreso": "2023-01-12 11:58:29.700509",
      "ultima_fecha": 1673562067
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "6070683379637194": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6070683379637194&width=1024&ext=1679503072&hash=AeRY63P8pgU8Uw4hI-g",
      "fb_username": "Alex Cardona",
      "fecha_ingreso": "2023-02-20 09:37:41.652151",
      "ultima_fecha": 1676907533
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6072715069430607": {
    "atributos": {
      "Correo": "202108609.yennyferpirir@gmail.com",
      "Gestion_numero": "Ayuda",
      "Gestion_problema": "Como puedo modificar una solícito que por error le coloque dos propietarios y es solo 1",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Yennyfer pirir",
      "Telefono": "30600932",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFLO6hb3Ac2pnhChwCn05jOCKdAeXlQjyu71CStffpDYIU5dSEpVanH9H5_SibsCkDnYHWBTq6M&psid=6072715069430607&width=1024&ext=1713538697&hash=AfoPcS6vUQna7GmF7FAacpQ5rV7kahHBj0tBD22zChkSGQ",
      "fb_username": "Yenny Ely de Tobar",
      "fecha_chatcenter": 1707147261151,
      "fecha_ingreso": "2023-04-13 14:25:53.669660",
      "leido": false,
      "log_chatcenter": "-NpttzJ_lXUfGspvszX8",
      "msg_noleido": 0,
      "ultima_fecha": 1710946697
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "6073276972726550": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6073276972726550&width=1024&ext=1686760324&hash=AeSPQ4bMigaMgoN45IA",
      "fb_username": "Avi Lémus",
      "fecha_ingreso": "2023-05-15 10:31:39.090153",
      "ultima_fecha": 1684168325
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6073771946058189": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6073771946058189&width=1024&ext=1691268857&hash=AeRFkhfU6QJDOkvZWOg",
      "fb_username": "Emilio Doren",
      "fecha_ingreso": "2023-07-06 14:53:29.444098",
      "ultima_fecha": 1688676859
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6073911822678338": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6073911822678338&width=1024&ext=1682618289&hash=AeTIK2e971rffzqA_RY",
      "fb_username": "Jorge Coyoy",
      "fecha_ingreso": "2023-03-28 10:54:40.586797",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680022511
    },
    "user_posicion": {
      "bloque": "CambioAmpliaObjeto",
      "posicion": 1
    }
  },
  "6078305085569372": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6078305085569372&width=1024&ext=1682801999&hash=AeSViy5iV_p88ug9TlM",
      "fb_username": "Eliseo Arreaga",
      "fecha_ingreso": "2023-03-30 13:56:03.829234",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680206210
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6079292815505571": {
    "atributos": {
      "Correo": "anamarroquin1780@gmail.com",
      "Gestion_numero": "Una duda yengo",
      "Gestion_problema": "Arancel para inscribirse una ampliación",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Ana Marroquín",
      "Telefono": "55543376",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFdR3dD2mJENe1lQ5zQqqded1bef9rTprend6jPqVitQpkK8vCK8-8UmOk4fky1AC3su4gW6a06&psid=6079292815505571&width=1024&ext=1715368032&hash=Afql2s6AZaHqCddKXh5mhSaynoJklxuWLDAHtRcgjFbA1Q",
      "fb_username": "Ana Lu De Guzmán",
      "fecha_ingreso": "2023-08-31 17:16:48.659732",
      "intentos": 0,
      "ultima_fecha": 1712776033
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6080698718672766": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6080698718672766&width=1024&ext=1684524038&hash=AeSGH22q03JFBaDwzHU",
      "fb_username": "Rolando Menchú",
      "fecha_ingreso": "2023-04-19 13:19:22.142105",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681932042
    },
    "user_posicion": {
      "bloque": "CambioDireccionFiscalEmpresa",
      "posicion": 1
    }
  },
  "6081175908593901": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6081175908593901&width=1024&ext=1677360775&hash=AeTmEMa9rr9HMDTt7Ms",
      "fb_username": "Marielos Orozco",
      "fecha_ingreso": "2023-01-26 14:30:32.700467",
      "ultima_fecha": 1674765444
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6081318068656417": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6081318068656417&width=1024&ext=1685821869&hash=AeS8aghQ4lwfvnb_Q8U",
      "fb_username": "Stephanie Brenda",
      "fecha_ingreso": "2023-05-04 13:51:09.200145",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1683229875
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6086892028045209": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6086892028045209&width=1024&ext=1678485764&hash=AeS-6n4NdwAt3fwh_fQ",
      "fb_username": "Erick J. López",
      "fecha_ingreso": "2023-02-08 14:52:55.105430",
      "ultima_fecha": 1675890324
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6087562354630040": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6087562354630040&width=1024&ext=1679114683&hash=AeQuM9hPx-Ksa6lU7Xw",
      "fb_username": "Angie Guinea Diez",
      "fecha_ingreso": "2023-02-15 21:46:19.353018",
      "ultima_fecha": 1676519183
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6088583324537649": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6088583324537649&width=1024&ext=1677432084&hash=AeTz50dXVK2gbupJ62w",
      "fb_username": "Ani Mita",
      "fecha_ingreso": "2023-01-27 10:24:09.671861",
      "ultima_fecha": 1674836747
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6089297761133555": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6089297761133555&width=1024&ext=1679112016&hash=AeTkxlEQTXMLtre7sgk",
      "fb_username": "Daniel Ortiz",
      "fecha_ingreso": "2023-02-15 21:01:12.097135",
      "ultima_fecha": 1676516515
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6091446177580117": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6091446177580117&width=1024&ext=1678283575&hash=AeQwzytT7Br9iHTchWk",
      "fb_username": "Jose Dah Belg",
      "fecha_ingreso": "2023-02-06 06:55:00.547197",
      "ultima_fecha": 1675688154
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6091936520850005": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6091936520850005&width=1024&ext=1680129471&hash=AeTAdGCY4IRuyJOnd5g",
      "fb_username": "Oscar Gálvez",
      "fecha_ingreso": "2023-02-27 11:10:33.900480",
      "intentos": 0,
      "ultima_fecha": 1677533870
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6092404584104472": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6092404584104472&width=1024&ext=1675869732&hash=AeQuhBVCFbYcwscAng8",
      "fb_username": "Luis Fernando Guit",
      "fecha_ingreso": "2023-01-09 08:28:01.439179",
      "ultima_fecha": 1673274540
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6095334277188916": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6095334277188916&width=1024&ext=1680803238&hash=AeTOBpIEveQPXipi9l4",
      "fb_username": "Vicky Mazariegos",
      "fecha_ingreso": "2023-03-07 10:43:08.196207",
      "intentos": 0,
      "ultima_fecha": 1678207585
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6099130556836449": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6099130556836449&width=1024&ext=1682186816&hash=AeSf_JUAB3E97WLcKSA",
      "fb_username": "Lorena Vasquez",
      "fecha_ingreso": "2023-03-23 11:04:22.593936",
      "ultima_fecha": 1679591068
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6101444686616747": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6101444686616747&width=1024&ext=1684954870&hash=AeRlbuKmIyv_D-KYOpc",
      "fb_username": "Lucy Lopez",
      "fecha_ingreso": "2023-04-24 12:57:42.210638",
      "intentos": 0,
      "ultima_fecha": 1682362871
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6101514039894768": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6101514039894768&width=1024&ext=1680358647&hash=AeSN1v61Vn66hHJn-E4",
      "fb_username": "Juan Ch",
      "fecha_ingreso": "2023-03-02 06:53:03.882366",
      "ultima_fecha": 1677763025
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6104891216215183": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6104891216215183&width=1024&ext=1675964352&hash=AeQF3ohRZ7YFHKoTT88",
      "fb_username": "Seimos PB",
      "fecha_ingreso": "2023-01-10 10:45:49.973018",
      "ultima_fecha": 1673369154
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6107432686011134": {
    "atributos": {
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6107432686011134&width=1024&ext=1683756471&hash=AeRXmVxs6jqlFYIeewo",
      "fb_username": "Karmen Trujillo",
      "fecha_ingreso": "2023-04-10 10:14:53.621259",
      "ultima_fecha": 1681160606
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6107631329299751": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6107631329299751&width=1024&ext=1677348461&hash=AeRXuuKsXti3yYEnmuE",
      "fb_username": "Bayron Jimenez",
      "fecha_ingreso": "2023-01-26 11:11:04.443884",
      "ultima_fecha": 1674753132
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6110591565675218": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6110591565675218&width=1024&ext=1682958630&hash=AeSjCajqSG5imV4SHCo",
      "fb_username": "Nineth Capriel",
      "fecha_ingreso": "2023-02-21 10:10:28.077197",
      "intentos": 0,
      "ultima_fecha": 1680362827
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6110733302272960": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6110733302272960&width=1024&ext=1675359010&hash=AeQUgtFAVSud2TGJNLE",
      "fb_username": "Nery Guzman",
      "fecha_ingreso": "2023-01-03 10:36:54.256463",
      "ultima_fecha": 1672763861
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6111950562265505": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6111950562265505&width=1024&ext=1691165560&hash=AeTeM9vBSeTifPzyqf4",
      "fb_username": "Katherine Ibarra",
      "fecha_ingreso": "2023-07-05 10:12:29.446829",
      "ultima_fecha": 1688573562
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6117469778339198": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6117469778339198&width=1024&ext=1682095276&hash=AeQaV9KWWbRTSdzq9FY",
      "fb_username": "Steven Mencos",
      "fecha_ingreso": "2023-03-22 09:38:17.990903",
      "intentos": 0,
      "ultima_fecha": 1679499538
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6117730651580622": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6117730651580622&width=1024&ext=1676651209&hash=AeRE5VBTZ-1ZgvOFEk4",
      "fb_username": "Sebastián De Leon",
      "fecha_ingreso": "2023-01-18 09:30:08.294586",
      "ultima_fecha": 1674055946
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6118437884889081": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6118437884889081&width=1024&ext=1682800235&hash=AeTre0hrhL0-bPi9BwE",
      "fb_username": "Brayan Zetino",
      "fecha_ingreso": "2023-03-30 08:44:34.357063",
      "intentos": 0,
      "ultima_fecha": 1680204444
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6118638864870464": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6118638864870464&width=1024&ext=1684965210&hash=AeQzBaqZJMomfhYPl-U",
      "fb_username": "Kimberlyn Hernández Villatoro",
      "fecha_ingreso": "2023-04-24 15:35:08.945523",
      "intentos": 1,
      "ultima_fecha": 1682373214
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6120731251291696": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6120731251291696&width=1024&ext=1677089091&hash=AeTZ4LPkIHYcjxh5uqM",
      "fb_username": "Lisdy VM",
      "fecha_ingreso": "2023-01-23 11:06:51.178887",
      "ultima_fecha": 1674493787
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6121263044588282": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6121263044588282&width=1024&ext=1687370298&hash=AeSc9yeP2J57Aq07E4w",
      "fb_username": "Lis Rocio Maldonado",
      "fecha_ingreso": "2023-05-16 11:08:28.223687",
      "ultima_fecha": 1684778300
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6121550654558040": {
    "atributos": {
      "Correo": "Necesito hablar con un asesor",
      "Gestion_numero": "Necesito hablar con un asesor",
      "Gestion_problema": "Necesito hablar con un asesor",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Necesito hablar con un asesor",
      "Telefono": "Necesito hablar con un asesor",
      "asesor_id": "T1vBPIA69DRIw8B34rv0YvdMDal2",
      "asesor_name": "ryaxon",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6121550654558040&width=1024&ext=1683150433&hash=AeQSZNkLjZFup9oykgM",
      "fb_username": "Angel Perez",
      "fecha_ingreso": "2023-03-31 14:19:11.416595",
      "intentos": 0,
      "ultima_fecha": 1680554616
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6122190581178633": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6122190581178633&width=1024&ext=1690685775&hash=AeRo4O0nSumC0XZIsdk",
      "fb_username": "Alejandra Elias",
      "fecha_ingreso": "2023-02-01 13:47:47.188590",
      "ultima_fecha": 1688093761
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6122252387819027": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6122252387819027&width=1024&ext=1674174708&hash=AeSZoAET22a58MVqLl4",
      "fb_username": "Estuardo Ramirez",
      "fecha_ingreso": "2022-12-20 17:39:40.708400",
      "ultima_fecha": 1671579658
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6125060450876895": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6125060450876895&width=1024&ext=1684097535&hash=AeQcKbTKyqbcGxZB1DM",
      "fb_username": "Edgar Ruiz",
      "fecha_ingreso": "2023-04-14 13:45:57.236050",
      "intentos": 2,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681501649
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6125177467562714": {
    "atributos": {
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6125177467562714&width=1024&ext=1684212171&hash=AeQXCzYmA2goAXyN5k4",
      "fb_username": "Manuel De Jesús Tzaj",
      "fecha_ingreso": "2023-04-15 21:34:20.452908",
      "intentos": 2,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681616276
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6129353883777064": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6129353883777064&width=1024&ext=1678458992&hash=AeQOpwZntuYLB6A5rP0",
      "fb_username": "Sergio Lemus",
      "fecha_ingreso": "2023-02-07 12:53:20.594525",
      "ultima_fecha": 1675863554
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6130638300351122": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6130638300351122&width=1024&ext=1679599587&hash=AeQH-MswhVFHVpNZEdo",
      "fb_username": "Emilly Ruano",
      "fecha_ingreso": "2023-02-21 12:22:46.681217",
      "ultima_fecha": 1677004039
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6131087243595538": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6131087243595538&width=1024&ext=1685734740&hash=AeSbRVllekyo-R02BrY",
      "fb_username": "Karla Corina",
      "fecha_ingreso": "2023-05-03 13:38:46.524333",
      "ultima_fecha": 1683142742
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6133142996769238": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6133142996769238&width=1024&ext=1682797218&hash=AeSAzVHisIgJTBRzh1A",
      "fb_username": "Glenda Hernández",
      "fecha_ingreso": "2023-03-30 12:29:41.043989",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680201426
    },
    "user_posicion": {
      "bloque": "SociedadMercantil",
      "posicion": 1
    }
  },
  "6134098813320648": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6134098813320648&width=1024&ext=1678304030&hash=AeRf7TddDfSpMRdFP2c",
      "fb_username": "Ana Marily",
      "fecha_ingreso": "2023-02-05 22:26:35.329696",
      "ultima_fecha": 1675708607
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6138904222890957": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6138904222890957&width=1024&ext=1686319955&hash=AeTcpdoxqgrys9JJ6so",
      "fb_username": "Leslie Ramirez",
      "fecha_ingreso": "2023-05-10 08:11:50.267836",
      "intentos": 0,
      "ultima_fecha": 1683727956
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6139546269448487": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6139546269448487&width=1024&ext=1685731893&hash=AeTvnu3MbaHGarU3oQY",
      "fb_username": "AbiDan Jom",
      "fecha_ingreso": "2023-05-03 12:50:22.441941",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1683139896
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6140727972676695": {
    "atributos": {
      "Correo": "zapetaarias@gmail.com",
      "Gestion_numero": "Una consulta\nEn una sociedad de responsabilidad limitada, la escritura de modificacion la puede firmar el representante legal o alguno de los socios ?",
      "Gestion_problema": "Una consulta\nEn una sociedad de responsabilidad limitada, la escritura de modificacion la puede firmar el representante legal o alguno de los socios ?",
      "Gestion_tipo": "Buenos días",
      "Nombre": "Kevin Zapeta",
      "Telefono": "41827285",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEeMEQqpL3vXpVN7p71nc5Iufz2UH3VC0maf_AjD0v-_zDbI7h5pJEus5rj_SgT8IVd_gC9cWm5&psid=6140727972676695&width=1024&ext=1708538737&hash=AfqxN8Xm-ZQbgehwmDZTZg5et4eSXU3iQb2PWKT_tFTu6g",
      "fb_username": "Këvïn Arias",
      "fecha_ingreso": "2023-04-12 08:09:06.286098",
      "intentos": 0,
      "ultima_fecha": 1705946740
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6142702809093753": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEw1e34GvpwdySg-c0Hw06o9oBBUJWceB0ktHl2cXOxnplPc6JDut1dgi1SXr9FqOnrEyzQYr2B&psid=6142702809093753&width=1024&ext=1703350187&hash=AeSmFKA-4vGwi_3G-N8",
      "fb_username": "Manuel Arriaga de Paz",
      "fecha_ingreso": "2023-01-18 09:14:13.596564",
      "intentos": 0,
      "ultima_fecha": 1700758032
    },
    "user_posicion": {
      "bloque": "Auxiliar_inscripcion",
      "posicion": 1
    }
  },
  "6144698148887045": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6144698148887045&width=1024&ext=1681421348&hash=AeRRWb0Ha_qTILvnZqU",
      "fb_username": "Marvin Hernandez",
      "fecha_ingreso": "2023-03-14 14:24:50.956442",
      "intentos": 2,
      "ultima_fecha": 1678825656
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6146505278747345": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGGYKtyJ4FoPkf1AFY2LQTRv52EfZZx4mIZBglLGq_xBT1Vdo6tJTw3Gx_D3S0E_jtpy6wka68o&psid=6146505278747345&width=1024&ext=1700324010&hash=AeQ9t6G2-n-r3ONKKyo",
      "fb_username": "Sofía Cermenio",
      "fecha_ingreso": "2023-03-29 14:04:25.571638",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1697732013
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6148440178542857": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6148440178542857&width=1024&ext=1686941366&hash=AeQhy8_l_xK9Ra-bQ-s",
      "fb_username": "Dulce Gatica",
      "fecha_ingreso": "2023-05-17 12:48:19.523560",
      "ultima_fecha": 1684349367
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6148860151861327": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6148860151861327&width=1024&ext=1683143557&hash=AeSW1VPS7zQbV_AfhiA",
      "fb_username": "Fabiola Castañeda",
      "fecha_ingreso": "2023-04-03 12:48:09.466333",
      "ultima_fecha": 1680547741
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6148861458572215": {
    "atributos": {
      "Correo": "antonionavas32@hotmail.com",
      "Gestion_numero": "1001-154002",
      "Gestion_problema": "Me aparece en: ‘ presentar en ventanilla ‘ pero no entiendo que tengo que entregar",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Antonio Navas",
      "Telefono": "35231117",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEAvaECRh1XYEPDsPALarPD76f6rG17Gkw-a3gCBSp37B1hEa5u3PsY6sd89-0Z08Rlc9nHwFLs&psid=6148861458572215&width=1024&ext=1712948017&hash=AfrebJM54RpTOXdetMEmmy7CxkCZWVlmk3kG6H-1hVwKuw",
      "fb_username": "Antonio Urrutia",
      "fecha_ingreso": "2024-03-13 12:32:06.820778",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1710356020
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6151153181615968": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6151153181615968&width=1024&ext=1678392422&hash=AeTI1dA3GEWr_utrwvQ",
      "fb_username": "Jimmy Flores Andrade",
      "fecha_ingreso": "2023-02-07 13:09:22.734983",
      "ultima_fecha": 1675796989
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6151332471553160": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6151332471553160&width=1024&ext=1675956555&hash=AeTm_C6oB43MzNpwqIk",
      "fb_username": "Mirna Argueta",
      "fecha_ingreso": "2023-01-10 08:34:51.528643",
      "ultima_fecha": 1673361356
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6156753031114555": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6156753031114555&width=1024&ext=1691864155&hash=AeQDm3mLOn7XyN8D4dI",
      "fb_username": "Victor Arturo Alvarez Aguilar",
      "fecha_ingreso": "2023-07-13 12:14:14.429928",
      "intentos": 3,
      "ultima_fecha": 1689272157
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6160834437309540": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6160834437309540&width=1024&ext=1680719994&hash=AeT7V9MXgy2NbRTcawQ",
      "fb_username": "Dalila Romero",
      "fecha_ingreso": "2023-03-03 14:25:44.984804",
      "intentos": 0,
      "ultima_fecha": 1678124346
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "6160984804021554": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGJ7N_Uo9Sn57799rD4kCtHHqs5dQdvGC9PN7La0tuOgagmjg14TjICxeY_dNAbVK20iKzfD9ve&psid=6160984804021554&width=1024&ext=1709324455&hash=AfqDFfbAougFTJBDYpFIcXBAaQ_Mz5NgMuK1qH-ElEpr6A",
      "fb_username": "Raquel Cuz",
      "fecha_ingreso": "2023-05-18 14:12:40.988568",
      "intentos": 0,
      "ultima_fecha": 1706732456
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6163094877146455": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6163094877146455&width=1024&ext=1690665491&hash=AeRbIfcZIf73m9WQ6H8",
      "fb_username": "Juanca Contreras",
      "fecha_ingreso": "2023-06-29 15:17:53.360454",
      "ultima_fecha": 1688073493
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6166041006749478": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6166041006749478&width=1024&ext=1680715858&hash=AeToO_ncRERBl6k71p8",
      "fb_username": "Marleny Menchú",
      "fecha_ingreso": "2023-02-22 08:03:18.838903",
      "intentos": 0,
      "ultima_fecha": 1678120210
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6167798849978160": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6167798849978160&width=1024&ext=1685219433&hash=AeQ_j1kzPVhU76u4lyw",
      "fb_username": "Edaniel Barreno",
      "fecha_ingreso": "2023-04-27 14:29:05.975858",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1682627435
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6175058635845727": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6175058635845727&width=1024&ext=1677353151&hash=AeRx_vzWqSoLZDwfLRY",
      "fb_username": "Vivii Ariass",
      "fecha_ingreso": "2023-01-26 12:29:12.904789",
      "ultima_fecha": 1674757821
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6177625649011922": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6177625649011922&width=1024&ext=1684772827&hash=AeSOnNWL2_RFFMAFDAw",
      "fb_username": "Beto Ico",
      "fecha_ingreso": "2023-04-22 10:19:50.236235",
      "intentos": 0,
      "ultima_fecha": 1682180830
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6178764618911306": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6178764618911306&width=1024&ext=1688387573&hash=AeQ8kzgOZhZy3y1c9W8",
      "fb_username": "Naty Magal",
      "fecha_ingreso": "2023-06-03 06:32:53.216167",
      "intentos": 1,
      "ultima_fecha": 1685795579
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6183498255061706": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6183498255061706&width=1024&ext=1689454328&hash=AeRt0BZH9mRBdbRPHZg",
      "fb_username": "Esly Carolina",
      "fecha_ingreso": "2023-06-15 14:49:17.874279",
      "intentos": 0,
      "ultima_fecha": 1686862329
    },
    "user_posicion": {
      "bloque": "InscripcionSociedad",
      "posicion": 1
    }
  },
  "6183792285058769": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6183792285058769&width=1024&ext=1694012665&hash=AeTHg6O07wKdunHZv9M",
      "fb_username": "Yessica Hernández",
      "fecha_ingreso": "2023-08-07 09:03:59.952772",
      "intentos": 0,
      "ultima_fecha": 1691420667
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6183954288333448": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=Nt8bk1DKsEAAX_JDG_o&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfBZDDXSdh2Jfyg0Dg7yOztascCscHbdXRVZUXMKVd8gDw&oe=64495C99",
      "fb_username": "Jorge Manrique Alas",
      "fecha_ingreso": "2023-03-27 14:11:29.563729",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1679947937
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6187918161301991": {
    "atributos": {
      "Correo": "cfralda19@gmail.com",
      "Gestion_numero": "40279-2023",
      "Gestion_problema": "La calificación de rechazo me manda a modificar el capital autorizado, pues el correcto es Q400,000 y por error omití colocar un 0 y el formulario está por Q40,000, el problema es que ese rubro aparece bloqueado para modificar",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Carlos Fernando de Paz Ralda",
      "Telefono": "41877143",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6187918161301991&width=1024&ext=1686433583&hash=AeRiDQmJsYQ1S5KI56w",
      "fb_username": "Carlos De Paz Ralda",
      "fecha_ingreso": "2023-05-11 15:20:54.620908",
      "ultima_fecha": 1683841583
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6194172550632904": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6194172550632904&width=1024&ext=1676150651&hash=AeRTI1Ni3a3KTPoxv-c",
      "fb_username": "Eduardo Rosales",
      "fecha_ingreso": "2023-01-12 14:21:42.664082",
      "ultima_fecha": 1673555434
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "6194867307236042": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6194867307236042&width=1024&ext=1679079485&hash=AeQkuXQmDhdVcrSmk6o",
      "fb_username": "Bryan Vargas",
      "fecha_ingreso": "2023-02-15 11:58:15.715845",
      "ultima_fecha": 1676483985
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6195155440529579": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6195155440529579&width=1024&ext=1696611522&hash=AeRnFYU_qWJs7el8Vr8",
      "fb_username": "Mario Cano",
      "fecha_ingreso": "2023-01-27 08:49:02.001437",
      "ultima_fecha": 1694019524
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6195666053859428": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGyGgkyVeNZaS0sh05NSi9fEUO8IDLd6QhklUTfM5N0yt4FizresqXgVyWQfbSkXFzX33f0N6MC&psid=6195666053859428&width=1024&ext=1702745799&hash=AeTSFPOpSIVUGLXJAOw",
      "fb_username": "Melisa Hernàndez",
      "fecha_ingreso": "2023-04-03 10:48:35.973011",
      "intentos": 0,
      "ultima_fecha": 1700153803
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6196104653850895": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6196104653850895&width=1024&ext=1692387242&hash=AeR5Im7D3-4QgTErHuc",
      "fb_username": "Paola Ramirez",
      "fecha_ingreso": "2023-07-19 13:32:35.971210",
      "ultima_fecha": 1689795245
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6198127883617970": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6198127883617970&width=1024&ext=1691164253&hash=AeRWE3_XIMfwIdJgMJk",
      "fb_username": "Geovanni Campos",
      "fecha_ingreso": "2023-07-05 09:47:50.434702",
      "ultima_fecha": 1688572255
    },
    "user_posicion": {
      "bloque": "SociedadMercantil",
      "posicion": 1
    }
  },
  "6200035573410574": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6200035573410574&width=1024&ext=1685764353&hash=AeQ4dyOLkNoehZ7t7nk",
      "fb_username": "Viviana Noemi Barrera Razo",
      "fecha_ingreso": "2023-05-03 21:52:34.086427",
      "intentos": 1,
      "ultima_fecha": 1683172359
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6200183970002502": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6200183970002502&width=1024&ext=1680390182&hash=AeS-31ZkoOgYAluzKpE",
      "fb_username": "Brenda Fernandez",
      "fecha_ingreso": "2023-03-02 16:00:50.050384",
      "intentos": 0,
      "ultima_fecha": 1677794559
    },
    "user_posicion": {
      "bloque": "Descargadoc",
      "posicion": 3
    }
  },
  "6200713349966250": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=K-3THke9Q8AAX8Z-g92&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfDiWxzjI2W4xoFp_fX8n1WIIfA_mqjmaU6pq5uztUMs5w&oe=64B26419",
      "fb_username": "Ana Torres",
      "fecha_ingreso": "2023-06-15 06:24:31.961300",
      "intentos": 0,
      "ultima_fecha": 1686831907
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6202686553184182": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6202686553184182&width=1024&ext=1686759671&hash=AeSeL6rOUYTTh_Jw-hU",
      "fb_username": "Ariel Montejo",
      "fecha_ingreso": "2023-05-15 10:20:15.248891",
      "intentos": 0,
      "ultima_fecha": 1684167675
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6203072413083485": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6203072413083485&width=1024&ext=1682780218&hash=AeSkVkRW6i_fDn-7DhY",
      "fb_username": "Roobertoo Miisael",
      "fecha_ingreso": "2023-03-30 07:48:40.823938",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680184427
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "6203754143009956": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6203754143009956&width=1024&ext=1682101379&hash=AeRNnoLvINk5QiFBGiE",
      "fb_username": "Caleb Sozo",
      "fecha_ingreso": "2023-03-22 11:20:35.412666"
    }
  },
  "6205112352909249": {
    "atributos": {
      "Correo": "ADELINAGRICELDA@HOTMAIL.COM",
      "Gestion_numero": "No. De trámite 32641982",
      "Gestion_problema": "No puedo descargar la patente",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Adelia Gricelda Cuyuch ixchop",
      "Telefono": "48151782",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFb56Z1Eo4H-O4GmsqlGt2MNJls_cCco5u_lOriBiNIz5b1WvVITH3tMum2laKh1CfPktktaK9X&psid=6205112352909249&width=1024&ext=1711294291&hash=AfqijBFJOQxAFWdHOpxtfeS5f98UvcAOCH853Ha1GLUN5w",
      "fb_username": "Karla Karina Us",
      "fecha_ingreso": "2023-04-18 11:01:11.504568",
      "ultima_fecha": 1708702292
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6205371652844535": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6205371652844535&width=1024&ext=1689572785&hash=AeTmwqY14IP4ulRlP7k",
      "fb_username": "Rey Valerio",
      "fecha_ingreso": "2023-06-16 23:46:25.688160"
    }
  },
  "6206151456074188": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6206151456074188&width=1024&ext=1680301673&hash=AeRXslgybsEQzfrFxWg",
      "fb_username": "Coti Karol",
      "fecha_ingreso": "2023-03-01 15:27:06.574730",
      "intentos": 0,
      "ultima_fecha": 1677706056
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6206930962764652": {
    "atributos": {
      "Gestion_tipo": "Certificación",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGXImzQaV5tFZVEpopAdpWloU13rBr3JBRBr1nVxri5jYkuExAUUu0vEBt5HN0XKeLPV56Ce8wi&psid=6206930962764652&width=1024&ext=1710693421&hash=AfrDZu75lRrpTW2krIF-671RzuOJum_ImEmQoSQWPMhHQQ",
      "fb_username": "Alex Silva Chajón",
      "fecha_ingreso": "2024-02-16 10:33:40.514619",
      "ultima_fecha": 1708101423
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6212105672154592": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6212105672154592&width=1024&ext=1679599622&hash=AeQOV0Qx4pwNZSurt84",
      "fb_username": "Margarita Alfaro",
      "fecha_ingreso": "2023-02-21 12:25:55.545315",
      "intentos": 0,
      "ultima_fecha": 1677004073
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6214242501977533": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6214242501977533&width=1024&ext=1690579805&hash=AeRqm4ygV3h-VChvlsg",
      "fb_username": "Ana Maria Pinto",
      "fecha_ingreso": "2023-06-28 15:27:50.961268",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1687987806
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6215714185208886": {
    "atributos": {
      "Correo": "emiliasaraipelenmorales@gmail.con",
      "Gestion_numero": "32604871",
      "Gestion_problema": "Debo adjuntar a la solicitud de inscripción de empresas, ya lo hice y no me deja avanzar, si no adjunto la inscripción del comerciante individual, ambas están subidas y me rechazan porque no hago lo que me piden y ambas ya están subidas",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Emilia, Pelen",
      "Telefono": "30103825",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6215714185208886&width=1024&ext=1688053543&hash=AeSFSp8iWLigOiCI6yY",
      "fb_username": "Emilia Sarai Pelén Morales",
      "fecha_ingreso": "2023-05-30 09:27:49.456628",
      "ultima_fecha": 1685461544
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6216796461704985": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6216796461704985&width=1024&ext=1673202860&hash=AeQuml-wrhVnnhQztaM",
      "fb_username": "Kevyn Rodas",
      "fecha_ingreso": "2022-12-09 11:43:38.877265",
      "ultima_fecha": 1670607891
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6220509967992933": {
    "atributos": {
      "Correo": "Leslyblanco462@gmail.com",
      "Gestion_numero": "32629484",
      "Gestion_problema": "Quiero solicitar una patente nueva a esta persona, pero me tira que ya esta registrada, el es mi esposo, pero quisiera saber si no la puedo sacara mi nombre mejor, para mas rapido xq me urge la patente para esta semana",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Lesly Blanco",
      "Telefono": "48283533",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6220509967992933&width=1024&ext=1684947038&hash=AeTgTaHa9Li2U_iPk3E",
      "fb_username": "Lezly Jimena BM",
      "fecha_ingreso": "2023-04-24 10:21:07.937712",
      "ultima_fecha": 1682355038
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6220697208042914": {
    "atributos": {
      "Gestion_numero": "Necesito saber cómo se llena el formulario de clausura de negocio",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6220697208042914&width=1024&ext=1694277938&hash=AeTJmiKd8DD4QvilLZs",
      "fb_username": "Contab Audito",
      "fecha_ingreso": "2023-04-25 14:01:32.747816",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1691685939
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "6221815541255732": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6221815541255732&width=1024&ext=1699284351&hash=AeS2sb6YTPkTkBA5ZkE",
      "fb_username": "Isaias Tzoy",
      "fecha_ingreso": "2023-10-07 09:24:56.945664",
      "ultima_fecha": 1696692352
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6222276917824647": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-6222276917824647",
      "fecha_ingreso": "2023-02-08 14:33:52.614051"
    }
  },
  "6225773834154438": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6225773834154438&width=1024&ext=1678558809&hash=AeSAS0O-IehCHmpwI6A",
      "fb_username": "Josué Del Cid",
      "fecha_ingreso": "2023-02-09 11:16:27.364696",
      "ultima_fecha": 1675963362
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6227647940579566": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6227647940579566&width=1024&ext=1676758273&hash=AeRcBKBWGnSMCgoqe50",
      "fb_username": "Priscila Per",
      "fecha_ingreso": "2023-01-19 15:14:49.905010",
      "ultima_fecha": 1674163000
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6228467183947846": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6228467183947846&width=1024&ext=1696209398&hash=AeTi9Ns3MCp0IVlEiWs",
      "fb_username": "Fernanda Villeda",
      "fecha_ingreso": "2023-09-01 19:07:07.150954",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1693617400
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "6229006110500517": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6229006110500517&width=1024&ext=1683946436&hash=AeQ7fH8t2EyrQ5q0BXM",
      "fb_username": "Yadi Villagra",
      "fecha_ingreso": "2023-04-12 19:49:18.767493",
      "intentos": 1,
      "ultima_fecha": 1681350568
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6231239476961265": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6231239476961265&width=1024&ext=1685815334&hash=AeR8mIxV4CVJHc_AA0Y",
      "fb_username": "Isabel Mejia",
      "fecha_ingreso": "2023-05-04 11:59:35.069755",
      "intentos": 2,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1683223338
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6231613940224841": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6231613940224841&width=1024&ext=1678893601&hash=AeRZT7l77OjEg86qAYo",
      "fb_username": "Gómez Ochoa",
      "fecha_ingreso": "2023-02-13 07:33:53.522426",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1676298121
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6231617450229526": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6231617450229526&width=1024&ext=1682535996&hash=AeQddcebXCwDlpvgmUo",
      "fb_username": "Madelen Morales",
      "fecha_ingreso": "2023-03-27 11:45:58.309722",
      "intentos": 0,
      "ultima_fecha": 1679940223
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6232647260128845": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6232647260128845&width=1024&ext=1678478618&hash=AeSN2X8yz3iKqj1IYTM",
      "fb_username": "GA Construing",
      "fecha_ingreso": "2023-02-08 13:05:09.446892",
      "ultima_fecha": 1675883180
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6232887640140902": {
    "atributos": {
      "Correo": "Olivarustrianadelaida@gmail.com",
      "Gestion_numero": "Patente",
      "Gestion_problema": "Extravío de patente de comercio",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Adelaida Oliva",
      "Telefono": "53202408",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6232887640140902&width=1024&ext=1690493324&hash=AeRNduqxLvdxKRLYzzg",
      "fb_username": "OR Mar",
      "fecha_ingreso": "2023-06-26 14:56:39.000620",
      "ultima_fecha": 1687901324
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6234807603281938": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6234807603281938&width=1024&ext=1691342239&hash=AeSDf78C43CIV9cFC2o",
      "fb_username": "Rudy Najera",
      "fecha_ingreso": "2023-07-07 11:17:19.577537"
    }
  },
  "6239513742780709": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6239513742780709&width=1024&ext=1684706109&hash=AeQnnIa393AM1fi0Vts",
      "fb_username": "Lopez Jeniifer",
      "fecha_ingreso": "2023-04-21 15:54:33.108993",
      "ultima_fecha": 1682114110
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6240706125965827": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6240706125965827&width=1024&ext=1689779758&hash=AeRnJlDtQwLbfklL-kI",
      "fb_username": "Jonatan Chojolan",
      "fecha_ingreso": "2023-06-19 09:15:03.025450",
      "ultima_fecha": 1687187762
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "6241452605900775": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6241452605900775&width=1024&ext=1684699186&hash=AeSgpxmEZ5_xuaG0Z2A",
      "fb_username": "Hélio Araujo",
      "fecha_ingreso": "2023-04-21 13:59:46.186906",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1682107191
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6242051782492279": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6242051782492279&width=1024&ext=1676735291&hash=AeRVKz7vz6xFH1W-EFQ",
      "fb_username": "Mouses Mant",
      "fecha_ingreso": "2023-01-19 08:38:20.540191",
      "ultima_fecha": 1674140022
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6247159882045316": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6247159882045316&width=1024&ext=1688528069&hash=AeToOrH1aMngumOEkk4",
      "fb_username": "Wilson Sisimith",
      "fecha_ingreso": "2023-06-04 21:22:31.585591",
      "intentos": 3,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1685936059
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6247678325245064": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6247678325245064&width=1024&ext=1676049007&hash=AeSwNGAciprySe_vE38",
      "fb_username": "Sindi Velasquez",
      "fecha_ingreso": "2023-01-11 10:15:16.771636",
      "ultima_fecha": 1673453799
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6248413531869134": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6248413531869134&width=1024&ext=1684441249&hash=AeQ5FzUBYnkWycNOZfA",
      "fb_username": "Yolanda Escobar",
      "fecha_ingreso": "2023-04-18 14:12:16.734758",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681849251
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6249370591749381": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6249370591749381&width=1024&ext=1680195036&hash=AeSD7Jcm9StPR6liybk",
      "fb_username": "Luna Arango",
      "fecha_ingreso": "2023-02-09 14:56:16.798884",
      "intentos": 1,
      "ultima_fecha": 1677599431
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "6256472364430380": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6256472364430380&width=1024&ext=1684601137&hash=AeTwqMt2CgsARHU2ySk",
      "fb_username": "Horacio Monzón",
      "fecha_ingreso": "2023-04-14 14:15:58.470675",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1682009139
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6256890637669078": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6256890637669078&width=1024&ext=1678466613&hash=AeTTqUByr64dXa4ifiY",
      "fb_username": "William Palma",
      "fecha_ingreso": "2023-02-08 09:44:06.473134",
      "ultima_fecha": 1675871174
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6257074884412306": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6257074884412306&width=1024&ext=1691859684&hash=AeTTVa8ICnZ9Xucy7E8",
      "fb_username": "Hugo Herrera",
      "fecha_ingreso": "2023-07-13 11:01:24.498508",
      "intentos": 1,
      "ultima_fecha": 1689267690
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6260403740637350": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6260403740637350&width=1024&ext=1677507126&hash=AeTsZ27A_tlqkJX5t0c",
      "fb_username": "Arc Sstore",
      "fecha_ingreso": "2023-01-23 11:36:28.931152",
      "ultima_fecha": 1674911782
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6260803520618289": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6260803520618289&width=1024&ext=1678482038&hash=AeQH2UkboZeBi0CYWl4",
      "fb_username": "Alfredo Romero",
      "fecha_ingreso": "2023-02-08 14:03:16.188248",
      "ultima_fecha": 1675886599
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6267747586592963": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6267747586592963&width=1024&ext=1679514563&hash=AeR428Kq1soE_z1uY2A",
      "fb_username": "Keyssi Palacios",
      "fecha_ingreso": "2023-02-20 12:49:02.030004",
      "ultima_fecha": 1676919021
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6269598579783131": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6269598579783131&width=1024&ext=1685913502&hash=AeR3RpzPQe-Pt37t_MY",
      "fb_username": "MP Mojarra",
      "fecha_ingreso": "2023-05-05 15:17:41.651835",
      "intentos": 0,
      "ultima_fecha": 1683321505
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6270436962992420": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6270436962992420&width=1024&ext=1690640355&hash=AeQN1FLks1IpLP438uQ",
      "fb_username": "N Iván Jimenéz",
      "fecha_ingreso": "2023-06-29 08:18:59.355237",
      "intentos": 1,
      "ultima_fecha": 1688048347
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6275960335781337": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6275960335781337&width=1024&ext=1680205762&hash=AeSO2YyqVh6Y6AADKG0",
      "fb_username": "Ligia Nohemi Hernández Baldetti",
      "fecha_ingreso": "2023-02-28 12:43:09.916827",
      "intentos": 2,
      "ultima_fecha": 1677610156
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6276198109067205": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHP5JBdbzL7u2ZmBD-xTY5i12_0vvpRNOb1MnmVxuiSuBi8G6yPwrdJrUwX272DUL_qLJrden7I&psid=6276198109067205&width=1024&ext=1705519236&hash=AfoBoRb9FYOZ8XGzlzgqVfNRihnCe2HFgzLVI5K1nZLVbQ",
      "fb_username": "Yeni Lara",
      "fecha_ingreso": "2023-05-05 09:43:37.052504",
      "ultima_fecha": 1702927238
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6278913895501694": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6278913895501694&width=1024&ext=1687468130&hash=AeRcm49togzU25TXJgk",
      "fb_username": "Susana Lopez",
      "fecha_ingreso": "2023-05-23 15:07:29.218649",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1684876133
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6282354761867581": {
    "atributos": {
      "Correo": "Asesor",
      "Gestion_numero": "Asesor",
      "Gestion_problema": "Asesor",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Asesor",
      "Telefono": "Asesor",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFqDvFW6C-f0kG_lbQZP8yLzgZxanZQs7U4uUYTeoinZawt4fkko-lb5BGSG1wG7VbN1qTCGLTo&psid=6282354761867581&width=1024&ext=1713985355&hash=AfreAPi6qXyTC2wdPsBoubAunKc2eaIGMyw3wzZzsqbbeA",
      "fb_username": "Paiz Jhon",
      "fecha_chatcenter": 1706143019579,
      "fecha_ingreso": "2024-01-24 18:34:59.780813",
      "leido": false,
      "log_chatcenter": "-Noy268p1K6WprPnEPbo",
      "msg_noleido": 0,
      "ultima_fecha": 1711393355
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "6283596511736016": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6283596511736016&width=1024&ext=1692287861&hash=AeTYhCltprZnT1qn4c0",
      "fb_username": "Abïï Rôdäs",
      "fecha_ingreso": "2023-07-18 09:57:41.259268",
      "intentos": 1,
      "ultima_fecha": 1689695866
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6284079195044924": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6284079195044924&width=1024&ext=1691179185&hash=AeQq6jCGJ595gPObZlA",
      "fb_username": "Ligia Trejo",
      "fecha_ingreso": "2023-07-05 13:59:08.432479",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1688587189
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "6284687971565134": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6284687971565134&width=1024&ext=1675549520&hash=AeSBRcNkrtkpFTE-D8s",
      "fb_username": "Ivan Tresketeccio",
      "fecha_ingreso": "2023-01-05 12:21:59.791863",
      "ultima_fecha": 1672954354
    },
    "user_posicion": {
      "bloque": "Guias",
      "posicion": 1
    }
  },
  "6287835761268716": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6287835761268716&width=1024&ext=1680379064&hash=AeS1yHUX-RHY-UUbHWU",
      "fb_username": "Horacio Morales",
      "fecha_ingreso": "2023-03-02 12:56:23.447048",
      "intentos": 0,
      "ultima_fecha": 1677783438
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "6291532280974147": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6291532280974147&width=1024&ext=1690589781&hash=AeTJPm4UA49TBjRC5Qs",
      "fb_username": "Omar Vinicio",
      "fecha_ingreso": "2023-06-28 18:16:21.772037",
      "ultima_fecha": 1687997787
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6298878916863630": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6298878916863630&width=1024&ext=1692389377&hash=AeS3AvlhQiBaojxQpEA",
      "fb_username": "Hamilton Sutuj",
      "fecha_ingreso": "2023-07-19 14:09:00.970107",
      "intentos": 1,
      "ultima_fecha": 1689797380
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6301273089981430": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6301273089981430&width=1024&ext=1697995123&hash=AeSedtwjaWvNcQiVZfY",
      "fb_username": "Byron Pinto",
      "fecha_ingreso": "2023-09-22 11:14:54.538370",
      "ultima_fecha": 1695403126
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6301675703224742": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6301675703224742&width=1024&ext=1684959135&hash=AeQvTD8UH_0jd3L3lHM",
      "fb_username": "Tomasita Turuy",
      "fecha_ingreso": "2023-04-24 14:10:46.748937",
      "intentos": 0,
      "ultima_fecha": 1682367138
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6305118329507536": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6305118329507536&width=1024&ext=1681505590&hash=AeR93Dck-u8AlPlVh7U",
      "fb_username": "Kevin Alexander",
      "fecha_ingreso": "2023-03-15 13:49:28.797129",
      "leido": true,
      "msg_noleido": 0,
      "ultima_fecha": 1678909889
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6305186409550676": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6305186409550676&width=1024&ext=1694625638&hash=AeSEoNUUA5tJgknBhmo",
      "fb_username": "David Ajuchan",
      "fecha_ingreso": "2023-08-14 11:19:36.617910",
      "ultima_fecha": 1692033639
    },
    "user_posicion": {
      "bloque": "MandatoInscripcion",
      "posicion": 1
    }
  },
  "6310021515748225": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6310021515748225&width=1024&ext=1691880871&hash=AeTRGEA-zp8qp-Bk8Ss",
      "fb_username": "Glenda Pérez Castillo",
      "fecha_ingreso": "2023-07-13 16:54:31.441233",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1689288876
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6313816001965776": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6313816001965776&width=1024&ext=1674148972&hash=AeRFEjMe9l4QvY9ARWo",
      "fb_username": "Yimi Sandoval",
      "fecha_ingreso": "2022-12-20 10:31:27.462105",
      "ultima_fecha": 1671553923
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6315042881863226": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6315042881863226&width=1024&ext=1678565852&hash=AeS7rY2JTjdyN3iXRo8",
      "fb_username": "Brillit Cipriano Ovalle",
      "fecha_ingreso": "2023-02-09 13:13:05.489519",
      "ultima_fecha": 1675970403
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6315718675197272": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFB20ecSeGyoSf-YuZvrpT6Sn2sp-o6KwDZyKQ75pVNivHGMNkHLivl2nqVzG6BvIb4rmqJvSdp&psid=6315718675197272&width=1024&ext=1710434869&hash=AfoNSeTxWIUW3N_ToEfMZTeg2EPYG4ujlvhR2RWhq7NxFg",
      "fb_username": "Wallid Kawanah",
      "fecha_ingreso": "2024-02-09 11:17:28.101568",
      "intentos": 0,
      "ultima_fecha": 1707842870
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6316366765092895": {
    "atributos": {
      "Gestion_numero": "Electrónico",
      "Gestion_problema": "No, tengo otra duda",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6316366765092895&width=1024&ext=1683214561&hash=AeRLSf6Q5AzqgP15V84",
      "fb_username": "Andy Voph",
      "fecha_ingreso": "2023-04-04 08:25:23.946440",
      "ultima_fecha": 1680618737
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "6317811168270802": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6317811168270802&width=1024&ext=1678470853&hash=AeS1piltVo05i7YwUSU",
      "fb_username": "Julio Barahona",
      "fecha_ingreso": "2023-02-08 10:55:55.122530",
      "ultima_fecha": 1675875414
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6318296274875478": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6318296274875478&width=1024&ext=1685409247&hash=AeR8tjXPOI0Cl9Y_Q54",
      "fb_username": "Amelia Fonseca",
      "fecha_ingreso": "2023-04-29 19:14:07.201218",
      "intentos": 1,
      "ultima_fecha": 1682817254
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6322808481071530": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6322808481071530&width=1024&ext=1680191581&hash=AeS2PV-Bzh8F03tQZ14",
      "fb_username": "Ana Velez",
      "fecha_ingreso": "2023-02-28 08:50:24.120437",
      "intentos": 0,
      "ultima_fecha": 1677595973
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6323426021041559": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-6323426021041559",
      "fecha_ingreso": "2023-04-05 11:23:21.496110",
      "intentos": 0,
      "ultima_fecha": 1680715404
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6323796537740843": {
    "atributos": {
      "Correo": "Morenoomurga@gmail.con",
      "Gestion_numero": "No tengo número",
      "Gestion_problema": "Quería saber cómo inscribir una empresa con 2 propietarios",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Stefanny murga",
      "Telefono": "49743433",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6323796537740843&width=1024&ext=1691698462&hash=AeQQ-EOpHSNwrUNU2b4",
      "fb_username": "Stefanny Moreno Murga",
      "fecha_ingreso": "2023-07-11 13:44:31.840444",
      "ultima_fecha": 1689106464
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6324867340902138": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6324867340902138&width=1024&ext=1699638946&hash=AeQ9064RKDo6kU27w_k",
      "fb_username": "Silvia Chavez",
      "fecha_ingreso": "2023-07-13 15:14:13.090785",
      "intentos": 0,
      "ultima_fecha": 1697046949
    },
    "user_posicion": {
      "bloque": "SociedadMercantil",
      "posicion": 1
    }
  },
  "6328062553906256": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6328062553906256&width=1024&ext=1693343816&hash=AeS8gwgr8gMX7FdtiPw",
      "fb_username": "Chely Revolorio",
      "fecha_ingreso": "2023-07-12 07:18:56.593497",
      "intentos": 0,
      "ultima_fecha": 1690751819
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "6333630136687793": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGFfRWR2G7MvWCdG8zf-mFeLPOfrPujIEoB4vdVDWckUznvlhabPuCWauCH2TeZGOS4jbmK-bl0&psid=6333630136687793&width=1024&ext=1705170904&hash=AfqnHAZ0z1kpcOv6rkoYUJCFTP9-1TGWoy68uN_ZsdnKLA",
      "fb_username": "Mario Edy López",
      "fecha_ingreso": "2023-01-20 12:49:30.065849",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1702578906
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6334663409887310": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6334663409887310&width=1024&ext=1686203163&hash=AeQVmDXFOo5aYFLtnP8",
      "fb_username": "León Alexander",
      "fecha_ingreso": "2023-05-08 23:43:31.474591",
      "intentos": 0,
      "ultima_fecha": 1683611164
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 3
    }
  },
  "6341359355910208": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6341359355910208&width=1024&ext=1686522909&hash=AeRNBjio1LQ75BXHE3s",
      "fb_username": "Brandon Osorio",
      "fecha_ingreso": "2023-05-12 16:34:52.826625",
      "ultima_fecha": 1683930911
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6346174685407351": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6346174685407351&width=1024&ext=1681568999&hash=AeQ0Ul3w2Aq6NIGiKG8",
      "fb_username": "Emanuel Andres",
      "fecha_ingreso": "2023-03-16 07:27:01.351435",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1678973295
    },
    "user_posicion": {
      "bloque": "ReposicionPatenteSociedad",
      "posicion": 1
    }
  },
  "6346507502118952": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGAvCj_Tbrj5shkyguwLV36tuRxs5e4vugCsETQA9psxyC_DGCN9VSVkS3pwsYB-lWlv79bg9aL&psid=6346507502118952&width=1024&ext=1708527812&hash=AfoYsY8LH8KQRqHq19taeL2hg0heJxm-EBF2O8xIkEBEvA",
      "fb_username": "Carlos Valle",
      "fecha_ingreso": "2024-01-22 09:01:16.424798",
      "intentos": 1,
      "ultima_fecha": 1705935815
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6350584511643131": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6350584511643131&width=1024&ext=1678298110&hash=AeRY7l8qkB2Jap9YbRc",
      "fb_username": "Aleja Sandoval",
      "fecha_ingreso": "2023-02-06 10:57:00.392310",
      "ultima_fecha": 1675702688
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6352846081495702": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6352846081495702&width=1024&ext=1693761196&hash=AeRjiRuyA_qCa7XSphs",
      "fb_username": "Javier Monroy",
      "fecha_ingreso": "2023-08-04 11:12:33.759377",
      "ultima_fecha": 1691169197
    },
    "user_posicion": {
      "bloque": "CambioDireccionFiscalEmpresa",
      "posicion": 1
    }
  },
  "6360028690707519": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6360028690707519&width=1024&ext=1684440412&hash=AeTjuKNuLTWxCcvQP3k",
      "fb_username": "Sucely Ortiz",
      "fecha_ingreso": "2023-04-18 10:33:05.315631",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681848414
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6361010523929646": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEsdZQ_kZa8ptlOx5SJihfL0HTpoX1gMTJpmMd5P85xS8Ygw5lsqsh-6EB3wCMgXRnrgZXgQywx&psid=6361010523929646&width=1024&ext=1703345118&hash=AeTzVNkp24rKqktB0Gs",
      "fb_username": "Alejandra Sil",
      "fecha_ingreso": "2023-03-27 07:35:45.025144",
      "intentos": 0,
      "ultima_fecha": 1700753120
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6361767543833750": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6361767543833750&width=1024&ext=1680876337&hash=AeT6hykrR3PmMbSnksY",
      "fb_username": "Luisa Martinez",
      "fecha_ingreso": "2023-03-08 07:03:57.109526",
      "ultima_fecha": 1678280679
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6361886893845759": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6361886893845759&width=1024&ext=1680976960&hash=AeTFNGwYPlPhUShAFRo",
      "fb_username": "Froilan Castellanos",
      "fecha_ingreso": "2023-03-09 11:00:34.435158",
      "intentos": 1,
      "ultima_fecha": 1678381300
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6362398090448479": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6362398090448479&width=1024&ext=1687028592&hash=AeScoZtrh3Ip9Sk1fh8",
      "fb_username": "Ana Silvia Pecks",
      "fecha_ingreso": "2023-05-18 13:02:38.937948",
      "ultima_fecha": 1684436595
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6362776490440003": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6362776490440003&width=1024&ext=1683995843&hash=AeR-PizVkXQFpuprZDg",
      "fb_username": "Fam Santamaria Valdez",
      "fecha_ingreso": "2023-04-13 09:32:41.851886",
      "ultima_fecha": 1681399964
    },
    "user_posicion": {
      "bloque": "InscripcionSociedad",
      "posicion": 1
    }
  },
  "6364667093651309": {
    "atributos": {
      "Gestion_numero": "77718-2023",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6364667093651309&width=1024&ext=1694627792&hash=AeR0MZLm41-XTog4Tkg",
      "fb_username": "Efren Gonzalez",
      "fecha_ingreso": "2023-08-14 11:55:34.883567",
      "intentos": 0,
      "ultima_fecha": 1692035793
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "6365432893469143": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6365432893469143&width=1024&ext=1677887565&hash=AeRZpMHzTxNAizE_-FM",
      "fb_username": "Abraham Girón",
      "fecha_ingreso": "2023-02-01 16:55:41.418333",
      "ultima_fecha": 1675292182
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6366540870107051": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6366540870107051&width=1024&ext=1691104991&hash=AeRAfUoK1Kay7FhxWOs",
      "fb_username": "Sebas Col",
      "fecha_ingreso": "2023-07-04 16:03:32.678426",
      "ultima_fecha": 1688512993
    },
    "user_posicion": {
      "bloque": "Descargadoc",
      "posicion": 2
    }
  },
  "6367379390025191": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6367379390025191&width=1024&ext=1693704352&hash=AeRdppbC-SsjHq85GWU",
      "fb_username": "Johana Huertas Arredondo",
      "fecha_ingreso": "2023-08-03 19:24:47.168935",
      "ultima_fecha": 1691112353
    },
    "user_posicion": {
      "bloque": "Descargadoc",
      "posicion": 2
    }
  },
  "6368261489871999": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6368261489871999&width=1024&ext=1683731641&hash=AeQ7tCUjfZD3xfEoG44",
      "fb_username": "Manuel Salguero",
      "fecha_ingreso": "2023-04-10 07:59:03.095949",
      "intentos": 3,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681135782
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6368580143254257": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6368580143254257&width=1024&ext=1699647928&hash=AeSyygGszDHCHjrOgjU",
      "fb_username": "Ele Rivera",
      "fecha_ingreso": "2023-10-11 14:21:35.891052",
      "intentos": 0,
      "ultima_fecha": 1697055932
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6373790669416766": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6373790669416766&width=1024&ext=1695994715&hash=AeTi0vtFq1fZtpmsdNU",
      "fb_username": "Axel TziánGt",
      "fecha_ingreso": "2023-08-30 07:37:48.850333",
      "ultima_fecha": 1693402717
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6380314758693237": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6380314758693237&width=1024&ext=1690146811&hash=AeRTkNNoGYpvoHRTwRM",
      "fb_username": "Mayra GMayra",
      "fecha_ingreso": "2023-06-23 15:06:19.656539",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1687554814
    },
    "user_posicion": {
      "bloque": "Anotaciones",
      "posicion": 1
    }
  },
  "6381654155294335": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHVqqGf5nL_0q72PMw3Fx7nCUqb8duyDdAih8YE75dlc71u2WN-H1Ny11qoxrm6pJWZkd6CkYFJ&psid=6381654155294335&width=1024&ext=1715362058&hash=Afq0LfJpG3DPN4MIGCT-Y6mqqzOs4OJg8diACaf3rl9nMg",
      "fb_username": "Elii Gabriiel",
      "fecha_ingreso": "2023-10-23 12:15:14.617933",
      "intentos": 1,
      "ultima_fecha": 1712770060
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6382317551852114": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6382317551852114&width=1024&ext=1693171845&hash=AeS6EQFkLKJGCZGzBUY",
      "fb_username": "Gartzea Mayle",
      "fecha_ingreso": "2023-07-28 15:30:45.347149",
      "ultima_fecha": 1690579849
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6384080508295615": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6384080508295615&width=1024&ext=1689181590&hash=AeR7HPfSNaMZTFEnNh0",
      "fb_username": "La Luna De Enero",
      "fecha_ingreso": "2023-06-12 10:54:54.407546",
      "ultima_fecha": 1686589592
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6384431238252883": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6384431238252883&width=1024&ext=1676352033&hash=AeSmWJyusX1j4czTdO0",
      "fb_username": "Noemí Gómez",
      "fecha_ingreso": "2023-01-12 13:15:12.980258",
      "ultima_fecha": 1673756799
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6390679047642314": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6390679047642314&width=1024&ext=1687710349&hash=AeQ2nAnb2U0kRsHTBdc",
      "fb_username": "Paola Barrios",
      "fecha_ingreso": "2023-05-26 10:24:51.787682",
      "intentos": 0,
      "ultima_fecha": 1685118354
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6391491737551368": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6391491737551368&width=1024&ext=1677423698&hash=AeQk4_trko8-48kI_rg",
      "fb_username": "Pastores Palencia Berganza",
      "fecha_ingreso": "2023-01-27 07:51:04.306198",
      "ultima_fecha": 1674828360
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6391511060882277": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6391511060882277&width=1024&ext=1680295140&hash=AeSgy3_d_AvVpssrQz0",
      "fb_username": "Walter Guerra",
      "fecha_ingreso": "2023-03-01 13:36:36.594086",
      "ultima_fecha": 1677699523
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6391838620860333": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6391838620860333&width=1024&ext=1684972568&hash=AeQSKau7a46qa457GkM",
      "fb_username": "Eugenio Romero",
      "fecha_ingreso": "2023-04-24 17:56:08.936530",
      "intentos": 1,
      "ultima_fecha": 1682380576
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6393076184139236": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6393076184139236&width=1024&ext=1694295203&hash=AeQKY1F-QyAM6ohSiw4",
      "fb_username": "JC Josehp",
      "fecha_ingreso": "2023-08-10 15:27:17.478784",
      "ultima_fecha": 1691703205
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6393095107387195": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6393095107387195&width=1024&ext=1677694677&hash=AeR_Ast1RFp1ofRuTvc",
      "fb_username": "Mayk Her",
      "fecha_ingreso": "2023-01-30 11:17:54.908431",
      "ultima_fecha": 1675099314
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6395645940542910": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6395645940542910&width=1024&ext=1694646418&hash=AeQVTk9JeqcYpJ0Skso",
      "fb_username": "Agapito Díaz",
      "fecha_ingreso": "2023-08-14 17:06:25.432295",
      "ultima_fecha": 1692054420
    },
    "user_posicion": {
      "bloque": "CambioNombreComercial",
      "posicion": 1
    }
  },
  "6397403253603770": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6397403253603770&width=1024&ext=1676832855&hash=AeQKBiO8ZaGSoXbKHb0",
      "fb_username": "Sandra Patricia Mirón",
      "fecha_ingreso": "2023-01-20 11:52:06.929967",
      "ultima_fecha": 1674237574
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6397844676945175": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6397844676945175&width=1024&ext=1685382519&hash=AeQLC_yuH8gqvM-l01k",
      "fb_username": "Corina Pérez",
      "fecha_ingreso": "2023-04-29 11:48:39.922587",
      "intentos": 1,
      "ultima_fecha": 1682790527
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6398541563490648": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6398541563490648&width=1024&ext=1688674910&hash=AeST0iriGArWBinyMQM",
      "fb_username": "Martinez Lex",
      "fecha_ingreso": "2023-02-22 11:13:04.781544",
      "ultima_fecha": 1686082913
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6399129156864865": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-6399129156864865",
      "fecha_ingreso": "2023-09-19 07:55:11.867941",
      "ultima_fecha": 1695131713
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "6413002122150599": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6413002122150599&width=1024&ext=1694620116&hash=AeQXLm2rCU44TeXiZqk",
      "fb_username": "Marlyn Yulissa González",
      "fecha_ingreso": "2023-08-14 09:48:18.411331",
      "intentos": 0,
      "ultima_fecha": 1692028118
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6413329605410481": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6413329605410481&width=1024&ext=1691956455&hash=AeQ4Z6TrEQvFhQ1xY6M",
      "fb_username": "Leonel Ramírez",
      "fecha_ingreso": "2023-07-14 13:54:15.523745",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1689364457
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6414938631938303": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGqyB7yFRoJTye4PixUfgjVa9i_ypiTK-XixpJHoL7EsLFYPKrolmLhToF1imfhL24-fm7-KjxB&psid=6414938631938303&width=1024&ext=1714661373&hash=Afo_WcQj9l10uzYAxA4Qpd7ep475BAIFg37kzhXvqYJYqQ",
      "fb_username": "Mónica Guzmán",
      "fecha_ingreso": "2023-08-22 10:18:43.529394",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1712069375
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6419922761430943": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6419922761430943&width=1024&ext=1692455479&hash=AeSt_eETewtgb9hXGaw",
      "fb_username": "Fernando Alejandro Dieguez",
      "fecha_ingreso": "2023-07-20 08:30:06.528984",
      "intentos": 0,
      "ultima_fecha": 1689863483
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6420202124742966": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6420202124742966&width=1024&ext=1692464748&hash=AeQGY9F7s3HAZboW3YU",
      "fb_username": "Marco Castro Vasquez",
      "fecha_ingreso": "2023-07-20 11:05:01.129948",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1689872751
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6420396414730453": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFSg1WEAvuMOfFAkVoS5GIgCJ7qdqWqNN-tlSHsGzFJ2nxrnk_QTD0-5GGse_ECJZnaPClWwSIr&psid=6420396414730453&width=1024&ext=1708562144&hash=AfrkzBbLWsgkba0yEw7lI3Nk3KuePwmup_R92KuKhn4-ww",
      "fb_username": "López Claudia",
      "fecha_ingreso": "2024-01-22 18:32:41.739295",
      "intentos": 0,
      "ultima_fecha": 1705970146
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6421511151280928": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6421511151280928&width=1024&ext=1696693383&hash=AeSOPoHlwmR7Kl_B0_U",
      "fb_username": "Erick Alvarado",
      "fecha_ingreso": "2023-08-03 11:06:38.250653",
      "ultima_fecha": 1694101385
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "6422419627834027": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6422419627834027&width=1024&ext=1697297707&hash=AeRfeDSgtMQpi4wlTRg",
      "fb_username": "Henry Iquite",
      "fecha_ingreso": "2023-09-14 09:26:05.057421",
      "intentos": 0,
      "ultima_fecha": 1694705708
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6429658913787673": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6429658913787673&width=1024&ext=1694610297&hash=AeTqJ3I4-5c477z3obQ",
      "fb_username": "Jose A. Pereira",
      "fecha_ingreso": "2023-08-14 07:04:58.031855",
      "intentos": 1,
      "ultima_fecha": 1692018305
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6433447903355021": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6433447903355021&width=1024&ext=1681079195&hash=AeR4Q9ijhPWWSCc-JGk",
      "fb_username": "Franklin Pelaez Breganza",
      "fecha_ingreso": "2023-03-10 15:24:20.413811",
      "ultima_fecha": 1678483529
    },
    "user_posicion": {
      "bloque": "Descargadoc",
      "posicion": 3
    }
  },
  "6433826599983465": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF8s5oMpR0SDxHZ9yXrj8fxQiO0dEYL4UHON5dWwDldwa8uX11Akh4mghQjUwVTaMNbI1gYk5IQ&psid=6433826599983465&width=1024&ext=1700243604&hash=AeQXccYHp1uTdGJLvkY",
      "fb_username": "Polet Franco",
      "fecha_ingreso": "2023-03-29 08:10:00.823225",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1697651608
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6434825309895205": {
    "atributos": {
      "Gestion_numero": "4167-2019",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGtbOqvSFajgC9YYt09vHLN1kyRhasl7SKwe2bO5h191LmiWdDbgoP7roVqZuI5MdnfN9KCKx8_&psid=6434825309895205&width=1024&ext=1705245327&hash=Afqx-iC56_zv_knEOdPIVwSvL3LsTyA1dszsSdZol5X-cw",
      "fb_username": "Emily Mulul",
      "fecha_ingreso": "2023-05-04 15:58:53.349902",
      "intentos": 0,
      "leido": true,
      "msg_noleido": 0,
      "ultima_fecha": 1702653328
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "6435023113197035": {
    "atributos": {
      "fb_picture": "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=hF2ymDha0pMAX9mTRik&_nc_ht=scontent-dfw5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfBZ3v44XqhimAgO8L92tUwVaD7_xao2xvKlgBRMWpVjig&oe=6443DE59",
      "fb_username": "Jose Miguel Garcia",
      "fecha_ingreso": "2023-03-23 09:11:20.759377",
      "intentos": 0,
      "ultima_fecha": 1679584361
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "6437239486371339": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6437239486371339&width=1024&ext=1696087755&hash=AeTKI2mZaOPOQiuZ4tY",
      "fb_username": "Karol Gomez",
      "fecha_ingreso": "2023-08-31 09:28:06.611276",
      "ultima_fecha": 1693495757
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6439984376090004": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6439984376090004&width=1024&ext=1693437128&hash=AeTi4lyZRWofxJ1LLPo",
      "fb_username": "Rocka Perez Gonzales",
      "fecha_ingreso": "2023-07-31 17:11:40.712944",
      "intentos": 0,
      "ultima_fecha": 1690845130
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6441907412582586": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFKNyrp8GI0qlTPFabxmXzFv7EDRc6trLsK7FnYyCjJ802vojBYREGbb1i8fmiTwweOh-rrDX5S&psid=6441907412582586&width=1024&ext=1702222868&hash=AeTvPuAtuBJW97TSCUA",
      "fb_username": "Victor Osorio",
      "fecha_ingreso": "2023-11-10 09:40:51.113460",
      "ultima_fecha": 1699630871
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "6442149122559754": {
    "atributos": {
      "Gestion_tipo": "Buen dia",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF_Mhln58ryAv2aNn_4ZZLKSC7F3OBRTNtpC0uziFlLwRLMhzIwN6z_1Iypqq3IG2aCJkrNEWlv&psid=6442149122559754&width=1024&ext=1707319091&hash=Afpj57Q9299g6Xe5Afmmq5mC-FvnH2V_feP6Yn3PRu79ag",
      "fb_username": "Pedrito Gonzalez",
      "fecha_ingreso": "2023-08-24 12:19:47.191156",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1704727094
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6444970825590183": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6444970825590183&width=1024&ext=1690666290&hash=AeQSIAGSL8gsj--QpKc",
      "fb_username": "Danery Lux",
      "fecha_ingreso": "2023-06-29 15:31:15.119270",
      "ultima_fecha": 1688074292
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6445441592226478": {
    "atributos": {
      "Gestion_numero": "5",
      "Gestion_problema": "Buena tarde, me podrían apoyar, quisiera saber si el trámite de cambio de dirección en las patentes puede realizarse en línea",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEiy_WEXSprc3zW3XjRs1MBx-7mmYEa3f1R1QrTqSN77xiSFq842TekXeblUrpwfV76IQqL9mA3&psid=6445441592226478&width=1024&ext=1711053862&hash=AfrHjwAuXajN-omiibOEClKkrxAIg_DAEjDVGJpygCTtjA",
      "fb_username": "Pamela Flores",
      "fecha_ingreso": "2023-11-30 10:09:47.460835",
      "intentos": 0,
      "ultima_fecha": 1708461862
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "6445548322211816": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG3xJlJMuvPrF0T3QO_5dh5F61xm6wWQ3DjL5g_6Wiuk-KRN4sEse61eAX6qBagYb5-f73QESYz&psid=6445548322211816&width=1024&ext=1708622730&hash=AfpXuAv67BrXlFG7sV39CdbGqNciiAIIUFdNd3U_Aoj-dw",
      "fb_username": "Pérez C Ligia",
      "fecha_ingreso": "2024-01-23 11:24:48.163774",
      "ultima_fecha": 1706030697
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6449277638474371": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6449277638474371&width=1024&ext=1691942074&hash=AeTxDxIF--veGZKK8eg",
      "fb_username": "Jorge Alberto",
      "fecha_ingreso": "2023-07-14 09:52:02.259529",
      "ultima_fecha": 1689350075
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6449290855136428": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6449290855136428&width=1024&ext=1687205284&hash=AeTNZTU7FB1t4ogRuTs",
      "fb_username": "Helen Pichillá",
      "fecha_ingreso": "2023-05-20 14:07:00.107940",
      "ultima_fecha": 1684613285
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6450096105102183": {
    "atributos": {
      "Correo": "lic.oguerrero@yahoo.com",
      "Gestion_numero": "1004-204169",
      "Gestion_problema": "no pusieron el apellido de casada, en la razon de Auxiliar",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Oscar Arturo Guerrero Garcia",
      "Telefono": "50607447",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHKsJFLC4PevHlDGZOY7J3ZI8fOb_rcK-ZwnWBBCI3YDlv2jDdr5K0Oq6bGTGrSRWKUxojh5VzJ&psid=6450096105102183&width=1024&ext=1714600233&hash=AfoRoU5BEEUX8JoMY1nri1Fx4T2Unh29Fhrvd8ir9HP82g",
      "fb_username": "Arturo Guerrero",
      "fecha_chatcenter": 1712008233986,
      "fecha_ingreso": "2023-11-03 13:31:59.337252",
      "leido": false,
      "log_chatcenter": "-NuQd7Fq3pUWLrIPnWQe",
      "msg_noleido": 0,
      "ultima_fecha": 1712008234
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "6454051504609475": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6454051504609475&width=1024&ext=1676654418&hash=AeR3nqg-NGmiSUbuEXw",
      "fb_username": "Carlos Ariel Maldonado",
      "fecha_ingreso": "2023-01-18 10:25:28.878373",
      "ultima_fecha": 1674059155
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6456039134434082": {
    "atributos": {
      "Correo": "licwernertezo@gmail.con",
      "Gestion_numero": "Necesito hablar con un asistente",
      "Gestion_problema": "Pague una certificación en línea y me aparece transacción no existente al solicitar la certificación",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Werner Tezo",
      "Telefono": "41374620",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6456039134434082&width=1024&ext=1691869763&hash=AeS5_ua06VXQm7XIqJ8",
      "fb_username": "Werner Daniel Tezo Leiva",
      "fecha_ingreso": "2023-07-13 12:14:11.636779",
      "ultima_fecha": 1689277763
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6457431697656056": {
    "atributos": {
      "Correo": "Ftzayc@gmail.com",
      "Gestion_numero": "Aun no tengo tramite",
      "Gestion_problema": "1230",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Heidy evelinda macz",
      "Telefono": "35506090",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6457431697656056&width=1024&ext=1691701922&hash=AeSB6vtzrniKVppUKw0",
      "fb_username": "Fide TC",
      "fecha_ingreso": "2023-07-11 12:56:16.713452",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1689109924
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6463725670339222": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6463725670339222&width=1024&ext=1690416341&hash=AeRFER157hpfbl71wIA",
      "fb_username": "Gerardo Sagui Luc",
      "fecha_ingreso": "2023-03-30 13:26:29.720899",
      "intentos": 0,
      "ultima_fecha": 1687824343
    },
    "user_posicion": {
      "bloque": "SociedadMercantil",
      "posicion": 1
    }
  },
  "6464354706931258": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6464354706931258&width=1024&ext=1679071894&hash=AeRTjj2g86TvIX0ETLU",
      "fb_username": "Mónica Urizar Sosa",
      "fecha_ingreso": "2023-02-15 09:51:45.174778",
      "ultima_fecha": 1676476396
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6468554906527360": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6468554906527360&width=1024&ext=1697667748&hash=AeRpw7zht9spN-9ysb0",
      "fb_username": "Magda Xocoy Velasco",
      "fecha_ingreso": "2023-09-18 16:13:43.112666",
      "ultima_fecha": 1695075749
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "6469924899760682": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6469924899760682&width=1024&ext=1692645413&hash=AeSNUNxKCOFc4NIhw5k",
      "fb_username": "Alex Romero",
      "fecha_ingreso": "2023-07-22 13:06:05.908452",
      "ultima_fecha": 1690053415
    },
    "user_posicion": {
      "bloque": "Descargadoc",
      "posicion": 2
    }
  },
  "6471159286244626": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6471159286244626&width=1024&ext=1677098180&hash=AeTFx9iKF6Hdb8Q3rNw",
      "fb_username": "Angelito Lutín Najera",
      "fecha_ingreso": "2023-01-17 08:21:38.216795",
      "ultima_fecha": 1674502873
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "6475081515951604": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6475081515951604&width=1024&ext=1699738675&hash=AeSyfuuvipHxGMOFA_c",
      "fb_username": "Clara Monterroso",
      "fecha_ingreso": "2023-10-12 14:38:17.156469",
      "ultima_fecha": 1697146677
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6479291722138575": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6479291722138575&width=1024&ext=1688144874&hash=AeQB6kuhMYE15Rdmedg",
      "fb_username": "Dina Maria",
      "fecha_ingreso": "2023-05-31 11:07:29.290337",
      "ultima_fecha": 1685552876
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6481173811974490": {
    "atributos": {
      "Correo": "misa_patricio@hotmail.com",
      "Gestion_numero": "77218-2023",
      "Gestion_problema": "Solicito información del status de patente de comercio",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Misael Patricio",
      "Telefono": "41508078",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHS-043Dao9jBhzIrF_yYotZj7b6HMg67nuuHwreIpuS1JSSy2TgONHPHe-4aiL3051CAPkhVTY&psid=6481173811974490&width=1024&ext=1700240124&hash=AeQ89C89d2zuCvixNN0",
      "fb_username": "Misael Patricio",
      "fecha_ingreso": "2023-08-02 12:32:49.863134",
      "intentos": 0,
      "leido": true,
      "msg_noleido": 0,
      "ultima_fecha": 1697648126
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6484423908243669": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6484423908243669&width=1024&ext=1687705799&hash=AeQ5iDAdOnr_ar1mv5g",
      "fb_username": "Oscar Aceituno",
      "fecha_ingreso": "2023-05-26 09:08:34.235565",
      "ultima_fecha": 1685113800
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "6487028934663692": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6487028934663692&width=1024&ext=1681675396&hash=AeSdf9WyPrvcf5YBRfE",
      "fb_username": "Angely Mayorga",
      "fecha_ingreso": "2023-03-17 13:00:19.070896",
      "intentos": 0,
      "ultima_fecha": 1679079684
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6487666014629431": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6487666014629431&width=1024&ext=1689181130&hash=AeRryxWUKMmEW1D08rI",
      "fb_username": "Mercy Ady Monzón",
      "fecha_ingreso": "2023-06-12 10:56:40.292851",
      "ultima_fecha": 1686589133
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6488770787836238": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6488770787836238&width=1024&ext=1686158300&hash=AeQ3Gk9W58V55H6W518",
      "fb_username": "Dany Salguero",
      "fecha_ingreso": "2023-05-08 11:17:05.884768",
      "ultima_fecha": 1683566302
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6488839387890041": {
    "atributos": {
      "Correo": "Maggy.nelson@gmail.com",
      "Gestion_numero": "Necesito consultar con un representante",
      "Gestion_problema": "Soy Naturópata, Quiropráctico y Acupunturista.. con mi debido reconocimiento del establecimiento INGTA avalado por una entidad del estado.. para poder poner mi consultorio y brindar mis servicios, debo tener patente de comercio?",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Maggy Nelson",
      "Telefono": "41001096",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6488839387890041&width=1024&ext=1692466624&hash=AeS-cK3bNLsxP6OD388",
      "fb_username": "Maggy Gabriela Nelson",
      "fecha_ingreso": "2023-07-20 11:18:16.364118",
      "ultima_fecha": 1689874625
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6499327806762221": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6499327806762221&width=1024&ext=1677351943&hash=AeS_0Dhi0W5evUUXqn8",
      "fb_username": "Edwin Argueta",
      "fecha_ingreso": "2023-01-26 12:09:36.691430",
      "ultima_fecha": 1674756613
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6504706352946672": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6504706352946672&width=1024&ext=1695497460&hash=AeQBTWtgtf9dxnAHCVM",
      "fb_username": "Gramajo Alex",
      "fecha_ingreso": "2023-08-24 11:24:09.778273",
      "ultima_fecha": 1692905464
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6504906849528046": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6504906849528046&width=1024&ext=1687028929&hash=AeSmzFQgAN2baplGO-M",
      "fb_username": "Silvita López",
      "fecha_ingreso": "2023-05-18 13:03:35.091372",
      "ultima_fecha": 1684436931
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6507898822567277": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6507898822567277&width=1024&ext=1683736023&hash=AeR5mbH6iwTRbkmpMLQ",
      "fb_username": "Jaicario Volvo",
      "fecha_ingreso": "2023-04-10 09:20:28.991302",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681140162
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6514282088586432": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6514282088586432&width=1024&ext=1675011825&hash=AeRpLlETTDOvAKktTO4",
      "fb_username": "Victor Hurtado",
      "fecha_ingreso": "2022-12-30 10:07:03.358738",
      "ultima_fecha": 1672416704
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6514375445341785": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6514375445341785&width=1024&ext=1697224857&hash=AeQkRyQVhC6QT1C7baQ",
      "fb_username": "Adis Bala",
      "fecha_ingreso": "2023-09-13 13:19:57.103157",
      "ultima_fecha": 1694632859
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6515850641874045": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFByZ5I8Hc_AhqtrhlJTGHElrbMwaPI5Ql3IQ_wU1JT_p9QWEvJVzi2thF5CUw-a6DYOsY4__BN&psid=6515850641874045&width=1024&ext=1700157973&hash=AeTJgh7Heey9aJVb2N0",
      "fb_username": "Yeilin Arrevillaga",
      "fecha_ingreso": "2023-10-17 12:06:13.495657",
      "ultima_fecha": 1697565976
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6516149105065335": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6516149105065335&width=1024&ext=1678900215&hash=AeTNmYDQxnHCt2-mWA8",
      "fb_username": "Saenz Karla",
      "fecha_ingreso": "2023-02-13 10:11:20.874556",
      "ultima_fecha": 1676304736
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6516570141704008": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6516570141704008&width=1024&ext=1678384668&hash=AeR78a0LrJJPmAnsnPU",
      "fb_username": "Gustavo James",
      "fecha_ingreso": "2023-02-07 10:53:29.622434",
      "ultima_fecha": 1675789239
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6517786878271221": {
    "atributos": {
      "Correo": "Januevosproyectos@gmail.com",
      "Gestion_numero": "018",
      "Gestion_problema": "Formulario rechazado",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Julio Ángel Arango Vicente",
      "Telefono": "54602350",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6517786878271221&width=1024&ext=1696798984&hash=AeRDbck1tAowLRvd4mY",
      "fb_username": "Celeste Picón",
      "fecha_ingreso": "2023-08-28 11:36:05.108637",
      "intentos": 0,
      "ultima_fecha": 1694206985
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6520654341312001": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6520654341312001&width=1024&ext=1686177044&hash=AeQd6CuBnN7pRYcEZh0",
      "fb_username": "Luis Angel Icó",
      "fecha_ingreso": "2023-05-08 16:29:04.283750",
      "intentos": 0,
      "ultima_fecha": 1683585047
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6521051841346869": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6521051841346869&width=1024&ext=1691615172&hash=AeRgYOgKeEX0qixoSQ8",
      "fb_username": "Cynthia Castellanos",
      "fecha_ingreso": "2023-07-10 15:06:02.623460",
      "ultima_fecha": 1689023174
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6521258501265636": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "1",
      "Gestion_problema": "Información de inscripción",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Noelia Licardie",
      "Telefono": "48769490",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6521258501265636&width=1024&ext=1693672664&hash=AeRD8xHzP6IdNHnO4jg",
      "fb_username": "Noli Farfan",
      "fecha_ingreso": "2023-08-03 10:32:18.278742",
      "intentos": 0,
      "ultima_fecha": 1691080665
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6521366187914770": {
    "atributos": {
      "Correo": "maybelilemus@gmail.com",
      "Gestion_numero": "Solo nesesito infoemacion para proceder",
      "Gestion_problema": "Aun no tengo numero",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Lilian Lémus",
      "Telefono": "40872629",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6521366187914770&width=1024&ext=1683730973&hash=AeTMTpKqjLbYGklNrzs",
      "fb_username": "Lilian Lémus de Sagastume",
      "fecha_ingreso": "2023-04-10 07:50:45.006356",
      "ultima_fecha": 1681135111
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6521461851253428": {
    "atributos": {
      "Correo": "rosangelapazportan@gmail.com",
      "Gestion_numero": "14264-1992",
      "Gestion_problema": "Que no le han dado trámite",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "LICENCIADA ROSANGELA PAZ PORTAN",
      "Telefono": "42295907",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6521461851253428&width=1024&ext=1694105718&hash=AeTegOFFTf20uWGlCKk",
      "fb_username": "Rouss Paz",
      "fecha_ingreso": "2023-08-08 10:52:28.700980",
      "ultima_fecha": 1691513718
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6521905514585310": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXElBLxkIQulm2buo0xmiGeowYxd_3HiVYiQY8yy7qST-GiWIk6K4xSLSdpZar0P-HBfx-tz8_HE&psid=6521905514585310&width=1024&ext=1700160070&hash=AeS5ny1hsCXR5fBnzHI",
      "fb_username": "Leonela Orozco",
      "fecha_ingreso": "2023-10-17 12:38:08.027903",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1697568071
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "6522289121148208": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6522289121148208&width=1024&ext=1685652434&hash=AeSxkM2WBezB_wnz4_U",
      "fb_username": "Gunder Orozco",
      "fecha_ingreso": "2023-05-02 14:46:52.212826",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1683060436
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6522983831124769": {
    "atributos": {
      "Correo": "deleonzjc@gmail.com",
      "Gestion_numero": "Registro de empresa y comerciante individual se puede dejar papelería en sede zona 4",
      "Gestion_problema": "Sede zona 4 están recibiendo papelería",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Julio de leon",
      "Telefono": "50132245",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6522983831124769&width=1024&ext=1694015230&hash=AeRd4lnlhy9ue82hYr0",
      "fb_username": "Patty Patty Sánchez",
      "fecha_ingreso": "2023-08-07 09:44:29.357480",
      "ultima_fecha": 1691423231
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6525710124109326": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6525710124109326&width=1024&ext=1678256187&hash=AeSdCmPWPTym86mEOpY",
      "fb_username": "Magali Velazquez",
      "fecha_ingreso": "2023-02-05 23:19:27.197287",
      "ultima_fecha": 1675660771
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6528270483885518": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6528270483885518&width=1024&ext=1692369917&hash=AeRL0-842pVNbarUWaE",
      "fb_username": "Alex Viana",
      "fecha_ingreso": "2023-07-19 08:45:05.885274",
      "ultima_fecha": 1689777920
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6532654820150980": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6532654820150980&width=1024&ext=1694286391&hash=AeQMDqs-NoDRskgLgI0",
      "fb_username": "Jose LC",
      "fecha_ingreso": "2023-08-10 13:03:54.096281",
      "ultima_fecha": 1691694392
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "6534562623288209": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE3xBmsO1PWs89eM1-ktGpYgPUZCdxB7sjkTsRqx4xrDm89_c4bkCapKkGvZRNCgfsDy6PSxvBO&psid=6534562623288209&width=1024&ext=1713112222&hash=AfpaunA4C0HlhIFuIFnJ4thqhy1oHceenZ8igxRTkgtJBw",
      "fb_username": "Emilia Zapeta",
      "fecha_ingreso": "2023-09-27 19:39:30.600152",
      "intentos": 0,
      "ultima_fecha": 1710520224
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6538488286238387": {
    "atributos": {
      "Correo": "Sanatoriosanjuan4@gmail.com",
      "Gestion_numero": "No. 1003-152911",
      "Gestion_problema": "Liberación de boletas",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Dinora García",
      "Telefono": "46154168",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHoZ-6xWUZIY9oYr0-9gc0bPYA4d54KqHR1BVOfMS7LikZr4HFA0agdkn6h_d9j12By4_lawXUn&psid=6538488286238387&width=1024&ext=1703344381&hash=AeTwC0YFIZv6xmZzco8",
      "fb_username": "Nemrac Avilo",
      "fecha_ingreso": "2023-08-22 15:18:41.299976",
      "intentos": 0,
      "ultima_fecha": 1700752381
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6540015049444059": {
    "atributos": {
      "Gestion_tipo": "Es virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6540015049444059&width=1024&ext=1699030984&hash=AeQjyndhUAE3zhuelO8",
      "fb_username": "Pérez Lissi",
      "fecha_ingreso": "2023-10-04 10:51:14.395784",
      "intentos": 1,
      "ultima_fecha": 1696438988
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6544113045695550": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6544113045695550&width=1024&ext=1695940755&hash=AeQpGDfethq90o1UXxY",
      "fb_username": "Paola Hurtarte",
      "fecha_ingreso": "2023-08-29 16:39:04.424523",
      "ultima_fecha": 1693348756
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6545869065519732": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHkHX1WqZTXW9dBERNQECaizRI0WYUZ2Uz-RCexHYEOAH3pnlCqPWdiTojw7nNUdp--GDDU3jDr&psid=6545869065519732&width=1024&ext=1706814570&hash=AfqsgYgfdb2hSKqL2GP8m-dxQqdAkE83K-qH8QaESgih3w",
      "fb_username": "Mely De León",
      "fecha_ingreso": "2024-01-02 13:08:21.381597",
      "intentos": 0,
      "ultima_fecha": 1704222573
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "6547900261978770": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF_j6XMngXIO26UlCQqjDbJsLgAzVI_Vz_8kbVilAohfUpIHtYtvMCgfEKPh2HcTNBaZAJrw42J&psid=6547900261978770&width=1024&ext=1709481334&hash=AfpJG3563y95MLomOH-7RLI0SgXz207Y8GX4imx20OINtA",
      "fb_username": "Alexander Gomez",
      "fecha_ingreso": "2024-02-02 09:54:54.593207",
      "ultima_fecha": 1706889335
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6549252708419296": {
    "atributos": {
      "Correo": "Edlopez9410@gmail.com",
      "Gestion_numero": "Buenos días",
      "Gestion_problema": "Realice una solicitud de certificación, pero aun no recibo respuesta",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Ervin Daniel López Barrios",
      "Telefono": "32634902",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6549252708419296&width=1024&ext=1683993653&hash=AeS3_x1n97NdK4rHUko",
      "fb_username": "Daniel Lopez",
      "fecha_ingreso": "2023-04-11 22:01:23.695383",
      "ultima_fecha": 1681397772
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6549308618431588": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-6549308618431588",
      "fecha_ingreso": "2023-02-24 15:00:18.622985",
      "ultima_fecha": 1677272420
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6553629481384547": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6553629481384547&width=1024&ext=1685819043&hash=AeTfrbbaNlG24m0eX3M",
      "fb_username": "Karla Valdez",
      "fecha_ingreso": "2023-05-04 13:02:48.677212",
      "ultima_fecha": 1683227044
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6563718337011657": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6563718337011657&width=1024&ext=1689182984&hash=AeTmbBeC7FWFdi1_5qY",
      "fb_username": "Allison Martinez",
      "fecha_ingreso": "2023-06-12 11:22:01.426013",
      "ultima_fecha": 1686590985
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6563814287056879": {
    "atributos": {
      "Gestion_tipo": "Hola qué la mucho gusto Irma el motivo de mi msj es para consultar plazas disponibles para poder aplicar 😃",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH5E5ossKkuArdMKYNVHblTwNVsKWPavw8xy7QLMV6gUvpOUxPWtbAnGSMvG1IlbW1RPh6E0bqh&psid=6563814287056879&width=1024&ext=1708887814&hash=Afq6YACD7h3bwoM-TcQzZPRUNnNf-dgbxG61i1F9n1KNAQ",
      "fb_username": "Sandrita Gutierrez Alvizures",
      "fecha_ingreso": "2024-01-25 22:13:01.668303",
      "ultima_fecha": 1706295816
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "6570585653039924": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFUy_R8kaPKunqLCzEVtXsLMefGTmWuSsz-XTTj5_oZN_YuIBG0snzRaGzScMnl9EdMWnwICAx3&psid=6570585653039924&width=1024&ext=1702240988&hash=AeT-ITLPZq9F-97B12w",
      "fb_username": "Maria Flores",
      "fecha_ingreso": "2023-11-10 14:42:47.964794",
      "ultima_fecha": 1699648989
    },
    "user_posicion": {
      "bloque": "Auxiliares_Asamblea_Acciones",
      "posicion": 1
    }
  },
  "6572463249436703": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6572463249436703&width=1024&ext=1677347286&hash=AeQnMyvVQyzhehujhhw",
      "fb_username": "Erick Cifuentes",
      "fecha_ingreso": "2023-01-26 09:28:18.520218",
      "ultima_fecha": 1674751957
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6576816962383048": {
    "atributos": {
      "Gestion_numero": "es para una patente de comercio",
      "Gestion_problema": "Necesito saber cual es codigo o nombre para pagar en linea desde Banrural para sacar una patente de comercio",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Joel Lopez",
      "Telefono": "no tengo por el momento",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6576816962383048&width=1024&ext=1692376802&hash=AeRPj9r-jWMRaf8Cp38",
      "fb_username": "Joel Lopez",
      "fecha_ingreso": "2023-07-19 10:36:35.848801",
      "ultima_fecha": 1689784803
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 6
    }
  },
  "6580426651974750": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6580426651974750&width=1024&ext=1680061093&hash=AeSCMkDkWvgF8uhU6d4",
      "fb_username": "Gesler Vásquez",
      "fecha_ingreso": "2023-02-26 20:38:17.205283",
      "ultima_fecha": 1677465502
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6581623191920225": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6581623191920225&width=1024&ext=1692461209&hash=AeQcxcOj-7nuikuKzzI",
      "fb_username": "Isabel Alvarez",
      "fecha_ingreso": "2023-07-20 10:04:52.576616",
      "ultima_fecha": 1689869211
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6581936011818970": {
    "atributos": {
      "Gestion_tipo": "Inicio",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6581936011818970&width=1024&ext=1689177973&hash=AeQLANKTLGjHa6f9PiI",
      "fb_username": "Erick Alvarez",
      "fecha_ingreso": "2023-06-12 09:31:28.495032",
      "intentos": 0,
      "ultima_fecha": 1686585975
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6582436395125202": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6582436395125202&width=1024&ext=1697125151&hash=AeQICFAL-FalRLCLD84",
      "fb_username": "Beverly Grijalba",
      "fecha_ingreso": "2023-09-12 09:38:00.825832",
      "ultima_fecha": 1694533152
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6583413035058538": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6583413035058538&width=1024&ext=1695572142&hash=AeRm2OVrATr2QZuhI6c",
      "fb_username": "Corina Rodriguez",
      "fecha_ingreso": "2023-08-25 10:15:23.853642",
      "ultima_fecha": 1692980144
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6584880614913443": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6584880614913443&width=1024&ext=1694974728&hash=AeTnscCmE_EtBQ8vc_0",
      "fb_username": "Andrea Soto",
      "fecha_ingreso": "2023-08-18 11:39:03.437167",
      "ultima_fecha": 1692382733
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6585991258131580": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6585991258131580&width=1024&ext=1692379700&hash=AeT4QILwNJpxiSJpZtg",
      "fb_username": "Jocabed Escobar",
      "fecha_ingreso": "2023-07-19 11:27:43.733187",
      "ultima_fecha": 1689787701
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6589031777791121": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6589031777791121&width=1024&ext=1682866655&hash=AeS_AIDvJwTSqX8b3IQ",
      "fb_username": "Rumina Miranda",
      "fecha_ingreso": "2023-03-31 07:46:15.267569",
      "intentos": 1,
      "ultima_fecha": 1680270775
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6593518894005716": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=Yehm_7dYxBQAX_oiKru&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAwm7YggREoLvStglrUNR9kZtA_S_Xv5-5Kc5Sg-kCt1w&oe=644EA299",
      "fb_username": "Sula Marcos",
      "fecha_ingreso": "2023-03-31 12:59:43.189532",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680291336
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6594272040605022": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6594272040605022&width=1024&ext=1682868698&hash=AeQNvQEtqG3aa93URlI",
      "fb_username": "Brenny C. Mejía",
      "fecha_ingreso": "2023-03-31 08:19:10.878810",
      "intentos": 0,
      "ultima_fecha": 1680272900
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "6596210110474156": {
    "atributos": {
      "Gestion_tipo": "físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEToHEK8y6Gk0Nni0-6XyyrCf2KGjhIyylE_nAlEo4HlingXBOrTvz2IDbJwirBlJb5U_nKFTf7&psid=6596210110474156&width=1024&ext=1700069160&hash=AeS2OGqdkD_iCdJYrKM",
      "fb_username": "Bryan Morales",
      "fecha_ingreso": "2023-10-16 11:25:28.266508",
      "intentos": 0,
      "ultima_fecha": 1697477163
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6598421240201721": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6598421240201721&width=1024&ext=1691692039&hash=AeRqCK3CqZkDw7J_BnM",
      "fb_username": "Merlin Martinez",
      "fecha_ingreso": "2023-07-11 12:25:01.729484",
      "ultima_fecha": 1689100040
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6599150700098389": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6599150700098389&width=1024&ext=1683816651&hash=AeQTwzhCJ61dcvpr-_0",
      "fb_username": "Pablo Enrique Domínguez Ortíz",
      "fecha_ingreso": "2023-04-11 07:43:29.514655",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681220786
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6608032199310438": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6608032199310438&width=1024&ext=1694792475&hash=AeSrp0dyviuQkzE1F34",
      "fb_username": "Jeyjey PelMos",
      "fecha_ingreso": "2023-08-16 09:25:43.476596",
      "ultima_fecha": 1692200476
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6610818565635603": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6610818565635603&width=1024&ext=1689965391&hash=AeQ2kJIHPkR7oqLb-lE",
      "fb_username": "Dgmusic García",
      "fecha_ingreso": "2023-06-21 12:49:15.947691",
      "ultima_fecha": 1687373394
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "6611809032243421": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6611809032243421&width=1024&ext=1697896223&hash=AeQVbufcdI8E5NMj7zw",
      "fb_username": "Verito Zöriänö",
      "fecha_ingreso": "2023-09-21 07:49:38.847986",
      "ultima_fecha": 1695304225
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6612279542152493": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6612279542152493&width=1024&ext=1696100938&hash=AeS2mutF5Q5rPOlmezU",
      "fb_username": "Mariielos Muñoz",
      "fecha_ingreso": "2023-08-31 12:38:29.732208",
      "ultima_fecha": 1693508943
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6614869391902776": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6614869391902776&width=1024&ext=1696285389&hash=AeSyxEZZPCMbPqFQiRE",
      "fb_username": "Rubí Girón",
      "fecha_ingreso": "2023-09-02 16:07:38.012349",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1693693392
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6619574531426239": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6619574531426239&width=1024&ext=1691598000&hash=AeSIkLKweEFyvoajjzQ",
      "fb_username": "María José",
      "fecha_ingreso": "2023-07-10 10:19:12.431691",
      "ultima_fecha": 1689006002
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6625446100838505": {
    "atributos": {
      "Gestion_tipo": "virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6625446100838505&width=1024&ext=1698428710&hash=AeTcA5pYtHp4uB1Khng",
      "fb_username": "Marinelli Juarez",
      "fecha_ingreso": "2023-08-28 10:49:45.954296",
      "intentos": 0,
      "ultima_fecha": 1695836713
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6627657200581008": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6627657200581008&width=1024&ext=1679155618&hash=AeS1hzQ7d3aytOqH0Vs",
      "fb_username": "Ero Siney",
      "fecha_ingreso": "2023-02-16 09:04:01.323410",
      "ultima_fecha": 1676560113
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6628962890565322": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGj-0N860RysJt_BLjXUDTrC4eR-1Z2-3GanQo1c_Lfc3IU3PwGOjQSj4ngUBla3NAKTHyHl2Xz&psid=6628962890565322&width=1024&ext=1705775541&hash=AfpzkeqPIyfyc2rK2oR7gb6TAe082-G4qwS-5IU-fbFDuQ",
      "fb_username": "Erick Martinez",
      "fecha_ingreso": "2023-12-21 12:30:41.646493",
      "ultima_fecha": 1703183544
    },
    "user_posicion": {
      "bloque": "CambioDireccionFiscalEmpresa",
      "posicion": 1
    }
  },
  "6633994506658919": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6633994506658919&width=1024&ext=1691858928&hash=AeTwtf2ePxpg01JHEAg",
      "fb_username": "Karol Gissell Romero",
      "fecha_ingreso": "2023-07-13 10:48:16.879969",
      "ultima_fecha": 1689266929
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "6635166443160264": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6635166443160264&width=1024&ext=1688067766&hash=AeRAB26nU1gClR-H-8s",
      "fb_username": "Selvin Msfp",
      "fecha_ingreso": "2023-05-30 13:39:05.839408",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1685475767
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6638113792949044": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6638113792949044&width=1024&ext=1699737647&hash=AeSJL-tQWpn8Dac73t0",
      "fb_username": "Dinora Martínez",
      "fecha_ingreso": "2023-10-12 15:20:47.321858"
    }
  },
  "6640794032655120": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6640794032655120&width=1024&ext=1691894142&hash=AeT5xIWm5gzOPKdUTY0",
      "fb_username": "Luis Santizo",
      "fecha_ingreso": "2023-07-13 20:35:14.041979",
      "intentos": 2,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1689302145
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6643988665644010": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6643988665644010&width=1024&ext=1693498948&hash=AeR0XIPT6gurIiqe75U",
      "fb_username": "Meli Castellanos",
      "fecha_ingreso": "2023-08-01 09:55:53.256663",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1690906949
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6655184181168293": {
    "atributos": {
      "Correo": "manfredopere@gmail.com",
      "Gestion_numero": "13",
      "Gestion_problema": "Solicitar negativa de patente",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Brayan manfredo perez che",
      "Telefono": "53572544",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6655184181168293&width=1024&ext=1696181297&hash=AeQGiwQ1PKWOdY9K3Us",
      "fb_username": "Manfredo Perez",
      "fecha_ingreso": "2023-08-31 13:37:02.594329",
      "intentos": 0,
      "ultima_fecha": 1693589297
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6655307497830139": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6655307497830139&width=1024&ext=1695309894&hash=AeQjCT5VCgXKAXtZqPg",
      "fb_username": "Estperez Pevel",
      "fecha_ingreso": "2023-02-13 09:02:57.561886",
      "ultima_fecha": 1692717895
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "6657385887632588": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHuz1ih64bWAWhV32kkl6wh_TDcL30e5zWpPoM-woBxsxVmEdXwWcYz-CvO5rTQAWtTtLr6AlWi&psid=6657385887632588&width=1024&ext=1701012468&hash=AeSHQoTe-V8kmvYprHU",
      "fb_username": "Miguel Angel González Esquivel",
      "fecha_ingreso": "2023-10-27 09:26:54.220623",
      "ultima_fecha": 1698420470
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6659639980736427": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6659639980736427&width=1024&ext=1689176427&hash=AeS7XLlOZH7h6VoyKlo",
      "fb_username": "Jossé Estrada",
      "fecha_ingreso": "2023-06-12 09:39:44.350112",
      "ultima_fecha": 1686584428
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6661001143968533": {
    "atributos": {
      "fb_picture": "https://scontent-iad3-1.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=fUSEDEuc7pcAX8vkRZ6&_nc_ht=scontent-iad3-1.xx&edm=AP4hL3IEAAAA&oh=00_AfAPyAENUd-f5ZlppvsriSkWxKBS0PsCvmRgkcDn62aNGQ&oe=654FB719",
      "fb_username": "Rudy Joaquin Castillo Marroquin",
      "fecha_ingreso": "2023-10-12 13:01:05.234290",
      "intentos": 0,
      "ultima_fecha": 1697137324
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6662551633776357": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6662551633776357&width=1024&ext=1691865601&hash=AeSg5f7D7G6zWeHaX1o",
      "fb_username": "Sandra Yesenia Orellana",
      "fecha_ingreso": "2023-07-13 12:39:07.533795",
      "intentos": 0,
      "ultima_fecha": 1689273603
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "6669159043110534": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6669159043110534&width=1024&ext=1681066095&hash=AeRN5fFsG2RujezOs04",
      "fb_username": "Caal Ku Alex",
      "fecha_ingreso": "2023-03-10 11:46:42.211900",
      "ultima_fecha": 1678470427
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6669878526442533": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6669878526442533&width=1024&ext=1699545473&hash=AeQOiR1TktvkUYRnEQA",
      "fb_username": "Paula Boteo",
      "fecha_ingreso": "2023-10-10 09:57:53.876436",
      "ultima_fecha": 1696953477
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6672379192823891": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6672379192823891&width=1024&ext=1691678535&hash=AeSd-xznsYM82I38RqE",
      "fb_username": "Richard Baeza",
      "fecha_ingreso": "2023-07-11 08:41:59.388623",
      "ultima_fecha": 1689086537
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6672594729426428": {
    "atributos": {
      "Correo": "jnajera.sociosestrategicosgt@gmail.com",
      "Gestion_numero": "No tengo numero de tramite",
      "Gestion_problema": "Tento un problema con E Servicios al querer hacer una consulta de comerciante individual",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Juan Najera",
      "Telefono": "30722666",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6672594729426428&width=1024&ext=1683243121&hash=AeR6bAY7levZoocddnQ",
      "fb_username": "Juan Antonio Nájera Cerón",
      "fecha_ingreso": "2023-04-04 16:23:07.341144",
      "intentos": 0,
      "ultima_fecha": 1680647295
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6673507592744094": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6673507592744094&width=1024&ext=1698259706&hash=AeQLz0YaUswMOi0GkBU",
      "fb_username": "Anibal Barahona",
      "fecha_ingreso": "2023-09-25 12:47:29.946359",
      "ultima_fecha": 1695667707
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6676835185665821": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6676835185665821&width=1024&ext=1682017404&hash=AeTEwSgNq-z09jaxyBM",
      "fb_username": "Jvlio Figueroa",
      "fecha_ingreso": "2023-03-09 12:56:33.276770",
      "intentos": 2,
      "ultima_fecha": 1679421670
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6690892664363287": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFQI510NKGwSkbJ2OoRT3VspxIEDuUx42eSH0-KR2iXi1ydMDOIuxjR581Phvq4xP0gqb6-iYRw&psid=6690892664363287&width=1024&ext=1700263587&hash=AeSHny6RCEwQ3dwZqVU",
      "fb_username": "Oficinas Chimaltenango",
      "fecha_ingreso": "2023-10-18 17:24:57.462149",
      "intentos": 0,
      "ultima_fecha": 1697671588
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6691228090943566": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGd__wLDJhYOcVif87NnS8vN8OP5vLV02thuL8nrzHWm3-uFWEvEqvK22oJ2OH8VIWkfKhUQ--c&psid=6691228090943566&width=1024&ext=1699914978&hash=AeSbiRX_hr_RpErYus4",
      "fb_username": "Manuel Lopezl",
      "fecha_ingreso": "2023-08-07 19:25:22.017691",
      "ultima_fecha": 1697322982
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6692577537440456": {
    "atributos": {
      "Gestion_tipo": "Muchos quiere trajabar quiere servir al pueblo nunca dan oportunidad",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6692577537440456&width=1024&ext=1689262866&hash=AeQ9SDvFce7MR6riyZQ",
      "fb_username": "Ana Celia De León Santizo",
      "fecha_ingreso": "2023-06-13 09:37:42.353188",
      "intentos": 2,
      "ultima_fecha": 1686670868
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "6703662179752837": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-6703662179752837",
      "fecha_ingreso": "2023-11-17 07:29:11.928428",
      "intentos": 0,
      "ultima_fecha": 1700227754
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6707523922595476": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=Yehm_7dYxBQAX_oiKru&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAwm7YggREoLvStglrUNR9kZtA_S_Xv5-5Kc5Sg-kCt1w&oe=644EA299",
      "fb_username": "Jerson Jolon",
      "fecha_ingreso": "2023-03-31 12:50:25.929676",
      "ultima_fecha": 1680288651
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6709509205735973": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6709509205735973&width=1024&ext=1692300246&hash=AeTGBX-ywKuZCjcv4Sc",
      "fb_username": "Diego Andrés",
      "fecha_ingreso": "2023-07-18 13:23:43.833391",
      "intentos": 0,
      "ultima_fecha": 1689708249
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6722574787755509": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6722574787755509&width=1024&ext=1676046833&hash=AeRn6qWZBbeYfAhpiiY",
      "fb_username": "Fernando Corona",
      "fecha_ingreso": "2023-01-11 09:37:24.579966",
      "ultima_fecha": 1673451626
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6722953141114455": {
    "atributos": {
      "Correo": "Yosh555@hotmail.es",
      "Gestion_numero": "11111",
      "Gestion_problema": "Buenas tardes, extravié la. Hoja de inscripción de mi empresa del registro mercantil  quisiera saber  si puedo obtener otra y si el tramite quizás sea en línea u oficinas y como seria si es posible muchas gracias.",
      "Gestion_tipo": "Electrónico",
      "Nombre": "José Renato Escobar Ajvix",
      "Telefono": "36959703",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHQ6cVgWfIEor-ViaIM-FCqpmmocYKfsUWqTokcagqBC3IOJ6UOIwhF3Q-ohbugBFgIikxwHknz&psid=6722953141114455&width=1024&ext=1702763872&hash=AeSjO4-b_KxRZJqH80A",
      "fb_username": "Yosef Escobar",
      "fecha_ingreso": "2023-11-16 13:37:22.957035",
      "ultima_fecha": 1700171873
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6725002720922056": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGEbaPhaqXWoa_yZ5B8Leo4XdndF9zt2AM2ALyTQQRuohZYPBxwJwIIZRMQkkm78YZk4eNGvNTT&psid=6725002720922056&width=1024&ext=1702865289&hash=AeS11WoAYYR-3Gv27do",
      "fb_username": "Monzon F",
      "fecha_ingreso": "2023-11-17 20:07:09.382451",
      "intentos": 0,
      "ultima_fecha": 1700273291
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6730386350311036": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6730386350311036&width=1024&ext=1682784638&hash=AeQeO8jtzu-N-yihw_0",
      "fb_username": "Pris Reyes",
      "fecha_ingreso": "2023-03-30 09:04:36.964631",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680188848
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6731407823606799": {
    "atributos": {
      "Gestion_numero": "agente",
      "Gestion_problema": "al registrarse con ustedes  crea alguna obligación ?",
      "Gestion_tipo": "Electrónico",
      "Nombre": "rosa",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6731407823606799&width=1024&ext=1699211515&hash=AeS18D28sGjQGHuKmLQ",
      "fb_username": "Rosita Reynoso",
      "fecha_ingreso": "2023-10-05 17:48:21.847086",
      "intentos": 0,
      "ultima_fecha": 1696619516
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 5
    }
  },
  "6734683516563038": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6734683516563038&width=1024&ext=1693668764&hash=AeTKc1qhEZ_c2jR1FRM",
      "fb_username": "Mabel Ramos",
      "fecha_ingreso": "2023-08-03 09:30:15.209327",
      "ultima_fecha": 1691076765
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6736628579757996": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGNKBKN21qlJuK8qxOPcISh8qmFjSgM766urqcib-eiy4c_zZR3tvDP7FEvFhz7Mv4ne8gYv0v2&psid=6736628579757996&width=1024&ext=1699994867&hash=AeSuDjZklohVjdejJ8s",
      "fb_username": "Jacquelyn Aifan",
      "fecha_ingreso": "2023-10-15 14:46:53.797227",
      "ultima_fecha": 1697402818
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6738358709521684": {
    "atributos": {
      "Correo": "juaneduardocerna@gmail.com",
      "Gestion_numero": "1001-145593",
      "Gestion_problema": "La patente ya aparece firmada, pero al descargar el archivo no se puede abrir, aparece un mensaje de que el archivo está corrupto, ya probé descargarlo desde 3 navegadores diferentes y abrirlo con 2 programas diferentes y no funciona. El archivo está corrupto.",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Juan Eduardo Cerna",
      "Telefono": "59865067",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6738358709521684&width=1024&ext=1692289645&hash=AeSM0ptRLeosUo0g6So",
      "fb_username": "Juan Eduardo",
      "fecha_ingreso": "2023-07-18 10:14:48.421197",
      "ultima_fecha": 1689697645
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6741994792482675": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6741994792482675&width=1024&ext=1679088141&hash=AeRNdshEo1n7ruPA8kU",
      "fb_username": "Emmanuel Nkonkola",
      "fecha_ingreso": "2023-02-15 14:23:59.551288",
      "ultima_fecha": 1676492643
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6745140088864688": {
    "atributos": {
      "Gestion_tipo": "Fisico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6745140088864688&width=1024&ext=1693411309&hash=AeS26DWLDwbs8k9Gmy0",
      "fb_username": "Sucy Hernández",
      "fecha_ingreso": "2023-07-31 09:53:37.331827",
      "intentos": 3,
      "ultima_fecha": 1690819312
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "6745767565543414": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6745767565543414&width=1024&ext=1698601867&hash=AeT-9rlsZ18nXg2ZzJI",
      "fb_username": "Yoselin RoAl",
      "fecha_ingreso": "2023-09-29 11:49:57.493807",
      "ultima_fecha": 1696009869
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6747504788695258": {
    "atributos": {
      "Correo": "princessgsosa21@gmail.com",
      "Gestion_numero": "1001186206",
      "Gestion_problema": "Lo puede presentar una tercer persona",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Judith Gonzalez Sosa",
      "Telefono": "47444365",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEk_A19mv5XCLKyMrKG3PrL-wpWgCAuZdb4lCjwTcJmz_L4IllEM6eZ_YcRIBz8Vv-GZ9ro6KGp&psid=6747504788695258&width=1024&ext=1711294108&hash=AfrJtW2Pd4AMdJ9N9YZGoO6DJBvoDaZBKTePWNu2y_uCMg",
      "fb_username": "Princess Sosa",
      "fecha_chatcenter": 1707944003080,
      "fecha_ingreso": "2024-01-11 08:52:50.159162",
      "leido": false,
      "log_chatcenter": "-NqdOJO2ENhtgTe5CVOr",
      "msg_noleido": 0,
      "ultima_fecha": 1708702108
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "6748039435259967": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=XLpp5VGUYKsAX_d3qO1&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfB65s223ogPJPR0kaTgR6hvQ9FwCr2et6m634K2rIh0bQ&oe=650E3C99",
      "fb_username": "Sindi Lu",
      "fecha_ingreso": "2023-08-23 21:18:50.004017",
      "leido": true,
      "msg_noleido": 0,
      "ultima_fecha": 1692847135
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6748524891940944": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHTAyLwBuI2yK41GTNgX2TLz3DOFz7_jANhdIvmju2F5_B55h7vVYRgjggOt-aqwbTUsGRaI6sg&psid=6748524891940944&width=1024&ext=1715315731&hash=AfrfumNU1uB4JBpP5nuwG5Exxplb3nRHIX7tqNPEuVC0Zg",
      "fb_username": "Sergio Leal",
      "fecha_ingreso": "2024-01-05 18:54:06.983933",
      "ultima_fecha": 1712723732
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "6751108334937067": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFBhTUKobnJzBxMbzph9ph59EsgM7BKDnI8SkpR5wT6XZ6s7DBmGAlwJf28bD_mHk4V3pitgcQ_&psid=6751108334937067&width=1024&ext=1701289466&hash=AeRG2WL2VZ6KrW7q2Uw",
      "fb_username": "Cristian Rosales",
      "fecha_ingreso": "2023-10-30 14:22:53.688810",
      "ultima_fecha": 1698697469
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6752411218186938": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXExKaiiEAnKn8IHrmep75ZSHEt-WTNeWSVD-3eYPtPeLd5jigvS4S5jK2PqYlQ_kx1YX2vaVdDb&psid=6752411218186938&width=1024&ext=1700340340&hash=AeRUAUsEeLrK70OtYjE",
      "fb_username": "Luis Pop",
      "fecha_ingreso": "2023-10-19 14:44:57.407435",
      "intentos": 0,
      "ultima_fecha": 1697748342
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6753063161377238": {
    "atributos": {
      "asesor_id": "GgTCycoVfSe0IHASf4V4Om5zCzl2",
      "asesor_name": "KSanti Torres",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6753063161377238&width=1024&ext=1680731401&hash=AeTRMVo4O_o5g3LQsJo",
      "fb_username": "Tutis Ylana",
      "fecha_ingreso": "2023-01-11 14:18:55.910406",
      "intentos": 0,
      "ultima_fecha": 1678135753
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6755235904570131": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFysnm5mCz4OEmM4amS66aENfDNG3YFdmmch10Nry-K7i22a8r9WRP8DhrrqclBbCttKC_rDeeK&psid=6755235904570131&width=1024&ext=1700001966&hash=AeSjk5BSv9Ak4iBvpX0",
      "fb_username": "Loli Acey",
      "fecha_ingreso": "2023-10-15 16:46:06.671934"
    }
  },
  "6756413447803985": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXELDBN6JrbEH-tFEtcvdwBm6eH_HGDTv5vohCixGQNUMONRPW_oW8ZktdTlgp--fy6qkR7GKtt5&psid=6756413447803985&width=1024&ext=1708808960&hash=AfqCL_-VcYnV4wv379GbVNGcAyIWJop9u23ocuS95JW-GQ",
      "fb_username": "Elmer Perez",
      "fecha_ingreso": "2024-01-25 15:08:57.720628",
      "ultima_fecha": 1706216962
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6762347223780323": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6762347223780323&width=1024&ext=1682003077&hash=AeSNb4ArC4EQo9NJvoA",
      "fb_username": "Ana Laura Segura",
      "fecha_ingreso": "2023-03-21 08:01:36.905356",
      "intentos": 0,
      "ultima_fecha": 1679407342
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6766498530136371": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHpHB40MpJ22dJIKUKZH1oebETf7lJESqV-bOsEpsHEruxJuuUBFH4YGuLIcklAb2qC8Tg9TQFF&psid=6766498530136371&width=1024&ext=1702659177&hash=AeS8BAg9brwx9bAZHo8",
      "fb_username": "Nestor Neyo Pacay",
      "fecha_ingreso": "2023-11-15 10:48:46.030234",
      "ultima_fecha": 1700067178
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6767805479983279": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGHIizP1FBh16Zcrd9mu5r3x2kylUvhMGXF7na0___kOClVj_DcHCQFRqs0r7bQAo-PcyFgpFYw&psid=6767805479983279&width=1024&ext=1705183910&hash=AfqcllY69ODmxqFd17FyTX88P8kK9NWJHIl6sogsKobtpg",
      "fb_username": "Elizabeth Sánchez",
      "fecha_ingreso": "2023-12-14 16:11:23.607204",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1702591912
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6770661953062545": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE_I8CAO4zvHOhDv_Rn8GkV0TDLcFRVhrc1Zb_iWiCR2wtYesQMrAOmysKea79HL5d0GlU1jjPV&psid=6770661953062545&width=1024&ext=1705591404&hash=AfrmTPV9LfmEA2AS1-JSnk9pzHC3S98OPjK7ANJX9_ynyQ",
      "fb_username": "Luisa Patzan",
      "fecha_ingreso": "2023-12-19 09:23:07.432527",
      "ultima_fecha": 1702999407
    },
    "user_posicion": {
      "bloque": "SociedadEmprendimientoINS",
      "posicion": 1
    }
  },
  "6771807666162587": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6771807666162587&width=1024&ext=1691944980&hash=AeRZ5T4Hc5lgvNrQ8z8",
      "fb_username": "Elizzabeth Taylor",
      "fecha_ingreso": "2023-07-14 10:09:15.741900",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1689352982
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6776619995760524": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEU8VFH9nBqqdGTSdsvE5Vp_GmE_A5W9tL3_Y361Fb8AsG8cR_qAI5M17RGbNRwtfoM1294D5ke&psid=6776619995760524&width=1024&ext=1700753457&hash=AeTZkJZZ3tChVbeIPpg",
      "fb_username": "Joa Salguero",
      "fecha_ingreso": "2023-10-24 09:30:18.182646",
      "ultima_fecha": 1698161458
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6778807832235069": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHxfw3_s2VMCPE1cyoBjwWhKVfo-9IB7G3vntgQz33obM_Lz2NOnM9_KCrp5zhWBUX4yJn-cCNd&psid=6778807832235069&width=1024&ext=1702136339&hash=AeRSGlfPl5jLYq0Jsxo",
      "fb_username": "Benjamin Ben",
      "fecha_ingreso": "2023-11-09 09:37:27.839871",
      "ultima_fecha": 1699544341
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6786785311399666": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF2w1OTh7aPpxu7H7GmjPM5Xhw8i20DZ6-k25X4lwXAdytdbAcbThx1LQTQFDmvbIrcJwmKKBJ7&psid=6786785311399666&width=1024&ext=1703701887&hash=AeT_4sUPFwG13RVwsE4",
      "fb_username": "Feliciano Gomes",
      "fecha_ingreso": "2023-11-27 12:21:17.307703",
      "intentos": 0,
      "leido": true,
      "msg_noleido": 0,
      "ultima_fecha": 1701109889
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6790210657730911": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFcdg-3ujC7Uu2P1f5kLeDH4YKOm-eLLdVA0e-idQnn5tXpC9NqPSA4wrLdUu4RPWbWs9VXsbZk&psid=6790210657730911&width=1024&ext=1710007462&hash=Afrmlaa8Ye5A-mF1hmuP2sGVrjOJZ7r7ZfS9lc_9OcZi6g",
      "fb_username": "Carolina González",
      "fecha_ingreso": "2023-11-14 12:11:41.260373",
      "ultima_fecha": 1707415463
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "6791498014217360": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6791498014217360&width=1024&ext=1686933767&hash=AeQiqPOjo1YBfdq9ziI",
      "fb_username": "Ariel Martinez",
      "fecha_ingreso": "2023-05-17 10:39:54.057484",
      "ultima_fecha": 1684341770
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6792801014175694": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFmler9Lck2DFuScJDWybIy_w4a74VLEKMNFzExt0ovn4Dut2aZcthwWC-0UwwQaIa0vzTrCDPa&psid=6792801014175694&width=1024&ext=1708719131&hash=AfrFqIqQYeKoGmHV4mi3OHo0dfzs89aCvZUgjk3MVfhTow",
      "fb_username": "Rafael Calderon",
      "fecha_ingreso": "2024-01-24 14:02:14.508556",
      "intentos": 0,
      "ultima_fecha": 1706127132
    },
    "user_posicion": {
      "bloque": "Auxiliares_Asamblea_Acciones",
      "posicion": 1
    }
  },
  "6793489617439020": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6793489617439020&width=1024&ext=1699462550&hash=AeRkaHWh474fE7hphNw",
      "fb_username": "Federico Suazo",
      "fecha_ingreso": "2023-10-09 10:54:33.981650",
      "intentos": 0,
      "ultima_fecha": 1696870552
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "6794227747258907": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6794227747258907&width=1024&ext=1679850127&hash=AeT6hg9ejCO7T8SWoZA",
      "fb_username": "Claudita Montenegro",
      "fecha_ingreso": "2023-02-24 10:01:41.153096",
      "ultima_fecha": 1677254553
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "6794355494019765": {
    "atributos": {
      "Gestion_tipo": "Necesito virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEiMC3IaCbIHe6UgJ-90IBhHNPB9_fCYsyTrWnb3Ee85-0jdDz4arIpIzSbjIP__rm27rsheSh9&psid=6794355494019765&width=1024&ext=1707450138&hash=AfoTKvxm6n_YM8Bbq0B1w0zlo9fOGzIJ_Urklri28BgeNQ",
      "fb_username": "Perico Jr Jr.",
      "fecha_ingreso": "2023-12-21 13:25:56.538480",
      "intentos": 1,
      "ultima_fecha": 1704858141
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "6796267547090661": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6796267547090661&width=1024&ext=1697661982&hash=AeRROxvBvdHLJe0G51w",
      "fb_username": "Billy Ortiz",
      "fecha_ingreso": "2023-09-18 14:46:01.336120",
      "ultima_fecha": 1695069984
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6797628987001816": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEE9vbIIxrQW99ZIGxI_lupLxG-6CmQsMWUSPQAm7STpO_mhv-Hdbq_mXYbQTc6V1yZShKctgo9&psid=6797628987001816&width=1024&ext=1705678096&hash=Afo9--8ju0phFnoLNps81CsimCC755Ne--9PvkspsfeH4Q",
      "fb_username": "Paolita Puluc",
      "fecha_ingreso": "2023-12-20 09:28:16.461259",
      "ultima_fecha": 1703086100
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6800274003432560": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHk1DZQk482E1OPIDXN3N_spqsy28jZv88EEUsqjhMurgYAdA4MIUrTNgKnv65K-wymoOuQRNJ6&psid=6800274003432560&width=1024&ext=1708202141&hash=AfrZ5tg_dTtcAIKiRGm-Fx_yWMaK4_67qwY0XJhfjb30GQ",
      "fb_username": "Bayan Fuentes",
      "fecha_ingreso": "2024-01-18 14:34:17.374237",
      "ultima_fecha": 1705610144
    },
    "user_posicion": {
      "bloque": "ReposicionPatente",
      "posicion": 1
    }
  },
  "6801300189991379": {
    "atributos": {
      "Correo": "crad0910@gmail.com",
      "Gestion_numero": "32738501",
      "Gestion_problema": "Rechazo de Gestion",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Edgar Hernández",
      "Telefono": "47320000",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH11Mr-s9VdNfX09a6qP77MDemIadPJ4lUL2HJ6PZg1vdq5VKdxhzzZe7Bu2JduoSQTMFsPBDVg&psid=6801300189991379&width=1024&ext=1699807956&hash=AeRZR6fZTH5QEpFizmM",
      "fb_username": "Edgar Hernandez Marroquin",
      "fecha_ingreso": "2023-10-12 15:50:43.947378",
      "intentos": 0,
      "ultima_fecha": 1697215958
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6803471043108788": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFDGUw0El_tVJ3mD0B4gDwgLR0OkwEFF98HkH5zercKXpC88NKjSdfHn7MJhgI1YlNJiv2D4U9L&psid=6803471043108788&width=1024&ext=1707506652&hash=AfrfhvUNMJCDSYbKXpIuhANiwKD-_5fshqOdI63H3kdh_w",
      "fb_username": "Marisol Véliz",
      "fecha_ingreso": "2024-01-10 13:18:59.190462",
      "intentos": 0,
      "ultima_fecha": 1704914654
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6806540539399010": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFCBOTPp2UHFpOwFD54Abf1JTikASiPCpeI_8RZTxEseglwzMP9XcDx9YuW5KHxTq_3n0RBvcVx&psid=6806540539399010&width=1024&ext=1701564599&hash=AeTKJKTn38N7qRcYaXY",
      "fb_username": "Dulce Palacios",
      "fecha_ingreso": "2023-11-02 18:49:25.955718",
      "ultima_fecha": 1698972602
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6809103565834223": {
    "atributos": {
      "Correo": "Bebegarcia02@gmail.com",
      "Gestion_numero": "1017-169634",
      "Gestion_problema": "Lo ingresé hace varios días y no me lo han autorizado",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Beberlyn Abigail García Nufio",
      "Telefono": "45841943",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFdpfGQGIBdgt3Gz1I6jXyyXgKNtqCjVNYCtAssAWmBitkyq7wAoZMHlOXM3aWRsaIB_Az5-s3L&psid=6809103565834223&width=1024&ext=1702567802&hash=AeRGdzU9dhsGERPFEh4",
      "fb_username": "Beberlyn Nufio",
      "fecha_ingreso": "2023-11-06 13:26:46.669637",
      "ultima_fecha": 1699975803
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "6810721455622768": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6810721455622768&width=1024&ext=1687382228&hash=AeQjX7n0OYzG_1cQkuE",
      "fb_username": "Pablo Rivas",
      "fecha_ingreso": "2023-05-22 15:15:00.837229",
      "ultima_fecha": 1684790231
    },
    "user_posicion": {
      "bloque": "SociedadEmprendimientoINS",
      "posicion": 1
    }
  },
  "6814657088621247": {
    "atributos": {
      "Correo": "Justinemendez16@gmail.con",
      "Gestion_numero": "Datos de industrias licoreras",
      "Gestion_problema": "Datos de industrias licoreras",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Justine Méndez",
      "Telefono": "41675627",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHVMzTSEWbGQQ5WqJ-8LEilRH1SN-ir2G1-mM8IMKH46JPk1piVtCllrlmPUTmrJRnMNUlKBEZ7&psid=6814657088621247&width=1024&ext=1701532905&hash=AeRGwncmgnxddMrBgGQ",
      "fb_username": "Justine Mendez",
      "fecha_ingreso": "2023-11-02 09:59:59.322045",
      "intentos": 0,
      "ultima_fecha": 1698940906
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6815185608538581": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6815185608538581&width=1024&ext=1696524728&hash=AeRRlTXlwQ9DfJIGtxg",
      "fb_username": "Pachuly Mario Ruiz",
      "fecha_ingreso": "2023-09-05 10:51:35.758125",
      "ultima_fecha": 1693932732
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "6816484778463871": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHOTabngSdTWNHW0zGESAeivfsjfu_bexK1c0WpvN5FBqOt8kG34MXAt8BoXCc8b9v_3KnT59nz&psid=6816484778463871&width=1024&ext=1708625843&hash=AfqiKxEJof1gnO2QpRNST09M3ZcrEKAqRJ5-zqZWfNleIA",
      "fb_username": "Geo Joss",
      "fecha_ingreso": "2024-01-23 12:14:48.788142",
      "ultima_fecha": 1706033751
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6819897274696624": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6819897274696624&width=1024&ext=1695499817&hash=AeS4UUGP4dTchtG523o",
      "fb_username": "Danny Vega",
      "fecha_ingreso": "2023-08-24 14:09:53.451067",
      "ultima_fecha": 1692907821
    },
    "user_posicion": {
      "bloque": "Auxiliar_inscripcion",
      "posicion": 1
    }
  },
  "6824967747580445": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHjmSrdv6yoJFyYF5TW-KZDlYetJd24vqIn2nLRDZ-bR9tfOLsZh6caEG4O6iuBxfmntX9itzas&psid=6824967747580445&width=1024&ext=1705590228&hash=AfrV0EfEMrwbQPeBAwhzlwjAmfhssPGYlpPG8Y169o2f6Q",
      "fb_username": "Edson Prado Leal",
      "fecha_ingreso": "2023-12-18 12:28:39.119960",
      "ultima_fecha": 1702998230
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6831804740175298": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG3PjhKY5uCNCCxcHMGEHv6FsG-j7jrF6bevsxsyeYA5Q_9JFYPJdWevWuWIxARb0Q-7_2Sm9Mj&psid=6831804740175298&width=1024&ext=1700342950&hash=AeTssMRKfKF1CHdih2o",
      "fb_username": "Cristián Aguilar",
      "fecha_ingreso": "2023-10-19 15:27:20.555303",
      "ultima_fecha": 1697750951
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6836076333152686": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGINVZNOqBlHWvGgvTLN-cw6fI2XfgVa5ewGcDcAeu2ywsa9qQJEFyhC9hQrAYpLXHonEyGjZHK&psid=6836076333152686&width=1024&ext=1701887090&hash=AeRD-qsq2WtAiXi6Dnk",
      "fb_username": "Yoli De Santizo",
      "fecha_ingreso": "2023-11-06 12:23:49.840510",
      "ultima_fecha": 1699295094
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "6839943416122445": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGKSw5tZXo7AGEcvXHDunkYXOqVF9uNJO-miqBBLYbPzdZp2SZursOfjq5JdxHo9UvRMfxGyxxU&psid=6839943416122445&width=1024&ext=1705602063&hash=AfpFuaZd9UO317PV_CTLFbQljq2bzk3-nLDjghqvBkdD3g",
      "fb_username": "Erick Guarcax",
      "fecha_ingreso": "2023-12-19 12:18:02.240854",
      "intentos": 0,
      "ultima_fecha": 1703010066
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "6846372808785800": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG-VxMs3h6HpQHPmdFDHd98DB1dLvrzyqzkq_xpbstWbA0fS-SDCXDL3Fa8Urziv-fs3HwwgTzi&psid=6846372808785800&width=1024&ext=1713112460&hash=Afp_4yGiZnKjC2F9qdWT9RZL_h7wHbTOgfqVdyKeJ790Tg",
      "fb_username": "Henry Hernandez",
      "fecha_ingreso": "2023-06-15 16:25:52.316983",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1710520463
    },
    "user_posicion": {
      "bloque": "CambioAmpliaObjeto",
      "posicion": 1
    }
  },
  "6847537175303324": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6847537175303324&width=1024&ext=1699205013&hash=AeRXr-p9rK2YoAlCAfY",
      "fb_username": "FutsalDown Guatemala",
      "fecha_ingreso": "2023-10-06 11:21:43.123689",
      "ultima_fecha": 1696613015
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6854571674580799": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE859eEiRGKE_Uj-CdFksfrifB6No-Td0JgF1MbIrr8RA2zn4aE2VqIYTrCZ-8UaatnIBjBDWIR&psid=6854571674580799&width=1024&ext=1700083200&hash=AeScfGi8nCKx9Asd1lE",
      "fb_username": "Paul Ramirez",
      "fecha_ingreso": "2023-10-16 15:19:23.764506",
      "ultima_fecha": 1697491201
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6858487697512235": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6858487697512235&width=1024&ext=1687455182&hash=AeQ50bN-qeZDP7BA0xA",
      "fb_username": "Eddy Pocon",
      "fecha_ingreso": "2023-05-23 11:28:43.063868",
      "intentos": 0,
      "ultima_fecha": 1684863188
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "6862134887228649": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJRtzox-yfUxF89nZn-oIglfv4LcBin-CW4eGm4qrDOQwC59Tvd4GWi9BrvTFMmoU4fgYTcOct&psid=6862134887228649&width=1024&ext=1708094498&hash=AfoOFLia-V_Ow3mi7-pciY65IngyRDnxpVLlRd9WwbBZTg",
      "fb_username": "Gustavo Enríquez",
      "fecha_ingreso": "2024-01-17 08:40:45.526917",
      "intentos": 0,
      "ultima_fecha": 1705502499
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6862746527091264": {
    "atributos": {
      "Correo": "alejandrina024@gmail.com",
      "Gestion_numero": "84015-2023",
      "Gestion_problema": "Aún no tengo respuesta y me gustaría saber cómo va el tramite",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Odilia Giron",
      "Telefono": "42856678",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6862746527091264&width=1024&ext=1695321182&hash=AeTqAQRvHCj0blv6FnU",
      "fb_username": "Alexandrina Giron de Orozco",
      "fecha_ingreso": "2023-08-22 11:43:00.938660",
      "intentos": 0,
      "ultima_fecha": 1692729182
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6866737296670296": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6866737296670296&width=1024&ext=1692807030&hash=AeQwQGJVWeFhjp6vSyg",
      "fb_username": "Kimberly Lemus de Rodriguez",
      "fecha_ingreso": "2023-07-24 10:09:11.112662",
      "ultima_fecha": 1690215032
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6868745393141432": {
    "atributos": {
      "Correo": "ld.maithe@gmail.com",
      "Gestion_numero": "32634362",
      "Gestion_problema": "Realicé el trámite el 28 de abril y aun no tengo respuesta",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Anlly Lopez",
      "Telefono": "51128110",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6868745393141432&width=1024&ext=1686509057&hash=AeQKCSAMTh2H5a2ijRU",
      "fb_username": "Maithé López",
      "fecha_ingreso": "2023-05-08 10:09:11.231117",
      "ultima_fecha": 1683917058
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6870741692948915": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6870741692948915&width=1024&ext=1699118964&hash=AeQa1UHRVc-EwKBOTG0",
      "fb_username": "Jose Rosales",
      "fecha_ingreso": "2023-10-05 11:28:59.956248",
      "ultima_fecha": 1696526966
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6873234329390622": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHb1HgyL381ttvycsK-Rjb0X9vPg19W6jVmXSl0wNe-XoUdonfQXIACtSplETL7L6w6j0zLIp1B&psid=6873234329390622&width=1024&ext=1701553914&hash=AeTIaS9tHEuKFxcTW2M",
      "fb_username": "Steve Nash",
      "fecha_ingreso": "2023-11-02 15:51:27.445542",
      "ultima_fecha": 1698961915
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "6873435152750257": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFeGlP8v4dnG9tDiFlzAY_BeucpELWtIC1QIYnx_WDXfMVJpD8MdHykUC48UDVLFBjgL_5KAPCu&psid=6873435152750257&width=1024&ext=1701209556&hash=AeTOtQeDWImMOsWlpOQ",
      "fb_username": "Jettot Ettot",
      "fecha_ingreso": "2023-10-29 16:08:25.606158",
      "ultima_fecha": 1698617559
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6881923878603436": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGqMPbZgDa2uvkuSDLQVr-UWeQDTKQFaEll-4aMm07rxUZD2xSbNUYvRz87ArzHnQsCSiJ3yqEG&psid=6881923878603436&width=1024&ext=1710996214&hash=Afr8MudU1M7EfferHYiCC1ffoc-OoLKAwiPvcP-Ax8fMog",
      "fb_username": "Rocïo Duarte De Velásquez",
      "fecha_ingreso": "2024-02-19 22:43:19.726897",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1708404216
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6882498068505662": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEXdo1aW3gp3V9TC8G4WZ-Hx-Pn84l3YbHX87HVDkTX8qcnnmpfK1KL1sX301AnaJcHARwzN4Uw&psid=6882498068505662&width=1024&ext=1705060544&hash=Afp438QndRAsHxav54l1I7Wbme9HHNm8jxAZ2LR_VpABag",
      "fb_username": "Jimmy Domingo",
      "fecha_ingreso": "2023-12-12 23:05:19.563709",
      "ultima_fecha": 1702468547
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6886205818151136": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG8E7SEP2qoZjN9KGPm3EUtbJHw5Ie8Y-VFdNjJA5f4s-WGRB2g7dugxV6q8dI7HsbStj6b4890&psid=6886205818151136&width=1024&ext=1706206986&hash=AfoEiisNBPIZZT5g-hcbhgkPaOPmJDfjjHt2xC8MzHg5Zg",
      "fb_username": "Manuel Mar",
      "fecha_ingreso": "2023-12-26 12:20:52.890378",
      "ultima_fecha": 1703614987
    },
    "user_posicion": {
      "bloque": "InscripcionSociedad",
      "posicion": 1
    }
  },
  "6891277584240142": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6891277584240142&width=1024&ext=1694625647&hash=AeRxacomT8zYRhndO-s",
      "fb_username": "Ailyn Barrera Betancourth",
      "fecha_ingreso": "2023-08-14 11:20:33.920350",
      "ultima_fecha": 1692033649
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6894525613973156": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFvkVaxnKqemhd57LQ0d3yoSlJ_KwbL1x_bSE1AoIXS2y1a-dgefGLDXErXF8PUrJ9GKwv3XnKS&psid=6894525613973156&width=1024&ext=1703198939&hash=AeQvhk6ckoTx-qtMTrA",
      "fb_username": "Hernán Fajardo",
      "fecha_ingreso": "2023-11-21 16:46:28.202912",
      "ultima_fecha": 1700606940
    },
    "user_posicion": {
      "bloque": "MandatoInscripcion",
      "posicion": 1
    }
  },
  "6895682100514334": {
    "atributos": {
      "Gestion_numero": "Buen día",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF5p0QO6-VsO0q5My1FJzINm7U5-2uX4740qDyM4REtcx3fbpj6_BlUcB_yp4qcnffn2IyT4yH1&psid=6895682100514334&width=1024&ext=1703182895&hash=AeQG_Tu8A_qmGkXI1cI",
      "fb_username": "Sam Adp",
      "fecha_ingreso": "2023-11-21 12:19:28.962499",
      "intentos": 0,
      "ultima_fecha": 1700590896
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "6898464310183669": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6898464310183669&width=1024&ext=1689263280&hash=AeQb3ghbp9gSE4UCZts",
      "fb_username": "Juan Carlos Pellecer",
      "fecha_ingreso": "2023-06-13 09:47:57.344387",
      "ultima_fecha": 1686671284
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6899824636768905": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGarxgISeCFuv4-THK5ciz_mdIlt5Tj7JNeO5HTn2g_BPzjyH57hVQsWnBNyzq5Lu001CUn_x23&psid=6899824636768905&width=1024&ext=1703268259&hash=AeR19E-NOPccqn6EVjo",
      "fb_username": "Alejandra Consuegra",
      "fecha_ingreso": "2023-11-22 12:04:07.479564",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1700676263
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6903347329721773": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6903347329721773&width=1024&ext=1698350814&hash=AeSh_sipQ7vCaB-7aP0",
      "fb_username": "Jodali Valdez",
      "fecha_ingreso": "2023-09-26 14:06:39.057658",
      "ultima_fecha": 1695758816
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6905862729482172": {
    "atributos": {
      "Correo": "Andre07palacios@gmail.com",
      "Gestion_numero": "32770888",
      "Gestion_problema": "Estoy tratando de inscribir una nueva empresa, pero el usuario asignado me pide que corrija el objeto de la empresa por uno que no tiene nada que ver con lo que se dedicará la misa y quiero una solución porque ya hice los pagos correspondientes",
      "Gestion_tipo": "Electrónico",
      "Nombre": "André Palacios",
      "Telefono": "35227751",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGvJyzFnfRcH6tjNUfj9xv9vv1-jKHwxszdXvaxMBmm_R6JI2KbpIjjo3SX-22fsl8K7UI5lQyT&psid=6905862729482172&width=1024&ext=1705001255&hash=Afpr1D6IV-3TMP7oIaWwMydEhDv19Tl_s2MQsKFo4M3Sag",
      "fb_username": "André Palacios",
      "fecha_ingreso": "2023-12-05 12:59:34.210229",
      "ultima_fecha": 1702409256
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6908406502577626": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH7sw0o_h7lEBew84ArBymG1wUxS_Ow50hlAeRMMQQffMOANmPu7g1CaiWPRbi59XMHojPI1E-w&psid=6908406502577626&width=1024&ext=1701557760&hash=AeSfzHyzidBt8oO1Tuo",
      "fb_username": "William Gonzalez Yax",
      "fecha_ingreso": "2023-11-02 16:54:30.487738",
      "ultima_fecha": 1698965762
    },
    "user_posicion": {
      "bloque": "Auxiliar_inscripcion",
      "posicion": 1
    }
  },
  "6910278575661579": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFzpCou4KrfhGJR1KsskW0n3h3A7fGEtG819rowtbY5hn_7xcNkHbYK9zbhHSf7Rx_mJqmxvDvb&psid=6910278575661579&width=1024&ext=1700850506&hash=AeT5mXrhifz3Kn0LcKs",
      "fb_username": "Armando García",
      "fecha_ingreso": "2023-10-25 12:28:04.426042",
      "ultima_fecha": 1698258508
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "6912119648840013": {
    "atributos": {
      "Correo": "eliasgp41@yahoo.com",
      "Gestion_numero": "Buen dia",
      "Gestion_problema": "Asesor",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Elias Chivalav",
      "Telefono": "47761288",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6912119648840013&width=1024&ext=1698418503&hash=AeSBpP3Nq2sbv9mqbLw",
      "fb_username": "Elías Chivalán",
      "fecha_ingreso": "2023-09-27 08:50:59.195723",
      "ultima_fecha": 1695826504
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6914246758671116": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "1001-182595",
      "Gestion_problema": "Me rechazaron por consignar droguería y farmacia",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Luis Jerez",
      "Telefono": "Carlos Godoy 35179822",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGthAPqlS73iZksxSQX_j9bbkluOFi3BIwrqNUeuD6ndeOoJLEHOw6o4Hq5o2sDJ81gxSL06h_t&psid=6914246758671116&width=1024&ext=1708095574&hash=AfoeWZxJf0mqZ36wY32A-H4ZTBMeBlsSJQ74SsHUqrOm0g",
      "fb_username": "Carlos Andrés Arriaga",
      "fecha_ingreso": "2024-01-10 14:04:53.891619",
      "ultima_fecha": 1705503574
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6923013931068590": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6923013931068590&width=1024&ext=1697999472&hash=AeR3bzRFZdYa0kCV7_U",
      "fb_username": "Jeanneth De Ramírez",
      "fecha_ingreso": "2023-09-22 12:27:29.573645",
      "ultima_fecha": 1695407474
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6926623997417937": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHz4vwkyET5KexqcxcdO_kIXpsHXnQMxJaIRnvDs6fmqgWnQnE-tPuQOwoWYiDY1515INtLZH6N&psid=6926623997417937&width=1024&ext=1705548420&hash=AfqINZmo85pfUSvX6B88SR6SnL7etjI7-EckIWjmO5l05Q",
      "fb_username": "Lesther Jimenes",
      "fecha_ingreso": "2023-12-18 21:26:30.963504",
      "ultima_fecha": 1702956421
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6927115810633961": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6927115810633961&width=1024&ext=1694033421&hash=AeRQKUzekmfvSt9rMEA",
      "fb_username": "Richar Montiel",
      "fecha_ingreso": "2023-08-07 14:25:33.580707",
      "ultima_fecha": 1691441425
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6929229773793413": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH0RuMYArZRB4_l1dTL1Mz8xCzGYbmPVIINju5iTY6xASblN2keDvaGT29ljCQWhBQoLXSrNn4D&psid=6929229773793413&width=1024&ext=1703445466&hash=AeTy7Y4FZcb56GXbEm0",
      "fb_username": "Carlos Guillermo Rodriguez",
      "fecha_ingreso": "2023-11-24 13:17:12.266330",
      "intentos": 2,
      "ultima_fecha": 1700853471
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6930775817020439": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGhF-4V9SXm5u-mikcjzWA9wgqHUB23Ovx7GEtI1jKrlluzPcvFkMvi8FX_it0NdN2wwfnAXKIf&psid=6930775817020439&width=1024&ext=1711316515&hash=Afplh7y-uv4_LNNB8EEJ3SZfACIvQlK8kUsB_kBPE40ktA",
      "fb_username": "Andrea Fabian",
      "fecha_ingreso": "2024-02-22 21:34:02.051142",
      "ultima_fecha": 1708724516
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6937276806321997": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG-hqRwu65GLm0Ya35k0-lje35jdBsZ7YdIENPAWnQI7jniY0UBdsuhwZCWFoh0tJ_r_KQP1k_U&psid=6937276806321997&width=1024&ext=1708874679&hash=AfqUVLYSZGPVIYP5PH0R3APOhIb_SlF2S8G7mr3qHpoOnQ",
      "fb_username": "K-rloz Andres",
      "fecha_ingreso": "2024-01-26 09:23:29.955270",
      "ultima_fecha": 1706282680
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6939554292750712": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6939554292750712&width=1024&ext=1694619126&hash=AeR73jvikQ9DAy9yUqE",
      "fb_username": "Renatho Marquez",
      "fecha_ingreso": "2023-08-14 09:31:48.077316",
      "intentos": 0,
      "ultima_fecha": 1692027128
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6946859088704018": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEoatwoYLK-WY-umvyjifhDE2hVy-E8xew68gXPaBsucn6Ip2vxq7Gv2mPwnIkFaxzG7l4aE7r5&psid=6946859088704018&width=1024&ext=1709929407&hash=AfoendH9YwzvKYFkTDVmC4QlQJeqfutYB-EzlAgeY5xe4w",
      "fb_username": "Marco Colop",
      "fecha_ingreso": "2023-11-14 15:02:34.561880",
      "ultima_fecha": 1707337410
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6950568621670911": {
    "atributos": {
      "Correo": "manoly2203@gmail.com",
      "Gestion_numero": "116623-2023",
      "Gestion_problema": "duda con el rechazp",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Licenciada Nora Cruz",
      "Telefono": "53192549",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF8hkkEJILs9KTsxzsjdpM9Znh3mEFUfApvqAeXTQt6G6k2A_JBQgb-coNI6_LG-wRjGuRJ1WCH&psid=6950568621670911&width=1024&ext=1702841734&hash=AeRlvVRfuUfVjkvrCu8",
      "fb_username": "Noritha C. González",
      "fecha_ingreso": "2023-11-17 12:09:22.315745",
      "ultima_fecha": 1700249734
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6951025234933900": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6951025234933900&width=1024&ext=1699462816&hash=AeQhh5c0Wpn8P2Ozy4k",
      "fb_username": "Eunice Garcia Alvarado",
      "fecha_ingreso": "2023-10-09 10:55:28.452237",
      "intentos": 0,
      "ultima_fecha": 1696870817
    },
    "user_posicion": {
      "bloque": "Auxiliares_Asamblea_Acciones",
      "posicion": 1
    }
  },
  "6956143611085810": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6956143611085810&width=1024&ext=1676648881&hash=AeQcJfM4_XVxOLlB_6A",
      "fb_username": "Any Sanchez",
      "fecha_ingreso": "2023-01-18 08:50:36.330714",
      "ultima_fecha": 1674053619
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "6957289474399030": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFYunTh7VWWUdd6yNbSY4WdNhsbA0dJlurvGxWPwnWzZUQlcofw4M_q3u3qbj2JTNr2AXdsgiWb&psid=6957289474399030&width=1024&ext=1712507959&hash=AfrQ8BdUjWTUEihb5H5978foFKLTEz9HrnpyW4j12smnwQ",
      "fb_username": "Barrios Brian",
      "fecha_ingreso": "2024-03-08 10:38:35.544760",
      "intentos": 1,
      "ultima_fecha": 1709915961
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6962139130467864": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6962139130467864&width=1024&ext=1682871611&hash=AeQonPP7eFpj4uMBETY",
      "fb_username": "Alex Solís",
      "fecha_ingreso": "2023-03-31 09:01:23.125134",
      "ultima_fecha": 1680275814
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6966060363415634": {
    "atributos": {
      "Gestion_numero": "97698-2023",
      "Gestion_problema": "Ver el estado de mi trámite",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Eduardo Cifuentes",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF2bOGtMwE4C3SGhEkvPY0bOZsY-FF4Prb0shmRZyrmTykQ5Ofrx4ZADIeOps2dy9HAWpnDw7z-&psid=6966060363415634&width=1024&ext=1700062845&hash=AeQLMBZidjkOfd5_BxM",
      "fb_username": "Estuardo Castañeda",
      "fecha_ingreso": "2023-10-16 09:27:29.954166",
      "intentos": 0,
      "ultima_fecha": 1697470846
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 5
    }
  },
  "6969804353102573": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF5V5_rjSzUTY1n_2cwtz7GxkDufP_5UBsUnHtn9JOaJAAUCVLt_OoFheY_FrZiTgXbggXrbHRf&psid=6969804353102573&width=1024&ext=1708016037&hash=Afrax9VbBJVBFl8w8WeA73PeWAjMCogbEVNBam5bjAbWqw",
      "fb_username": "Elvia Hernandez",
      "fecha_ingreso": "2024-01-16 10:53:38.027157",
      "ultima_fecha": 1705424039
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6970601792960385": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6970601792960385&width=1024&ext=1695575225&hash=AeSwCg5-IRNke6asM1s",
      "fb_username": "De Lopez Mayrena",
      "fecha_ingreso": "2023-08-25 11:05:38.071227",
      "intentos": 0,
      "ultima_fecha": 1692983227
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "6970621029694905": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGRoOkf7PZGREqY10_F7293XR3KrvEnvZ3jYnNPY96pYxobMpubEml7ku8_T2rvZJyHQ8BKWk7f&psid=6970621029694905&width=1024&ext=1704300556&hash=Afq7QCPc2J-XkLQb8lsUI8mRJzI39NJzuhATB5xOoOEErA",
      "fb_username": "Claudia Iliana Castillo",
      "fecha_ingreso": "2023-12-04 10:46:48.537322",
      "ultima_fecha": 1701708558
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6972322766136017": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHRyQICraB5CJOIr6BuyMtKeb6rCnVomRSenqolVboS0nLjWCC5k4Df7VObCN1cMQ1cTTpr0v99&psid=6972322766136017&width=1024&ext=1705094193&hash=AfrJ-GTqdy1pfR3Hm2fhXw5ckIZ8RHLpFYvhQ9EEdNDRfA",
      "fb_username": "Heydy Abac",
      "fecha_ingreso": "2023-12-13 15:12:12.060063",
      "ultima_fecha": 1702502195
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "6978757425485813": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6978757425485813&width=1024&ext=1690146421&hash=AeRIQXQVpG6iN68bwzg",
      "fb_username": "Paz Geo",
      "fecha_ingreso": "2023-06-23 15:07:01.166392",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1687554425
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "6979096448878746": {
    "atributos": {
      "Gestion_tipo": "Auxiliar de comercio",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEJ2c22O1ZoITFgwAXWtReUUab7zkOYMY7-y1DwjjUktD9-OsDHWntjgFdgOysPWItPN06A9hBA&psid=6979096448878746&width=1024&ext=1713460641&hash=Afq8nZDAg9YA1xYirNZqVWRvTy6W06Q_ag5vUMonRJBCTQ",
      "fb_username": "Guisela González Recinos",
      "fecha_ingreso": "2024-03-19 11:14:47.303932",
      "intentos": 1,
      "ultima_fecha": 1710868644
    },
    "user_posicion": {
      "bloque": "Auxiliar_inscripcion",
      "posicion": 1
    }
  },
  "6981899991918385": {
    "atributos": {
      "Correo": "angelica.morenopadilla1982@gmail.com",
      "Gestion_numero": "Patente de comercio",
      "Gestion_problema": "Patente",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Angelica Moreno",
      "Telefono": "42851098",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHd4ZIEOVhzP1GpqhbGYrdoIzi8A5ab4Wf5f4iu97Cgft5_meX8zn_8qoQx212uR1-mYSzmROdo&psid=6981899991918385&width=1024&ext=1712867955&hash=Afojc0kHcW2E3t49JKFIZABUThh9IcK150-xgm_GVH0Slg",
      "fb_username": "Mariela Moreno",
      "fecha_chatcenter": 1710275950490,
      "fecha_ingreso": "2024-02-15 12:30:13.976976",
      "leido": false,
      "log_chatcenter": "-NsoNzUMEkBe91wTrBIj",
      "msg_noleido": 1,
      "ultima_fecha": 1710275955
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "6984291621680684": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGZ4SEpXovAycGBHOOgU7kWhCMXAhjpF6RUqIrLUIpfziAjqA9mPbeVYZyXglTok98UspXw6cnI&psid=6984291621680684&width=1024&ext=1713372171&hash=AfoRpSiCSUCasXo_LLyiE18a1Q5ibpybJvRwrOw6yhq2uw",
      "fb_username": "LN RV",
      "fecha_ingreso": "2024-03-18 10:40:56.564456",
      "ultima_fecha": 1710780174
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6986301368047754": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6986301368047754&width=1024&ext=1696180024&hash=AeQmQJRzTKJZ0llMNLU",
      "fb_username": "Pablo Juam Morales Castillo",
      "fecha_ingreso": "2023-09-01 11:05:33.902318",
      "ultima_fecha": 1693588025
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "6987232368022839": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGEmgPqtJdXwUDomBORYYBCVjKygru178EsdQmpKhBqMWMN5Cx6eQO2zV1WUG4iwkW_v9kvj63G&psid=6987232368022839&width=1024&ext=1708792678&hash=AfqHQ0pyUmCeF5V7MRr8oXdRC5gFM30gO8_suAamA68h6Q",
      "fb_username": "Santi Gabriel",
      "fecha_ingreso": "2024-01-25 10:37:27.452820",
      "ultima_fecha": 1706200679
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "6987238301334653": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFL-YelY2th1wN5_EnvODKozFzO8ALrljga9qh-C6ZyG_pxxTN9K1q4SqaqX8r8E2SwvoEesiZR&psid=6987238301334653&width=1024&ext=1702587116&hash=AeQjNo-4su5jENjtQEw",
      "fb_username": "Adriana Vargas",
      "fecha_ingreso": "2023-11-14 14:51:22.216615",
      "intentos": 0,
      "ultima_fecha": 1699995117
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "6988735564579188": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHqp5YPy2jJd6qF62c33edg5iT5phNiev8RpICLZHWdV0K0E_Hh6YQpdn7oPCLFuaJvZI4E6s-j&psid=6988735564579188&width=1024&ext=1708213334&hash=AfrNXkmwP9fV8EoWIvliUguk3LVNoHmeZybI0aFAX8pMSg",
      "fb_username": "Roger Mendizábal",
      "fecha_ingreso": "2024-01-18 17:40:25.633086",
      "ultima_fecha": 1705621336
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "6992353520834335": {
    "atributos": {
      "Correo": "rodolfocj4198@gmail.com",
      "Gestion_numero": "32752219",
      "Gestion_problema": "me aparece esto y no se quedo de hacer \tEL EXPEDIENTE SE TRABAJO A TRAVES DE E-PORTAL DEBE PRESENTARSE EN VENTANILLAS DE E-PORTAL",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Edwin Contreras",
      "Telefono": "38382257",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHaTcq-08u5lVctUzaVYZWRda159T7h9WutbliTTTV5SeRCvhjdzntbsmIR-RAD1Fm0hugoW6fF&psid=6992353520834335&width=1024&ext=1702489670&hash=AeTYnhUVhPO-jMTO-gU",
      "fb_username": "Rodolfo Jimenez",
      "fecha_ingreso": "2023-11-13 11:41:27.818033",
      "ultima_fecha": 1699897671
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "6999659043462351": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE11KNy730gXBmRq9DTMHnKJ908lvRwZ95DvHHMyL2ttPOpSP9AV3KVGYCtm8lMw8LDBLYyk76j&psid=6999659043462351&width=1024&ext=1701359841&hash=AeSvbuQ_vs4un9VIbOs",
      "fb_username": "Michelle Dávila",
      "fecha_ingreso": "2023-10-31 09:56:47.368297",
      "ultima_fecha": 1698767842
    },
    "user_posicion": {
      "bloque": "Pagos_en_linea",
      "posicion": 1
    }
  },
  "6999816493414748": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=6999816493414748&width=1024&ext=1699658592&hash=AeReW9GfRgV_hoZCVj0",
      "fb_username": "Ana Lu",
      "fecha_ingreso": "2023-10-11 17:22:12.449034",
      "ultima_fecha": 1697066595
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7000320973349147": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "Solicitud de certificación fallida",
      "Gestion_problema": "Solicitud de certificación fallida",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Gerardo orozco",
      "Telefono": "56924102",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEnmKHeja3Ibs5_pQpvS7Nwr7Q43aEsXFDQHwWmhHamwkIhg-YVw2OA2_oraWdcDDr3EXc4foqv&psid=7000320973349147&width=1024&ext=1708289333&hash=AfpAdBKCTJGS2dMvUfSe491en9K_VSHnlewy5Z3QJGj2Sg",
      "fb_username": "Luis Xec",
      "fecha_ingreso": "2024-01-19 14:42:42.567439",
      "intentos": 0,
      "ultima_fecha": 1705697333
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7003305206351757": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7003305206351757&width=1024&ext=1685208946&hash=AeQN6pJJMjExRLhuoaI",
      "fb_username": "Rocío Miranda",
      "fecha_ingreso": "2023-04-27 11:34:48.129981",
      "ultima_fecha": 1682616949
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "7006906275995066": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7006906275995066&width=1024&ext=1694622730&hash=AeRJZ_qkn39I-9-25Kg",
      "fb_username": "Verónica García",
      "fecha_ingreso": "2023-08-14 10:31:41.762173",
      "ultima_fecha": 1692030732
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7014502008562005": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7014502008562005&width=1024&ext=1696723019&hash=AeTthVZahXFqVWSWF6U",
      "fb_username": "Mario A Chiroy",
      "fecha_ingreso": "2023-09-07 17:49:17.895223",
      "ultima_fecha": 1694131022
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7016830521689261": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFzrLlVNuhMRK3Qcp0wrHHPx6cfRI1nsHXCLWPBLEO7zvOmYOuNeUmVhS4rXbILU8PmFeLUS2fO&psid=7016830521689261&width=1024&ext=1704479746&hash=AfpQVoOV6qBg3qQ3GacSbHc1zaSnHOxvKftKPA0DwdWq9w",
      "fb_username": "Luis Fernando Iriarte",
      "fecha_ingreso": "2023-12-06 12:35:13.696800",
      "ultima_fecha": 1701887748
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7018975368170614": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEJi3FL-AbkcsbXJAh6eT5faokBbmpz4RXRAL-yHuw-ZaW5LhZU2pHlzHYPDcUy2u4FMlvg1nTU&psid=7018975368170614&width=1024&ext=1700671990&hash=AeS4IyqNmEdLF-elek0",
      "fb_username": "JuanMa Valenzuela",
      "fecha_ingreso": "2023-10-23 10:52:09.088991",
      "ultima_fecha": 1698079991
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7021496374564935": {
    "atributos": {
      "Correo": "Cristian-reyes1523@hotmail.com",
      "Gestion_numero": "Ninguno",
      "Gestion_problema": "Regresar",
      "Gestion_tipo": "Electrónico",
      "Nombre": "34046-2005 no puedo imprimir la razón ELECTRONICA",
      "Telefono": "Cristian reyes 40036748",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF8te4p6LoojdR-vECo3H7BdQNPbNMXFZu2lcKbRnDOm31wAoVZ7q1BDRko46cSQYnEaQDs_jGP&psid=7021496374564935&width=1024&ext=1710704289&hash=AfryPVxEAkSzWKQbqrrYZXNhyykjvMWK6AmEbfwVc27iYQ",
      "fb_username": "Cristian Reyes",
      "fecha_chatcenter": 1708106116197,
      "fecha_ingreso": "2024-02-16 11:50:03.068153",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-Nqn2imMgByneb4O-YjX",
      "msg_noleido": 0,
      "ultima_fecha": 1708112289
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7023932877657013": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7023932877657013&width=1024&ext=1699116057&hash=AeRQXxAVO8GhhRoPtag",
      "fb_username": "Jes Sal",
      "fecha_ingreso": "2023-10-05 10:39:44.143091",
      "ultima_fecha": 1696524059
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "7024434860975329": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEgJLCYv0HmecEro5fA77oSwLSANxqfQAkSAFoqNWgLrv98UpRRrabNXAhfG1oobbnH3S5rp_nb&psid=7024434860975329&width=1024&ext=1707715460&hash=AfpXKzwzSQIzPgExNWRW_KGGmN9pj1auhbKDNYBHGehgRg",
      "fb_username": "Silvia De León",
      "fecha_ingreso": "2024-01-12 23:23:24.812620",
      "ultima_fecha": 1705123462
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7026116664141017": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGZgBgIFGj47lOxwbc8xOnDFBRmAKaQH-CVniRUs91T-iPcp-Iga7azF0qTiM_ubI5epGpcPwuN&psid=7026116664141017&width=1024&ext=1706479275&hash=AfqRU4bbNFJZGgiXiMRzGk_TuxoziVDWNO_Rw3XhyKlbmQ",
      "fb_username": "Omar Hernández",
      "fecha_ingreso": "2023-12-29 16:00:29.756260",
      "intentos": 0,
      "ultima_fecha": 1703887278
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7026346290737838": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF8x-CdqSRUR05f1te80vWSPrvE0M2cbR2PsHV_Xdtfq4TCiDE8fiiJu2QV_tfJCxPR_VM-IAaD&psid=7026346290737838&width=1024&ext=1702574192&hash=AeSDfE_gR4Y38CdU96Y",
      "fb_username": "Lincy Guillen",
      "fecha_ingreso": "2023-11-14 11:16:02.947492",
      "ultima_fecha": 1699982195
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "7027553757330045": {
    "atributos": {
      "Correo": "Alela251994montenegro@gmail.com",
      "Gestion_numero": "https://scontent.xx.fbcdn.net/v/t39.1997-6/39178562_1505197616293642_5411344281094848512_n.png?stp=cp0_dst-png&_nc_cat=1&ccb=1-7&_nc_sid=fc3f23&_nc_ohc=HcM54he3vxQAX8p8Whs&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=00_AfBrNNH-g-e6qfk2TvnKAjdZpACnWN4UObZDKKAfp-sYjg&oe=6589CC4B",
      "Gestion_problema": "https://scontent.xx.fbcdn.net/v/t39.1997-6/39178562_1505197616293642_5411344281094848512_n.png?stp=cp0_dst-png&_nc_cat=1&ccb=1-7&_nc_sid=fc3f23&_nc_ohc=HcM54he3vxQAX8p8Whs&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=00_AfBrNNH-g-e6qfk2TvnKAjdZpACnWN4UObZDKKAfp-sYjg&oe=6589CC4B",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Alejandra Nimatuj",
      "Telefono": "55637207",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHtHnSyVvrrYrU6I-BargV4zs5pQDQheAJgm8hb9Mj3pNXV439NnjB6NY1c4f-wUIjhd4pBViJa&psid=7027553757330045&width=1024&ext=1705786611&hash=Afo6tRWFbdB5P5j6b6J_3aKmLd8N3TMZSKgkemGxUNlrUA",
      "fb_username": "Ale NimaMont",
      "fecha_ingreso": "2023-12-21 15:07:46.982395",
      "ultima_fecha": 1703194611
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7029018343876690": {
    "atributos": {
      "Gestion_numero": "Estará abierto hoy?",
      "Gestion_problema": "Gracias.",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHbIToY5ZrtkCevK4_QX1UPGs-G_KCcoNgjd4_iVNED2fzXSt0qB6RsPzQloyGr1AicDY1KIGWa&psid=7029018343876690&width=1024&ext=1714567564&hash=AfpO7iYnUt8eXVxCr0gQuZpl67mkgKsjiEUVkQEdj4-Eng",
      "fb_username": "Elsiton Alberto Blanco",
      "fecha_ingreso": "2024-04-01 06:44:45.659894",
      "ultima_fecha": 1711975567
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "7033415310014284": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG40gCfzQSs-L89_U3UVPhyRDtzwlROH2hFCprU1oQ6kUJWow6UQh4sRvY0KB0ErEMGFUhNpFoH&psid=7033415310014284&width=1024&ext=1703293670&hash=AeSD1KkdfIFGybqBw0E",
      "fb_username": "Diego Bladimir Hernandez",
      "fecha_ingreso": "2023-11-22 19:02:47.754365",
      "intentos": 0,
      "ultima_fecha": 1700701672
    },
    "user_posicion": {
      "bloque": "ReposicionPatente",
      "posicion": 1
    }
  },
  "7034508393322046": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFROY4ukeXqlJJKCBVSftYf_W6ybj0amqbtkzqMTT0CtO4Ljfu0JcqRabAfYBSkipiAdvuJ19xp&psid=7034508393322046&width=1024&ext=1715271358&hash=Afq8eU6wG0fd2KNav4GEgiKBi9K7JE4E8VpFlPPPSSYZZw",
      "fb_username": "Joshua Tuluxan",
      "fecha_ingreso": "2024-04-09 10:13:33.461883",
      "intentos": 0,
      "ultima_fecha": 1712679359
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7044346662299419": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFq6dnP29DmOQXWO6wSQVEbuAnCwI0SQKYZHyNFAQDVNcJvku-a0tqqYN9kukWzRlknq3Y12L3H&psid=7044346662299419&width=1024&ext=1703688190&hash=AeQZ_U_F3wPNbHyPvfw",
      "fb_username": "Mariana Zachrisson",
      "fecha_ingreso": "2023-11-27 08:42:59.644629",
      "intentos": 1,
      "ultima_fecha": 1701096192
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7048100261888780": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGsz_m94XvLDu1491f6LOsYKr3rVbRRaZNYcnYTjV37wUAvLb9qmMyNsyz3rcLwuauYBa9leRku&psid=7048100261888780&width=1024&ext=1701020366&hash=AeQsJKgbah4gnq5poqQ",
      "fb_username": "Sherelin López",
      "fecha_ingreso": "2023-10-18 10:56:01.846452",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1698428370
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7048963231806548": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHltHb3qjl_9KyNo9GojKvzic020eC7UDdnzyyDAUpfi6IUlygR5bb17-gomxZXHQa9vwuf1EMG&psid=7048963231806548&width=1024&ext=1708278811&hash=Afo9nIpXMITGdQbk5ZUCW-5sx0CZmRtqhk9rScMIRcZYeg",
      "fb_username": "Douglas Gómez",
      "fecha_ingreso": "2024-01-19 11:50:26.947730",
      "ultima_fecha": 1705686813
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "7066943513341854": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHrugH-g9bcDO7qiZLDRUfN0kdYMl4WcDIKpjWEWtlzN6TG_-Mz84daom3KO6oeK17yv5mICIwa&psid=7066943513341854&width=1024&ext=1709227286&hash=AfqdAygvzdp8blcS1gdpncTxpM3EJ5TDL1x4EsU3ynaLIA",
      "fb_username": "Marisa Mejía",
      "fecha_ingreso": "2024-01-30 11:20:51.925422",
      "ultima_fecha": 1706635288
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7067246350055419": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH20u_PR8psr6nZzeaNH1xBT54wmfZnkBlekRxQXPRBd_vA2YLudotZk62IfGh7fHzL-1wmmNab&psid=7067246350055419&width=1024&ext=1707249724&hash=Afoz7EEjIZfRm8fTnPZZAPNt4LQ9ElQhmz9G6DEWU28w0Q",
      "fb_username": "Sergio Calel",
      "fecha_ingreso": "2024-01-07 14:01:49.529455",
      "ultima_fecha": 1704657726
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7067591483352247": {
    "atributos": {
      "Gestion_tipo": "Fisico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGecjaXBsBUAp7s9bxj0in0lRm5Ch05W5nmDXHg4IUSl1lHKjjrypQwkmjsO6zHhbMXOZH-gr3z&psid=7067591483352247&width=1024&ext=1713973139&hash=Afp8vSKK7Cfxm-IwgyHeZCec6XMRpMLl9Iu2FnaH_s_ldQ",
      "fb_username": "Bella Chavez",
      "fecha_ingreso": "2024-03-25 09:37:41.102627",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1711381142
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7067989566566015": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7067989566566015&width=1024&ext=1698337742&hash=AeRvwKffwZAC1A3TIIM",
      "fb_username": "Michelle Montalvo",
      "fecha_ingreso": "2023-09-26 10:28:17.673687",
      "intentos": 0,
      "ultima_fecha": 1695745745
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "7068747899841733": {
    "atributos": {
      "Correo": "lsayoficina@gmail.com",
      "Gestion_numero": "12538-2024",
      "Gestion_problema": "No han trabajado el nombramiento",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Brenda Say",
      "Telefono": "41134168",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFSfElJwO8N3qnbuOWMAzB1WtC9N5cvxLQjceApnz5zKq2cOrmHZuyb9vQiDEy5ut6Su1xigT12&psid=7068747899841733&width=1024&ext=1711906810&hash=AfoQeTvcwr71K3m_j0z2NXW6ST794DN39K_HfI8tSGG09g",
      "fb_username": "Cony Dí",
      "fecha_chatcenter": 1707510132266,
      "fecha_ingreso": "2024-02-09 14:17:55.146187",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-NqEXDthMaRZodhSISTR",
      "msg_noleido": 0,
      "ultima_fecha": 1709314810
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7069441746442367": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFoaZY0pPJZXp61RGJTpmUnXNMJAK5MetszCmMcQYDejgVe3kBnzKWIfobH2bq8XvtuW9evo9rw&psid=7069441746442367&width=1024&ext=1705848890&hash=AfrVhseLE5gJCEgLVS7xDarOopobP6WgzOT8tgFKBkdNyg",
      "fb_username": "Kevin García",
      "fecha_ingreso": "2023-12-22 08:53:55.290120",
      "ultima_fecha": 1703256893
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7072104572803716": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7072104572803716&width=1024&ext=1690731027&hash=AeSS0Hk9JtxDn-p1WIA",
      "fb_username": "Ross De Vásquez",
      "fecha_ingreso": "2023-06-30 09:30:27.911802",
      "ultima_fecha": 1688139032
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7074270099293168": {
    "atributos": {
      "Gestion_numero": "Pero tengo el inconveniente que la patente tiene dos personas pero solo esta el titular",
      "Gestion_problema": "No se si debe llevar los dos documentos",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEB7SKegJ3DPyhP-DSAiHhXk1YJ9wqaGDFZ9khAeANYAF8zKFCaLJnYrI2Yg8tlRtqymCd4EpZG&psid=7074270099293168&width=1024&ext=1711904186&hash=Afq_yNrJtZTJAwLevoDOcSfAXwPICcejgHKu1csbEbopbQ",
      "fb_username": "Aislin Lopez",
      "fecha_ingreso": "2024-03-01 10:52:48.864016",
      "ultima_fecha": 1709312187
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 4
    }
  },
  "7074688442586799": {
    "atributos": {
      "Correo": "JOSUE.CH01@HOTMAIL.COM",
      "Gestion_numero": "21676-2024",
      "Gestion_problema": "Mi trámite fue rechazado por tener mal la dirección. El comentario del asesor dice \"verificar y reingresar\". \nPara reingresar el trámite, ¿Otra vez debo pagar en el banco, o con el primer pago puedo volver a presentarme?",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Josue Ackner Chicoj Vásquez",
      "Telefono": "38852019",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEOt4WPYKi5iZmSe3FAFmaRpa56sSvW2gxadJtyKH0NM2Cv0IFDU1ZyWGEpaA9xVdAKRXFWTfXA&psid=7074688442586799&width=1024&ext=1711300946&hash=AfqJlzXcTV49fGJs2YvDke7NQclLknQHhSMPgSynMArOPA",
      "fb_username": "Edwin Cardona",
      "fecha_chatcenter": 1708708045999,
      "fecha_ingreso": "2024-02-23 11:03:29.111893",
      "leido": false,
      "log_chatcenter": "-NrLvuIdoZCyVeZvYPDA",
      "msg_noleido": 0,
      "ultima_fecha": 1708708946
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7078649818863882": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHhQ-1aVFxsupHklys68viOLX0vErQXEvOnkZKv-ATcSWr2WlZ8mu9-WLBk4BGmc4UB_JG4lxmy&psid=7078649818863882&width=1024&ext=1702133093&hash=AeSH0an6lH6O5GYseyg",
      "fb_username": "Gerardo Rizzo Arrivillaga",
      "fecha_ingreso": "2023-11-09 08:44:53.664639",
      "ultima_fecha": 1699541098
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7080398275318277": {
    "atributos": {
      "Correo": "roxy_br@hotmail.com",
      "Gestion_numero": "32735790",
      "Gestion_problema": "Rechazaron el expediente por lo siguiente: LOS DATOS DE INSCRIPCION QUE DEBE DE CONSIGNAR SON DE COMERCIANTE, NO DE EMPRESA. Sin embargo, el formulario y el pago fue realizado como indica el portal del registro",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Tamara Calderón",
      "Telefono": "47000245",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFDo8U0pre5vQ4SVM0XHiA-ZJzT0lgDypVprDsxnkuHSyKEb74GJCP8OhYuFHW_Q4ehZkqZMhFI&psid=7080398275318277&width=1024&ext=1705593830&hash=Afqmhlnnk4ip39QMPC10QOl517JVqWdQbgsI-gH_Rk959g",
      "fb_username": "Tammy Calderón",
      "fecha_ingreso": "2023-10-04 08:30:08.810700",
      "ultima_fecha": 1703001832
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7080573375390333": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGhxrUliP24uJ0ersw-gFXl_2wEqBkJm68hL_rqPIf-Se5nt-gqQBhbIKnGZJY2g2evp1A2Bzfl&psid=7080573375390333&width=1024&ext=1708630104&hash=AfpNPmrzUAy0c7r2ORsIoGq-m-M1eGWp2Mj08A1ff4Dq4A",
      "fb_username": "Miguel Gómez Cabrera",
      "fecha_ingreso": "2024-01-23 13:28:14.683682",
      "ultima_fecha": 1706038106
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7093324577345048": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGPpcNJP_SNK7JWaSHwVI3grjaCaN0YpDO_LxmTLYVnsPtsxc7RKOsr19sANtcy_Y2hH49w19SK&psid=7093324577345048&width=1024&ext=1700255030&hash=AeS1wWklyr_Oygjho8s",
      "fb_username": "Luis Diaz Overdick",
      "fecha_ingreso": "2023-10-18 15:03:21.476591",
      "ultima_fecha": 1697663031
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "7096331867159659": {
    "atributos": {
      "Correo": "Lucydexulu@hotmail.com",
      "Gestion_numero": "Necesito ayuda",
      "Gestion_problema": "Ingrese solicitud de libros carge la carta de autorización y dpi del representante legal y la solitud firmada por mi y me lo rechazaron x",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Lucia garcia",
      "Telefono": "55814565",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGg0ZpbQ5VagO1IWSliFZgZLJob_lBEjyn8SRm-T4PGh-bSjydVeTrHLvacfAExGPXOGMKGqnBb&psid=7096331867159659&width=1024&ext=1715356671&hash=Afp40DItIsicgR9DhkAbruFC9-_NIC3VyCNxd_eCBr-65A",
      "fb_username": "Lucy Garcia Villanueva",
      "fecha_chatcenter": 1712761525434,
      "fecha_ingreso": "2024-04-05 11:36:48.184908",
      "intentos": 0,
      "leido": true,
      "log_chatcenter": "-Nv7XhIev2ZFnBHPKKpu",
      "msg_noleido": 0,
      "ultima_fecha": 1712764671
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7098605563522571": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFvBjEvgteJNAejQ6Y5vhe1Rwo_iBcSL36w4298WIS_g6bHFd2chUV6ICZsHGZfH94jv4uFhidq&psid=7098605563522571&width=1024&ext=1710178883&hash=AfrVgQPSe3rKYrRW8PN9SXHZ8fAg-8S4Ugl8R8NUQVvD8g",
      "fb_username": "Alisson Méndez",
      "fecha_ingreso": "2024-02-10 11:31:02.015590",
      "ultima_fecha": 1707586885
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7101176496611354": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "Buena tarde",
      "Gestion_problema": "Estan trabajando las sedes departamentales del Registro Mercantil",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Henry González",
      "Telefono": "41729977",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFixGzXV_LpH8GXkzbVLLjcwPR0utkPndTfXrM_O8G3L0_QQQgZ1UgezrBB5w0xPZaCOzV-1ZXP&psid=7101176496611354&width=1024&ext=1713993781&hash=AfpKW3sOu3bKWhnqmkdzeDQ8dNtEPjMwo_USfbqR99w28Q",
      "fb_username": "Henry Gonzalez",
      "fecha_ingreso": "2023-12-12 06:23:34.309177",
      "ultima_fecha": 1711401782
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7103942116309465": {
    "atributos": {
      "fb_picture": "https://scontent-iad3-1.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=810bd0&_nc_ohc=1IEA7QUVPPQAX8nAE9k&_nc_ht=scontent-iad3-1.xx&edm=AP4hL3IEAAAA&oh=00_AfAi64CxXTKceXtxzG7NGpQdF8FFnnjtpSi4Rqpi_YPymA&oe=65F87719",
      "fb_username": "Leandres Telns",
      "fecha_ingreso": "2024-02-17 14:39:50.085367",
      "ultima_fecha": 1708202392
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "7104644599580323": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHBZfI72-8QC7QRNQCxN55Q_wDw2PQmjgHj_4JSpG6CqUMy_BKRfZT1WXH5MI73bOcuj7rzCw_4&psid=7104644599580323&width=1024&ext=1700062157&hash=AeSiP6Hw707nKIKlve8",
      "fb_username": "Thoni Cruz",
      "fecha_ingreso": "2023-09-25 20:41:02.096357",
      "intentos": 0,
      "ultima_fecha": 1697470158
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "7105392992839521": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFYeI3FTBJ7Ajeq9dBwQ4ndGNdC6zrDs45qHqVt5OgKG0p-Skm1ODfuuFj4kEQG3mGgDhfNt9IN&psid=7105392992839521&width=1024&ext=1709235161&hash=Afor9yRY0ziF-WrLkOhVcTu0lYvlxA4HMm25WDED9Zow7Q",
      "fb_username": "Rick Orlando",
      "fecha_ingreso": "2024-01-30 13:31:45.348949",
      "intentos": 0,
      "ultima_fecha": 1706643164
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7109329495757076": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXENPnY_q6lZbObZcdsAcqaaGmx5cmIlOqMJtW0J5Sglu0OxF0mm9VyzUlj9adPjMEWINC46_T97&psid=7109329495757076&width=1024&ext=1706374798&hash=Afp_cwUqQ8OcwQaFzDhblbdkuqaQ5cjIAb4VlO4ZA-Sn_A",
      "fb_username": "Ana Vasquez",
      "fecha_ingreso": "2023-12-28 10:54:46.329180",
      "ultima_fecha": 1703782801
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7111083995665160": {
    "atributos": {
      "fb_picture": "https://scontent-yyz1-1.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=810bd0&_nc_ohc=o-ol1RTwZfgAX8mSOOR&_nc_ht=scontent-yyz1-1.xx&edm=AP4hL3IEAAAA&oh=00_AfDFdpyWHCw5UCgXRCUKU64_lCBAnjitQXkZzsqJt3TQbg&oe=65F0FE99",
      "fb_username": "Yul Martínez",
      "fecha_ingreso": "2024-02-11 21:14:22.454155",
      "ultima_fecha": 1707708058
    },
    "user_posicion": {
      "bloque": "InscripcionSociedad",
      "posicion": 1
    }
  },
  "7115314401825584": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFY6GCxG-WKImHuRZPaUn-DrxPTK-itJoFkVXyBE3Hz42-iEvoe-S9OvcvoEoWKWtPurCbjjmcf&psid=7115314401825584&width=1024&ext=1701275887&hash=AeTskhfA-sYeFj87wLk",
      "fb_username": "Cristian Montes",
      "fecha_ingreso": "2023-10-30 10:02:25.580352",
      "ultima_fecha": 1698683889
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7117336008359958": {
    "atributos": {
      "Gestion_tipo": "físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGeJkEqmhPqMHsTGrnGfyhyuF_JVh7AS2veYMwFSjPhytpNZncdq53yi1_0xcwf1aw90fA2c57u&psid=7117336008359958&width=1024&ext=1709357888&hash=Afp3juZecJxHDL-ThO2vqVcZm9jOlU7Z6QZaeQiTpEnatg",
      "fb_username": "Eleazar Quej",
      "fecha_ingreso": "2024-01-30 14:17:31.528005",
      "intentos": 0,
      "ultima_fecha": 1706765891
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7120099228098439": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "https://scontent.xx.fbcdn.net/v/t39.1997-6/39178562_1505197616293642_5411344281094848512_n.png?stp=cp0_dst-png&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=mSMopW2giLoAX9dYctS&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=00_AfA2RDyLwMjkNi9_W9-bFdz22Y0ml2AWvitbkiHw269TEA&oe=66085C4B",
      "Gestion_problema": "Quiero saber si un negocio de farmacia para cambiar c\nnombre que se presenta y paga",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Arce Pérez",
      "Telefono": "42253436",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFI3dYN0GquBqHrYF417OoXcBK9hTcnrV8oED3AqFf4SgDoVE5Xug46OS6XP7lD5d16I7voavSN&psid=7120099228098439&width=1024&ext=1714158821&hash=AfqJ-Fkkv84MJk70z13yuJ9RFpTMf5Ig8F332LSmJSvLJg",
      "fb_username": "Debora Barrios",
      "fecha_chatcenter": 1711562996405,
      "fecha_ingreso": "2024-03-27 12:04:07.753536",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-Nu05ffqRBkI637FDp0W",
      "msg_noleido": 0,
      "ultima_fecha": 1711566821
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7129919073702770": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7129919073702770&width=1024&ext=1692299173&hash=AeSRVqcACiWC7pBfqmw",
      "fb_username": "Wendy Rodas",
      "fecha_ingreso": "2023-07-18 13:06:13.583406"
    }
  },
  "7129965620395178": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG8J0MgyyXYCbYR77y0u901SH8xVi39KeJzsVPnEr_257BIOfRRBKWAfMh1UAMg1qJPHEz4jgLz&psid=7129965620395178&width=1024&ext=1704904232&hash=AfpC_nekYs_yG58to39xrAdnq402vk4xsOI07GWP-_beKQ",
      "fb_username": "Scarlet Velásquez",
      "fecha_ingreso": "2023-12-11 10:30:32.608874",
      "ultima_fecha": 1702312239
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7132524170194255": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGcvrAiZ9bCdoDu6rD5FJ6iSkVj6rwYLeaOfaIEPXOwij51XiW9XOR1r6DqgNmesfd4MrhEBQwr&psid=7132524170194255&width=1024&ext=1713549377&hash=AfprYdu5Q9P1ox4Q2E723GhyTB4QtbM1Ynag2NnTTlISDg",
      "fb_username": "Astrid Morales",
      "fecha_ingreso": "2024-02-13 13:27:50.406226",
      "intentos": 0,
      "ultima_fecha": 1710957381
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7135199459894403": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGi5gsLT7NpTsMtD3xwRiuq51TQM9fMKWe3_EhRlJjpUgXPB1NNVvOaPzpfCwOduI1mUNeXaL4K&psid=7135199459894403&width=1024&ext=1707507937&hash=Afr7XfxIiZZ8DokvybcenKRKMEQnHefsEqcGCpol_P4sHw",
      "fb_username": "Angelo Frank",
      "fecha_ingreso": "2024-01-10 13:36:09.428882",
      "intentos": 0,
      "ultima_fecha": 1704915939
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "7137007722977896": {
    "atributos": {
      "Gestion_numero": "aun lo hare",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGD7Yavel8JILWbai-K5jKE9m21RZkUNGI5ibL4nKDIrHM54yNoVqj3JkhCCbcqZJ7XX6cSyzU3&psid=7137007722977896&width=1024&ext=1700175314&hash=AeQ2vqzl-mAa5sQQ3VA",
      "fb_username": "Morales José",
      "fecha_ingreso": "2023-10-17 16:48:52.694482",
      "intentos": 0,
      "ultima_fecha": 1697583314
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7137139599688682": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFyo-8eER-07VE7-uS6N0lwP1qyckm_PX2LFT_YVrKXOm1E7cufbWoT5w9QSBD0FZTrQ-ayhMRp&psid=7137139599688682&width=1024&ext=1714052748&hash=AfpNFf0hjNuPbgauaqQajve_Cf6Kbw4LGhHR5trnh2P1iQ",
      "fb_username": "Jessica Lopez",
      "fecha_ingreso": "2024-03-26 07:45:31.628181",
      "ultima_fecha": 1711460751
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7146581112024894": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7146581112024894&width=1024&ext=1682010730&hash=AeTSngCULsWDXDmDQqk",
      "fb_username": "José Luis López",
      "fecha_ingreso": "2023-03-21 10:09:29.621418",
      "ultima_fecha": 1679414995
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7152121571572452": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGuRG9pOW0WFfCLTXL-CwrqveSVOuKUMJidlZX1qNC4KAnlo0aR4wNXS7kZn0utrlgwZOup2xTL&psid=7152121571572452&width=1024&ext=1713375795&hash=AfrGkAI-taxhU-jEKciakyrGvhVD8-SmdUH8kltFLsOALg",
      "fb_username": "Paolita Morales",
      "fecha_ingreso": "2024-03-18 11:41:38.920370",
      "ultima_fecha": 1710783797
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7152552131494714": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXELMDRuTZa7JSqECMCKoDKRaOCuTGUu4qkeZ1C0h25OaUyY9zLv2p3oOxWYYsnm7z5HVvOeaV3X&psid=7152552131494714&width=1024&ext=1707336329&hash=AfrTDnprhbaVB-JKB4_S75F-U4WBjWMsxtWrpUboQEa-Jg",
      "fb_username": "Francisco Sandoval",
      "fecha_ingreso": "2024-01-08 11:20:37.571469",
      "ultima_fecha": 1704744332
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7153651744730819": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFr8Eh1UJPwG65PcTWZCy8S_vFPwBbIM_PUraVRuLuHijuGv0gssJznvuZZLs4RCk-luA1dYg-3&psid=7153651744730819&width=1024&ext=1710367161&hash=AfrsBK9sg4bvZbMjE5Cu0T6E4m3GrcThDfv2FgQrIALb_Q",
      "fb_username": "Jorge Otoniel Mux Sanchez",
      "fecha_ingreso": "2024-02-12 15:58:07.005772",
      "ultima_fecha": 1707775163
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7154432087945126": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFi5P4Yscspvt-Y1bCjyRsVOKlCEzZm9mcCCduB8jqh6vDeysXMB8gaWxl_eNtNgiGg4DiHtDYb&psid=7154432087945126&width=1024&ext=1712256913&hash=AfoVZ3jOiwIZtNupKjoA0EeQA9yE0YHr48ErmrtdqiehAg",
      "fb_username": "Andy Cruz",
      "fecha_ingreso": "2024-03-05 12:48:18.160439"
    }
  },
  "7157092501084467": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFA_M1Ljx3ZyVG4_hQ7GHp_aEBbynHpXqbng-grpBeZm5_kP4eMctKViMSorCUKRTO0w4YSZMIN&psid=7157092501084467&width=1024&ext=1713398432&hash=AfotpSIv6kRAkj5rC9WAAjk1o9KI4ZDw3rsh4ESH4Z49ng",
      "fb_username": "Emilia Chamale",
      "fecha_ingreso": "2024-03-18 16:09:33.663904",
      "ultima_fecha": 1710806435
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7157614114297034": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHB-HOdNhJX-vaXpcz1EqqO6CTrFD3oOM9Y_zCpluqGwhdO4SiZ0fm41L08GJYJRnyvOCjVy5_i&psid=7157614114297034&width=1024&ext=1705675319&hash=AfoqrkboZ7qoltwu7ne5heaRG9P3rWD2_tQwuNNXCEFptw",
      "fb_username": "Morales Mishelle",
      "fecha_ingreso": "2023-12-20 08:26:37.920890",
      "ultima_fecha": 1703083304
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7158838917473351": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFgQQFeXFHzJ2sv5AbFsOQbYwiuxoQ3PyrJyCRGiHlB1gkULSMwxZswcJbolVhn-bICL64SElAA&psid=7158838917473351&width=1024&ext=1700148126&hash=AeRCj66fuUUBMlr8EWU",
      "fb_username": "Marco Muñoz",
      "fecha_ingreso": "2023-10-13 16:30:35.876959",
      "ultima_fecha": 1697556128
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7159982857347464": {
    "atributos": {
      "Correo": "allenpuluc339@gmail.com",
      "Gestion_numero": "M34",
      "Gestion_problema": "No me atendieron y aún no eran las 16:00 horas, incluso saque ticket",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Carlos Allen",
      "Telefono": "30786846",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHwO9DZ4DIDadDAZfffqmHAu_vhEYt1wtfpqruTS_b5z0DH2lC9aBjm462Y-EyXO2yHh39YDjgV&psid=7159982857347464&width=1024&ext=1701572716&hash=AeQmuu9vm1ou2JdxUsY",
      "fb_username": "Carlos Allen",
      "fecha_ingreso": "2023-11-02 15:57:49.210402",
      "intentos": 0,
      "ultima_fecha": 1698980717
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7160708044012304": {
    "atributos": {
      "Gestion_numero": "Quisera saber si una Asociación todavía existe ?",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHK1AlA7dm8t5-kB9MHKX1rF41bIJjj0eonq9Kc4-6a9X007wDUJKp_-fLzUdO_bCDr07J76A3L&psid=7160708044012304&width=1024&ext=1710700525&hash=Afotq9-ylXG3VL-vekhhqB_LCoeiGCdvrJbcKt_Cc7FopA",
      "fb_username": "Aracely Vásquez",
      "fecha_ingreso": "2024-02-16 12:33:29.227402",
      "ultima_fecha": 1708108526
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7162481113848229": {
    "atributos": {
      "Correo": "Oficinajcsanfco@hotmail.com",
      "Gestion_numero": "Tramite No.32809032",
      "Gestion_problema": "Quiero descargar la patente pero en el sistema no me aparece nose si el tramite ya salio o hay algun problema, a la hora de consultar ahi dice que se traslada a archivo",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "BASILIA HERNANDEZ",
      "Telefono": "35195032",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEMJXsFSFuZAfqbmMecdyUnzNoqO4eowcldgoF1hInl-Tm0-BSJBDeMXeOVNiJralTzivE5jad6&psid=7162481113848229&width=1024&ext=1711052793&hash=Afp3nmEL41cX8GwhPlIK7KScx6gTqzrQ9T0YTkS_2iH6Vg",
      "fb_username": "Chiquilla Hernandez",
      "fecha_ingreso": "2024-02-20 09:58:24.472152",
      "ultima_fecha": 1708460793
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7163698967018154": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHVoHOROeyjSksunDkF2lMyifp3xnyXUqopf74LInhHz64uNR3GK3y9J0Y8qdY1JxGBmP6bxYB5&psid=7163698967018154&width=1024&ext=1713453919&hash=AfplS9h1nLx-NJ4yjip6DrDokQU9M9KRkfTzerEBiI_n_w",
      "fb_username": "Ingrid Villegas",
      "fecha_ingreso": "2024-03-19 09:25:19.923346"
    }
  },
  "7164873246868169": {
    "atributos": {
      "Gestion_tipo": "fisico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHFFW9HvrAO4vPtYvU10o4DWDBncYmDAyjLcoql6vmRLPdFXJROkkgWZ1-pZmcHbDmsx_sXR7YM&psid=7164873246868169&width=1024&ext=1705082263&hash=Afq-cBtsgqV0GOtWIxWGzENCYT36UdxXa8iMbNKtmixMIQ",
      "fb_username": "Nelson Lopez",
      "fecha_ingreso": "2023-12-13 11:57:30.766017",
      "ultima_fecha": 1702490266
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7165886683518467": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHGYx3htqKRuAwCzdUrvbY2ZttSSETkxc7uePzJKFMPHk4Unj8neY5Piwb_JiKxPrGLjU3TdyjV&psid=7165886683518467&width=1024&ext=1712160195&hash=Afo5zpGai1j-AsqrZQblHjNwV18rgY5ISJurAmtLLqrmUQ",
      "fb_username": "Odǝth Ramírǝz",
      "fecha_ingreso": "2024-03-04 10:03:03.977100",
      "ultima_fecha": 1709568197
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7166267513458534": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE4ZC0eFPCK9zM2CEHNHrbCQAD-s-cye39NZzHUqXFrHActvPSXCNihLugmignCzIHYEXmY4Je5&psid=7166267513458534&width=1024&ext=1708315956&hash=Afp7Ws2TZv-VhAFn3pvBH2oiQ6bGBulEuKA2HrmfVCXTaQ",
      "fb_username": "Joaquin Alexis",
      "fecha_ingreso": "2024-01-19 22:12:26.609115",
      "ultima_fecha": 1705723957
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7169481579753365": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEux-7o1n2GrTRMso4sg6bO8_rq63c9nIJbQE6RV9hBavMTHY5IdsUPbzelXBK86CF9ZDJiYl8N&psid=7169481579753365&width=1024&ext=1700157266&hash=AeSsNCvgqlH7hpc_9vQ",
      "fb_username": "Diego Girón Maldonado",
      "fecha_ingreso": "2023-10-17 11:54:01.515089",
      "ultima_fecha": 1697565269
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7172144929534368": {
    "atributos": {
      "Gestion_tipo": "Fue físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJ85_tshpFTmpkwJzvVfhTgdl84g3x8csLWO0ZFWGXDb3Y8B_Q3YmoDcmGQdEyKCHGxe7-QO-X&psid=7172144929534368&width=1024&ext=1710685184&hash=Afqn6mVRzhvN7Ab7DwkMtKnwnB1T6s81_uZJOQxmdi7aRw",
      "fb_username": "Vindue Templados Home",
      "fecha_ingreso": "2024-02-16 08:16:36.557683",
      "intentos": 1,
      "ultima_fecha": 1708093188
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "7177840068899242": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7177840068899242&width=1024&ext=1693065751&hash=AeQ1niy2MZ6gh7xgj3M",
      "fb_username": "Jeremías Enoc Tzunún",
      "fecha_ingreso": "2023-07-27 10:00:56.856385",
      "ultima_fecha": 1690473752
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7179416058786533": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGwJcFDf-ErF04O7iJvflZmdiH132-W5wjLnDLA6HOC0LbpL3PlvNt6E_gREeGTXhaiD9gB3cP_&psid=7179416058786533&width=1024&ext=1707675618&hash=AfoICN3h--8owRQE3xORmTCjiO_aqDOiqr4PlQPSChDjuw",
      "fb_username": "Yusefh Hernández",
      "fecha_ingreso": "2024-01-12 12:19:24.920998",
      "ultima_fecha": 1705083620
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7181647348566774": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG7Rca-W41OZ_-9Z8kxP9Mgnig0tVVGXKr97ZiTKlGwX22nUQgp0DQI-dZ1PZm7q6Zv1hP6CDC4&psid=7181647348566774&width=1024&ext=1703452598&hash=AeS0uWGnJLGcDvcHnrA",
      "fb_username": "Sergio Be",
      "fecha_ingreso": "2023-11-24 15:16:19.507422",
      "ultima_fecha": 1700860601
    },
    "user_posicion": {
      "bloque": "CambioDireccionFiscalEmpresa",
      "posicion": 1
    }
  },
  "7186492051467619": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFx68LsJefsKD1SRtEi0Y6bTjBXqqbWiHGETzCI9d1K2nf6BLHESZPUUWc1NSL58bK1gs3AZk9d&psid=7186492051467619&width=1024&ext=1713971511&hash=AfqW3sh_Fu7fri0oUCY4vyHzsK3m4g3081sAiUh2chiagw",
      "fb_username": "Tziná Andre",
      "fecha_ingreso": "2024-03-25 09:09:55.831055",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1711379513
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7190648987625211": {
    "atributos": {
      "Correo": "Jorgeluisdiciembre.1@gmail.com",
      "Gestion_numero": "32780239",
      "Gestion_problema": "Llene el formulario para la inscripción de patente lo.ingrese a ventanilla pero revise en línea y me aparece con una nota que dice falta \nLa.direccion completa.como debo de corregir eso",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Jorge Luis Miculax Ajsivinac",
      "Telefono": "57129900",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE3azKNPUVRv_8ECdWbpAEB-tz-_2dAH3NU7vMFcvM0TP72r6kD-bWWWbzhuAzLJYnrS_U1ljm9&psid=7190648987625211&width=1024&ext=1706369016&hash=Afq47Ql_uPrwY3poqs3htJR4e-YIFy9DTRZWFXMAjaduXg",
      "fb_username": "Ely Per",
      "fecha_ingreso": "2023-12-27 15:48:53.729405",
      "ultima_fecha": 1703777017
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7191319990927183": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF2Jubfr2HO0KwMpQmACo4iU-k5AuPTk07k3C3RRmQOi1-69dK2Gk82i61t5eYYlNNLC6UbtvbH&psid=7191319990927183&width=1024&ext=1705501707&hash=AfqUH0lABjQvzZrfYpPe0OXmhZK8uTFPN2CYoWuYUyElgQ",
      "fb_username": "Gustavo Rodriguez",
      "fecha_ingreso": "2023-12-18 08:24:20.014171",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1702909712
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "7193610117400146": {
    "atributos": {
      "Correo": "franbosa93@gmail.com",
      "Gestion_numero": "32800426",
      "Gestion_problema": "me indician que el nombre comercial se encuentra consignado 2 veces y no encuentro el error en mi formulario y quisiera saber si el trámite lo puedo subsanar o debo pagar de nuevo los aranceles",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Edwin Francisco Bosarreyes López",
      "Telefono": "43938893",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG8S4MkIcOEyobkPdp2V8kpsHGLxYH7ARcKLi1Ayu27MsOFXMCS8AQ7aUpnH4L9bBvFpyl9HoAM&psid=7193610117400146&width=1024&ext=1709240282&hash=Afp5zb6tyTQWLCyhtNhGW9cvYJiMU_lnoYMcjxWpF6i7yQ",
      "fb_username": "Cristian Juarez",
      "fecha_chatcenter": 1706643288373,
      "fecha_ingreso": "2024-01-30 13:27:08.663340",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-NpQrU4iwxcLQYq2Tgxq",
      "msg_noleido": 0,
      "ultima_fecha": 1706648282
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7201292176595774": {
    "atributos": {
      "Correo": "edgaralva15@yahoo.es",
      "Gestion_numero": "Asesor",
      "Gestion_problema": "Cacelar gestión",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Edgar Alvarado",
      "Telefono": "56986544",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGdazUdOl-oFn48ek_GA7osj-WpK0sM7sxEZlOt4bfaEMo7LtyB2fdkuPlnJsPtZVuUq7BT4uRF&psid=7201292176595774&width=1024&ext=1705781867&hash=AfoiOhzCVwKgfaKpnrmrXMSAy5IJjA4rlgNwjzreTbCVcQ",
      "fb_username": "Edgar Alva",
      "fecha_ingreso": "2023-12-21 14:14:50.692792",
      "intentos": 0,
      "ultima_fecha": 1703189868
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7202663986454083": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFiHzxzEqjSCfz3JDBJzvHB3IERHmNa0rDF7LQa9JdOqmJXVkeYTLyoSHId4GC-I0ED5GHV3wJh&psid=7202663986454083&width=1024&ext=1712266803&hash=Afp1wAHW8FhKV5InJGAUG6Rd5a_IzJnd8eCdyxlJxyCJCQ",
      "fb_username": "Griselda Con",
      "fecha_ingreso": "2024-03-05 15:38:57.330987",
      "ultima_fecha": 1709674805
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 2
    }
  },
  "7203390133083826": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGBjT-7T7COO4Fl3Wtqia8DyUS39R3vix-CQOfsMVUuRS9gR5V0rtFbuaP9TusANAPU6AvQiuL5&psid=7203390133083826&width=1024&ext=1711836185&hash=AfocPxFXxL4mEaevPP2ZS2ZuiMUEsIf9aNgXgPzkkFhAGQ",
      "fb_username": "Keily Garcia",
      "fecha_ingreso": "2024-02-29 15:58:36.463083",
      "ultima_fecha": 1709244186
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7204357456326323": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHOyrhD4pHWnLv7qFXr2kpWzV5uIXxjY_uvPC8gTd2KHzjlnHM3_yF_hgfTZQytuJUG1yl9XNym&psid=7204357456326323&width=1024&ext=1713983141&hash=Afp6fbb6vY0-WT1NCNIjbHhH-PPWXXoW7rpk7RnRv04lwg",
      "fb_username": "Ely Ely",
      "fecha_ingreso": "2024-03-25 12:25:20.138476",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1711391142
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7209447609169657": {
    "atributos": {
      "Gestion_numero": "Buenos dias",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXESTnBlSGAvwLbUhqyuCVd-HkuB49VjfEefZzBod5r6pkz40rf4PIg8VAkwJOMFdp-xN5f0eyTM&psid=7209447609169657&width=1024&ext=1714143999&hash=AfqmygMqh2SlN52n57tW8ZI14GE8v4cegU6Y5tn_iDCaXQ",
      "fb_username": "Ismael Subuyuj",
      "fecha_ingreso": "2024-03-27 00:48:02.538846",
      "ultima_fecha": 1711552001
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7210686212284241": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH-1Dqljl-v6HwsArVob0xNX2SoAgeKZ7idbXAQ7YqzYczdWYMKtCfaDU1f82Avs9HFsPgUBVTD&psid=7210686212284241&width=1024&ext=1703258854&hash=AeQLwY65XVdSPrE7OgQ",
      "fb_username": "Xavier Paiz",
      "fecha_ingreso": "2023-11-22 09:26:14.813897",
      "intentos": 0,
      "ultima_fecha": 1700666855
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7211140849006694": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEqvma5oLVBbG62Uolf4_F4mkp6bYK6OsRPgK90nP8tdjSNgP4wkzeQVv8Sn_3zeB2Zfc-aK5nM&psid=7211140849006694&width=1024&ext=1713642582&hash=AfqK305VcaG3ROq1h90MjI6WNAP16pYgpdG-XzDf5eFq_Q",
      "fb_username": "Josue Cruz",
      "fecha_ingreso": "2024-03-15 09:29:20.453423",
      "ultima_fecha": 1711050584
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7211949912258457": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGOBrzz4EXdVIzAemy0piLmpI5YAl-VhRYko3P4gUSm3s8bpNHsOTJ3eD64BeZdnk98qceL93d2&psid=7211949912258457&width=1024&ext=1712331345&hash=Afr__WqnotPzt2KOKuDTa-Z7TFMN4r2cWEbP3pRqAQ6xIw",
      "fb_username": "Mildred Juarez",
      "fecha_ingreso": "2024-03-06 09:35:45.964934",
      "intentos": 1,
      "ultima_fecha": 1709739350
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7215671885111881": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGqgsDsf2VejIBVQpiGWBBc1LF6o2c6XjFEwRquKWLyimdQ80_e8mxvd1PIRg7Ra0VDkaBQwj53&psid=7215671885111881&width=1024&ext=1702745219&hash=AeTnxmSqs8jeOBHUiTA",
      "fb_username": "Yulisa Enriquez",
      "fecha_ingreso": "2023-11-16 10:46:19.132768",
      "ultima_fecha": 1700153221
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "7230623170326623": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEtiRwLEwUfUeCoTE-yJLqFaFoizTwwbea9XHfm0TznKDDq0xC9vKGKcsX_FvYR2fjW8T5NglD2&psid=7230623170326623&width=1024&ext=1710080045&hash=AfoWeKdrVHz5nUMxL_ki0VNmNnfEXtpXr2iV_jNScq2_FQ",
      "fb_username": "Marlin DC",
      "fecha_ingreso": "2024-02-09 08:13:15.995753",
      "ultima_fecha": 1707488047
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7251595211569509": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "013 y 018",
      "Gestion_problema": "No se encuentra la trabajadora en su puesto",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "David Citalan",
      "Telefono": "41556031",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEtVSxoqeSYyPyHWIzVuk4smRxihuQMkVSirFzoLaGu6CvFnQiGQ8-ve7UEQgFrhwg8fmz-l3UW&psid=7251595211569509&width=1024&ext=1708271835&hash=AfoE6BUESCD4gu89Z-Ywhdqtswyl_wPFJmxv8FkHeKJ-jg",
      "fb_username": "David Citalan",
      "fecha_ingreso": "2024-01-19 09:05:20.588735",
      "intentos": 0,
      "ultima_fecha": 1705679835
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7254964807924426": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJAL1oF6O0uOzZBI52ETUjtBaS3J1DIhEo5p4TAixjObR0e9Ze_dy0gujOE5qFAvKDG-Ah47e8&psid=7254964807924426&width=1024&ext=1713634233&hash=AfqbhpL_yYytT_eRKkvgCl6dZK_n3Gjsb0oWbT61tODEnw",
      "fb_username": "Colin Jacky",
      "fecha_ingreso": "2024-03-21 11:29:50.972025",
      "ultima_fecha": 1711042234
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7264588436912822": {
    "atributos": {
      "Correo": "marlondavidcanelbarahona@gmail.com",
      "Gestion_numero": "Es una consulta la q deseo hacer",
      "Gestion_problema": "El día de ayer realice el llenado de formulario para solicitar la patente de comercio\n\nPero el problema es q ala hora de generar el formulario salió sin número de DPI habrá algún tipo de problema si lo imprimo así y hacer el trámite de la patente de comercio??",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Marlon canel",
      "Telefono": "59486034",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHqer_SqMM36HonCNzn2foYfg3fjtAFJoEgi2ZSYS0II4NlTwvaJlhdbsGMlyQ1Vmzm01HC0agb&psid=7264588436912822&width=1024&ext=1707084263&hash=AfqWhG3yeh8IOPOoqB8m111eVVWIx6XTeZUvNdEkmodUKQ",
      "fb_username": "David Estuardo Canel",
      "fecha_ingreso": "2023-12-30 12:10:49.327815",
      "ultima_fecha": 1704492263
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7267153013304699": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHNtUixY62gErHnji9ck4N8ITJRbmV4h6ThtDQIN8f9RoL2nagOFLcWjrn9nnDscEj0QDoEMwto&psid=7267153013304699&width=1024&ext=1709223792&hash=Afpg9Ymfzin789R9oxlpoJLO7XO2qRbNtu-8UiaBaCWBAg",
      "fb_username": "Joh Val",
      "fecha_ingreso": "2024-01-30 10:22:21.886586",
      "ultima_fecha": 1706631793
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7267581409922569": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7267581409922569&width=1024&ext=1695319891&hash=AeSPZocOGXKcnKW5cM4",
      "fb_username": "Alma Herrarte",
      "fecha_ingreso": "2023-08-22 12:11:01.378472",
      "ultima_fecha": 1692727894
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "7268426333252335": {
    "atributos": {
      "fb_picture": "https://scontent-atl3-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=810bd0&_nc_ohc=-h2fNFXsOQwAX_NY_ey&_nc_ht=scontent-atl3-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAZwpLZEzgb13uoKwX5gRWJuM8JNs5wtRIm6JK1eM4zrQ&oe=65D20359",
      "fb_username": "R A González García",
      "fecha_ingreso": "2024-01-19 11:11:23.956103",
      "ultima_fecha": 1705684451
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7271564562960521": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXELPqmePFvicUNOp_pO3eGGgzencpWBHSTPPsgqQ4vv3Wbnom3vapU2KznB--avn1x6jLsihHpp&psid=7271564562960521&width=1024&ext=1713637900&hash=AfrvyFcjqYdPgyxQG2gNmOfYOlBiSzLwv-V2J2qF7gU3Qw",
      "fb_username": "JC JC",
      "fecha_ingreso": "2024-03-21 12:30:38.853940",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1711045902
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7272992819385600": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGKu5YVhAzcjb7QqXfsH3eExpxCvvoanEsMD9_XW_RqLXF8bdo3A55FvjDvffGahefB6lZksNtH&psid=7272992819385600&width=1024&ext=1704568688&hash=AfopmMVGf2-VqTztEhNJ65TMi_lRPvrQxoMNciY2pdSeTw",
      "fb_username": "Lau AG",
      "fecha_ingreso": "2023-12-07 13:16:36.699589",
      "ultima_fecha": 1701976690
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "7273963642689620": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEFC2NvayJVrjk2U3Nw9D68lozJPJehwnIIBlE7zv30ONteH3UpVfr0rhUNSYVkfNv7w31jLSpz&psid=7273963642689620&width=1024&ext=1713360355&hash=AfpO1KXxwP9tD_zGTRKvPFPzhKGuwAVmTeWPHGXz_0KGEw",
      "fb_username": "Nancy Chanay",
      "fecha_ingreso": "2024-03-18 07:25:33.237052",
      "ultima_fecha": 1710768358
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "7275326262551227": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHzolXJE0yU6Al5d7Yqqt3kQrTic7RmLg74DkAPJaHWR16LUpTS_QH4iBB245EkEp8SDrNdkKsr&psid=7275326262551227&width=1024&ext=1713742920&hash=Afqt9sPcW0qx32MryZneXt-4KzcV51RWWZxNpb4GUXP-dw",
      "fb_username": "Hugo Cahal",
      "fecha_ingreso": "2024-03-22 17:40:38.223366",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1711150921
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7277167272329087": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGpNKALpNXFJvxJDPPmmL2I8-DPeX-XL1e8Lt7T8hLvDP7RL26V8XWy9yCrqchj1oNyiR3kmpNl&psid=7277167272329087&width=1024&ext=1711292627&hash=Afrn6cDEA0yaZXlsT70O0gAe011ZkHXb1jVwIVM1t0CjLQ",
      "fb_username": "William Renė St",
      "fecha_ingreso": "2024-02-23 09:03:47.283835",
      "ultima_fecha": 1708700632
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7279036635445441": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7279036635445441&width=1024&ext=1693599751&hash=AeT1a0eoOkZKz7VBUXk",
      "fb_username": "Alicia Chanchavac",
      "fecha_ingreso": "2023-08-02 14:22:16.263190",
      "ultima_fecha": 1691007753
    },
    "user_posicion": {
      "bloque": "ClausuraEmpresa",
      "posicion": 1
    }
  },
  "7280003062019257": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEnr13WWEdr6LGCDqBs4fQ0iuYLMfk6ojIaBMuJWj4sYIjEZ8y0Tmt_5OSGkOpbv8fHYp7SsLQS&psid=7280003062019257&width=1024&ext=1702054481&hash=AeQYlSmF-YuRxa0qypY",
      "fb_username": "Dyr Gt",
      "fecha_ingreso": "2023-11-08 10:44:38.789728",
      "ultima_fecha": 1699462485
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7281433775269756": {
    "atributos": {
      "Gestion_tipo": "Asesor",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHBJBLTa2SPyopyoBVEFrm8RUKpgnOVhlX89ZQarWQx4hcy-lpCDzzyNCvXrCqFWrPxaCUHCyOQ&psid=7281433775269756&width=1024&ext=1711294539&hash=AfqQLSiyDlVl886Awmge6lxnlgdxIau4zKVSAynyDdS8SA",
      "fb_username": "Fatima Madison",
      "fecha_ingreso": "2024-02-23 09:29:34.275465",
      "intentos": 0,
      "ultima_fecha": 1708702541
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "7283594741759987": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEiDWXUNHvmazhLQXTHvp8pIGmu5OGBEZ_7WfU8aEVyJvtpqBUZBb3IInpGg78BJ33J2z9hfOWR&psid=7283594741759987&width=1024&ext=1715266083&hash=Afrnkjmo_LIOSHHsF5soZhjuhA-0jAGgcli-9Mq2Me_jvg",
      "fb_username": "Gerson Morales Cordón",
      "fecha_ingreso": "2024-04-09 08:48:03.860120",
      "ultima_fecha": 1712674088
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7284261584927342": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFTr3B84FT6m8DA9KG6n241KkdhpFfjtUawPOpS42Bo1jYP8bJI_HGgDl-qgKhH8KzMQ5q4QGTd&psid=7284261584927342&width=1024&ext=1704398029&hash=AfrXnpA7W2Zd4cMCvnRSiqXypoN0ruBW9bWRtRaqWrsgpQ",
      "fb_username": "Mario Acte Ico",
      "fecha_ingreso": "2023-12-05 13:49:50.865812",
      "ultima_fecha": 1701806032
    },
    "user_posicion": {
      "bloque": "ReposicionPatenteSociedad",
      "posicion": 1
    }
  },
  "7284368688260091": {
    "atributos": {
      "Gestion_numero": "Libros contables",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG6VrBoO_5132h099nABvXCNf01IReDurZ0_r38yw1tda5oRbixlyKjR7y53SucK7NvEZE16yff&psid=7284368688260091&width=1024&ext=1701965439&hash=AeRBYO6L-WW3jtU-9JQ",
      "fb_username": "Shirley Yajaira Cinto",
      "fecha_ingreso": "2023-11-07 10:09:55.644924",
      "ultima_fecha": 1699373440
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7287669954589519": {
    "atributos": {
      "Correo": "no.",
      "Gestion_numero": "13415-2024",
      "Gestion_problema": "Deseo que me proporcionen el correo de Sociedades Nuevas.",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Diego Pérez.",
      "Telefono": "59821227",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGgM5jcJpoVKO1b15U1q4bjzkpgfpcxBM9R9ANdv-oh1z9MbIuzJpilAXPg5ReaM4yiznie4wZ-&psid=7287669954589519&width=1024&ext=1710019617&hash=AfpWbzR88BLAvggbQWEcRNbMtQiklKhmGUNAUWFHm3Cv5Q",
      "fb_username": "Diego Perez",
      "fecha_chatcenter": 1707424080622,
      "fecha_ingreso": "2024-02-08 14:20:52.050323",
      "leido": false,
      "log_chatcenter": "-Nq9OyBiwrVoaSfLK4DS",
      "msg_noleido": 0,
      "ultima_fecha": 1707427617
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7291143884278092": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7291143884278092&width=1024&ext=1681483395&hash=AeTVRgC8Gf0Y3V7FJME",
      "fb_username": "Hugo José Gonzalez Ussac",
      "fecha_ingreso": "2023-03-15 07:38:48.096787",
      "ultima_fecha": 1678887696
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "7292571830818366": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "Sacar patente de comersio para farmasia",
      "Gestion_problema": "Ami me la bendieron",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Kandi marquez",
      "Telefono": "32293167",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHjXizSJXEdPsPYT2NMVAS6xhbjfbEg-wnk1K-DRd8hIV9JUWR3gXYimfGDd9tDaAEqj8fCO1qu&psid=7292571830818366&width=1024&ext=1712769305&hash=AfpP0s_tSe2681NXHW88yxYuH15BhOsuWgT3Z20FhSOD5Q",
      "fb_username": "Candi Marquez",
      "fecha_chatcenter": 1706871022933,
      "fecha_ingreso": "2024-02-02 04:48:17.342619",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-NpdRDLjgoSASK0saGVh",
      "msg_noleido": 0,
      "ultima_fecha": 1710177306
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7304102572936598": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGK4x2UdNyL0AQXDAwR4BZJHmP4sMPS0RF3uv0yJfXdwkUUW_zgRw7__piD1JxGyAlcTSDyxn2C&psid=7304102572936598&width=1024&ext=1700240085&hash=AeT0hOToYfZlczTze4E",
      "fb_username": "Miguel Enrique García",
      "fecha_ingreso": "2023-10-18 10:54:45.628140",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1697648089
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7306865739375925": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH2VLb7DuxCauSndFdFdNB4S7tH1zdxYATo28YaBNhp8haRzWNW2xMKfEn49VG5Dfr1Kn5yUjEn&psid=7306865739375925&width=1024&ext=1708617032&hash=Afq55vSxi85U1m3PUKMeRPxStbuf3gtkMRhj6QYxBCrvkg",
      "fb_username": "Eugenia Tormo",
      "fecha_ingreso": "2024-01-23 09:49:51.634559",
      "ultima_fecha": 1706025036
    },
    "user_posicion": {
      "bloque": "Auxiliar_inscripcion",
      "posicion": 1
    }
  },
  "7310390615642712": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7310390615642712&width=1024&ext=1691353483&hash=AeRSL4_coCdl2jxpVTY",
      "fb_username": "Abi Choxom",
      "fecha_ingreso": "2023-07-07 14:23:41.155736",
      "ultima_fecha": 1688761485
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "7324273360922442": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7324273360922442&width=1024&ext=1677788487&hash=AeT8tEhjdHUhHHFqgwg",
      "fb_username": "Julia Maria De Los Angeles Rodriguez Chavez",
      "fecha_ingreso": "2023-01-31 13:24:24.324971",
      "ultima_fecha": 1675193114
    },
    "user_posicion": {
      "bloque": "Guias",
      "posicion": 1
    }
  },
  "7327950907291538": {
    "atributos": {
      "Gestion_numero": "Libro diaco",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGL790KlBEmiU0OH_rJ4cgTqst0TScyvZIPtU0Rsenh3vzdnO3VhqwzrWDvMV86L54kvnvoqn3H&psid=7327950907291538&width=1024&ext=1713114041&hash=AfqN5dsX2T_pPS6BeaiyyrSUf4jhn3Mvr1EostscVVhSXw",
      "fb_username": "Corado Pily",
      "fecha_ingreso": "2024-03-15 10:59:53.761398",
      "ultima_fecha": 1710522042
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7328996223874760": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEtyz4JPwa2WO5f5jvqF46ksPWypF5zZYxVhsB53XLMpX5Hh256brvZB_xeRl_CKsytVBNLtKgY&psid=7328996223874760&width=1024&ext=1713045557&hash=AfrSCDJbB6B1JQ3FHOtMqdWGz1VjXSHRZ4vVkDdyOPwRQQ",
      "fb_username": "Jessi Pineda",
      "fecha_ingreso": "2024-03-14 15:58:46.763493",
      "ultima_fecha": 1710453558
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7329115993789579": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEjytV8x0tVcZG9rTPyyOxdaf7RfgJdyrvh7wbDls8ZRBTExTF_JhM0uwx4TDfi97zejVdevRbV&psid=7329115993789579&width=1024&ext=1711574101&hash=AfrtI66YDsTk6guqkYmXrxCeHvfVtCSryzml_dX-lgTMQA",
      "fb_username": "Fer Gie",
      "fecha_ingreso": "2023-10-11 11:48:04.914389",
      "ultima_fecha": 1708982103
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7331179720307485": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHqNVmCx3u13aW5L7bCrby3zWo1TrPs_mecrWcg_EBS4-Q1G1wc1_EaekulCEpZc2NtYw725SCD&psid=7331179720307485&width=1024&ext=1714008288&hash=AfppYCq5BbaIqClPgsOYEH3l1FQqAg76gCmTSpyBZko_7Q",
      "fb_username": "Majo GR",
      "fecha_ingreso": "2024-03-25 19:24:13.252980",
      "ultima_fecha": 1711416290
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "7333664546749808": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEYHl4qpelFd1LcAsq37Ru_qhsBh8T-wzTaVecpfDJSOVOMSDxomUqYYn8G1BUqWokvjj1S-TCQ&psid=7333664546749808&width=1024&ext=1714747921&hash=Afq8noz9IuCI0V-gb-9qaVEGNTjEgkjvkXojqbb1ILot8g",
      "fb_username": "Arme Nia Paz",
      "fecha_ingreso": "2024-04-03 08:50:33.782128",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1712155922
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "7339144302784773": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGZWe9wyIsryVnH5Qg5gHqr7eyxlwIkIUqKjc36ooLfZOBEiVXhF8mu_45Kj9xn5JGCDDJAVXUm&psid=7339144302784773&width=1024&ext=1703179183&hash=AeQjxMucVhsMxMtfs1c",
      "fb_username": "Pukkita Linda",
      "fecha_ingreso": "2023-11-21 06:52:47.515599",
      "ultima_fecha": 1700587184
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7342353242474989": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFuKEzl2lBeuw6OkXbECRZR7GZxB0OvW-SwXhtmM4r7SI4oDvjAhygAzuFVHzWk2PNtsq6Vh8m7&psid=7342353242474989&width=1024&ext=1710509201&hash=AfoGzPMybUzVmiaeN2pGzFS4NmVrmG5P_fA92qvA96xcUg",
      "fb_username": "Luis Pérez",
      "fecha_ingreso": "2024-02-14 07:26:20.302195",
      "ultima_fecha": 1707917202
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7348106891975928": {
    "atributos": {
      "acceso_asesor": 1712171943000,
      "asesor_id": "T1vBPIA69DRIw8B34rv0YvdMDal2",
      "asesor_name": "ryaxon",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHxEv9Ksz2RKn3vQdJYUCAQUHNWU1p7toeoSQdoaCLH80rAKVDxPJ1Q3YKxQpKDjmazt4xyZiiu&psid=7348106891975928&width=1024&ext=1714763940&hash=AfpA0coD60CMvbSYYyDwzBxpiAaDsRHuUv2TLbJo7_Rpug",
      "fb_username": "Linddaa Weenn",
      "fecha_chatcenter": 1712266840201,
      "fecha_ingreso": "2024-04-03 13:18:48.188387",
      "leido": false,
      "log_chatcenter": "-Nuf2cVvpxp7dtKfXYJg",
      "msg_noleido": 0,
      "ultima_fecha": 1712171943
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 2
    }
  },
  "7355223197859274": {
    "atributos": {
      "Correo": "licda.mor@gmail.con",
      "Gestion_numero": "32327-2024",
      "Gestion_problema": "Asesor",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Ludin Madai ortiz ruiz",
      "Telefono": "59494217",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEG60PyCTTYKdMbdRZQkCukfHGrAxi8lJQVu8qyUlN5tmwwRCs5Bls6zh64oRFsyCzjizJ8vXGm&psid=7355223197859274&width=1024&ext=1713995604&hash=AfpvTI7Cf88UexMBXoMmEfPE4PXBY8rcMPdEp95PyNPhYA",
      "fb_username": "Madai Ortiz R. de Hernández",
      "fecha_ingreso": "2024-03-25 15:50:41.377742",
      "intentos": 0,
      "ultima_fecha": 1711403605
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7358988244115295": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "1",
      "Gestion_problema": "De cuánto es el boleto de ornato que se pagá",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Nicolás Rodríguez",
      "Telefono": "57475824",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7358988244115295&width=1024&ext=1698951072&hash=AeRl_LZrJT2az8bg0dI",
      "fb_username": "Nicolás Rodriguez",
      "fecha_ingreso": "2023-09-27 06:39:04.708456",
      "ultima_fecha": 1696359072
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7371903629499001": {
    "atributos": {
      "Gestion_numero": "Horarios",
      "Gestion_problema": "Horario",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "ELDER Barillas",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEKh9Klxo2V2fZVVNlJ2bpn6QVxSOHabZw1bpd-YSpWiTsFGAJUjTVgGIK3dNRzyalAkdAD6yk-&psid=7371903629499001&width=1024&ext=1708631132&hash=AfpGWOUAmNxgKc5A_r0ldVwIvYuE3PY8Z0uXEUaTKm01Kg",
      "fb_username": "Elder Barillas",
      "fecha_ingreso": "2024-01-23 13:44:59.709752",
      "ultima_fecha": 1706039133
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 5
    }
  },
  "7381531088542273": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFomKreoGhPifx57UtEaB0fUM_4JLuagZzTJWt5R0HhGP5NqOSG9-IvEkFvucbR2EKlUyPM6YRB&psid=7381531088542273&width=1024&ext=1700240274&hash=AeSOSV4wVdQXCSXbzjk",
      "fb_username": "Dannii Cifuentes",
      "fecha_ingreso": "2023-10-18 10:56:56.044508",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1697648275
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7381989295196435": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "93900-2023",
      "Gestion_problema": "Rechazado",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Oliver López",
      "Telefono": "54512194",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7381989295196435&width=1024&ext=1697213950&hash=AeQ1gmygFsUlrmhMwtU",
      "fb_username": "Jose Oliver Lopez",
      "fecha_ingreso": "2023-09-13 10:15:22.393772",
      "ultima_fecha": 1694621951
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7382346801825529": {
    "atributos": {
      "Gestion_tipo": "Virtual",
      "fb_picture": "https://scontent-iad3-1.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=810bd0&_nc_ohc=aPJYkDh1lRoAX9AfbHY&_nc_ht=scontent-iad3-1.xx&edm=AP4hL3IEAAAA&oh=00_AfA7FjdZel6mItomhsNbu0F_9jVCr4bbZJAqEPdlCmZtig&oe=65A95D19",
      "fb_username": "Josué Amilcar Hernández Pérez",
      "fecha_ingreso": "2023-12-19 14:17:39.069829",
      "leido": true,
      "msg_noleido": 0,
      "ultima_fecha": 1703017077
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7384226215002851": {
    "atributos": {
      "Correo": "No.",
      "Gestion_numero": "Necesito hablar con un asesor",
      "Gestion_problema": "Necesito hablar con un asesor",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Elvia Floricelda xuc",
      "Telefono": "38385610",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGroXL_KJOg7hz2-zlOYdwY3jjesscIXZ6FRG2rcKS2YAIfrTe5Sq8o1h0ykGalYZuUtDRY_jzs&psid=7384226215002851&width=1024&ext=1712178506&hash=Afpa05Oyx_HNDhQd1NmUzOzJq_yq8mM2Lw8rzYLQ6HS8Nw",
      "fb_username": "Elvia Floricelda",
      "fecha_chatcenter": 1709578563700,
      "fecha_ingreso": "2024-03-04 12:49:21.700157",
      "leido": false,
      "log_chatcenter": "-Ns9of1boADz_3ABhz0p",
      "msg_noleido": 0,
      "ultima_fecha": 1709586506
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7387978451222501": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFuKwqEBDY7p8ES0xW-yIqwMfLB1tvN59atrrKepr4dV-pGTnLvIQ7v1QCSZT32T720oocVPtJW&psid=7387978451222501&width=1024&ext=1700159532&hash=AeS3rtyMK36lJibYk98",
      "fb_username": "Lisbeth GH",
      "fecha_ingreso": "2023-10-17 12:31:45.752681",
      "ultima_fecha": 1697567534
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7397594770262155": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGER_kj0K_oWpx456WuK2zeY5UqsdIV8kbByPMSYll-EeRgprLqvQZGk4FedFhyMnEjGfleY0-z&psid=7397594770262155&width=1024&ext=1709930978&hash=AfoVIuAPlOUAUHChRxgp_mJzBucbeJ4I5UHOJOAs_uoV2g",
      "fb_username": "Wen Steph",
      "fecha_ingreso": "2024-02-07 14:49:25.560803",
      "ultima_fecha": 1707338981
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7399946023433525": {
    "atributos": {
      "Gestion_tipo": "Como puede ser electrónico?",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG1kf6HKXoxj1D7tZ64lR4e0RNE4bEhbYQIBUhiJ_t9-v8DqArVju6QW8fKVrMctMeNQlmKIgJj&psid=7399946023433525&width=1024&ext=1712750741&hash=Afr9k-692OHIggr1ktsw_42SpU4h7bWyuGEn9vhFbidALA",
      "fb_username": "Edgar Hernandez",
      "fecha_ingreso": "2024-03-11 06:04:37.176830",
      "ultima_fecha": 1710158743
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "7400994346583059": {
    "atributos": {
      "Gestion_numero": "Teléfono para llamar",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7400994346583059&width=1024&ext=1697910532&hash=AeQmat5RvZoVabSpKvI",
      "fb_username": "Luisa Rivas",
      "fecha_ingreso": "2023-09-21 11:47:04.558522",
      "ultima_fecha": 1695318533
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7404590579604089": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF1qd_vvzP1JFU5m5nipqYBRhzWaq9E1qv0H5l8E_sxEKhTaNcQxMKXqQGNYD2rsAUEQvKgozZB&psid=7404590579604089&width=1024&ext=1710689164&hash=AfoqSMUZjGzaKuU0MsoAfVLKoVxVqHCJifBsA6Vlw35K2Q",
      "fb_username": "Raquel Siquiná",
      "fecha_ingreso": "2024-02-16 09:23:56.014457",
      "ultima_fecha": 1708097165
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7406359496082093": {
    "atributos": {
      "Correo": "lic.erickrodas3@gmail.com",
      "Gestion_numero": "13605-2024",
      "Gestion_problema": "No me deja descagar el documento para corregir los previos",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Erick Ivan Ramon Rodas",
      "Telefono": "36404901",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE4zTzh5RlhJgKAQyfx3VHMb_6LrCJs8bE0kN6FFPQ4erP6tc6lufG_qOriIvJnJZoKWvlTdWNe&psid=7406359496082093&width=1024&ext=1710542626&hash=AfpJokJWRjOJuI63ovPk6KcSRFR0NAqxhS1qOAMWAiMVfw",
      "fb_username": "Erick Rodas",
      "fecha_chatcenter": 1707950627358,
      "fecha_ingreso": "2024-02-14 16:42:07.709959",
      "leido": false,
      "log_chatcenter": "-Nqdm_dCTKqrYUKEpao-",
      "ultima_fecha": 1707950627
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7408204282540779": {
    "atributos": {
      "Correo": "rodolfocj4198@gmail.com",
      "Gestion_numero": "Buen día",
      "Gestion_problema": "Boleta invalida",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Edwin Contreras",
      "Telefono": "38382257",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFszaoQ6N731TZIz-eTU0vRclOoJuKkRMVV1avAxHc8RMSCzEx6u_w4yieEk_NSUdlRD5OsNgPS&psid=7408204282540779&width=1024&ext=1701534087&hash=AeREnN3wUl8A2Sr3ZiA",
      "fb_username": "Edwin Contreras",
      "fecha_ingreso": "2023-10-27 18:50:14.939460",
      "intentos": 0,
      "ultima_fecha": 1698942089
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7414145908674072": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE-3t2DGkAdGGaoS1kljpwM4tlkmlKaeUfPcqzNN75UmSEejFuzFxpF62vwvbK2yca9kpFxUWqE&psid=7414145908674072&width=1024&ext=1713136582&hash=AfpFsAkPKq8oMetKjVmSe_jwEaBzJl21wfwUefmNB-giuQ",
      "fb_username": "Esteban Fuentes",
      "fecha_ingreso": "2024-03-15 17:13:57.618316",
      "ultima_fecha": 1710544585
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 2
    }
  },
  "7416447861774962": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXF9gHtcD4dJpwmJS-QVV2_nh4vIorsa-HO0J8MncH745ZdQ3y7OJYiLj2Dbgo12Mk3bQ5sVPdqG&psid=7416447861774962&width=1024&ext=1712862183&hash=Afoughe-sON22Md1aE7TukleTrjLDMwJb6piWT5gn_4Ndg",
      "fb_username": "Jose Curiel",
      "fecha_ingreso": "2024-03-12 13:02:52.621491",
      "ultima_fecha": 1710270185
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7418217828265883": {
    "atributos": {
      "Correo": "claritc_1701@hotmail.com",
      "Gestion_numero": "Requisito solicitud patente de comercio",
      "Gestion_problema": "Requisito patente de comercio",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Clara Tzay",
      "Telefono": "54435465",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGC8M4g0mlAPBvt8rtMHljWaR3ukrgbnNXx0VNTj4SEMDZe8sMWfJEUMbtlziuUrGpDuEncHF_A&psid=7418217828265883&width=1024&ext=1710092350&hash=Afr1fyiNxOge_Qb2MmMPpaJmHDQNlG4aVMpxKIjsyw177A",
      "fb_username": "Clari Tzay CG",
      "fecha_chatcenter": 1707500340342,
      "fecha_ingreso": "2024-02-09 11:36:35.818603",
      "leido": false,
      "log_chatcenter": "-NqDwsHjSeHk3veJRgz0",
      "msg_noleido": 0,
      "ultima_fecha": 1707500350
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7420516478041847": {
    "atributos": {
      "Correo": "davilamariana299@gmail.com",
      "Gestion_numero": "32826725",
      "Gestion_problema": "Me comentaron que para ver la patente sería después de 4 días y ya pasó más de una semana y aun no se cual es el problema, necesito saber que procede, si hay algún inconveniente o simplemente un retraso de parte de ustedes",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Mariana Davila",
      "Telefono": "31164248",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHtpep_phqTKC_BPa0mp5E17gaYQLXvIPLHKnL0RWjjnSo__CbZMyLKieMX_hmLGGp_xN-oLK_R&psid=7420516478041847&width=1024&ext=1713377735&hash=AfqvLhk5CfMfSqm0LJDXaFxLpU-IsmXwzn_PJ17LZaukRA",
      "fb_username": "Mariana Davila",
      "fecha_chatcenter": 1710785040244,
      "fecha_ingreso": "2024-03-18 11:57:40.361404",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-NtHj-ytmDGYP3GVKYe_",
      "msg_noleido": 0,
      "ultima_fecha": 1710785735
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7428770330574968": {
    "atributos": {
      "Gestion_numero": "22455661",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFQcEnDL6EwkqvpCn9BZnClpe4N9sSNxaqyH78q0pE2JkzjUtmEOUOxtv0vKRFVSktUzw7jnfT8&psid=7428770330574968&width=1024&ext=1713028329&hash=AfoVRECEnMhKDxeWMYN04BJrJ8N3juaobsR9144h745sdA",
      "fb_username": "Mirza Barrios",
      "fecha_ingreso": "2024-03-14 11:00:37.451907",
      "intentos": 0,
      "ultima_fecha": 1710436330
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7441843672544279": {
    "atributos": {
      "Correo": "Corinag80@yahoo.com",
      "Gestion_numero": "32816395",
      "Gestion_problema": "Vigencia de nombramiento",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Corina guzman",
      "Telefono": "54605282",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHU_HlolHONONRoObtZEIVRRX4SSFaU5fSJ-rjzcBZUYr10Siv_40-CZmSnxwwIr8A9CRj9qJKn&psid=7441843672544279&width=1024&ext=1715375117&hash=AfozxckaxcMNfbt0mmFZIhuH6cvitoX4ykAlf5vxv_N5IA",
      "fb_username": "Corina Montufar",
      "fecha_chatcenter": 1708539606669,
      "fecha_ingreso": "2024-02-16 12:28:30.544567",
      "leido": false,
      "log_chatcenter": "-NrBtMPw9HhF0sJxlwV0",
      "msg_noleido": 1,
      "ultima_fecha": 1712783117
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7443550332397829": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHKohtYMY4fTdIR-_MRrRfB4_ICyyiZ1u1Hw6q2nGBeWlBbluCZcwrE_NlDwPrU0jOv217JKbBE&psid=7443550332397829&width=1024&ext=1713372469&hash=Afrkg3C55yfUjg6gjh6WYOHnIiz6H1RZGXOM2Reh02xP1g",
      "fb_username": "Jose Diego",
      "fecha_ingreso": "2024-03-18 08:22:30.118335",
      "intentos": 0,
      "ultima_fecha": 1710780470
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7445771888768548": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFuErXlX3YkM4W_ITmO-HGrp-iatIA9ITLM1ToMa3Icup7N-rkehigVvhGyprmQ1MtPw5bBTZGw&psid=7445771888768548&width=1024&ext=1707330043&hash=AfrrNvz3RlwdlH80poj8XYb82s03Q8nBzs_dwnP9-l_sYA",
      "fb_username": "Juan E. Pérez",
      "fecha_ingreso": "2024-01-08 12:20:19.063759",
      "ultima_fecha": 1704738045
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "7449526168393555": {
    "atributos": {
      "Correo": "mayenveliz23@gmail.com",
      "Gestion_numero": "Ingreso de patente",
      "Gestion_problema": "Que días estará cerrado el registro?",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Valeria mayen",
      "Telefono": "58226757",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE-3ZNEWcYs1y1xapUfeanjNEcMHt58_4I9q_LL3n4_3A6ZTC7jACYJuV1O_8dhc3g4vAK6g7ee&psid=7449526168393555&width=1024&ext=1705596623&hash=Afp9XzFuUbTPLGpRsxYlJeWXy77xR3XwVIivT3lNBkZFAw",
      "fb_username": "Valeria Mayen",
      "fecha_ingreso": "2023-12-19 10:25:14.267304",
      "ultima_fecha": 1703004623
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7457934477591000": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFJrsM0QNBAkJG4FfZC4vel2g9oFqOUgxSUrIB_gplIfGL0pGHYyf5F1i1xY1DxrCKztfyrYiea&psid=7457934477591000&width=1024&ext=1702151213&hash=AeSkT4u-f7aK63X5Kis",
      "fb_username": "Alberto Bautista",
      "fecha_ingreso": "2023-11-09 13:46:54.013305",
      "intentos": 1,
      "ultima_fecha": 1699559217
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7464614133620412": {
    "atributos": {
      "Gestion_tipo": "Físico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH00NeYFJXf8QAmjVV2Ke71WDpaEW-EpLt0d252MTIXQkhS6rDmMVcKiGBtHbWbC15e-AkhqiKb&psid=7464614133620412&width=1024&ext=1709826645&hash=AfoS0wPjTJkfjwocgmkUspSkjYU8uEt5WuxRZTn6LHVl6g",
      "fb_username": "Yoselin Chávez",
      "fecha_ingreso": "2024-02-06 09:50:27.665138",
      "ultima_fecha": 1707234648
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "7468582606531295": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGj4BRA2M3gYVXCkhfJRhawOkaIopebY0n2PTx4lm_egSlStQwbVirz_ezYxXUHCpVTacO4Y7BQ&psid=7468582606531295&width=1024&ext=1713102364&hash=AfoVC7LV38hJ2qoi4FbXrqs0xzoNP0UGk0xMhQEKTzQltw",
      "fb_username": "Morales Xsharlys",
      "fecha_ingreso": "2024-03-15 07:45:24.660706",
      "ultima_fecha": 1710510366
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7475458695819089": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGk5znkZ6Zo2Vl57v7bzVAGH6lTzhfPdgxvf68EX72ipWP6wFTD4aCqbJb4gsAFJr0xtsi684W_&psid=7475458695819089&width=1024&ext=1709844977&hash=Afpv9gPFzN_r-zZMmtgz3zjQKvxVC6U1bRTbMZgVVXzv4g",
      "fb_username": "Servicontable Sian",
      "fecha_ingreso": "2024-02-06 14:54:11.429177",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1707252981
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "7478646512151249": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=7478646512151249&width=1024&ext=1698951354&hash=AeTg0VHLUnuKR8h__Uc",
      "fb_username": "Gabyta Gomez Doradea",
      "fecha_ingreso": "2023-10-03 12:54:43.099790",
      "ultima_fecha": 1696359357
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7479753782068412": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFD_TnOpI8YL-nXOLj2y63TiLBAtj1jAe9l8If2hsQMO1Zj2cSfaGfcQmn8AtifDa4c6R16IsZ5&psid=7479753782068412&width=1024&ext=1714074046&hash=AfoHQBH7HogMtKyDpe21eA3zX3cJk2s6uWQIrEU2zlpZ_A",
      "fb_username": "Ma DOlivares",
      "fecha_ingreso": "2024-03-26 13:28:36.908524",
      "ultima_fecha": 1711482047
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7481255781931671": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG_g5at4raJUqx51WPPGcsJvzIp1xFTLvnZfDF8MBR0tyNydcdV9FbwoE8MlCepwFM18M7kJD6I&psid=7481255781931671&width=1024&ext=1711291758&hash=Afr0KKkJ2A_GDSXc7JDcpjXg_s7-6CU2BNnsjXxt4Y0JBA",
      "fb_username": "San Ajin",
      "fecha_ingreso": "2024-02-23 08:48:43.901762",
      "intentos": 0,
      "ultima_fecha": 1708699760
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7484776598219220": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEBYzutnpzO-dxR-gX9BGGiR6Tj_-OeIiSqZ6opjWZqNKD46Q7bgEWq9lZ2wngqWajSLGGHbIg9&psid=7484776598219220&width=1024&ext=1707926431&hash=AfqcYxGGHm2dxEN-HlUoR-8T3EkxAttx3N1ImuxmPU_oYg",
      "fb_username": "Sair Miker Sanz Pez",
      "fecha_ingreso": "2024-01-15 09:58:36.000261",
      "ultima_fecha": 1705334432
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "7488955114501556": {
    "atributos": {
      "Gestion_numero": "Necesito saber si ya están atendiendo en el registro central o no??? No quiero ir por gusto",
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHflzGSY_JJMVNO3Rfs9RtGrGcNLhgtCXNoqD8cEy0FKqKi0LRWhVRTKBdnWFhuoPe9z4F_mtAV&psid=7488955114501556&width=1024&ext=1713098582&hash=Afqwco_X8wCI7PjZve4sCJnBjNO8ZMl-MRPbZ0DKdxPTzg",
      "fb_username": "Blanca Verónica Molina Miranda",
      "fecha_ingreso": "2024-03-15 06:40:38.683540",
      "intentos": 0,
      "ultima_fecha": 1710506583
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7535967549767564": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFfdv15_uYVYfRygcy4FoAE_yk453U1XuN3w18Um1gKnixhUSXeGWGYg2DhFPnqENrEBEe8oNL6&psid=7535967549767564&width=1024&ext=1710595045&hash=AfoXyBMmaC8Mz35YhWquQx0pZt1etMd0U26m1Ikm4VmVtA",
      "fb_username": "Josy Esteban",
      "fecha_ingreso": "2024-02-15 07:16:45.876832",
      "intentos": 0,
      "ultima_fecha": 1708003047
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7538908716160264": {
    "atributos": {
      "Correo": "angelbeteta10@gmail.com",
      "Gestion_numero": "20207-2024",
      "Gestion_problema": "20207",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Angel Beteta",
      "Telefono": "40019573",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE8xTibBpp0We1NHIBjhRk6g2qrrgN2MEZ-fiygw1Lblp0Pah2uvFRgL84DNcm5M0q59WHW57Xh&psid=7538908716160264&width=1024&ext=1711295763&hash=AfrvkTgo7Lhwxi0EfPZnpFdnvU7xgPOJLYWvd7APtBVr-Q",
      "fb_username": "Angel Beteta",
      "fecha_chatcenter": 1708701828623,
      "fecha_ingreso": "2024-02-23 09:20:58.531978",
      "leido": false,
      "log_chatcenter": "-NrLZBO-IR5GbeGfAi4Z",
      "msg_noleido": 0,
      "ultima_fecha": 1708703763
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7542279475801614": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHjOlDNvvJZ-PZBgkHd-KrGArKqbxRm2U9h1yZ5K_KYIKs_mwApcBfX8dWqQ9acV1c_Zbk36mNL&psid=7542279475801614&width=1024&ext=1704315129&hash=AfqPObJzX8BRUzZQ8uOqGDvmlvUJN8PTeNAx06T1L-SkSQ",
      "fb_username": "Maria Minera",
      "fecha_ingreso": "2023-12-04 14:49:32.150940",
      "ultima_fecha": 1701723075
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "7555818854457374": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHK7I91dMfRtLJCFW7l4ixbJFsp_SxNsa42Y9fMOA191r_hck32CiJe2tpP3bEUNS0__eRA-PKd&psid=7555818854457374&width=1024&ext=1714085254&hash=Afozg1N07wUHJabrgbg5j_cxsmh2sNnvs9qekSHNHShndQ",
      "fb_username": "Leonel Chávez",
      "fecha_ingreso": "2024-03-26 16:46:56.991185",
      "ultima_fecha": 1711493255
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7558982627466843": {
    "atributos": {
      "Gestion_numero": "Es necesario hacer la publicacion de la convocatoria de la asamblea aunque no hubo cambio de junta directiva",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHXC9DkwWfMYZrC20Ml63U9uySYmsW9c20cKKAvPuDkqNuAoxCIhzKUipfpbmNX1ZYURhQHcBfO&psid=7558982627466843&width=1024&ext=1711295676&hash=Afoq5CK8qsTZr5cbBJmCKL2jaXKAYaR6S1vw5xz_eLRRew",
      "fb_username": "Sandra Quisquiná",
      "fecha_ingreso": "2024-02-23 09:49:45.685083",
      "ultima_fecha": 1708703677
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7560040514015572": {
    "atributos": {
      "Correo": "kennetmartinezgarcia@gmail.com",
      "Gestion_numero": "32803920",
      "Gestion_problema": "no tengo información del retraso",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Kennet Martinez",
      "Telefono": "33654958",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGcx_i3_MUxnkCShsKJsu9js4B_s44VGk9YcjYOfifgUy5SkVBcZk6UFV-WBFzagyw1gtfG7l5V&psid=7560040514015572&width=1024&ext=1711224815&hash=AfqbVFbVr0BaYVbfvDQ8N1bCVK7BaOjqObQgHLBoqw7Z_g",
      "fb_username": "Henser Isaias Martinez",
      "fecha_chatcenter": 1708628791428,
      "fecha_ingreso": "2024-02-22 13:04:06.976950",
      "leido": false,
      "log_chatcenter": "-NrHC_1yMHolj73WYts1",
      "msg_noleido": 0,
      "ultima_fecha": 1708632815
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7596212790388684": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEpVEZpgZFSDcsPeKjwKpxwevHizFQk-ibis3Hn1cOq9tB2LCRLCPJDKuw_ZTzgDLmt7XZ5Jsq0&psid=7596212790388684&width=1024&ext=1710688231&hash=AfpuxLYQrDJrL-YbJJRysKyAJaiHB85uZv9-Ppj9uf9Qqw",
      "fb_username": "Wehrle Monroy",
      "fecha_ingreso": "2024-02-16 09:09:41.786425",
      "ultima_fecha": 1708096204
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "7603447293016514": {
    "atributos": {
      "Correo": "Maysegura@ayalavivaryasociados.com",
      "Gestion_numero": "113324 - 2023 inscripcion sociedad anónima",
      "Gestion_problema": "No dan respuesta se ingreso desde 9 de noviembre fui personalmente a verificar porque no cambiaba el estatus y ese día rechazaron se reingreso el 24 de noviembre y no hay modo q cambie de estatus nose si esta rechazado o que... Aparte no contestan en ningún número",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Marelyn Segura",
      "Telefono": "30608964",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG51OGsUzuZUYS7DHvmLFp4umUQblC2PGtcWvF2bDcEroIxbfi5EjxVkKugXNzCD7bDmmVNh3Hc&psid=7603447293016514&width=1024&ext=1704403576&hash=AfoqYiKEupuiEACLiXY-N3v-wHtcYFA2KTGt5JIC6wgOjw",
      "fb_username": "May Segura",
      "fecha_ingreso": "2023-12-05 11:42:34.466918",
      "intentos": 0,
      "ultima_fecha": 1701811576
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7606894125993870": {
    "atributos": {
      "Correo": "luis.oliva93@gmail.com",
      "Gestion_numero": "Trámite",
      "Gestion_problema": "Descargar razón electrónica",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Luis Castañeda",
      "Telefono": "44448332",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFTGMo1apuNXphRG3grmSruIkJGXs_coqQ3xbplSkPn3nxy3_wu54ploUkNzwZPNAskP1kEfL19&psid=7606894125993870&width=1024&ext=1711049086&hash=Afrz8_FlpQ_wfcb4z5uda9hzHj0IbOI37GxewC_je_nEbw",
      "fb_username": "Luis Enrique",
      "fecha_ingreso": "2023-09-30 09:53:24.155793",
      "ultima_fecha": 1708457087
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7615086595173650": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEuPkxEzbkO0PVZOeeIvWp5P5NuUAKHh3Tfx8iG0yPYZHw_rTMeEr7ZKPNja_j--xORDX6sEDXw&psid=7615086595173650&width=1024&ext=1705698229&hash=Afq6bzVprNKzMD9WKsuUyiPDL1eZkAAOub1HQMSezMTaqw",
      "fb_username": "Paola Guzmán",
      "fecha_ingreso": "2023-12-20 15:03:42.074043",
      "ultima_fecha": 1703106231
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7634356389948782": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGMN7kz9KaTYi28Jt5imS_Fkdj2sgxRVRCVtXY2SfToRaM2dgbEASoWbsV7MRQrNKGelXNzXt77&psid=7634356389948782&width=1024&ext=1711648924&hash=Afp4FIgB6wmq4-7WZWFjuBanTfDYo6RpxGlIFh8lIUcC4g",
      "fb_username": "Jorje Castañeda",
      "fecha_ingreso": "2024-02-27 12:02:05.060930"
    }
  },
  "7634869949932728": {
    "atributos": {
      "Gestion_tipo": "Algún número de telefono donde me pueda comunicar a las oficinas?",
      "acceso_asesor": 1712243887000,
      "asesor_id": "T1vBPIA69DRIw8B34rv0YvdMDal2",
      "asesor_name": "ryaxon",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXG2uDIYNuio_cmVFOm-xFeSFYcwttyJofO9YO9ceBPi_G9Smy1MHtznLPnFAP5aQhnHQ9-9HL0i&psid=7634869949932728&width=1024&ext=1714857994&hash=AfrVc07P2f6LkTzihUd_qPDNTvHVtdlqbSaLx0C-skiGGQ",
      "fb_username": "Nancy Estrada",
      "fecha_chatcenter": 1712264680849,
      "fecha_ingreso": "2024-04-04 09:13:00.215512",
      "intentos": 1,
      "leido": false,
      "log_chatcenter": "-NuevOK0wum7EdudZL9y",
      "msg_noleido": 0,
      "ultima_fecha": 1712265994
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7640831685969605": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFRg28DQpEw04RpbM1vWVUH9tNbC-q17hDIIL_ZPpFntvLCtTc932LxUPf4Hioe-cR3RQRbLqE9&psid=7640831685969605&width=1024&ext=1711739989&hash=Afoxax978G2T1L43KXC-Swxo8hPEMY4wmZW6fV1hD0gViQ",
      "fb_username": "Cesar Hernandez",
      "fecha_ingreso": "2024-02-28 13:19:36.591994",
      "ultima_fecha": 1709147992
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7661319580552830": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFETIAKEoo4EG4QzM0dkGWBBzNIIT7Q2Y3xTH6e4EpJMJ6_usewz_cs2nnNWTv7sEkJLo68Xq_F&psid=7661319580552830&width=1024&ext=1713968866&hash=AfqvjufFe7Pq9DkB4YWSJumN0Li23gbZVuuohl-hh0tYcw",
      "fb_username": "Raquel Hidalgo",
      "fecha_ingreso": "2024-03-25 08:26:03.341993",
      "intentos": 1,
      "ultima_fecha": 1711376872
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7665983953416285": {
    "atributos": {
      "Gestion_tipo": "Buenos días",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHZbStt0hN6qvuHggnLrKmmlDm-yYq-jYLvFMyKsRZDgv9ZMT7vPiBWL18UXCzaRS3qcJldohOX&psid=7665983953416285&width=1024&ext=1713622343&hash=AfpiIYgWrs0R1AcFj-Yf97YipvZv2fGPGEJELRCz6_syGg",
      "fb_username": "Marvin Hernandez",
      "fecha_ingreso": "2023-12-27 16:47:18.575866",
      "ultima_fecha": 1711030347
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "7668616809867380": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFHQYuA-2YedKLhHYPGMn60kVYBPO-CufktRaaO8m9741NREu2s4-JFytxO4UDh8ro-teKj8dKc&psid=7668616809867380&width=1024&ext=1715368566&hash=AfpA2LdjyeL_mSA0XQMnshc2MpR_vk06LhtxvoeE5mIUhQ",
      "fb_username": "Alvizuris Kevin",
      "fecha_ingreso": "2024-04-09 12:48:34.819806",
      "ultima_fecha": 1712776567
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "7672722072758463": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHD2nMwcH2X0SOJvQH8sCXRiRE11LCE5mvL8hK7QN252J6PUiwjyos0mECuMslZF7dE4hvhuMod&psid=7672722072758463&width=1024&ext=1709643818&hash=AfrZQ4sdHF41yv07a2GJC4tL3563CUemp6DiKM0WcLsnYA",
      "fb_username": "Irvin Cervantes",
      "fecha_ingreso": "2024-02-04 07:02:53.496229",
      "ultima_fecha": 1707051820
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "7682777208453528": {
    "atributos": {
      "Gestion_numero": "32838637",
      "Gestion_problema": "Al verificar con el QR me despliega una pantalla en donde tiene casi 5 días de no avanzar",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Leonardo pórtela",
      "Telefono": "Pueden darme un teléfono",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEoNBuv1lBu6USLOPeCPczntK4pfWO8MZ_yH4eju5YLgSxotokKX-0nV-F2-e5QuXJZVefjgswZ&psid=7682777208453528&width=1024&ext=1714680762&hash=AfrPAVMkTckqgaC2TAK4WX_KVgs3lKKj5ioh2rLOFcEaXA",
      "fb_username": "LO Olpach",
      "fecha_ingreso": "2024-04-02 14:09:49.506409",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1712088763
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 6
    }
  },
  "7703556669708158": {
    "atributos": {
      "Gestion_numero": "12510",
      "Gestion_problema": "Ingresar de nuevo expediente por rechazo pero no sé si están atendiendo",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Virginia Galicia",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEYMcJGwMHUURQ7__koCGd9aAWSzlFj9vLaJE3_myuKc25bei1ybrR5E4LhGFO-QVRW_wfOqpdI&psid=7703556669708158&width=1024&ext=1713370795&hash=AfoF1PWdzDsNVZAAYNb2qa7zfU4kQP1-dkqxhshcax4l8A",
      "fb_username": "Lety Galicia",
      "fecha_ingreso": "2024-03-18 10:18:23.263615",
      "ultima_fecha": 1710778796
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 5
    }
  },
  "7711257378933885": {
    "atributos": {
      "Correo": "Jmeono@inproalegal.com",
      "Gestion_numero": "Más de 20 llamadas",
      "Gestion_problema": "https://scontent.xx.fbcdn.net/v/t1.15752-9/435460220_454021020395566_2272191688689633169_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=5f2048&_nc_ohc=_Dq59Qlv6HAAb6DuA0Q&_nc_oc=Adh_EBfmUZt3Jz2XD3iqJxc2ElKmp2dBd_mVE1MycOb7pkdMfFnsTP66gNtLAF42MlFMGZ1Ma-7TqCMwPDfku-GU&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdV0Gs5wtZ7SiUTz-JRnN9wGTNSqFPqnfqrHjuhLL-uT8Q&oe=663CFED6",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Jorge Meoño",
      "Telefono": "42179387",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE0aXvAqlH3HE7fZvSzpM14f-caJfBSmCZpykYHBkzRrEY3Erwq_HEqO814Y_AvygC5Ow54Y6uC&psid=7711257378933885&width=1024&ext=1715362379&hash=AfqiRWMZfafwooAX7wGu2kThqRuYlP8M5fs5N8pHc-aRAg",
      "fb_username": "J Rodrigo Meono",
      "fecha_ingreso": "2024-04-09 14:42:09.718751",
      "ultima_fecha": 1712770381
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7711708088847354": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGdWIL6QywgM_HVZ80sw8_Fxr9oAc-65y1e59YXbSjLi8bd0axFxCYZ7YKlD9W3-pMISRNznnaZ&psid=7711708088847354&width=1024&ext=1710263077&hash=Afqljv3PQTdYvYOlhFG4lpMKonN26stUAippuKgiuloUqg",
      "fb_username": "JuanPa Ortiz",
      "fecha_ingreso": "2024-02-09 12:29:53.065033",
      "ultima_fecha": 1707671080
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7717323054945441": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEwowH6DRWoGBBTtOo1VJQhh44VLrVF7HnUKMTSLZT73x-0OBvd1t0rTIK1cNT94tW8FCxFQSI9&psid=7717323054945441&width=1024&ext=1712862087&hash=Afoz9BeT4SiWnOgSU_EnTHNmdVyZVIckAfGoHyFDVm7kBg",
      "fb_username": "Django Freeman",
      "fecha_ingreso": "2024-03-12 13:00:08.562343",
      "ultima_fecha": 1710270064
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "7733876676676152": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHHccqGt4_f89lfhtDnPOrnGEQahaAiLpvVm-f_QWXHeBc1WGCW6hZHq6axEJBQHyk_Mc0JFUEH&psid=7733876676676152&width=1024&ext=1713038842&hash=AfrwimRCHdvu__r4jcie_YPJbxSduOpZHZyAQjDl3kL7BQ",
      "fb_username": "Maybelyn de Valdéz",
      "fecha_ingreso": "2024-03-14 14:07:10.242031",
      "ultima_fecha": 1710446843
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "7735790079781609": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGVk2pIepaPOFcNlCrvGTKd39XPfDu6Uks0jfHfzH7_7PX9E68UZchDScQK2k_HO7nmoWcd8tl6&psid=7735790079781609&width=1024&ext=1707917560&hash=Afobv2EVQMr7p0UwTWO_e-PI-5ivxwIzAHuwsLktmJX4sg",
      "fb_username": "Débora Marroquín",
      "fecha_ingreso": "2024-01-15 07:28:20.186100",
      "ultima_fecha": 1705325564
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "7737051789695060": {
    "atributos": {
      "Correo": "ChajònRony673@gmail.com",
      "Gestion_numero": "32839627",
      "Gestion_problema": "Me dijeron que me llegaría un mensaje a mi correo electrónico pero no me ha llegado",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Carmen Yessenia Vásquez",
      "Telefono": "36677611",
      "asesor_id": "dvVQxzxmoxXp7ifiNVkUrniMEjz1",
      "asesor_name": "mapineda",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHqvV8FLnTMhEjXaEKjfla74lqPmCBdLDE0enni4gZMeUqLVr9-nFl2CumjfnkbcuPLjhbpj_h1&psid=7737051789695060&width=1024&ext=1714662662&hash=AfoLkFHpcsq65GpU2T3AdSljwJ-GdtSPIqJWDsgLuKbOew",
      "fb_username": "Yesenia Martinez",
      "fecha_chatcenter": 1712070649853,
      "fecha_ingreso": "2024-03-21 21:07:43.828770",
      "intentos": 0,
      "leido": false,
      "log_chatcenter": "-NuUMDVnioTT0E_FWPcZ",
      "msg_noleido": 0,
      "ultima_fecha": 1712070662
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7773548709328978": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHvTX7Y8KWnBdDTudCaXcS389uJmdA_p_E0tQ9a3y7kOyxTiOdlH_eHCwcLtQTZsHYNNaJRWqu1&psid=7773548709328978&width=1024&ext=1705869181&hash=AfqlFbYPHF_SVUrmlRp7BdpONH_CFhOhS-5S3HQJPiMMOw",
      "fb_username": "Henry Solares",
      "fecha_ingreso": "2023-12-22 14:32:41.622431",
      "ultima_fecha": 1703277182
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "7776771065680009": {
    "atributos": {
      "Correo": "melissa020394@gmail.com",
      "Gestion_numero": "Asesor",
      "Gestion_problema": "Consulta",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Melissa Pineda",
      "Telefono": "Patente",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEJHLxHUQeGw2ZQtlBgLmI9APF8AVSxK4qrY4zBq1zt8DQFzt4XJ-5k8pUzsyH6k765sFkX1kEQ&psid=7776771065680009&width=1024&ext=1713722032&hash=AfpL1ct21rusKXeYyYY4qz76o8wGWhUgnDnVsdhQPacoJw",
      "fb_username": "Katherine Pineda",
      "fecha_chatcenter": 1711129159468,
      "fecha_ingreso": "2024-03-21 18:25:48.172452",
      "leido": false,
      "log_chatcenter": "-NtbEiSY1CasUIR4kC6T",
      "msg_noleido": 0,
      "ultima_fecha": 1711130033
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7779430342086808": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGUBxisIuPKrZhvzTntdCApmf2DOaXoKSf68ULau4hiwOEm_vYtcW5UEpKHgYdmO0JVHVm5ZXpS&psid=7779430342086808&width=1024&ext=1713364958&hash=AfpBix6hn2QWRoR0jhmdpnjHrC6AohsuO9tMykwDJcLj2A",
      "fb_username": "Athziri Manzanares",
      "fecha_ingreso": "2024-03-18 08:41:53.981838",
      "ultima_fecha": 1710772959
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "7810099419024270": {
    "atributos": {
      "Gestion_numero": "Buenos días. Con una consulta, me podrían indicar los horarios para esta semana en la sede de Quetzaltenango por favor?",
      "Gestion_problema": "Buenos días. Con una consulta, me podrían indicar los horarios para esta semana en la sede de Quetzaltenango por favor?",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Buenos días. Con una consulta, me podrían indicar los horarios para esta semana en la sede de Quetzaltenango por favor?",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGGUSTdMhVFxtyi34p7gZGmY8vm4qxVlYS2u-yHC_hGh6kEXktpRsHRXcxA8JnnnQateGNoGOC7&psid=7810099419024270&width=1024&ext=1713972093&hash=Afrk7U3vtRaM5idJ5_xpYvMYCiLlBN4clVjoz00DFcekPA",
      "fb_username": "Arnoldo Argueta Pereira",
      "fecha_ingreso": "2024-03-25 09:13:07.053298",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1711380094
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 5
    }
  },
  "7856193104390920": {
    "atributos": {
      "Gestion_numero": "Físico",
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHSKax5NzoXJ6VGXSnjt7BotgIUCN3ymCtP3AVFdDiihuJbEe1wumzHnL4vs3ReHUU171pKYWyO&psid=7856193104390920&width=1024&ext=1714943364&hash=AfqLd3_RFUhYQaaHvSXMGggmRB9PNBeuuFkcLO51QJikOg",
      "fb_username": "Fatima Novoa",
      "fecha_ingreso": "2024-04-05 15:08:41.290187",
      "ultima_fecha": 1712351365
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 3
    }
  },
  "7876401199051296": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "1017-193351",
      "Gestion_problema": "Boleta de pago de cancelación",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Alejandra Miranda",
      "Telefono": "23681761",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH2Yw6uDHt0naE3wCqRUgiV-oKApyWiU_xlwMVYdaDiMbdrHptOx0POKHZ2EInaqcGBvp14G3ki&psid=7876401199051296&width=1024&ext=1711562299&hash=AfrpJ4km2FKhcDDU398f8hoBV6_sSku7_qeeFMhT2K47KQ",
      "fb_username": "Alejandra Miranda",
      "fecha_ingreso": "2024-02-21 08:35:45.006843",
      "ultima_fecha": 1708970300
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "7884626661604464": {
    "atributos": {
      "Correo": "Soniachiroy99@gmail.com",
      "Gestion_numero": "Horario del día de hoy",
      "Gestion_problema": "Esta abierto el día de hoy",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Sonia Chiroy",
      "Telefono": "48721871",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEE45H5Dpif65skiHf5ZyoqP2nq7tIJuGJo2_AhO3Rc_iY6hirOICWpuZWpkci5QIgQCEDu7KgM&psid=7884626661604464&width=1024&ext=1713976687&hash=AfqaWWfi2yLlaOp9Ntkzq72hrQUICc04gu240Nfv38D44w",
      "fb_username": "Soffiia Marlenyy",
      "fecha_chatcenter": 1711384509309,
      "fecha_ingreso": "2024-03-25 10:33:50.328160",
      "leido": false,
      "log_chatcenter": "-NtqSniqrvTTd-gjLner",
      "msg_noleido": 0,
      "ultima_fecha": 1711384687
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "7976342375725683": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHYFQMYyDNK1-IjW--PUPbfqmmgkGBmXKwnymT1N6_c9BIIQ3MADT6Y7jlS1RTPw8SpXyNw8Qhj&psid=7976342375725683&width=1024&ext=1709440298&hash=AfoJDWjp3oNX3McZqhETdrqyoMgNxSAyoAZOFBwLnRvHWA",
      "fb_username": "María Juarez Illu",
      "fecha_ingreso": "2024-02-01 22:30:24.687691",
      "ultima_fecha": 1706848299
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "8008625645865528": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHaRt8FaR0aZELKt0WFj69B4YZK1Q-8oueN4S0nGiQhqA2xlAji7Mh-hR9B9t_cTPrwZka0qosw&psid=8008625645865528&width=1024&ext=1711166653&hash=AfqHUWgWHN4TAYUT1uXxVrVaXinajtoSR8G_vBKSiB9GVA",
      "fb_username": "Daniel Alvarado",
      "fecha_ingreso": "2024-02-21 22:01:43.335969",
      "ultima_fecha": 1708574655
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "8078330238947939": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFrgGeOeY5i5caeYQ8IHRmqdq_YDC6g4lfkwWjNoaN5ISY5AzBcy2ifHFDuTZkgKjeKL4YQcnbv&psid=8078330238947939&width=1024&ext=1708815462&hash=AfqDXHMWRpIh5exn29AFixUkerfl_X11Ncpz6kkB70MZTA",
      "fb_username": "Mriz Abby",
      "fecha_ingreso": "2024-01-25 16:57:42.287956",
      "ultima_fecha": 1706223464
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "8094933587186625": {
    "atributos": {
      "Gestion_tipo": "Electrónico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEPGjkZP47N77iyrANZOroq052Yt9iYfp-FFb5-fHUthaJDDNBqWNAqiHxAk2-0j3KfLdgRaDuE&psid=8094933587186625&width=1024&ext=1709996293&hash=AfpjjMJKk7BhYaz1K_kACIYOHfk7Q6GkU4JzOz7A_7EZNA",
      "fb_username": "Karens Godinez",
      "fecha_ingreso": "2024-02-08 08:57:56.040527",
      "ultima_fecha": 1707404294
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "8147849835242075": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXH6fZGlXjdlyYMAPJ5vY9B9rpdNOqsgyd6K751R6bWNH9L4JtbLA42z_Gfxwlh21ZI6nCLnJ2Us&psid=8147849835242075&width=1024&ext=1713104704&hash=AfqFg1XRKb9CwmszBqblBOi7o6D9VK2il_NvtlAVliIVHQ",
      "fb_username": "René Abigail",
      "fecha_ingreso": "2024-03-14 13:30:21.105661",
      "intentos": 0,
      "ultima_fecha": 1710512705
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "8150852131609058": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8150852131609058&width=1024&ext=1693404948&hash=AeTM_ZyjcfvH-k7C-Rw",
      "fb_username": "Mario Ch",
      "fecha_ingreso": "2023-07-31 07:48:30.715839",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1690812949
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "8170196166343529": {
    "atributos": {
      "Correo": "daaela@gmail.com",
      "Gestion_numero": "035",
      "Gestion_problema": "Ayuda",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Anai Blanco",
      "Telefono": "49351541",
      "asesor_id": "rULE4jTnk2dpx7kJ5h2Tly3cRSI2",
      "asesor_name": "echocoj",
      "chatcenter": true,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFNSx8HrfCPfC1PfXNS66eu5AiGS4NYdz4x8gQKsSghTzkbEKzKo7BpVUiNHBZ5DmZRyqff__Nr&psid=8170196166343529&width=1024&ext=1713630363&hash=Afq3Fr00IAQubuqwcweLcA845Ujf39ofN-lUHEt2o0oL8w",
      "fb_username": "Daniela Barrera de Blanco",
      "fecha_chatcenter": 1711038030106,
      "fecha_ingreso": "2024-03-21 09:41:27.253459",
      "leido": false,
      "log_chatcenter": "-NtWo54DkU9WJ2dcoh3U",
      "msg_noleido": 0,
      "ultima_fecha": 1711038364
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 7
    }
  },
  "8197798150251510": {
    "atributos": {
      "Gestion_tipo": "Físico (presencial)",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEkfowl4036iQHB7m7ansI6qVP20nwN6JOgjfOKZ4YeF6NZjNR-FhWX_9fcwC1Q1TAuyGbl9kaY&psid=8197798150251510&width=1024&ext=1713500384&hash=AfrtQ-UwHsaZhnDOccajX-Oq_SryGfeZk13n_3h_-kkI3g",
      "fb_username": "Monica Ortiz",
      "fecha_ingreso": "2024-03-19 22:19:02.614560",
      "ultima_fecha": 1710908386
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 2
    }
  },
  "8305315182820657": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=5f2048&_nc_ohc=YMYMrsxgckkAX9AsRVy&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfAOlImhq5xGM4kMsn5Bv3R_EWmar8kdgS5LQguLx6y3Aw&oe=66343B19",
      "fb_username": "Roxy De León",
      "fecha_ingreso": "2024-04-02 22:02:33.961795",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1712116968
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8314457598629280": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8314457598629280&width=1024&ext=1673202614&hash=AeQygd7lAwyvGHKGCa0",
      "fb_username": "Santi Torres",
      "fecha_ingreso": "2022-12-03 06:52:17.342772",
      "ultima_fecha": 1670607647
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "8442278049176856": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8442278049176856&width=1024&ext=1674080853&hash=AeSU1Wuxn0Pa2QbHzk8",
      "fb_username": "Maggie Ann",
      "fecha_ingreso": "2022-12-08 15:07:01.045063",
      "ultima_fecha": 1671485810
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "8444055828997989": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8444055828997989&width=1024&ext=1677870376&hash=AeSv1mv1aGCuIONCrNg",
      "fb_username": "Danilo Flores",
      "fecha_ingreso": "2023-02-01 12:09:53.601959",
      "ultima_fecha": 1675274997
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "8470301989678864": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8470301989678864&width=1024&ext=1673206382&hash=AeTi_4CSWd2p4zV0eXQ",
      "fb_username": "Pablo Urias Johnson",
      "fecha_ingreso": "2022-12-09 11:43:53.708484",
      "ultima_fecha": 1670611410
    },
    "user_posicion": {
      "bloque": "Asesor",
      "posicion": 1
    }
  },
  "8503481729722063": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8503481729722063&width=1024&ext=1676756339&hash=AeRxyY_UX-WtQ134voU",
      "fb_username": "Leonel Chang",
      "fecha_ingreso": "2023-01-19 14:44:24.612588",
      "ultima_fecha": 1674161067
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "8529860000420156": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXEKHYPoVpP3cBTIqHeSPDw5tBGJstpHwFiCztMbQV1pGBGFjeHDG5tfKcAwJdh0qcgWPsI-J6Q-&psid=8529860000420156&width=1024&ext=1708894355&hash=AfoiXJxW25apX8FtieymYsgsWv8ImXmes3aBVS1IjN1OGQ",
      "fb_username": "Gt Romaynor",
      "fecha_ingreso": "2023-03-02 13:26:22.947116",
      "intentos": 0,
      "ultima_fecha": 1706302357
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8541773672560032": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8541773672560032&width=1024&ext=1677337654&hash=AeRctN_yuBWYvXngGoA",
      "fb_username": "Gaby de García",
      "fecha_ingreso": "2023-01-18 11:54:24.143037",
      "ultima_fecha": 1674742326
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "8564165776991642": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8564165776991642&width=1024&ext=1677431334&hash=AeStYA2sMTU4tErAm70",
      "fb_username": "Jose A. Azurdia",
      "fecha_ingreso": "2023-01-11 13:38:13.508335",
      "ultima_fecha": 1674835995
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "8586885288052443": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8586885288052443&width=1024&ext=1676567325&hash=AeRnZv62l9KnKgssPk4",
      "fb_username": "Ricardo Gonzales",
      "fecha_ingreso": "2023-01-17 10:11:04.687933",
      "ultima_fecha": 1673972072
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "8598533610217787": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8598533610217787&width=1024&ext=1675532481&hash=AeQakYlNeG3ZKsfXPcU",
      "fb_username": "Marc Anthony",
      "fecha_ingreso": "2023-01-05 10:45:33.514805",
      "ultima_fecha": 1672937317
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 1
    }
  },
  "8599683616768792": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8599683616768792&width=1024&ext=1678999133&hash=AeQXFG25KSfBim7fKFw",
      "fb_username": "Vivi Porras",
      "fecha_ingreso": "2023-02-14 13:39:59.711505",
      "ultima_fecha": 1676403642
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "8633835493354196": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8633835493354196&width=1024&ext=1678297826&hash=AeTsDNUz4qV_MPqLVn8",
      "fb_username": "Ramon Castillo",
      "fecha_ingreso": "2023-01-25 09:25:50.489461",
      "ultima_fecha": 1675702406
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "8635486006523406": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8635486006523406&width=1024&ext=1678035318&hash=AeQhBAc499uEMsYFr5g",
      "fb_username": "Letty Valdez",
      "fecha_ingreso": "2023-02-03 09:57:23.296782",
      "ultima_fecha": 1675439920
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "8659057554165267": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8659057554165267&width=1024&ext=1677702190&hash=AeQH347-5-YWGRtV0Ek",
      "fb_username": "Adelso Ceron",
      "fecha_ingreso": "2023-01-30 13:23:42.813983",
      "ultima_fecha": 1675106825
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8672034552869282": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8672034552869282&width=1024&ext=1677089670&hash=AeRHecBFSU41o8gK8Bo",
      "fb_username": "Luis Carlos Chávez Morales",
      "fecha_ingreso": "2023-01-20 10:17:20.338202",
      "ultima_fecha": 1674494364
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "8679326138808013": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8679326138808013&width=1024&ext=1677443400&hash=AeRfzDoHRDP01vVkbxs",
      "fb_username": "Amado Ortega",
      "fecha_ingreso": "2023-01-27 13:33:14.803922",
      "ultima_fecha": 1674848061
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "8701282893276506": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8701282893276506&width=1024&ext=1678463966&hash=AeTEeijEuFr34bs_0fo",
      "fb_username": "Aguiilar Lilian",
      "fecha_ingreso": "2023-02-08 08:59:40.850617",
      "ultima_fecha": 1675868527
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "8703141709756489": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8703141709756489&width=1024&ext=1680406119&hash=AeRyANW81RtXVrJHCH4",
      "fb_username": "Rosamaria Flores del Pinal",
      "fecha_ingreso": "2023-03-02 11:27:49.907727",
      "intentos": 3,
      "ultima_fecha": 1677810491
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "8748570231881835": {
    "atributos": {
      "fb_picture": "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=7z6O-rE62kgAX_kfyOL&_nc_ht=scontent-dfw5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfDTsUycJjKkkDzV9X1SrdFV9XtfhVsN7GNGnU4yIjfblg&oe=6408C319",
      "fb_username": "Maria Aguilar",
      "fecha_ingreso": "2023-01-31 14:04:10.377985",
      "ultima_fecha": 1675707970
    },
    "user_posicion": {
      "bloque": "Formularios_ver",
      "posicion": 2
    }
  },
  "8786009374804399": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8786009374804399&width=1024&ext=1679079643&hash=AeTTCLpJrR--2BOr_w0",
      "fb_username": "Deisy Lopez",
      "fecha_ingreso": "2023-02-15 11:58:43.080377",
      "ultima_fecha": 1676484146
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "8788185667923212": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8788185667923212&width=1024&ext=1680360768&hash=AeQoXJAU_TsweVVSpsM",
      "fb_username": "HP Yath",
      "fecha_ingreso": "2023-03-02 07:50:49.923809",
      "ultima_fecha": 1677765144
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "8795782220463027": {
    "atributos": {
      "Correo": "julian_auditores@hotmail.com",
      "Gestion_numero": "63097-2023",
      "Gestion_problema": "No se descarga el rechazo actualizado",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Julián González",
      "Telefono": "42114749",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8795782220463027&width=1024&ext=1691090192&hash=AeQTJe6WqxavTzslsmc",
      "fb_username": "Julián González",
      "fecha_ingreso": "2023-01-30 07:45:38.431739",
      "ultima_fecha": 1688498193
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "8800858896654873": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8800858896654873&width=1024&ext=1679174217&hash=AeSoTGhFGMYATWXBOlU",
      "fb_username": "Nery Herrarte",
      "fecha_ingreso": "2023-02-16 14:16:24.061755",
      "ultima_fecha": 1676578708
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "8814098531994426": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8814098531994426&width=1024&ext=1680366793&hash=AeQNPHygKUu0VFLm6QY",
      "fb_username": "Rene Oswaldo",
      "fecha_ingreso": "2023-03-02 09:29:34.485344",
      "intentos": 0,
      "ultima_fecha": 1677771171
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "8815103811863327": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8815103811863327&width=1024&ext=1674487255&hash=AeSU8RTo6_0IT1xroBo",
      "fb_username": "Ana De Leon",
      "fecha_ingreso": "2022-12-24 08:23:10.731453",
      "ultima_fecha": 1671892178
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "8824015837669841": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8824015837669841&width=1024&ext=1680748073&hash=AeRiJ_-5r4yllSmCPDU",
      "fb_username": "CasaOptica Guatemala",
      "fecha_ingreso": "2023-03-06 19:16:45.955225",
      "ultima_fecha": 1678152424
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "8855909421117423": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8855909421117423&width=1024&ext=1678390217&hash=AeRrNE42lfJTIscZIkY",
      "fb_username": "Mariela Macz",
      "fecha_ingreso": "2023-01-26 14:00:53.034069",
      "ultima_fecha": 1675794786
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "8861467740544915": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8861467740544915&width=1024&ext=1675269381&hash=AeRLDU5SqTDR3P5eDD4",
      "fb_username": "Obdulio Mejía",
      "fecha_ingreso": "2023-01-02 09:43:05.943752",
      "ultima_fecha": 1672674238
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "8869663299772166": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8869663299772166&width=1024&ext=1679596452&hash=AeSo33OynDiFlF_v1Ho",
      "fb_username": "Londy López D'Santizo",
      "fecha_ingreso": "2023-02-16 12:12:43.251284",
      "intentos": 2,
      "ultima_fecha": 1677000904
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "8888353741205695": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8888353741205695&width=1024&ext=1681334440&hash=AeQUlXubs6PxDT4_Cyk",
      "fb_username": "Cindy Hernández Marroquin",
      "fecha_ingreso": "2023-03-13 14:18:51.619934",
      "intentos": 0,
      "ultima_fecha": 1678738753
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8894768253928357": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8894768253928357&width=1024&ext=1679858578&hash=AeQhW_7plIjXCslX2i4",
      "fb_username": "Abigail De Jesus Roblero",
      "fecha_ingreso": "2023-02-24 12:22:56.082030",
      "intentos": 0,
      "ultima_fecha": 1677263003
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8900251516714508": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8900251516714508&width=1024&ext=1675435661&hash=AeRCdtxYZr0UApiNrRk",
      "fb_username": "Marcos Val",
      "fecha_ingreso": "2023-01-04 07:52:49.810845",
      "ultima_fecha": 1672840504
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "8902600936429378": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGup0k5RhAFrHYlcetgb968CZrbbWK6su-NUeP29l9l8pjgcUMUcHDb_ProO2Bo2NuB2_9LmAqp&psid=8902600936429378&width=1024&ext=1710108479&hash=Afo2iso8IUhrx57VrPPGmQVuVOKAR9Oa1bmNlhAR7GvqUw",
      "fb_username": "Christian Ricardo LebNij",
      "fecha_ingreso": "2024-02-09 16:06:06.195293",
      "ultima_fecha": 1707516481
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "8905833419486722": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8905833419486722&width=1024&ext=1681061295&hash=AeQl1IOyuYTVPh8oDb8",
      "fb_username": "Heto Herrera",
      "fecha_ingreso": "2023-03-10 10:26:46.510858",
      "intentos": 0,
      "ultima_fecha": 1678465629
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "8926112944126619": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8926112944126619&width=1024&ext=1683221663&hash=AeQ-03FsHrib0Wlp5Gw",
      "fb_username": "Xiguac Ixchel",
      "fecha_ingreso": "2023-04-04 10:29:46.310906",
      "intentos": 0,
      "ultima_fecha": 1680625813
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8948688625201483": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8948688625201483&width=1024&ext=1680751289&hash=AeQl-6d78AunMjCrTIo",
      "fb_username": "Saraí Pineda",
      "fecha_ingreso": "2023-03-06 20:14:31.231926",
      "ultima_fecha": 1678155640
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "8959077397466169": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8959077397466169&width=1024&ext=1678048545&hash=AeR3tcccxyMNVtcYylQ",
      "fb_username": "Williams Chanax",
      "fecha_ingreso": "2023-02-03 13:35:48.369625",
      "ultima_fecha": 1675453147
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "8963578757050296": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8963578757050296&width=1024&ext=1682014829&hash=AeT_ttkMED22QPyhbQQ",
      "fb_username": "Silvia Sánchez",
      "fecha_ingreso": "2023-03-21 11:16:14.202773",
      "intentos": 0,
      "ultima_fecha": 1679419093
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "8971976706176293": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8971976706176293&width=1024&ext=1677778908&hash=AeSZECbLaOpWuaHLBUw",
      "fb_username": "Jaime Rolando Jordán Lemus",
      "fecha_ingreso": "2023-01-31 10:43:30.027494",
      "ultima_fecha": 1675183536
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "8981461231896379": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8981461231896379&width=1024&ext=1681406873&hash=AeQ-BTgtNVn4h2KY9YM",
      "fb_username": "Fercho Mcrio",
      "fecha_ingreso": "2023-03-14 10:25:31.596625",
      "ultima_fecha": 1678811181
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "8991404487600860": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8991404487600860&width=1024&ext=1682797640&hash=AeT51HQLcygsJtRDvhw",
      "fb_username": "Garrido Dulmary",
      "fecha_ingreso": "2023-03-30 12:43:45.889328",
      "intentos": 0,
      "ultima_fecha": 1680201847
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "8996789003724440": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=8996789003724440&width=1024&ext=1683752331&hash=AeTtLGo8QVsN8NqkCkQ",
      "fb_username": "Daniel Sican",
      "fecha_ingreso": "2023-04-10 13:50:33.638105",
      "ultima_fecha": 1681156468
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "9007791495958568": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9007791495958568&width=1024&ext=1683230987&hash=AeTkTgaPH-DCCgnbXeQ",
      "fb_username": "Guillermo Lucero",
      "fecha_ingreso": "2023-04-04 13:05:42.173924",
      "intentos": 1,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680635164
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9011351028935161": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9011351028935161&width=1024&ext=1680825637&hash=AeRueu42BcY9lWct3iw",
      "fb_username": "Nathalie Pernillo",
      "fecha_ingreso": "2023-03-07 16:59:14.717638",
      "ultima_fecha": 1678229985
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9070472669661213": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9070472669661213&width=1024&ext=1681928666&hash=AeQd6s2z2otzEjxDY_4",
      "fb_username": "Marce Xuyá",
      "fecha_ingreso": "2023-03-20 11:21:15.472868",
      "intentos": 0,
      "ultima_fecha": 1679332937
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "9072478922825638": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9072478922825638&width=1024&ext=1682618886&hash=AeRMVNSApW-WwBvwHoM",
      "fb_username": "Andrea Marroquín",
      "fecha_ingreso": "2023-03-21 07:38:07.269681",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1680023107
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  },
  "9075167552555276": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "32611572",
      "Gestion_problema": "Cuando lo autorizaran",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Porfirio Chanchavac",
      "Telefono": "44210132",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9075167552555276&width=1024&ext=1682884562&hash=AeR_bxhp_WDddDTM3lM",
      "fb_username": "Jorge Lopez",
      "fecha_ingreso": "2023-03-30 12:29:31.090355",
      "intentos": 0,
      "ultima_fecha": 1680288762
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "9095021010538071": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9095021010538071&width=1024&ext=1679570058&hash=AeRfmQqihjlvQhbO1hg",
      "fb_username": "Ēxøñīc Lẞ",
      "fecha_ingreso": "2023-02-21 04:15:10.359677",
      "ultima_fecha": 1676974514
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9102326056507081": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9102326056507081&width=1024&ext=1682109416&hash=AeQLymLYhj1rZ2dnkqo",
      "fb_username": "Minerva Occidental",
      "fecha_ingreso": "2023-03-22 13:31:59.395230",
      "intentos": 1,
      "ultima_fecha": 1679513675
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9122179384466294": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9122179384466294&width=1024&ext=1677955816&hash=AeQcIz9qlMgitSKu3ts",
      "fb_username": "Karen Lisseth Ortiz",
      "fecha_ingreso": "2023-02-02 11:52:11.875225",
      "ultima_fecha": 1675360431
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "9126978447343731": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9126978447343731&width=1024&ext=1685119302&hash=AeTCP9if5hxgqkMlJEE",
      "fb_username": "Carrillo Francisco",
      "fecha_ingreso": "2023-04-26 10:37:43.630842",
      "ultima_fecha": 1682527304
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "9129890507086351": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "70022",
      "Gestion_problema": "215",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "No me han dado razón de la inscripción",
      "Telefono": "5537-1441",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9129890507086351&width=1024&ext=1686167754&hash=AeTXw6X5cpXpK9thrWc",
      "fb_username": "Denisse Alonzo",
      "fecha_ingreso": "2023-05-08 13:48:34.618028",
      "intentos": 0,
      "ultima_fecha": 1683575755
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "9132574646784171": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9132574646784171&width=1024&ext=1682190525&hash=AeR8HS3FnC7xJka37IE",
      "fb_username": "Julio Miranda",
      "fecha_ingreso": "2023-03-23 12:05:13.587630",
      "intentos": 0,
      "ultima_fecha": 1679594777
    },
    "user_posicion": {
      "bloque": "SociedadMercantil",
      "posicion": 1
    }
  },
  "9134867316555733": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9134867316555733&width=1024&ext=1683130751&hash=AeQulFEJQXZ_jg6BEjE",
      "fb_username": "Berner Estuardo Nájera",
      "fecha_ingreso": "2023-04-03 09:14:44.668309",
      "intentos": 0,
      "ultima_fecha": 1680534935
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9143058952378747": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9143058952378747&width=1024&ext=1677434921&hash=AeR35Tfyxzeznr6K35k",
      "fb_username": "Saydy FL",
      "fecha_ingreso": "2023-01-27 11:09:42.808616",
      "ultima_fecha": 1674839582
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "9149694418436679": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9149694418436679&width=1024&ext=1683134502&hash=AeQPPR8VnqTfqulZ0Hc",
      "fb_username": "Marco Garcia",
      "fecha_ingreso": "2023-04-03 10:17:16.525894",
      "intentos": 0,
      "ultima_fecha": 1680538685
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "9190839924319723": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9190839924319723&width=1024&ext=1687139383&hash=AeQckbO593Gt2cCqgGc",
      "fb_username": "Ibarra Ale",
      "fecha_ingreso": "2023-05-19 19:49:16.228200",
      "ultima_fecha": 1684547386
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "9194740930598684": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9194740930598684&width=1024&ext=1686247270&hash=AeRHIlLn1WHAjwANV6Y",
      "fb_username": "Fuad Hazboun Recinos",
      "fecha_ingreso": "2023-05-09 12:00:42.814653",
      "intentos": 1,
      "ultima_fecha": 1683655274
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "9216689095068675": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9216689095068675&width=1024&ext=1686519416&hash=AeS2hYlYYkSI5lwwdHw",
      "fb_username": "María José López Ruano",
      "fecha_ingreso": "2023-05-12 09:25:57.608772",
      "ultima_fecha": 1683927418
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "9231285923612316": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9231285923612316&width=1024&ext=1688259356&hash=AeRIwacKQQ6tM68c-AI",
      "fb_username": "Silvia María",
      "fecha_ingreso": "2023-06-01 18:55:30.627359",
      "ultima_fecha": 1685667358
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "9239224349484401": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9239224349484401&width=1024&ext=1684010125&hash=AeR7hcw6dfP0277RYio",
      "fb_username": "Christian Oswaldo Ramirez",
      "fecha_ingreso": "2023-04-13 13:30:42.434409"
    }
  },
  "9268100023216091": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9268100023216091&width=1024&ext=1675628456&hash=AeSMes8bTcp-_Vvq2Ek",
      "fb_username": "Adriana Perez",
      "fecha_ingreso": "2023-01-06 13:26:12.393121",
      "ultima_fecha": 1673033283
    },
    "user_posicion": {
      "bloque": "Sedes",
      "posicion": 1
    }
  },
  "9269534619754666": {
    "atributos": {
      "Correo": "yensimadai@hotmail.com",
      "Gestion_numero": "21151-2023",
      "Gestion_problema": "Me lo siguen rechazando pero cuando descargo la hoja de rechazo sigue apareciendo la 1ra. Hoja y no se cual es el inconvenientes",
      "Gestion_tipo": "Electrónico",
      "Nombre": "Yensi Madaí López González",
      "Telefono": "54476236",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9269534619754666&width=1024&ext=1684433175&hash=AeQBxdTY-X2J4NVIp-E",
      "fb_username": "Yensi Lopez",
      "fecha_ingreso": "2023-03-31 08:00:50.582361",
      "ultima_fecha": 1681841175
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "9270716672970027": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9270716672970027&width=1024&ext=1694970402&hash=AeSSMOV26PtH-tYCIzI",
      "fb_username": "Myrna Cacao",
      "fecha_ingreso": "2023-04-13 15:04:30.209654",
      "intentos": 0,
      "ultima_fecha": 1692378405
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "9270845809654991": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9270845809654991&width=1024&ext=1686931748&hash=AeTSLYJCA-8B6dsupck",
      "fb_username": "Lesly Lucero",
      "fecha_ingreso": "2023-05-17 10:08:31.917860",
      "ultima_fecha": 1684339751
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "9272690652803453": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9272690652803453&width=1024&ext=1688055261&hash=AeSOXzgSzCQxuyLOiHk",
      "fb_username": "Noé Olayo",
      "fecha_ingreso": "2023-05-08 09:52:33.195817",
      "intentos": 0,
      "ultima_fecha": 1685463263
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "9296736293732319": {
    "atributos": {
      "Correo": "No",
      "Gestion_numero": "Reposición de patente de comercio",
      "Gestion_problema": "Requisitos para reponer patente de comercio",
      "Gestion_tipo": "Electrónico",
      "Nombre": "María González",
      "Telefono": "54603701",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9296736293732319&width=1024&ext=1688767759&hash=AeQl_dtE7zwC1B5hHPs",
      "fb_username": "Rosario Somoza",
      "fecha_ingreso": "2023-06-07 14:46:47.286167",
      "ultima_fecha": 1686175759
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "9314942168580226": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXFfMxaIjvDM-sqijT6OLjQMimflVqRTVxIweIW_jRNkhXZ0UB-JCxPPk8fsJUqkS1WAQ_nYulPO&psid=9314942168580226&width=1024&ext=1712252492&hash=Afoy49mTUPJfm9lb9MhOtvEETz0Yc3IasDRh8_I6omYfJQ",
      "fb_username": "Herber Barreno",
      "fecha_ingreso": "2023-05-24 13:20:30.877051",
      "intentos": 0,
      "ultima_fecha": 1709660494
    },
    "user_posicion": {
      "bloque": "Certificaciones",
      "posicion": 1
    }
  },
  "9326443944095253": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9326443944095253&width=1024&ext=1686509195&hash=AeQO_ms4-6eSpasC-j8",
      "fb_username": "Fer Ghonzlez",
      "fecha_ingreso": "2023-05-12 12:46:35.564207",
      "ultima_fecha": 1683917198
    },
    "user_posicion": {
      "bloque": "SociedadEmprendimientoINS",
      "posicion": 1
    }
  },
  "9348868411821207": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=S7G7zlblfxcAX_Ze-rb&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfCbjDlhTeDDFfO8s-dqFdCOwj5392YdCQpRzChAmhwbaA&oe=64ACAD99",
      "fb_username": "Esmeralda Gallardo",
      "fecha_ingreso": "2023-06-10 18:40:40.169333",
      "intentos": 0,
      "ultima_fecha": 1686446255
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "9351006951640408": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9351006951640408&width=1024&ext=1688596138&hash=AeQgBPtQKJRVfTnbWTo",
      "fb_username": "Byron Cifuentes",
      "fecha_ingreso": "2023-06-05 16:19:28.938941",
      "intentos": 0,
      "ultima_fecha": 1686004141
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "9353907161346881": {
    "atributos": {
      "Correo": "Info_akamstore@icloud.com",
      "Gestion_numero": "Trámite inscripción de empresa",
      "Gestion_problema": "Rechazo en trámite de patente por objetivo",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "Myrna Azucena Amesquita Navarro",
      "Telefono": "57890738",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9353907161346881&width=1024&ext=1687969344&hash=AeSLpyK2l1xM5wE4F_I",
      "fb_username": "Akam Luiz",
      "fecha_ingreso": "2023-05-26 08:33:37.573022",
      "ultima_fecha": 1685377344
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "9357732614301293": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9357732614301293&width=1024&ext=1688680621&hash=AeQ25rI9Bz59vSiG-y4",
      "fb_username": "Guadalupe Martínez",
      "fecha_ingreso": "2023-06-06 15:57:01.917713",
      "intentos": 1,
      "ultima_fecha": 1686088626
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9388363934567828": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9388363934567828&width=1024&ext=1691265865&hash=AeR0cvTTxpbv1llAk74",
      "fb_username": "Margarita Rodríguez",
      "fecha_ingreso": "2023-07-06 13:16:53.446419",
      "intentos": 0,
      "ultima_fecha": 1688673867
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "9392694550748071": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9392694550748071&width=1024&ext=1678291135&hash=AeQaLjDuS0-fmAsoWEY",
      "fb_username": "Strosner Sagastume",
      "fecha_ingreso": "2023-02-06 08:59:17.829907",
      "ultima_fecha": 1675695712
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 1
    }
  },
  "9395035330567816": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9395035330567816&width=1024&ext=1688685838&hash=AeTYFYu8qJwgXhx38BE",
      "fb_username": "Telma Marro",
      "fecha_ingreso": "2023-06-06 17:21:31.077638",
      "intentos": 0,
      "ultima_fecha": 1686093841
    },
    "user_posicion": {
      "bloque": "Sedes_Capital",
      "posicion": 1
    }
  },
  "9406679736040790": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9406679736040790&width=1024&ext=1687983074&hash=AeR9WqGm--TyeP2LOQI",
      "fb_username": "Kevin Samuel Lopez",
      "fecha_ingreso": "2023-05-29 14:10:07.608126",
      "ultima_fecha": 1685391075
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9411537868863623": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9411537868863623&width=1024&ext=1679672997&hash=AeR7fCnfOimpXvSQQmA",
      "fb_username": "Elizabeth Pacheco",
      "fecha_ingreso": "2023-01-06 09:40:34.063717",
      "intentos": 0,
      "ultima_fecha": 1677077443
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9428924997179899": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9428924997179899&width=1024&ext=1689444670&hash=AeR5nAv4fNvT6nd9l-0",
      "fb_username": "Telmis Herrera",
      "fecha_ingreso": "2023-06-15 12:07:26.837824",
      "ultima_fecha": 1686852642
    },
    "user_posicion": {
      "bloque": "eVentanilla",
      "posicion": 2
    }
  },
  "9440285286012200": {
    "atributos": {
      "fb_picture": "",
      "fb_username": "guest-9440285286012200",
      "fecha_ingreso": "2023-05-10 10:18:11.879796",
      "intentos": 0,
      "ultima_fecha": 1683735494
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "9460963460645201": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9460963460645201&width=1024&ext=1688848311&hash=AeROGgKsj4qTwWUVTS4",
      "fb_username": "Felipe Torres",
      "fecha_ingreso": "2023-06-08 14:31:51.764409",
      "intentos": 1,
      "ultima_fecha": 1686256316
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9506642909375756": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9506642909375756&width=1024&ext=1686173721&hash=AeREEHf03k9iAaGUv78",
      "fb_username": "Veronica Sc",
      "fecha_ingreso": "2023-05-08 15:34:58.100688",
      "ultima_fecha": 1683581724
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "9537626266263388": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9537626266263388&width=1024&ext=1678652442&hash=AeSnIOBr3q1VZCFYwOs",
      "fb_username": "Elena Martinez D Guzmán",
      "fecha_ingreso": "2023-02-10 13:18:56.111129",
      "ultima_fecha": 1676056985
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "9566947643379429": {
    "atributos": {
      "fb_picture": "https://scontent-ord5-2.xx.fbcdn.net/v/t1.30497-1/84628273_176159830277856_972693363922829312_n.jpg?stp=dst-jpg_p720x720&_nc_cat=1&ccb=1-7&_nc_sid=12b3be&_nc_ohc=G_cozWADkD0AX-xsuQ-&_nc_ht=scontent-ord5-2.xx&edm=AP4hL3IEAAAA&oh=00_AfDCUCfU79wbgn9yKAAlVu48oVC-SUWZ9IfIBMep6753PQ&oe=64DFA799",
      "fb_username": "Rafael Gomez",
      "fecha_ingreso": "2023-07-19 14:24:36.482115",
      "intentos": 0,
      "ultima_fecha": 1689798499
    },
    "user_posicion": {
      "bloque": "Firma_electronica",
      "posicion": 1
    }
  },
  "9600725126619293": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9600725126619293&width=1024&ext=1685909041&hash=AeQTVHhgTg56JERhmtU",
      "fb_username": "Nelsotionn Cenationn",
      "fecha_ingreso": "2023-05-05 14:03:17.687103",
      "ultima_fecha": 1683317043
    },
    "user_posicion": {
      "bloque": "e_Servicios",
      "posicion": 2
    }
  },
  "9616985978342754": {
    "atributos": {
      "Correo": "Beverly1197@gmail.com",
      "Gestion_numero": "32681414",
      "Gestion_problema": "Necesitolapatente",
      "Gestion_tipo": "Físico (presencial)",
      "Nombre": "JosePerez",
      "Telefono": "58670914",
      "chatcenter": false,
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9616985978342754&width=1024&ext=1691956101&hash=AeTd1C9lcFt2bMXK4wc",
      "fb_username": "Gerson Tujab",
      "fecha_ingreso": "2023-07-12 11:24:17.357413",
      "ultima_fecha": 1689364101
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 0
    }
  },
  "9618332788208053": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9618332788208053&width=1024&ext=1689942836&hash=AeSGwJIDd1DGGTq8nKk",
      "fb_username": "Arian Alvarez",
      "fecha_ingreso": "2023-06-21 06:33:11.475628",
      "ultima_fecha": 1687350838
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "9654602954611342": {
    "atributos": {
      "Gestion_tipo": "Fisico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXE9TKlG6ABdIJVbgep-oJTdJWe7eKkllmzlAPw_Syk3Hd4v7ptcTVQyyqSuMeecYzQE2eF2nAzV&psid=9654602954611342&width=1024&ext=1713631643&hash=Afrtd5n1zyBaaUvVxdQYWctsvBv7WDTpQ-MMAad6vh3S_A",
      "fb_username": "Eliel Machic",
      "fecha_ingreso": "2023-07-28 13:51:06.935624",
      "intentos": 0,
      "ultima_fecha": 1711039644
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9659702394043531": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9659702394043531&width=1024&ext=1683920110&hash=AeSoenXUrFrZDTBTNIk",
      "fb_username": "Evelin Pirir",
      "fecha_ingreso": "2023-04-12 12:23:26.043640",
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1681324236
    },
    "user_posicion": {
      "bloque": "Auxiliares_Asamblea_Acciones",
      "posicion": 1
    }
  },
  "9693759693968208": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9693759693968208&width=1024&ext=1687039168&hash=AeT4g9KzUngaDtaeX8c",
      "fb_username": "Francis Vides Garcia",
      "fecha_ingreso": "2023-05-18 15:58:44.878588",
      "ultima_fecha": 1684447172
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9713101572096163": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9713101572096163&width=1024&ext=1694637670&hash=AeSQiGbXA14e6v_bZ8Y",
      "fb_username": "Mildred Fernandez",
      "fecha_ingreso": "2023-08-14 14:40:27.647698",
      "ultima_fecha": 1692045671
    },
    "user_posicion": {
      "bloque": "Sedes_deptos",
      "posicion": 1
    }
  },
  "9754316641310258": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9754316641310258&width=1024&ext=1692199700&hash=AeQge8imqnlbNcrjE9c",
      "fb_username": "Angie Del Cid",
      "fecha_ingreso": "2023-07-17 09:28:20.861697",
      "ultima_fecha": 1689607703
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "9760008197373333": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9760008197373333&width=1024&ext=1689285195&hash=AeQKhcxpK51B29wwXXg",
      "fb_username": "Walter Fox",
      "fecha_ingreso": "2023-06-12 13:02:13.197978",
      "intentos": 0,
      "ultima_fecha": 1686693197
    },
    "user_posicion": {
      "bloque": "Boletas_Pagos",
      "posicion": 1
    }
  },
  "9779005715458126": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9779005715458126&width=1024&ext=1680479048&hash=AeR9KUw1bo0inDrVrC8",
      "fb_username": "Margui Rodriguez",
      "fecha_ingreso": "2023-03-03 16:43:03.753595",
      "ultima_fecha": 1677883413
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9791378667601470": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9791378667601470&width=1024&ext=1695326865&hash=AeQarZ25G3gOTKNLs1E",
      "fb_username": "Yesenia García",
      "fecha_ingreso": "2023-08-22 13:43:48.588069",
      "ultima_fecha": 1692734867
    },
    "user_posicion": {
      "bloque": "Resolver_dudas",
      "posicion": 2
    }
  },
  "9817949504913804": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9817949504913804&width=1024&ext=1691676410&hash=AeSUYok4yscjP2K4EuA",
      "fb_username": "Giovanni Badalamenti",
      "fecha_ingreso": "2023-07-11 08:06:12.967396",
      "intentos": 1,
      "ultima_fecha": 1689084413
    },
    "user_posicion": {
      "bloque": "Sede_Virtual",
      "posicion": 1
    }
  },
  "9875379052536500": {
    "atributos": {
      "Gestion_tipo": "es fisico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9875379052536500&width=1024&ext=1697209769&hash=AeR_ICO5WTKqFDuEs50",
      "fb_username": "Eli Marroquin",
      "fecha_ingreso": "2023-09-13 09:08:23.941186",
      "ultima_fecha": 1694617774
    },
    "user_posicion": {
      "bloque": "Tramites",
      "posicion": 2
    }
  },
  "9881878565220716": {
    "atributos": {
      "Gestion_tipo": "virtual",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9881878565220716&width=1024&ext=1696436558&hash=AeT3Rx0FluPa0k7clXg",
      "fb_username": "Andres C. Morales",
      "fecha_ingreso": "2023-09-04 10:19:55.614171",
      "ultima_fecha": 1693844561
    },
    "user_posicion": {
      "bloque": "ePortal",
      "posicion": 2
    }
  },
  "9883468518391112": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9883468518391112&width=1024&ext=1694187452&hash=AeRQyjEjrkgxTpQ0AlA",
      "fb_username": "Angela Puluc",
      "fecha_ingreso": "2023-08-09 09:37:33.039275",
      "ultima_fecha": 1691595457
    },
    "user_posicion": {
      "bloque": "default",
      "posicion": 2
    }
  },
  "9949068975134997": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXHFkv7xGYnrGfjoU8r8KyTboM9Y_jOr_3jz1KVY9jWUzpuDmx1qzHCoRX-ZQeehoLfaH-eHZHkX&psid=9949068975134997&width=1024&ext=1707600452&hash=Afo8O6v-HoIgdagQ6AAJ907BUUIzhuh3msmnJ8MziLGDzA",
      "fb_username": "Saraí Navarro",
      "fecha_ingreso": "2023-08-21 14:25:06.110443",
      "ultima_fecha": 1705008455
    },
    "user_posicion": {
      "bloque": "e_consultas",
      "posicion": 2
    }
  },
  "9973561712669270": {
    "atributos": {
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?psid=9973561712669270&width=1024&ext=1680721823&hash=AeRVP1UJK4hGWhLybac",
      "fb_username": "Gloria Julajuj",
      "fecha_ingreso": "2023-03-06 12:07:20.847974",
      "intentos": 0,
      "ultima_fecha": 1678126175
    },
    "user_posicion": {
      "bloque": "InscripcionEmpresa",
      "posicion": 1
    }
  },
  "9985374814821195": {
    "atributos": {
      "Gestion_tipo": "Fisico",
      "fb_picture": "https://platform-lookaside.fbsbx.com/platform/profilepic/?eai=AXGyCRplImtqCT70nbE-JpdguNdfdBWf_bNVSRGDq__ZtZDzWJ5S6KWuTJHIUswoDGXQugy34fQd&psid=9985374814821195&width=1024&ext=1709916429&hash=Afr6s6aV1ti6mYHVdVjFdPZhJqlqPMFPNTy1nczhReSl5A",
      "fb_username": "Carlos Paredes",
      "fecha_ingreso": "2023-04-20 12:36:04.771479",
      "intentos": 0,
      "leido": false,
      "msg_noleido": 0,
      "ultima_fecha": 1707324431
    },
    "user_posicion": {
      "bloque": "EmpresaMercantil",
      "posicion": 1
    }
  }
}

 export default {datosA}
