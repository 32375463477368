import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import Variables from './variables'
import { Button, Typography,Avatar } from "../../components/Wrappers";

import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';

function Reports ()
{
   var classes = useStyles();

   return (
      <Variables
        red={"telegram"}
        icono={  <Typography className={classes.LeyendaPorcentajeTitulo}>
            <TelegramIcon style={{ fontSize: 43,
                                   color: "#279fd3"
                                 }}  />
          </Typography>}
      />
   );

}



export default Reports;
