import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';

import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import DeleteElementos from '../DeleteElementos';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import DialogElement from './dialogElement';
import log_it from './../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    }
  }),
);

export function Imagen(props){

    const classes = useStyles();
    var {bot_actual} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);
    /*variables de datos*/
    const [url,setUrl]=React.useState("");
    const [tipo,setTipo] = React.useState("image");


    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);

    /*inicializar vaiables*/
    useEffect(() =>{

        if(props.datos)
        {
            setUrl(props.datos.url);
            setTipo(props.datos.tipo)
        }

      },[open]);


      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        if(props.id_elemento===-1)
        {
          setOpen(false);
          props.cerrarElmento();
        }
        setOpen(false);
      };


      const GuardarCambios=()=>{

        setCargando(true);
        var patch_tipo = tipo
        if(bot_actual.conexion=="instagram"){
          patch_tipo = 'image'
        }
        if(props.id_elemento===-1){
          setCargando(false);
          setOpen(false);
          enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
          props.GuardarNuevoElemento({
              elemento:"archivo",
              tipo:patch_tipo,
              url:url,
          },props.new_index);
        }else{
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
            .update({
                elemento:"archivo",
                tipo:patch_tipo,
                url:url,
            },function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                    setCargando(false);
                    enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                    setOpen(false);
                    var obj = {
                      fecha: + new Date(),
                      nombre: "modificar elemento",
                      id_accion: 132,
                      id_bot: bot_actual.id,
                      red_social: bot_actual.conexion,
                      bloque:props.id_bloque,
                      elemento:patch_tipo,
                    }
                    log_it(datos.id_empresa,user.id,obj)
  
                }
            });
        }

      }
    const CambiarTipo=(e)=>{
      setTipo(e.target.value);
    }

    return(
        <React.Fragment>

          <DialogElement
            id_elemento={props.id_elemento}
            moverElementos={props.moverElementos}
            moverMasuno={props.MoverMasuno}
            moverMenosuno={props.MoverMenosuno}
            icono={<ImageRoundedIcon/>}
            metodoAbrir={handleClickOpen}
            nombre={props.nombre}
            tamanio={props.tamanio}
            dialogOpen={open}
            dialogClose={handleClose}
            cargando={cargando}
            disabledSave={url.length === 0}
            guardar={GuardarCambios}
            eliminar={props.eliminar}
            cerrar={setOpen}
          >

                    <Grid item xs={12}>
                        <TextField
                          id="ElImagen_url"
                          value={url}
                          onChange={e => setUrl(e.target.value)}
                          label="URL"
                          variant="outlined"
                          multiline
                          disabled={datos.permisos.abcbot.bloques!==true}
                          fullWidth />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                        <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                        {(bot_actual.conexion=="instagram")?
                          <Select
                            disabled="true"
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined-quil"
                            value={'image'}
                            label="Tipo"
                            onChange={CambiarTipo}
                          >
                              <MenuItem value={"image"}>Imagen</MenuItem>
                          </Select>
                          :
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined-quil"
                              value={tipo}
                              label="Tipo"
                              onChange={CambiarTipo}
                          >
                              <MenuItem value={"image"}>Imagen</MenuItem>
                              <MenuItem value={"file"}>Archivo</MenuItem>
                              <MenuItem value={"video"}>Video</MenuItem>
                              <MenuItem value={"audio"}>Audio</MenuItem>
                          </Select>
                          }
                        </FormControl>
                    </Grid>


          {tipo==='image' &&    <Grid item xs={12}>
                        <Avatar alt="Remy Sharp" src={url} />
                    </Grid>}

              </DialogElement>
        </React.Fragment>
       );

}
