import React, { useState ,useEffect,Component} from 'react';
import useStyles from '../styles';
import {Grid} from "@material-ui/core";
import Widget from '../../../components/Widget';
import { Button, Typography,Avatar } from "../../../components/Wrappers";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import PantallaCompleta from './PantallaCompleta'
import IconDoc from '../../../images/documentoss.png';
export default function ManagerFile(props)
{

      var classes = useStyles();

  return(
    <Grid item sm={6} md={4}  lg={3}>
         <Widget
        bodyClass={classes.fullHeightBody}
        className={classes.card}
        disableWidgetMenu
        >

        <Grid container  >

            <Grid item xs={12}>
            {props.datos.nombre!== undefined && props.datos.nombre!== "" &&
              <Typography  variant="body2">
                  Nombre: {props.datos.nombre.substring(0,100)}
              </Typography>}
                <Typography  variant="body2">
                    Autor:{props.datos.usuario_name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {props.tipo === 0 &&
                  <Avatar
                    alt="Avatar"
                    src={props.avatar}
                    variant="square"
                    classes={{ root: classes.imagenes }}
                />}
                {props.tipo === 1 &&
                  <Avatar
                    alt="Avatar"
                    src={IconDoc}
                    variant="square"
                    classes={{ root: classes.imagenes }}
                />}
                {props.tipo === 2 &&
                  <audio controls>
                    <source src={props.avatar} />
                        Tu navegador no soporta audio HTML5.
                  </audio>
                }
                {props.tipo === 3 &&
                  <video style={{maxWidth:150}} controls>
                    <source src={props.avatar} />
                  </video>
                }


            </Grid>

            <Grid item xs={12}>
                <Grid container  >

                    <Grid item xs={4}>
                      <Tooltip title="Enviar Imagen">
                         <IconButton
                                onClick={ e => props.enviar({tipo:props.datos.tipo,
                                                        nombre:props.datos.nombre,
                                                        tamanio:props.datos.tamano,
                                                        propietario:'AD',
                                                        link:props.datos.link,
                                                      })

                                }
                                >
                             <SendIcon/>
                         </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={4}>
                      <PantallaCompleta url={props.avatar} tipo={props.tipo}/>
                    </Grid>

                    <Grid item xs={4}>
                            <Tooltip title="Eliminar">
                                <IconButton
                                onClick={ e => props.borrarContenido({ref:props.datos.ref,
                                                                tipo: props.tipo,
                                                                id:props.datos.key})}
                                    >
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                    </Grid>


                </Grid>
            </Grid>
        </Grid>
    </Widget>

    </Grid>

  )
}
