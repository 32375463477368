import {auth,db,fecha_hora} from "./setup";
import log_it from './../log/log';

export function watchUserChanges(callback) {

    const unsub= auth.onAuthStateChanged((user) => {


        if(user && !user.isAnonymous){

            user.getIdToken(true).then(function(idToken) {
                callback({
                    id:user.uid,
                    email:user.email,
                    displayName:user.displayName,
                    token:idToken,
                });
            }).catch(function(error) {

                callback({
                    id:user.uid,
                    email:user.email,
                    displayName:user.displayName,
                    token:null,
                });
            });

     /*      callback({
                id:user.uid,
                email:user.email,
                displayName:user.displayName,
                token:null,
            });*/

        }else{

            callback(null);
        }

    });

    return unsub;
}

export function watchDatosUSer(uid,callback) {

/*  var connectedRef = db.ref(".info/connected");
  connectedRef.on("value", function(snap) {
    if (snap.val() === true) {
      alert("connected");
    } else {
      alert("not connected");
    }
  });*/

    const ultima_conexion=db.ref('usuarios/'+uid+'/configuraciones/ultima_conexion');
    const conectado=db.ref('conectado/'+uid+"/en_linea");



    conectado.once('value').then((con)=>{
        return con.val();
    }).then((conect)=>{

        const unsub = db
        .ref('usuarios/'+uid)
        .on('value',snapshot => {

            var datos=snapshot.val();
            if(conect)
            {
                var datos_no={
                    admin:false,
                    en_linea:true,
                    permisos:{ paginas:['otra_sesion']}

                         }
                callback(datos_no);
           }else{

            conectado.onDisconnect().set(false);
            ultima_conexion.onDisconnect().set(fecha_hora.database.ServerValue.TIMESTAMP);
            /*abandonar sesion*/
            let id_firebase =  db.ref("/log_usuarios/"+datos.id_empresa+"/"+uid+"/").push({
                fecha: fecha_hora.database.ServerValue.TIMESTAMP,
                nombre: "abrir sesión",
                id_accion: 3,
                fecha_fin:"",
                id_accion_fin: "",
                nombre_fin: ""
            }).key;
            db.ref("/log_usuarios/"+datos.id_empresa+"/"+uid+"/"+id_firebase).onDisconnect().update({
              fecha_fin:fecha_hora.database.ServerValue.TIMESTAMP,
              id_accion_fin: 4,
              nombre_fin: "abandonar sesión"
            });

                conectado.set(true);
                localStorage.setItem("theme",datos.configuraciones.tema);
                localStorage.setItem("userData",JSON.stringify(datos));
                localStorage.setItem("id_firebase",id_firebase);


                db.ref('roles/'+datos.rol).on('value',snapshot => {
                    let permisos=snapshot.val();
                    datos={...datos,permisos:permisos};

                    callback(datos);
                });
            }


           // console.log("fecha",new Date(datos.configuraciones.ultima_conexion).toLocaleString('es-MX'));

        });

    });

/*
    const unsub = db
        .ref('usuarios/'+uid)
        .on('value',snapshot => {


            var datos=snapshot.val();
            console.log("en linea",datos.configuraciones.en_linea);
            console.log("datos usuarios",datos);



            if(datos.configuraciones.en_linea>1)
            {
                console.log("entro 1");
                var datos_no={
                    admin:false,
                    en_linea:true,
                    permisos:{ paginas:['otra_sesion']}

                         }
                callback(datos_no);

           }else{

            conectado.onDisconnect().set(0);
            ultima_conexion.onDisconnect().set(fecha_hora.database.ServerValue.TIMESTAMP);
            console.log("entro 2",datos.configuraciones.en_linea);
                conectado.set(datos.configuraciones.en_linea+1);
                localStorage.setItem("theme",datos.configuraciones.tema);
                localStorage.setItem("userData",JSON.stringify(datos));

                db.ref('roles/'+datos.rol).on('value',snapshot => {
                    let permisos=snapshot.val();
                    datos={...datos,permisos:permisos};
                   // console.log(datos);
                    callback(datos);

                });
            }


           // console.log("fecha",new Date(datos.configuraciones.ultima_conexion).toLocaleString('es-MX'));




        });*/

    return watchDatosUSer;
}

/*
export function watchDatosUSer(uid,callback) {

    const unsub = db
        .collection('usuarios')
        .onSnapshot(snapshot => {
            const docs=[];

            snapshot.forEach((doc) =>{
                const data = doc.data();

                docs.push({
                    ...data,
                    id:doc.id,
                });
            });
            callback(docs);
        });

    return watchDatosUSer;
}
*/
