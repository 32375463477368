import React, { useState ,useEffect,Component} from 'react';
import {ListItemText,Avatar,List,ListItem,ListItemAvatar,TextField ,IconButton, makeStyles} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../styles';
import SendIcon from '@material-ui/icons/Send';
import {db,fecha_hora} from "../../../services/firebase";
import {mensajes_chat} from "../../../services/automata"
import { useUserState} from "../../../context/UserContext";
import {useChatState,useChatDispatch,AtenderUsuario,EnviarMensaje} from '../context/ChatContext';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Adjuntos from './Adjuntos'
import LikeHeart from './LikeHeart';
import log_it from './../../../services/log/log'

const ex_imagen=['jpg','jpeg','png'];
const ex_doc=['txt','doc','docx','pptx','ppt','xlsx','xls','odt','pdf'];
const ex_video=['mp4'];
const ex_audio=['mp3'];

function InputMensaje(props){
    var classes = useStyles();
    var { datos ,user} = useUserState();
    var chatDispach=useChatDispatch();
    var {bot_actual,usuario_actual,atender_usuario,datos_bot,datos_chat} = useChatState();

    const[enviando,setEnviando]=React.useState(false);
    const[mensajeEnviar,setMensajeEnviar]=React.useState("");
    const [error,setError]=React.useState(false);

    const[pressedAlt,setPressedAlt]=React.useState(false);
    const[posOfWasPressedEnter,setPosOfWasPressedEnter]=React.useState(0);
    const[chatcenterInputTextAreaStyle,setChatcenterInputTextAreaStyle]=React.useState({});

    const[caretOn,setCaretOn]=React.useState(false);

    useEffect(() =>{
      setError(false);
      setMensajeEnviar("");
    },[usuario_actual]);



    const enviarMensaje=()=>
    {
      let mensajeEnviar_sin=mensajeEnviar.trim();
      let tipo=isUrl(mensajeEnviar_sin);

        if(tipo.url)
        {

          EnviarMensaje(chatDispach,
            mensajeEnviar_sin,
            tipo.tipo, //tipo
            tipo.nombre, //nombre
            '', //tamano
            'Otro', //propietario
            usuario_actual,
            mensajes_chat,
            user,
            datos,
            setError,
            setEnviando,
            ReiniciarVariables,
          );
        }else{

          EnviarMensaje(chatDispach,
            mensajeEnviar_sin,
            'text', //tipo
            '', //nombre
            '', //tamano
            '', //propietario
            usuario_actual,
            mensajes_chat,
            user,
            datos,
            setError,
            setEnviando,
            ReiniciarVariables,
          );
        }

        // props.setDisabled_finalizarAtencion(false)

    }

    const ReiniciarVariables=()=>{
      setMensajeEnviar("");
    }



    const enviarEnter=(e)=>{
      if(e.keyCode === 18){
        e.preventDefault();
        setPressedAlt(true)
      }else if(e.keyCode === 16){
        e.preventDefault();
        setPressedAlt(true)
      }else if(e.keyCode === 13){
        e.preventDefault();
        if(pressedAlt){

          // let posOfWasPressedEnter= (e.target.selectionStart)
          var ss = (e.target.selectionStart)
          var msj = mensajeEnviar

          var msjParte1 = msj.substr(0,ss)
          var msjParte2 = msj.substr(ss,msj.length)
          setMensajeEnviar(msjParte1+"\n"+msjParte2)
          if(e.target.createTextRange){

            var range = e.target.createTextRange()
            range.move('character',ss)
            range.select()
          }else{
            if(e.target.selectionStart){

              e.target.focus()
              e.target.setSelectionRange(ss,ss)
              setPosOfWasPressedEnter(ss)
              setCaretOn(false)

          }else{
              e.target.focus()
          }
        }
          // console.log("A4")
          // e.target.selectionEnd = posOfWasPressedEnter+1
       }else{
        enviarMensaje()

       }
      }
      // props.setDisabled_finalizarAtencion(false)

    }

    const useStylesCaretOff = makeStyles((theme)=>({
      root:{
        color:'transparent',
        textShadow:'0px 0px 0px 0px black'
      }
    }))
    const useStylesCaretOn = makeStyles((theme)=>({
      root:{
        color:'black'
      }
    }))

    const soltarTeclas = (e) => {
      if(e.keyCode === 18){
        e.preventDefault();
        setPressedAlt(false)
      }else if(e.keyCode === 16){
        e.preventDefault();

        setPressedAlt(false)


      }else if(e.keyCode === 13){
        e.preventDefault();
        var ss = posOfWasPressedEnter+1
        e.target.setSelectionRange(ss,ss)
        setCaretOn(true)
      }
    }



    const classesOn = useStylesCaretOn()
    const classesOff = useStylesCaretOff()

return (

  <React.Fragment>

    {error && <Alert severity="error">Error al enviar el mensaje</Alert> }
    <TextField fullWidth multiline

        InputProps={caretOn?{classesOn}:{classesOff}}
        disabled={datos_bot!=="" && usuario_actual!=="" && enviando ===false?false:true}
        value={mensajeEnviar}
        className={classes.margin}
        onChange={e => {setMensajeEnviar(e.target.value);}}
        onKeyDown={enviarEnter}
        onKeyUp={soltarTeclas}
        rowsMax={5}

        id="input-with-icon-textfield" InputProps={{
          endAdornment:(
                <InputAdornment position="end">
                  <React.Fragment>
                    <IconButton
                      aria-label="send message"
                      edge="end"
                      onClick={enviarMensaje}
                      disabled={ datos_bot!=="" && usuario_actual!=="" && enviando ===false ?false:true}
                      >
                     {enviando ===false && <SendIcon/>}
                     { enviando && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </IconButton>
                    <LikeHeart/>
                    <Adjuntos/>
                  </React.Fragment>
                </InputAdornment>
              ),}}/>
  </React.Fragment>

    );
}


function isUrl(s) {
    var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/

    if(regexp.test(s)){
      let url=regexp.exec(s);
      if(url===null)      {

        return {url:false,tipo:'text',nombre:""};
      }


      if(url[0].length===s.length)
      {

        return ObtenerExtension(s);

      }else{

        return {url:false,tipo:'text',nombre:""};
      }


    }

    return {url:false,tipo:'text',nombre:""};
}

function ObtenerExtension(s){
  let arreglo = s.split("?");
  let indice= arreglo[0].lastIndexOf(".");

  if(indice===-1){
    return  {url:false,tipo:'text',nombre:""};//archivo
  }

  let nombre= arreglo[0].split("/");
  let nombre_par=nombre[nombre.length-1].split('%2F');

  if(nombre_par.length>0)
  {
    nombre=nombre_par;
  }
  let tipo= arreglo[0].substring(indice+1,arreglo[0].length);

  ///comprobar si es una imagenes
  let isimagen =ex_imagen.indexOf(tipo);
  if(isimagen!==-1)
  {
      return  {url:true,tipo:'image',nombre:""};//imagen
  }

  let isdoc=ex_doc.indexOf(tipo);
  if(isdoc!==-1)
  {
      return  {url:true,tipo:'file',nombre:""};//archivo
  }

  let isvideo=ex_video.indexOf(tipo);
  if(isvideo!==-1)
  {
      return  {url:true,tipo:'video',nombre:""};//video
  }

  let isaudio=ex_audio.indexOf(tipo);
  if(isaudio!==-1)
  {
      return  {url:true,tipo:'audio',nombre:""};//audio
  }

  return  {url:false,tipo:'text',nombre:""};//texto



}

export default InputMensaje;
