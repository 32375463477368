import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import {Grid} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';

import IconButton from '@material-ui/core/IconButton';

import LanguageIcon from '@material-ui/icons/Language';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
  }),
);


export default function LlaveWeb(props){

    const classes = useStyles();


    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);


    /*variables de datos*/
    const [web,setWeb]=React.useState('');
    const [wordpress,setWordpress]=React.useState("")




    /*inicializar vaiables*/
      useEffect(() =>{

          setWeb('<script src="https://webchat.automatadg.com/automataChatV2.js"></script> \n'+
         '<script>\n'+
          'var chatWebAutomataDgv2 = new chatAutomata();\n'+
          'chatWebAutomataDgv2.empresa="'+props.empresa+'",'+
          'chatWebAutomataDgv2.bot="'+props.id+'",\n'+
          'chatWebAutomataDgv2.avatar="https://i.imgur.com/4Vd0kJa.png"\n'+
          'chatWebAutomataDgv2.name="'+props.nombre+'"\n'+
          'chatWebAutomataDgv2.render();\n'+
         '</script>');

         setWordpress(
          'Id empresa = '+props.empresa+'\n' +
          'Id bot = '+props.id+'\n'+
          'URl avatar= "https://i.imgur.com/4Vd0kJa.png"\n'+
          'Nombre del bot= '+props.nombre
         );

        return ()=>{

        }

      },[open]);



    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };




    return(
        <React.Fragment>
          <Typography onClick={handleClickOpen}>Conexión</Typography>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                  <DialogTitle id="form-dialog-title">Conexión Web<LanguageIcon/></DialogTitle>
                <DialogContent dividers>


                <Grid spacing={3} container >

                <Grid item xs={12}>
                  <Typography >Para agregar el chat a tu pagina web copia y pega el codigo Web antes del cierre de la etiqueta body de tu pagina web</Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField id="ElTexto_mensaje"
                               value={web}
                               multiline
                               label="Web"
                               variant="outlined"
                               fullWidth
                               disabled={true}
                               />
                </Grid>
                <Grid item xs={12}>
                  <Typography >Para agregar el chat a tu pagina wordpress, instala el plugin y luego configura los siguientes parametros</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon/>}
                      href={"https://webchat.automatadg.com/avi-wp.zip"}
                      target="_blank"
                    >
                      Pluging Wordpress
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TextField id="ElTexto_mensaje"
                               value={wordpress}
                               label="Wordpress"
                               variant="outlined"
                               fullWidth
                               disabled={true}
                               multiline
                               />
                </Grid>


                </Grid>
                </DialogContent>
                 <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrrar
                    </Button>
                </DialogActions>

            </Dialog>

        </React.Fragment>
       );

}
