import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from "../../../../components/Wrappers";
import AddBoxIcon from '@material-ui/icons/AddBox';
import PantillaBotonBotones from "./platillaBoton_botones"
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import DeleteElementos from '../DeleteElementos';
import {useSnackbar } from 'notistack';
import { useUserState} from "../../../../context/UserContext";
import {db} from "../../../../services/firebase";
import {
    useAdminBotState,
} from '../../contexto/AdminBotContext';
import DialogElement from './dialogElement';

import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites
  ,mensajeTextoContenidoErrores
} from './validaciones'

import {
  maximaCantidadBotonesEnModulo,
  maximaCantidadCaracteresEnBotones,
  validarTextoBotonesContenidoEscrito,
  mensajeTextoBotonErrores,
  validarTextoMensajeErrorEscrito,
  mensajeTextoErrorErrores
} from './validaciones'

import log_it from './../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    }
  }),
);


export function PlantillaBoton(props){

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
      var {bot_actual,bloques} =useAdminBotState();
      var { datos,user } = useUserState();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);
    const [arregloBotonoes,setArregloBotones]= React.useState([]);
    const [items,setItems]=React.useState([]);
    const [errorBoton,setErrorBoton]=React.useState(0);

    /*variables de datos*/
    const [mensaje,setMensaje]=React.useState("")
    const [intentos,setIntentos]=React.useState("0");
    const [mensajeError,setMensajeError]=React.useState("");
    const [atributos,setAtributos]= React.useState("");
    const [listaButtons,setListaButtons] = React.useState("");

    const [enviar_a,setEnviar_a]=React.useState("");

    /*nuevo boton*/
    const [tipo,setTipo] = React.useState(10);
    const [enviar,setEnviar]=React.useState("0");
    const [nuevoNombre,setNuevoNombre]= React.useState("");
    const [errorNuevoNombre,setErrorNuenoNombre]=React.useState(false);
    const [nuevaUrl,setNuevaUrl]=React.useState("");
    const [errorNuevaUrl,setErrorNuevaUrl] = React.useState(false);

    const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);
    const [mensajeErrorCodigoValidacion,setMensajeErrorCodigoValidacion]=React.useState(-1);
    const [mensajeCodigoValidacionBoton,setMensajeCodigoValidacionBoton]=React.useState(-1);


    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);

    const cambiarTipo=(event)=>{
        if(event.target.value!==undefined)
        {
            setTipo(event.target.value);
        }

    }

    const cambiarEnviar=(event)=>{
        if(event.target.value!==undefined)
        {
            setEnviar(event.target.value);
        }

    }
    const cambiarEnviarA=(event)=>{
      if(event.target.value!==undefined)
      {
          setEnviar_a(event.target.value);
      }
    }

    const AgregarBoton=()=>{
        var nombreTrim = nuevoNombre.trim()


        nombreTrim.length===0? setErrorNuenoNombre(true): setErrorNuenoNombre(false);
        nuevaUrl.length===0 && tipo===20 ? setErrorNuevaUrl(true): setErrorNuevaUrl(false);

        if( !(nombreTrim.length===0 || (nuevaUrl.length===0 && tipo===20)) )
        {

            setArregloBotones((prevState) => {

                //value 10 bloque
                if(tipo===10)
                {
                  prevState.push({
                    enviar_a:enviar==="0"?"":enviar,
                    payload:"boton",
                    title:nombreTrim,
                    type:"postback"
                  });
                }else{
                  prevState.push({
                    url:nuevaUrl,
                    title:nombreTrim,
                    type:"web_url"
                  });
                }
                //actualizarBotonoes(prevState);
                let lista=[]
                prevState.forEach((element, index, array) =>{
                    lista.push(
                    <PantillaBotonBotones key={"replies_"+index}
                        id={index}
                        editar={setArregloBotones}
                        eliminar={EliminarBoton}
                        enviar_a={element.enviar_a}
                        type={element.type}
                        title={element.title}
                        url={element.url}
                    />
                    );

                });
                setListaButtons(lista);

                setNuevoNombre("");
                setNuevaUrl("");
                setIntentos("0");
                enqueueSnackbar('Agregado correctamente', {variant: 'success'});
              return prevState;
            });

        }


    }
/*--------------------------------------------------------------------------*/
    /*inicializar vaiables*/
    function actualizarBotonoes(arreglo){

      let lista=[]
      arreglo.forEach((element, index, array) =>{
          lista.push(
          <PantillaBotonBotones key={"replies_"+index}
              id={index}
              editar={setArregloBotones}
              eliminar={EliminarBoton}
              enviar_a={element.enviar_a}
              type={element.type}
              title={element.title}
              url={element.url}
          />
          );

      });
      setListaButtons(lista);

    }


    useEffect(() =>{

        if(props.datos)
        {
            setMensaje(props.datos.mensaje);
            setIntentos(props.datos.intento);
            setMensajeError(props.datos.mensaje_error);
            setAtributos(props.datos.atributo);
            actualizarBotonoes(props.datos.buttons);
            setEnviar_a((props.datos.enviar_a===""||props.datos.enviar_a===undefined)?"0":props.datos.enviar_a)

            let lista=[]
            props.datos.buttons.forEach((element, index, array) =>{

              //value 10 bloque
              if(element.type==="postback")
              {
                  lista.push({
                  enviar_a:element.enviar_a,
                  payload:element.payload,
                  title:element.title,
                  type:element.type
                });
              }else{
                lista.push({
                  url:element.url,
                  title:element.title,
                  type:element.type
                });
              }

            });
            setArregloBotones(lista);
        }

    },[open]);


    useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
      }
    },[bloques]);

/*------------------------------Eliminar boton------------------------------------*/
    const EliminarBoton =(datos)=>{

      setArregloBotones((prevState) => {

        prevState.splice(datos.id,1);
        actualizarBotonoes(prevState);
        enqueueSnackbar('Eliminado correctamente', {variant: 'success'});
        return prevState;
      });

    }
/*---------------------------------Guardar-------------------------------------*/

const Guardar=()=>{
  var temp_enviar_a = ""
  var temp_intentos = 0

  temp_intentos = parseInt(intentos)
  if(isNaN(temp_intentos)){
    temp_intentos=0
  }

  if(enviar_a!="0"){
    temp_enviar_a=enviar_a
  }

  if(arregloBotonoes<=0){
        enqueueSnackbar('Debe de exisite almenos un boton', {variant: 'error'});
  }else{

    setCargando(true);

    if(props.id_elemento===-1){
      setCargando(false);
      setOpen(false);
      enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
      props.GuardarNuevoElemento({
            atributo:atributos,
            elemento:"texto_boton",
            intento:temp_intentos,
            enviar_a:temp_enviar_a,
            mensaje:mensaje,
            mensaje_error:mensajeError,
            buttons:arregloBotonoes
      },props.new_index);
    }else{
        db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
        .set({
              atributo:atributos,
              elemento:"texto_boton",
              intento:temp_intentos,
              enviar_a:temp_enviar_a,
              mensaje:mensaje,
              mensaje_error:mensajeError,
              buttons:arregloBotonoes

        },function(error) {
            if (error) {
                setCargando(false);
                enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
            } else {
                setCargando(false);
                enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                setOpen(false);
                var obj = {
                  fecha: + new Date(),
                  nombre: "modificar elemento",
                  id_accion: 132,
                  id_bot: bot_actual.id,
                  red_social: bot_actual.conexion,
                  bloque:props.id_bloque,
                  elemento:"texto_boton",
                }
                log_it(datos.id_empresa,user.id,obj)
            }
        });
    }

  }
}
/*-------------------------Abrir y cerrrar modal-----------------------*/
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };

/*-----------------------------------------------------------------------*/
    return(
        <React.Fragment>
          <DialogElement
            id_elemento={props.id_elemento}
            moverElementos={props.moverElementos}
            moverMasuno={props.MoverMasuno}
            moverMenosuno={props.MoverMenosuno}
            icono={<ListAltIcon/>}
            metodoAbrir={handleClickOpen}
            nombre={props.nombre}
            tamanio={props.tamanio}
            dialogOpen={open}
            dialogClose={handleClose}
            cargando={cargando}
            disabledSave={   mensaje.length === 0 ||  intentos<0 ||  errorBoton>0  || (atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos)))}
            guardar={Guardar}
            eliminar={props.eliminar}
            cerrar={setOpen}
          >


                    <Grid item xs={12}>
                        <TextField
                          id="ElTexto_mensaje"
                          value={mensaje}
                          // onChange={e => setMensaje(e.target.value)}
                          onChange={
                            (e)=>{
                              /*Para hacerlo mas legible*/
                              var codigoValidacion = validarTextoMensajeContenidoEscrito(e,e.target.value,bot_actual.conexion)
                              setMensajeCodigoValidacion(codigoValidacion)
                              if(codigoValidacion<1){
                                setMensaje(e.target.value)
                              }
                            }
                          }
                          inputProps={{
                            maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                          }}

                          label="Mensaje"
                          variant="outlined"
                          fullWidth
                          multiline
                          // error={mensaje.length===0}
                          // helperText={mensaje.length===0?"El campo es obligatorio":""}
                          error={ mensajeCodigoValidacion != -1}
                          helperText={mensajeTextoContenidoErrores(mensajeCodigoValidacion,bot_actual.conexion)}
                          disabled={datos.permisos.abcbot.bloques!==true}
                          />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <TextField
                          id="outlined-basic"
                          value={intentos}
                          onChange={e => setIntentos(e.target.value)}
                          label="Intentos"
                          type={"number"}
                          variant="outlined"
                          fullWidth
                          error={intentos<0 }
                          helperText={intentos<0?"El valor debe ser positivo":""}
                          disabled={datos.permisos.abcbot.bloques!==true}
                          />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                     <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                        <InputLabel id="select-enviar_a">Enviar a</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined-quil"
                            value={enviar_a}
                            onClick={cambiarEnviarA}
                            label="Enviar"
                        >
                            <MenuItem value="0">
                                <em>None</em>
                            </MenuItem>
                            {items}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid spacing={2} container >
                            <Grid item xs={12} sm={6}>
                                <TextField id="outlined-basic"
                                  value={mensajeError}
                                  // onChange={e => setMensajeError(e.target.value)}
                                  onChange={
                                    (e)=>{
                                      /*Para hacerlo mas legible*/
                                      var codigoValidacion = validarTextoMensajeErrorEscrito(e,e.target.value,bot_actual.conexion, null)
                                      setMensajeErrorCodigoValidacion(codigoValidacion)
                                      if(mensajeErrorCodigoValidacion<1){
                                        setMensajeError(e.target.value)
                                      }
                                    }
                                  }
                                  label="Mensaje de error"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  disabled={datos.permisos.abcbot.bloques!==true}
                                  inputProps={{
                                    maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                   }}
                                   error={
                                    (mensajeErrorCodigoValidacion!=-1 )
                                    }
                                   helperText={
                                     mensajeTextoErrorErrores(mensajeErrorCodigoValidacion,bot_actual.conexion)
                                  }
                                   />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                  id="outlined-basic"
                                  value={atributos}
                                  onChange={e => setAtributos(e.target.value)}
                                  label="Atributos"
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  disabled={datos.permisos.abcbot.bloques!==true}
                                  error={atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos))}
                                  helperText={ atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos)) &&
                                  "El atributo solo pude contener numeros, letras y guiones"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography>Botones</Typography>
                    </Grid>

                    {/*Nuevo item*/}
                {datos.permisos.abcbot.bloques===true &&
                    <Grid item xs={12} >
                        <Grid spacing={2} container >

                            <Grid item xs={12} sm={2}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined-quil"
                                    value={tipo}
                                    onClick={cambiarTipo}
                                    label="Tipo"
                                >
                                    <MenuItem value={10}>Bloque</MenuItem>
                                    <MenuItem value={20}>URL</MenuItem>
                                </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                  value={nuevoNombre}
                                  label="Nombre"
                                  variant="outlined"
                                  // onChange={e => setNuevoNombre(e.target.value)}
                                  onChange={
                                    e=>{
                                      /*Para hacerlo mas legible*/
                                      var codigoValidacion = validarTextoBotonesContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                      setMensajeCodigoValidacionBoton(codigoValidacion)
                                      if(codigoValidacion<1){
                                        setNuevoNombre(e.target.value)
                                      }
                                    }
                                  }
                                  error={ (mensajeCodigoValidacionBoton != -1) || errorNuevaUrl}
                                  helperText={mensajeTextoBotonErrores(mensajeCodigoValidacionBoton)}

                                  inputProps={{
                                    maxLength:maximaCantidadCaracteresEnBotones
                                  }}
                                  // error={errorNuevoNombre}
                                  // helperText={errorNuevoNombre?"El campo es obligatorio":""}
                                  fullWidth />
                            </Grid>


                            {tipo===10 ?
                               <Grid item xs={12} sm={4}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                      <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                                      <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined-quil"
                                          value={enviar}
                                          onClick={cambiarEnviar}
                                          label="Enviar"
                                      >
                                          <MenuItem value="0">
                                              <em>None</em>
                                          </MenuItem>
                                          {items}
                                      </Select>
                                  </FormControl>
                              </Grid>
                              :
                              <Grid item xs={12} sm={4}>
                                  <TextField
                                    value={nuevaUrl}
                                    label="URL"
                                    variant="outlined"
                                    onChange={e => setNuevaUrl(e.target.value)}


                                    error={errorNuevaUrl}
                                    helperText={errorNuevaUrl?"El campo es obligatorio":""}



                                    fullWidth />
                              </Grid>
                            }

                            <Grid item xs={12} sm={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<AddBoxIcon/>}
                                onClick={AgregarBoton}
                                disabled={
                                  !(listaButtons.length<=(maximaCantidadBotonesEnModulo-1))
                                }

                            >
                                Agregar
                            </Button>
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid item xs={12}>
                        <Divider variant="middle" className={classes.colorDivider}/>
                    </Grid>

                    {listaButtons}

              </DialogElement>
        </React.Fragment>
       );

}
