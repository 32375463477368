import React, { useEffect } from 'react';

var AdminBotStateContext =React.createContext();
var AdminBotDispatchContext = React.createContext();

function adminBotReducer(state,action)
{
    switch(action.type)
    {
        case "BOT_ACTUAL":
            return {...state,
                bot_actual:action.bot_actual,
            }

        case "BLOQUES":
            return {...state,
                bloques:action.bloques,
            }

        case "ASESORES":
          return {...state,
              asesores:action.asesores,
          }

        case "ATRIBUTOS":
          return {...state,
              atributos:action.atributos,
          }

        case "LISTA_BLOQUES":
          if(state.bloque_actual === "")
          {
            return {...state,
                lista_bloques:action.lista_bloques,
            }
          }else{
            let bloqueActual = action.lista_bloques.find(element => element.id === state.bloque_actual.nombre);
            return {...state,
                    lista_bloques:action.lista_bloques,
                    bloque_actual:bloqueActual=== undefined? "":{
                      nombre:bloqueActual.id,
                      padre:bloqueActual.info.padre,
                      elementos:bloqueActual.info.elementos===undefined?[]:bloqueActual.info.elementos,
                    }
                  }
          }

        case "BLOQUE_ACTUAL":
          return {...state,
              bloque_actual:action.bloque_actual,
          }

        case "REINICARVAR":
          return {...state,
            bot_actual:null,
            bloques:null,
            asesores:[],
            atributos:[],
            lista_bloques:[],
            bloque_actual:"",
          }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
            }
    }
}


function AdminBotProvider({ children })
{
    var [state,dispatch]= React.useReducer( adminBotReducer,{
        bot_actual:null,
        bloques:null,
        asesores:[],
        atributos:[],
        lista_bloques:[],
        bloque_actual:"",
    });

    return (
        <AdminBotStateContext.Provider value={state}>
            <AdminBotDispatchContext.Provider value={dispatch}>
                {children}
            </AdminBotDispatchContext.Provider>
        </AdminBotStateContext.Provider>

    );
}

function useAdminBotState(){
    var context = React.useContext(AdminBotStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}

function useAdminBotDispatch(){
    var context = React.useContext(AdminBotDispatchContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}

export {AdminBotProvider,
        useAdminBotState,
        useAdminBotDispatch,
        botActual,
        Bloques,
        Asesores,
        Atributos,
        ListaBloques,
        BloqueActual,
        ReiniciarVariables
       }

const botActual =(dispatch,bots) => {
    dispatch({
        type: "BOT_ACTUAL",bot_actual:bots
    });
}

const Bloques =(dispatch,bloques) => {

    dispatch({
        type: "BLOQUES",bloques:bloques
    });
}

const Asesores =(dispatch,asesores) => {
    dispatch({
        type: "ASESORES",asesores:asesores
    });
}

const Atributos= (dispatch,bloques)=>{

  let lista_atrib=[];
  bloques.forEach((item, i) => {
    if(item!==undefined)
    {
      item.forEach((element, j) => {
        if(element.atributo!== undefined && element.atributo!== "" )
        {
            lista_atrib.push(element.atributo);
        }
      });
    }
  });
  dispatch({
      type: "ATRIBUTOS",atributos:lista_atrib
  });

}

const ListaBloques =(dispatch,lista_bloques) => {
    dispatch({
        type: "LISTA_BLOQUES",lista_bloques:lista_bloques
    });
}


const BloqueActual =(dispatch,bloque_actual) => {
    dispatch({
        type: "BLOQUE_ACTUAL",bloque_actual:bloque_actual
    });
}

const ReiniciarVariables = (dispatch) => {
    dispatch({
        type: "REINICARVAR"
    });
}
