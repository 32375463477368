import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LanguageIcon from '@material-ui/icons/Language';

import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteElementos from '../DeleteElementos';
import DialogElement from './dialogElement';

import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites,
  mensajeTextoContenidoErrores,

  validarTextoBotonesContenidoEscrito,
  maximaCantidadCaracteresEnBotones,
  mensajeTextoBotonErrores,

  validarTextoMensajeErrorEscrito,
  mensajeTextoErrorErrores
} from './validaciones'

import log_it from './../../../../services/log/log'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    }
  }),
);


export function WebView(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);
    const [items,setItems]=React.useState([]);

    /*variables de datos*/
    const [mensaje,setMensaje]=React.useState("")
    const [enviara,setEnviar] = React.useState("0");
    const [boton,setBoton]= React.useState("");
    const [url,setUrl]= React.useState("");
    const [mostrarUrl,setMostrarUrl] = React.useState("");
    const [mensajeError,setMensajeError] = React.useState("");
    const [intentos,setIntentos]=React.useState(0);

    const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);
    const [mensajeCodigoValidacionBoton,setMensajeCodigoValidacionBoton]=React.useState(-1);
    const [mensajeErrorCodigoValidacion,setMensajeErrorCodigoValidacion]=React.useState(2);


      useEffect(() =>{
        if(bloques!==null)
        {
            let lista_items=[]
            bloques.forEach((element, index, array) => {
                lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
            });
            setItems(lista_items);

        }
      },[bloques]);

      useEffect(() =>{
        /*Guardar nuevo elemento*/
        if(props.abrir)
        {
          setOpen(true);
        }
      },[]);

    /*inicializar vaiables*/
    useEffect(() =>{


        if(props.datos)
        {
            setBoton(props.datos.boton);
            setEnviar((props.datos.enviar_a===""||props.datos.enviar_a===undefined)?"0":props.datos.enviar_a)
            setIntentos(props.datos.intento);
            setMensaje(props.datos.mensaje);
            setMensajeError(props.datos.mensaje_error);
            setMostrarUrl(props.datos.mostrar_url);
            setUrl(props.datos.url)

        }

      },[open]);



    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);

    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
      var botonTrim = boton.trim()
      var temp_intentos = 0
      temp_intentos = parseInt(intentos)
      if(isNaN(temp_intentos)){
        temp_intentos=0
      }
      setBoton(botonTrim)
        setCargando(true);
        /*Guardar cuando es un nuevo elemento*/
        if(props.id_elemento===-1){
          setCargando(false);
          setOpen(false);
          enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});        
          props.GuardarNuevoElemento({
              boton:botonTrim,
              elemento:"webview_showpage",
              enviar_a:(enviara==="0"?"":enviara),
              intento:temp_intentos,
              mensaje:mensaje,
              mensaje_error:mensajeError,
              mostrar_url:mostrarUrl,
              url:url
          },props.new_index);

        }else{
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
            .update({
                boton:botonTrim,
                elemento:"webview_showpage",
                enviar_a:(enviara==="0"?"":enviara),
                intento:temp_intentos,
                mensaje:mensaje,
                mensaje_error:mensajeError,
                mostrar_url:mostrarUrl,
                url:url
            },function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                    setCargando(false);
                    enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                    setOpen(false);
                    var obj = {
                      fecha: + new Date(),
                      nombre: "modificar elemento",
                      id_accion: 132,
                      id_bot: bot_actual.id,
                      red_social: bot_actual.conexion,
                      bloque:props.id_bloque,
                      elemento:"webview_showpage",
                    }
                    log_it(datos.id_empresa,user.id,obj)
                }
            });

        }
    }

    /*Borrar elemento */
    const EliminarElemento=()=>{
        setOpen(false);
        props.eliminar(props.id_elemento);
    }

    return(
        <React.Fragment>

            <DialogElement
              id_elemento={props.id_elemento}
              moverElementos={props.moverElementos}
              moverMasuno={props.MoverMasuno}
              moverMenosuno={props.MoverMenosuno}
              icono={<AssignmentRoundedIcon/>}
              metodoAbrir={handleClickOpen}
              nombre={props.nombre}
              tamanio={props.tamanio}
              dialogOpen={open}
              dialogClose={handleClose}
              cargando={cargando}
              disabledSave={  mensaje.length === 0 || mensajeError.length===0  || intentos<0 ||
                boton.length === 0 ||  url.length === 0 ||  mostrarUrl.length === 0 ||  mensajeError.length === 0
              }
              guardar={GuardarCambios}
              eliminar={props.eliminar}
              cerrar={setOpen}
            >

                    <Grid item xs={12}>
                        <TextField id="ElTexto_mensaje"
                                   value={mensaje}
                                  //  onChange={e => setMensaje(e.target.value)}
                                  onChange={
                                    (e)=>{
                                      /*Para hacerlo mas legible*/
                                      var codigoValidacion = validarTextoMensajeContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                      setMensajeCodigoValidacion(codigoValidacion)
                                      if(codigoValidacion<1){
                                        setMensaje(e.target.value)
                                      }
                                    }
                                  }
                                  inputProps={{
                                    maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                  }}
                                   label="Mensaje"
                                   variant="outlined"
                                   fullWidth
                                   multiline
                                   error={ mensajeCodigoValidacion != -1}
                                   helperText={mensajeTextoContenidoErrores(mensajeCodigoValidacion,bot_actual.conexion)}

                                  //  error={ mensaje.length === 0}
                                  //  helperText={mensaje.length === 0?"El campo es obligatorio":""}
                                   disabled={datos.permisos.abcbot.bloques!==true}
                                   />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid spacing={2} container >

                          <Grid item xs={12} sm={6}>
                              <TextField id="ElTexto_mensajeBoton"
                                 label="Boton"
                                 variant="outlined"
                                 fullWidth
                                 multiline
                                 value={boton}
                                //  onChange={e=>setBoton(e.target.value)}
                                onChange={
                                  e=>{
                                    /*Para hacerlo mas legible*/
                                    var codigoValidacionBoton = validarTextoBotonesContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                    setMensajeCodigoValidacionBoton(codigoValidacionBoton)
                                    if(codigoValidacionBoton<1){
                                      setBoton(e.target.value)
                                    }
                                  }
                                }
                                //  error={boton.length===0 || boton.length>20}
                                //  helperText={boton.length===0?"Campo obligatorio":
                                //       boton.length>20?"El texto no puede ser mayor a 20 caracteres":
                                //       ""
                                // }
                                error={ (mensajeCodigoValidacionBoton != -1)}
                                helperText={mensajeTextoBotonErrores(mensajeCodigoValidacionBoton)}
                                inputProps={{
                                  maxLength:maximaCantidadCaracteresEnBotones
                                }}
                                disabled={datos.permisos.abcbot.bloques!==true}
                              />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                              <FormControl variant="outlined" className={classes.formControl}  disabled={datos.permisos.abcbot.bloques!==true}   >
                              <InputLabel id="demo-simple-select-outlined-label">Enviar a</InputLabel>
                              <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={enviara}
                                  onChange={e=>setEnviar(e.target.value)}
                                  label="Enviar a"
                              >
                                  <MenuItem value="0">
                                      <em>None</em>
                                  </MenuItem>
                                  {items}
                              </Select>

                              </FormControl>
                          </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="url"
                                   value={url}
                                   onChange={e => setUrl(e.target.value)}
                                   label="Url"
                                   variant="outlined"
                                   fullWidth
                                   error={ url.length === 0}
                                   helperText={url.length === 0?"El campo es obligatorio":""}
                                   disabled={datos.permisos.abcbot.bloques!==true}
                                   />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="ElTexto_mensajemostrarUrl"
                                   value={mostrarUrl}
                                   onChange={e => setMostrarUrl(e.target.value)}
                                   label="Url a mostrar"
                                   variant="outlined"
                                   fullWidth
                                   error={ mostrarUrl.length === 0}
                                   helperText={mostrarUrl.length === 0?"El campo es obligatorio":""}
                                   disabled={datos.permisos.abcbot.bloques!==true}
                                   />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField id="ElTexto_mensajemostrarUrl"
                                   value={mensajeError}
                                  //  onChange={e => setMensajeError(e.target.value)}
                                  onChange={
                                    (e)=>{
                                      /*Para hacerlo mas legible*/
                                      var ia = false
                                      var codigoValidacion = validarTextoMensajeErrorEscrito(e,e.target.value,bot_actual.conexion,ia)
                                      setMensajeErrorCodigoValidacion(codigoValidacion)
                                      if(mensajeErrorCodigoValidacion<1){
                                        setMensajeError(e.target.value)
                                      }
                                    }
                                  }
                                   label="Mensaje de Error"
                                   variant="outlined"
                                   fullWidth
                                   multiline
                                  //  error={ mensajeError.length === 0}
                                  //  helperText={mensajeError.length === 0?"El campo es obligatorio":""}
                                   disabled={datos.permisos.abcbot.bloques!==true}
                                   inputProps={{
                                    maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                   }}
                                   error={
                                    (mensajeErrorCodigoValidacion!=-1 )
                                    }
                                   helperText={
                                     mensajeTextoErrorErrores(mensajeErrorCodigoValidacion,bot_actual.conexion)
                                  }
                                   />
                    </Grid>

                    <Grid item xs={12} sm={3} >
                        <TextField id="outlined-basic" value={intentos}
                        onChange={e => setIntentos(e.target.value)}
                        label="Intentos" type={"number"}
                        variant="outlined"
                        fullWidth
                        error={intentos<0 }
                        helperText={intentos<0?"El valor debe ser positivo":"" }
                        disabled={datos.permisos.abcbot.bloques!==true}
                        />
                    </Grid>

            </DialogElement>
        </React.Fragment>
       );

}
