import React, { useState ,useEffect,Component} from 'react'
import {Typography } from '../../../components/Wrappers';
import {ListItemText,Avatar,List,ListItem,ListItemAvatar} from '@material-ui/core';
import useStyles from '../styles';
import InputMensaje from './InputMensaje';
import {useChatState,useChatDispatch,MostrarCargarChat,OcultarCargarChat} from '../context/ChatContext';
import original from '../../../images/original.png';
import ChatIcon from '@material-ui/icons/Chat';
import MenuBotones from './MenuBotones';
import {db} from '../../../services/firebase';
import { useUserState} from "../../../context/UserContext";

import {
    Grid,      
  } from "@material-ui/core";

function ChatUSer(){

    var classes = useStyles();
    var {usuario_actual,datos_bot,bot_actual} = useChatState();
    var chatDispach=useChatDispatch();
    var { datos } = useUserState();

    const[listaChat, setListaChat] = React.useState("");    
    const[limiteChat,setLimiteChat] = React.useState(10);
  
    useEffect(() =>{      
       // console.log("datos_bot",usuario_actual);
       

        ///buscar conversaciones
    let conexion=db.ref('conversaciones/'+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key).limitToLast(500)
        .on("value",(snapshot)=>{
        //  console.log("conersacion",snapshot.val());

          let conversation=[];
          MostrarCargarChat(chatDispach);
          snapshot.forEach(function(data) {

            if(data.val().tipo_usuario == "usuario"){   
              conversation.push(
                <ListItem key={data.key}>
                  <ListItemAvatar>
                    <Avatar alt={usuario_actual.user_name}                           
                    />
                  </ListItemAvatar>
                  <ListItemText id={data.key} primary={data.val().contenido} secondary={
                      <React.Fragment><small>  {data.val().fecha_hora} </small></React.Fragment>}  />
                </ListItem>
              );                   
           }


          if(data.val().tipo_usuario == "bot") {
              conversation.push(
                <ListItem key={data.key} >
                  <ListItemText id={data.key} primary={data.val().contenido} secondary={
                    <React.Fragment><small>  {data.val().fecha_hora} </small></React.Fragment>} style={{textAlign: 'right'}}/>
                  <ListItemAvatar >
                  <Avatar alt={`Bot`}
                    src={original}
                  />
                  </ListItemAvatar>
                </ListItem>
              );
          }
        
          if(data.val().tipo_usuario == "chatcenter") {
              conversation.push(
                <ListItem  key={data.key} >
                  <ListItemText id={data.key} primary={data.val().contenido} secondary={
                    <React.Fragment><small>  {data.val().fecha_hora} </small></React.Fragment>} style={{textAlign: 'right'}}/>
                  <ListItemAvatar >
                  <Avatar alt={`Chatcenter`} className={classes.purple}>
                    <ChatIcon/></Avatar>
                  </ListItemAvatar>
                </ListItem>
              );            
          }
          });
         
          setListaChat(conversation);
          OcultarCargarChat(chatDispach);
        });

        return ()=>{
          db.ref('conversaciones/'+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key).off();
        }


      },[usuario_actual]);


    useEffect(() =>{  
    document.getElementById("contenedor-lista-chat")
    .scroll({ top: document.getElementById("contenedor-lista-chat").scrollHeight, behavior: 'auto' });
     },[listaChat]);

  /*  const loadMoreItems=(event)=> {
      if(event.target.scrollTop===0)
      {
        console.log("cambiar limite");
        setLimiteChat(20);
      }
    } */

    return( 
        <Grid container spacing={3}> 
            { datos_bot!=="" && usuario_actual!==""  &&
              <ListItem className={classes.usuarioActual}>
                <ListItemAvatar>
                    <Avatar className={classes.usuarioActualAvatar} alt={usuario_actual.user_name}>{usuario_actual.user_name.charAt(0)}</Avatar>                      
                </ListItemAvatar>                   
                <ListItemText className={classes.usuarioActualTitulo} primary={usuario_actual.user_name} secondary={
                  <React.Fragment>                       
                    <Typography component="span"
                      className={classes.usuarioActualSubTitulo}>
                      {usuario_actual.key}
                    </Typography> 
                  </React.Fragment>} /> 
                </ListItem>
            } 
            <Grid container spacing={3}  alignItems="flex-end" >   
                <Grid item xs={12} md={12} >   
                  <List id={'contenedor-lista-chat'} 
                    className={classes.Chatlist}
                  //  onScroll={loadMoreItems}                    
                  >      
                     {listaChat}           
                  </List>
                </Grid>   
 
            </Grid>  
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <InputMensaje/>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.MargenTop2}>
                <Grid item xs={12} md={12}>
                    <MenuBotones/>
                </Grid>
            </Grid>
       </Grid>
    );

}

export default ChatUSer; 