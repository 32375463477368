import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';


/*
databaseURL: "https://avi1-b48f1.firebaseio.com",
databaseURL: "http://localhost:9000/?ns=avi1-b48f1",
*/


const config = {
    apiKey: "AIzaSyAqYz0bN3A-FWzXXFMZtMln4Bvj_L6kcNg",
    authDomain: "avi1-b48f1.firebaseapp.com",
    databaseURL: "https://avi1-b48f1.firebaseio.com",
    projectId: "avi1-b48f1",
    storageBucket: "avi1-b48f1.appspot.com",
    messagingSenderId: "271883633443",
    appId: "1:271883633443:web:54960ff8a626a5e9008296"

};
/*
const config = {
 apiKey: "AIzaSyCXXQmDz8c0Zo5QXScOz6Vwh1Aa3_ePC9o",
  authDomain: "avidg-fb168.firebaseapp.com",
  databaseURL: "https://avidg-fb168.firebaseio.com",
  projectId: "avidg-fb168",
  storageBucket: "avidg-fb168.appspot.com",
  messagingSenderId: "161954283601",
  appId: "1:161954283601:web:010c90733f03ddaed8f870",
  measurementId: "G-C3LCZ0KTXQ"
};
*/

firebase.initializeApp(config);

export const codigo_funte=config.authDomain;

export const funciones_fb=firebase.functions();
          //   funciones_fb.useFunctionsEmulator("http://localhost:5001");
export const auth = firebase.auth();
export const db = firebase.database();
export const authPersistencia = firebase.auth.Auth.Persistence.LOCAL;
export const storage=firebase.storage();
export const fecha_hora=firebase;

/*
db.settings({
    timestampsInSnapshots:true,
})

firebase.settings({
    timestampsInSnapshots: true
}); */
