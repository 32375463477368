
import React,{useState} from 'react';
import Widget from '../../../components/Widget';
import {Grid,CircularProgress} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TextField from '@material-ui/core/TextField';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from './styleEmpresas';
import {Typography } from "../../../components/Wrappers";
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useUserState} from "../../../context/UserContext";




import {useAdminDispatch,OcultarCrear_empresa,crear_empresa,inicializar_datos} from '../contexto/AdminContext'



export default function NuevaEmpresa() {
    
    var classes = useStyles();
    var adminDispach=useAdminDispatch();
    var { user,datos } = useUserState();   

  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [errordetail, setErrordetail] = useState(null);

  var [nombre, setNombre] = useState("");
  var [correo, setCorreo] = useState("");
  var [telefono, setTelefono] = useState(0);
  var [nit, setNit] = useState("");
  var [direccion, setDireccion] = useState("");
  var [iamgen, setImagen] = useState([]);
  var [imagenDark, setImagenDark] = useState([]);
  var [guardadoCorrecto,setGuardaroCorrecto]=useState(false);


  var [mostrarProgreso,setMostrarProgreso]=useState(true);
  var [progressImagen,setProgressImagen]=useState(0);
  var [progressImagenDark,setProgressImagenDark]=useState(0);


  var regresarPantall = () =>{
    inicializar_datos(datos,adminDispach);
    OcultarCrear_empresa(adminDispach);
  }


  const inicializar = () =>{
    setNombre("");
    setCorreo("");
    setTelefono("");
    setNit("");
    setDireccion("");
    setImagenDark("");
    setImagen("");
    setProgressImagenDark(0);
    setProgressImagen(0);
    setImagen([]);
    setImagenDark([]);
    setGuardaroCorrecto(false);

  };
  return (



    <React.Fragment>
        <Grid spacing={3} container >  
            <Grid item xs={12}>
                <Widget disableWidgetMenu >                  
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"                      
                            startIcon={<KeyboardBackspaceIcon />}
                            onClick={regresarPantall }                      
                            >                    
                            Regresar
                        </Button>
                </Widget>
            </Grid>
            <Grid item xs={12}>
                <Widget disableWidgetMenu >                  
                    <form  noValidate autoComplete="off"> 
                        <Grid spacing={2} container >  
                            <Grid item xs={12}>
                                <Typography variant='h3'>
                                    Nueva Empresa 
                                </Typography>
                            </Grid> 
                        </Grid>
                        <Grid spacing={2} container direction="column" justify="center" >  
                            <Grid item xs={12} md={3}>
                                <TextField value={nombre} onChange={e => setNombre(e.target.value)} id="outlined-basic" label="Nombre de la empresa" variant="outlined" fullWidth className={classes.magenGrandeTop}/>
                            </Grid> 
                            <Grid item xs={12} md={3}>
                                <TextField value={correo} onChange={e => setCorreo(e.target.value)} id="outlined-basic" label="Correo elctronico" variant="outlined" fullWidth />
                            </Grid> 
                            <Grid  item xs={12} md={3}>
                            <TextField  error={Number.isInteger(parseInt(telefono, 10)) ? false : true} helperText="Ingrese solo numeros" value={telefono} onChange={e => setTelefono(e.target.value)} id="outlined-basic" label="Telefono" variant="outlined"  type="number" fullWidth/>
                                    
                            </Grid> 
                            <Grid item xs={12} md={3}>
                                <TextField value={nit} onChange={e => setNit(e.target.value)} id="outlined-basic" label="Nit" variant="outlined" fullWidth />
                            </Grid> 
                            <Grid item xs={12}  md={3}>
                                <TextField value={direccion} onChange={e => setDireccion(e.target.value)} id="outlined-basic" label="Direccion de facturacío" variant="outlined" fullWidth />
                            </Grid>                  
                           
                  
                        </Grid>
                        <Grid spacing={2} container >                           
                            <Grid item xs={12} md={6}>
                                <DropzoneArea
                                    maxFileSize={500000}
                                    filesLimit={1}
                                    acceptedFiles={['image/png']}
                                    dropzoneText={"Seleccione una imagen para la Empresa"}
                                    value={iamgen}
                                    onChange={(files) => setImagen(files)}
                                />
                            </Grid>    
                        </Grid>
                        <Grid spacing={2} container > 
                            <Grid item xs={12} md={6}>
                                <DropzoneArea
                                    maxFileSize={500000}
                                    filesLimit={1}
                                    value={imagenDark}
                                    acceptedFiles={['image/png']}
                                    dropzoneText={"Seleccione una imagen para la Empresa cuando se utilice el tema Dark"}
                                    onChange={(files) => setImagenDark(files)}
                                />
                            </Grid>    
                        </Grid>    
                        <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12} className={classes.alinearDerecha}>                           
                            {isLoading ? (
                                <CircularProgress size={26} className={classes.loginLoader} />
                                ) : (
                            guardadoCorrecto===false &&  <Button
                                    disabled={
                                        nombre.length === 0 ||
                                        correo.length === 0 ||
                                        telefono.length === 0 ||
                                        nit.length === 0 ||                                     
                                        iamgen.length === 0 ||
                                        imagenDark.length===0                                      
                                    }
                                    onClick={() =>
                                        {
                                            crear_empresa(  
                                                nombre,
                                                correo,
                                                telefono,
                                                nit,
                                                direccion,
                                                iamgen,
                                                imagenDark,                                      
                                                setGuardaroCorrecto,
                                                setProgressImagen,
                                                setProgressImagenDark,
                                                setIsLoading,   
                                                setError,
                                                setErrordetail,
                                                user,
                                                datos                                          
                                            )
                                            
                                        }
                                    }
                                    variant="contained"
                                    color="primary"
                                    size="large"  
                                    startIcon={<SaveIcon />} 
                                                                
                                    >  
                                
                                    Guardar
                                </Button>
                                )}                       
                            </Grid>
                           
                        </Grid>
                        {
                            isLoading &&

                            <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12}>  
                                <Typography >
                                    Guardando Imagen
                                </Typography>                         
                                <LinearProgress variant="determinate" value={progressImagen} />
                            </Grid>    
                            <Grid item xs={12}>  
                                <Typography >
                                    Guardando Imagen tema Dark
                                </Typography>                            
                                <LinearProgress variant="determinate" value={progressImagenDark} />
                                
                            </Grid>    
                            
                        </Grid>    

                        }    
                       
                       { error && 
                        <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12}>    
                               
                                    <Alert severity="error">Error —{errordetail}</Alert>                                                    
                            </Grid>    
                        </Grid> 
                        }
                        {guardadoCorrecto &&    
                            <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                                 <Grid item xs={12}> 
                                     <Alert severity="success">La empresa se a guardado correctamente</Alert> 
                                            <Typography>
                                            Para ingresar un nuevo registro seleccione la siguiente opcion
                                            </Typography>  
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"  
                                                startIcon={<SaveIcon />}
                                                onClick={inicializar}                                                                
                                                >                                  
                                                Nuevo Registro
                                            </Button>                                                 
                                </Grid>    
                            </Grid> 
                        }
                        
                    </form>
                </Widget>
            </Grid>
        </Grid>  
     </React.Fragment> 

  );
}


