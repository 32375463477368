import React , { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Boton from "./Boton";


function Botones(props){

  const [listaBotones,setListaBotones] = React.useState([]);
  const [items,setItems]=React.useState([]);

  const EliminarBoton=(datos)=>{

      props.editar( (prevState)=>{
        var contador = 0
        prevState[datos.index].buttons.splice(datos.id,1);
        let lista=[];
        prevState[datos.index].buttons.forEach((item, key) => {
          contador++
          lista.push(
              item.type==="web_url"?
                <Boton key={props.elemento+props.bloque+"nuevo_elemento_carrusel"+props.index+"_"+key}
                       title={item.title}
                       type={item.type}
                       url={item.url}
                       editar={props.editar}
                       index={props.index}
                       index_boton={key}
                       eliminar={EliminarBoton}
                       />
                :
                <Boton key={props.elemento+props.bloque+"nuevo_elemento_carrusel"+props.index+"_"+key}
                       title={item.title}
                       type={item.type}
                       enviar_a={item.enviar_a}
                       editar={props.editar}
                       index={props.index}
                       index_boton={key}
                       eliminar={EliminarBoton}
                       />
                     );
        });

      setListaBotones(lista);

      props.setContador(contador)

        return prevState;
      });
  }




  useEffect(() =>{

// console.log("props.lista",props.lista)
    let lista=[];
    var contador = 0
      props.lista.forEach((item, key) => {
        contador++
        lista.push(
        /*  item.type==="web_url"?*/
            <Boton key={props.elemento+props.bloque+"nuevo_elemento_carrusel"+props.index+"_"+key}
                   title={item.title}
                   type={item.type}

                   url={item.type==="web_url"?item.url:undefined}
                   enviar_a={item.type==="postback"?item.enviar_a:undefined}

                   editar={props.editar}
                   index={props.index}
                   index_boton={key}
                   eliminar={EliminarBoton}
                   />
    /*        :
            <Boton key={props.elemento+props.bloque+"nuevo_elemento_carrusel"+props.index+"_"+key}
                   title={item.title}
                   type={item.type}
                   enviar_a={item.enviar_a}
                   editar={props.editar}
                   index={props.index}
                   index_boton={key}
                   eliminar={EliminarBoton}
                   />*/
        );
      });
    props.setContador(contador)
    setListaBotones(lista);

  },[props.lista,props.actualizar]);

    return (
      <React.Fragment>
      {listaBotones}
      </React.Fragment>
    );

}

export default Botones;
