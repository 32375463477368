/* eslint-disable no-use-before-define */
import React, { useState ,useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useStyles from '../styles';
import { useUserState} from "../../../context/UserContext";
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import {db} from "../../../services/firebase";


function RSSIcon(nombre){
    var icon = null
    if(nombre==="Facebook"){
      icon = <FacebookIcon style={{backgroundColor:"#FAFAFA",color:"#395692",borderRadius:'2px'}} />
    }else if(nombre==="Whatsapp"){
      icon = <WhatsAppIcon style={{color:"#FAFAFA",backgroundColor:"#42be52",borderRadius:'2px'}} />
    }else if(nombre==="Instagram"){
      icon = <InstagramIcon style={{color:"#FAFAFA",    borderRadius: '8px',
      background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
      borderRadius:'2px'}} />
    }else if(nombre==="Telegram"){
      icon = <TelegramIcon style={{color:"#FAFAFA",    backgroundColor:"#32a7d9",
      borderRadius:'2px'
      }} />
    }else if(nombre==="WebChat"){
        icon = <LanguageIcon style={{color:"#FAFAFA",backgroundColor:"#056179",borderRadius:'2px'}} />
    }

    return(
      icon
    )
}
export default function FiltroRed(props) {

  var classes = useStyles();
  var { datos,user } = useUserState();
  const fixedOptions = [];
  const [value, setValue] = React.useState([...fixedOptions]);

  //inicialicar props
    useEffect(() =>{
        if(datos.permisos.chatcenter !== undefined)
        {
          let redesSociales=[];
          if(datos.permisos.chatcenter.facebook)
          {
            redesSociales.push(top100Films[0]);
          }
          if(datos.permisos.chatcenter.whatsapp)
          {
            redesSociales.push(top100Films[1]);
          }
          if(datos.permisos.chatcenter.telegram)
          {
            redesSociales.push(top100Films[2]);
          }
          if(datos.permisos.chatcenter.instagram)
          {
            redesSociales.push(top100Films[3]);
          }
          if(datos.permisos.chatcenter.web)
          {
            redesSociales.push(top100Films[4]);
          }
            props.lista(redesSociales);
            setValue(redesSociales);
        }else{

          db.ref('/config_user/'+user.id+'/configuraciones/ChatCenter').once("value",(snapshot)=>{

            if(snapshot.val()!==null)
            {
            //  setValue(snapshot.val().redes);
               let arreglo=[];
               snapshot.val().redes.forEach((item, i) => {

                 switch (item.id) {
                  case 'facebook':
                    arreglo.push(top100Films[0]);
                    break;
                  case 'whatsapp':
                    arreglo.push(top100Films[1]);
                    break;
                  case 'telegram':
                    arreglo.push(top100Films[2]);
                    break;
                  case 'instagram':
                    arreglo.push(top100Films[3]);
                    break;
                  case 'webchat':
                    arreglo.push(top100Films[4]);
                    break;
                 }

               });
              props.lista(arreglo);
              setValue(arreglo);
               //setListaBots(snapshot.val().listaBots);
            }else{
            
              props.lista([top100Films[0],top100Films[1],top100Films[2],top100Films[3],top100Films[4]]);
              setValue([top100Films[0],top100Films[1],top100Films[2],top100Films[3],top100Films[4]]);
            }

          });

        }
    },[]);


  return (
    <Autocomplete
      multiple
      disabled={props.disable || (datos.permisos.chatcenter!== undefined && datos.permisos.chatcenter.red===false ) }
      id="fixed-tags-demo"
      value={value}
      fullWidth={true}
      onChange={(event, newValue) => {
        if(!props.disable && !(datos.permisos.chatcenter!== undefined && datos.permisos.chatcenter.red===false) ){
          props.lista(newValue);
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }
      }}
      options={top100Films}
      getOptionLabel={(option) => option.title}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
          avatar={RSSIcon(option.title)}
          // label={option.title}
            {...getTagProps({ index })}
            disabled={fixedOptions.indexOf(option) !== -1}

          />
        ))
      }
      className={classes.tamanoFiltro}
      renderInput={(params) => (
        <TextField fullWidth={true} {...params} label="Red social" variant="outlined"  />
      )}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'Facebook', id: 'facebook' },
  { title: 'Whatsapp', id: 'whatsapp' },
  { title: 'Telegram', id: 'telegram' },
  { title: 'Instagram', id: 'instagram' },
  { title: 'WebChat', id: 'webchat' },

];
