import React, { useState ,useEffect} from 'react'
import {
    Grid
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
import { useUserState} from "../../context/UserContext";
// styles
import useStyles from './styles'

import original from '../../images/original.png'
import Themes from "../../themes"
import { useThemeDispatch } from "../../context/ThemeContext"


// components
import Widget from './components/Widget'
import {Button,  Typography, Link } from '../../components/Wrappers'
import {
    Delete as DeleteIcon,
    FilterList as FilterListIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import { lighten } from '@material-ui/core/styles'
import cn from 'classnames'
import CircularProgress from '@material-ui/core/CircularProgress';
import {db} from "../../services/firebase";

const useStyles1 = makeStyles((theme) => ({
  img1: {
    '&:hover': {
      background: theme.background,
      borderRadius: 10,
      boxShadow: '200px 200px 150px 100px rgba(59, 104, 197, .5)',
      height: 85,
    }
  }
}));
// Recent Orders
function Telegram() {
    var classes = useStyles()
    var theme = useTheme()
    var classes1=useStyles1();
    var { datos } = useUserState();
    const [listabot,setListabot]=React.useState([]);
    const [datosBd, setDatosBd] = React.useState('');
    const [clientechat, setClientechat] = React.useState('');
    /*Obtener datosbot de la base de datosbot*/
    const [bot, setBot] = React.useState([]);
    const [numbot, setNumBot] = React.useState(0);
    const [datosbot,setDatos]=React.useState([]);
    const [progeso,setProgreso]=React.useState(true);

    useEffect(() =>{

      let conexion_reporte=db.ref("bots/"+datos.id_empresa).on("value",function(snapshot){
         
        let asistentes=[]
        snapshot.forEach(function(data) {  

          if(data.val().conexiones.telegram){
      
            asistentes.push(
               <Grid item xs={3} md={3} key={"botnum-"+data.key}>
                   <Widget
                       title={"No. "+data.key} botnum={data.key} setconfig={'tgsetting'} botconfig={'tgbots'}
                       bodyClass={classes.fullHeightBody}
                       className={classes.card}>
                       <Grid item xs={12} direction={'column'} container alignItems={"center"} >
                         <Link href={"#/app/tgbots/"+data.key} color={"primary"}>
                           <img className={classes1.img1} src={original} alt="image bot"  height="100" with="100"/>
                         </Link>
                       </Grid>
                       <Grid item container direction={'column'} alignItems={'center'} justify={"space-between"} >
                             <Grid item xs={12} key={'dato'+data.key} >
                               <hr/>
                               <span align="center"><strong>{data.val().nombre+" ("+data.val().idioma+")"}</strong></span>
                             </Grid>
                       </Grid>
                   </Widget>
               </Grid>
             );                
           }

        }); 
        setProgreso(false);
        setListabot(asistentes);

      });

        /*    db.ref('usuario_bot/'+datos.id_empresa+"/bots/").once("value", function(snap) {
              setNumBot(1)
            });*/
      return ()=>{
        db.ref("bots/"+datos.id_empresa).off("child_added",conexion_reporte);
      }
    

  },[]);

    return (
      <div className="container">
        {progeso && <React.Fragment><CircularProgress /> Loading....</React.Fragment> }
        <Grid container spacing={1}>          
            {listabot}
        </Grid>
      </div>

    )
}

// #######################################################################

export default Telegram;
