import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../../../contexto/AdminBotContext'
import { useUserState} from "../../../../../context/UserContext";
import {db} from "../../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteElementos from '../../DeleteElementos';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

import ListaCatalogo from './listaCatalogo';
import Datos from './datos';
import Catalogo from './catalogo';
import CancelarOrden from './cancelarOrden';
import DialogElement from '../dialogElement';

import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites,
  mensajeTextoContenidoErrores,

  validarTextoBotonesContenidoEscrito,
  maximaCantidadCaracteresEnBotones,
  mensajeTextoBotonErrores,
  validarTextoMensajeErrorEscrito,
  mensajeTextoErrorErrores

} from './../validaciones'

import log_it from './../../../../../services/log/log'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",
    },
    textoDivider:{
      fontWeight: "bold",
    },
    gridList: {

      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    margen:{
      padding: theme.spacing(1),
    },

  }),
);


export function CompraWeb(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);

    /*variables de datos*/
    const [arrayCatalogo,setArrayCatalogo]= React.useState([]);
    const [arraydatos,setArrayDatos]=React.useState([]);

    const [replays,setReplays] = React.useState([]);
    const [items,setItems]=React.useState([]);


    const [mensaje,setMensaje]=React.useState("");
    const [boton,setBoton]= React.useState("");
    const [enviara,setEnviar] = React.useState("0");
    const [url,setUrl]= React.useState("");
    const [notificacion,setNotificacion] = React.useState("");
    const [ia,setIA]=React.useState(false);
    const [mensajeCancelar,setMensajeCancelar]=React.useState("");
    const [mensajeError,setMensajeError]=React.useState("");
    const [intentos,setIntentos]=React.useState(0);

    const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);
    const [mensajeCodigoValidacionBoton,setMensajeCodigoValidacionBoton]=React.useState(-1);
    const [mensajeErrorCodigoValidacion,setMensajeErrorCodigoValidacion]=React.useState(0);


    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);


    useEffect(() =>{
        if(bloques!==null)
        {
            let lista_items=[]
            bloques.forEach((element, index, array) => {
                lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
            });
            setItems(lista_items);

        }
      },[bloques]);

    /*inicializar vaiables*/
    useEffect(() =>{
        if(props.datos)
        {
          if(props.datos.catalogo!==undefined)
          {
            let catalogo=JSON.stringify(props.datos.catalogo);
              setArrayCatalogo(JSON.parse(catalogo));
          }

          if(props.datos.datos!==undefined)
          {
            let datos=JSON.stringify(props.datos.datos);
            setArrayDatos(JSON.parse(datos));
          }

          if(props.datos.quick_replies!==undefined)
          {
            let quick=JSON.stringify(props.datos.quick_replies);
            setReplays(JSON.parse(quick));
          }

          setMensaje(props.datos.mensaje);
          setBoton(props.datos.boton);
          setEnviar((props.datos.enviar_a===""||props.datos.enviar_a===undefined)?"0":props.datos.enviar_a)
          setUrl(props.datos.url);
          setNotificacion(props.datos.notificacion);
          setIA(props.datos.ia);
          setMensajeCancelar(props.datos.mensaje_cancelar);
          var msjError = ""
          if(props.datos.mensaje_error!=undefined){
            msjError = props.datos.mensaje_error
          }
          setMensajeError(msjError);
          //Revalidar mensaje error actualizado
          var codigoValidacion = validarTextoMensajeErrorEscrito(null,msjError,bot_actual.conexion,ia)
          setMensajeErrorCodigoValidacion(codigoValidacion)


          setIntentos(props.datos.intento===undefined?"":props.datos.intento);
        }
      },[open]);


    const cambiarIA=()=>{

    }

    const cambiarEnviar=(event)=>{
        if(event.target.value!==undefined)
        {
            setEnviar(event.target.value);
        }
    }
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
       setValue(newValue);
     };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{

        setCargando(true);
        /*Guardar cuando es un nuevo elemento*/
        if(props.id_elemento===-1){
          setCargando(false);
          setOpen(false);
          enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
          props.GuardarNuevoElemento({
              boton:boton,
              catalogo:arrayCatalogo,
              datos:arraydatos,
              elemento:"webview_detalle",
              enviar_a:enviara,
              ia:ia,
              intentos:intentos,
              mensaje:mensaje,
              mensaje_cancelar:mensajeCancelar,
              mensaje_error:mensajeError,
              notificacion:notificacion,
              quick_replies:replays,
              url:url,
            },props.new_index);

        }else{
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
            .update({
                boton:boton,
                catalogo:arrayCatalogo,
                datos:arraydatos,
                elemento:"webview_detalle",
                enviar_a:enviara,
                ia:ia,
                intentos:intentos,
                mensaje:mensaje,
                mensaje_cancelar:mensajeCancelar,
                mensaje_error:mensajeError,
                notificacion:notificacion,
                quick_replies:replays,
                url:url,
              },function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                    setCargando(false);
                    enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                    setOpen(false);
                    var obj = {
                      fecha: + new Date(),
                      nombre: "modificar elemento",
                      id_accion: 132,
                      id_bot: bot_actual.id,
                      red_social: bot_actual.conexion,
                      bloque:props.id_bloque,
                      elemento:"webview_detalle",
                    }
                    log_it(datos.id_empresa,user.id,obj)
                }
            });
        }

    }

    /*Borrar elemento */
    const EliminarElemento=()=>{

        setOpen(false);
        props.eliminar(props.id_elemento);
    }

    return(
        <React.Fragment>

        <DialogElement
          id_elemento={props.id_elemento}
          moverElementos={props.moverElementos}
          moverMasuno={props.MoverMasuno}
          moverMenosuno={props.MoverMenosuno}
          icono={<ShoppingCartIcon/>}
          metodoAbrir={handleClickOpen}
          nombre={props.nombre}
          tamanio={props.tamanio}
          dialogOpen={open}
          dialogClose={handleClose}
          cargando={cargando}
          disabledSave={
            mensaje.length===0 || boton.length===0 || boton.length>20 || url.length===0 ||
            notificacion.length===0 || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(notificacion)===false ||
            (mensajeError.length===0 && ia) || (intentos<0 && ia)
          }
          guardar={GuardarCambios}
          eliminar={props.eliminar}
          cerrar={setOpen}
        >

                    <Grid item xs={12}>
                        <Typography className={classes.textoDivider}>
                         Confirmar Compra
                         </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid spacing={1} container>

                          <Grid item xs={12}>
                              <TextField id="ElTexto_mensaje"
                                 label="Mensaje"
                                 variant="outlined"
                                 fullWidth
                                 multiline
                                 value={mensaje}
                                //  onChange={e=>setMensaje(e.target.value)}
                                onChange={
                                  (e)=>{
                                    /*Para hacerlo mas legible*/
                                    var codigoValidacion = validarTextoMensajeContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                    setMensajeCodigoValidacion(codigoValidacion)
                                    if(codigoValidacion<1){
                                      setMensaje(e.target.value)
                                    }
                                  }
                                }
                                inputProps={{
                                  maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                }}
                                //  error={mensaje.length===0}
                                //  helperText={mensaje.length===0?"Campo obligatorio":""}
                                error={ mensajeCodigoValidacion != -1}
                                helperText={mensajeTextoContenidoErrores(mensajeCodigoValidacion,bot_actual.conexion)}

                                disabled={datos.permisos.abcbot.bloques!==true}
                                />
                          </Grid>

                          <Grid item xs={12}>
                              <Grid spacing={2} container >

                                <Grid item xs={12} sm={6}>
                                    <TextField id="ElTexto_mensaje"
                                       label="Boton"
                                       variant="outlined"
                                       fullWidth
                                       value={boton}
                                      //  onChange={e=>setBoton(e.target.value)}
                                      onChange={
                                        e=>{
                                          /*Para hacerlo mas legible*/
                                          var codigoValidacionBoton = validarTextoBotonesContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                          setMensajeCodigoValidacionBoton(codigoValidacionBoton)
                                          if(codigoValidacionBoton<1){
                                            setBoton(e.target.value)
                                          }
                                        }
                                      }
                                      //  error={boton.length===0 || boton.length>20}
                                      //  helperText={boton.length===0?"Campo obligatorio":
                                      //       boton.length>20?"El texto no puede ser mayor a 20 caracteres":
                                      //       ""
                                      // }
                                      error={ (mensajeCodigoValidacionBoton != -1)}
                                      helperText={mensajeTextoBotonErrores(mensajeCodigoValidacionBoton)}
                                      inputProps={{
                                        maxLength:maximaCantidadCaracteresEnBotones
                                      }}
                                      disabled={datos.permisos.abcbot.bloques!==true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}    >
                                    <InputLabel id="demo-simple-select-outlined-label">Enviar a</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={enviara}
                                        onChange={e=>setEnviar(e.target.value)}
                                        label="Enviar a"
                                    >
                                        <MenuItem value="0">
                                            <em>None</em>
                                        </MenuItem>
                                        {items}
                                    </Select>

                                    </FormControl>
                                </Grid>

                              </Grid>
                          </Grid>

                          <Grid item xs={12}>
                              <TextField id="ElTexto_mensaje"
                                   label="Url"
                                   variant="outlined"
                                   fullWidth
                                   value={url}
                                   onChange={e=>setUrl(e.target.value)}
                                   error={url.length===0}
                                   helperText={url.length===0?"Campo obligatorio":""}
                                   disabled={datos.permisos.abcbot.bloques!==true}
                              />
                          </Grid>

                          <Grid item xs={12}>
                              <TextField id="ElTexto_mensaje"
                                 label="Notificacion"
                                 variant="outlined"
                                 fullWidth
                                 value={notificacion}
                                 onChange={e=>setNotificacion(e.target.value)}
                                 error={notificacion.length===0 || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(notificacion)===false}
                                 helperText={notificacion.length===0?"Campo obligatorio":
                                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(notificacion)?"":"El correo no es valido"
                                }
                                disabled={datos.permisos.abcbot.bloques!==true}
                                         />
                          </Grid>

                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider variant="middle" className={classes.colorDivider}/>
                    </Grid>


                    <Grid item xs={12}>
                        <Typography className={classes.textoDivider}>
                         Catalago
                         </Typography>
                    </Grid>

                    <ListaCatalogo
                      arreglo={arrayCatalogo}
                      editar={setArrayCatalogo}
                      bloque={props.id_bloque}
                      elemento={props.id_elemento}
                      keyword={"catalogo"}
                      />

                      <Grid item xs={12}>
                          <br/>
                      </Grid>

                    <Grid item xs={12}>
                        <Divider variant="middle" className={classes.colorDivider}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.textoDivider}>
                         Datos
                         </Typography>
                    </Grid>

                    <Catalogo
                        lista={arraydatos}
                        editar={setArrayDatos}
                        index={-1}
                      />

                      <Grid item xs={12}>
                          <br/>
                      </Grid>

                    <Grid item xs={12}>
                        <Divider variant="middle" className={classes.colorDivider}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.textoDivider}>
                         Cancelar Compra
                         </Typography>
                    </Grid>

                    <CancelarOrden
                      arregloReplies={replays}
                      editar={setReplays}
                      editar_mensajeCancelar={setMensajeCancelar}
                      editar_ia={setIA}
                      mensajeCancelar={mensajeCancelar}
                      ia={ia}


                    />

                  { ia &&
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                  }

                  { ia &&
                  <Grid item xs={12}>
                      <Divider variant="middle" className={classes.colorDivider}/>
                  </Grid>}

                  { ia &&
                  <Grid item xs={12}>
                      <Typography className={classes.textoDivider}>
                        Aplica para confirmar y cancelar
                       </Typography>
                  </Grid>}

                {ia &&
                    <Grid item xs={12}>
                      <Grid spacing={2} container >

                        <Grid item xs={12} sm={10} >
                          <TextField id="ElTexto_mensaje"
                                     label="Mensaje de error"
                                     variant="outlined"
                                     fullWidth
                                     multiline
                                    //  onChange={e=>setMensajeError(e.target.value)}
                                    onChange={
                                      (e)=>{
                                        /*Para hacerlo mas legible*/
                                        var codigoValidacion = validarTextoMensajeErrorEscrito(e,e.target.value,bot_actual.conexion,ia)
                                        setMensajeErrorCodigoValidacion(codigoValidacion)
                                        if(codigoValidacion<1){
                                          setMensajeError(e.target.value)
                                        }
                                      }
                                    }
                                     value={mensajeError}
                                    //  error={mensajeError.length===0 && ia}
                                    //  helperText={mensajeError.length===0?"El campo es obligatorio":""}
                                    inputProps={{
                                      maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                     }}
                                     error={
                                      (ia && mensajeError.length===0) ||
                                      (mensajeErrorCodigoValidacion!=-1 )
                                      }
                                     helperText={

                                       mensajeTextoErrorErrores(mensajeErrorCodigoValidacion,bot_actual.conexion)
                                    }

                                     disabled={datos.permisos.abcbot.bloques!==true}
                                     />
                        </Grid>
                        <Grid item xs={12} sm={2} >
                          <TextField id="ElTexto_mensaje"
                                     label="Intentos"
                                     variant="outlined"
                                     fullWidth
                                     type={"number"}
                                     value={intentos}
                                     onChange={e=>setIntentos(e.target.value)}
                                     error={intentos<0 && ia }
                                     helperText={intentos<0?"El valor no puede ser negativo":""}
                                     disabled={datos.permisos.abcbot.bloques!==true}
                                     />
                        </Grid>

                      </Grid>
                  </Grid>}

            </DialogElement>

        </React.Fragment>
       );

}
