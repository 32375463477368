import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  imagenes: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    display:'block',
    margin:'auto',
  },
  imagenesDark: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    display:'block',
    margin:'auto',
    backgroundColor:"#000000 !important",
  },
  iconoEliminar:{
    color:"#CD5C5C"
  },
  iconoEditar:{
    color:"#5dade2"
  },
  colorFondoHead:{
    backgroundColor:  theme.palette.primary.light,
  },
  TituloUsusarios:{
    textAlign: 'center',
    backgroundColor:  theme.palette.primary.light,

  },
  textoUsuario:{
      fontWeight:'bold !important' ,
      fontSize: '18px !important',
      color: '#ffff',
  },
  textoHeadEmpresa:{
    fontSize :'18px !important',
    display:'block',
    margin:'auto',
    textAlign:'center',
    color:  theme.palette.primary.main,
  },
  alinearDerecha:{
    textAlign: 'right'
  },
  magenGrandeTop:{
   margin: ' 25px 0 0 0',
  },
  loginLoader: {
    marginLeft: theme.spacing(4)
  },
  fondoDark: {
    backgroundColor:"#000000 !important",
  },

  colorFB:{
    	color:"#4867aa"
  },
  colorTg:{
    color:"#2ba0d9"
  },
  colorWa:{
    color:"#25D366"
  },
  colorWeb:{
    color: theme.palette.primary.main,
  },

  colorIg:{
    borderRadius: '8px',
    border:'2px',
    background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
    color:"#FAFAFA"
  },
  nuevoBloque:{
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
  },
  bloqueElementos: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  separadorBloques:{
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  bloqueEditor:{
    borderColor :"black",
    border:1
  },
  boxBloque:{
    width: "90%",
    display: 'flex',
    justifyContent: 'center',
  },
  Bloquelist: {
    width: '100%',
    position: 'relative',
    overflowY: 'auto',
    /*height: theme.spacing(70),*/
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  Bloquedefault:{
    backgroundColor: theme.palette.primary.main,
  },
  rootlistItem:{
    "&.Mui-selected, &:hover": {
      backgroundColor: "#CDCED7 !important",
      color: "white"
    }
  },
  BarraMenu:{
      borderColor: theme.palette.secondary.main,
      border:1

  },
  bordeItem:{
  margin: '3px',
  borderTop: `10px solid ${theme.palette.primary.main}`,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  width: '350px',
  '& svg': {
    margin: theme.spacing(1.5),
  },
  '& hr': {
    margin: theme.spacing(0, 0.5),
  },
},
  bordeItemDEfault:{
  margin: '3px',
  borderTop: `10px solid ${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.light,
  color: theme.palette.secondary.main,
  width: '350px',
  '& svg': {
    margin: theme.spacing(1.5),
  },
  '& hr': {
    margin: theme.spacing(0, 0.5),
  },
  subtituloBloque:{
    fontSize:'5px',
  },
  tituloBloque:{
    fontWeight: '500',
  },
},
listaElementos:{
    borderTop: `30px solid ${theme.palette.primary.main}`,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    padding: '30px',
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
    [theme.breakpoints.down("xs")]: {
        padding: '0',
        borderTop: `0px solid ${theme.palette.primary.main}`,
        border: `0px solid ${theme.palette.primary.main}`,
    },
  }
}));
