import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../contexto/AdminBotContext'
import { useUserState} from "../../../context/UserContext";
import {db} from "../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteBloque from './DeleteBloque';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import log_it from './../../../services/log/log';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    }
  }),
);


export default function EditarBloque(props){

    const classes = useStyles();
    var {bot_actual,bloques,bloque_actual} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);
    const [items,setItems]=React.useState([]);

    /*variables de datos*/
    const [nombre,setNombre]=React.useState("")
    const [padre,setPadre] = React.useState("0");

    useEffect(() =>{
        if(bloques!==null)
        {
            let lista_items=[]
            bloques.forEach((element, index, array) => {
                lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
            });
            setItems(lista_items);
        }
      },[bloques]);


    /*inicializar vaiables*/
      useEffect(() =>{
        setNombre(props.nombre);
        setPadre(props.padre);
      },[open]);


    const CambiarPadre=(event)=>{
      if(event.target.value!==undefined)
      {
          setPadre(event.target.value);
      }
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
    //buscar si ya existe un bloque con el mismo Nombre
     setCargando(true);
      
      if(nombre===props.nombre)
      {
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+nombre)
            .update({
                  padre:padre
            },function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                    setCargando(false);
                    enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                    setOpen(false);
                    var obj = {
                      fecha: + new Date(),
                      nombre: "modificar bloque",
                      id_accion: 122,
                      id_bot: bot_actual.id,
                      red_social: bot_actual.conexion,
                      bloque:nombre,
                    }
                    log_it(datos.id_empresa,user.id,obj)
          
                }
            });
      }else{

        if(bloques.indexOf(nombre)=== -1)
        {
            let actual=props.nombre;

            let cont=JSON.stringify(props.elementos);
            let nuevo=""
            if(cont===undefined){
            nuevo="{\""+nombre+"\": {"+
              "\"is_default\":false,"+
              "\"padre\":\""+padre+"\""+
              "}}";
            }else{
              nuevo="{\""+nombre+"\": {"+
              "\"is_default\":false,"+
              "\"elementos\":"+cont+","+
              "\"padre\":\""+padre+"\""+
              "}}";
            }
            setCargando(false);
            setOpen(false);
            enqueueSnackbar('Procesando la solicitud', {variant: 'info'});
            props.cambiarNombre({datos:nuevo,actual:actual});
            var obj = {
              fecha: + new Date(),
              nombre: "modificar bloque",
              id_accion: 122,
              id_bot: bot_actual.id,
              red_social: bot_actual.conexion,
              bloque:nombre,
            }
            log_it(datos.id_empresa,user.id,obj)
  
/*
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id)
            .update(JSON.parse(nuevo),function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                  db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+actual)
                  .set(null);
                  setCargando(false);
                  enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                  setOpen(false);

                }
            });
*/

        }else{
          setCargando(false);
          enqueueSnackbar('Ya existe un bloque con el mismo nombre', {variant: 'error'});
        }

      }





    /*    setCargando(true);
        db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
        .update({
            atributo:atributos,
            elemento:"texto",
            ia:ia,
            intento:intentos,
            mensaje:mensaje,
            mensaje_error:mensajeError,
            validacion: validacion==="0"?"":validacion
        },function(error) {
            if (error) {
                setCargando(false);
                enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
            } else {
                setCargando(false);
                enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                setOpen(false);

            }
        });
*/

    }

    /*Borrar elemento */
    const EliminarElemento=()=>{

        setOpen(false);
        props.eliminar(props.id_elemento);
    }

    return(
        <React.Fragment>

        <Tooltip title="Editar bloque">
          <IconButton disabled={bloque_actual===""?true: bloque_actual.nombre==="default"?true:    datos.permisos.abcbot.bloques===true?false:true}  color="primary" aria-label="upload picture" component="span" onClick={handleClickOpen}>
            <EditIcon />
            </IconButton>
        </Tooltip>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                <DialogTitle id="form-dialog-title">{"Bloque:"+props.nombre} <EditIcon/> </DialogTitle>
                <DialogContent dividers>


                <Grid spacing={3} container >


                <Grid item xs={12}>
                  {datos.permisos.abcbot.bloques===true &&
                      <DeleteBloque
                        eliminar={props.eliminar}
                        cerrar={setOpen}
                        idBloque={props.nombre}
                      />}
                </Grid>

                <Grid item xs={12}>
                    <Grid spacing={2} container >


                        <Grid item xs={12} sm={8}>
                            <TextField id="ElTexto_mensaje"
                                       value={nombre}
                                       onChange={e => setNombre(e.target.value)}
                                       label="Nombre"
                                       variant="outlined"
                                       fullWidth
                                       disabled={datos.permisos.abcbot.bloques!==true}
                                       error={ /^[a-zA-Z0-9_-]{1,30}$/.test(nombre)===false || nombre==="default"}
                                       helperText={/^[a-zA-Z0-9_-]{1,30}$/.test(nombre)===false?"El nombre no puede tener espacios en blanco y debe contener entre 1 y 30 caracteres":
                                        nombre==="default"?"El nombre no puede ser default":""
                                      }
                                       />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl}  error={padre==="0"}       >
                            <InputLabel id="demo-simple-select-outlined-label">Padre</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={padre}
                                onClick={CambiarPadre}
                                label="Padre"
                                disabled={datos.permisos.abcbot.bloques!==true}
                            >
                                <MenuItem value="0">
                                    <em>None</em>
                                </MenuItem>
                                {items}
                            </Select>
                            <FormHelperText>{ padre==="0"?"El campo es obligatorio":""}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>


                </Grid>


                </DialogContent>
               {cargando ?
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                :
                  datos.permisos.abcbot.bloques===true?
                  <DialogActions>
                      <Button onClick={GuardarCambios} color="secondary"
                            disabled={
                              padre === "0" || /^[a-zA-Z0-9_-]{1,30}$/.test(nombre)===false || nombre===padre || nombre==="default" || datos.permisos.abcbot.bloques!==true
                            }
                      >
                          Guardar
                      </Button>
                      <Button onClick={handleClose} color="primary">
                          Cancelar
                      </Button>
                  </DialogActions>
                  :
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                    </DialogActions>
              }

            </Dialog>

        </React.Fragment>
       );

}
