import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import Respuesta from "./respuesta";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Parametro from './parametro'
import {useSnackbar } from 'notistack';
import { Typography } from "../../../../../../components/Wrappers";
import { useUserState} from "../../../../../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    formControl: {
      width: "100%",
}
  }),
);

function Parametros (props){

  const classes = useStyles();
  var { datos,user } = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const[items,setItems] = React.useState([]);
  const [arregloParametros,setArregloParametros] = React.useState([]);

  const [nombre,setNombre] = React.useState("");
  const [valor,setValor] = React.useState("");
  const [actualizarParametros,setActualizarParametros] = React.useState(true);

  useEffect(() =>{

    if(props.arreglo)
    {
      /*-----------------------------Parametros--------------------*/
      let arreglo=[];
      let i=0;
      for( var keyA in props.arreglo)
      {
        arreglo.push(<Parametro
                        key={"parametro-"+props.id_bloque+"-"+props.id_elemento+"-"+keyA+i}
                        nombre={keyA}
                        valor={props.arreglo[keyA]}
                        editar={props.editar}
                        eliminar={EliminarParametro}
                      />);
        i++;
      }
      setArregloParametros(arreglo);
    }

  },[props.arreglo,actualizarParametros]);

  const AgregarParametros=()=>{


    if(props.arreglo===undefined || Object.entries(props.arreglo).length === 0)
    {
     props.editar(
       JSON.parse("{\""+nombre+"\":\""+valor+"\"}")
     );
     setNombre("");
     setValor("");

    }else if(props.arreglo[nombre]===undefined)
    {
      props.editar( (prevState)=>{
        let param=JSON.stringify(prevState);
        param=param.slice(0, -1)+",\""+nombre+"\":\""+valor+"\"}";
        return JSON.parse(param);
      });
      setNombre("");
      setValor("");
    }else{
        enqueueSnackbar('Ya existe un parametro con ese nombre', {variant: 'error'});
    }
  }

  const EliminarParametro=(datos)=>{
    props.editar((prevState)=>{
      delete prevState[datos.id]
      return prevState;
    });
    setActualizarParametros(actualizarParametros?false:true);
  }

  return(
    <React.Fragment>
      <Grid item xs={12} >
          <Grid spacing={2} container >

          <Grid item xs={12}>
              <Typography variant={"h3"}>Parametros</Typography>
          </Grid>

          { datos.permisos.abcbot.bloques===true &&
            <div>
              <Grid
              style={{marginBottom:"15px"}}
              item xs={12} sm={5}>
                  <TextField
                      id="outlined-basic"
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      value={nombre}
                      onChange={e=>setNombre(e.target.value)}
                      />
              </Grid>

              <Grid
              style={{marginBottom:"15px"}}
              item xs={12} sm={5}>
                  <TextField
                      id="outlined-basic"
                      label="Valor"
                      variant="outlined"
                      fullWidth
                      value={valor}
                      onChange={e=>setValor(e.target.value)}
                      />
              </Grid>



              <Grid
              style={{marginBottom:"15px"}}
              item xs={12} sm={2}>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<AddBoxIcon/>}
                    onClick={AgregarParametros}
                    disabled={nombre.length===0 || valor.length===0}
                >
                    Agregar
                </Button>
              </Grid>
            </div>}

          </Grid>
      </Grid>

      <Grid item xs={12}>
          <Divider variant="middle" className={classes.colorDivider}/>
      </Grid>


          {arregloParametros}




    </React.Fragment>
  );

}

export default Parametros;
