import React,{useEffect} from 'react';
import EmpresasLite from './componentes/EmpresasLite';
import ManagerUser from './componentes/ManagerUser';
import {Grid} from "@material-ui/core";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import Widget from '../../components/Widget';
import Button from '@material-ui/core/Button';
import Nuevaempresa from './componentes/NuevaEmpresa';
import { useUserState} from "../../context/UserContext";
import LinearProgress from '@material-ui/core/LinearProgress';
import EditarEmpresa from './componentes/EditarEmpresa'

import {AdminProvider,MostrarCrear_empresa,useAdminDispatch,useAdminState,inicializar_datos} from './contexto/AdminContext'

function  LayoutAdmin(){

    var { datos } = useUserState();   
    var adminDispach=useAdminDispatch();
    
    useEffect(() =>{    
      inicializar_datos(datos,adminDispach);
    
    },[]);

    
    var { crear_empresa,mostrar_empresa,editar_usuario,empresa,permiso_crearEmp,editar_empresa } = useAdminState();

    const lista_empresas = empresa.map((empresa) =>  <EmpresasLite key={empresa.id} id={empresa.id} nombre={empresa.nombre} estado={empresa.estatus} foto={empresa.foto}/>); 
  
    return (
 
      <React.Fragment>     
            {permiso_crearEmp && crear_empresa && <Nuevaempresa/>} 
            
                <Grid spacing={2} container >  
                  {
                    permiso_crearEmp && mostrar_empresa &&
                    empresa.length > 0 &&
                     <Grid item xs={12}>                     
                          <Widget disableWidgetMenu >                  
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"                      
                                    startIcon={<LibraryAddIcon />}   
                                    onClick={() => MostrarCrear_empresa(adminDispach)}                      
                                  >                    
                                    Nueva empresa
                                </Button>
                          </Widget>
                        </Grid>
                    }
                    {  empresa.length === 0 &&
                        <Grid item xs={12}>
                            <Widget disableWidgetMenu>
                                Cargando ....
                                <LinearProgress/>
                            </Widget>
                        </Grid>
                    }
                    {mostrar_empresa && lista_empresas}
              </Grid>

            {editar_usuario &&
              <ManagerUser></ManagerUser>  
            }    
           
           { editar_empresa && <EditarEmpresa/>}
       
      </React.Fragment> 
     
/*
          <React.Fragment>
            {
            mostrar_usuario===false ? 

              crear_empresa===true?
                <Nuevaempresa/>
              :        
                <Grid spacing={2} container >  
                    <Grid item xs={12}>
                        <Widget disableWidgetMenu >                  
                              <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"                      
                                  startIcon={<LibraryAddIcon />}
                                 
                                >                    
                                  Nueva empresa
                              </Button>
                        </Widget>
                      </Grid>
                      <EmpresasLite />    
                      <EmpresasLite/>   
                      <EmpresasLite/>    
                      <EmpresasLite/>   
                      <EmpresasLite/>    
                      <EmpresasLite/>   
                      <EmpresasLite/>    
                      <EmpresasLite/>   
                  </Grid>
            :       
              <Grid container spacing={2}>  
                <ManagerUser></ManagerUser>   
              </Grid>        

            }       

          </React.Fragment>      
*/
    );



}


class Admin extends React.Component{
  render(){
    return (
      <AdminProvider>
        <LayoutAdmin/>
      </AdminProvider>
    );
  }
}

export default Admin;