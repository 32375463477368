import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState, useAdminBotDispatch,BloqueActual} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import QueueIcon from '@material-ui/icons/Queue';
import Tooltip from '@material-ui/core/Tooltip';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import log_it from './../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    }
  }),
);


export default function PasosBloque (props){

    const classes = useStyles();
    var {bot_actual,bloques,bloque_actual} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();
    var adminBotDispach=useAdminBotDispatch();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);
    const [items,setItems]=React.useState([]);

    /*variables de datos*/
    const [nombre,setNombre]=React.useState("")
    const [padre,setPadre] = React.useState("0");

    useEffect(() =>{
        if(bloques!==null)
        {
            let lista_items=[]
            bloques.forEach((element, index, array) => {
                lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
            });
            setItems(lista_items);
        }
      },[bloques]);


    /*inicializar vaiables*/
      useEffect(() =>{
        if(props.duplicar){
          setNombre("duplicado_"+props.nombre);
          setPadre(props.padre===undefined?"0":props.padre);
        }else{
          setNombre("");
          setPadre("0");
        }
      },[open]);


    const CambiarPadre=(event)=>{
      if(event.target.value!==undefined)
      {
        setPadre(event.target.value);
      }
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
    //buscar si ya existe un bloque con el mismo Nombre
     setCargando(true);

        if(bloques.indexOf(nombre)=== -1)
        {
          let nuevo="{\""+nombre+"\":{\"padre\":\""+padre+"\",\"is_default\":false}}";

          if (bot_actual.conexion === "webchat")
          {
              nuevo="{\""+nombre+"\":{\"padre\":\""+padre+"\",\"default\":false}}";
          }

          if(props.duplicar)
          {
            if(bot_actual.conexion ==="webchat")
            {
              nuevo="{\""+nombre+"\":{\"padre\":\""+padre+"\",\"default\":false,\"elementos\":"+ JSON.stringify(props.elementos)+"}}";
            }else{
              nuevo="{\""+nombre+"\":{\"padre\":\""+padre+"\",\"is_default\":false,\"elementos\":"+ JSON.stringify(props.elementos)+"}}";
            }

          }

          db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id)
          .update(JSON.parse(nuevo),
            function(error) {
              if (error) {
                  setCargando(false);
                  enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
              } else {
                  setCargando(false);
                  enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                  setOpen(false);

                  if(props.duplicar)
                  {
                    BloqueActual(adminBotDispach,{
                                                  nombre:nombre,
                                                  padre:padre,
                                                  elementos:props.elementos
                                                  });
                  }else{
                    BloqueActual(adminBotDispach,{
                                                  nombre:nombre,
                                                  padre:padre,
                                                  elementos:[]
                                                  });
                  }           
                  var obj = {
                    fecha: + new Date(),
                    nombre: "crear bloque",
                    id_accion: 121,
                    id_bot: bot_actual.id,
                    red_social: bot_actual.conexion,
                    bloque:nombre,
                  }
                  log_it(datos.id_empresa,user.id,obj)
        

              }
          });/*

        setCargando(false);
        setOpen(false);
        props.agregar({nombre:nombre,padre:padre});49.99*/

        }else{
          setCargando(false);
          enqueueSnackbar('Ya existe un bloque con el mismo nombre', {variant: 'error'});
        }

      }



    return(
        <React.Fragment>


    {props.duplicar===true ?
      <Tooltip title={props.tooltip}>
        <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClickOpen}
            disabled={bloque_actual===""?true: datos.permisos.abcbot.bloques===true?false:true}
        >
          {props.icono}
        </IconButton>
      </Tooltip>


      :

      <Tooltip title={props.tooltip}>
        <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClickOpen}
            disabled={datos.permisos.abcbot.bloques===true?false:true}
        >
          {props.icono}
        </IconButton>
      </Tooltip>

      }

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
              {props.duplicar===true ?
                  <DialogTitle id="form-dialog-title">Duplicar bloque<QueueIcon/></DialogTitle>
                  :
                  <DialogTitle id="form-dialog-title">Nuevo Bloque<AddCircleOutlineIcon /></DialogTitle>
              }
                <DialogContent dividers>


                <Grid spacing={3} container >


                <Grid item xs={12}>
                    <Grid spacing={2} container >


                        <Grid item xs={12} sm={8}>
                            <TextField id="ElTexto_mensaje"
                                       value={nombre}
                                       onChange={e => setNombre(e.target.value)}
                                       label="Nombre"
                                       variant="outlined"
                                       fullWidth
                                       error={ /^[a-zA-Z0-9_-]{1,30}$/.test(nombre)===false || nombre==="default"}
                                       helperText={/^[a-zA-Z0-9_-]{1,30}$/.test(nombre)===false?"El nombre no puede tener espacios en blanco y debe contener entre 1 y 30 caracteres":
                                        nombre==="default"?"El nombre no puede ser default":""
                                      }
                                       />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl}  error={padre==="0" || padre===0} >
                            <InputLabel id="demo-simple-select-outlined-label">Padre</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={padre}
                                onClick={CambiarPadre}
                                label="Padre"
                            >
                                <MenuItem value="0">
                                    <em>None</em>
                                </MenuItem>
                                {items}
                            </Select>
                            <FormHelperText>{ padre==="0" || padre===0?"El campo es obligatorio":""}</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>


                </Grid>


                </DialogContent>
               {cargando ?
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                : <DialogActions>
                    <Button onClick={GuardarCambios} color="secondary"
                          disabled={
                            padre === "0" || padre===0 || /^[a-zA-Z0-9_-]{1,30}$/.test(nombre)===false || nombre===padre || nombre==="default"
                          }
                    >
                        Guardar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>}

            </Dialog>

        </React.Fragment>
       );

}
