import React, { useState ,useEffect} from 'react'

import { useTheme, makeStyles } from '@material-ui/styles'
import MobileStepper from '@material-ui/core/MobileStepper';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
// styles
import useStyles from './styles'

// components
import {Button } from '../../components/Wrappers'

import {db} from "../../services/firebase";
import datosB from "./datos";



// Recent Orders
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);



const useStyles1 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

function Dashboard() {
    var classes = useStyles()
    var classes1 = useStyles1()
    var theme = useTheme()
    const [activeStep, setActiveStep] = React.useState(0);
    const [maxSteps, setMaxSteps] = React.useState([]);
    // const maxSteps = tutorialSteps.length;

    const [tutorialSteps, setTutorialSteps] = React.useState([]);

    // local
    var [mainChartState, setMainChartState] = useState('monthly')

    // Recent Orders table

    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('price')
    const [selected, setSelected] = React.useState([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const handleNext = () => {

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const calcularUsr = () => {
      const datosA = datosB.datosA;
      // Crear un objeto para almacenar los conteos de usuarios por día
      let usersPerDay = {};

// Definir las fechas de inicio y fin en milisegundos
      let startDate = new Date('2024-04-01').getTime();
      let endDate = new Date('2024-04-11').getTime();
      let totalUsers = 0;

// Iterar sobre cada usuario en datosA
      for (let user in datosA) {
          // Verificar si el usuario tiene el atributo ultima_fecha
          if (datosA[user].atributos && datosA[user].atributos.ultima_fecha) {
              // Convertir el timestamp a milisegundos
              let userDate = datosA[user].atributos.ultima_fecha * 1000;

              // Verificar si la fecha del usuario está dentro del rango de fechas
              if (userDate >= startDate && userDate < endDate) {
                  // Obtener el día del mes
                  let day = new Date(userDate).getDate();

                  // Si el día ya existe en el objeto, incrementar el contador, si no, inicializarlo a 1
                  usersPerDay[day] = (usersPerDay[day] || 0) + 1;
                  // Incrementar el total de usuarios
                  totalUsers++;
              }
          }
      }

      console.log(usersPerDay);
      console.log('Total de usuarios:', totalUsers);

  };
  useEffect(() =>{
      db.ref("dashboard/")
    .once("value",(snapshot)=>{
      var listDashboardImgs = []
      snapshot.forEach((item, i) => {
        // console.log(item.val())
        listDashboardImgs.push({
          label:item.val().label,
          imgPath:item.val().link
        })
      });
      setMaxSteps(listDashboardImgs.length)
      setTutorialSteps(listDashboardImgs)
    })
  },[]);

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const isSelected = name => selected.indexOf(name) !== -1

    const [datosBd, setDatosBd] = React.useState('');
    const [clientechat, setClientechat] = React.useState('');
    /*Obtener datos de la base de datos*/



    return (
      <div className={classes.root}  align="center">
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 1 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} style={{width:"90%"}}/>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={tutorialSteps.length}
        position="static" style={{width:"80%"}}
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Siguiente
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Átras
          </Button>
        }
      />
    <Button onClick={calcularUsr}>
       Calcular
    </Button>

    </div>

    );
}

// #######################################################################
export default Dashboard
