import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import Widget from '../../../../components/Widget';
import useStyles from '../../styles';
import Box from '@material-ui/core/Box';
import { Typography } from "../../../../components/Wrappers";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import Divider from '@material-ui/core/Divider';
import PasosBloque from "./PasosBloque"

 function NuevoBloque(props){

    var classes = useStyles();



    return(
        <React.Fragment>
            <Grid item xs={12} className={classes.boxBloque}>
                <Box boxShadow={3}>
                    <Box justifyContent="center">
                        <Box border={1}>
                            <Widget
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu
                                >
                                <Grid spacing={2} container>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" className={classes.bloqueElementos}>
                                            Nuevo Bloque
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.bloqueElementos}>
                                        <PasosBloque
                                          agregar={props.agregar}
                                        />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Widget>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                    <div  className={classes.separadorBloques}>
                        <UnfoldMoreIcon/>
                    </div>
            </Grid>
        </React.Fragment>
    );

}

export default NuevoBloque;
