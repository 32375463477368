import React from "react";
import {Typography } from "../components/Wrappers";


function LoadingInterface () {
    
    return(
        <Typography>
            Loading interface ...
        </Typography>
           
    );

}



export default LoadingInterface;