import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteElementos from '../DeleteElementos';
import ErrorIcon from '@material-ui/icons/Error';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    },
    borderItem:{
          /*boxShadow: '1px 1px 5px #999',*/
          paddingTop: '15px',
          paddingBottom:'15px',
          marginBottom:'10px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.primary.main,
          color:"white",

       },
  }),
);


export function NoElement(props){

    const classes = useStyles();
    var {bot_actual} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);




    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleCloseEditar = () => {
      setAnchorEl(null);
    };



    return(
        <React.Fragment>


                <ListItem className={classes.borderItem}>
                  <ListItemIcon style={{color:"#ffff"}}>
                    <ErrorIcon/>
                  </ListItemIcon>
                  <ListItemText primary={"["+props.id_elemento+"] "+props.nombre} />
                    {datos.permisos.abcbot.bloques &&
                    <ListItemSecondaryAction>
                     <IconButton  style={{color:"#ffff"}} edge="end" aria-label="delete" onClick={handleClick}>
                       <ArrowDropDownIcon />
                     </IconButton>
                   </ListItemSecondaryAction>}
                </ListItem>

                <Menu
                     disableAutoFocusItem
                     id="simple-menu"
                     anchorEl={anchorEl}
                     keepMounted
                     open={Boolean(anchorEl)}
                     onClose={handleCloseEditar}
                     classes={{paper:classes.paper}}
                   >
                     <MenuItem className={classes.menuitem} disabled={!datos.permisos.abcbot.bloques} onClick={handleClickOpen}><EditIcon fontSize={"small"}/>Editar</MenuItem>
                   </Menu>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                  <DialogTitle id="form-dialog-title">Elemento: No encontrado <ErrorIcon/> </DialogTitle>
                  <DialogContent dividers>


                  <Grid spacing={3} container >
                      <Grid item xs={12}>
                            <Typography variant={"h3"}>Elemento no reconocido</Typography>
                      </Grid>
                      <Grid item xs={12}>
                            <Typography >El elemento actual no es reconocido por el editor de bloques, por favor contacte al administrador del sitio para resolver el problema</Typography>
                      </Grid>
                  </Grid>


                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleClose} color="primary">
                          Cancelar
                      </Button>
                  </DialogActions>
            </Dialog>
        </React.Fragment>
       );

}
