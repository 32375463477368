import React from 'react';

import {storage,db} from "../../../services/firebase";
import log_it from './../../../services/log/log';

var AdminStateContext =React.createContext();
var AdminDispatchContext = React.createContext();

function adminReducer(state, action)
{
    switch(action.type)
    {
        case "INICIAR_EMPRESAS":
            return {...state,
                empresa:[],
            }

        case "PERMISO_EMPRESAS":
            return {...state,
                permiso_crearEmp:action.permiso,
            }

        case "INICIAR_DATOS":
            state.empresa.push(action.empresa);
            return {...state,
                empresa:state.empresa,

            }

        case "SHOW_CREATEEMPRESA":
            return {...state,  mostrar_empresa: false,
                crear_empresa: true,

                 }

        case "OCULTAR_CREAREMPRESA":
                    return {...state,  mostrar_empresa: true,
                        crear_empresa: false,
                          }

        case "EDITAR_USUARIO":
            return {...state,  mostrar_empresa: false,
                crear_empresa: false,
                editar_empresa:false,
                editar_usuario:true,
                id_empresa:action.id_empresa

                }

        case "OCULTAR_EDITARUSUARIO":
            return {...state,  mostrar_empresa: true,
                crear_empresa: false,
                editar_empresa:false,
                editar_usuario:false,
                id_empresa:-1,
                }

        case "EDITAR_EMPRSA":
            return {...state,  mostrar_empresa: false,
                editar_empresa:true,
                id_empresa:action.id_empresa ,
                }

        case "OCULTAR_EDITAREMPRESA":
        return {...state,  mostrar_empresa: true,
            editar_empresa:false,
            id_empresa:-1 ,
            }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
            }
    }

}

function AdminProvider({ children })
{
    var [state,dispatch]= React.useReducer( adminReducer,{
        mostrar_empresa: true,
        crear_empresa: false,
        editar_empresa:false,
        editar_usuario:false,
        id_usuario: -1,
        id_empresa:-1,
        empresa: [],
        permiso_crearEmp:false,

    });
/*
    var { user } = useUserState();
    useEffect(() =>{

        inicializar_datos(user.id,dispatch);
      },[]);
*/
    return (
        <AdminStateContext.Provider value={state}>
            <AdminDispatchContext.Provider value={dispatch}>
                {children}
            </AdminDispatchContext.Provider>
        </AdminStateContext.Provider>

    );
}

function useAdminState(){
    var context = React.useContext(AdminStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}

function useAdminDispatch(){
    var context = React.useContext(AdminDispatchContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}
export {AdminProvider,
        useAdminState,
        useAdminDispatch,
        MostrarCrear_empresa,
        OcultarCrear_empresa,
        Editar_usuario,
        inicializar_datos,
        crear_empresa,
        OcultarEditar_usuario ,
        Editar_empresa,
        Ocultar_Editarempresa,
        editar_empresa,
    }


const MostrarCrear_empresa =dispatch => {
    dispatch({
        type: "SHOW_CREATEEMPRESA",
    });
}

const OcultarCrear_empresa =dispatch => {
    dispatch({
        type: "OCULTAR_CREAREMPRESA",
    });
}

const Editar_usuario =(dispatch,id_empresa) => {
    dispatch({
        type: "EDITAR_USUARIO",id_empresa:id_empresa
    });
}

const OcultarEditar_usuario=dispatch => {
    dispatch({
        type: "OCULTAR_EDITARUSUARIO",
    });
}


const Editar_empresa =(dispatch,id_empresa) => {
    dispatch({
        type: "EDITAR_EMPRSA",id_empresa:id_empresa
    });
}

const Ocultar_Editarempresa=dispatch => {
    dispatch({
        type: "OCULTAR_EDITAREMPRESA",
    });
}

function editar_empresa(
    nombre,correo,telefono,nit,direccion,imagen,imagenDark,
    setGuaradoCorrecto,
    setProgressImagen,
    setProgressImagenDark,
    setIsLoading,
    setError,
    setErrordetail,
    id_empresa
)
{

    var fin_imagen=false;
    var fin_dark=false;
    setError(false);
    setIsLoading(true);

    db.ref("usuario_bot/"+id_empresa+"/informacion").update({
        correo:correo,
        direccion_facturacion:direccion,
        nit: nit ,
        nombre: nombre,
        telefono:telefono,
    },function(error) {
        if (error) {
          // The write failed...
          setGuaradoCorrecto(false);
          setError(true);
          setErrordetail("Error al guardar los datos de la empresa");
        } else {
          // Data saved successfully!
          //guardar imagenes
          if(imagen.length>0)
          {
            var extImagen=getFileExtension3(imagen[0].name);
            var guardarImagen=storage.ref('empresas/'+id_empresa+"/icono."+extImagen).put(imagen[0]);
            guardarImagen.on('state_changed', function(snapshot){
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgressImagen(progress);

            }, function(error) {
              fin_imagen=true;
              if(fin_imagen && fin_dark)
              {
                  setError(true);
                  setErrordetail("Erro al guardar las imagenes");
                  setIsLoading(false);
                  setGuaradoCorrecto(false);
              }

            }, function() {

              fin_imagen=true;
              if(fin_imagen && fin_dark)
              {
                  setIsLoading(false);
                  setGuaradoCorrecto(true);

              }
           });


          }else{
            fin_imagen=true;
          }

          if(imagenDark.length>0)
          {
            var extDark=getFileExtension3(imagenDark[0].name);
            var guardarDarck=storage.ref('empresas/'+id_empresa+"/dark."+extDark).put(imagenDark[0]);
            guardarDarck.on('state_changed', function(snapshot){
               // Observe state change events such as progress, pause, and resume
               // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
               var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgressImagenDark(progress);

             }, function(error) {
               console.log("error subiendo archivo");
               fin_dark=true;
               if(fin_imagen && fin_dark)
               {
                   setError(true);
                   setErrordetail("Erro al guardar las imagenes");
                   setIsLoading(false);
                   setGuaradoCorrecto(false);
               }
             }, function() {
               fin_dark=true;
               if(fin_imagen && fin_dark)
               {
                   setIsLoading(false);
                   setGuaradoCorrecto(true);

               }
            });
          }else{
            fin_dark=true;
          }

          if(fin_imagen && fin_dark)
          {
              setIsLoading(false);
              setGuaradoCorrecto(true);

          }










        }

      }
    );


}

function crear_empresa(
    nombre,correo,telefono,nit,direccion,imagen,imagenDark,
    setGuaradoCorrecto,
    setProgressImagen,
    setProgressImagenDark,
    setIsLoading,
    setError,
    setErrordetail,
    user,datos
    )
{
    var fin_imagen=false;
    var fin_dark=false;
    setError(false);
    setIsLoading(true);
    var newkey = db.ref().child('usuario_bot').push( {informacion:{
                                                        correo:correo,
                                                        direccion_facturacion:direccion,
                                                        id: 0,
                                                        nit: nit ,
                                                        nombre: nombre,
                                                        status: "activo",
                                                        telefono:telefono,
                                                        contadores: {admin:0,chat:0},
                                                        contrato:{ fin:"",
                                                                   inicio:"",
                                                                   usuarios_admin:0,
                                                                   usuarios_chat:0,
                                                                   interacciones:0,
                                                                   conteo_interacciones:0
                                                                }

                                                     }}).key;
    db.ref("usuario_bot/"+newkey+"/informacion").update({
        id: newkey,
    },function(error) {
        if (error) {
          // The write failed...
          console.log("Error al crear empresa");
          setGuaradoCorrecto(false);
          setError(true);
          setErrordetail("Ërror al guardar los datos de la empresa");
        } else {
          // Data saved successfully!
          db.ref("/empresas").once('value').then(function(snapshot){
                var lista  = snapshot.val();
                lista.push(newkey);
                db.ref("/").update({empresas:lista});
                var obj = {
                    fecha: + new Date(),
                    nombre: "crear empresa",
                    id_accion: 201,
                    id_usuario:user.id,
                    id_empresa:newkey
                }
                log_it(datos.id_empresa,user.id,obj)
          });
          //guardar imagenes
          var extImagen=getFileExtension3(imagen[0].name);
          var extDark=getFileExtension3(imagenDark[0].name);


          var guardarImagen=storage.ref('empresas/'+newkey+"/icono."+extImagen).put(imagen[0]);

          guardarImagen.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgressImagen(progress);

          }, function(error) {
            console.log("error subiendo archivo");
            fin_imagen=true;
            if(fin_imagen && fin_dark)
            {
                setError(true);
                setErrordetail("Erro al guardar las imagenes");
                setIsLoading(false);
                setGuaradoCorrecto(false);
            }

          }, function() {

            fin_imagen=true;
            if(fin_imagen && fin_dark)
            {
                setIsLoading(false);
                setGuaradoCorrecto(true);

            }
         });


         var guardarDarck=storage.ref('empresas/'+newkey+"/dark."+extDark).put(imagenDark[0]);
         guardarDarck.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
           setProgressImagenDark(progress);

          }, function(error) {
            console.log("error subiendo archivo");
            fin_dark=true;
            if(fin_imagen && fin_dark)
            {
                setError(true);
                setErrordetail("Erro al guardar las imagenes");
                setIsLoading(false);
                setGuaradoCorrecto(false);
            }
          }, function() {
            fin_dark=true;
            if(fin_imagen && fin_dark)
            {
                setIsLoading(false);
                setGuaradoCorrecto(true);

            }
         });




        }

      }
    );


    /*
    db.ref('/empresas/').once('value')
    .then(function(snapshot){
            var datos=snapshot.val();

            datos.sort((a,b)=>a-b);
            let nuevoID=datos[datos.length-1]+1;
            datos.push(nuevoID);
           return {id:nuevoID,arrreglo:datos};
    }).then(function(nuevoID){

        firebase.database().ref('empresas/').set({
            empresas: nuevoID.datos
          }, function(error) {
            if (error) {
               return false;
            } else {
              // Data saved successfully!
              return true;
            }
          }).then( function(valor){
            if(valor){
                //escribir datos usuario_bot
                firebase.database().ref('usuario_bot/').update({
                    empresas: nuevoID.datos
                  }, function(error) {
                    if (error) {
                       return false;
                    } else {
                      // Data saved successfully!
                      return true;
                    }
                  });
            }
          });
    });

  */



}

function inicializar_datos(data,dispatch)
{
    dispatch({type: "INICIAR_EMPRESAS"} );
    //console.log("data",data);

    //obtener lista de empresaas
 /*   db.ref('/usuarios/'+userID).once('value')
    .then(function(snapshot){
            var datos=snapshot.val();
            return datos;

    }).then(function(data){
   */
        //persmiso de empresas
     /*   db.ref('/roles/'+data.rol+"/empresas").once('value')
        .then(function(data){
            var permisos=data.val();


            if(permisos.indexOf("crear") > -1)
            {
                dispatch({type: "PERMISO_EMPRESAS",permiso:true});
            }
        });*/

        //permiso de empresas
        if(data.permisos.empresas.crear)
        {
            dispatch({type: "PERMISO_EMPRESAS",permiso:true});
        }


        var ruta="";

        if(data.admin){
            ruta='empresas/';
        }else{
            ruta='/usuario_bot/'+data.id_empresa+'/informacion/';
        }

        db.ref(ruta).once('value').then(function(snapshot) {

            var empresa=snapshot.val();

            if(data.admin)
            {
                return {codigo:1,arreglo:empresa};
            }else
            {

                return  {codigo:2,arreglo:empresa,id:data.id_empresa};
            }

        }).then( function (listado){

            if(listado.codigo===2)
            {
                var foto_emp="";
                //buscar la foto
                if(localStorage.getItem('theme')==='dark')
                {
                var id_empresa=listado.id+'/dark.png';
                }else{
                var id_empresa=listado.id+'/icono.png';
                }
                var storageRef=storage.ref('empresas/'+id_empresa).getDownloadURL().then(function(url) {
                dispatch({type: "INICIAR_DATOS",empresa:{id:listado.id,
                                                         nombre:listado.arreglo.nombre,
                                                         estatus:listado.arreglo.status,
                                                         foto:url,
                                                         usuarios_chat:listado.arreglo.contrato.usuarios_chat,
                                                         usuarios_admin:listado.arreglo.contrato.usuarios_admin,
                                                         contador_admin:listado.arreglo.contadores.admin,
                                                         contador_chat:listado.arreglo.contadores.chat,
                                                        } });

                }).catch(function(error) {
                console.log("ërror al descargar imagen de perfil");
                console.log(error);
                dispatch({type: "INICIAR_DATOS",empresa:{id:listado.id,
                                                         nombre:listado.arreglo.nombre,
                                                         estatus:listado.arreglo.status,
                                                         foto:"",
                                                         usuarios_chat:listado.arreglo.contrato.usuarios_chat,
                                                         usuarios_admin:listado.arreglo.contrato.usuarios_admin,
                                                         contador_admin:listado.arreglo.contadores.admin,
                                                         contador_chat:listado.arreglo.contadores.chat,
                                                        } });
                });



            }else{

                listado.arreglo.forEach(function(element)
                {
                     ///informacion de cada empresa
                     db.ref('/usuario_bot/'+element+'/informacion').once('value').then(function(emp){

                        return emp.val();
                     }).then(function(info){
                        if(info)
                        {
                             var foto_emp="";
                             //buscar la foto
                             if(localStorage.getItem('theme')==='dark')
                             {
                             var id_empresa=element+'/dark.png';
                             }else{
                             var id_empresa=element+'/icono.png';
                             }
                             var storageRef=storage.ref('empresas/'+id_empresa).getDownloadURL().then(function(url) {
                                dispatch({type: "INICIAR_DATOS",empresa:{id:element,
                                                                         nombre:info.nombre,
                                                                         estatus:info.status,
                                                                         foto:url,
                                                                         usuarios_chat:info.contrato.usuarios_chat,
                                                                         usuarios_admin:info.contrato.usuarios_admin,
                                                                         contador_admin:info.contador_admin,
                                                                         contador_chat:info.contador_chat,
                                                                        } });

                             }).catch(function(error) {
                                console.log("ërror al descargar imagen de perfil");
                                console.log(error);
                                dispatch({type: "INICIAR_DATOS",empresa:{id:element,
                                                                         nombre:info.nombre,
                                                                         estatus:info.status,
                                                                         foto:"",
                                                                         usuarios_chat:info.contrato.usuarios_chat,
                                                                         usuarios_admin:info.contrato.usuarios_admin,
                                                                         contador_admin:info.contadores.admin,
                                                                         contador_chat:info.contadores.chat,
                                                                        } });
                             });

                             //llenar las empresas
                             //lista_empresas.push({id:element,nombre:info.nombre,estatus:info.status,foto:""});



                        }
                     });
                });

            }


        });


  //  });



}

function getFileExtension3(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }
