import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { useUserState} from "../../../../context/UserContext";
import { useAdminBotDispatch,
    botActual,
    useAdminBotState,
    bloques
} from '../../contexto/AdminBotContext';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#dc004e",
    }
  }),
);


function PantillaBotonBotones(props){

    const classes = useStyles();
    var adminBotDispach=useAdminBotDispatch();
    var {bot_actual,bloques} =useAdminBotState();
    const [items,setItems]=React.useState([]);
    var { datos } = useUserState();
    /*variables*/
    const [enviar,setEnviar]=React.useState("0");
    const [tipo,setTipo] = React.useState("postback");
    const [titulo,setTitulo]= React.useState("");
    const [errorTitulo, setErrorTitulo] =React.useState(false);
    const [url,setUrl]=React.useState("");
    const [errorUrl,setErrorUrl]= React.useState(false);


      useEffect(() =>{
          if(bloques!==null)
          {
              let lista_items=[]
              bloques.forEach((element, index, array) => {
                  lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
              });
              setItems(lista_items);
              if(props.type==="postback")
              {
                  setEnviar(props.enviar_a===""?"0":props.enviar_a);
              }
          }
        },[bloques,props.enviar_a]);

        useEffect(() =>{
            setTipo(props.type);
            setTitulo(props.title);
            
            if(props.type==="web_url")
            {
                setUrl(props.url);
            }
          },[props.type,props.title,props.url]);


    const cambiarTipo=(event)=>{
        if(event.target.value!==undefined)
        {
            setTipo(event.target.value);
            props.editar((prevState) => {
              if(event.target.value==="postback"){
                prevState[props.id]=
                {
                  enviar_a:enviar,
                  payload:"boton",
                  title:titulo,
                  type:"postback",
                };
                return prevState;
              }else{
                prevState[props.id]=
                {
                  url:url,
                  title:titulo,
                  type:"web_url",
                };
                return prevState;
              }

           });
        }
    }
    const cambiarEnviar=(event)=>{
        if(event.target.value!==undefined)
        {
            setEnviar(event.target.value);
            props.editar((prevState) => {
              prevState[props.id].enviar_a=event.target.value==="0"?"":event.target.value;
              return prevState;
           });
        }
    }

    const cambiarTitulo=(event)=>{
      setTitulo(event.target.value);
      props.editar((prevState) => {
        prevState[props.id].title=event.target.value;
        return prevState;
     });
    }

    const cambiarUrl=(event)=>{
      setUrl(event.target.value);
      props.editar((prevState) => {
        prevState[props.id].url=event.target.value;
        return prevState;
     });
    }

    const eliminar=()=>{
        props.eliminar({id:props.id});
    }


    return(

        <Grid item xs={12} >
            <Grid spacing={2} container >


                <Grid item xs={12} sm={2}>
                <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined-quil"
                        value={tipo}
                        onClick={cambiarTipo}
                        label="Tipo"
                    >
                        <MenuItem value={"postback"}>Bloque</MenuItem>
                        <MenuItem value={"web_url"}>URL</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField
                        id="outlined-basic"
                        value={titulo}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        onChange={cambiarTitulo}
                        disabled={datos.permisos.abcbot.bloques!==true}
                        error={titulo.length===0 || titulo.length>20}
                        helperText={titulo.length===0?"El campo es obligatorio":
                                    titulo.length>20?"El titulo no puede ser mayor a 20 caracteres" : ""
                            }
                        />
                </Grid>

                {tipo==="web_url" &&
                <Grid item xs={12} sm={4}>
                    <TextField
                      id="outlined-basic"
                      value={url}
                      label="URL"
                      variant="outlined"
                      fullWidth
                      disabled={datos.permisos.abcbot.bloques!==true}
                      onChange={cambiarUrl}
                      error={url.length===0}
                      helperText={url.length===0?"El campo es obligatorio":""}
                      />
                </Grid>
                }

               {tipo==="postback" &&
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                        <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined-quil"
                            value={enviar}
                            onClick={cambiarEnviar}
                            label="Enviar"
                        >
                            <MenuItem value="0">
                                <em>None</em>
                            </MenuItem>
                            {items}
                        </Select>
                    </FormControl>
                </Grid>}

              {datos.permisos.abcbot.bloques===true  &&
                <Grid item xs={6} sm={2}>
                  <Tooltip title="Borrar">
                    <IconButton
                          aria-label="delete"
                          className={classes.colorDelete}
                          onClick={eliminar}
                          >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>}

            </Grid>
        </Grid>
);


}

export default PantillaBotonBotones;
