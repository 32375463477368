import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReplyIcon from '@material-ui/icons/Reply';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Typography } from "../../../../../components/Wrappers";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteElementos from '../../DeleteElementos';
import QuickReplies from "./../quick_replies"
import CircularProgress from '@material-ui/core/CircularProgress';
import { useUserState} from "../../../../../context/UserContext";
import {db} from "../../../../../services/firebase";
import { useAdminBotDispatch,
    botActual,
    useAdminBotState,
    bloques
} from '../../../contexto/AdminBotContext';
import {useSnackbar } from 'notistack';
import DialogElement from './../dialogElement';

import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites,
  mensajeTextoContenidoErrores,
  validarTextoMensajeErrorEscrito,
  mensajeTextoErrorErrores,

  maximaCantidadCaracteresEnBotones,
  validarTextoBotonesContenidoEscrito,
  mensajeTextoBotonErrores,

  validarTextoMensajeReplayError
} from './../validaciones'
import {
  traducirSaltosDeLinea_A_DobleBackslash,
  traducirDobleBackslash_A_SaltosDeLinea
} from './utilidades'

import log_it from './../../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    colorDivider:{
      backgroundColor: theme.palette.primary.main,
    }
  }),
);

export function Whatsapp_Menu(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    const { enqueueSnackbar } = useSnackbar();
    var { datos, user } = useUserState();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [items,setItems]=React.useState([]);
    const [cargando,setCargando] =React.useState(false);
    const [errorNuevoReplay, setErrorNuevoReplay]= React.useState(false);
    const [updateList, setUpdateList]= React.useState(true);

    /*variables de datos*/
    const [atributos,setAtributos]= React.useState("");
    const [enviar_a,setEnviar_a]= React.useState("");
    const [ia,setIA]=React.useState(true);
    const [intentos,setIntentos]=React.useState(0);
    const [mensaje,setMensaje]=React.useState("")
    const [mensajeError,setMensajeError]=React.useState("");
    const [url,setUrl]=React.useState("");

    const [listaReplies,setListaReplies]= React.useState([]);
    const [arregloReplies,setArregloReplies]= React.useState([]);

    /*Variables de nuevo replays*/
    const [nuevoRepliesNombre,setNuevoRepliesNombre] = React.useState("");
    const [enviar,setEnviar]=React.useState("0");

    const [errorReplay,setErrorReplay] = React.useState(false);

    const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);
    const [mensajeErrorCodigoValidacion,setMensajeErrorCodigoValidacion]=React.useState(0);
    const [mensajeCodigoValidacionBoton,setMensajeCodigoValidacionBoton]=React.useState(-1);


    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);

    /*Actualizar lista de replays*/
    function ActualizarReplays(listaArray){
  //  useEffect(() =>{
      let lista=[]
        listaArray.forEach((element, index, array) =>{
            lista.push(
            <QuickReplies key={"replies_"+index}
              id={index}
              title={element.title}
              enviar_a={element.enviar_a}
              maxIndex={listaArray.length}
              deshabilitadoAbajo={index==listaArray.length-1}
              deshabilitadoArriba={index==0}
              error={setErrorReplay}
              eliminar={EliminarReplay}
              mover={MoverReplay}
              editar={setArregloReplies}
            />
            );
        });
        setListaReplies(lista);
      /*  document.getElementById("element_txtrapido_"+props.id_bloque+"_"+props.id_elemento)
        .scroll({ top: document.getElementById("element_txtrapido_"+props.id_bloque+"_"+props.id_elemento).scrollHeight, behavior: 'auto' });*/
    }//,[arregloReplies,updateList]);;

    /*inicializar vaiables*/
    useEffect(() =>{
        if(props.datos)
        {
          var msj = traducirDobleBackslash_A_SaltosDeLinea(props.datos.mensaje)
          var msj_err = traducirDobleBackslash_A_SaltosDeLinea(props.datos.mensaje_error)
          var patch_enviar_a = props.datos.enviar_a
          if(props.datos.enviar_a===undefined || props.datos.enviar_a==""){
            patch_enviar_a="0"
          }
          setAtributos(props.datos.atributo);
          setEnviar_a(patch_enviar_a);
          setIA(props.datos.ia);
          setIntentos(props.datos.intento);
          setMensaje(traducirDobleBackslash_A_SaltosDeLinea(props.datos.mensaje));
          setMensajeError(traducirDobleBackslash_A_SaltosDeLinea(props.datos.mensaje_error));
          setUrl(props.datos.url);
          var codigoValidacion = validarTextoMensajeReplayError(msj_err,bot_actual.conexion,ia)
          setMensajeErrorCodigoValidacion(codigoValidacion)

            let lista=[]
            props.datos.opciones.forEach((element, index, array) =>{
                lista.push(
                {
                  enviar_a: element.enviar_a,
                  title: element.texto,
                }
                );
            });
            setArregloReplies(lista);
            ActualizarReplays(lista);
        }

    },[open]);

    /*Llenar lista de enviar a */
    useEffect(() =>{
        if(bloques!==null)
        {
            let lista_items=[]
            bloques.forEach((element, index, array) => {
                lista_items.push(  <MenuItem key={"item"+props.id_elemento+"_"+index} value={element}>{element}</MenuItem>);
            });
            setItems(lista_items);
        }
    },[bloques]);

    /*--------------------------------------Replays----------------------------------------------------*/
    const AgregaNuevoReplay=()=>{
        setErrorNuevoReplay(false);
      if(nuevoRepliesNombre.length===0)
      {
          setErrorNuevoReplay(true);
      }else{
        setArregloReplies((prevState) => {
          prevState.push({
                          enviar_a:enviar,
                          title: nuevoRepliesNombre,
                        });
          let lista=[]
          prevState.forEach((element, index, array) =>{
              lista.push(
              <QuickReplies key={"replies_"+index}
                id={index}
                title={element.title}
                enviar_a={element.enviar_a}
                maxIndex={prevState.length}
                deshabilitadoAbajo={index==prevState.length-1}
                deshabilitadoArriba={index==0}
                error={setErrorReplay}
                eliminar={EliminarReplay}
                mover={MoverReplay}
                editar={setArregloReplies}
              />);
            });
          setListaReplies(lista);
          return prevState;
       });
       setNuevoRepliesNombre("");
       setEnviar("");
       enqueueSnackbar('Agregado correctamente', {variant: 'success'});
      }
    }

    const EliminarReplay=(datos)=>{
        setArregloReplies((prevState) => {
          prevState.splice(datos.id,1);
          let lista=[]
            prevState.forEach((element, index, array) =>{
                lista.push(
                <QuickReplies key={"replies_"+index}
                  id={index}
                  title={element.title}
                  maxIndex={prevState.length}
                  deshabilitadoAbajo={index==prevState.length-1}
                  deshabilitadoArriba={index==0}
                  enviar_a={element.enviar_a}
                  error={setErrorReplay}
                  eliminar={EliminarReplay}
                  mover={MoverReplay}
                  editar={setArregloReplies}
                />
                );
            });
            setListaReplies(lista);
            enqueueSnackbar('Eliminado correctamente', {variant: 'success'});
          return prevState;
        });
  }

  const MoverReplay=(indexReplay,arriba)=>{
    setArregloReplies(
      (prevState) => {
        var lista = prevState
        var elegido = lista.slice(indexReplay,indexReplay+1)
        var elegido = lista.splice(indexReplay,1)
        if(arriba){
          prevState.splice(indexReplay-1,0,elegido[0])
        }else{
          prevState.splice(indexReplay+1,0,elegido[0])
        }

        let listaNueva=[]
        lista.forEach((element, index, array) =>{
          listaNueva.push(
            <QuickReplies key={"replies_"+index}
                title={element.title}
                enviar_a={element.enviar_a}
                id={index}
                maxIndex={lista.length}
                deshabilitadoAbajo={index==lista.length-1}
                deshabilitadoArriba={index==0}
                error={setErrorReplay}
                eliminar={EliminarReplay}
                mover={MoverReplay}
                editar={setArregloReplies}
            />
            );
        });
        setListaReplies(listaNueva);
      return prevState;
      }
    );
  }

/*------------------------------------------------------------------------------*/
const cambiarBloqueEnviarA=(event)=>{
  if(event.target.value!==undefined)
  {
      setEnviar_a(event.target.value);
  }
}
  const cambiarEnviar=(event)=>{
        if(event.target.value!==undefined)
        {
            setEnviar(event.target.value);
        }
    }
    const cambiarIA=(event)=>{
      var codigoValidacion = validarTextoMensajeReplayError(event,traducirDobleBackslash_A_SaltosDeLinea(mensajeError),bot_actual.conexion,event.target.checked)
      setMensajeErrorCodigoValidacion(codigoValidacion)

      setIA(event.target.checked)
    }


    /*Cerrar Modal*/
    const handleClickOpen = () => {
      setOpen(true);
      setListaReplies([]);
    };
    /*Abrir Modal*/
    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };

    /*Guardar Cambios*/
    const GuardarCambios=()=>{
      var temp_intentos = 0

      temp_intentos = parseInt(intentos)
      if(isNaN(temp_intentos)){
        temp_intentos=0
      }

      var mod_mensaje = traducirSaltosDeLinea_A_DobleBackslash(mensaje)
      var mod_mensajeError = traducirSaltosDeLinea_A_DobleBackslash(mensajeError)
      var mod_arregloReplies = []
      var patch_enviar_a = enviar_a
      if(enviar_a===undefined || enviar_a=="0"){
        patch_enviar_a=""
      }
      arregloReplies.forEach((value,index,array)=>{
        var opcion = {
          texto:value.title,
          enviar_a:value.enviar_a
        }
        mod_arregloReplies.push(opcion)
      })


      if(arregloReplies<=0)
      {
            enqueueSnackbar('Debe de exisite almenos una opción', {variant: 'error'});
      }else{
        var mod_opciones = ""
        setCargando(true);
        if(props.id_elemento===-1){

          setCargando(false);
          setOpen(false);
          enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
          props.GuardarNuevoElemento({
            atributo:atributos,
            elemento: "menu",
            enviar_a:patch_enviar_a,
            ia: ia,
            intento: temp_intentos,
            mensaje: mod_mensaje,
            mensaje_error:mod_mensajeError,
            opciones:mod_arregloReplies,
            url:url,
          },props.new_index);

        }else{

            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
            .update({
              atributo:atributos,
              elemento: "menu",
              enviar_a:patch_enviar_a,
              ia: ia,
              intento: temp_intentos,
              mensaje: mod_mensaje,
              mensaje_error:mod_mensajeError,
              opciones:mod_arregloReplies,
              url:url,
            },function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                    setCargando(false);
                    enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                    setOpen(false);
                    var obj = {
                      fecha: + new Date(),
                      nombre: "modificar elemento",
                      id_accion: 132,
                      id_bot: bot_actual.id,
                      red_social: bot_actual.conexion,
                      bloque:props.id_bloque,
                      elemento:"menu",
                    }
                    log_it(datos.id_empresa,user.id,obj)
                }
            });

        }


      }

    }


    return(
        <React.Fragment>

        <DialogElement
          id_elemento={props.id_elemento}
          moverElementos={props.moverElementos}
          moverMasuno={props.MoverMasuno}
          moverMenosuno={props.MoverMenosuno}
          icono={<ReplyIcon/>}
          metodoAbrir={handleClickOpen}
          nombre={props.nombre}
          tamanio={props.tamanio}
          dialogOpen={open}
          dialogClose={handleClose}
          cargando={cargando}
          disabledSave={mensaje.length === 0 || (ia && mensajeError.length===0) ||  intentos<0 ||  (ia && intentos===0) || errorReplay  || (atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos)))}
          guardar={GuardarCambios}
          eliminar={props.eliminar}
          cerrar={setOpen}
        >


                        <Grid item xs={12}>
                            <TextField id="ElTexto_mensaje"
                                       value={mensaje}
                                      //  onChange={e => setMensaje(e.target.value)}
                                      onChange={
                                        (e)=>{
                                          /*Para hacerlo mas legible*/
                                          var text = e.target.value
                                          var codigoValidacion = validarTextoMensajeContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                          setMensajeCodigoValidacion(codigoValidacion)
                                          if(codigoValidacion<1){
                                            setMensaje(text)
                                          }
                                        }
                                      }
                                      inputProps={{
                                        maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                      }}
                                      label="Mensaje"
                                       variant="outlined"
                                       fullWidth
                                       multiline
                                      //  error={ mensaje.length === 0}
                                      error={ mensajeCodigoValidacion != -1}
                                       disabled={datos.permisos.abcbot.bloques!==true}
                                      //  helperText={mensaje.length === 0?"El campo es obligatorio":""}
                                      helperText={mensajeTextoContenidoErrores(mensajeCodigoValidacion,bot_actual.conexion)}

                                      />
                        </Grid>


                        <Grid item xs={12}>
                            <Grid spacing={2} container >

                                <Grid item xs={12} sm={2}>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch color="primary" />}
                                        label="IA"
                                        labelPlacement="top"
                                        checked={ia}
                                        onChange={cambiarIA}
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="outlined-basic"
                                        value={intentos}
                                        onChange={e => setIntentos(e.target.value)}
                                        label="Intentos"
                                        type={"number"}
                                        variant="outlined"
                                        fullWidth
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                        error={(ia && intentos<=0) || intentos<0 }
                                        helperText={ ia && intentos<=0? "El campo es obligatorio si la IA esta activa":
                                                  intentos<0?"El valor debe ser positivo":"" }
                                        />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                                    <InputLabel id="demo-simple-select-outlined-label">Enviar a</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined-quil"
                                        value={enviar_a}
                                        onClick={cambiarBloqueEnviarA}
                                        label="Enviar a"
                                    >
                                        <MenuItem value="0">
                                            <em>None</em>
                                        </MenuItem>
                                        {items}
                                    </Select>
                                  </FormControl>
                              </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid spacing={2} container >
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="outlined-basic"
                                        value={mensajeError}
                                        multiline
                                        // onChange={e => setMensajeError(e.target.value)}
                                        onChange={
                                          (e)=>{
                                            /*Para hacerlo mas legible*/
                                            var adaptedIaValue = ia
                                            var codigoValidacion = validarTextoMensajeReplayError(e,e.target.value,bot_actual.conexion,adaptedIaValue)
                                            setMensajeErrorCodigoValidacion(codigoValidacion)
                                            if(codigoValidacion<1){
                                              setMensajeError(e.target.value)
                                            }
                                          }
                                        }
                                        label="Mensaje de error" variant="outlined"
                                        fullWidth
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                        // error={ia && mensajeError.length===0}
                                        // helperText={ ia && mensajeError.length===0?"El campo es obligatorio si la IA esta activa":""}
                                        inputProps={{
                                          maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                         }}
                                         error={
                                          (ia && mensajeError.length===0) ||
                                          (mensajeErrorCodigoValidacion!=-1 )

                                          }
                                         helperText={
                                              mensajeTextoErrorErrores(mensajeErrorCodigoValidacion,bot_actual.conexion)
                                        }

                                         />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="outlined-basic"
                                        value={atributos}
                                        onChange={e => setAtributos(e.target.value)}
                                        label="Atributos"
                                        variant="outlined"
                                        fullWidth
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                        error={atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos))}
                                        helperText={ atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos)) &&
                                        "El atributo solo pude contener numeros, letras y guiones"}
                                      />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-basic-url"
                                        value={url}
                                        onChange={e => setUrl(e.target.value)}
                                        label="URL"
                                        variant="outlined"
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                        fullWidth
                                      />
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography>Opciones</Typography>
                        </Grid>

      { datos.permisos.abcbot.bloques===true  &&
                    <Grid item xs={12}>
                            <Grid spacing={2} container >
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        id="outlined-basic"
                                        value={nuevoRepliesNombre}
                                        // onChange={e => setNuevoRepliesNombre(e.target.value)}
                                        onChange={
                                          e=>{
                                            /*Para hacerlo mas legible*/
                                            var codigoValidacion = validarTextoBotonesContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                            setMensajeCodigoValidacionBoton(codigoValidacion)
                                            if(codigoValidacion<1){
                                              setNuevoRepliesNombre(e.target.value)
                                            }
                                          }
                                        }
                                        label="Texto"
                                        variant="outlined"
                                        fullWidth
                                        // error={errorNuevoReplay}
                                        // helperText={errorNuevoReplay?"El campo es obligatorio":""}
                                        error={ (mensajeCodigoValidacionBoton != -1) || errorNuevoReplay}
                                        helperText={mensajeTextoBotonErrores(mensajeCodigoValidacionBoton)}

                                        inputProps={{
                                          maxLength:maximaCantidadCaracteresEnBotones
                                        }}

                                        />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <FormControl variant="outlined" className={classes.formControl}>
                                      <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                                      <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined-quil"
                                          value={enviar}
                                          onClick={cambiarEnviar}
                                          label="Enviar"
                                      >
                                          <MenuItem value="0">
                                              <em>None</em>
                                          </MenuItem>
                                          {items}
                                      </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<AddBoxIcon/>}
                                    onClick={AgregaNuevoReplay}
                                >
                                    Agregar
                                </Button>
                                </Grid>
                            </Grid>
                        </Grid>}

                        <Grid item xs={12}>
                            <Divider variant="middle" className={classes.colorDivider}/>
                        </Grid>
                        {listaReplies}
                </DialogElement>
        </React.Fragment>
       );

}
