import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {Grid} from "@material-ui/core";
import { useUserState} from "../../../../../context/UserContext";

import Catalogo from './catalogo'

export default function ListaCatalogo(props)
{
  var { datos,user } = useUserState();
  const [catalogo,setCatalogo] = React.useState([]);
  const [actualizar,setActualizar] =React.useState(false);

  useEffect(() =>{
      if(props.arreglo)
      {
        let lista=[];
        props.arreglo.forEach((item, i) => {
          lista.push(<Catalogo
                      lista={item===null|| item===undefined?[]:item}
                      editar={props.editar}
                      index={i}
                      eliminar={Eliminar}
                      key={props.keyword+props.bloque+"_"+props.elemento+"-"+i}
                      />)
        });
        setCatalogo(lista);
      }
    },[props.arreglo,actualizar]);

  const AgregarCatalogo=()=>{
    props.editar((prevState)=>{
      prevState.push([]);
      return prevState;
    })
    setActualizar(actualizar?false:true);
  }

  const Eliminar=(datos)=>{
    props.editar((prevState) => {
      prevState.splice(datos.id,1);
      return prevState;
    });
    setActualizar(actualizar?false:true);
  }

  return(
  <Grid item xs={12} sm={12}>
    <Grid spacing={3} container>

        {datos.permisos.abcbot.bloques===true &&
          <Grid item xs={12} sm={12}>
          <Button
              variant="contained"
              color="primary"
              endIcon={<AddBoxIcon/>}
              onClick={AgregarCatalogo}
          >
              Agregar Catalogo
          </Button>
        </Grid>}

      {catalogo}

    </Grid>
  </Grid>
  )

}
