import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../contexto/AdminBotContext'
import { useUserState} from "../../../context/UserContext";
import {db,storage} from "../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { DropzoneArea } from 'material-ui-dropzone';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import TelegramIcon from '@material-ui/icons/Telegram';
import Checkbox from '@material-ui/core/Checkbox';
import Edit from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import imagenAvi from '../../../images/original.png'
import LanguageIcon from '@material-ui/icons/Language';
import log_it from './../../../services/log/log';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",
    },
    textoDelete:{
        color:"#dc004e",
    },
    colorUnselect:{
      color:"#a8a8a8",
      fontSize:theme.spacing(4),
    },
    colorFacebook:{
      color:"#395692",
      fontSize:theme.spacing(4),
    },
    colorWhatsapp:{
      color:"#42be52",
      fontSize:theme.spacing(4),
    },
    colorInstagram:{
      color:"#FAFAFA",
      borderRadius: '8px',
      background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
      fontSize:theme.spacing(4),
    },
    colorTelegram:{
      color:"#32a7d9",
      fontSize:theme.spacing(4),
    },
    alinearDerecha:{
      textAlign: 'right'
    },
    iconoEditar:{
      color:"#5dade2"
    },
    imagenes: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      display:'block',
      margin:'auto',
    },

  }),
);


export default function NuevoAsistente(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);


    /*variables de datos*/
    const [nombre,setNombre]=React.useState("")
    const [facebook,setFacebook] = React.useState(false);
    const [whatsapp,setWhatsapp] = React.useState(false);
    const [instagram,setInstagram ] = React.useState(false);
    const [telegram,setTelegram ] = React.useState(false);
    const [webchat,setWebchat] = React.useState(false);

    const [fidelizacion,setFidelizacion] = React.useState(false);
    const [idioma, setIdioma] = React.useState("es");
    const [url,setUrl] = React.useState("");

    const [progress,setProgress]= React.useState(0);
    const [iamgen, setImagen] = React.useState([]);
    const [guardarImg,setGuardarImg] = React.useState(false);
    const [avatar,setAvatar]=React.useState("");

    const [tipo,setTipo]=React.useState("default");
    var conexionesOriginales = {}

    /*inicializar vaiables*/
      useEffect(() =>{
        if(props.index!==-1)
        {

          setNombre(props.datos.nombre);
          setFacebook(props.datos.conexiones.facebook===undefined?false:props.datos.conexiones.facebook);
          setWhatsapp(props.datos.conexiones.whatsapp===undefined?false:props.datos.conexiones.whatsapp);
          setInstagram(props.datos.conexiones.instagram===undefined?false:props.datos.conexiones.instagram);
          setTelegram(props.datos.conexiones.telegram===undefined?false:props.datos.conexiones.telegram);
          setWebchat(props.datos.conexiones.webchat===undefined ? false : props.datos.conexiones.webchat)
          setFidelizacion(props.datos.fidelizacion===undefined?false:props.datos.fidelizacion);
          conexionesOriginales = {
            facebook:props.datos.conexiones.facebook,
            telegram:props.datos.conexiones.telegram,
            whatsapp:props.datos.conexiones.whatsapp,
            instagram:props.datos.conexiones.instagram,
            webchat:props.datos.conexiones.webchat
          }
          setIdioma(props.datos.idioma);
          if(props.datos.tipo_imagen==="url")
          {
              setUrl(props.datos.url);
              setAvatar(props.datos.url)
              setTipo("url");
          }else if(props.datos.tipo_imagen==="imagen"){
              setUrl(props.datos.url);
              setAvatar(props.datos.url);
              setTipo("imagen");
          }else{
            setTipo("default");
              setAvatar(imagenAvi);
          }


        }
      },[open]);



    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
      //console.log(datos)
      //console.log(user)
      setCargando(true);
    if(props.index!==-1)
    {
      var obj = {
        fecha: + new Date(),
        nombre: "editar asistente",
        id_accion: 102,
        id_bot: props.index,
      }
        if(tipo==="url")
        {
          GuardarURL({
            conexiones:{facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat},
            fidelizacion:fidelizacion,
            idioma:idioma,
            nombre:nombre,
            tipo_imagen:"url",
            url:url
          });

        }else if(tipo==="imagen"){

            if(iamgen.length===0)
            {
              GuardarURL({
                conexiones:{facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat},
                fidelizacion:fidelizacion,
                idioma:idioma,
                nombre:nombre,
                tipo_imagen:"imagen",
                url:url
              });

            }else{
            GuardarImagen();
          }

        }else{
            var conexionesActivas = {facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat};
            GuardarURL({
              conexiones:conexionesActivas,
              fidelizacion:fidelizacion,
              idioma:idioma,
              nombre:nombre,
              tipo_imagen:"default",
            });
        }


        log_it(datos.id_empresa,user.id,obj)

    }else{

          if(tipo==="url")
          {
            let arrayBots = JSON.parse(JSON.stringify(props.arrayBots));
            if(arrayBots.length===0){arrayBots.push(null)}
            arrayBots.push({
              conexiones:{facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat},
              fidelizacion:fidelizacion,
              idioma:idioma,
              nombre:nombre,
              tipo_imagen:"url",
              url:url
            });
            GuardarNuevoURL(arrayBots);

          }else if(tipo==="imagen")
          {
            GuardarNuevaImagen();

          }else{

            let arrayBots = JSON.parse(JSON.stringify(props.arrayBots));
            if(arrayBots.length===0){arrayBots.push(null)}

            arrayBots.push({
              conexiones:{facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat},
              fidelizacion:fidelizacion,
              idioma:idioma,
              nombre:nombre,
              tipo_imagen:"default",
            });
            GuardarNuevoURL(arrayBots);

          }
          let arrayBots = JSON.parse(JSON.stringify(props.arrayBots));
          var obj = {
            fecha: + new Date(),
            nombre: "crear asistente",
            id_accion: 101,
            id_bot: arrayBots.length,
          }
          log_it(datos.id_empresa,user.id,obj)

    }
  }

    function getFileExtension3(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
      }


    function GuardarNuevoURL(arrayBots){

      db.ref("/bots/"+datos.id_empresa+"/")
      .update(arrayBots,function(error) {
          if (error) {

              setCargando(false);
              enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
          } else {
            db.ref("/bloques/"+datos.id_empresa+"/facebook/"+(arrayBots.length-1))
            .update({default:{is_default:true}});

            db.ref("/bloques/"+datos.id_empresa+"/whatsapp/"+(arrayBots.length-1))
            .update({default:{is_default:true}});

            db.ref("/bloques/"+datos.id_empresa+"/instagram/"+(arrayBots.length-1))
            .update({default:{is_default:true}});

            db.ref("/bloques/"+datos.id_empresa+"/webchat/"+(arrayBots.length-1))
            .update({default:{default:true}});

            setCargando(false);
              enqueueSnackbar('Guardado exitoso', {variant: 'success'});
              inicializarDatos();
          }
      });
    }


    function  GuardarURL(info)
    {
      db.ref("/bots/"+datos.id_empresa+"/"+props.index)
      .update(info,function(error) {
          if (error) {
              setCargando(false);
              enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
          } else {
              setCargando(false);
              enqueueSnackbar('Guardado exitoso', {variant: 'success'});
              inicializarDatos();
          }
      });
    }

    function GuardarImagen()
    {
          setGuardarImg(true);
          var extImagen=getFileExtension3(iamgen[0].name);
          var guardarImagen=storage.ref('empresas/'+datos.id_empresa+"/bots/"+props.index+"."+extImagen).put(iamgen[0]);

          guardarImagen.on('state_changed', function(snapshot){
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(progress);

          }, function(error) {
              setGuardarImg(false);
              setCargando(false);
              enqueueSnackbar('Error al subir la imagen', {variant: 'error'});
          }, function() {
              setGuardarImg(false);
                guardarImagen.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  db.ref("/bots/"+datos.id_empresa+"/"+props.index)
                  .update({
                    conexiones:{facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat},
                    fidelizacion:fidelizacion,
                    idioma:idioma,
                    nombre:nombre,
                    tipo_imagen:"imagen",
                    url:downloadURL
                  },function(error) {
                      if (error) {
                          setCargando(false);
                          enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                      } else {
                          setCargando(false);
                          enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                          inicializarDatos();
                      }
                  });

                });
              });

    }


    function GuardarNuevaImagen()
    {
          setGuardarImg(true);
          var extImagen=getFileExtension3(iamgen[0].name);
          var guardarImagen=storage.ref('empresas/'+datos.id_empresa+"/bots/"+props.index+"."+extImagen).put(iamgen[0]);

          guardarImagen.on('state_changed', function(snapshot){
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(progress);

          }, function(error) {
              setGuardarImg(false);
              setCargando(false);
              enqueueSnackbar('Error al subir la imagen', {variant: 'error'});
          }, function() {
              setGuardarImg(false);
                guardarImagen.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  let arrayBots = JSON.parse(JSON.stringify(props.arrayBots));
                  if(arrayBots.length===0){arrayBots.push(null)}
                  arrayBots.push({
                    conexiones:{facebook:facebook,telegram:telegram,whatsapp:whatsapp,instagram:instagram,webchat:webchat},
                    fidelizacion:fidelizacion,
                    idioma:idioma,
                    nombre:nombre,
                    tipo_imagen:"imagen",
                    url:downloadURL
                  });
                  GuardarNuevoURL(arrayBots);
                });
              });

    }

    function inicializarDatos(){
      setNombre("");
      setFacebook(false);
      setWhatsapp(false);
      setInstagram(false);
      setTelegram(false);
      setFidelizacion(false);
      setIdioma('es');
      setUrl("");
      setAvatar("");
      setTipo("default");
      setImagen([]);
      setOpen(false);


    }

    /*Borrar elemento */
    const EliminarElemento=()=>{

        setOpen(false);
        props.eliminar(props.id_elemento);
    }

    const NuevoMensaje=()=>{
      enqueueSnackbar('Guardado exitoso', {variant: 'warning'});
    }

    return(
        <React.Fragment>
          {props.index===-1?
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<PersonAddIcon />}
                onClick={handleClickOpen}
            >
                Nuevo Asistente
            </Button>
            :
            <Typography className={classes.alinearDerecha} >
                <IconButton aria-label="Editar"   onClick={handleClickOpen}>
                    <Typography>
                        Editar
                    </Typography>
                    <Edit className={classes.iconoEditar}/>
                </IconButton>
            </Typography>

          }

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                {props.index===-1 ?
                  <DialogTitle id="form-dialog-title">Nuevo Asistente<PersonAddIcon/></DialogTitle>
                  :
                  <DialogTitle id="form-dialog-title">{"Editar asistente:"+props.datos.nombre} <EditIcon /> </DialogTitle>
                }
                <DialogContent dividers>


                <Grid spacing={3} container >


                <Grid item xs={12}>
                    <TextField id="ElTexto_mensaje"
                               value={nombre}
                               onChange={e => setNombre(e.target.value)}
                               label="Nombre"
                               variant="outlined"
                               fullWidth
                               disabled={datos.permisos.abcbot.bot!==true}
                               error={nombre.length==0}
                               helperText={nombre.length==0?"Campo obligatorio":""}
                               />
                </Grid>

                <Grid item xs={12}>
                    <Grid spacing={2} container >

                        <Grid item xs={12} sm={4}>
                          <FormControlLabel
                            control={<Switch checked={fidelizacion} disabled={datos.permisos.abcbot.bot!==true} name="checkedA"  onChange={e=>setFidelizacion(e.target.checked)} />}
                            label="Fidelización"/>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            <FormControl variant="outlined" className={classes.formControl}  >
                            <InputLabel id="demo-simple-select-outlined-label">Idioma</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={idioma}
                                disabled={datos.permisos.abcbot.bot!==true}
                                onChange={e=>setIdioma(e.target.value)}
                                label="Idioma"
                            >
                                <MenuItem value={"es"}>Español</MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Conexiones</Typography>
                </Grid>


                <Grid item xs={12}>
                    <Grid spacing={2} container >

                        <Grid item xs={12} sm={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox  disabled={datos.permisos.abcbot.bot!==true} checked={facebook} onChange={e=>setFacebook(e.target.checked)} icon={<FacebookIcon className={classes.colorUnselect} />}
                                    checkedIcon={<FacebookIcon className={classes.colorFacebook}  />}
                                name="Facebook"
                                />}
                                label="Facebook"
                              />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={<Checkbox disabled={datos.permisos.abcbot.bot!==true} checked={whatsapp} onChange={e=>setWhatsapp(e.target.checked)} icon={<WhatsAppIcon className={classes.colorUnselect} />} checkedIcon={<WhatsAppIcon className={classes.colorWhatsapp}  />} name="Whatsapp" />}
                                label="Whatsapp"
                              />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={<Checkbox disabled={datos.permisos.abcbot.bot!==true} checked={instagram} onChange={e=>setInstagram(e.target.checked)} icon={<InstagramIcon className={classes.colorUnselect} />} checkedIcon={<InstagramIcon className={classes.colorInstagram}  />} name="Instagram" />}
                                label="Instagram"
                              />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <FormControlLabel
                                control={<Checkbox disabled={datos.permisos.abcbot.bot!==true} checked={telegram} onChange={e=>setTelegram(e.target.checked)} icon={<TelegramIcon className={classes.colorUnselect} />} checkedIcon={<TelegramIcon className={classes.colorTelegram}  />} name="Telegram" />}
                                label="Telegram"
                              />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControlLabel
                                control={<Checkbox disabled={datos.permisos.abcbot.bot!==true} checked={webchat} onChange={e=>setWebchat(e.target.checked)} icon={<LanguageIcon className={classes.colorUnselect} />} checkedIcon={<LanguageIcon className={classes.colorWeb}  />} name="Webchat" />}
                                label="Webchat"
                              />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography>Imagen</Typography>
                </Grid>

                {guardarImg &&
                                  <Grid item xs={12}>
                                    <Typography >
                                        Guardando Imagen
                                    </Typography>
                                    <LinearProgress variant="determinate" value={progress} />
                                </Grid>
                    }

                {props.index!==-1 &&
                  <Grid item xs={12}>
                    <Grid pacing={2} container>
                      <Grid item xs={12} sm={4}>
                        <Avatar
                        alt="Avatar"
                        variant="square"
                        src={avatar}
                        classes={{ root: classes.imagenes }}
                        />
                        </Grid>
                    </Grid>
                </Grid>}

            {  datos.permisos.abcbot.bot &&
                <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" className={classes.formControl}  >
                    <InputLabel id="demo-simple-select-outlined-label">Tipo imagen</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipo}
                        onClick={e=> {
                          if(e.target.value!==null && e.target.value!==undefined ){
                            setTipo(e.target.value)
                          }
                        }}
                        label="Validacion"
                    >
                        <MenuItem value={"default"}>Default</MenuItem>
                        <MenuItem value={"url"}>URL</MenuItem>
                        <MenuItem value={"imagen"}>Subir imagen</MenuItem>

                    </Select>
                    </FormControl>
                </Grid>}

              {tipo==="url" &&
                <Grid item xs={12}>
                    <TextField id="ElTexto_mensaje"
                               value={url}
                               onChange={e => setUrl(e.target.value)}
                               label="URL imagen"
                               variant="outlined"
                               fullWidth
                               disabled={datos.permisos.abcbot.bot!==true}
                               />
                </Grid>
              }

              { tipo==="imagen" &&
                 <Grid item xs={12} md={6}>
                    <DropzoneArea
                        maxFileSize={300000}
                        filesLimit={1}
                        acceptedFiles={['image/png']}
                        dropzoneText={"Seleccione una imagen para el bot"}
                        value={iamgen}
                        onChange={(files) => setImagen(files)}
                        disabled={datos.permisos.abcbot.bot!==true}
                    />
                </Grid>}



                </Grid>
                </DialogContent>
               {cargando ?
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                : <DialogActions>
                    <Button onClick={GuardarCambios} color="secondary"
                        disabled={nombre.length===0 || datos.permisos.abcbot.bot!==true}
                    >
                        Guardar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>}

            </Dialog>

        </React.Fragment>
       );

}
