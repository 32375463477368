import React , { useState, useEffect } from 'react';
import {
  Grid,
  TextField as Input,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Widget from '../../components/Widget';
import useStyles from './styles';
import { Button, Typography,Avatar } from "../../components/Wrappers";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ChatIcon from '@material-ui/icons/Chat';
import Alert from '@material-ui/lab/Alert';
import { useUserState} from "../../context/UserContext";
import ReactApexChart from 'react-apexcharts'
import {db} from "../../services/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';

import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import log_it from './../../services/log/log';

function Interacciones(props)
{
  var classes = useStyles();
  var { datos, user } = useUserState();

  const [sinDatos, setSinDatos] = React.useState(false);
  const [progreso, setProgreso] = React.useState(false);
  const [nombrebot, setNombrebot]=React.useState("");
  const [fechaIni, setFechaIni] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(),1,0,0,0));
  const [fechaFin, setFechaFinal] = React.useState(new Date( new Date().getFullYear(), new Date().getMonth(),new Date().getDate(),23,59,59 ));
  const [series,setSeries]=React.useState([{
      data :[]/*.sort((a,b)=> a.x-b.x)*/,
      name: "Nuevos usuarios",
  }]);

  const [seriesUsuarios,setSeriesUsusarios]=React.useState([{
      data :[]/*.sort((a,b)=> a.x-b.x)*/,
      name: "Nuevos usuarios",
  }]);

  const  [options,setOptions]=React.useState({
      chart: {
          height: 380,
          width: "100%",
          type: "area",
          animations: {
            initialAnimation: {
              enabled: false
            }
          },
          toolbar: {
              show: true,
              tools: {
                  download: false
              }
          }
      },
      xaxis: {
          type: "datetime",
          labels: {
              style: {
                colors:datos.configuraciones.tema==='dark'?"#fff":"#000"
              },
              datetimeUTC : false
            },
      },
      yaxis: {
          labels: {
              style: {
                colors: datos.configuraciones.tema==='dark'?"#fff":"#000"
              }
          },
      },
      tooltip: {
          enabled: true,
           theme: datos.configuraciones.tema==='dark'?"dark":"light" ,
       },
       colors: ["#6dc96d"],
       title: {
                text: 'Interacciones'
              },


    });

    const  [optionsUsuarios,setOptionsUsuarios]=React.useState({
        chart: {
            height: 380,
            width: "100%",
            type: "area",
            animations: {
              initialAnimation: {
                enabled: false
              }
            },
            toolbar: {
                show: true,
                tools: {
                    download: false
                }
            }
        },
        xaxis: {
            type: "datetime",
            labels: {
                style: {
                  colors:datos.configuraciones.tema==='dark'?"#fff":"#000"
                },
                datetimeUTC : false
              },
        },
        yaxis: {
            labels: {
                style: {
                  colors: datos.configuraciones.tema==='dark'?"#fff":"#000"
                }
            },
        },
        tooltip: {
            enabled: true,
             theme: datos.configuraciones.tema==='dark'?"dark":"light" ,
         },
         title: {
            text: 'Usuarios'
         },

      });


  var camibarBot = (event)=> {
      setNombrebot(event.target.value);
  };

  const cambiarFechaIni = (date) => {
    setFechaIni(date);
  };

  const cambiarFechaFin = (date) => {
     date.setHours(23,59,59,59);
    setFechaFinal(date);
  };

  const generarReporte=()=>{
  setProgreso(true);
  setSinDatos(false);

    db.ref("conversaciones/"+datos.id_empresa+"/"+props.red+"/"+nombrebot.valor).
       once("value",function(snapshot){
        //var lista = snapshot.val();

          let interacciones=[];
          let usuarios=[];
          let usuarios_control=[];

          let data_key=[];
          let otro=[];

          snapshot.forEach(function(data){

              data.forEach(function(lista){
                //  console.log("key",data.key );
                let info=lista.val();

              //  obtener la fecha
                let aux=FechatoDateSoloHora(info.fecha_hora,fechaIni,fechaFin);
                if(aux!==null){

                    let fecha=aux.getTime();

                    //si es bot y si la fecha esta entre el rango solicitado
                    if( (info.tipo_usuario==="bot" || info.tipo_usuario==="chatcenter" || info.tipo_usuario==="watson" ) && fecha>=fechaIni.getTime() && fecha<=fechaFin.getTime())
                    {
                          var index = interacciones.findIndex(function(item, i){
                                      return item.x === fecha
                          });

                          if(index>-1)
                          {
                              interacciones[index].y=interacciones[index].y+1;

                          }else{
                              interacciones.push({
                                  x:fecha,
                                  y:1});
                          }
                    }

            /*        if(info.fecha_hora.startsWith("2021-06-11") && (info.tipo_usuario==="bot" || info.tipo_usuario==="chatcenter"))
                    {

                        //grafica de usuarios_control
                        //si la fecha esta en el rango
                        var indice = data_key.findIndex(function(item, i){
                                    return (item.key === data.key)
                        });

                        if(indice === -1)
                        {
                          data_key.push({key:data.key,contidad:1});
                        }else{
                          data_key[indice].contidad=data_key[indice].contidad+1;
                        }


                    /*    console.log("-----------------");
                        console.log("data key",data.key);
                        console.log("lista key",lista.key);
                        console.log("data",data.val());
                        console.log("lista",lista.val());
                          console.log("fechaString",info.fecha_hora);
                          console.log("fecha",aux);
                          console.log("fechaI",fechaIni);
                          console.log("fechaF",fechaFin);*/
              //      }


                    if(fecha>=fechaIni.getTime() && fecha<=fechaFin.getTime())
                    {
                    let nueva_fecha=FechatoDateSoloHora(info.fecha_hora,fechaIni,fechaFin).getTime();

                        var indice = usuarios_control.findIndex(function(item, i){
                                    return (item.fid === data.key && item.fecha===nueva_fecha)
                        });

                        if(indice<0)
                        {
                            var index = usuarios.findIndex(function(item, i){
                                    return item.x ===nueva_fecha
                                  });

                            if(index>-1)
                            {
                                usuarios[index].y=usuarios[index].y+1;

                            }else{
                                usuarios.push({
                                    x:nueva_fecha,
                                    y:1});
                            }
                            usuarios_control.push({
                              fid:data.key,
                              fecha:nueva_fecha});
                       }
                    }

                }

              });

          });

          if(interacciones.length===0 && usuarios.length===0){setSinDatos(true)}
          ///llenar grafico de interacciones
          setSeries([{
                data :interacciones.sort((a,b)=> a.x-b.x),
                name: "Interacciones",
             }
            ]);
          ///grafico de usuarios atendidos
          setSeriesUsusarios([{
                data :usuarios.sort((a,b)=> a.x-b.x),
                name: "Usuarios",
             }
            ]);
          setProgreso(false);
          var obj = {
            fecha: + new Date(),
            nombre: "generar reporte interacciones",
            id_accion: 502,
            id_bot: nombrebot.valor,
            red_social: props.red,
          }
          log_it(datos.id_empresa,user.id,obj)
   });


  }


  return(
    <Widget
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            title={'Generar Reporte'}
    >

      <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>

        { props.red==="telegram" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <TelegramIcon style={{ fontSize: 43,
                                     color: "#279fd3"
                                   }}  />
            </Typography>
          </Grid>
        }
        { props.red==="facebook" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <FacebookIcon style={{ fontSize: 43,
                                     color: "#4867aa"
                                   }}  />
            </Typography>
          </Grid>
        }
        { props.red==="whatsapp" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <WhatsAppIcon style={{ fontSize: 43,
                                     color: "#25cd60"
                                   }}  />
            </Typography>
          </Grid>
        }
        { props.red==="instagram" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <InstagramIcon style={{ fontSize: 43,
                                     color: "#a7338e"
                                   }}  />
            </Typography>
          </Grid>
        }

        { props.red==="webchat" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <LanguageIcon style={{ fontSize: 43,
                                     color: "#056179"
                                   }}  />
            </Typography>
          </Grid>
        }

          <Grid item xs={12}>
              <Typography className={classes.LeyendaPorcentaje}>Interacciones y usuarios atendidos</Typography>
          </Grid>


          <Grid item xs={12}>
            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>

                <Grid item  xs={4} >
                 <FormControl variant="outlined"  className={classes.centrar}  >
                  <KeyboardDatePicker
                     disableToolbar
                     variant="inline"
                     format="dd-MM-yyyy"
                     margin="normal"
                     label="Fecha inicio"
                     value={fechaIni}
                     onChange={cambiarFechaIni}
                     KeyboardButtonProps={{
                       'aria-label': 'change date',
                     }}
                   />
                  </FormControl>
                 </Grid>

                <Grid item  xs={4} className={classes.centrar}>
                   <FormControl variant="outlined" >
                     <InputLabel id="demo-simple-select-outlined-label">Bot</InputLabel>
                       <Select
                           labelId="demo-simple-select-outlined-label"
                           id="demo-simple-select-outlined"
                           label="Age"
                           value={nombrebot}
                           onChange={camibarBot}
                       >
                       <MenuItem value="">
                           <em>None</em>
                       </MenuItem>
                         {props.listabot}
                       </Select>
                   </FormControl>
                 </Grid>

                 <Grid item  xs={4}  className={classes.centrar}>
                    <FormControl variant="outlined"  >
                       <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Fecha fin"
                          value={fechaFin}
                          onChange={cambiarFechaFin}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                    </FormControl>
                  </Grid>
            </Grid>
          </Grid>

        {fechaFin<fechaIni &&
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.centrar} >
              <Alert severity="error">La fecha de inicio debe ser menor a la fecha final</Alert>
            </FormControl>
          </Grid>
        }

        {  progreso &&
            <Grid item xs={12}  >
                <FormControl variant="outlined" className={classes.centrar}>
                    <CircularProgress disableShrink/>
                </FormControl>
            </Grid>
        }

        {   progreso===false &&
         <Grid item xs={12}>
             <FormControl variant="outlined" className={classes.centrar}>
               <Button
               variant="contained"
               color="primary"
               size="large"
               startIcon={<ChatIcon />}
               disabled={nombrebot==="" || fechaFin<fechaIni}
               onClick={generarReporte}
               >
               Generar reporte
               </Button>
             </FormControl>
         </Grid>
       }

      {sinDatos &&
        <Grid item xs={12}>
          <Alert severity="warning">No se encontraron datos en el rago de fecha</Alert>
        </Grid>
      }

         <Grid item xs={12}>
          <ReactApexChart options={options} series={series} type="area" height={350} />
        </Grid>

        <Grid item xs={12}>
         <ReactApexChart options={optionsUsuarios} series={seriesUsuarios} type="area" height={350} />
       </Grid>



      </Grid>
    </Widget>
  );
}

//fromato de fecha 2021-08-03 10:38:49.204860
function FechatoDate(fecha)
{
  try {
    let fecha_hora = fecha.split(' ');
    let fecha_split = fecha_hora[0].split('-');
    let hora_split= fecha_hora[1].split(':');

    let nueva_fecha=new Date( fecha_split[0], parseInt(fecha_split[1])-1,fecha_split[2],hora_split[0],0,0 );

    return nueva_fecha;

  } catch (error) {
    return null;
  }

}

//fromato de fecha 2021-08-03 10:38:49.204860
function FechatoDateSoloHora(fecha,fechaIni,fechaFin)
{
  try {

    if (isNaN(fecha)) {
      let fecha_hora = fecha.split(' ');
      let fecha_split = fecha_hora[0].split('-');

      if((fechaIni.getDate() === fechaFin.getDate() || (fechaFin.getDate() -fechaIni.getDate())<3 ) &&
         fechaIni.getMonth() === fechaFin.getMonth() &&
         fechaIni.getFullYear() === fechaFin.getFullYear()
        )
      {
        let hora_split= fecha_hora[1].split(':');
        let nueva_fecha=new Date( fecha_split[0], parseInt(fecha_split[1])-1,fecha_split[2],hora_split[0],0,0 );
        return nueva_fecha;

      }else{

        let nueva_fecha=new Date( fecha_split[0], parseInt(fecha_split[1])-1,fecha_split[2],0,0,0 );
        return nueva_fecha;
      }
    }else{

      let fecha_actual= new Date(fecha);
      if((fechaIni.getDate() === fechaFin.getDate() || (fechaFin.getDate() -fechaIni.getDate())<3 ) &&
         fechaIni.getMonth() === fechaFin.getMonth() &&
         fechaIni.getFullYear() === fechaFin.getFullYear()
        )
      {

        let nueva_fecha=new Date( fecha_actual.getFullYear(), fecha_actual.getMonth(),fecha_actual.getDate(),fecha_actual.getHours(),0,0 );
        return nueva_fecha;

      }else{

        let nueva_fecha=new Date( fecha_actual.getFullYear(), fecha_actual.getMonth(),fecha_actual.getDate(),0,0,0 );
        return nueva_fecha;
      }


    }



  } catch (error) {
    return null;
  }

}
export default Interacciones;
