import React from 'react';

import {storage,db} from "../../../services/firebase";

var ChatStateContext= React.createContext();
var ChatDispatchContext= React.createContext();

function chatReducer(state,action)
{
    switch(action.type)
    {
        case "INICIAR_DATOS":
            return {...state,
                datos_bot:action.datos_bot,
                bot_actual:action.bot_actual,   
            }

        case "GET_CONVERSATION":
            return {...state,
                usuario_actual:action.usuario_actual,
                atender_usuario:false,              
            }
        case "MOSTRAR_ATRIBUTOS":
            return {...state,
                atributos:true,              
            }
        case "CERRAR_ATRIBUTOS":
            return {...state,
                atributos:false,              
            }
        case "ATENDER_USUARIO":
            return {...state,
                atender_usuario:true,              
            }            
        case "DETENER_ATENDER_USUARIO":
            return {...state,
                atender_usuario:false,              
            }

        case "ABRIR_SALIRCHAT":
            return {...state,
                salir_chat:true,              
            }

        case "CERRAR_SALIRCHAT":
            return {...state,
                salir_chat:false,              
            }

        case "MOSTRAR_CARGARDATOS":
            return {...state,
                cargado_datos:true,              
            }

        case "CERRAR_CARGARDATOS":
            return {...state,
                cargado_datos:false,              
            }

        case "CARGANDO_CHAT":
            return {...state,
                cargando_chat:true,              
        }

        case "OCULTAR_CARGANDOCHAT":
            return {...state,
                cargando_chat:false,              
        }
            
        
 
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
            }
    }
   
}

function ChatProvider({ children })
{
    var [state,dispatch]= React.useReducer( chatReducer,{
        bot_actual:"",
        datos_bot:"",
        usuario_actual:"",
        atributos:false,
        atender_usuario:false,
        salir_chat:false,
        cargado_datos:false,
        cargando_chat:false,
    });


    return (
        <ChatStateContext.Provider value={state}>
            <ChatDispatchContext.Provider value={dispatch}>
                {children}
            </ChatDispatchContext.Provider>
        </ChatStateContext.Provider>
    );

}

function useChatState(){
    var context = React.useContext(ChatStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}

function useChatDispatch(){
    var context = React.useContext(ChatDispatchContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context; 
}

export {ChatProvider,
        useChatState,
        useChatDispatch,
        IniciarDatosBot,
        GetConversation,
        MostrarAtributios,
        CerrarAtributos,
        AtenderUsuario,
        DetenerAtenederUsuario,       
        CerarSalirChat,
        AbrirSalirChat,
        MostrarCargarDatos,
        OcultarCargarDatos,
        MostrarCargarChat,
        OcultarCargarChat

    }

const IniciarDatosBot =(dispatch,bot_actual,datos_bot) => {
    dispatch({
        type: "INICIAR_DATOS",bot_actual:bot_actual,datos_bot:datos_bot,
    });
}

const GetConversation =(dispatch,usuario_actual) => {
    dispatch({
        type: "GET_CONVERSATION",usuario_actual:usuario_actual,
    });
}

const MostrarAtributios =(dispatch) => {
    //console.log("context mostrar atributos");
    dispatch({
        type: "MOSTRAR_ATRIBUTOS",
    });
}

const CerrarAtributos =(dispatch) => {
    dispatch({
        type: "CERRAR_ATRIBUTOS",
    });
}


const AtenderUsuario =(dispatch) => {
    dispatch({
        type: "ATENDER_USUARIO",
    });
}


const DetenerAtenederUsuario =(dispatch) => {
    dispatch({
        type: "DETENER_ATENDER_USUARIO",
    });
}


const AbrirSalirChat =(dispatch) => {
    dispatch({
        type: "ABRIR_SALIRCHAT",
    });
}

const CerarSalirChat =(dispatch) => {
    dispatch({
        type: "CERRAR_SALIRCHAT",
    });
}


const MostrarCargarDatos =(dispatch) => {
    dispatch({
        type: "MOSTRAR_CARGARDATOS",
    });
}

const OcultarCargarDatos =(dispatch) => {
    dispatch({
        type: "CERRAR_CARGARDATOS",
    });
}

const MostrarCargarChat =(dispatch) => {
    dispatch({
        type: "CARGANDO_CHAT",
    });
}

const OcultarCargarChat =(dispatch) => {
    dispatch({
        type: "OCULTAR_CARGANDOCHAT",
    });
}