import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db,storage} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { DropzoneArea } from 'material-ui-dropzone';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import Checkbox from '@material-ui/core/Checkbox';
import Edit from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import log_it from './../../../../services/log/log';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
       flexGrow: 1,
       backgroundColor: theme.palette.background.paper,
       display: 'flex',
       height: "100%",
     },
     tabs: {
       borderRight: `1px solid ${theme.palette.divider}`,
     },
    formControl: {
      width: "100%",
    },
    iconoIA:{
      color:"#ffa611"
    },

  }),
);


export default function Iabot(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);


    /*variables de datos*/
    const [enableWatson,setEnableWatson]=React.useState(false);
    const [apikey,setApikey]=React.useState("")
    const [skillid,setSkillid]=React.useState("")
    const [url,setUrl]=React.useState("")
    const [version,setVersion]=React.useState("")




    /*inicializar vaiables*/
      useEffect(() =>{
        //whatson IBM
        db.ref('IA/'+datos.id_empresa+'/'+props.id+'/watson').once('value',function(snapshot){
          if(snapshot.val()!==null){
            setApikey(snapshot.val().api_key);
            setSkillid(snapshot.val().skill_id);
            setUrl(snapshot.val().url);
            setVersion(snapshot.val().version);
            setEnableWatson(true);
          }else{
            setEnableWatson(false);
            setApikey("");
            setSkillid("");
            setUrl("");
            setVersion("");
          }
        });

      //  console.log("datos",datos.permisos.abcbot.ia);

        return ()=>{
          db.ref('IA/'+datos.id_empresa+'/'+props.id+'/watson').off();
        }

      },[open]);


    const handleChange = (event, newValue) => {
       setValue(newValue);
     };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
      setCargando(true);

        let datos_ia=null;
        if(enableWatson){
          datos_ia={
            api_key:apikey,
            skill_id:skillid,
            url:url,
            version:version
          }
        }
        //Para guardar en el log de usuarios
        var obj = {
          fecha: + new Date(),
          nombre: "editar IA",
          id_accion: 152,
          id_bot: props.id,
        }
        log_it(datos.id_empresa,user.id,obj)


        db.ref('IA/'+datos.id_empresa+'/'+props.id+'/watson').set(datos_ia,function(error){
          if (error) {
              enqueueSnackbar('Error a guardar datos', {variant: 'error'});
              setCargando(false);
          } else {
            setCargando(false);
            setOpen(false);
            enqueueSnackbar('Datos guardados correctamente', {variant: 'success'});
          }
        });

  /*    db.ref('llaves/'+datos.id_empresa+'/facebook/'+props.id).update(
        {
          fb_key:fbkey,
          fb_token:fbtoken
        });
        setOpen(false);
      enqueueSnackbar('Datos guardados exitosamente', {variant: 'success'});*/
    }


    return(
        <React.Fragment>
          <Typography  variant="body2" weight={"bold"} >
              <Tooltip title="Inteligencia Artificial">
                  <IconButton aria-label="delete" className={classes.iconoIA} onClick={handleClickOpen}>
                      <EmojiObjectsIcon fontSize="large" />
                  </IconButton>
              </Tooltip>
          </Typography>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
            {/* <DialogTitle id="form-dialog-title">Conexion Facebook<FacebookIcon/></DialogTitle> */}
            <DialogTitle id="form-dialog-title">Configurar IA</DialogTitle>
                <DialogContent dividers>
                  <Grid spacing={3} container>



                  <div className={classes.root}>
                        <Tabs
                          orientation="vertical"
                          variant="scrollable"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          className={classes.tabs}
                        >
                          <Tab label="IA" {...a11yProps(0)} />

                        </Tabs>
                        <TabPanel value={value} index={0}>

                        <Grid spacing={3} container >

                        <Grid item xs={12}>
                            <FormControlLabel
                             disabled={datos.permisos.abcbot.ia!==true}
                              control={<Switch checked={enableWatson} onChange={(e)=>setEnableWatson(e.target.checked)} name="checkedA" />}
                              label="Habilitar IA"
                            />
                        </Grid>

                        { enableWatson && datos.rol==="super_admin" &&
                           <Grid item xs={12}>

                            <Grid spacing={3} container >

                                <Grid item xs={12}>
                                    <TextField id="ElTexto_mensaje"
                                               value={apikey}
                                               onChange={e => setApikey(e.target.value)}
                                               label="api_key"
                                               variant="outlined"
                                               fullWidth
                                               disabled={datos.permisos.abcbot.ia!==true}
                                               error={apikey.length==0}
                                               helperText={apikey.length==0?"Campo obligatorio":""}
                                               />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="ElTexto_mensaje"
                                               value={skillid}
                                               onChange={e => setSkillid(e.target.value)}
                                               label="skillid"
                                               variant="outlined"
                                               fullWidth
                                                disabled={datos.permisos.abcbot.ia!==true}
                                               error={skillid.length==0}
                                               helperText={skillid.length==0?"Campo obligatorio":""}
                                               />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="ElTexto_mensaje"
                                               value={url}
                                               onChange={e => setUrl(e.target.value)}
                                               label="url"
                                               variant="outlined"
                                               fullWidth
                                                disabled={datos.permisos.abcbot.ia!==true}
                                               error={url.length==0}
                                               helperText={url.length==0?"Campo obligatorio":""}
                                               />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="ElTexto_mensaje"
                                               value={version}
                                               onChange={e => setVersion(e.target.value)}
                                               label="version"
                                               variant="outlined"
                                               fullWidth
                                               disabled={datos.permisos.abcbot.ia!==true}
                                               error={version.length==0}
                                               helperText={version.length==0?"Campo obligatorio":""}
                                               />
                                </Grid>

                          </Grid>
                        </Grid>}

                        </Grid>
                        </TabPanel>
                      </div>


                  </Grid>
                </DialogContent>
               {cargando ?
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                : <DialogActions>
                    <Button onClick={GuardarCambios} color="secondary"
                    disabled={ (apikey.length===0 || skillid.length===0 || url.length===0 || version.length===0) && enableWatson===true || datos.permisos.abcbot.ia!==true}
                    >
                        Guardar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>}

            </Dialog>

        </React.Fragment>
       );

}
