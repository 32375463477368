import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import Respuesta from "./respuesta";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from "../../../../../components/Wrappers";
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {useAdminBotState} from '../../../contexto/AdminBotContext';
import { useUserState} from "../../../../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,
 },
    formControl: {
      width: "100%",
    },
    colorDelete:{
      color:"#dc004e",
    },
    gridList: {

      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    margen:{
      padding: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

  }),
);

function Respuestas(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const[items,setItems] = React.useState([]);
    const [arregloRespuestas,setArregloRespuesta]= React.useState([]);

    const [tipo,setTipo]=React.useState("texto");
    const [enviara,setEnviara]=React.useState("0");
    const [valor,setValor] = React.useState("");
    const [mensaje,setMensaje]=React.useState("");

    const [actualizarRespuestas,setActualizarRespuestas]=React.useState(true);
    useEffect(() =>{
        if(bloques!==null)
        {
            let lista_items=[]
            bloques.forEach((element, index, array) => {
                lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
            });
            setItems(lista_items);
        }
      },[bloques]);


  useEffect(() =>{

    if(props.arreglo)
    {
      /*-----------------------------Respuestas--------------------*/
      let resp=[];
      props.arreglo.forEach((item, i) => {
        if(item.enviar_a!=undefined)
        {
        resp.push(  <Respuesta
          key={"respuesta-"+props.id_bloque+"-"+props.id_elemento+"-"+i}
          enviar_a={item.enviar_a}
          valor={item.valor}
          index={i}
          eliminar={EliminarRespuesta}
          editar={props.editar}
        />);
        }else{
            resp.push(<Respuesta
              key={"respuesta-"+props.id_bloque+"-"+props.id_elemento+"-"+i}
              mensaje={item.mensaje}
              valor={item.valor}
              index={i}
              eliminar={EliminarRespuesta}
              editar={props.editar}
            />)
        }
      });

      setArregloRespuesta(resp);
    }

  },[props.respuestas,actualizarRespuestas]);

  const AgregarRespuesta=()=>{

      props.editar((prevState)=>{
        if(tipo==="texto")
        {
          prevState.push({
            mensaje:mensaje,
            valor:valor
          });
          return prevState
         }else{
            prevState.push({
              enviar_a:enviara==="0"?"":enviara,
              valor:valor
            });
            return prevState
          }

      });

      setMensaje("");
      setValor("");
      setEnviara("0");
      setActualizarRespuestas(actualizarRespuestas?false:true);
  }

  const EliminarRespuesta=(datos)=>{

    props.editar((prevState)=>{
        prevState.splice(datos.id,1);
        return prevState;
    });
    setActualizarRespuestas(actualizarRespuestas?false:true);
  }

  return(

    <React.Fragment>

    <Grid item xs={12}>
        <Typography variant={"h3"}>Respuestas</Typography>
    </Grid>

    {/*Nuevo item*/}
    <Grid item xs={12} >
        <Grid spacing={2} container >

          { datos.permisos.abcbot.bloques===true &&
              <div style={{width:'100%'}}>
              <Grid spacing={2} container >

                <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined-quil"
                        value={tipo}
                        label="Tipo"
                        onChange={e=>setTipo(e.target.value)}
                    >
                        <MenuItem value={"bloque"}>Bloque</MenuItem>
                        <MenuItem value={"texto"}>Texto</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField id="outlined-basic"
                      label="Nombre"
                      variant="outlined"
                      value={valor}
                      onChange={e=>setValor(e.target.value)}
                      fullWidth />
                </Grid>


          {tipo==="bloque" ?
                      <Grid item xs={12} sm={4}>
                      <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                          <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                          <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined-quil"
                              value={enviara}
                              label="Enviar"
                              onChange={e=>setEnviara(e.target.value)}
                          >
                              <MenuItem value="0">
                                  <em>None</em>
                              </MenuItem>
                              {items}
                          </Select>
                          </FormControl>
                      </Grid>
                      :

                      <Grid item xs={12} sm={4}>
                          <TextField id="outlined-basic"
                            label="Mensaje"
                            variant="outlined"
                            value={mensaje}
                            onChange={e=>setMensaje(e.target.value)}
                            fullWidth />
                      </Grid>
                    }

                <Grid item xs={12} sm={12}>
                <Button
                    variant="contained"
                    color="primary"
                    endIcon={<AddBoxIcon/>}
                    fullWidth
                    disabled={
                      valor.length===0 || (tipo==="texto" && mensaje.length===0)
                    }
                    onClick={AgregarRespuesta}
                >
                    Guardar
                </Button>
                </Grid>
              </Grid>
            </div>
                  }

        </Grid>
    </Grid>


    <Grid item xs={12}>
        <Divider variant="middle" className={classes.colorDivider}/>
    </Grid>


    {arregloRespuestas}

</React.Fragment>

  )

}

export default Respuestas;
