import React, { useState ,useEffect,Component} from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {Button,  Typography, Link } from '../../../components/Wrappers';
import { withStyles } from "@material-ui/core/styles";
import useStyles from '../styles';
import { lightBlue,red,green, deepPurple } from '@material-ui/core/colors';
import {useChatState,
        useChatDispatch,
        MostrarAtributios,
        AtenderUsuario,
        CerarSalirChat,
        AbrirSalirChat} from '../context/ChatContext';
import { useUserState} from "../../../context/UserContext";
import {db,fecha_hora} from "../../../services/firebase";

const ColorButton = withStyles((theme) => ({
    root: {
      //color: theme.palette.getContrastText(purple[500]),
      borderColor: lightBlue[500],
    },
  }))(Button);
  const StopButton = withStyles((theme) => ({
    root: {
      //color: theme.palette.getContrastText(purple[500]),
      borderColor: red[300],
    },
  }))(Button);


function MenuBotones ()
{
    var classes = useStyles();
    var chatDispach=useChatDispatch();
    var {usuario_actual,datos_bot,bot_actual,datos_chat} = useChatState();
    var { datos,user } = useUserState();

    const mostrarAtributos=()=>{
      //console.log("mostrar atributos");
      if(usuario_actual!=="" && datos_bot!=="")
      {
        MostrarAtributios(chatDispach);
      }
    }

    const IniciarChat=()=>{

      if(usuario_actual!=="" && !usuario_actual.chatcenter)
      {
        let index_ultima_actual =  datos_bot.findIndex(item => item.id === usuario_actual.id);
        db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos").update({
          chatcenter:true,
          fecha_chatcenter:fecha_hora.database.ServerValue.TIMESTAMP,
          acceso_asesor: index_ultima_actual === -1 ? null : datos_bot[index_ultima_actual].dato.atributos.ultima_fecha
        });
        AtenderUsuario(chatDispach);
        handle_ChatcenterActualizarAtendiendo(
          user,
          datos,
          usuario_actual,
        );
      }
    };

    const MostrarSalir=()=>{
      if(usuario_actual!=="" && datos_bot!=="")
      {
        if(usuario_actual.chatcenter)
        {
          AbrirSalirChat(chatDispach);

        }
      }

    }

    function handle_ChatcenterActualizarAtendiendo(user,datos,usuario_actual){

        return new Promise(function (fulfill, reject){

                if(usuario_actual==="")
                {
                  fulfill();
                }

                //--ALGORITMO
                //EVALUAR Si el chat tiene un asesor actualmente
                // console.log("usuario actual log",usuario_actual);

                if(usuario_actual.asesor === ""){
                  // no tiene un asesor
                  // No tiene un asignado un log_chatcenter
                  var obj={
                    estado:true,
                    fecha_ini:fecha_hora.database.ServerValue.TIMESTAMP,
                    fecha_usuario:fecha_hora.database.ServerValue.TIMESTAMP,
                    id:usuario_actual.key,
                    usuario:user.id,
                    nombre:datos.nombre,
                    estado: true
                  }
                  var refPush = db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/").push(obj,function(err){
                    var log_channel_id = refPush.key
                    db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/")
                    .update({log_chatcenter:log_channel_id,
                             asesor_id:user.id,
                             asesor_name:datos.nombre,
                            });
                  });
                  fulfill();
                }else{
                  //ya tiene asociado log_chatcenter
                  //Si quien atiende no es el mismo que ya estaba atendiendo entonces tengo que actualizar

                  //1.pasar al historial el que estaba actualmente en log_chatcenter
                  //2.actualizar al chat center su nuevo asesor_id y asesor_name
                  //./1
                  //obtener el historial
                  if(usuario_actual.asesor!==user.id){
                    db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).once('value').then(function(snap){
                      return snap.val();
                    }).then(function (log_chatcenter){

                        let historial=[];
                          if(log_chatcenter===null){
                            fulfill();
                            return 0;
                          }

                          if(('historial' in log_chatcenter)){
                            historial=log_chatcenter.historial;
                          }

                          historial.push({
                            fecha_ini:log_chatcenter.fecha_usuario,
                            fecha_fin:fecha_hora.database.ServerValue.TIMESTAMP,
                            usuario:log_chatcenter.usuario,
                            nombre:log_chatcenter.nombre
                          }
                          );

                        db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).
                          set({
                              estado:true,
                              fecha_ini:log_chatcenter.fecha_ini,
                              id:log_chatcenter.id ,
                              historial: historial,
                              fecha_usuario:  fecha_hora.database.ServerValue.TIMESTAMP,
                              usuario:user.id,
                              nombre:datos.nombre
                          });
                          //./2
                          db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/asesor_id").set(user.id);
                          db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/asesor_name").set(datos.nombre);

                          fulfill();

                    });
                  }else{
                    fulfill();
                  }
                }
        });

    }



    return(
        <ButtonGroup size="small" aria-label="small outlined button group">
        {/* <Button  color={"yellow"} onClick={mostrarAtributos}>Atributos</Button>
        <StopButton onClick={MostrarSalir} >Detener Chat</StopButton>
        <ColorButton onClick={IniciarChat} >Iniciar Chat</ColorButton> */}
      </ButtonGroup>
    );
}


export default MenuBotones;
