import React , { useState, useEffect } from 'react';
import QuickReplies from "../quick_replies"
import {Grid} from "@material-ui/core";
import { Typography } from "../../../../../components/Wrappers";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useUserState} from "../../../../../context/UserContext";

function CancelarOrden(props){
  var { datos,user } = useUserState();
  const [arregloReplies,setArregloReplies]= React.useState([]);
  const [errorReplay,setErrorReplay] = React.useState(false);
  const [listaReplies,setListaReplies]= React.useState([]);
  const [ia,setIA]=React.useState(false);

  const [actualizar,setActualizar]= React.useState(false);
  const [mensajeError,setMensajeError]=React.useState("");

    useEffect(() =>{

      setIA(props.ia);
      setMensajeError(props.mensajeCancelar);

      let lista=[]
      props.arregloReplies.forEach((element, index, array) =>{
            lista.push(
            <QuickReplies key={"replies_"+index}
                title={element.title}
                enviar_a={element.enviar_a}
                id={index}
                error={setErrorReplay}
                eliminar={EliminarReplay}
                editar={props.editar}
                payload={element.payload==="cancelar"?"cancelar":undefined}
            />
            );
        });
        if(lista.length===0)
        {
          let nueva=[{
            content_type:'text',enviar_a:'default',image_url: "",
            payload: 'cancelar',
            title: 'cancelar',
          }];

          lista.push(
          <QuickReplies key={"replies_0"}
              title={"cancelar"}
              enviar_a={"default"}
              id={0}
              error={setErrorReplay}
              eliminar={EliminarReplay}
              editar={props.editar}
              payload={"cancelar"}
          />
          );

          props.editar(nueva);

        }
        setListaReplies(lista);

    },[  props.arregloReplies,actualizar]);

  const EliminarReplay=(datos)=>{
    props.editar((prevState) => {
      prevState.splice(datos.id,1);
      return prevState;
    });
    setActualizar(actualizar?false:true);
  }

  const AgregarReplay=()=>{
    props.editar((prevState)=>{
      prevState.push({
        content_type:'text',enviar_a:'default',image_url: "",
        payload: '',
        title: 'nuevo',
      });
      return prevState;
    })
    setActualizar(actualizar?false:true);
  }



  const CambiarIA=(e)=>{
    setIA(e.target.checked);
    props.editar_ia(e.target.checked);
  }

  const CambiarMensajeError=(e)=>{
    setMensajeError(e.target.value);
    props.editar_mensajeCancelar(e.target.value);
  }


  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Grid spacing={2} container >

          <Grid item xs={12} sm={10}>
              <TextField id="ElTexto_mensaje"
                         label="Mensaje Cancelar"
                         variant="outlined"
                         fullWidth
                         value={mensajeError}
                         onChange={CambiarMensajeError}
                         disabled={datos.permisos.abcbot.bloques!==true}


                         />
          </Grid>
          <Grid item xs={12} sm={2}>
              <FormControlLabel
                  value="top"
                  control={<Switch color="primary" />}
                  label="IA"
                  labelPlacement="top"
                  checked={ia}
                  onChange={CambiarIA}
                  disabled={datos.permisos.abcbot.bloques!==true}
              />
          </Grid>
          <Grid item xs={12}>
              <Typography >
               Replies
               </Typography>
          </Grid>

          {datos.permisos.abcbot.bloques===true &&
            <Grid item xs={12} sm={12}>
            <Button
                variant="contained"
                color="primary"
                endIcon={<AddBoxIcon/>}
                onClick={AgregarReplay}
            >
                Agregar Replies
            </Button>
          </Grid>}


          {listaReplies}
        </Grid>
      </Grid>
    </React.Fragment>
  );

}

export default CancelarOrden;
