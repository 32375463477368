import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//webservice
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import HttpIcon from '@material-ui/icons/Http';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from "../../../../../components/Wrappers";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteElementos from '../../DeleteElementos';
import Parametro from './parametro'
import Respuesta from './respuesta'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PropTypes from 'prop-types';
import Respuestas from './respuestas';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import Parametros from './parametros'
import AppBar from '@material-ui/core/AppBar';
import {useAdminBotState} from '../../../contexto/AdminBotContext'
import { useUserState} from "../../../../../context/UserContext";
import {db} from "../../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogElement from '../dialogElement';

import log_it from './../../../../../services/log/log'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,
 },
    formControl: {
      width: "100%",
    },
    colorDelete:{
      color:"#dc004e",
    },
    gridList: {

      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    margen:{
      padding: theme.spacing(1),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },

  }),
);

export function WebService(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    const [items,setItems]=React.useState([]);

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);

    /*variables de datos*/
    const [tipo,setTipo] = React.useState("get");
    const [url,setUrl] = React.useState("");
    const [arregloParametros,setArregloParametros] = React.useState([]);
    const [arregloRespuestas,setArregloRespuestas]= React.useState([]);

    const [enviar_a,setEnviar_a]=React.useState("");

    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);

    /*inicializar vaiables*/
    useEffect(() =>{

      if(props.datos)
      {
        setTipo(props.datos.metodo);
        setUrl(props.datos.url);
        setEnviar_a((props.datos.enviar_a===""||props.datos.enviar_a===undefined)?"0":props.datos.enviar_a)

        if(props.datos.parametros!=undefined)
        {
          let parametros=JSON.stringify(props.datos.parametros);
          setArregloParametros(JSON.parse(parametros));
        }

        if(props.datos.respuestas!=undefined)
        {
          let respuestas=JSON.stringify(props.datos.respuestas);
          setArregloRespuestas(JSON.parse(respuestas));
        }

      }

    },[open]);



    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };

    const cambiarTipo=(event)=>{
        if(event.target.value!==undefined)
        {
            setTipo(event.target.value);
        }

    }

    const cambiarEnviarA=(event)=>{
      if(event.target.value!==undefined)
      {
          setEnviar_a(event.target.value);
      }
    }

    useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
      }
    },[bloques]);

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
       setValue(newValue);
     };

    const GuardarCambios=()=>{
      var temp_enviar_a = ""
      if(enviar_a!="0"){
        temp_enviar_a=enviar_a
      }

        setCargando(true);
        if(props.id_elemento===-1){
          setCargando(false);
          setOpen(false);
          enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
          props.GuardarNuevoElemento({
            elemento:"webservice",
            metodo:tipo,
            enviar_a:temp_enviar_a,
            url:url,
            parametros:arregloParametros,
            respuestas:arregloRespuestas,
          },props.new_index);


        }else{
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
            .set({
              elemento:"webservice",
              metodo:tipo,
              enviar_a:temp_enviar_a,
              url:url,
              parametros:arregloParametros,
              respuestas:arregloRespuestas,
            },function(error) {
                if (error) {
                    setCargando(false);
                    enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                } else {
                    setCargando(false);
                    enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                    setOpen(false);
                    var obj = {
                      fecha: + new Date(),
                      nombre: "modificar elemento",
                      id_accion: 132,
                      id_bot: bot_actual.id,
                      red_social: bot_actual.conexion,
                      bloque:props.id_bloque,
                      elemento:"webservice",
                    }
                    log_it(datos.id_empresa,user.id,obj)
                }
            });
      }

    }

    return(
        <React.Fragment>

          <DialogElement
            id_elemento={props.id_elemento}
            moverElementos={props.moverElementos}
            moverMasuno={props.MoverMasuno}
            moverMenosuno={props.MoverMenosuno}
            icono={<HttpIcon/>}
            metodoAbrir={handleClickOpen}
            nombre={props.nombre}
            tamanio={props.tamanio}
            dialogOpen={open}
            dialogClose={handleClose}
            cargando={cargando}
            disabledSave={url.length===0}
            guardar={GuardarCambios}
            eliminar={props.eliminar}
            cerrar={setOpen}
          >

                    <Grid item xs={12}>
                        <TextField
                          id="ElTexto_mensaje"
                          value={url}
                          onChange={e => setUrl(e.target.value)}
                          label="URL"
                          variant="outlined"
                          fullWidth
                          error={url.length===0}
                          helperText={url.length===0?"El campo es obligatorio":""}
                          disabled={datos.permisos.abcbot.bloques!==true}
                          />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                            <InputLabel id="demo-simple-select-outlined-label">Metodo</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined-quil"
                                value={tipo}
                                onClick={cambiarTipo}
                                label="Metodo"
                            >
                                <MenuItem value={"post"}>post</MenuItem>
                                <MenuItem value={"get"}>get</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                        <InputLabel id="select-enviar_a">Enviar a</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined-quil"
                            value={enviar_a}
                            onClick={cambiarEnviarA}
                            label="Enviar"
                        >
                            <MenuItem value="0">
                                <em>None</em>
                            </MenuItem>
                            {items}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Parametros</Typography>
                    </Grid>



                  <Paper square className={classes.root}>
                   <Tabs
                     value={value}
                     onChange={handleChange}
                     variant="fullWidth"
                     indicatorColor="secondary"
                     textColor="secondary"
                     aria-label="icon label tabs example"
                   >
                     <Tab icon={<PublishIcon />} label="Parametros" {...a11yProps(0)} />
                     <Tab icon={<GetAppIcon />} label="Respuestas" {...a11yProps(1)} />
                   </Tabs>

                     <TabPanel value={value} index={0}>
                      <Grid spacing={3} container >
                        <Parametros
                        id_bloque={props.id_bloque}
                        id_elemento={props.id_elemento}
                        arreglo={arregloParametros}
                        editar={setArregloParametros}
                        />
                        </Grid>
                     </TabPanel>


                    <TabPanel value={value} index={1}>
                      <Grid spacing={3} container >
                      <Respuestas
                        id_bloque={props.id_bloque}
                        id_elemento={props.id_elemento}
                        arreglo={arregloRespuestas}
                        editar={setArregloRespuestas}
                      />
                      </Grid>
                    </TabPanel>
                </Paper>
         </DialogElement>

        </React.Fragment>
       );

}
