import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../../components/Wrappers/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db,storage} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { DropzoneArea } from 'material-ui-dropzone';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import Checkbox from '@material-ui/core/Checkbox';
import Edit from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LockIcon from '@material-ui/icons/Lock';

import imagenAvi from '../../../../images/original.png'
import log_it from './../../../../services/log/log';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",
    },
    textoDelete:{
        color:"#dc004e",
    },
    colorUnselect:{
      color:"#a8a8a8",
      fontSize:theme.spacing(4),
    },
    colorFacebook:{
      color:"#395692",
      fontSize:theme.spacing(4),
    },
    colorWhatsapp:{
      color:"##42be52",
      fontSize:theme.spacing(4),
    },
    colorTelegram:{
      color:"#32a7d9",
      fontSize:theme.spacing(4),
    },
    alinearDerecha:{
      textAlign: 'right'
    },
    iconoEditar:{
      color:"#5dade2"
    },
    imagenes: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      display:'block',
      margin:'auto',
    },

  }),
);


export default function LlaveTg(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);


    /*variables de datos*/
    const [tgToken,setTgToken]=React.useState("")
    const [tgUrl,setTgUrl]=React.useState("")
    const [tgConexiones,setTgConexiones]=React.useState(0)




    /*inicializar vaiables*/
      useEffect(() =>{
        props.cerrarMenu();
        db.ref('llaves/'+datos.id_empresa+'/telegram/'+props.id).once('value',function(snapshot){
          if(snapshot.val()!==null){
            setTgToken(snapshot.val()['tg-token']);
            setTgUrl(snapshot.val().url);
            setTgConexiones(snapshot.val().conexiones);
          };
        })

        return ()=>{
          db.ref('llaves/'+datos.id_empresa+'/telegram/'+props.id).off();
        }

      },[open]);



    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
        setCargando(true);
      db.ref('llaves/'+datos.id_empresa+'/telegram/'+props.id).set(
        {
          'tg-token':tgToken,
          conexiones:parseInt(tgConexiones),
          url:tgUrl,
        },function(error){
          if (error) {
              enqueueSnackbar('Error a guardar datos', {variant: 'error'});
              setCargando(false);
          } else {
            var obj = {
              fecha: + new Date(),
              nombre: "editar conexión",
              id_accion: 142,
              id_bot: props.id,
              red_social:"telegram"
            }
            log_it(datos.id_empresa,user.id,obj)
            setCargando(false);
            setOpen(false);
            enqueueSnackbar('Datos guardados correctamente', {variant: 'success'});
          }
        });
    }


    return(
        <React.Fragment>
          <Typography onClick={handleClickOpen}>Conexión</Typography>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                  <DialogTitle id="form-dialog-title">Conexión Telegram<TelegramIcon/></DialogTitle>
                <DialogContent dividers>
                <Grid spacing={3} container >
                <Grid item xs={4}>
                    <TextField id="tgConexiones"
                               value={tgConexiones}
                               onChange={e => setTgConexiones(e.target.value)}
                               label="Conexiones"
                               variant="outlined"
                               fullWidth
                               disabled={datos.permisos.abcbot.bot!==true}
                               error={tgConexiones<=0}
                               helperText={tgConexiones<=0?"El número de reacciones debe ser mayor a 0":""}
                               />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="tgToken"
                               value={tgToken}
                               onChange={e => setTgToken(e.target.value)}
                               label="tg-token"
                               variant="outlined"
                               fullWidth
                               disabled={datos.permisos.abcbot.bot!==true}
                               error={tgToken.length==0}
                               helperText={tgToken.length==0?"Campo obligatorio":""}
                               />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="tgUrl"
                               value={tgUrl}
                               onChange={e => setTgUrl(e.target.value)}
                               label="url"
                               variant="outlined"
                               fullWidth
                               disabled={datos.permisos.abcbot.bot!==true}
                               error={tgUrl.length==0}
                               helperText={tgUrl.length==0?"Campo obligatorio":""}
                               />
                </Grid>
                </Grid>
                </DialogContent>
               {cargando ?
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                : <DialogActions>
                    <Button onClick={GuardarCambios} color="secondary"
                        disabled={tgUrl.length===0 || tgToken.length===0 ||  datos.permisos.abcbot.bot!==true}
                    >
                        Guardar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>}

            </Dialog>

        </React.Fragment>
       );

}
