import React,{useEffect} from 'react';
import {Grid} from "@material-ui/core";
import Widget from '../../../components/Widget';
import useStyles from '../styles';
import {db} from "../../../services/firebase";
import LinearProgress from '@material-ui/core/LinearProgress';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { Typography } from "../../../components/Wrappers";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
/*componenetes*/
import Bloque from './Bloque';
import NuevoBloque from './nuevoBloque/NuevoBloque';
import {useSnackbar } from 'notistack';
/*Contexto*/
import { useUserState} from "../../../context/UserContext";
import { useAdminBotDispatch,
    botActual,
    useAdminBotState,
    Bloques,
    Atributos,
    ListaBloques,
    BloqueActual,
    ReiniciarVariables
} from '../contexto/AdminBotContext'
import IconButton from '@material-ui/core/IconButton';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Box from '@material-ui/core/Box';
import ListaElementos from './ListaElementos';

import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"

import Divider from '@material-ui/core/Divider';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PasosBloque from "./nuevoBloque/PasosBloque"
import EditarBloque from './editarBloque';
import NuevoElemento from './nuevoElemento';
import WebIcon from '@material-ui/icons/Web';

import { makeStyles } from '@material-ui/core/styles';
import Instagram from '@material-ui/icons/Instagram';
import PublicIcon from '@material-ui/icons/Public';
import TelegramIcon from '@material-ui/icons/Telegram';
import VentanaListaBloques from './VentanaListaBloques';

/*Elementos facebook*/
import {
        Texto,
        Imagen,
        TextoRapido,
        PlantillaBoton,
        ChatCenter,
        Carrusel,
        WebService,
        CompraWeb,
        OrdenCompra,
        WebView,
        NoElement,
    } from './elementos'
/*Elementos facebook*/
import {
    Whatsapp_Texto,
    Whatsapp_Menu,
    Whatsapp_WebService,
    Whatsapp_ChatCenter,
    Whatsapp_Archivo,
    Whatsapp_textoRapido,
    Whatsapp_ChatCenter2, Whatsapp_Lista

} from './elementos/elementos_whatsapp'

import {
  Instagram_Texto,
  Instagram_TextoRapido,
  Instagram_Chatcenter,
  Instagram_Carrusel,
  Instagram_Archivo,
  Instagram_WebService,
  Instagram_Planificador,
  Instagram_Historia,

} from './elementos/elementos_instagram'

/*Elementos webchat*/
import {
  Webchat_ChatCenter,
  WebChat_TextoRapido,
}from './elementos/elementos_webchat'


/*Elementos Telegram*/
import {
  Telegram_texto,
  Telegram_textoRapido
}from './elementos/elementos_telegram'

import log_it from './../../../services/log/log'

const useStylesMake = makeStyles((theme) => ({
  root: {

    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

function EditorBloques(props){

    var classes = useStyles();
    var style = useStylesMake();

    var adminBotDispach=useAdminBotDispatch();
    var {bot_actual,bloques,lista_bloques,bloque_actual} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    const [loader,setLoader] = React.useState(false);
    const [listaBloques,setListaBloques] = React.useState([]);

    const [arrayBloques,setArrayBloques] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    /*--------------Drag and drop------------------------*/

    /*nuevo elemento*/
    const [nuevoElemento,setNuevoElemento] = React.useState("");


    useEffect(() =>{

        setLoader(true);
        let conn =db.ref('bloques/'+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id).on('value',function(snapshot){

            if(snapshot.val()==null){

                let bloque_inicial={
                    default:{
                      elementos:[],
                      is_default:true,
                    }}

                if(bot_actual.conexion === "webchat"){
                    bloque_inicial= {
                        default:{
                          elementos:[],
                          default:true,
                        }
                    }
                }

                db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/").update(
                {
                  default:{
                    elementos:[],
                    is_default:true,
                  }
                });


            }else{

              let lista_bloques=[];
              let context_bloques=[];
              let atributos=[];

                snapshot.forEach(function(data){

                  lista_bloques.push({
                    id:data.key,
                    info:data.val()
                  });
                  context_bloques.push(data.key);
                  atributos.push(data.val().elementos);
                });

              Bloques(adminBotDispach,context_bloques);
              Atributos(adminBotDispach,atributos);
              ListaBloques(adminBotDispach,lista_bloques);
            }

        });

        return ()=>{
          db.ref('bloques/'+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id).off('value',conn);
        }

      },[]);

    const regresar = ()  =>{
        ReiniciarVariables(adminBotDispach);
    }


    const CambiarNombre=(info)=>{
      db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id)
      .update(JSON.parse(info.datos),function(error){
          if (error) {
            enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
          } else {
            db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+info.actual)
            .set(null);
            enqueueSnackbar('Guardado exitoso', {variant: 'success'});
          }
          let nuevos_datos=JSON.parse(info.datos)
          let nombre_nuevo= Object.keys(nuevos_datos)[0];

        /*  console.log("nuevo nombre",{nombre:nombre_nuevo,
                                                          padre:nuevos_datos[nombre_nuevo].padre,
                                                          elementos:nuevos_datos[nombre_nuevo].elementos=== undefined?[]:nuevos_datos[nombre_nuevo].elementos
                                                        });
*/
          BloqueActual(adminBotDispach,{nombre:nombre_nuevo,
                                                      padre:nuevos_datos[nombre_nuevo].padre,
                                                      elementos:nuevos_datos[nombre_nuevo].elementos=== undefined?[]:nuevos_datos[nombre_nuevo].elementos
                                                    });

          setSelectedIndex(null);
        });
    }

    const EliminarBloque=(nombre)=>{
      setSelectedIndex(null);
      BloqueActual(adminBotDispach,"");
      db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+nombre)
      .set(null,function(error) {
          if (error) {
              enqueueSnackbar('Error al eliminar el bloque', {variant: 'error'});
          } else {
            enqueueSnackbar('Bloque eliminado correctamente', {variant: 'success'});
            var obj = {
              fecha: + new Date(),
              nombre: "eliminar bloque",
              id_accion: 123,
              id_bot: bot_actual.id,
              red_social: bot_actual.conexion,
              bloque:nombre,
            }
            log_it(datos.id_empresa,user.id,obj)
          }
      });

    }



    const handleListItemClick = (event, index,nombre) => {
      setSelectedIndex(index);
      BloqueActual(adminBotDispach,{nombre:nombre,index:index});
    };


    /*Guardar Nuevo elemento*/
    const GuardarAddElemento=(datosNuevos,index)=>{
        let elm=bloque_actual.elementos;
        let nuevo= elm===undefined?[]:elm.slice();
        nuevo.splice(index, 0, datosNuevos);

        let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+bloque_actual.nombre+"/elementos";

        db.ref(ref)
        .set(nuevo,function(error) {
            if (error) {
                enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
                setNuevoElemento([]) ;
            } else {
                enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                setNuevoElemento([]) ;
                var obj = {
                  fecha: + new Date(),
                  nombre: "crear elemento",
                  id_accion: 131,
                  id_bot: bot_actual.id,
                  red_social: bot_actual.conexion,
                  bloque:bloque_actual.nombre,
                  elemento:datosNuevos.elemento
                }
                log_it(datos.id_empresa,user.id,obj)
            }
        });

    }

    /*Crear Elementos de Telegram */
    const AddElemento_Telegram= (tipo,newIndex) =>{
        let nuevo=[]
          if (bot_actual.conexion==="telegram"){

            if(tipo==="texto"){
              nuevo.push( <Telegram_texto key={"nuevoElemento"+props.nombre}
                                         id_bloque={props.nombre}
                                         id_elemento={-1}
                                         abrir={true}
                                         GuardarNuevoElemento={GuardarAddElemento}
                                         cerrarElmento={CerrarNuevoElemento}
                                         new_index={newIndex}
                                          />
                                        );
            } else if(tipo==="archivo"){
                 nuevo.push(
                                        <Imagen key={"nuevoElemento"+props.nombre}
                                         nombre={"archivo"}
                                         id_bloque={props.nombre}
                                         id_elemento={-1}
                                         abrir={true}
                                         GuardarNuevoElemento={GuardarAddElemento}
                                         cerrarElmento={CerrarNuevoElemento}
                                         new_index={newIndex}
                                     />);
             }else if(tipo==="texto_boton"){
                  nuevo.push(
                                         <Telegram_textoRapido key={"nuevoElemento"+props.nombre}
                                          nombre={"texto_boton"}
                                          id_bloque={props.nombre}
                                          id_elemento={-1}
                                          abrir={true}
                                          GuardarNuevoElemento={GuardarAddElemento}
                                          cerrarElmento={CerrarNuevoElemento}
                                          new_index={newIndex}
                                      />);
              }
          }
          setNuevoElemento(nuevo);
    }

    /*Crar un nuevo elemento*/
    const AddElemento=(tipo,newIndex)=>{
      let nuevo=[]
      // Elementos de facebook e instagram
      if (bot_actual.conexion==="facebook" || bot_actual.conexion==="instagram" || bot_actual.conexion==="webchat"){
        console.log("tipo", tipo);
        console.log("newIndex", newIndex);
        console.log("conexion", bot_actual.conexion);

        if(tipo==="texto"){
          nuevo.push(<Texto key={"nuevoElemento"+props.nombre}
                                      nombre={"texto"}
                                      id_bloque={props.nombre}
                                      id_elemento={-1}
                                      abrir={true}
                                      GuardarNuevoElemento={GuardarAddElemento}
                                      cerrarElmento={CerrarNuevoElemento}
                                      new_index={newIndex}
                                      />)

          setNuevoElemento(nuevo);
          return 0;

        }else if(tipo==="archivo"){
          nuevo.push(<Imagen  key={"nuevoElemento"+props.nombre}
                                  nombre={"archivo"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);

        }else if(tipo==="texto_rapido" && bot_actual.conexion==="webchat"){

              nuevo.push(<WebChat_TextoRapido key={"nuevoElemento"+props.nombre}
                                      nombre={"Texto rapido"}
                                      id_bloque={props.nombre}
                                      id_elemento={-1}
                                      abrir={true}
                                      GuardarNuevoElemento={GuardarAddElemento}
                                      cerrarElmento={CerrarNuevoElemento}
                                      new_index={newIndex}
                                  />)
              setNuevoElemento(nuevo);

        }else if(tipo==="texto_rapido"){
          nuevo.push(<TextoRapido key={"nuevoElemento"+props.nombre}
                                  nombre={"Texto rapido"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);
        }else if(tipo==="texto_boton"){
          nuevo.push(<PlantillaBoton key={"nuevoElemento"+props.nombre}
                                  nombre={"Plantilla con boton"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);
        }else if(tipo==="chatcenter" && bot_actual.conexion==="webchat"){
          nuevo.push(<Webchat_ChatCenter key={"nuevoElemento"+props.nombre}
                                  nombre={"chatcenter"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);

        }else if(tipo==="chatcenter" && (bot_actual.conexion=="facebook" || bot_actual.conexion=="instagram")){
          nuevo.push(<ChatCenter key={"nuevoElemento"+props.nombre}
                                  nombre={"chatcenter"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);

        }else if(tipo==="carrusel"){
          nuevo.push(<Carrusel key={"nuevoElemento"+props.nombre}
                                  nombre={"carrusel"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);

        }else if(tipo==="webservice") {
          nuevo.push(<WebService key={"nuevoElemento"+props.nombre}
                                  nombre={"webservice"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);

        }else if(tipo==="webview_detalle"){
          nuevo.push(<CompraWeb key={"nuevoElemento"+props.nombre}
                                  nombre={"Detalle"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);

        }else if(tipo==="webview_showpage"){
          nuevo.push(<WebView key={"nuevoElemento"+props.nombre}
                                  nombre={"Webview"}
                                  id_bloque={props.nombre}
                                  id_elemento={-1}
                                  abrir={true}
                                  GuardarNuevoElemento={GuardarAddElemento}
                                  cerrarElmento={CerrarNuevoElemento}
                                  new_index={newIndex}
                              />)
          setNuevoElemento(nuevo);
        }


      }else if(bot_actual.conexion=="whatsapp"){
        if(tipo=="texto"){
          nuevo.push(<Whatsapp_Texto key={"nuevoElemento"+props.nombre}
          nombre={"texto"}
          id_bloque={props.nombre}
          id_elemento={-1}
          abrir={true}
          GuardarNuevoElemento={GuardarAddElemento}
          cerrarElmento={CerrarNuevoElemento}
          new_index={newIndex}
          />)
        }else if(tipo=="menu"){
          nuevo.push(<Whatsapp_Menu key={"nuevoElemento"+props.nombre}
          nombre={"menu"}
          id_bloque={props.nombre}
          id_elemento={-1}
          abrir={true}
          GuardarNuevoElemento={GuardarAddElemento}
          cerrarElmento={CerrarNuevoElemento}
          new_index={newIndex}
          />)
        }else if(tipo=="webservice"){
          nuevo.push(<Whatsapp_WebService key={"nuevoElemento"+props.nombre}
          nombre={"webservice"}
          id_bloque={props.nombre}
          id_elemento={-1}
          abrir={true}
          GuardarNuevoElemento={GuardarAddElemento}
          cerrarElmento={CerrarNuevoElemento}
          new_index={newIndex}
          />)
        }else if(tipo=="chatcenter"){
          nuevo.push(<Whatsapp_ChatCenter key={"nuevoElemento"+props.nombre}
                      nombre={"chatcenter"}
                      id_bloque={props.nombre}
                      id_elemento={-1}
                      abrir={true}
                      GuardarNuevoElemento={GuardarAddElemento}
                      cerrarElmento={CerrarNuevoElemento}
                      new_index={newIndex}
                  />)
        }else if(tipo=="chatcenter2"){
          nuevo.push(<Whatsapp_ChatCenter2 key={"nuevoElemento"+props.nombre}
                      nombre={"chatcenter2"}
                      id_bloque={props.nombre}
                      id_elemento={-1}
                      abrir={true}
                      GuardarNuevoElemento={GuardarAddElemento}
                      cerrarElmento={CerrarNuevoElemento}
                      new_index={newIndex}
                  />)
        }else if(tipo=="archivo"){
          nuevo.push(<Whatsapp_Archivo key={"nuevoElemento"+props.nombre}
              nombre={"archivo"}
              id_bloque={props.nombre}
              id_elemento={-1}
              abrir={true}
              GuardarNuevoElemento={GuardarAddElemento}
              cerrarElmento={CerrarNuevoElemento}
              new_index={newIndex}
          />)
        }else if(tipo=="texto_rapido"){
          nuevo.push(<Whatsapp_textoRapido key={"nuevoElemento"+props.nombre}
              nombre={"texto_rapido"}
              id_bloque={props.nombre}
              id_elemento={-1}
              abrir={true}
              GuardarNuevoElemento={GuardarAddElemento}
              cerrarElmento={CerrarNuevoElemento}
              new_index={newIndex}
          />)
        }
        else if(tipo=="lista"){
            nuevo.push(<Whatsapp_Lista key={"nuevoElemento"+props.nombre}
                                             nombre={"lista"}
                                             id_bloque={props.nombre}
                                             id_elemento={-1}
                                             abrir={true}
                                             GuardarNuevoElemento={GuardarAddElemento}
                                             cerrarElmento={CerrarNuevoElemento}
                                             new_index={newIndex}
            />)
        }
        setNuevoElemento(nuevo);


      }

      // else if(bot_actual.conexion=="instagram"){
      //   console.log(tipo)
      //   if(tipo=="texto"){
      //     console.log("AQUIII")
      //     nuevo.push(<Instagram_Texto key={"nuevoElemento"+props.nombre}
      //     nombre={"texto"}
      //     id_bloque={props.nombre}
      //     id_elemento={-1}
      //     abrir={true}
      //     GuardarNuevoElemento={GuardarAddElemento}
      //     cerrarElmento={CerrarNuevoElemento}
      //     />)
      //   }
      //   setNuevoElemento(nuevo);
      // }

    }

    const CerrarNuevoElemento=()=>{
        setNuevoElemento([]) ;
    }


    const MoverElemento=(id_source,id_destino)=>{

      let elm=bloque_actual.elementos;
      let nuevo= elm===undefined?[]:elm.slice();
      //obtener el elemento a mover
      let origen=nuevo[id_source];
      //borrar el elemento a mover
      nuevo.splice(id_source, 1);
      //mover el elemento
      nuevo.splice(id_destino, 0,origen);

      let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+bloque_actual.nombre+"/elementos";
      db.ref(ref)
      .set(nuevo,function(error) {
          if (error) {
              enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
          }else{
            //console.log(origen)
            var obj = {
              fecha: + new Date(),
              nombre: "modificar elemento",
              id_accion: 132,
              id_bot: bot_actual.id,
              red_social: bot_actual.conexion,
              bloque:bloque_actual.nombre,
              elemento:origen.elemento,
            }
            log_it(datos.id_empresa,user.id,obj)
          }
      });

    }

    const AnalizarDragend=(result)=>{

        if(result.destination!==null)
        {
          ///se crar un nuevo elemento
          if(result.source.droppableId==="Drop_Elementos_facebook" && result.destination.droppableId==="Drop_Elementos_actuales")
          {
            let componenete_name=result.draggableId.split("-");          
            AddElemento(componenete_name[1],result.destination.index);

          }else if(result.source.droppableId==="Drop_Elementos_whatsapp" && result.destination.droppableId==="Drop_Elementos_actuales")
          {
            let componenete_name=result.draggableId.split("-");
            AddElemento(componenete_name[1],result.destination.index);

          }else if(result.source.droppableId==="Drop_Elementos_Instagram" && result.destination.droppableId==="Drop_Elementos_actuales")
          {
            let componenete_name=result.draggableId.split("-");
            AddElemento(componenete_name[1],result.destination.index);

          }else if(result.source.droppableId==="Drop_Elementos_webchat" && result.destination.droppableId==="Drop_Elementos_actuales")
          {
            let componenete_name=result.draggableId.split("-");
            AddElemento(componenete_name[1],result.destination.index);

          }else if(result.source.droppableId==="Drop_Elementos_telegram" && result.destination.droppableId==="Drop_Elementos_actuales")
          {
            let componenete_name=result.draggableId.split("-");
            AddElemento_Telegram(componenete_name[1],result.destination.index);

          }else if(result.source.droppableId==="Drop_Elementos_actuales" && result.destination.droppableId==="Drop_Elementos_actuales" &&
                   result.source.index !==  result.destination.index
          ){
              let index_destino=result.destination.index;
              MoverElemento(result.source.index,result.destination.index);
          }




        }

    }

    return (
        <Grid spacing={2} container >
           {loader && <LinearProgress />}
             <DragDropContext onDragEnd={(result)=> AnalizarDragend(result)}>

                <Grid item xs={12}>
                    <Widget
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    disableWidgetMenu
                    >
                    <Grid spacing={2} container>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                startIcon={<ArrowBackIcon />}
                                onClick={regresar}
                            >
                                Regresar
                            </Button>
                        </Grid>

                        <Grid item xs={12}>

                            <Box fontWeight={600} m={1}  fontFamily="Monospace" fontSize="h2.fontSize">
                            {
                              bot_actual.conexion==="facebook"?
                              <FacebookIcon style={{ fontSize: 43,
                                                     color: "#4867aa"
                                                   }}  />
                              :null}
                            {bot_actual.conexion==="whatsapp"?
                                <WhatsAppIcon style={{ fontSize: 43,
                                                     color: "#25cd60"
                                                   }}  />
                              :null
                            }
                            {bot_actual.conexion==="instagram"?
                                <InstagramIcon style={{

                                  fontSize: 43,
                                  borderRadius: '8px',
                                  border:'2px',
                                  background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                                  color:"#FAFAFA"

                                }}  />
                              :null
                            }
                            {bot_actual.conexion==="webchat"?
                                <PublicIcon style={{ fontSize: 43,
                                                     color: "#25cd60"
                                                   }}  />
                              :null
                            }
                            {bot_actual.conexion==="telegram"?
                                <TelegramIcon style={{ fontSize: 43,
                                                     color: "#2ba0d9"
                                                   }}  />
                              :null
                            }
                              {bot_actual.nombre}
                              </Box>

                        </Grid>

                            <Grid item xs={12}>
                                <Grid container alignItems="center" className={style.root}>
                            {/*<Box borderRadius={16} borderTop={1} borderBottom={1} borderColor="secondary.main">*/}
                              <PasosBloque
                                tooltip={"Nuevo bloque"}
                                icono={<LibraryAddIcon/>}
                              />
                              <EditarBloque
                                nombre={bloque_actual===""?"":bloque_actual.nombre}
                                padre={bloque_actual===""?"":bloque_actual.padre}
                                elementos={bloque_actual===""?"":bloque_actual.elementos}
                                cambiarNombre={CambiarNombre}
                                eliminar={EliminarBloque}
                              />
                              <PasosBloque
                                  duplicar={true}
                                  elementos={bloque_actual===""?"":bloque_actual.elementos}
                                  nombre={bloque_actual===""?"":bloque_actual.nombre}
                                  padre={bloque_actual===""?"":bloque_actual.padre}
                                  tooltip={"Duplicar bloque"}
                                  icono={<FileCopyIcon/>}
                              />
                               <Divider orientation="vertical" flexItem />
                               <VentanaListaBloques lista_bloques={lista_bloques} CambiarNombre={CambiarNombre} EliminarBloque={EliminarBloque} />
                          {/*  </Box>*/}
                          </Grid>
                            </Grid>



                        <Grid spacing={2} container>
                          <Grid item xl={2} lg={3} md={4} sm={6} xs={6} >
                          <NuevoElemento
                            nombre={bloque_actual.nombre}
                          />
                          </Grid>

                          <Grid item xl={10} lg={9} md={8} sm={6} xs={6}>

                              {bloque_actual===""?
                                <Grid item xs={12} className={classes.boxBloque}>
                                  <Box justifyContent="center">

                                  <Grid spacing={2} container>
                                      <Grid item xs={12}>
                                        <div className={classes.bloqueElementos}>
                                            <Typography variant="h3">
                                               Iniciar
                                            </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={12}>
                                          <Divider />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <div className={classes.bloqueElementos}>
                                          <Typography variant="h3">
                                             Para ver los elementos de un bloque por favor seleciona un bloque de la lista de bloques.
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <div className={classes.bloqueElementos}>
                                          <Typography variant="h3">
                                            usa el boton   <WebIcon  size="large" style={{ color: "#4867aa"
                                          }}/> de la barra de herramientas para abrir la lista de bloques
                                          </Typography>
                                        </div>
                                      </Grid>
                                    </Grid>

                                  </Box>
                                </Grid>
                              :
                                  <ListaElementos nuevoElemento={nuevoElemento}/>
                              }

                          </Grid>


                        </Grid>

                    </Grid>
                    </Widget>
                </Grid>
           </DragDropContext>
        </Grid>

    );

}

export default EditorBloques;
