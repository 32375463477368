import React, { useState ,useEffect,Component} from 'react';
import { useTheme } from "@material-ui/styles";
import useStyles from './styles';
import Widget from '../../components/Widget';
import {
    Grid,  
    TextField as Input,  
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useUserState} from "../../context/UserContext";
import {db} from "../../services/firebase";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from './componentes/TabPanel';
import a11yProps from './componentes/a11yProps';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ChatIcon from '@material-ui/icons/Chat';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
//componenet
import ListaUsuarios from './componentes/ListaUsuarios'
import {ChatProvider,
        useChatDispatch,
        IniciarDatosBot,
        useChatState,
        MostrarCargarDatos,
        OcultarCargarDatos,GetConversation
    } from './context/ChatContext';
import ChatUser from './componentes/ChatUser';
import Atributos from './componentes/Atributos';
import SalirChatCenter from './componentes/SalirChatCenter';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

function LayoutChat()
{
   
    var classes = useStyles();
    var theme = useTheme();
    var { datos } = useUserState();
    var chatDispach=useChatDispatch();
    var {datos_bot,usuario_actual,cargado_datos,cargando_chat} = useChatState();
    
    const [mostrarSlect,setMostrarSelect]=React.useState(true);
    const [nombrebot,setNombrebot]=React.useState("");
    const [listabot,setListabot]=React.useState([]);
    const [value, setValue] = React.useState(0);


    //Obtener informacion de los bots
    useEffect(() =>{
       // console.log("entro aqui1:"+"usuario_bot/"+datos.id_empresa+"/bots");

       MostrarCargarDatos(chatDispach);
        let conexion_reporte=db.ref("bots/"+datos.id_empresa).orderByChild("nombre").on("child_added",function(snapshot){
            var lista = snapshot.val();

            if(lista.conexiones.whatsapp){            
               setListabot( (prevState)=> {                               
                prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);              
                return prevState;
               });
            }
            OcultarCargarDatos(chatDispach);
        });

        return ()=>{
            db.ref("bots/"+datos.id_empresa).off("child_added",conexion_reporte);        
            db.ref("cliente_chat").off(); 
          }      

    },[]);

  
    var camibarBot = (event)=> {          
        
        setNombrebot(event.target.value);        
        if(event.target.value==="")
        {
            IniciarDatosBot(chatDispach,"", "");
            GetConversation(chatDispach,"")

           
        }else{
            if(event.target.value)
            {
                GetConversation(chatDispach,"")
                MostrarCargarDatos(chatDispach);


                db.ref("cliente_chat/"+datos.id_empresa+"/whatsapp/"+event.target.value.valor).
                orderByChild('atributos/chatcenter').
                on("value",function(snapshot){                
             
                        IniciarDatosBot(chatDispach,event.target.value.valor, snapshot.val()); 
                                          
                }); 
              
            }
            
        }
   
    
    };

    const handleChange = (event, newValue) => {
        //console.log('nuevo_valor',newValue);
        setValue(newValue);
        
      };


  

    return( 
        
    <Grid container spacing={3}>
        <Grid item xs={12} md={12}> 
            <Widget
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    disableWidgetMenu                  
            >
                    <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                        <Grid item xs={12}  >
                            <Typography className={classes.LeyendaPorcentaje}>Seleccione el bot de WhatsApp <WhatsAppIcon/></Typography> 
                        </Grid>                              
                    </Grid>
                    {
                        mostrarSlect &&
                            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>                                                   
                                <Grid item xs={12}  >
                                    <FormControl variant="outlined" className={classes.centrar}>                                  
                                    {  cargado_datos === false&&  
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"                                  
                                            label="Age"
                                            value={nombrebot}
                                            onChange={camibarBot}
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                            {listabot}
                                        </Select>}
                                        {cargado_datos && <CircularProgress />}
                                    </FormControl>

                                </Grid>                           
                            </Grid> 
                    }
                   
            </Widget>     
        </Grid>   
       
 
        <Grid item xs={12} md={12}>   
           
                <Grid container spacing={3}  alignItems={"flex-start"} justify={"space-between"} direction={'row'}>                                                   
                    <Grid item xs={12} md={4}  > 
                        <ListaUsuarios/>
                    </Grid>  
                    <Grid item xs={12} md={8} > 
                    {cargando_chat &&  
                        <ListItem>
                            <ListItemIcon>
                                <CircularProgress />            
                            </ListItemIcon>
                            <ListItemText primary="Loading..." />                          
                        </ListItem>   
                    }                     
                        {datos_bot!=="" && usuario_actual!==""  && datos_bot[usuario_actual.key].atributos.chatcenter && <Alert severity="warning">Atendiendo en Chatcenter: {usuario_actual.user_name} {usuario_actual.key}</Alert>}                       
                        {<ChatUser/>}
                    </Grid>              
                </Grid>

        </Grid>      
            <Atributos/>  
            <SalirChatCenter/>
       
 
    </Grid>                


);

}


class Chatcenter extends React.Component{
    render(){
        return(
            <ChatProvider>
                <LayoutChat/>
            </ChatProvider>
        );
    }
}

export default Chatcenter;