import React, { useState ,useEffect,Component} from 'react';

import {
    NativeSelect, 
    InputLabel,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    FormControl,
} from '@material-ui/core'
import {Button,  Typography, Link } from '../../../components/Wrappers';
import useStyles from '../styles';
import {useChatState,useChatDispatch,CerarSalirChat,DetenerAtenederUsuario} from '../context/ChatContext';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useUserState} from "../../../context/UserContext";
import {db} from "../../../services/firebase";
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function SalirChatCenter (){

    var classes = useStyles();
    var {usuario_actual,datos_bot,salir_chat,bot_actual} = useChatState();    
    var chatDispach=useChatDispatch();
    var { datos } = useUserState();

    const [opcion,setOpcion]= React.useState("10");
    const [cargado,setCargando]= React.useState(false);
    const [error,setError]=React.useState(false);

    const [simular,setSimular]= React.useState(true);

    const CambiarSimularMensaje=(event)=>{
      setSimular(event.target.checked);
    }

    const CambiarValor= (event)=>{
      if(event.target.value==="10"){setSimular(true);}
      if(event.target.value==="20"){setSimular(false);}
        setOpcion(event.target.value);        
    }

    const handleCloseChat=()=>{
        CerarSalirChat(chatDispach);
    }


    function simularMensaje(tipo){

      if(bot_actual!=="" && usuario_actual!=="")
      {


        if(!simular)
        {
          if(opcion==="10"){    
              db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
                chatcenter:false
              }); }         
          if(opcion==="20"){  
            db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
              chatcenter:false
            });    
            db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/user_posicion").update({
              bloque:"default",
              posicion:0 
              });}        

            
            DetenerAtenederUsuario(chatDispach);
            setCargando(false);  
            CerarSalirChat(chatDispach);
        }else{
            let name = usuario_actual.user_name.split(" ");
            let firstname = (name[0]!=null?name[0]:"");
            let lastname = (name[1]!=null?name[1]:"");

            if(opcion==="10"){    
              db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
                chatcenter:false
              }); }         
            if(opcion==="20"){  
              db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
                chatcenter:false
              });    
              db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/user_posicion").update({
                bloque:"default",
                posicion:0 
              });}        

            /*https://bots.automatadg.com/telegram/conexion?info=1,-MEOmd7vz3I6y7JtEalX*/
            fetch('https://bots.automatadg.com/telegram/conexion?info='+bot_actual+','+datos.id_empresa, {
              method: 'POST',                 
              body: JSON.stringify({
                "message":{
                    "chat":{
                        "first_name": firstname,
                        "id": usuario_actual.key,
                        "last_name": lastname,
                        "username": usuario_actual.tg_username
                      },
                      "text": "nextElement"
                }
              }), // data can be `string` or {object}!      
            }).then((response) => {
              //console.log('responmse',response);
                  return response;
                })
                .then((empleados) => { 

                  
                  DetenerAtenederUsuario(chatDispach);
                  setCargando(false);  
                  CerarSalirChat(chatDispach);
                }).catch( (error)=>{
                  //console.log("error al enviar",error);  
                  setCargando(false);         
                  setError(true);
                }); 
        }
          
      } 
    }

    const EjecutarAccion=()=>{
       // console.log("ejecutar accion",opcion);  
        setError(false);
     
        if(usuario_actual!=="" && datos_bot!=="")
        {
            
          //continuar con el flujo normal del bot
          if(opcion==="10")
          {             
             /* setCargando(true);        
              db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
                chatcenter:false
              });*/
              setCargando(true);
              simularMensaje(opcion);
             // DetenerAtenederUsuario(chatDispach);
            
          }         
           //enviar al inicio del flujo
           //user position 0 bloque default
          if(opcion==="20")
          {           
           /* setCargando(true);  
            db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
              chatcenter:false
            });

            db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
              bloque:"default",
              posicion:0 
            });
          */
            setCargando(true);  
            simularMensaje(opcion);
             // DetenerAtenederUsuario(chatDispach);
              
          }        

        }
   

    }

    return(    
        
        <Dialog open={salir_chat} maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Iniciar Chat</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccione una de las opciones para regresar al usuario al flujo del chat.
            </DialogContentText>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">Acción</InputLabel>
                <Select
                native
                value={opcion}
                onChange={CambiarValor}
                label="accion"  
                fullWidth         
                >   
                <option value={"10"}>Continuar el flujo del chat</option>
                <option value={"20"}>Mover al inicio del flujo</option>            
                </Select>
                <FormControlLabel
                  control={
                    <Switch                
                      name="checkedB"
                      color="primary"
                      checked={simular}
                      onChange={CambiarSimularMensaje}
                    />
                  }
                  label="Simular mensaje"
                />
            </FormControl>
          </DialogContent>
          {error && <Alert severity="error">Error al procesar la solicitud</Alert> }
          <DialogActions>
            {cargado && <CircularProgress />}
            {cargado===false && <Button onClick={handleCloseChat} color="primary">
              Cancelar
            </Button>}
            {cargado===false && <Button onClick={EjecutarAccion} color="primary">
              Ejecutar
            </Button>}
          </DialogActions>
        </Dialog>);
}

export default SalirChatCenter;