import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
    Grid,
} from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useStyles from './styleEmpresas';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';

import {storage,db} from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import Alert from '@material-ui/lab/Alert';
import {useAdminDispatch,inicializar_datos} from '../contexto/AdminContext';
import CircularProgress from '@material-ui/core/CircularProgress';
//import DialogContent from '@material-ui/core/DialogContent';
import log_it from './../../../services/log/log'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    iconoEditar:{
        color:"#5dade2"
    },
    textoHeadEmpresa:{
    fontSize :'18px !important',
    display:'block',
    margin:'auto',
    textAlign:'center',
    color:  theme.palette.primary.main,
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Contrato(props) {

    var { datos,user } = useUserState();  
    var adminDispach=useAdminDispatch(); 
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [cargando,setCargando]=React.useState(false);
    const [usuariosAdmin,setUsuariosAdmin]=React.useState(0);
    const [usuariosChat,setUsuariosChat]=React.useState(0);
    const [interacciones,setInteracciones]=React.useState(0);
    const [fechaIni,setFechaIni]=React.useState("");
    const [fechaFin,setFechaFin]=React.useState("");

    const [error,setError] = React.useState(false);
    const [mensajeError,setMensajeError] = React.useState("");

    const handleClickOpen = () => {
        setCargando(true);
        setError(false);
        setOpen(true);        
        leerDatos ();


    };
    const handleClose = () => {
        setOpen(false);
        
    };

    function actualizarDatos()
    {
        inicializar_datos(datos,adminDispach);
    }

    ///informacion de la empresa
    function leerDatos ()
    {
       
        //console.log('usuario_bot/'+props.id+'/informacion/contrato');
        db.ref('usuario_bot/'+props.id+'/informacion/contrato').once('value').then(function(valores){
            return valores.val();
        }).then(function (v){
            if(v)
            {
                setUsuariosAdmin(v.usuarios_admin);
                setUsuariosChat(v.usuarios_chat);
                setInteracciones(v.interacciones);
                setFechaIni(v.inicio);
                setFechaFin(v.fin);
            }else{
                setUsuariosAdmin(0);
                setUsuariosChat(0);
                setInteracciones(0);
                setFechaIni("");
                setFechaFin("");
            }

            setCargando(false);
        }).catch(function(error){
            setMensajeError("Error al leer los datos");
            setError(true);
            setCargando(false);
        });
        

    }

    const  guardarDatos = () =>
    {
       if(datos.admin)
       {
            setCargando(true);
            db.ref('usuario_bot/'+props.id+'/informacion/contrato').update({
                usuarios_admin:usuariosAdmin,
                usuarios_chat:usuariosChat,
                interacciones:interacciones,
                inicio:fechaIni,
                fin:fechaFin,
            }).then(function (parametros){

                var obj = {
                    fecha: + new Date(),
                    nombre: "editar contrato",
                    id_accion: 205,
                    id_empresa: props.id,
                    id_usuario: user.id,
                }
                log_it(datos.id_empresa,user.id,obj)

            setCargando(false);
            setOpen(false);
            actualizarDatos();
            }).catch(function (error){
                setMensajeError("Error al Guardar datos");
                setError(true);
                setCargando(false);
            });
       }else{
        setMensajeError("Operacion no permitida");
        setError(true);
        setCargando(false);
       } 
       
    }

    return (
        <div>
            <Grid item xs={2}>
                <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                    <Tooltip title="Contratos">
                        <IconButton aria-label="Contratos" onClick={handleClickOpen}>
                            <AssignmentIcon className={classes.iconoEditar}/>
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>

            <Dialog
                fullScreen={fullScreen}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                TransitionComponent={Transition}
                maxWidth={'md'}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Contrato
                </DialogTitle>
                <DialogContent dividers>
                        <DialogActions>
                            <TextField
                                fullWidth
                                id="outlined-helperText"
                                label="Cantidad de usuarios Admin"                               
                                variant="outlined"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                                value={usuariosAdmin}
                                onChange={e => setUsuariosAdmin(e.target.value)}
                                error={Number.isInteger(parseInt(usuariosAdmin, 10)) ? false : true}
                                disabled={datos.admin ? false : true}
                            />    
                        </DialogActions>
                        <DialogActions>
                            <TextField
                                fullWidth
                                disabled={datos.admin ? false : true}
                                id="outlined-helperText"
                                label="Cantidad de usuarios de Chat"                             
                                variant="outlined"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                                value={usuariosChat}
                                onChange={e => setUsuariosChat(e.target.value)}
                                error={Number.isInteger(parseInt(usuariosChat, 10)) ? false : true}
                            />
                        </DialogActions>
                        <DialogActions>
                            <TextField
                                fullWidth
                                disabled={datos.admin ? false : true}
                                id="outlined-helperText"
                                label="Interacciones"                              
                                variant="outlined"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                                value={interacciones}
                                onChange={e => setInteracciones(e.target.value)}
                                error={Number.isInteger(parseInt(interacciones, 10)) ? false : true}
                                
                            />
                        </DialogActions>
                        <DialogActions>
                            <TextField
                                fullWidth
                                disabled={datos.admin ? false : true}
                                id="outlined-helperText"
                                label="Fecha inicio"                               
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                                value={fechaIni}
                                onChange={e => setFechaIni(e.target.value)} 
                                error={ fechaIni === ""? true : false} 
                            />
                        </DialogActions>
                        <DialogActions>
                            <TextField
                                fullWidth
                                disabled={datos.admin ? false : true}
                                id="outlined-helperText"
                                label="Fecha fin"                                
                                variant="outlined"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                  }}
                                value={fechaFin}
                                onChange={e => setFechaFin(e.target.value)}
                                error={ fechaFin === ""? true : false} 
                            />
                        </DialogActions>                             
                </DialogContent>
                <DialogActions>
                {  error && <Alert severity="error">{mensajeError}</Alert>}
                {  props.estado !== 'activo' && <Alert severity="error">Empresa inactiva</Alert>}
                { cargando ? 
                    <Typography>Cargando..</Typography>
                    :
                datos.admin &&
                    <Button
                        disabled={
                            props.estado !== 'activo' ||
                            usuariosAdmin < 0 ||
                            usuariosChat < 0 ||
                            interacciones < 0 ||
                            fechaIni === "" ||
                            fechaFin === ""
                        }
                        autoFocus onClick={guardarDatos} color="primary">
                        Guardar cambios
                    </Button>
                }
                    
                </DialogActions>
            </Dialog>
        </div>
    );
}