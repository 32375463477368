import React, { useState ,useEffect} from 'react'
import {
    Grid,
    Box,
    TableRow,
    TextField,
    TableCell,
    Checkbox,
    TableHead,
    TableSortLabel,
    Toolbar,
    IconButton,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
// styles
import useStyles from './styles'
import Avatar from '@material-ui/core/Avatar';
import original from '../../images/original.png'
import Themes from "../../themes"
import { useThemeDispatch } from "../../context/ThemeContext"
import ChildCareIcon from '@material-ui/icons/ChildCare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

// components
import WidgetSetting from '../../components/WidgetSetting'
import {Button,  Typography, Link } from '../../components/Wrappers'
import {
    Delete as DeleteIcon,
    FilterList as FilterListIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import { lighten } from '@material-ui/core/styles'
import cn from 'classnames'
import {db} from "../../services/firebase";
import AppBar from '@material-ui/core/AppBar';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import { useUserState,userNewPassword} from "../../context/UserContext";
// Recent Orders

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy)
}
const useStyles1 = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

function Facebook(props) {
    var classes = useStyles()
    var classes1 = useStyles()
    var theme = useTheme()

    const handlerCopy=(event) =>{
        event.preventDefault();
      }

    const [datosBd, setDatosBd] = React.useState('');
    const [keyBot,setKeyBot]=React.useState('');
    const [tokenBot,setTokenBot]=React.useState('');
    var { datos } = useUserState();
    /*Obtener datos de la base de datos*/

    useEffect(() =>{


            db.ref('bots/'+datos.id_empresa+"/"+props.match.params.id+"/nombre").on(
                'value',snapshot => {
                    var datos=snapshot.val();
                    setDatosBd(datos);
            });
            db.ref('llaves/'+datos.id_empresa+"/facebook/"+props.match.params.id).on(
                'value',snapshot => {
                    var key=snapshot.val();
                    if(key!==null)
                    {
                      setKeyBot(key['fb_key']);
                      setTokenBot(key['fb_token'])
                    }

            });



  },[]);

    return (
      <div className="container">
        <Grid>
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <BrightnessAutoIcon />
              </IconButton>
              <Typography variant="body2" color="inherit">Asistente: {datosBd}</Typography>
            </Toolbar>
          </AppBar>
        </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <List md={2}>
                    <ListItem>
                      <ListItemIcon>
                        <Avatar>
                        <CompareArrowsIcon />
                          </Avatar>
                      </ListItemIcon>
                        <WidgetSetting style={{width:"80%"}}
                            title="Conexión Facebook"
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}>
                            <Grid container spacing={4} justify={"space-between"}  alignItems={"flex-start"}>
                              <Grid item xs={4}>
                                <TextField  disabled id="key" label="Key" fullWidth value={keyBot} InputLabelProps={{
                                  shrink: true,}} onCopy={handlerCopy} />
                              </Grid>
                              <Grid item xs={7}>
                                  <TextField  disabled id="token" label="Token" fullWidth value={tokenBot} InputLabelProps={{
                                          shrink: true,readOnly: true,}} onCopy={handlerCopy} />
                            </Grid>
                            </Grid>
                        </WidgetSetting>
                      </ListItem>
                    </List>
                </Grid>

            </Grid>
      </div>

    )
}

// #######################################################################

function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
    var array = new Array(length).fill()
    let lastValue

    return array.map((item, index) => {
        let randomValue = Math.floor(Math.random() * multiplier + 1)

        while (
            randomValue <= min ||
            randomValue >= max ||
            (lastValue && randomValue - lastValue > maxDiff)
        ) {
            randomValue = Math.floor(Math.random() * multiplier + 1)
        }

        lastValue = randomValue

        return { value: randomValue }
    })
}

export default Facebook;
