import React , { useState, useEffect } from 'react';

export function traducirSaltosDeLinea_A_DobleBackslash(texto){
    var stringfied = JSON.stringify(texto)
    //Eliminamos comillas de inicio y fin
    stringfied = stringfied.replaceAll("\"","")
    //remplazamos los slash simples por dobles
    return stringfied.replace("/(\\n)/g","\\\\")
}

export function traducirDobleBackslash_A_SaltosDeLinea(texto){
    var stringfied = JSON.stringify(texto)
    //Eliminamos comillas de inicio y fin
    stringfied = stringfied.replaceAll("\"","")
    //92 es el numero de caracter de backslash
    //remplazamos los slash simples por dobles
    var x = stringfied.replaceAll(String.fromCharCode(92,92)+"n","\n")
    return x
}

