import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { useUserState} from "../../../../../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#dc004e",
    }
  }),
);

function Parametro(props){

  const classes = useStyles();
  var { datos,user } = useUserState();

  const [nombre,setNombre]= React.useState("");
  const [valor,setValor] = React.useState("");

  useEffect(() =>{

      setNombre(props.nombre);
      setValor(props.valor);

  },[props.nombre,props.valor]);


  const editarValor=(e)=>{
    setValor(e.target.value);
    props.editar((prevState)=>{
      prevState[nombre]=e.target.value;
      return prevState;
    });
  }

  const eliminar=()=>{
      props.eliminar({id:nombre});
  }

  return(
      <Grid item xs={12} >
          <Grid spacing={2} container >
              <Grid item xs={12} sm={5}>
                  <TextField  id="outlined-basic"
                              disabled={true}
                              value={nombre}
                              label="Nombre"
                              variant="outlined"
                              error={nombre.length===0}
                              helperText={nombre.length===0?"El campo es obligatorio":""}
                              disabled={datos.permisos.abcbot.bloques!==true}
                              fullWidth />
              </Grid>
              <Grid item xs={12} sm={5}>
                  <TextField  id="outlined-basic"
                              value={valor}
                              label="Valor"
                              variant="outlined"
                              onChange={editarValor}
                              error={valor.length===0}
                              helperText={valor.length===0?"El campo es obligatorio":""}
                              disabled={datos.permisos.abcbot.bloques!==true}
                              fullWidth />
              </Grid>

        {datos.permisos.abcbot.bloques===true &&
                <Grid item xs={6} sm={2}>
                <Tooltip title="Borrar">
                  <IconButton
                        aria-label="delete"
                        className={classes.colorDelete}
                        onClick={eliminar}
                        >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>}

          </Grid>
      </Grid>
);


}


export default Parametro;
