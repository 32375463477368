import React, { useState ,useEffect,Component} from 'react';
import { useTheme } from "@material-ui/styles";
import useStyles from './styles';
import {makeStyles} from '@material-ui/core/styles'
import Widget from '../../components/Widget';
import {
    Grid,
    TextField as Input,
    Container
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useUserState } from "../../context/UserContext";
import {db,fecha_hora} from "../../services/firebase";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from './componentes/TabPanel';
import a11yProps from './componentes/a11yProps';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ChatIcon from '@material-ui/icons/Chat';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

//Icons
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import YoutubeSearchedForOutlinedIcon from '@material-ui/icons/YoutubeSearchedForOutlined';

//componenet
import ListaUsuarios from './componentes/ListaUsuarios'
import {ChatProvider,
        useChatDispatch,
        IniciarDatosBot,
        useChatState,
        MostrarCargarDatos,
        OcultarCargarDatos,
        GetConversation,

        AgregarDatos,
        ModificarDAtos,
        EliminarDAtos,
        MostarChatcenter,
        NuevoChat,

        MostrarAtributios,
        AtenderUsuario,
        CerarSalirChat,
        AbrirSalirChat



    } from './context/ChatContext';
import ChatUser from './componentes/ChatUser';
import Atributos from './componentes/Atributos';
import SalirChatCenter from './componentes/SalirChatCenter';
import {ListItemText,List,ListItemIcon,ListItem,ListItemAvatar,TextField , Chip, Divider} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import FiltroRed from './componentes/FiltroRed';
import FiltroBot from './componentes/FiltroBot';
import MessageIcon from '@material-ui/icons/Message';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SmsIcon from '@material-ui/icons/Sms';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { ButtonGroup } from '@material-ui/core';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

import log_it from './../../services/log/log';

function LayoutChat(props)
{
    var classes = useStyles();
    const fatherStyle = makeStyles((theme)=>({
      root:{
        display:"flex",
        flexFlow:"column",
        height: "65vh",
        width:"100%"
      }
    }))
    const listasStyle = makeStyles((theme)=>({
      root:{
        flex:"1 1 auto",
        minHeight: "calc(100%)",
        height:"calc(100%)",
        maxHeight: "calc(100%)",
        paddingLeft:'5px',
        paddingRigth:'5px',
        width:"100%",
        overflow:'hidden'
      }
    }));
    const recuadroBotones = makeStyles((theme) => ({
      root: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        borderColor: '#C4C4C4'
      },
    }));

    var listasStyleUse = listasStyle();
    var fatherStyleUse = fatherStyle();
    var recuadroStyleUse = recuadroBotones();

    var theme = useTheme();
    var classes = useStyles();
    var chatDispach=useChatDispatch();
    var {usuario_actual,datos_bot,bot_actual,datos_chat} = useChatState();
    var { datos,user } = useUserState();

    var {datos_bot,usuario_actual,cargado_datos,cargando_chat,inicio_limite,estado_usuario_chatcenter} = useChatState();

    const [nuevoChat,setNuevoChat]=React.useState(false);
    const [mostrarLoad,setMostrarLoad] = React.useState(false);

    const [nombrebot,setNombrebot]=React.useState("");
    const [listabot,setListabot]=React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(null); ///usuario seleccionado

    const[listaBots,setListaBots] = React.useState([]);
    const[listaRedes,setListaRedes]= React.useState([]);

  //  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [obtenerChatSelectedIndex, setObtenerChatSelectedIndex] = React.useState(-1);
  const [loadingObtenerChats,setLoadingObtenerChats] = React.useState(false);
  const[listaUsuarios,setListaUsuarios]= React.useState([]);

  const [iniciarChatcenter,setIniciarChatcenter] = React.useState(false);
    //Obtener informacion de los bots
  /*  useEffect(() =>{
       // console.log("entro aqui1:"+"usuario_bot/"+datos.id_empresa+"/bots");

       MostrarCargarDatos(chatDispach);
        let conexion_reporte=db.ref("bots/"+datos.id_empresa).orderByChild("nombre").on("child_added",function(snapshot){
            var lista = snapshot.val();

            if(lista.conexiones.facebook){
               setListabot( (prevState)=> {
                prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                return prevState;
               });
            }
            OcultarCargarDatos(chatDispach);
        });

        return ()=>{
            db.ref("bots/"+datos.id_empresa).off("child_added",conexion_reporte);
            db.ref("cliente_chat").off();
          }

    },[]);*/




   useEffect(() =>{
       console.log('console', datos.permisos.oldchadcenter)

    return ()=>{

      listaBots.forEach((bot,i) =>{
          listaRedes.forEach((red, i) => {
            db.ref("cliente_chat/"+datos.id_empresa+"/"+red.id+"/"+bot.id).off();
          });
      });
    }



},[]);

  function AgregarUsuarios(red, bot_id,bot_nombre){
    return new Promise(function (fulfill, reject){
      let empresa=datos.id_empresa;
      db.ref("cliente_chat/"+empresa+"/"+red+"/"+bot_id).orderByChild("/atributos/ultima_fecha")
         .once("value",(snapshot)=>{

           let lista_usuarios=[]
           let lista_chat=[];
           let ultimo="";

           snapshot.forEach((item, i) => {
             let info=item.val();

            /*Fecha en formato timestamp*/
             if(info.atributos.ultima_fecha === undefined || info.atributos.ultima_fecha ===null )
             {
               info.atributos.ultima_fecha=0;
             }else{
               if(Number.isInteger(info.atributos.ultima_fecha))
               {
                 info.atributos.ultima_fecha= new Date(info.atributos.ultima_fecha*1000).getTime();
               }else{
                 info.atributos.ultima_fecha= new Date(info.atributos.ultima_fecha).getTime();
               }
             }


             if (info.atributos.chatcenter){
               lista_chat.unshift({
                                         id:empresa+"-"+red+"-"+bot_id+"-"+item.key,
                                         user_id:item.key,
                                         dato:{...info,info:{bot:bot_id,red:red,nombre:bot_nombre}}
                                     });
            }else{
              lista_usuarios.unshift({
                                        id:empresa+"-"+red+"-"+bot_id+"-"+item.key,
                                        user_id:item.key,
                                        dato:{...info,info:{bot:bot_id,red:red,nombre:bot_nombre}}
                                    });
            }
            ultimo=info.atributos.ultima_fecha;

           });
           if(lista_chat.length==0){
            MostarChatcenter(chatDispach,1);
          }

            fulfill({
                     usuarios:lista_usuarios,
                     chat:lista_chat,
                     ultimo:ultimo,
                     red:red,
                     bot_id:bot_id,
                     bot_nombre:bot_nombre,
                   });
            });
       });
    }

    function escucharCambios(red, bot_id,bot_nombre,fecha_ini){
      let empresa=datos.id_empresa;
      //Agregar Ususarios
          if(fecha_ini===0)
          {
            db.ref("cliente_chat/"+empresa+"/"+red+"/"+bot_id).orderByChild("/atributos/ultima_fecha")
               .on("child_added",(snapshot)=>{

                let datos=snapshot.val();
                //  console.log("DATOS DEL CHAAAAAT")
                //  console.log(datos)
                 datos.info={bot:bot_id,red:red,nombre:bot_nombre}


                 AgregarDatos(chatDispach,{
                                           id:empresa+"-"+red+"-"+bot_id+"-"+snapshot.key,
                                           user_id:snapshot.key,
                                           dato:datos
                                         });
              //  console.log("agregar",empresa+"-"+red+"-"+bot_id+"-"+snapshot.key);

             });
          }else{
            fecha_ini=Math.trunc(fecha_ini/1000);
             db.ref("cliente_chat/"+empresa+"/"+red+"/"+bot_id).orderByChild("/atributos/ultima_fecha").startAfter(fecha_ini)
                .on("child_added",function(snapshot){

                  let datos=snapshot.val();

                  datos.info={bot:bot_id,red:red,nombre:bot_nombre}
                  datos.atributos.ultima_fecha = new Date(datos.atributos.ultima_fecha*1000).getTime();
                  AgregarDatos(chatDispach,{
                                            id:empresa+"-"+red+"-"+bot_id+"-"+snapshot.key,
                                            user_id:snapshot.key,
                                            dato:datos
                                          });
            //     console.log("agregar",empresa+"-"+red+"-"+bot_id+"-"+snapshot.key);
              });
          }

            //Modificar usuarios
            db.ref("cliente_chat/"+empresa+"/"+red+"/"+bot_id).on("child_changed",(snapshot)=>{

                let datos=snapshot.val();
                //datos.atributos.ultima_fecha = new Date(datos.atributos.ultima_fecha*1000).getTime();
                datos.info={bot:bot_id,red:red,nombre:bot_nombre}
                console.log('datos a modificar',{
                                           id:empresa+"-"+red+"-"+bot_id+"-"+snapshot.key,
                                           user_id:snapshot.key,
                                           dato:datos
                                         } );
                ModificarDAtos(chatDispach,{
                                           id:empresa+"-"+red+"-"+bot_id+"-"+snapshot.key,
                                           user_id:snapshot.key,
                                           dato:datos
                                         });
             });

             //Eliminar
             db.ref("cliente_chat/"+empresa+"/"+red+"/"+bot_id).on("child_removed",(snapshot)=>{
                  let datos=snapshot.val();
                  datos.atributos.ultima_fecha = new Date(datos.atributos.ultima_fecha*1000).getTime();
                  datos.info={bot:bot_id,red:red,nombre:bot_nombre}
                  //si se eilimina el chat activo
                  if(empresa+"-"+red+"-"+bot_id+"-"+snapshot.key === usuario_actual.id)
                  {
                    db.ref('conversaciones/'+empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
                  }
                  EliminarDAtos(chatDispach,empresa+"-"+red+"-"+bot_id+"-"+snapshot.key);
              });
    }


    function guardarPreferencias(listaBots,listaRedes)
    {
        new Promise((resolve, reject) => {
          db.ref('/config_user/'+user.id+'/configuraciones/ChatCenter').update({
                    bots:listaBots,
                    redes:listaRedes
                },(error) => {
                    resolve();
                });
        });
    }


    const iniciar = ()=>{

        if(listaBots.length>0 && listaRedes.length>0)
        {

    /*      db.ref('/usuarios/'+user.id+'/configuraciones/ChatCenter').update({
              bots:listaBots,
              redes:listaRedes
          });*/
          guardarPreferencias(listaBots,listaRedes);

          setIniciarChatcenter(true);
          setLoadingObtenerChats(true);

          setMostrarLoad(true);

          let lista_datos_prom=[];
          var listaBotsToLog = []
          var listaRedesToLog = []
          for(var i in  listaBots){
            listaBotsToLog.push(listaBots[i].id)
          }
          for(var j in listaRedes){
            listaRedesToLog.push(listaRedes[j].id)
          }

          var obj = {
            fecha: + new Date(),
            nombre: "iniciar chatcenter",
            id_accion: 400,
            id_bot: listaBotsToLog,
            red_social: listaRedesToLog,
          }
          log_it(datos.id_empresa,user.id,obj)
          listaBots.forEach((bot,i) =>{
              listaRedes.forEach((red, i) => {
              //  AgregarUsuarios(red.id,bot.id,bot.title);
                lista_datos_prom.push(AgregarUsuarios(red.id,bot.id,bot.title));
              });
          });

          Promise.all(lista_datos_prom).then(function(results) {
              let lista_resultados=[];
              let lista_res_chat=[];

              results.forEach(function(obj) {


                //Aqui termina todo?
                lista_resultados=lista_resultados.concat(obj.usuarios)
                lista_res_chat=lista_res_chat.concat(obj.chat);
                escucharCambios(obj.red, obj.bot_id,obj.bot_nombre,obj.ultimo);
                lista_resultados.sort(OrdernarDatos);
                lista_res_chat.sort(OrdenarDatosChat);
                IniciarDatosBot(chatDispach,lista_resultados,lista_res_chat);
                setMostrarLoad(false);
                setNuevoChat(true);

              });
              setLoadingObtenerChats(false);


          });

        }else{
           console.log("no hay datos");
        }


    }

    function OrdernarDatos(a, b){

      let nuevo_a=a.dato.atributos.ultima_fecha ;
      let nuevo_b=b.dato.atributos.ultima_fecha;

      if(nuevo_b === undefined && nuevo_a === undefined)
      {
        return 0;
      }

      if(nuevo_a === undefined)
      {
        return 1;
      }

      if(nuevo_b === undefined)
      {
        return -1;
      }

      if (nuevo_a < nuevo_b) {
        return 1;
      }
      if (nuevo_a > nuevo_b) {
        return -1;
      }
      // a must be equal to b
      return 0;

    }

    function OrdenarDatosChat(a,b){


      if(b.dato.atributos.fecha_chatcenter === undefined && a.dato.atributos.fecha_chatcenter === undefined)
      {
        return 0;
      }

      if(a.dato.atributos.fecha_chatcenter === undefined)
      {
        return -1;
      }

      if(b.dato.atributos.fecha_chatcenter === undefined)
      {
        return 1;
      }


      let nuevo_a=  new Date(a.dato.atributos.fecha_chatcenter).getTime();
      let nuevo_b=  new Date(b.dato.atributos.fecha_chatcenter).getTime();


      if (nuevo_a < nuevo_b) {
        return 1;
      }
      if (nuevo_a > nuevo_b) {
        return -1;
      }
      // a must be equal to b
      return 0;
    }

    const Iniciar_nuevoChat= ()=>{

      var obj = {
        fecha: + new Date(),
        nombre: "detener chatcenter",
        id_accion: 403,
      }

      /*Apagar escuchadores y terminar de completar el obj*/
      if(usuario_actual!=="")
      {
        db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
        obj["id_bot"] = usuario_actual.info.bot;
        obj["red_social"] = usuario_actual.info.red;
        obj["usuario"] = usuario_actual.key;
        obj["nombre_usuario"] = usuario_actual.user_name;
      }

        log_it(datos.id_empresa,user.id,obj)


      listaBots.forEach((bot,i) =>{
          listaRedes.forEach((red, i) => {
            db.ref("cliente_chat/"+datos.id_empresa+"/"+red.id+"/"+bot.id).off();
          });
      });
      setNuevoChat(false);
        NuevoChat(chatDispach);


    }


const mostrarAtributos=()=>{
  //console.log("mostrar atributos");
    if(usuario_actual!=="" && datos_bot!=="")
    {
      MostrarAtributios(chatDispach);
      var obj = {
        fecha: + new Date(),
        nombre: "visualizar atributos",
        id_accion: 410,
        id_bot: usuario_actual.info.bot,
        red_social: usuario_actual.info.red,
        usuario:usuario_actual.key,
        nombre_usuario:usuario_actual.user_name
      }
      log_it(datos.id_empresa,user.id,obj)
    }
}

const IniciarChat=()=>{
if(usuario_actual!=="")
{
  db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos").update({
    chatcenter:true,
    fecha_chatcenter:fecha_hora.database.ServerValue.TIMESTAMP,
  });
  var obj = {
    fecha: + new Date(),
    nombre: "atender usuario",
    id_accion: 402,
    id_bot: usuario_actual.info.bot,
    red_social: usuario_actual.info.red,
    usuario:usuario_actual.key,
    nombre_usuario:usuario_actual.user_name
  }
  log_it(datos.id_empresa,user.id,obj)

  AtenderUsuario(chatDispach);
  handle_ChatcenterActualizarAtendiendo(
    user,
    datos,
    usuario_actual,
  );
}
};

const MostrarSalir=()=>{
if(usuario_actual!=="" && datos_bot!=="")
{
  if(usuario_actual.chatcenter)
  {
    AbrirSalirChat(chatDispach);
    var obj = {
      fecha: + new Date(),
      nombre: "finalizar atención",
      id_accion: 403,
      id_bot: usuario_actual.info.bot,
      red_social: usuario_actual.info.red,
      usuario:usuario_actual.key,
      nombre_usuario:usuario_actual.user_name
    }
    log_it(datos.id_empresa,user.id,obj)
  }
}

}

  const obtenerChatcenterUsuarios = (newValue) => {
    if(usuario_actual!==""){
      db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
    }
    //setSelectedIndex(null);
    GetConversation(chatDispach,"");
    MostarChatcenter(chatDispach,newValue);
  //  setValue(newValue);

  }

function handle_ChatcenterActualizarAtendiendo(user,datos,usuario_actual){
  // console.log("ABC")
  return new Promise(function (fulfill, reject){

          if(usuario_actual==="")
          {
            fulfill();
          }

          //--ALGORITMO
          //EVALUAR Si el chat tiene un asesor actualmente
          // console.log("usuario actual log",usuario_actual);

          if(usuario_actual.asesor === ""){
            // no tiene un asesor
            // No tiene un asignado un log_chatcenter
            var obj={
              estado:true,
              fecha_ini:fecha_hora.database.ServerValue.TIMESTAMP,
              fecha_usuario:fecha_hora.database.ServerValue.TIMESTAMP,
              id:usuario_actual.key,
              usuario:user.id,
              nombre:datos.nombre,
            }
            var refPush = db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/").push(obj,function(err){
              var log_channel_id = refPush.key
              db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/")
              .update({
                log_chatcenter:log_channel_id,
                asesor_id:user.id,
                asesor_name:datos.nombre,
              });
            });
            fulfill();
          }else{
            //ya tiene asociado log_chatcenter
            //Si quien atiende no es el mismo que ya estaba atendiendo entonces tengo que actualizar

            //1.pasar al historial el que estaba actualmente en log_chatcenter
            //2.actualizar al chat center su nuevo asesor_id y asesor_name
            //./1
            //obtener el historial
            if(usuario_actual.asesor!==user.id){
              db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).once('value').then(function(snap){
                return snap.val();
              }).then(function (log_chatcenter){

                  let historial=[];
                    if(log_chatcenter===null){
                      fulfill();
                      return 0;
                    }

                    if(('historial' in log_chatcenter)){
                      historial=log_chatcenter.historial;
                    }

                    historial.push({
                      fecha_ini:log_chatcenter.fecha_usuario,
                      fecha_fin:fecha_hora.database.ServerValue.TIMESTAMP,
                      usuario:log_chatcenter.usuario,
                      nombre:log_chatcenter.nombre
                    }
                    );

                  db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).
                    set({
                        estado:true,
                        fecha_ini:log_chatcenter.fecha_ini,
                        id:log_chatcenter.id ,
                        historial: historial,
                        fecha_usuario:  fecha_hora.database.ServerValue.TIMESTAMP,
                        usuario:user.id,
                        nombre:datos.nombre
                    });
                    //./2
                    db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/asesor_id").set(user.id);
                    db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/asesor_name").set(datos.nombre);

                    fulfill();

              });
            }else{
              fulfill();
            }
          }
  });

}



    return(
        <>
        {!datos.permisos.oldchatcenter ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                        >

                            <Grid container spacing={1}  alignItems={"center"} justify={"center"} direction={'row'}>
                                <Grid item sm={12} md={12} >
                                    <Typography className={classes.textoHeadEmpres} variant="body1" >Para continuar usando ChatCenter, por favor ingresa a la nueva versión.</Typography>
                                </Grid>
                                <Grid item sm={12} md={12} style={{marginTop:'10px', justifyContent: 'center'}}>
                                    <Button variant="contained" color="primary" href="https://chat.automatadg.com" target="_blank">
                                        Chatcenter
                                    </Button>
                                </Grid>
                            </Grid>
                        </Widget>
                    </Grid>
                </Grid>
            ): (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                        >
                            <Grid container spacing={1}  alignItems={"center"} justify={"center"} direction={'row'}>
                                <Grid item sm={12} md={6} >
                                    <FormControl variant="outlined"  className={classes.centrar}>
                                        <FiltroRed lista={setListaRedes} disable={nuevoChat} />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} md={6}>
                                    <FormControl variant="outlined" className={classes.centrar}>
                                        <FiltroBot lista ={setListaBots} disable={nuevoChat} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container
                                  direction="row"

                                  alignItems="center"
                                  style={{paddingLeft:"0px",marginTop:"8px"}}
                            >
                                <Grid item xs={12}  sm={6} md={6} lg={2} style={{paddingLeft:"0px",marginRight:"8px"}} className={recuadroStyleUse.root}>

                                    <BottomNavigation
                                        value={obtenerChatSelectedIndex}
                                        style={{borderRadius:"8px"}}

                                        onChange={(event, newValue) => {

                                            if(newValue==0){
                                                iniciar()

                                            }else{
                                                GetConversation(chatDispach,"");
                                                Iniciar_nuevoChat()
                                                setIniciarChatcenter(false);
                                            }
                                            setObtenerChatSelectedIndex(newValue)

                                            if(usuario_actual!==""){
                                                db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
                                            }
                                            //setSelectedIndex(null);
                                            MostarChatcenter(chatDispach,newValue);
                                            //  setValue(newValue);
                                        }}
                                        showLabels
                                    >
                                        <BottomNavigationAction disabled={iniciarChatcenter===true} label="Obtener chats" icon={<PlayCircleFilledWhiteOutlinedIcon/>}   className={classes.navegatorChat}/>
                                        <BottomNavigationAction disabled={iniciarChatcenter===false} label="Nuevo chat" icon={<YoutubeSearchedForOutlinedIcon/>}   className={classes.navegatorChat}/>
                                    </BottomNavigation>
                                </Grid>

                                <Grid item xs={12}  sm={6} md={6} lg={2} className={recuadroStyleUse.root} style={{marginRight:"8px"}} >
                                    <BottomNavigation
                                        value={estado_usuario_chatcenter}
                                        style={{borderRadius:"8px"}}
                                        onChange={(event, newValue) => {


                                            if(usuario_actual!==""){
                                                db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
                                            }
                                            //setSelectedIndex(null);
                                            GetConversation(chatDispach,"");
                                            MostarChatcenter(chatDispach,newValue);
                                            //  setValue(newValue);
                                        }}
                                        showLabels
                                    >
                                        <BottomNavigationAction label="ChatCenter" icon={<PersonOutlineIcon />} className={classes.navegatorChatSecondary} />
                                        <BottomNavigationAction label="Usuarios" icon={<SmsIcon />}   className={classes.navegatorChatSecondary} />
                                    </BottomNavigation>

                                </Grid>
                                {/* <Grid item xs={4} className={recuadroStyleUse.root}>
                                  <BottomNavigation
                                    value={estado_usuario_chatcenter}
                                    onChange={(event, newValue) => {
                                      if(newValue==0){
                                        iniciar()
                                      }else{
                                        Iniciar_nuevoChat()
                                      }
                                    }}
                                    showLabels
                                    >
                                    <BottomNavigationAction label="ChatCenter" icon={<PersonOutlineIcon/>}   />
                                    <BottomNavigationAction label="Usuarios" icon={<HighlightOffOutlinedIcon/>}   />
                                  </BottomNavigation>
                                </Grid> */}
                                <Grid item xs={12}  sm={6} md={6} lg={2} className={recuadroStyleUse.root} style={{paddingTop:"8px"}}>
                                    <Grid container
                                          direction="row"
                                          alignItems="center" >
                                        <Grid item xs={4} style={{textAlign:"center"}}>
                                            <Tooltip title="Iniciar atención">
                                                <IconButton
                                                    onClick={IniciarChat}
                                                    style={{color:'#00701a'}}
                                                    color="primary" aria-label="Finalizar atención" component="span"
                                                >
                                                    <SpeakerNotesIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={4} style={{textAlign:"center"}}>
                                            <Tooltip title="Atributos del cliente">
                                                <IconButton
                                                    onClick={mostrarAtributos}
                                                    style={{color:'#ffa000'}}
                                                    color="primary" aria-label="Atributos del cliente" component="span"
                                                >
                                                    <ListAltOutlinedIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={4} style={{textAlign:"center"}}>
                                            <Tooltip title="Finalizar atención">
                                                <IconButton
                                                    onClick={MostrarSalir}
                                                    style={{color:'#8e0000'}}
                                                    color="primary" aria-label="Finalizar atención" component="span"
                                                >
                                                    <SpeakerNotesOffIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* </Grid> */}
                                <Grid item xs={3} style={{paddingLeft:"8px",flexBasis:"39.66667%"}}>
                                    { datos_bot!=="" && usuario_actual!==""  &&
                                        <Grid container >
                                            <ListItem className={classes.usuarioActual}>
                                                <Grid item xs={2}>

                                                    <Box fontWeight={600} sm={12}  fontFamily="Monospace" fontSize="3.fontSize">
                                                        {
                                                            usuario_actual.info.red==="facebook"?
                                                                <FacebookIcon style={{ fontSize: 43,
                                                                    color: "#4867aa"
                                                                }}  />
                                                                :null}
                                                        {usuario_actual.info.red==="whatsapp"?
                                                            <WhatsAppIcon style={{ fontSize: 43,
                                                                color: "#25cd60"
                                                            }}  />
                                                            :null
                                                        }
                                                        {usuario_actual.info.red==="instagram"?
                                                            <InstagramIcon style={{

                                                                fontSize: 43,
                                                                borderRadius: '8px',
                                                                border:'2px',
                                                                background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                                                                color:"#FAFAFA"

                                                            }}  />
                                                            :null
                                                        }
                                                        {bot_actual.nombre}
                                                    </Box>

                                                </Grid>
                                                <ListItemAvatar style={{paddingBottom:'2px'}}>
                                                    <Avatar className={classes.usuarioActualAvatar} alt={usuario_actual.user_name}>{usuario_actual.user_name!==undefined?usuario_actual.user_name.charAt(0):"X"}</Avatar>
                                                </ListItemAvatar>
                                                <ListItemText className={classes.usuarioActualTitulo} primary={usuario_actual.user_name} secondary={
                                                    <React.Fragment>
                                                        <Typography component="span"
                                                                    className={classes.usuarioActualSubTitulo}>
                                                            {usuario_actual.key}
                                                        </Typography>
                                                    </React.Fragment>} />
                                            </ListItem>
                                        </Grid>

                                    }
                                </Grid>
                            </Grid>


                        </Widget>
                    </Grid>


                    <Grid item xs={12} md={12}
                          style={{height:"*", maxHeight:"max-content"}}
                    >

                        <div className={fatherStyleUse.root}>
                            <div className={listasStyleUse.root} >
                                <Grid container spacing={3} alignItems={"flex-start"} justify={"space-between"} direction={'row'}
                                      style={{overflow:"hidden", height:"*", maxHeight:"max-content",display:"flex", width:"105%"}}
                                >


                                    {/* </div>

                  <div className={listasStyleUse.root} > */}
                                    <Grid style={{overflowY:"auto"}} item xs={12} md={4}  >
                                        {loadingObtenerChats?
                                            <ListItem>
                                                <ListItemIcon>
                                                    <CircularProgress />
                                                </ListItemIcon>
                                                <ListItemText primary="Loading..." />
                                            </ListItem>
                                            :
                                            <ListaUsuarios listaUsuarios={listaUsuarios} seleccionar={selectedIndex} setSeleccionar={setSelectedIndex} />
                                        }
                                    </Grid>
                                    <Grid style={{overflow:"none", height:"*", maxHeight:"auto"}}  item xs={12} md={8} >
                                        {cargando_chat &&
                                            <ListItem>
                                                <ListItemIcon>
                                                    <CircularProgress />
                                                </ListItemIcon>
                                                <ListItemText primary="Loading..." />
                                            </ListItem>
                                        }
                                        {/* {usuario_actual!==""  && usuario_actual.chatcenter && <Alert severity="warning">Atendiendo en Chatcenter: {usuario_actual.user_name} {usuario_actual.key}</Alert>} */}
                                        <Grid
                                            item xs={12} md={12}
                                            style={{width:"94%"}} >
                                            <ChatUser
                                                usuario_actual={usuario_actual} usuario_actual_chatcenter={usuario_actual.chatcenter}/>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                    </Grid>


                    <Atributos/>
                    <SalirChatCenter/>

                </Grid>

            )}
</>
);

}



class Chatcenter extends React.Component{
    render(){
        return(
            <ChatProvider>
                <LayoutChat/>
            </ChatProvider>
        );
    }
}

export default Chatcenter;
