import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import {
    Grid,
    TextField as Input
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";
import { useUserState,userNewPassword} from "../../context/UserContext";
import {storage,db} from "../../services/firebase";
import Themes from "../../themes"
import { useThemeDispatch } from "../../context/ThemeContext";
import ReactApexChart from 'react-apexcharts'
import { useTheme } from "@material-ui/styles";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import MessageIcon from '@material-ui/icons/Message';


const Plan = () => {

    var classes = useStyles();
    var theme = useTheme();
    var { user,datos } = useUserState();

    const [interacciones,setInteracciones] = React.useState("");
    const [contadorInteracciones,setContadorInteracciones] = React.useState("");
    const [usuariosAdmin,setUsuariosAdmin]= React.useState("");
    const [usuariosChat,setUsuariosChat]= React.useState("");
    const [contadorAdmin,setContadorAdmin]= React.useState("");
    const [contadorChat,setContadorChat]= React.useState("");

    useEffect(() =>{
        let datos_empresa=db.ref('usuario_bot/'+datos.id_empresa+'/informacion').on('value',(snapshot)=>{
            var info_empresas=snapshot.val();

            setInteracciones(info_empresas.contrato.interacciones);
            setContadorInteracciones(info_empresas.contrato.conteo_interaccion);
            setUsuariosAdmin(info_empresas.contrato.usuarios_admin);
            setUsuariosChat(info_empresas.contrato.usuarios_chat);
            setContadorAdmin(info_empresas.contadores.admin);
            setContadorChat(info_empresas.contadores.chat);

        });

        return()=>{
            db.ref('usuario_bot/'+datos.id+'/informacion').off('value',datos_empresa);
        }

    },[]);

    const bot1= {

        series: [(100*contadorInteracciones)/interacciones],
        options: {
          chart: {
            width:'10',
            type: 'radialBar',
            offsetY: 0
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: '18px',
                  color: theme.palette.primary.light,
                  offsetY: 90
                },
                value: {
                  offsetY: 45,
                  fontSize: '18px',
                  color: theme.palette.primary.light,
                  formatter: function (val) {
                    return val + "%";
                  }
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65, 91]
            },
          },
          stroke: {
            dashArray: 2,
          },
          labels: ['Interacciones consumidas'],
        },


      };

    return (
         <Grid container spacing={3} alignItems={"center"}>
                <Grid item sm={12}  md={6}>
                    <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                    >

                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item >
                                <Typography className={classes.titulos}>
                                    Interacciones  <MessageIcon/>
                                </Typography>
                            </Grid>


                            <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                <Grid item xs={12}   >
                                    <ReactApexChart options={bot1.options} series={bot1.series} type="radialBar" height={200} />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                <Grid item xs={6} className={classes.titulos}  >
                                    <Typography className={classes.texto}>
                                        {interacciones === '' ? <AllInclusiveIcon /> : interacciones}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}  className={classes.titulos} >
                                    <Typography className={classes.texto}>
                                        {contadorInteracciones}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                <Grid item xs={6} className={classes.titulos}  >
                                    <Typography>
                                        contratadas
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}  className={classes.titulos} >
                                    <Typography>
                                        Consumidas
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>

        </Grid>
    );
};

export default Plan;
