import React from "react";
import { useUserState } from "../context/UserContext";


function Root ({ children }) {

      
        var { authReady } = useUserState();
       
        if(!authReady)
        {
            return (<div>Loading data user...</div>);
        }else{
        return (<div>{children}</div>);

        }           

}



export default Root;