import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';


import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  tamno:{
  maxHeight:"max-content",
  maxWidth:"max-content",
  justifyContent: "center",
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PantallaCompleta(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
    {
      props.tipo ===1 ?
      <Tooltip title="Ver pantalla completa">
        <IconButton
            href={props.url} target="_blank"
            >
            <VisibilityIcon />
        </IconButton>
      </Tooltip>

      :
      <Tooltip title="Ver pantalla completa">
        <IconButton
            onClick={handleClickOpen}
            >
            <VisibilityIcon />
        </IconButton>
      </Tooltip>
    }




      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Cerrar
            </Typography>
          </Toolbar>
        </AppBar>
        {props.tipo === 0 && <img src={props.url} className={classes.tamno}/>}
        {props.tipo === 2 &&
          <audio controls>
            <source src={props.url} />
                Tu navegador no soporta audio HTML5.
          </audio>
        }
        {props.tipo === 3 &&
          <video controls>
            <source src={props.url} />
          </video>
        }
      </Dialog>
    </div>
  );
}
