import React from 'react';

import {storage,db,fecha_hora} from "../../../services/firebase";
import log_it from './../../../services/log/log';
var ChatStateContext= React.createContext();
var ChatDispatchContext= React.createContext();

function chatReducer(state,action)
{
    switch(action.type)
    {


        case "GET_CONVERSATION":
            return {...state,
                usuario_actual:action.usuario_actual,
                //atender_usuario:false,
            }

        case "MOSTRAR_ATRIBUTOS":
            return {...state,
                atributos:true,
            }
        case "CERRAR_ATRIBUTOS":
            return {...state,
                atributos:false,
            }
        case "ATENDER_USUARIO":
            return {...state,
                atender_usuario:true,
            }
        case "DETENER_ATENDER_USUARIO":
            return {...state,
                atender_usuario:false,
            }

        case "ABRIR_SALIRCHAT":
            return {...state,
                salir_chat:true,
            }

        case "CERRAR_SALIRCHAT":
            return {...state,
                salir_chat:false,
            }

        case "MOSTRAR_CARGARDATOS":
            return {...state,
                cargado_datos:true,
            }

        case "CERRAR_CARGARDATOS":
            return {...state,
                cargado_datos:false,
            }

        case "CARGANDO_CHAT":
            return {...state,
                cargando_chat:true,
        }

        case "OCULTAR_CARGANDOCHAT":
            return {...state,
                cargando_chat:false,
        }

/*---------------------------------------------------------------------------------------*/
        case "NUEVO_CHAT":
          return{
              ...state,
              usuario_actual:"",
              atributos:false,
              atender_usuario:false,
              salir_chat:false,
              cargado_datos:false,
              cargando_chat:false,

              bot_actual:"",
              datos_bot:[],
              datos_chat:[],
              estado_usuario_chatcenter:0,

              index_listaUsuarios:null,
              index_listaChat:null,
              limite_chat:20,
              limite_usuario:20,
              actualizar_atributo:false,
              refrencia_actual:null,
              listaArchivos_Usuarios:[],
          }

        case "CHATCENTER":
          return{
            ...state,
            estado_usuario_chatcenter:action.chatcenter,
          }

        case "INICIAR_DATOS":
            return {...state,
                datos_bot:action.datos_bot,
                datos_chat:action.chat,
            }

       case "AGREGAR_DATOS":///
            if(action.lista.dato.atributos.chatcenter)///si es
            {
              let index_agregar = state.datos_chat.findIndex(item => item.id === action.lista.id);
              if(index_agregar===-1){
                state.datos_chat.unshift(action.lista);
              }
              return{...state,
                bot_actual:1,
              }
            }else{
              let index_agregar = state.datos_bot.findIndex(item => item.id === action.lista.id);
              if(index_agregar===-1){
                state.datos_bot.unshift(action.lista);
              }
              return{...state,
                bot_actual:1,
              }
            }


        case "MODIFICAR_DATOS":
          let buscar_chat = state.datos_chat.findIndex(item => item.id === action.registro.id);
          //si eesta en la lista de chat center
          if(buscar_chat!==-1){
            //si el registro que viene ya no es chat Center

                  if(!action.registro.dato.atributos.chatcenter)
                  {
                    state.datos_chat.splice(buscar_chat,1);
                    state.datos_bot.unshift(action.registro);
                    //si el que se elimino es el bot_actual se pone en useFunctionsEmulator
                    if(state.usuario_actual!=="" && action.registro.id === state.usuario_actual.id)
                    {
                      state.usuario_actual="";
                      state.index_listaChat=null;
                      state.refrencia_actual.off();

                    }
                  }else{

                    ///si el registro q viene es de mayor fecha de intereaccion
                    let ultima_fecha=state.datos_chat[0].dato.atributos.fecha_chatcenter;
                    if(action.registro.id!==state.datos_chat[0].id && action.registro.dato.atributos.fecha_chatcenter>= ultima_fecha)
                    {
                          if(state.index_listaChat===state.limite_chat)
                          {
                            state.index_listaChat=null;
                            state.refrencia_actual.off();
                          }else{
                            if(state.index_listaChat!==null){
                              //state.index_listaChat= state.index_listaChat+1;
                            }
                          }

                          state.datos_chat.splice(buscar_chat,1);
                          state.datos_chat.unshift(action.registro);

                    }else{
                        state.datos_chat[buscar_chat]= action.registro;
                    }

                  }

          }else{
            /*------------------Lista de no chatcenter------------*/
            let buscar_datos = state.datos_bot.findIndex(item => item.id === action.registro.id);
                if(buscar_datos!==-1)
                {
                      if(action.registro.dato.atributos.chatcenter)
                      {
                        state.datos_bot.splice(buscar_datos,1);
                        state.datos_chat.unshift(action.registro);
                        //si el que se elimino es el bot_actual se pone en useFunctionsEmulator
                        if(state.usuario_actual!=="" && action.registro.id === state.usuario_actual.id)
                        {
                          state.index_listaUsuarios=null;
                          state.index_listaChat=action.registro.id;
                          state.estado_usuario_chatcenter=0;
                        }
                  }else{
                        let ultima_fecha=state.datos_bot[0].dato.atributos.ultima_fecha;
                        if( action.registro.id!==state.datos_bot[0].id && action.registro.dato.atributos.ultima_fecha>= ultima_fecha)
                        {
                              if(state.index_listaUsuarios===state.limite_usuario)
                              {
                                state.index_listaUsuarios=null;
                                state.refrencia_actual.off();
                              }else{
                                if(state.index_listaUsuarios!==null){
                                  state.index_listaUsuarios= state.index_listaUsuarios+1;
                                }
                              }
                              state.datos_bot.splice(buscar_datos,1);
                              state.datos_bot.unshift(action.registro);


                        }else{
                            state.datos_bot[buscar_datos]= action.registro;
                        }
                  }
            }
         }

         /*-------------------------Actualizar el usuario actual-----------------*/
         if(state.usuario_actual!=="" && state.usuario_actual.id===action.registro.id){
           state.actualizar_atributo? state.actualizar_atributo=false: state.actualizar_atributo=true

           state.usuario_actual.chatcenter= action.registro.dato.atributos.chatcenter;
           state.usuario_actual.log_chatcenter= action.registro.dato.atributos.log_chatcenter===undefined? "": action.registro.dato.atributos.log_chatcenter;
           state.usuario_actual.asesor= action.registro.dato.atributos.asesor_id===undefined? "": action.registro.dato.atributos.asesor_id;


         }
         return{...state}

        case "ELIMINAR_DATOS":
          let buscar_chat_eliminar = state.datos_chat.findIndex(item => item.id === action.id);
          if(buscar_chat_eliminar!==-1){

            ///si el que se elimino es el seleccionado
            if(state.usuario_actual!=="" && action.id === state.usuario_actual.id)
            {
               state.index_listaChat=null;
               state.usuario_actual="";
            }
            state.datos_chat.splice(buscar_chat_eliminar,1);

          }else{
            let buscar_datos_eliminar = state.datos_bot.findIndex(item => item.id === action.id);
            if(buscar_datos_eliminar!==-1)
            {
              ///si el que se elimino es el seleccionado
              if(state.usuario_actual!=="" && action.id === state.usuario_actual.id)
              {
                 state.index_listaUsuarios=null;
                 state.usuario_actual="";
              }
              state.datos_bot.splice(buscar_datos_eliminar,1);
            }
          }

          return{...state}


        case "CAMBIAR_INDEXUSUARIOS":
        return{...state,
              index_listaUsuarios:action.index
              }

        case "CAMBIAR_INDEXCHAT":
        return{...state,
              index_listaChat:action.index
              }

        case "AUMENTAR_LIMITEUSUARIO":
        return{...state,
              limite_usuario:state.limite_usuario+20
              }

        case "AUMENTAR_LIMITECHAT":
        return{...state,
              limite_chat:state.limite_chat+20
              }

        case "REFERENCIA_ACTUAL":
        return{...state,
              refrencia_actual:action.ref
              }

        case "ARCHIVOS_USUARIOS":
          if(action.tipo ===0){

            return{...state ,
                  listaImagenes: action.archivos
                  }

          }else if(action.tipo ===1){

            return{...state ,
                  listaArchivos: action.archivos
                  }

          }else if(action.tipo ===2){

            return{...state ,
                  listaAudios: action.archivos
                  }

          }else if(action.tipo ===3){

            return{...state ,
                  listaVideos: action.archivos
                  }

          }


        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
            }
    }

}

function ChatProvider({ children })
{
    var [state,dispatch]= React.useReducer( chatReducer,{
        usuario_actual:"",
        atributos:false,
        atender_usuario:false,
        salir_chat:false,
        cargado_datos:false,
        cargando_chat:false,

        bot_actual:"",
        datos_bot:[],
        datos_chat:[],
        estado_usuario_chatcenter:0,

        index_listaUsuarios:null,
        index_listaChat:null,
        limite_chat:20,
        limite_usuario:20,
        actualizar_atributo:false,

        refrencia_actual:null,

        listaArchivos:[],
        listaImagenes:[],
        listaAudios:[],
        listaVideos:[],

    });


    return (
        <ChatStateContext.Provider value={state}>
            <ChatDispatchContext.Provider value={dispatch}>
                {children}
            </ChatDispatchContext.Provider>
        </ChatStateContext.Provider>
    );

}

function useChatState(){
    var context = React.useContext(ChatStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}

function useChatDispatch(){
    var context = React.useContext(ChatDispatchContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
      }
      return context;
}

export {ChatProvider,
        useChatState,
        useChatDispatch,
        IniciarDatosBot,
        GetConversation,
        MostrarAtributios,
        CerrarAtributos,
        AtenderUsuario,
        DetenerAtenederUsuario,
        CerarSalirChat,
        AbrirSalirChat,
        MostrarCargarDatos,
        OcultarCargarDatos,
        MostrarCargarChat,
        OcultarCargarChat,

        AgregarDatos,
        ModificarDAtos,
        EliminarDAtos,
        AumentarLimite,
        MostarChatcenter,
        NuevoChat,
        CambiarIndexUsuarios,
        CambiarIndexChat,
        AumentarLimiteChat,
        AumentarLimiteUsuario,
        ReferenciaActual,
        ArchivosUsuarios,
        EnviarMensaje

    }



const MostrarAtributios =(dispatch) => {
    //console.log("context mostrar atributos");
    dispatch({
        type: "MOSTRAR_ATRIBUTOS",
    });
}

const CerrarAtributos =(dispatch) => {
    dispatch({
        type: "CERRAR_ATRIBUTOS",
    });
}


const AtenderUsuario =(dispatch) => {
    dispatch({
        type: "ATENDER_USUARIO",
    });
}


const DetenerAtenederUsuario =(dispatch) => {
    dispatch({
        type: "DETENER_ATENDER_USUARIO",
    });
}


const AbrirSalirChat =(dispatch) => {
    dispatch({
        type: "ABRIR_SALIRCHAT",
    });
}

const CerarSalirChat =(dispatch) => {
    dispatch({
        type: "CERRAR_SALIRCHAT",
    });
}


const MostrarCargarDatos =(dispatch) => {
    dispatch({
        type: "MOSTRAR_CARGARDATOS",
    });
}

const OcultarCargarDatos =(dispatch) => {
    dispatch({
        type: "CERRAR_CARGARDATOS",
    });
}

const MostrarCargarChat =(dispatch) => {
    dispatch({
        type: "CARGANDO_CHAT",
    });
}

const OcultarCargarChat =(dispatch) => {
    dispatch({
        type: "OCULTAR_CARGANDOCHAT",
    });
}

/*-----------------------------------Redes sociales-----------------------------------------*/

const IniciarDatosBot =(dispatch,datos_bot,chat) => {
    dispatch({
        type: "INICIAR_DATOS",datos_bot:datos_bot,chat:chat,
    });
}


const AgregarDatos=(dispatch,datos)=>{
    dispatch({
        type: "AGREGAR_DATOS",lista:datos,
    });
}

const ModificarDAtos=(dispatch,registro)=>{

    if(Number.isInteger(registro.dato.atributos.ultima_fecha))
    {
      registro.dato.atributos.ultima_fecha = new Date(registro.dato.atributos.ultima_fecha*1000).getTime();
    }else{
      registro.dato.atributos.ultima_fecha = new Date(registro.dato.atributos.ultima_fecha).getTime();
    }
    dispatch({
        type: "MODIFICAR_DATOS",registro:registro,
    });
}

const EliminarDAtos=(dispatch,id)=>{
    dispatch({
        type: "ELIMINAR_DATOS",id:id,
    });
}

const AumentarLimite=(dispatch)=>{
  dispatch({
      type: "AUMENTAR_LIMITE",
  });
}

const GetConversation =(dispatch,usuario_actual) => {
    dispatch({
        type: "GET_CONVERSATION",usuario_actual:usuario_actual,
    });
}

const MostarChatcenter =(dispatch,valor) => {
    dispatch({
        type: "CHATCENTER",chatcenter:valor,
    });
}

const NuevoChat =(dispatch) => {
    dispatch({
        type: "NUEVO_CHAT",
    });
}

const CambiarIndexUsuarios = (dispatch,index)=>{
  dispatch({
      type: "CAMBIAR_INDEXUSUARIOS",index:index
  });
}


const CambiarIndexChat = (dispatch,index)=>{
  dispatch({
      type: "CAMBIAR_INDEXCHAT",index:index
  });
}

const AumentarLimiteChat= (dispatch)=>{
  dispatch({
      type: "AUMENTAR_LIMITECHAT"
  });
}

const AumentarLimiteUsuario= (dispatch)=>{
  dispatch({
      type: "AUMENTAR_LIMITEUSUARIO"
  });
}


const ReferenciaActual = (dispatch,ref)=>{
  dispatch({
      type: "REFERENCIA_ACTUAL",ref:ref
  });
}

const ArchivosUsuarios = (dispatch,archivos,tipo)=>{
  dispatch({
      type: "ARCHIVOS_USUARIOS",archivos:archivos,tipo:tipo
  });
}


function EnviarMensaje(
    chatDispach,
    mensajeEnviar,
    tipo,
    nombre,
    tamano,
    propietario,
    usuario_actual,
    mensajes_chat,
    user,
    datos,
    Error,
    Enviando,
    ReiniciarVariables
  ){
      if(usuario_actual!=="" && mensajeEnviar!=="")
      {


        if(usuario_actual.asesor==="")
        {

            //colocar atender al usuario
            db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos").update({
              chatcenter:true,
              fecha_chatcenter:fecha_hora.database.ServerValue.TIMESTAMP,
              ultima_fecha:fecha_hora.database.ServerValue.TIMESTAMP ,
              acceso_asesor: fecha_hora.database.ServerValue.TIMESTAMP
            });
            AtenderUsuario(chatDispach);
            var obj = {
              fecha: + new Date(),
              nombre: "atender usuario",
              id_accion: 402,
              id_bot: usuario_actual.info.bot,
              red_social: usuario_actual.info.red,
              usuario:usuario_actual.key,
              nombre_usuario:usuario_actual.user_name
            }
            log_it(datos.id_empresa,user.id,obj)
        }

          Error(false);
          Enviando(true);
            var formData = new FormData();
            formData.append('empresa',datos.id_empresa);
            formData.append('userid',usuario_actual.key);
            formData.append('bot',usuario_actual.info.bot);
            formData.append('red',usuario_actual.info.red);
            formData.append('mensaje',mensajeEnviar);
            formData.append('tipo',tipo);
            formData.append('user_cc',datos.nombre);
            formData.append('iduser_cc',user.id);
            formData.append('nombre',nombre);
            formData.append('tamano',tamano);
            formData.append('propietario',propietario);

            fetch(mensajes_chat, {
              method: 'POST',
              body: formData, // data can be `string` or {object}!
            }).then((response) => {
              //console.log('responmse',response);
                  return response;
                })
                .then((empleados) => {
                  Enviando(false);
                  ReiniciarVariables();
                            handle_ChatcenterActualizarAtendiendo(
                              user,
                              datos,
                              usuario_actual,
                            );

                }).catch( (error)=>{
                  Enviando(false);
                  Error(true);
                });

      }


}


function handle_ChatcenterActualizarAtendiendo(user,datos,usuario_actual){

    return new Promise(function (fulfill, reject){

            if(usuario_actual==="")
            {
              fulfill();
            }

            //--ALGORITMO
            //EVALUAR Si el chat tiene un asesor actualmente
            //console.log("usuario actual log",usuario_actual);

            if(usuario_actual.asesor === ""){
              // no tiene un asesor
              // No tiene un asignado un log_chatcenter
              var obj={
                estado:true,
                fecha_ini:fecha_hora.database.ServerValue.TIMESTAMP,
                fecha_usuario:fecha_hora.database.ServerValue.TIMESTAMP,
                id:usuario_actual.key,
                usuario:user.id,
                nombre:datos.nombre,
                estado: true
              }
              var refPush = db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/").push(obj,function(err){
                var log_channel_id = refPush.key
                db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/")
                .update({log_chatcenter:log_channel_id,
                         asesor_id:user.id,
                         asesor_name:datos.nombre,
                        });
              });
              fulfill();
            }else{
              //ya tiene asociado log_chatcenter
              //Si quien atiende no es el mismo que ya estaba atendiendo entonces tengo que actualizar

              //1.pasar al historial el que estaba actualmente en log_chatcenter
              //2.actualizar al chat center su nuevo asesor_id y asesor_name
              //./1
              //obtener el historial
              if(usuario_actual.asesor!==user.id){
                db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).once('value').then(function(snap){
                  return snap.val();
                }).then(function (log_chatcenter){
                    let historial=[];
                      if(log_chatcenter===null){
                        fulfill();
                        return 0;
                      }

                      if(('historial' in log_chatcenter)){
                        historial=log_chatcenter.historial;
                      }

                      historial.push({
                        fecha_ini:log_chatcenter.fecha_usuario===undefined?log_chatcenter.fecha_ini:log_chatcenter.fecha_usuario,
                        fecha_fin:fecha_hora.database.ServerValue.TIMESTAMP,
                        usuario:log_chatcenter.usuario,
                        nombre:log_chatcenter.nombre
                      }
                      );
                      var ingress = {
                        estado:true,
                        fecha_ini:log_chatcenter.fecha_ini,
                        id:log_chatcenter.id ,
                        historial: historial,
                        fecha_usuario:  fecha_hora.database.ServerValue.TIMESTAMP,
                        usuario:user.id,
                        nombre:datos.nombre
                    }
                    db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).

                      set(ingress);
                      //./2
                      db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/asesor_id").set(user.id);
                      db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/asesor_name").set(datos.nombre);

                      fulfill();

                });
              }else{
                fulfill();
              }
            }
    });

}
