import React , { useState, useEffect } from 'react';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import {Grid} from "@material-ui/core";
import Widget from '../../../components/Widget';
import Divider from '@material-ui/core/Divider';
import useStyles from '../styles';
import { Typography } from "../../../components/Wrappers";
import Box from '@material-ui/core/Box';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {db} from '../../../services/firebase';
import {useAdminBotState} from '../contexto/AdminBotContext'
import { useUserState} from "../../../context/UserContext";
import {useSnackbar } from 'notistack';
import Skeleton from '@material-ui/lab/Skeleton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuBloque from './menuBloque';
import PasosBloque from './nuevoBloque/PasosBloque';

/*Elementos facebook*/
import {
        Texto,
        Imagen,
        TextoRapido,
        PlantillaBoton,
        ChatCenter,
        Carrusel,
        WebService,
        CompraWeb,
        OrdenCompra,
        WebView,
        NoElement,
    } from './elementos'
/*Elementos facebook*/
import {
        Whatsapp_Texto,
        Whatsapp_Menu,
        Whatsapp_WebService,
        Whatsapp_ChatCenter,
        Whatsapp_Archivo

} from './elementos/elementos_whatsapp'
import {
  Instagram_Texto,

} from './elementos/elementos_instagram'

/*Editar bloque*/
import EditarBloque from './editarBloque';
import NuevoElemento from './nuevoElemento';

import { ExpandLess } from '@material-ui/icons';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';

function Bloque(props){
    var classes = useStyles();

    const [listaElementos,setListaElementos] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    var {bot_actual} =useAdminBotState();
    var { datos,user } = useUserState();

    const [cargando,setCargando] = React.useState(false);

    const [nuevoElemento,setNuevoElemento] = React.useState("");
    const [moverElementos,setMoverElementos] = React.useState(false);


      const eliminar_elemento=(id_elemento)=>{
        let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.nombre+"/elementos";

        let nuevo_arreglo=props.elementos;
        nuevo_arreglo.splice(id_elemento,1);
        db.ref(ref).set(nuevo_arreglo
            ,function(error) {
                if (error) {

                    enqueueSnackbar('Error al eleminar el elemento', {variant: 'error'});
                }else{
                    enqueueSnackbar('Eliminado correctamente', {variant: 'success'});
                }
        });

      }


      const CerrarNuevoElemento=()=>{
          setNuevoElemento([]) ;
      }

      const GuardarAddElemento=(datosNuevos)=>{

          let elm=JSON.stringify(props.elementos);
          let nuevo=JSON.parse(elm);
          nuevo.push(datosNuevos);

          let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.nombre+"/elementos";
          db.ref(ref)
          .set(nuevo,function(error) {
              if (error) {
                  enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
              } else {
                  enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                  setNuevoElemento([]) ;
              }
          });

      }

      const MoverMenosuno=(id_bloque)=>{

        let actual=JSON.parse(JSON.stringify(props.elementos))[id_bloque];
        let el_m=JSON.parse(JSON.stringify(props.elementos));
        el_m.splice(id_bloque,1);
        el_m.splice((id_bloque-1),0,actual);

        let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.nombre+"/elementos";
        db.ref(ref)
        .set(el_m,function(error) {
            if (error) {
                enqueueSnackbar('Error al movel el elemento', {variant: 'error'});
            }
        });

      }

      const MoverMasuno=(id_bloque)=>{

        let actual=JSON.parse(JSON.stringify(props.elementos))[id_bloque];
        let el_m=JSON.parse(JSON.stringify(props.elementos));
        el_m.splice(id_bloque,1);
        el_m.splice((id_bloque+1),0,actual);

        let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.nombre+"/elementos";
        db.ref(ref)
        .set(el_m,function(error) {
            if (error) {
                enqueueSnackbar('Error al movel el elemento', {variant: 'error'});
            }
        });

      }


      const DuplicarBloque=(id_bloque)=>{


      }


      /*Opciones del menu de bloques*/
      const [anchorEl, setAnchorEl] = React.useState(null);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };



return (

<React.Fragment>
      <Grid container className={props.padre!==""?classes.bordeItem:classes.bordeItemDEfault} spacing={3}  alignItems={"flex-start"} justify={"space-between"} direction={'row'}>

        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.nombre}
          </Typography>
          <Typography className={classes.subtituloBloque}>
            {props.nombre!=="default"?props.padre:""}
          </Typography>
        </Grid>
      </Grid>
      {/*  <ListItemAvatar>
          <Avatar className={props.nombre==="default"?classes.Bloquedefault:null}>
            {props.nombre.charAt(0)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={props.nombre} secondary={props.nombre!=="default"?props.padre:""}/>*/}
  </React.Fragment>


)

}

export default Bloque;


/*

<Grid item xs={12} className={classes.boxBloque}>
<Box boxShadow={3}>
  <Box justifyContent="center">
      <Box border={1}>
          <Widget
              bodyClass={classes.fullHeightBody}
              className={classes.card}
              disableWidgetMenu
              >
              <Grid spacing={2} container>
                  <Grid item xs={12}>
                  <div className={classes.bloqueElementos}>
                      <Typography variant="h3">
                         { props.nombre==="default"?"Default": "Nombre:"+props.nombre }
                      </Typography>

                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
  {props.nombre!=="default" &&

                          <EditarBloque
                            nombre={props.nombre}
                            padre={props.padre}
                            elementos={props.elementos}
                            cambiarNombre={props.cambiarNombre}
                            eliminar={props.eliminar}
                          />

    }
                        <MenuItem onClick={handleClose}>
                            <FormControlLabel
                                 control={
                                   <Checkbox
                                     checked={moverElementos}
                                     onChange={e=>setMoverElementos(e.target.checked)}
                                     name="checkedB"
                                     color="primary"
                                   />
                                 }
                                 label="Mover elementos"
                               />
                        </MenuItem>
                      <PasosBloque
                          duplicar={true}
                          elementos={props.elementos}
                          cerrar={setAnchorEl}
                          nombre={props.nombre}
                          padre={props.padre}
                      />
                      </Menu>


                  </div>
                  </Grid>
                  {    props.nombre!=="default" &&
                      <Grid item xs={12}>
                          <Typography variant="h5">
                              Padre: {props.padre}
                          </Typography>
                      </Grid>
                  }
                  <Grid item xs={12}>
                      <Divider />
                  </Grid>


                  <Grid item xs={12}>
                      <Typography variant="h5">
                          Elementos
                      </Typography>
                        {cargando && <Skeleton animation="wave" />}
                        {cargando && <Skeleton animation="wave" />}
                      <div className={classes.bloqueElementos}>
                          {listaElementos}
                          {
                            datos.permisos.abcbot.bloques===true &&
                              <NuevoElemento
                                nombre={props.nombre}
                                nuevoElemento={AddElemento}
                              />
                          }
                      </div>
                  </Grid>
              </Grid>
          </Widget>
      </Box>
  </Box>
  </Box>

  {nuevoElemento}

</Grid>




*/
