import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import {
    Grid,
    TextField as Input,
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useUserState,userNewPassword} from "../../context/UserContext";
import {db} from "../../services/firebase";
import SendIcon from '@material-ui/icons/Send';
import { useTheme } from "@material-ui/styles";
import ReactApexChart from 'react-apexcharts'
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import GetAppIcon from '@material-ui/icons/GetApp';
import AssessmentIcon from '@material-ui/icons/Assessment';

import ReactExport from "react-export-excel";
import Interacciones from "./Interacciones";
import FacebookLog from "./ReportsFacebookLog";
import InstagramIcon from '@material-ui/icons/Instagram';
import log_it from './../../services/log/log';

function Variables(props)
{
    var classes = useStyles();
    var theme = useTheme();

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



    const [nombre,setNombre]=React.useState("");
    const [nombrebot,setNombrebot]=React.useState("");
    const [listabot,setListabot]=React.useState([]);
    const [columnasVariables,setColumnasVariabels]=React.useState([]);
    const [datosVariables,setDatosVariabels]=React.useState([]);
    const [nuevo,setNuevo] =React.useState(false);
    var { datos, user } = useUserState();

    const [mostrarDescarga,setMostrarDescarga] = React.useState(false);
    const [mensajeDescarga,setMensajeDescarga] = React.useState(false);
    const [mensajeErrorDescarga,setMensajeErrorDescarga] = React.useState(false);

    const [progreso,setProgerso] = React.useState(false);
    const [datosClientes,setDatosClientes] = React.useState([]);


    const [fechaIni, setFechaIni] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(),1));
    const [fechaFin, setFechaFinal] = React.useState(new Date( new Date().getFullYear(), new Date().getMonth(),new Date().getDate(),23,59,59 ));

    //Obtener informacion de los bots
    useEffect(() =>{

        let conexion_reporte=db.ref("bots/"+datos.id_empresa).orderByChild("nombre").on("child_added",function(snapshot){
            var lista = snapshot.val();

            if(props.red==="facebook")
            {
              if(lista.conexiones.facebook){
                 setListabot( (prevState)=> {
                  prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                  return prevState;
                 });
              }
            }

            if(props.red === "whatsapp")
            {
              if(lista.conexiones.whatsapp){
                 setListabot( (prevState)=> {
                  prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                  return prevState;
                 });
              }
            }

            if(props.red === "telegram")
            {
              if(lista.conexiones.telegram){
                 setListabot( (prevState)=> {
                  prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                  return prevState;
                 });
              }
            }

            if(props.red === "instagram")
            {
              if(lista.conexiones.instagram){
                 setListabot( (prevState)=> {
                  prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                  return prevState;
                 });
              }
            }

            if(props.red === "webchat")
            {
              if(lista.conexiones.webchat){
                 setListabot( (prevState)=> {
                  prevState.push(<MenuItem key={lista.nombre} value={{valor:snapshot.key,texto:lista.nombre}}>{lista.nombre}</MenuItem>);
                  return prevState;
                 });
              }
            }

        });

        return ()=>{
            db.ref("bots/"+datos.id_empresa).off("child_added",conexion_reporte);
            db.ref("cliente_chat/"+datos.id_empresa+"/"+props.red+"/"+nombrebot).off();
          }

    },[]);


    function uptadeTable(lista)
    {
        let atributos=[];
        let encabezados=[];

        let columnasExcel=[];


        ///grafica de usuario atendidos
        let usuariosAtendidos=[];

        for( var keyA in lista)
        {

            ///atributos
            for( var key in lista[keyA].atributos)
            {

                const enca= encabezados.find(columna => columna === key);

                if( key !== "fb_picture" && key !=="ig_picture" && key !== "chatcenter" && key !== "fecha_chatcenter" && (encabezados.length===0 || enca === undefined))
                {
                    encabezados.push(key);
                    columnasExcel.push(<ExcelColumn key={"encabezdos_columnas"+key} label={key} value={key}/>)
                }

                ///llenar grafico de usuarios atendidos por fecha
                if( key==="fecha_ingreso")
                {

                    let fecha = FechatoDateSoloHora(lista[keyA].atributos[key],fechaIni,fechaFin);
                    if (fecha!==null)
                    {
                      let nuevaFecha=fecha.getTime();

                      if(  nuevaFecha>=fechaIni.getTime() &&
                           nuevaFecha<=fechaFin.getTime())
                        {



                            var index = usuariosAtendidos.findIndex(function(item, i){
                                return item.x === nuevaFecha
                                });


                            if(index>-1)
                            {
                                usuariosAtendidos[index].y=usuariosAtendidos[index].y+1;

                            }else{
                                usuariosAtendidos.push({
                                    x:nuevaFecha,
                                    y:1});
                            }
                       }
                    }
                }

            }
            //Formato de datos

            if(lista[keyA].atributos.ultima_fecha!==undefined){

        

                if(Number.isInteger(lista[keyA].atributos.ultima_fecha)){
                    lista[keyA].atributos.ultima_fecha=(new Date(lista[keyA].atributos.ultima_fecha*1000));

                }else{
                    lista[keyA].atributos.ultima_fecha=FechatoDate(lista[keyA].atributos.ultima_fecha);
                }

              if(
                  lista[keyA].atributos.ultima_fecha.getTime()>=fechaIni.getTime() &&
                  lista[keyA].atributos.ultima_fecha.getTime()<=fechaFin.getTime())
                {
                    if(lista[keyA].atributos.fecha_ingreso!==undefined)
                    {
                        lista[keyA].atributos.fecha_ingreso =FechatoDate(lista[keyA].atributos.fecha_ingreso).toLocaleString('es-MX');
                        lista[keyA].atributos.ultima_fecha= lista[keyA].atributos.ultima_fecha.toLocaleString('es-MX');
                    }
                    atributos.push(lista[keyA].atributos);

                }


            }

        }

        setColumnasVariabels(columnasExcel);
        setDatosVariabels(atributos);

          ///llenar grafico de usuarios atendidos
          setSeries([{
                data :usuariosAtendidos.sort((a,b)=> a.x-b.x),
                name: "Nuevos usuarios",
             }
            ]);

        setProgerso(false);
        setMensajeDescarga(true);
        setMostrarDescarga(true);
    }

    function ActualizarTraking(lista)
    {

            let datos_traking=[];
           // const nombre=lista.atributos.fb_username;
            for( var keyA in lista)
            {
                if(lista[keyA].canales)
                {
                    const auncios=lista[keyA].canales.anuncios;
                    const links =lista[keyA].canales.link;
                    let nombre=lista[keyA].atributos.fb_username;

                    if(auncios)
                    {


                        for( var key in auncios)
                        {

                            let aux=FechatoDate(auncios[key].fecha_hora);
                            if (aux!==null)
                            {
                              let fecha=aux.getTime();
                              if(
                                  fecha>=fechaIni.getTime() &&
                                  fecha<=fechaFin.getTime())
                                {
                                    FechatoDate(auncios[key].fecha_hora);
                                    auncios[key].fecha_hora=aux.toLocaleString('es-MX');
                                    datos_traking.push({...auncios[key],canal_entrada:"anuncio",fb_username:nombre,fbid:keyA});
                                }
                            }
                        }

                    }

                    if(links)
                    {
                        for( var key in links)
                        {
                            let aux=FechatoDate(links[key].fecha_hora);
                            if (aux!==null)
                            {
                              let fecha=aux.getTime();
                              if(
                                  fecha>=fechaIni.getTime() &&
                                  fecha<=fechaFin.getTime())
                                {
                                  links[key].fecha_hora=aux.toLocaleString('es-MX');
                                  datos_traking.push({...links[key],canal_entrada:"link",fb_username:nombre,fbid:keyA});
                                }
                            }
                        }
                    }
               }

            }


            setDatosClientes(datos_traking);

    }


    function iniciar(id_bot)
    {


    /*  db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+id_bot).orderByChild("atributos/fecha_chatcenter").on("value",function(snapshot){
         snapshot.forEach(function(data){
            if (data.val().atributos.ultima_fecha!==undefined)
            {
                db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+id_bot+"/"+data.key+"/atributos").update({
                  ultima_fecha:new Date(data.val().atributos.ultima_fecha).getTime()
                });
                console.log("cambio de fecha:"+"cliente_chat/"+datos.id_empresa+"/facebook/"+id_bot+"/"+data.key+"/atributos");
            }

          });
     });*/

        db.ref("cliente_chat/"+datos.id_empresa+"/"+props.red+"/"+id_bot).
        //   orderByChild("atributos/ultima_fecha").
        //   startAt(fechaIni.getTime()).
        //   endAt(fechaFinal.getTime()).
           once("value",function(snapshot){
            var lista = snapshot.val();
            uptadeTable(lista);
            if(props.red==="facebook"){
              ActualizarTraking(lista);
            }
       });

    }

    const generarReporte = () =>{

      setProgerso(true);
      iniciar(nombrebot.valor);

      var obj = {
        fecha: + new Date(),
        nombre: "generar reporte atributos",
        id_accion: 501,
        id_bot: nombrebot.valor,
        red_social: props.red,
      }
      log_it(datos.id_empresa,user.id,obj)

    }

    var camibarBot = (event)=> {

        setNombrebot(event.target.value);
    };

    var nuevoReporte = () =>{

          setDatosVariabels([]);
          setColumnasVariabels([]);
          setNuevo(false);
          setNombrebot("");
          setDatosClientes([]);

          setMostrarDescarga(false);
          setMensajeErrorDescarga(false);
          setMensajeDescarga(false);
          setSeries([{
                data :[],
                name: "Nuevos usuarios",
             }
            ]);
    }




    const [mostrarGrafica,setMostrargrafica]= React.useState(true);
    const  [series,setSeries]=React.useState([{
        data :[]/*.sort((a,b)=> a.x-b.x)*/,
        name: "Nuevos usuarios",
    }]);
    const  [options,setOptions]=React.useState({
        chart: {
            height: 380,
            width: "100%",
            type: "area",
            animations: {
              initialAnimation: {
                enabled: false
              }
            },
            toolbar: {
                show: true,
                tools: {
                    download: false
                }
            }
        },
        xaxis: {
            type: "datetime",
            labels: {
                style: {
                  colors:datos.configuraciones.tema==='dark'?"#fff":"#000"
                },
                datetimeUTC : false
              },
        },
        yaxis: {
            labels: {
                style: {
                  colors: datos.configuraciones.tema==='dark'?"#fff":"#000"
                }
            },
        },
        tooltip: {
            enabled: true,
             theme: datos.configuraciones.tema==='dark'?"dark":"light" ,
         }

      });

      /*Valor : Eventos para rango de fecha*/
     const fechaIniDateChange = (date) => {
       setFechaIni(date);
     };

     const fechaFinDateChange = (date) => {
       setFechaFinal(date);
     };


     /*interacciones*/

     const cambiarFechaIni = (date) => {
       setFechaIni(date);
     };

     const cambiarFechaFin = (date) => {
       date.setHours(23,59,59,59);
       setFechaFinal(date);
     };

   return (
        <Grid container spacing={3}>


        <Grid item xs={12} md={12}>
            <Widget
                    bodyClass={classes.fullHeightBody}
                    className={classes.card}
                    disableWidgetMenu
                    title={'Generar Reporte'}
            >

            <Grid item xs={12}  >
             {props.icono}
            </Grid>

            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
              {props.red==="facebook" ?
                <Grid item xs={12}  >
                    <Typography className={classes.LeyendaPorcentaje}>Variables y Tracking de clientes</Typography>
                </Grid>
                :
                <Grid item xs={12}  >
                    <Typography className={classes.LeyendaPorcentaje}>Variables</Typography>
                </Grid>
              }
            </Grid>


            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>

            {mostrarDescarga ===false &&
               <Grid item  xs={12} className={classes.centrar}>
                  <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>

                    <Grid item  xs={4} >
                       <FormControl variant="outlined"  className={classes.centrar}  >
                          <KeyboardDatePicker
                             disableToolbar
                             variant="inline"
                             format="dd-MM-yyyy"
                             margin="normal"
                             label="Fecha inicio"
                             value={fechaIni}
                             onChange={cambiarFechaIni}
                             KeyboardButtonProps={{
                               'aria-label': 'change date',
                             }}
                           />
                        </FormControl>
                     </Grid>

                     <FormControl variant="outlined" >
                       <InputLabel id="demo-simple-select-outlined-label">Bot</InputLabel>
                         <Select
                             labelId="demo-simple-select-outlined-label"
                             id="demo-simple-select-outlined"
                             label="Age"
                             value={nombrebot}
                             onChange={camibarBot}
                         >
                         <MenuItem value="">
                             <em>None</em>
                         </MenuItem>
                           {listabot}
                         </Select>
                     </FormControl>

                     <Grid item  xs={4}  className={classes.centrar}>
                        <FormControl variant="outlined"  >
                           <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd-MM-yyyy"
                              margin="normal"
                              label="Fecha fin"
                              value={fechaFin}
                              onChange={cambiarFechaFin}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                        </FormControl>
                      </Grid>

                  </Grid>
                </Grid>
              }


                {mostrarDescarga &&
                  <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                    <Grid item xs={12}  >
                          <Typography className={classes.LeyendaPorcentaje}>{nombre}</Typography>
                    </Grid>
                  </Grid>
                }


                {  progreso &&
                    <Grid item xs={12}  >
                        <FormControl variant="outlined" className={classes.centrar}>
                            <CircularProgress disableShrink/>
                        </FormControl>
                    </Grid>
                }


              { mensajeDescarga && datosVariables.length>0  &&


                    <Grid item xs={12}  >
                        <FormControl variant="outlined" className={classes.centrar}>
                          <Alert severity="success">Reporte generado de forma correcta. Descargue el reporte en el siguiente botón.</Alert>
                        </FormControl>
                    </Grid>

              }

              { mensajeErrorDescarga  &&


                    <Grid item xs={12}  >
                        <FormControl variant="outlined" className={classes.centrar}>
                          <Alert severity="error">Error al generar el porte intente de nuevo</Alert>
                        </FormControl>
                    </Grid>

              }


              {mostrarDescarga && datosVariables.length>0 &&
                <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                    <Grid item xs={12}  >
                      <FormControl variant="outlined" className={classes.centrar}>

                      {props.red==="facebook" ?
                            <ExcelFile filename={"Atributos_Facebook"} element={
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<GetAppIcon />}
                                  >
                                    Descargar
                                  </Button>
                            }>
                              <ExcelSheet data={datosVariables} name="Atributos">
                                  {columnasVariables}
                              </ExcelSheet>
                                <ExcelSheet data={datosClientes} name="Tracking de clientes">
                                <ExcelColumn  label={"fbid"} value={"fbid"}/>
                                <ExcelColumn  label={"Usuario fb"} value={"fb_username"}/>
                                <ExcelColumn  label={"Canal de entrada"} value={"canal_entrada"}/>
                                <ExcelColumn  label={"Fecha"} value={"fecha_hora"}/>
                                <ExcelColumn  label={"Parametro"} value={"parametro"}/>
                                <ExcelColumn  label={"Auncio"} value={"anuncio_id"}/>
                              </ExcelSheet>
                            </ExcelFile>
                        :
                            <ExcelFile filename={"Atributos_"+props.red} element={
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={<GetAppIcon />}
                                  >
                                    Descargar
                                  </Button>
                            }>
                              <ExcelSheet data={datosVariables} name="Atributos">
                                  {columnasVariables}
                              </ExcelSheet>
                            </ExcelFile>
                        }

                      </FormControl>
                    </Grid>
                </Grid>
              }

              {mostrarDescarga && datosVariables.length===0 &&

                <Grid item xs={12}  >
                    <FormControl variant="outlined" className={classes.centrar}>
                      <Alert severity="warning">No se encontraron datos en el rago de fecha</Alert>
                    </FormControl>
                </Grid>

              }

              { progreso===false && mostrarDescarga==false &&
                 <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.centrar}>
                      <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={nombrebot==="" || fechaIni>fechaFin }
                      startIcon={<AssessmentIcon />}
                      onClick={generarReporte}
                      >
                      Generar reporte
                      </Button>
                    </FormControl>
                </Grid>
              }

            </Grid>

            {
                mostrarDescarga &&
                <Grid item xs={12}  >
                      <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SendIcon />}
                          onClick={nuevoReporte}
                          >
                          Nuevo Reporte
                      </Button>
                </Grid>

            }

            {fechaFin<fechaIni &&
              <Grid item xs={12}>
                <FormControl variant="outlined" className={classes.centrar} >
                  <Alert severity="error">La fecha de inicio debe ser menor a la fecha final</Alert>
                </FormControl>
              </Grid>
            }




            </Widget>
        </Grid>


            <Grid item xs={12}>
                    <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                    >
                            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"}>
                                <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>
                                    <Grid item xs={12}  >
                                        <Typography className={classes.LeyendaPorcentaje}>Usuarios Nuevos</Typography>
                                    </Grid>

                                    {mostrarDescarga && datosVariables.length===0 &&

                                      <Grid item xs={12}  >
                                          <FormControl variant="outlined" className={classes.centrar}>
                                            <Alert severity="warning">No se encontraron datos en el rago de fecha</Alert>
                                          </FormControl>
                                      </Grid>

                                    }
                                    <Grid item xs={12}>
                                        {mostrarGrafica &&  <ReactApexChart options={options} series={series} type="area" height={350} />}
                                   </Grid>
                                </Grid>
                           </Grid>
                    </Widget>
            </Grid>

            {/*Interacciones */}
            <Grid item xs={12} md={12}>
              <Interacciones listabot={listabot} red={props.red}/>
            </Grid>

            <Grid item xs={12} md={12}>
              <FacebookLog listabot={listabot} red={props.red}/>
            </Grid>

        </Grid>
   );

}

//fromato de fecha 2021-08-03 10:38:49.204860
function FechatoDate(fecha)
{
  try {
    let fecha_hora = fecha.split(' ');
    let fecha_split = fecha_hora[0].split('-');
    let hora_split= fecha_hora[1].split(':');

    let nueva_fecha=new Date( fecha_split[0], parseInt(fecha_split[1])-1,fecha_split[2],hora_split[0],hora_split[1],hora_split[2] );

    return nueva_fecha;

  } catch (error) {
    return null;
  }

}

//fromato de fecha 2021-08-03 10:38:49.204860
function FechatoDateSoloHora(fecha,fechaIni,fechaFin)
{
  try {
    let fecha_hora = fecha.split(' ');
    let fecha_split = fecha_hora[0].split('-');

    if((fechaIni.getDate() === fechaFin.getDate() || (fechaFin.getDate() -fechaIni.getDate())<3 ) &&
       fechaIni.getMonth() === fechaFin.getMonth() &&
       fechaIni.getFullYear() === fechaFin.getFullYear()
      )
    {
      let hora_split= fecha_hora[1].split(':');
      let nueva_fecha=new Date( fecha_split[0], parseInt(fecha_split[1])-1,fecha_split[2],hora_split[0],0,0 );
      return nueva_fecha;

    }else{fechaIni.getDay()

      let nueva_fecha=new Date( fecha_split[0], parseInt(fecha_split[1])-1,fecha_split[2],0,0,0 );
      return nueva_fecha;
    }

  } catch (error) {
    return null;
  }

}

export default Variables;
