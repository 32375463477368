import React, { useState ,useEffect,Component} from 'react';
import {ListItemText,Avatar,List,ListItem,ListItemAvatar,TextField ,IconButton} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from '../styles';
import SendIcon from '@material-ui/icons/Send';
import {db,fecha_hora} from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import {useChatState,useChatDispatch,AtenderUsuario} from '../context/ChatContext';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';



function InputMensaje(){
    var classes = useStyles();
    var { datos,user } = useUserState();
    var chatDispach=useChatDispatch();
    var {bot_actual,usuario_actual,atender_usuario,datos_bot} = useChatState();

    const[enviando,setEnviando]=React.useState(false);
    const[mensajeEnviar,setMensajeEnviar]=React.useState("");
    const [error,setError]=React.useState(false);
  

    useEffect(() =>{    
      setError(false);
      setMensajeEnviar("");
    },[usuario_actual]);

    

    const enviarMensaje=()=>
    {
       
        if(bot_actual!=="" && usuario_actual!=="" && mensajeEnviar!=="")
        {

          if(!datos_bot[usuario_actual.key].atributos.chatcenter)
          {
              //colocar atender al usuario
              db.ref("cliente_chat/"+datos.id_empresa+"/whatsapp/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
                chatcenter:true,
                fecha_chatcenter:fecha_hora.database.ServerValue.TIMESTAMP
              });  
 
              AtenderUsuario(chatDispach);
              


          }
     

          setError(false);  
          setEnviando(true);   
            var formData = new FormData();
            formData.append('bot',bot_actual);
            formData.append('empresa',datos.id_empresa);
            formData.append('mensaje',mensajeEnviar);
            formData.append('userid',usuario_actual.key);
            formData.append('red','whatsapp');
            formData.append('plantilla','0');

            fetch('https://bots.automatadg.com/servicio/conexion', {
              method: 'POST',                 
              body: formData, // data can be `string` or {object}!      
            }).then((response) => {            
                  return response;
                })
                .then((empleados) => {
                //  console.log('respuesta',empleados);
                  setEnviando(false);   
                  setMensajeEnviar("")
                }).catch( (error)=>{
                  //console.log("error al enviar",error);
                  setEnviando(false); 
                  setError(true);
                });
            
        } 
    }


    const enviarEnter=(e)=>{
      if(e.keyCode === 13){        
        enviarMensaje();
        e.preventDefault();
        // put the login here
     }
    }

return ( 

  <React.Fragment>

    {error && <Alert severity="error">Error al enviar el mensaje</Alert> }
    <TextField fullWidth multiline rowsMax={2}
        disabled={datos_bot!=="" && usuario_actual!=="" && enviando ===false  ?false:true}
        value={mensajeEnviar}
        className={classes.margin}  
        onChange={e => {setMensajeEnviar(e.target.value);}}  
        onKeyDown={enviarEnter}            
        id="input-with-icon-textfield" InputProps={{
          endAdornment:(        
                <InputAdornment position="end">
                  <IconButton
                    aria-label="send message" 
                    edge="end"                    
                    onClick={enviarMensaje}
                    disabled={ datos_bot!=="" && usuario_actual!=="" && enviando ===false ?false:true}
                    >
                   {enviando ===false && <SendIcon/>}
                   { enviando && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </IconButton>               
                </InputAdornment>
              ),}}/>
  </React.Fragment>

    );
}

export default InputMensaje;