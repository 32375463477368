import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import Variables from './variables'
import { Button, Typography,Avatar } from "../../components/Wrappers";

import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

function Reports ()
{
   var classes = useStyles();

   return (
      <Variables
        red={"whatsapp"}
        icono={<Typography className={classes.LeyendaPorcentajeTitulo}>
          <WhatsAppIcon style={{ fontSize: 43,
                                 color: "#25cd60"
                               }}  />
        </Typography>}
      />
   );

}



export default Reports;
