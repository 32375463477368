
import React, { useState ,useEffect,Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import {db,storage,fecha_hora}  from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useChatState,useChatDispatch,ArchivosUsuarios,AtenderUsuario,EnviarMensaje} from '../context/ChatContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import SendIcon from '@material-ui/icons/Send';
import {mensajes_chat} from "../../../services/automata"
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MicIcon from '@material-ui/icons/Mic';
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import IconDoc from '../../../images/documentoss.png';
import IconMusic from '../../../images/musica.png';
import IconVideo from '../../../images/video.png';

import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import ManagerFile from './ManagerFile'

import {Grid} from "@material-ui/core";
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: "auto",
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  progressBarr:{
     marginTop: theme.spacing(2),
  }
}));

const ex_doc=['txt','doc','docx','pptx','ppt','xlsx','xls','odt','pdf','csv','txt'];

export default function Adjuntos() {

  const classes = useStyles();
  var { datos ,user} = useUserState();
  var chatDispach=useChatDispatch();
  var {bot_actual,
      usuario_actual,
      atender_usuario,
      datos_bot,
      datos_chat,
      listaArchivos,
      listaImagenes,
      listaAudios,
      listaVideos} = useChatState();

  const [nombreArchivo,setNombreArchivo] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [archivosNuevos,setArchivosNuevos] = React.useState([]);
  const [archivos,setArchivos] = React.useState([]);
  const [actualizando,setActualizando] = React.useState(false);
  const [progress,setProgress] = React.useState(0);
  const [cargando,setCargando] = React.useState(false);

  const [errorUploadVideo,setErrorUploadVideo]=React.useState(false);
  const [errorDelete,setErrorDelete]= React.useState(false);
  const [mensajeEnviar,setMensajeEnviar]= React.useState("");
  const [error,setError] = React.useState(false);
  const [enviando,setEnviando] = React.useState(false);
  const [ocularDrop,setOcultarDrop] = React.useState(false);
  /*
  0 Imagen
  1 Archivos
  2 Audios
  3 Videos
  */
  const [ventana,setVentana]= React.useState(0);
  const [success,setSuccess] = React.useState(false);

  const [errorEnviar,setErrorEnviar] = React.useState(false);
  const tileData = [
    {
      img: "https://material-ui.com/static/images/grid/complex.jpg",
      title: 'Image',
      author: 'author',
    },
  ];


    useEffect(() =>{

    db.ref('/multimedia/'+datos.id_empresa+'/imagen').on('value',function(snaptshop){
      let arreglo=[]
      snaptshop.forEach((item, i) => {
        let datos=item.val();
        datos.key=item.key;
        arreglo.push(datos);
      });
      ArchivosUsuarios(chatDispach,arreglo,0);
    });

    db.ref('/multimedia/'+datos.id_empresa+'/archivos').on('value',function(snaptshop){
      let arreglo=[]
      snaptshop.forEach((item, i) => {
        let datos=item.val();
        datos.key=item.key;
        arreglo.push(datos);
      });

      ArchivosUsuarios(chatDispach,arreglo,1);
    });


    db.ref('/multimedia/'+datos.id_empresa+'/audios').on('value',function(snaptshop){
      let arreglo=[]
      snaptshop.forEach((item, i) => {
        let datos=item.val();
        datos.key=item.key;
        arreglo.push(datos);
      });
      ArchivosUsuarios(chatDispach,arreglo,2);
    });


    db.ref('/multimedia/'+datos.id_empresa+'/videos').on('value',function(snaptshop){
      let arreglo=[]
      snaptshop.forEach((item, i) => {
        let datos=item.val();
        datos.key=item.key;
        arreglo.push(datos);
      });
      ArchivosUsuarios(chatDispach,arreglo,3);
    });





    return ()=>{
      db.ref('/multimedia/'+datos.id_empresa+'/').off();
      }
  },[]);


  const EnviarArchivoSinGuardar = () => {

              setSuccess(false);
              setErrorDelete(false);
              setEnviando(false);
              setError(false);
              if(archivosNuevos.length===0)
              {
                return 0;
              }


              //extensin de Archivos Nuevos
                setErrorUploadVideo(false);
              if(ventana===1){
              let  extension_archivo = file_extension(archivosNuevos[0].name);
                if (ex_doc.indexOf(extension_archivo)===-1)
                {
                  setErrorUploadVideo(true);
                  return 0;
                }
              }

              new Promise((resolve) => {
                    setOcultarDrop(true);

                    let direccion= 'temporal';

                    let nombre_nuevo=db.ref('/multimedia/'+datos.id_empresa+'/'+direccion).push({
                      usuario:user.id,
                      usuario_name:datos.nombre,
                    }).key;

                    let  extension_archivo = file_extension(archivosNuevos[0].name);
                    if(extension_archivo===undefined){
                      //formato incorrecto del archivo
                        return 0;
                    }

                    var  uploadTask = storage.ref('/empresas/'+datos.id_empresa+'/bucket/empresa/'+direccion+'/'+nombre_nuevo+"."+extension_archivo).put(archivosNuevos[0]);
                    // Register three observers:
                    // 1. 'state_changed' observer, called any time the state changes
                    // 2. Error observer, called on failure
                    // 3. Completion observer, called on successful completion
                    uploadTask.on('state_changed', function(snapshot){
                      // Observe state change events such as progress, pause, and resume
                      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                      setProgress(progress);

                    }, function(error) {
                      // Handle unsuccessful uploads
                      db.ref('/multimedia/'+datos.id_empresa+'/'+direccion+"/"+nombre_nuevo).set(null);
                      //console.log("error",error);
                      setArchivosNuevos([]);
                      setProgress(0);
                      setOcultarDrop(false);
                      setNombreArchivo("");
                      setError(true);
                      resolve();
                    }, function() {
                      // Handle successful uploads on complete
                      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                      setProgress(0);
                      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                      //  console.log('File available at', downloadURL);
                      enviarMensajeBorrandoArchivo({
                          tipo: 'image',
                          nombre:nombreArchivo,
                          tamanio:parseInt(archivosNuevos[0].size / 1024),
                          propietario:'AD',
                          link:downloadURL,
                          refStorage: '/empresas/'+datos.id_empresa+'/bucket/empresa/'+direccion+'/'+nombre_nuevo+"."+extension_archivo,
                          refDB: '/multimedia/'+datos.id_empresa+'/'+direccion+"/"+nombre_nuevo
                      });
                          setNombreArchivo("");
                          setArchivosNuevos([]);
                          setProgress(0);
                          setOcultarDrop(false);
                            setSuccess(true);
                          resolve();
                      });
                    });
              });

  }

  const SubirArchivos = ( )=>{

        setSuccess(false);
        setErrorDelete(false);
        setEnviando(false);
        setError(false);
        if(archivosNuevos.length===0)
        {
          return 0;
        }


        //extensin de Archivos Nuevos
          setErrorUploadVideo(false);
        if(ventana===1){
        let  extension_archivo = file_extension(archivosNuevos[0].name);
          if (ex_doc.indexOf(extension_archivo)===-1)
          {
            setErrorUploadVideo(true);
            return 0;
          }
        }

        new Promise((resolve) => {
              setOcultarDrop(true);

              let direccion= ventana===0? 'imagen':
                                          ventana===1? 'archivos':
                                          ventana===2? 'audios':'videos';

              let nombre_nuevo=db.ref('/multimedia/'+datos.id_empresa+'/'+direccion).push({
                usuario:user.id,
                usuario_name:datos.nombre,
              }).key;

              let  extension_archivo = file_extension(archivosNuevos[0].name);
              if(extension_archivo===undefined){
                //formato incorrecto del archivo
                  return 0;
              }

              var  uploadTask = storage.ref('/empresas/'+datos.id_empresa+'/bucket/empresa/'+direccion+'/'+nombre_nuevo+"."+extension_archivo).put(archivosNuevos[0]);
              // Register three observers:
              // 1. 'state_changed' observer, called any time the state changes
              // 2. Error observer, called on failure
              // 3. Completion observer, called on successful completion
              uploadTask.on('state_changed', function(snapshot){
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);

              }, function(error) {
                // Handle unsuccessful uploads
                db.ref('/multimedia/'+datos.id_empresa+'/'+direccion+"/"+nombre_nuevo).set(null);
                //console.log("error",error);
                setArchivosNuevos([]);
                setProgress(0);
                setOcultarDrop(false);
                setNombreArchivo("");
                setError(true);
                resolve();
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                setProgress(0);
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //  console.log('File available at', downloadURL);
                    db.ref('/multimedia/'+datos.id_empresa+'/'+direccion+"/"+nombre_nuevo).set({
                      nombre:nombreArchivo,
                      link:downloadURL,
                      tipo:ventana===0? 'image':
                                                  ventana===1? 'file':
                                                  ventana===2? 'audio':'video',
                      tamano: parseInt(archivosNuevos[0].size / 1024),
                      usuario:user.id,
                      usuario_name:datos.nombre,
                      ref:'/empresas/'+datos.id_empresa+'/bucket/empresa/'+direccion+'/'+nombre_nuevo+"."+extension_archivo,

                    });
                /*    setArchivos((prevState)=>{
                      prevState.push({
                        img:downloadURL,    setCargando(false);
                        title:archivosNuevos[0].name,
                        author:datos.nombre,
                        cols:3,
                      });
                      return prevState;
                    });*/
                    setNombreArchivo("");
                    setArchivosNuevos([]);
                    setProgress(0);
                    setOcultarDrop(false);
                      setSuccess(true);
                    resolve();
                });
              });
        });


  }

  const handleClickOpen = (valor) => {
  setAnchorEl(null);
    setErrorUploadVideo(false);
    setVentana(valor)
    setCargando(false);
    setOpen(true);
    setErrorDelete(false);
    setEnviando(false);
    if(valor === 4){
      setCargando(true);
    }


  };
  const handleClose = () => {
    if(ocularDrop==false){
      setOpen(false);
      setErrorUploadVideo(false);
      setSuccess(false);
      setArchivosNuevos(false);
      setError(false);
      setNombreArchivo("");
    }

  };

  /*
  *Imagenes 4 mb jpg,png
  *Videos  15 mb mp4
  *Audios  15 mb mp3
  *Documentos 20 mb pdf,doc,dox,csv,txt,xls,xlsx,ppt,pptx,



  */
  const enviarMensaje=(mensaje)=>
  {
      setErrorEnviar(false);
      if(mensaje=== undefined)
      {
        setErrorEnviar(true);
        return 0;
      }

      EnviarMensaje(
        chatDispach,
        mensaje.link,
        mensaje.tipo,
        mensaje.nombre,
        mensaje.tamanio,
        mensaje.propietario,
        usuario_actual,
        mensajes_chat,
        user,
        datos,
        setError,
        setEnviando,
        ReiniciarVariables,
      );

    }

    const enviarMensajeBorrandoArchivo=(mensaje)=>
    {
        setErrorEnviar(false);
        if(mensaje=== undefined)
        {
          setErrorEnviar(true);
          return 0;
        }

        EnviarMensaje(
          chatDispach,
          mensaje.link,
          mensaje.tipo,
          mensaje.nombre,
          mensaje.tamanio,
          mensaje.propietario,
          usuario_actual,
          mensajes_chat,
          user,
          datos,
          setError,
          setEnviando,
          BorrarArchivoTemporal(mensaje.refStorage, mensaje.refDB),
        );

      }


  const BorrarArchivoTemporal=(refStorage, refDB)=>{
        console.log('refStorage', refStorage);
        console.log('refdb', refDB);
        setOpen(false);
        setArchivosNuevos([]);
        setErrorDelete(false);
        setEnviando(false);
        /*storage.ref(refStorage).delete().then(() => {
          console.log("borrado");
        }).catch((error) => {
          console.log("error al borrar", error);
        });
        db.ref(refDB).set(null);*/
    }

  const ReiniciarVariables=()=>{
      setOpen(false);
      setArchivosNuevos([]);
      setErrorDelete(false);
      setEnviando(false);
  }

  const BorrarContenido=(direccion)=>{


    setErrorDelete(false);
    setEnviando(true);
    if(direccion.ref===undefined || direccion.ref===null)
    {
      setEnviando(false);
      return 0;
    }
    storage.ref(direccion.ref).delete().then(function() {
      // File deleted successfully
      let tipo= direccion.tipo===0? 'imagen':
                                  direccion.tipo===1? 'archivos':
                                  direccion.tipo===2? 'audios':'videos';

      db.ref('/multimedia/'+datos.id_empresa+'/'+tipo+'/'+direccion.id).set(null);
      setEnviando(false);

    }).catch(function(error) {
      //console.log("error",error);
      setErrorDelete(true);
      setEnviando(false);
    });

  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
    <IconButton
      aria-label="send message"
      edge="end"
      onClick={handleClick}
      >
    <AttachFileIcon/>
    </IconButton>

  { usuario_actual.info!== undefined &&
      <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={e =>handleClickOpen(0)}><PhotoLibraryIcon/>Imagenes</MenuItem>
      {usuario_actual.info.red !== "instagram" && <MenuItem onClick={e =>handleClickOpen(1)}><InsertDriveFileIcon/>Archivos</MenuItem>}
      {usuario_actual.info.red !== "instagram" && <MenuItem onClick={e =>handleClickOpen(2)}><MicIcon/>Audios</MenuItem>}
      {usuario_actual.info.red !== "instagram" && <MenuItem onClick={e =>handleClickOpen(3)}><VideocamIcon/>Videos</MenuItem>}
      {<MenuItem onClick={e =>handleClickOpen(4)}><LaptopChromebookIcon/>Desde pc</MenuItem>}
    </Menu>
  }


      { /*-------------------------------imagenes-------------------------------------------------------------------*/}
      <Dialog onClose={handleClose}
              disableBackdropClick
              disableEscapeKeyDown fullWidth={true} aria-labelledby="customized-dialog-title" open={open} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {ventana===0 && "Imagenes"}
          {ventana===1 && "Archivos"}
          {ventana===2 && "Audios"}
          {ventana===3 && "Videos"}
          {ventana===4 && "Desde PC"}
        </DialogTitle>
          {errorEnviar && <Alert severity="error">Error al enviar el archivo adjunto</Alert> }
          {errorDelete && <Alert severity="error">Error al borrar el contenido</Alert> }
          {ventana===0 && listaImagenes.length>45 && <Alert severity="warning">Se supero el numero maximo de imagenes permitidas</Alert>}
          {ventana===1 && listaArchivos.length>45 && <Alert severity="warning">Se supero el numero maximo de archivos permitidos</Alert>}
          {ventana===2 && listaAudios.length>45 && <Alert severity="warning">Se supero el numero maximo de audios permitidos</Alert>}
          {ventana===3 && listaVideos.length>45 && <Alert severity="warning">Se supero el numero maximo de videos permitidos</Alert>}
          {enviando &&  <CircularProgress disableShrink />}
        <DialogContent dividers>
          <Grid spacing={2} container >
            <Grid item xs={12}>
            {cargando ?
                  <Grid spacing={2} container >

                  {ocularDrop===false && ventana !== 4 && <Grid item xs={12}>
                    <Button  color="primary" variant="contained"
                    size="large" onClick={e =>{setCargando(false);
                                               setErrorUploadVideo(false);
                                               setSuccess(false);
                                               setErrorDelete(false);
                                               setEnviando(false);
                                             }} >
                      Regresar
                    </Button>
                  </Grid>
                  }
                    <Grid item xs={12}>
                      <LinearProgress variant="determinate" value={progress} />
                    </Grid>

                    { errorUploadVideo &&
                      <Grid item xs={12}>
                        <Alert severity="error">Solo se pueden enviar archivos de Microsoft Oficce y archivos pdf</Alert>
                      </Grid>
                    }

                    { error &&
                      <Grid item xs={12}>
                        <Alert severity="error">Error al guardar elemento</Alert>
                      </Grid>
                    }

                    { success &&
                      <Grid item xs={12}>
                        <Alert severity="success">Elemento cargado exitosamente</Alert>
                      </Grid>
                    }


                    {ocularDrop===false && ventana !== 4 &&
                    <Grid item xs={12}>
                      <TextField
                           value={nombreArchivo}
                           onChange={e => setNombreArchivo(e.target.value)}
                           label="Nombre (Opcional)"
                           variant="outlined"
                           error={nombreArchivo.length>101}
                           helperText={nombreArchivo.length>101?"El nombre no puede tener mas de 100 caracteres":""}
                           disabled={ocularDrop}
                           fullWidth
                           />
                    </Grid>}

                    { ocularDrop===false &&

                        <Grid item xs={12}>
                          {ventana === 0 && listaImagenes.length<46 &&//imagen
                          (<DropzoneArea
                          filesLimit={1}
                          maxFileSize={4000000}
                          acceptedFiles={['image/png,image/jpeg']}
                          dropzoneText={"Subir Imagenes"}
                          value={archivosNuevos}
                          onChange={(files) => setArchivosNuevos(files)}
                          />)}

                          {  ventana === 1 && listaArchivos.length<46 && //archivo
                          (<DropzoneArea
                          filesLimit={1}
                          maxFileSize={20000000}
                          acceptedFiles={['application/pdf,'+
                                          'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,'+
                                          'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'+
                                          'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'+
                                          'application/vnd.oasis.opendocument.text,'+
                                          'text/csv,text/plain']}
                          dropzoneText={"Subir Archivos"}
                          value={archivosNuevos}
                          onChange={(files) => setArchivosNuevos(files)}
                          />)}

                          {ventana === 2 && listaAudios.length<46 &&//audio
                          (<DropzoneArea
                          filesLimit={1}
                          maxFileSize={10000000}
                          acceptedFiles={['audio/mpeg']}
                          dropzoneText={"Subir Audios"}
                          value={archivosNuevos}
                          onChange={(files) => setArchivosNuevos(files)}
                          />)}

                          {ventana === 3 &&  listaVideos.length<46 &&//video
                          (<DropzoneArea
                          filesLimit={1}
                          maxFileSize={10000000}
                          acceptedFiles={['video/mp4']}
                          dropzoneText={"Subir Video"}
                          value={archivosNuevos}
                          onChange={(files) => setArchivosNuevos(files)}
                          />)}

                          {ventana === 4  &&//imagen
                          (<DropzoneArea
                          filesLimit={1}
                          maxFileSize={4000000}
                          acceptedFiles={['image/png,image/jpeg']}
                          dropzoneText={"Subir Imagenes"}
                          value={archivosNuevos}
                          onChange={(files) => setArchivosNuevos(files)}
                          />)}

                        </Grid>
                    }

                    {ocularDrop===false &&
                      ventana === 4 ?
                        <Button  color="primary" onClick={EnviarArchivoSinGuardar} disabled={nombreArchivo.length>101}>
                            Enviar
                        </Button>
                    :
                        <Button  color="primary" onClick={SubirArchivos} disabled={nombreArchivo.length>101}>
                            Cargar archivo
                        </Button>
                  }


                  </Grid>

                :
                  <React.Fragment>
                    <Grid spacing={2} container >
                    {ventana === 0 && enviando===false && listaImagenes.map((item,index)=>(
                     <ManagerFile
                          key={item.key}
                          datos={item}
                          avatar={item.link}
                          enviar={enviarMensaje}
                          tipo={0}
                          borrarContenido={BorrarContenido}

                          />

                    ))}
                    {ventana === 1 && enviando===false && listaArchivos.map((item,index)=>(
                    <ManagerFile
                          key={item.key}
                          datos={item}
                          avatar={item.link}
                          enviar={enviarMensaje}
                          tipo={1}
                          borrarContenido={BorrarContenido}
                          />

                      ))}
                      {ventana === 2 && enviando===false && listaAudios.map((item,index)=>(
                      <ManagerFile
                              key={item.key}
                              datos={item}
                              avatar={item.link}
                              enviar={enviarMensaje}
                              tipo={2}
                              borrarContenido={BorrarContenido}
                              />
                      ))}
                      {ventana === 3 && enviando===false && listaVideos.map((item,index)=>(
                      <ManagerFile
                                key={item.key}
                                datos={item}
                                avatar={item.link}
                                enviar={enviarMensaje}
                                tipo={3}
                                borrarContenido={BorrarContenido}
                                />

                      ))}
                      </Grid>

                  </React.Fragment>
            }

              </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          {cargando===false &&
            <Button  color="primary" onClick={e => setCargando(true)} >
            subir archivo
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}


function file_extension(filename){
       return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
   }
