import React from 'react'
import {
    Home as HomeIcon,
    FilterNone as UIElementsIcon,
    BorderAll as TableIcon,
    QuestionAnswer as SupportIcon,
    LibraryBooks as LibraryIcon,
    HelpOutline as FAQIcon,
    BarChart as ChartIcon,
    Map as MapIcon,
    Apps as CoreIcon,
    Description as DescriptionIcon,
    ShoppingCart as ShoppingCartIcon,
    StarBorder as ExtraIcon,
    Chat as ChatIcon,
    Add as AddSectionIcon,
    FolderOpen as FolderIcon,
    Description as DocumentationIcon,
    Person as UserIcon,
    ShoppingCart as ShoppingCart,
    Assessment as Assessment,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import LanguageIcon from '@material-ui/icons/Language';
import BuildIcon from '@material-ui/icons/Build';
import CreateIcon from '@material-ui/icons/Create';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';


// components
import Dot from './components/Dot'

const structure = [
    { id: 0, type: "title",label: 'DASHBOARD' },
  /*  { id: 1, label: 'Mis Redes', link: '/app/redes', icon: <HomeIcon /> },*/
    /*{ id: 3, label: 'Ordenes de Compra', icon: <ShoppingCart /> },*/

    { id: 4, type: 'divider' },
    { id: 5, type: "title", label: 'Mis Redes' },
    { id: 6, label: 'Facebook',  link:'',icon: <FacebookIcon/>,
    children:[
        {label: 'Asistentes',link:'/app/facebook'},
        {label: 'Reportes', link: '/app/reports' },
        {label: 'ChatCenter', link: '/app/chatCenter' },
        /*{label: 'Secuencias', link: '/app/secuencia' },*/
    ]},
    /*{ id: 7, label: 'Whatsapp',  icon: <WhatsAppIcon /> },*/
    { id: 8, label: 'Telegram',  icon: <TelegramIcon />,
    children:[
      {label: 'Asistentes',link:'/app/telegram'},
      {label: 'Reportes', link: '/app/tgreports' },
      {label: 'ChatCenter', link: '/app/tgchatCenter' },
    ]},
    /*{ id: 9, label: 'Website',  icon: <LanguageIcon /> },*/

    { id: 10, type: 'divider' },
    { id: 11,type: "title", label: 'Configuraciones' },
    { id: 12, label: 'Mi Perfil',link:'/app/periflUsuario', icon: <PeopleAltIcon /> },
    { id: 13, label: 'Administrador', link: '/app/admin', icon: <BuildIcon /> },
]

function AddSection() {
    const useStyles = makeStyles(theme => ({
        root: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '50%',
            height: 30,
            width: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
        },
    }))

    const classes = useStyles()

    return (
        <section className={classes.root}>
            <AddSectionIcon />
        </section>
    )
}

export default structure
