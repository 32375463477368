import React, { useState ,useEffect} from 'react'
import {
    Grid,
    Box,
    TableRow,
    TextField,
    TableCell,
    Checkbox,
    TableHead,
    TableSortLabel,
    Toolbar,
    IconButton,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
// styles
import useStyles from './styles'
import Avatar from '@material-ui/core/Avatar';
import original from '../../images/original.png'
import Themes from "../../themes"
import { useThemeDispatch } from "../../context/ThemeContext"
import ChildCareIcon from '@material-ui/icons/ChildCare';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

// components
import WidgetSetting from '../../components/WidgetSetting'
import {Button,  Typography, Link } from '../../components/Wrappers'
import {
    Delete as DeleteIcon,
    FilterList as FilterListIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import { lighten } from '@material-ui/core/styles'
import cn from 'classnames'
import {db} from "../../services/firebase";
import AppBar from '@material-ui/core/AppBar';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import { useUserState} from "../../context/UserContext";
// Recent Orders

const useStyles1 = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

function Artificial(props) {
    var classes = useStyles()
    var classes1 = useStyles()
    var theme = useTheme()
    var { datos } = useUserState();
    const handlerCopy=(event) =>{
        event.preventDefault();
      }

    const [datosBd, setDatosBd] = React.useState('');
    const [keyBot,setKeyBot]=React.useState('');
    const [tokenBot,setTokenBot]=React.useState('');
    const [urlia,setUrlIA]=React.useState('');
    const [skillia,setSkillIA]=React.useState('');
    const [apiia,setApiIA]=React.useState('');
    const [clientechat, setClientechat] = React.useState('');
    /*Obtener datos de la base de datos*/

    useEffect(() =>{
      db.ref('bots/'+datos.id_empresa+"/"+props.match.params.id+"/nombre").on(
          'value',snapshot => {
              var datos=snapshot.val();
              setDatosBd(datos);
      });
      db.ref('IA/'+datos.id_empresa+"/"+props.match.params.id+"/watson").on(
          'value',snapshot => {
              var ia=snapshot.val();
              if(ia!==null)
            {  setApiIA(ia['api_key']);
              setSkillIA(ia['skill_id'])
              setUrlIA(ia['url'])}
      });
  },[]);



    return (
      <div className="container">
        <Grid>
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <BrightnessAutoIcon />
              </IconButton>
              <Typography variant="body2" color="inherit">Asistente: {datosBd}</Typography>
            </Toolbar>
          </AppBar>
        </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <List md={2}>
                  <ListItem>
                    <ListItemIcon>
                     <Avatar>
                      <ChildCareIcon />
                     </Avatar>
                    </ListItemIcon>
                    <WidgetSetting style={{width:"80%"}}
                        title="Conexión IA"
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}>
                        <Grid container spacing={4} justify={"space-between"}  alignItems={"flex-start"}>
                          <Grid item xs={6}>
                            <TextField disabled id="Key_IA" label="IA Key" fullWidth value={apiia} InputLabelProps={{
                                    shrink: true,readOnly: true,}} onCopy={handlerCopy} />
                          </Grid>
                          <Grid item xs={6}>
                              <TextField disabled id="skill"label="Skill" fullWidth value={skillia} InputLabelProps={{
                                      shrink: true,readOnly: true,}} onCopy={handlerCopy} />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField disabled id="Url_IA" value={urlia} label="URL" fullWidth InputLabelProps={{
                                    shrink: true,readOnly: true,}} onCopy={handlerCopy} />
                          </Grid>
                        </Grid>
                    </WidgetSetting>
                    </ListItem>
                </List>
              </Grid>
            </Grid>
      </div>

    )
}

// #######################################################################


export default Artificial;
