import React, { forwardRef,useState ,useEffect,Component} from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Button, Typography,Avatar } from "../../../components/Wrappers";
import {useChatState,useChatDispatch,CerrarAtributos} from '../context/ChatContext';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import useStyles from '../styles';
import {Grid,  } from "@material-ui/core";
import {db} from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import log_it from './../../../services/log/log'

function Atributos(){

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
      };

    var {atributos,datos_bot,datos_chat,usuario_actual,bot_actual,actualizar_atributo} = useChatState();
    var chatDispach=useChatDispatch();
    var { datos, user } = useUserState();
    var classes = useStyles();

    const [atributosUser,setAtributosUser]=React.useState("");
    const [state, setState] = React.useState({
        //editable?: ('always' | 'onUpdate' | 'onAdd' | 'never'); /^\s+|\s+$/g
        columns: [
        { title: 'atributo', field: 'atributo',initialEditValue:"atributo" ,validate : rowData =>/^[a-zA-Z0-9_-]{1,30}$/.test(rowData.atributo) ?true : { isValid: false, helperText: 'El atributo no puede contener espacios en blanco y debe tener entre 1-30 caracteres' } },
        { title: 'valor', field: 'valor' ,initialEditValue:"valor",  validate : rowData => rowData.valor.length >=1  ?true : { isValid: false, helperText: 'El valor no puede estar vacío' } },
        ],
        data: [
        ],
      });

    useEffect(() =>{

        if(usuario_actual!=="")
        {
            if(usuario_actual.chatcenter)
            {
                //si esta en la lista chatcenter
                let index_agregar = datos_chat.findIndex(item => item.id === usuario_actual.id);

                if(index_agregar!==-1)
                {
                  var arreglo = Object.entries(datos_chat[index_agregar].dato.atributos);
                  let lista_atributos=[];
                  arreglo.map(item=>{
                      if(item[0]!="chatcenter" &&
                         item[0]!="fecha_chatcenter" &&
                         item[0]!="fb_picture" &&
                         item[0]!="bloque"  &&
                         item[0]!="posicion" &&
                         item[0]!="ultima_fecha" &&
                         item[0]!="log_chatcenter" &&
                         item[0]!="asesor_id" &&
                         item[0]!="asesor_name" &&
                         item[0]!="ultima_fecha" &&
                         item[0]!="ig_picture"
                        ){
                          lista_atributos.push({atributo: item[0], valor: item[1] });
                      }
                    });
                  setState((prevState) => {
                  return { ...prevState, data:lista_atributos };
                  });
                }

            }else{
              // console.log("usuairo no chat")
              //si no esta en la lista de chat center
              let index_agregar = datos_bot.findIndex(item => item.id === usuario_actual.id);
          //    console.log("index_agregar",index_agregar);
              if(index_agregar!==-1)
              {
                var arreglo = Object.entries(datos_bot[index_agregar].dato.atributos);
                let lista_atributos=[];
                arreglo.map(item=>{
                    if(item[0]!="chatcenter" &&
                       item[0]!="fecha_chatcenter" &&
                       item[0]!="fb_picture" &&
                       item[0]!="bloque"  &&
                       item[0]!="posicion" &&
                       item[0]!="ultima_fecha" &&
                       item[0]!="log_chatcenter" &&
                       item[0]!="asesor_id" &&
                       item[0]!="asesor_name" &&
                       item[0]!="ultima_fecha" &&
                       item[0]!="ig_picture" ){
                        lista_atributos.push({atributo: item[0], valor: item[1] });
                    }
                  });
                setState((prevState) => {
                return { ...prevState, data:lista_atributos };
                });
              }
            }


        }else{
            setAtributosUser("");
        }

      },[usuario_actual,actualizar_atributo]);

    const cerrarModal=()=>{
    CerrarAtributos(chatDispach);
    }


    var nuevo_atributo=(newData) =>
    new Promise((resolve) => {
      var nombre_log = "agregar atributos";
      var codigo_log = 411;
      //si esta en la lista chatcenter
      var atributos_actuales;
      var index_agregar = datos_chat.findIndex(item => item.id === usuario_actual.id);
      if(index_agregar !=-1){
        atributos_actuales = Object.entries(datos_chat[index_agregar].dato.atributos);
      }

      if(index_agregar === -1 ){
        index_agregar = datos_bot.findIndex(item => item.id === usuario_actual.id);
        atributos_actuales = Object.entries(datos_bot[index_agregar].dato.atributos);
      }


      if(index_agregar!==-1){
        //console.log(atributos_actuales)
        atributos_actuales.forEach(element => {
          //console.log(element )
          if(element[0]==newData.atributo){
            nombre_log = "editar atributos";
            codigo_log = 412;
          }
        })

      }

      if ( newData.atributo ===  "intentos" || newData.atributo === "fecha_ingreso" || newData.atributo === "fb_username" || newData.atributo === "wb_username" || newData.atributo === "ig_username" || newData.atributo === "tg_username" || newData.atributo === "tg_nombre" || newData.atributo === "ws_username")
      {
        resolve();
      }else{

        let json_string = "{\""+newData.atributo+"\":\""+newData.valor+"\"}";
        let nuevo = JSON.parse(json_string);

        db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos").update(
         nuevo, function(error) {
            if (error) {
                resolve();
            } else {
              var obj = {
                fecha: + new Date(),
                nombre:nombre_log,
                id_accion: codigo_log,
                id_bot: usuario_actual.info.bot,
                red_social: usuario_actual.info.red,
                usuario:usuario_actual.key,
                nombre_usuario:usuario_actual.user_name,
                atributo:newData.atributo
              }

              log_it(datos.id_empresa,user.id,obj)
                resolve();
            }
          }
          );
      }
    });


    var eliminar_atributo=(oldData) =>
    new Promise((resolve) => {

    if ( oldData.atributo ===  "intentos" || oldData.atributo === "fecha_ingreso" || oldData.atributo === "fb_username" || oldData.atributo === "wb_username" || oldData.atributo === "ig_username" || oldData.atributo === "tg_username" || oldData.atributo === "tg_nombre" || oldData.atributo === "ws_username")
      {
        resolve();
      }else{

        let json_string = "{\""+oldData.atributo+"\":null}";
        let nuevo = JSON.parse(json_string);
        db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos").update(
         nuevo, function(error) {
            if (error) {
                resolve();
            } else {
              var obj = {
                fecha: + new Date(),
                nombre: "eliminar atributos",
                id_accion: 413,
                id_bot: usuario_actual.info.bot,
                red_social: usuario_actual.info.red,
                usuario:usuario_actual.key,
                nombre_usuario:usuario_actual.user_name,
                atributo:oldData.atributo
              }
              log_it(datos.id_empresa,user.id,obj)
              resolve();
            }
          }
          );

      }
    });

    return(

                <Drawer anchor={"bottom"} open={atributos} onClose={cerrarModal}>
                <MaterialTable
                    className={classes.AtributosTamMax}
                    icons={tableIcons}
                    title="Atributos"
                    columns={state.columns}
                    data={state.data}
                    editable={{
                        onRowAdd: nuevo_atributo,
                        onRowUpdate: nuevo_atributo,
                        onRowDelete: eliminar_atributo,
                    }}
                    />
                </Drawer>

    )




}

export default Atributos;
