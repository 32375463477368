import React, { useState ,useEffect,Component} from 'react'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
    Grid,
    NativeSelect,
    Box,
    Badge,
    TableRow,
    TableCell,
    Checkbox,
    TableHead,
    TableSortLabel,
    Toolbar,
    IconButton,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    TextField,
    RadioGroup,
    Tabs,
    Tab,
    Menu,
    Select,
    Paper,
    Stepper,
    Step,
    StepLabel
} from '@material-ui/core'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import MobileStepper from '@material-ui/core/MobileStepper';
import AppBar from '@material-ui/core/AppBar';
//-------------------------------------------------------------
import { useTheme, makeStyles } from '@material-ui/styles'
import { useThemeDispatch } from "../../context/ThemeContext"
import {Button,  Typography, Link } from '../../components/Wrappers'
import PropTypes from 'prop-types'
import cn from 'classnames'
//import Steppers from 'react-stepper-horizontal';
//import Carousel from 'react-bootstrap/Carousel'
// styles
import useStyles from './styles'
import Themes from "../../themes"
import { lighten } from '@material-ui/core/styles'

//Images
import original from '../../images/original.png'

// components
import WidgetBlock from '../../components/WidgetBlock'
import WidgetElement from '../../components/WidgetElement'
//import TextElement from './elements/TextElement/TextElement'

// database
import {db} from "../../services/firebase";

//icons-------------------------
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import ReplyIcon from '@material-ui/icons/Reply';
import ChatIcon from '@material-ui/icons/Chat';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import HttpIcon from '@material-ui/icons/Http';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

import { useUserState} from "../../context/UserContext";

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
//setting of tabs----------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
//------------------------------------------------------
//***************************************************************

//start function main bots
const useStyles1 = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabc: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,
   display: 'flex',
   height: 224,
   width:50,
 },
 root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function Bots(props) {
    var classes = useStyles();
    var classes1 = useStyles1();
    var theme = useTheme()
    //control of carousel------
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
      setActiveStep(0);
    };
    var { datos } = useUserState();
    //var to save info db----------------------------------
    const [datosBd, setDatosBd] = React.useState('');
    const [bloque, setBloque] = React.useState([]);
    const [numbloque, setNumBloque] = React.useState(0);
    const [elemento,setElemento]=React.useState([]);
    const [carousels,setCarousel]=React.useState([]);
    // vars to tabs--------------------------------------------
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [valuex, setValuex] = React.useState(0);

    const [progeso,setProgreso]=React.useState(true);
    /*Obtener datos de la base de datos*/

    useEffect(() =>{

          //get name bot
            db.ref('bots/'+datos.id_empresa+"/"+props.match.params.id+"/nombre").once(
                'value',snapshot => {
                    var datos=snapshot.val();
                    setDatosBd(datos);
            });
            //get data of blocks by bot
            db.ref('bloques/'+datos.id_empresa+"/facebook/"+props.match.params.id).once("value", function(snap) {
              setNumBloque(snap.numChildren())
              setProgreso(true);
              snap.forEach(function(data) {

                  if (data.key==='default'){
                    setBloque(bloque => [data.key,...bloque]);
                    setElemento(elemento=>[data.val().elementos,...elemento]);
                  }else{
                    setBloque(bloque => [...bloque,data.key]);
                    setElemento(elemento=>[...elemento,data.val().elementos]);
                  }
              });
              setProgreso(false);
            });
            return  () =>{
              
            }

        },[]);

// event to numer block badge
  const badgeProps = {
    color: 'primary',
    children: <AppsIcon />,
  };
// event to tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
//var to show tabs with blocks---------------------------------
  const MyTabs=()=>{
    let bloques=[];
    for (let i = 0; i <(numbloque); i++) {
      bloques.push(<Tab component="div" key={'bloque'+i} label={
        <Grid container justify={"space-between"}  alignItems={"flex-start"}>
            <Grid item xs={10}>
              <Link component="button" variant="body2"><strong>{bloque[i]}</strong></Link>
            </Grid>
          </Grid>}{...a11yProps(i)}/>);
    }
    return bloques
  };

  // functions to show elements---------------------------------
  const ListElement=()=> {
    let paneltabs=[];
    for (let i=0;i<(numbloque);i++){
      paneltabs.push(<TabPanel key={"tab"+i} value={value} index={i}>
                      <List md={2}>
                              <MyElement tab={value}/>
                      </List>
                    </TabPanel>);

  }
    return paneltabs;
  };
  //----------------Elements-----------------------------------
  const MyElement=(props)=>{
      let partes=[];
      if(typeof elemento[props.tab] !== "undefined"){
        //console.log('long: ',elemento[props.tab].length);
        for(let i=0;i<elemento[props.tab].length;i++){
          if(elemento[props.tab][i].elemento==='texto'){
            partes.push(<TextElement key={(props.tab)+'-'+i} info={elemento[props.tab][i]}/>);
          }else if(elemento[props.tab][i].elemento==='archivo'){
            partes.push(<ImageElement key={(props.tab)+'-'+i} info={elemento[props.tab][i]}/>);
          }else if(elemento[props.tab][i].elemento==='chatcenter'){
            partes.push(<ChatCenterElement key={(props.tab)+'-'+i} info={elemento[props.tab][i]}/>);
          }else if(elemento[props.tab][i].elemento==='texto_rapido'){
            partes.push(<QuickReplyElement key={(props.tab)+'-'+i} info={elemento[props.tab][i]}
                        llave={(props.tab)+'-'+i}/>);
          }else if(elemento[props.tab][i].elemento==='texto_boton'){
            partes.push(<TextoBotonElement key={(props.tab)+'-'+i} info={elemento[props.tab][i]}
                        llave={(props.tab)+'-'+i}/>);
          }else if(elemento[props.tab][i].elemento==='carrusel'){
            partes.push(<CarouselElement key={(props.tab)+'-'+i} info={elemento[props.tab][i]}
                        llave={(props.tab)+'-'+i}/>);
          }
          //new elements-----
          else if(elemento[props.tab][i].elemento==='webservice'){
            partes.push(<Webservice key={(props.tab)+'-'+i} info={elemento[props.tab][i]}
                        llave={(props.tab)+'-'+i}/>);
          }else if(elemento[props.tab][i].elemento==='detalle'){
            partes.push(<Detail key={(props.tab)+'-'+i} info={elemento[props.tab][i]}
                        llave={(props.tab)+'-'+i}/>);
          }
        }
    }
    return partes;
  };
  //----Elements---------------------------------------------------------
  const TextElement=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
            <AssignmentRoundedIcon />
            </Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Texto" bodyClass={classes.fullHeightBody} className={classes.card}>
              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={10}>
                      <div>
                        <TextField
                          id="standard-multiline-flexible1"
                          label="Mensaje"
                          multiline
                          rowsMax={2} fullWidth
                          value={props.info.mensaje}
                        />
                      </div>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel control={<Checkbox name="checkedC" />} label="IA"
                    checked={props.info.ia}/>
                </Grid>
                <Grid item xs={10}>
                        <TextField
                          id="standard-multiline-flexible2"
                          label="Mensaje de Error"
                          multiline
                          rowsMax={2} fullWidth
                          value={props.info.mensaje_error}
                        />
                </Grid>
                <Grid item xs={2}>
                        <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                          id="standard-multiline-flexible3"
                          label="Limite" fullWidth
                          value={props.info.intento}
                        />
                </Grid>
                <Grid item xs={7}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="uncontrolled-native">Método</InputLabel>
                    <NativeSelect
                      defaultValue={props.info.validacion}
                      inputProps={{name: 'periodo', id: 'perdiodo-tiempo',}}>
                      <option value={'nit'}>nit</option>
                      <option value={'telefono'}>telefono</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                        <TextField
                          id="standard-multiline-flexible4"
                          label="Atributo" fullWidth
                          value={props.info.atributo}
                        />
                </Grid>
              </Grid>
            </WidgetElement>
        </ListItem>
    );
  };
  const ImageElement=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
             <ImageRoundedIcon />
            </Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Archivo" bodyClass={classes.fullHeightBody} className={classes.card} >
              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={12}>
                        <TextField id="standard-multiline-flexible9" label="URL"
                          fullWidth
                          value={props.info.url}/>
                </Grid>
                <Grid item xs={12}>
                        <TextField id="standard-multiline-flexible9" label="Tipo"
                          fullWidth
                          value={props.info.tipo}/>
                </Grid>
              {props.info.tipo==="image" &&
                <Grid item xs={3}>
                  <Avatar alt="Url" src={props.info.url} className={classes1.large}/>
                </Grid>}
              </Grid>
            </WidgetElement>
        </ListItem>
    );
  };
  const ChatCenterElement=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
              <ChatIcon />
            </Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Chat Center" bodyClass={classes.fullHeightBody} className={classes.card}>

              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={12}  >
                      <div>
                        <TextField
                          id="standard-multiline-flexible14"
                          label="Mensaje"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje}
                        />
                      </div>
                </Grid>
                <Grid item xs={10}  >
                        <TextField
                          id="standard-multiline-flexible15"
                          label="Mensaje de espera"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje_espera}
                        />
                </Grid>
                <Grid item xs={4}>
                    <Typography>Replies <small>(horizontal)</small></Typography>
                </Grid>
                <Grid item xs={8} >
                  <Button variant="outlined" color="primary"><small>{props.info.quick_replies[0].title}</small></Button>
                  <Button variant="outlined" color="primary"><small>{props.info.quick_replies[1].title}</small></Button>
                </Grid>

              </Grid>
            </WidgetElement>
        </ListItem>
    );
  };
  const QuickReplyElement=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
            <ReplyIcon /></Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Texto Rápido" bodyClass={classes.fullHeightBody} className={classes.card}>

              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={12}>
                      <div>
                        <TextField
                          id="standard-multiline-flexible10"
                          label="Mensaje"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje}
                        />
                      </div>
                </Grid>
                <Grid item xs={10}>
                        <TextField
                          id="standard-multiline-flexible11"
                          label="Mensaje de Error"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje_error}
                        />
                </Grid>
                <Grid item xs={2} >
                        <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                          id="standard-multiline-flexible12"
                          label="Limite" fullWidth value={props.info.intento}
                        />
                </Grid>
                <Grid item xs={5}  >
                        <TextField
                          id="standard-multiline-flexible13"
                          label="Atributo" fullWidth value={props.info.atributo}
                        />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel control={<Checkbox name="checkedC" />} label="IA"
                    checked={props.info.ia}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Replies <small>(alineación horizontal)</small></Typography>
                </Grid>
                <Grid item xs={12} >
                  <QRmodules qr={props.info.quick_replies.length} info={props.info} keys={props.llave}/>
                </Grid>

              </Grid>
            </WidgetElement>
        </ListItem>
    );
  };
  const QRmodules=(props)=>{
    let module=[];
      for(let i=0;i<props.qr;i++){
        module.push(<Button variant="outlined" color="primary" key={"qr-"+props.keys+"-"+i}>
                    <small>{props.info.quick_replies[i].title}{(props.info.quick_replies[i].enviar_a==="")?"":":"}
                      {props.info.quick_replies[i].enviar_a}</small></Button>);
      }

    return module;
  };

  const TextoBotonElement=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
            <ViewAgendaIcon /></Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Plantilla de Botón" bodyClass={classes.fullHeightBody} className={classes.card}>
              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={12}>
                      <div>
                        <TextField
                          id="standard-multiline-flexible5"
                          label="Mensaje"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje}
                        />
                      </div>
                </Grid>
                <Grid item xs={10}>
                        <TextField
                          id="standard-multiline-flexible6"
                          label="Mensaje de Error"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje_error}
                        />
                </Grid>
                <Grid item xs={2}>
                        <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                          id="standard-multiline-flexible7"
                          label="Limite" fullWidth value={props.info.intento}
                        />
                </Grid>
                <Grid item xs={5}>
                        <TextField
                          id="standard-multiline-flexible8"
                          label="Atributo" fullWidth value={props.info.atributo}
                        />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Botones <small>(alineación vertical)</small></Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextBotonModule buttonum={props.info.buttons.length} info={props.info} keys={props.llave}/>
                </Grid>
              </Grid>
            </WidgetElement>
        </ListItem>
    );
  };
  const TextBotonModule=(props)=>{
    let module=[];
      for(let i=0;i<props.buttonum;i++){
        if(props.info.buttons[i].type==="web_url"){
          module.push(<Button variant="outlined" color="primary" key={"button_web-"+props.keys+"-"+i}>
                      <small>{props.info.buttons[i].title}{(props.info.buttons[i].url==="")?"":":"}
                        {props.info.buttons[i].url}</small></Button>);
        }else if(props.info.buttons[i].type==="postback"){
          module.push(<Button variant="outlined" color="primary" key={"button_post"+i}>
                      <small>{props.info.buttons[i].title}{(props.info.buttons[i].enviar_a==="")?"":":"}
                        {props.info.buttons[i].enviar_a}</small></Button>);
        }
      }

    return module;
  };
  const CarouselElement=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
            <ViewCarouselIcon />
            </Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Carrusel" bodyClass={classes.fullHeightBody} className={classes.card}>

              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={10}  >
                        <TextField
                          id="standard-multiline-flexible16"
                          label="Mensaje de Error"
                          multiline
                          rowsMax={2} fullWidth value={props.info.mensaje_error}
                        />
                </Grid>
                <Grid item xs={2}  >
                        <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                          id="standard-multiline-flexible17"
                          label="Limite" fullWidth value={props.info.intento}
                        />
                </Grid>
                <Grid item xs={5}  >
                        <TextField
                          id="standard-multiline-flexible18"
                          label="Atributo" fullWidth value={props.info.atributo}
                        />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Módulos <small>(alineación horizontal)</small></Typography>
                </Grid>
                  <div className={classes.root}>
                    <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                      <Grid item xs={6}>
                        <CarouselModule modulenum={props.info.modulos.length} info={props.info} keys={props.llave}/>
                      </Grid>
                </Grid>
                  </div>

              </Grid>
            </WidgetElement>
        </ListItem>
    );
  };
  const CarouselModule=(props)=>{
    let module=[];
    let pasos=[];
    for(let i=0;i<props.modulenum;i++){
      module.push(<div key={"carousel-mod-"+props.keys+"-"+i} >
      <WidgetElement disableWidgetMenu
          title="Módulo" bodyClass={classes.fullHeightBody} className={classes.card}>
          <Grid container spacing={1} justify={"space-between"}  alignItems={"flex-start"}>
            <Grid item xs={6}>
                  <div>
                    <TextField
                      id={"title-"+props.keys+"-"+i}
                      label="Título" fullWidth value={props.info.modulos[i].title}
                      />
                  </div>
            </Grid>
            <Grid item xs={6}>
                  <div>
                    <TextField
                      id={"subtitle-"+props.keys+"-"+i}
                      label="Subtítulo" fullWidth value={props.info.modulos[i].subtitle}/>
                  </div>
            </Grid>
            <Grid item xs={8}>
                    <TextField
                      id={"url-"+props.keys+"-"+i}
                      label="Imagen URL" fullWidth value={props.info.modulos[i].image_url}
                      />
            </Grid>
            <Grid item xs={12}>
              <Typography>Botones <small>(alineación vertical)</small></Typography>
            </Grid>
            <Grid item xs={12}>
              <CarouselButtons buttonnum={props.info.modulos[i].buttons.length} info={props.info.modulos[i]} keys={props.llave}/>
            </Grid>
          </Grid>
        </WidgetElement>
      </div>
    );

    }
    pasos.push(<Carousel className="carousel-wrapper" key={"carousel-item-"+props.key}>{module}</Carousel>);
    return pasos;
  };
  const CarouselButtons=(props)=>{
    let module=[];
      for(let i=0;i<props.buttonnum;i++){
        if(props.info.buttons[i].type=="web_url"){
          module.push(<Button variant="outlined" color="primary" key={"button_web-"+props.keys+"-"+i}>
                      <small>{props.info.buttons[i].title}{(props.info.buttons[i].url==="")?"":":"}
                        {props.info.buttons[i].url}</small></Button>);
        }else if(props.info.buttons[i].type=="postback"){
          module.push(<Button variant="outlined" color="primary" key={"button_post"+i}>
                      <small>{props.info.buttons[i].title}{(props.info.buttons[i].enviar_a==="")?"":":"}
                        {props.info.buttons[i].enviar_a}</small></Button>);
        }
      }

    return module;
  };
  const Webservice=(props)=>{
    return(
      <ListItem>
        <ListItemIcon>
          <Avatar>
          <HttpIcon/></Avatar>
        </ListItemIcon>
        <WidgetElement disableWidgetMenu style={{width:"100%"}}
            title="Web service" bodyClass={classes.fullHeightBody} className={classes.card}>
            <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
              <Grid item xs={12}>
                    <div>
                      <TextField
                        id="url-webservice"
                        label="URL"
                        multiline
                        rowsMax={2} fullWidth value={props.info.url}
                      />
                    </div>
              </Grid>
              <Grid item xs={4}>
                    <div>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="uncontrolled-native">Método</InputLabel>
                        <NativeSelect
                          defaultValue={props.info.metodo}
                          inputProps={{name: 'periodo', id: 'perdiodo-tiempo',}}>
                          <option value={'POST'}>POST</option>
                          <option value={'GET'}>GET</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
              </Grid>
              <Grid item xs={12}>
                  <Typography>Párametros<small>(envío al servicio)</small></Typography>
              </Grid>
              <Grid item xs={12} >
                <Parametros_web param={props.info.parametros} keys={props.llave}/>
              </Grid>
              <Grid item xs={12}>
                  <Typography>Respuestas<small>(Mostrar al usuario chat)</small></Typography>
              </Grid>
              <Grid item xs={12} >
                <Respuestasweb res={props.info.respuestas.length} info={props.info} keys={props.llave}/>
              </Grid>
            </Grid>
        </WidgetElement>
      </ListItem>
    );
  }
  const Parametros_web=(props)=>{
    let module=[];
    if(props.param === undefined || props.param ===null){
      return module;
    }
    var count = Object.keys(props.param).length;
      for (var key in props.param) {
        module.push(<Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"} key={"param-"+props.keys+"-"+key}>
                    <Grid item xs={6}><TextField label="Párametro" fullWidth value={key}/></Grid><Grid item xs={6}>
                    <TextField label="Valor" fullWidth value={props.param[key]}/></Grid></Grid>);
      }

    return module;
  }
  const Respuestasweb=(props)=>{
    let module=[];
    for(let i=0;i<props.res;i++){
      if(props.info.respuestas[i].enviar_a){//bloque
        module.push(<Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"} key={"res-"+props.keys+"-"+i}>
                    <Grid item xs={4}><TextField label="Respuesta" fullWidth value={props.info.respuestas[i].valor}/></Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="uncontrolled-native">Enviar a</InputLabel>
                        <NativeSelect
                          defaultValue={props.info.respuestas[i].enviar_a} inputProps={{name: 'a_bloque', id: 'a_bloque',}}>
                          <option value={props.info.respuestas[i].enviar_a}>{props.info.respuestas[i].enviar_a}</option>
                          <option value={'default'}>default</option>
                        </NativeSelect>
                      </FormControl></Grid>
                  </Grid>);
      }else{//mensaje
        module.push(<Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"} key={"res-"+props.keys+"-"+i}>
                    <Grid item xs={4}><TextField label="Respuesta" fullWidth value={props.info.respuestas[i].valor}/></Grid>
                    <Grid item xs={6}><TextField label="Mensaje" multiline rowsMax={2} fullWidth value={props.info.respuestas[i].mensaje}/></Grid>
                  </Grid>);
      }
    }

    return module;
  };
  const Detail=(props)=>{
    return(
      <ListItem>
          <ListItemIcon>
            <Avatar>
            <AddShoppingCartIcon /></Avatar>
          </ListItemIcon>
          <WidgetElement disableWidgetMenu style={{width:"100%"}}
              title="Detalle (Orden de Compra)" bodyClass={classes.fullHeightBody} className={classes.card}>
              <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                <Grid item xs={12}>
                    <Typography>Confirmar Orden</Typography>
                </Grid>
                <Grid item xs={12}>
                      <div>
                        <TextField id="standard-multiline-flexible10" label="Mensaje" multiline
                          rowsMax={2} fullWidth value={props.info.mensaje}/>
                      </div>
                </Grid>
                <Grid item xs={12}>
                      <div>
                        <TextField id="standard-multiline-flexible10" label="Email" multiline
                          rowsMax={2} fullWidth value={props.info.notificacion}/>
                      </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Botones <small>(alineación vertical)</small></Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextBotonModule buttonum={props.info.buttons.length} info={props.info} keys={props.llave}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography><small>Aplica para Confirmar y Cancelar</small></Typography>
                </Grid>
                <Grid item xs={10}>
                      <div>
                        <TextField id="standard-multiline-flexible10" label="Mensaje de Error" multiline
                          rowsMax={2} fullWidth value={props.info.mensaje_error}/>
                      </div>
                </Grid>
                <Grid item xs={2} >
                        <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                          id="standard-multiline-flexible12"
                          label="Limite" fullWidth value={props.info.intento}
                        />
                </Grid>
                <Grid item xs={12}>
                    <Typography>Cancelar Orden</Typography>
                </Grid>
                <Grid item xs={10}>
                      <div>
                        <TextField id="standard-multiline-flexible10" label="Mensaje Cancelar" multiline
                          rowsMax={2} fullWidth value={props.info.mensaje_cancelar}/>
                      </div>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel control={<Checkbox name="checkedC" />} label="IA"
                    checked={props.info.ia}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Replies <small>(alineación horizontal)</small></Typography>
                </Grid>
                <Grid item xs={12} >
                  <QRmodules qr={props.info.quick_replies.length} info={props.info} keys={props.llave}/>
                </Grid>
              </Grid>
          </WidgetElement>
      </ListItem>
    );
  }
//show page bots blocks and elements---------------------------------------------
    return (
      <div className={classes.root} >
        <Grid>
          <AppBar position="static">
            <Toolbar variant="dense">
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <BrightnessAutoIcon />
              </IconButton>
              <Typography variant="body2" color="inherit">Asistente: {datosBd}</Typography>
            </Toolbar>
          </AppBar>
        </Grid>
        <br/>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" className={classes.title}>
                  Mis Bloques <Badge badgeContent={(numbloque)} {...badgeProps} />
                </Typography>

                <WidgetBlock disableWidgetMenu container className={classes.root}>
                  <Grid item xs={12} md={12}>
                      <Tabs orientation="vertical"
                        variant="scrollable" value={value} onChange={handleChange} aria-label="Vertical tabs example"
                        className={classes.tabs}>
                        {/*---------show block by bot--------------*/}
                        {MyTabs()}
                        </Tabs>
                  </Grid>
                </WidgetBlock>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" className={classes.title}>
                  Mis Elementos
                </Typography>
                <div className={classes.root}>
                  {/*---------show elements by block--------------*/}
                  {progeso && <React.Fragment><CircularProgress /> Loading....</React.Fragment> }
                  <ListElement/>
                </div>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </div>

    )
}

// #######################################################################

export default Bots;
