import React, { useState, useEffect } from "react";
import AssignmentRoundedIcon from "@material-ui/icons/AssignmentRounded";
import DialogElement from "../dialogElement";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { traducirDobleBackslash_A_SaltosDeLinea } from "./utilidades";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useAdminBotState } from "../../../contexto/AdminBotContext";
import { useUserState } from "../../../../../context/UserContext";
import {
  maximaCantidadElementosCarrousel,
  mensajeTextoContenidoErrores,
  mensajeTextoErrorErrores,
  mensajeTextoLongitudLimites,
  validarTextoMensajeContenidoEscrito,
  validarTextoMensajeErrorEscrito,
} from "../validaciones";
import BloqueLista from "./bloque_lista";
import { useSnackbar } from "notistack";
import { db } from "../../../../../services/firebase";
import log_it from "../../../../../services/log/log";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete: {
      color: "#ffff",
      backgroundColor: "#dc004e",
    },
    loadingChip: {
      color: "#ffff",
    },
  })
);

export function Whatsapp_Lista(props) {
  const classes = useStyles();
  var { bot_actual, bloques } = useAdminBotState();
  const { enqueueSnackbar } = useSnackbar();
  var { datos, user } = useUserState();

  const [open, setOpen] = React.useState(false);
  const [cargando, setCargando] = React.useState(false);
  const [ia, setIA] = React.useState(false);
  const [validacion, setValidacion] = React.useState("0");

  /*variables de datos*/
  const [titulo, setTitulo] = React.useState("");
  const [footer, setFooter] = React.useState("");

  const [btn_global, setBtn_global] = React.useState("");

  const [arregloSeccion, setArregloSeccion] = React.useState([]);
  const [actualizarArregloSeccion, setActualiarArregloSeccion] = React.useState(
    true
  );

  const [atributo, setAtributo] = React.useState("");
  const [intentos, setIntentos] = React.useState(0);
  const [mensaje, setMensaje] = React.useState("");
  const [mensajeError, setMensajeError] = React.useState("");

  const [contador, setContador] = React.useState(0);
  const [contador10, setContador10] = React.useState(false);

  const [
    mensajeErrorCodigoValidacion,
    setMensajeErrorCodigoValidacion,
  ] = React.useState(-1);

  const [mensajeCodigoValidacion, setMensajeCodigoValidacion] = React.useState(
    -1
  );

  const [items, setItems] = React.useState([]);
  const [enviar_a, setEnviar_a] = React.useState("");

  useEffect(() => {
    /*Guardar nuevo elemento*/
    if (props.abrir) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (bloques !== null) {
      let lista_items = [];
      bloques.forEach((element, index, array) => {
        lista_items.push(
          <MenuItem key={"item" + props.id + "_" + index} value={element}>
            {element}
          </MenuItem>
        );
      });
      setItems(lista_items);
    }
  }, [bloques]);

  /*inicializar vaiables*/
  useEffect(() => {
    if (props.datos) {
      // console.log("datos", props.datos);
      setAtributo(props?.datos?.atributo ? props?.datos?.atributo : "");
      setBtn_global(props?.datos?.btn_global ? props?.datos?.btn_global : "");
      setEnviar_a(props?.datos?.enviar_a ? props?.datos?.enviar_a : "");
      // setFooter(props?.datos?.footer ? props?.datos?.footer : "");
      setIntentos(props?.datos?.intento ? props?.datos?.intento : "");
      setMensaje(props?.datos?.mensaje ? props?.datos?.mensaje : "");
      setMensajeError(
        props?.datos?.mensaje_error ? props?.datos?.mensaje_error : ""
      );
      setTitulo(props?.datos?.titulo ? props?.datos?.titulo : "");

      let arreglo = JSON.stringify(props.datos.items);
      setArregloSeccion(JSON.parse(arreglo));
    }
  }, [open]);

  const GuardarCambios = () => {
    console.log("4", arregloSeccion);

    var temp_enviar_a = "";
    var temp_intentos = 0;

    temp_intentos = parseInt(intentos);
    if (isNaN(temp_intentos)) {
      temp_intentos = 0;
    }

    if (enviar_a != "0") {
      temp_enviar_a = enviar_a;
    }

    if (arregloSeccion.length === 0) {
      enqueueSnackbar("Debe de existir almenos una Sección", {
        variant: "error",
      });
    } else {
      let flag = false;
      arregloSeccion.forEach((item, i) => {
        if (item.options.length === 0) {
          enqueueSnackbar(
            "Debe de existir almenos una Opción por cada Sección",
            {
              variant: "error",
            }
          );
          flag = true;
          return;
        }
      });
      if (!flag) {
        var patch_arregloSeccion = arregloSeccion;
        setCargando(true);

        var lista_obj = {
          atributo: atributo,
          btn_global: btn_global,
          elemento: "lista",
          enviar_a: temp_enviar_a,
          intento: temp_intentos,
          mensaje: mensaje,
          mensaje_error: mensajeError,
          titulo: titulo,
          // footer: footer,
          items: patch_arregloSeccion,
        };

        if (props.id_elemento === -1) {
          setCargando(false);
          setOpen(false);
          enqueueSnackbar("Guardando nuevo elemento", { variant: "info" });
          props.GuardarNuevoElemento(lista_obj, props.new_index);
        } else {
          db.ref(
            "/bloques/" +
              datos.id_empresa +
              "/" +
              bot_actual.conexion +
              "/" +
              bot_actual.id +
              "/" +
              props.id_bloque +
              "/elementos/" +
              props.id_elemento
          ).set(lista_obj, function(error) {
            if (error) {
              setCargando(false);
              enqueueSnackbar("Error al guardar los datos", {
                variant: "error",
              });
            } else {
              setCargando(false);
              enqueueSnackbar("Guardado exitoso", { variant: "success" });
              setOpen(false);
              var obj = {
                fecha: +new Date(),
                nombre: "modificar elemento",
                id_accion: 132,
                id_bot: bot_actual.id,
                red_social: bot_actual.conexion,
                bloque: props.id_bloque,
                elemento: "lista",
              };
              log_it(datos.id_empresa, user.id, obj);
            }
          });
        }
      }
    }
  };

  const cambiarEnviarA = (event) => {
    if (event.target.value !== undefined) {
      setEnviar_a(event.target.value);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (props.id_elemento === -1) {
      setOpen(false);
      props.cerrarElmento();
    }
    setOpen(false);
  };

  const AgregarSeccion = () => {
    // console.log("agregarseccion", arregloSeccion)
    if (contador >= 10) {
      setContador10(true);
      return;
    }
    setContador10(false);
    setArregloSeccion((prevState) => {
      if (prevState.length <= 10) {
        prevState.push({
          options: [],
          subtitle: "",
          title: "",
        });
        return prevState;
      }
    });
    setActualiarArregloSeccion(actualizarArregloSeccion ? false : true);
  };

  const EliminarSeccion = (datos) => {
    let cont = 0;
    arregloSeccion.forEach((item, i) => {
      if (item.options) {
        item.options.forEach((item2, l) => {
          cont++;
        });
      }
    });
    // console.log("eliminar", cont);
    setContador(cont);

    setArregloSeccion((prevState) => {
      prevState.splice(datos.id, 1);
      return prevState;
    });
    setActualiarArregloSeccion(actualizarArregloSeccion ? false : true);
  };

  const updateContador = () => {
    let cont = 0;
    arregloSeccion.forEach((item, i) => {
      if (item.options) {
        item.options.forEach((item2, l) => {
          cont++;
        });
      }
    });
    // console.log("agregar opcio", cont);
    setContador(cont);

    if (cont >= 9) {
      setContador10(true);
      // return true;
    }

    setContador10(false);
    return cont;
  };

  const actualizarContador = () => {
    let cont = 0;
    arregloSeccion.forEach((item, i) => {
      if (item.options) {
        item.options.forEach((item2, l) => {
          cont++;
        });
      }
    });
    setContador(cont);

    if (cont >= 10) {
      setContador10(true);
    } else {
      setContador10(false);
    }
  };

  return (
    <React.Fragment>
      <DialogElement
        id_elemento={props.id_elemento}
        moverElementos={props.moverElementos}
        moverMasuno={props.MoverMasuno}
        moverMenosuno={props.MoverMenosuno}
        icono={<AssignmentRoundedIcon />}
        metodoAbrir={handleClickOpen}
        nombre={props.nombre}
        tamanio={props.tamanio}
        dialogOpen={open}
        dialogClose={handleClose}
        cargando={cargando}
        disabledSave={
          titulo.length === 0
          // intentos < 0 ||
          // (atributo.length > 0 && !/^[a-zA-Z0-9_-]{1,30}$/.test(atributo))
        }
        guardar={GuardarCambios}
        eliminar={props.eliminar}
        cerrar={setOpen}
      >
        <Grid item xs={12}>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                label="Titulo"
                variant="outlined"
                fullWidth
                multiline
                // disabled={datos.permisos.abcbot.bloques !== true}
                inputProps={{
                  maxLength: 60,
                }}
                helperText={
                  titulo.length >= 60
                    ? "El texto no debe ser mayor a 60 caracteres"
                    : titulo.length === 0
                    ? "Este campo es obligatorio"
                    : ""
                }
                error={titulo.length >= 60 || titulo.length === 0}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <TextField
                id="footer-lista"
                value={footer}
                onChange={(e) => setFooter(e.target.value)}
                label="Footer"
                variant="outlined"
                fullWidth
                // disabled={datos.permisos.abcbot.bloques !== true}
                helperText={
                  footer.length > 60
                    ? "El texto no debe ser mayor a 60 caracteres"
                    : footer.length === 0
                    ? "Este campo es obligatorio"
                    : ""
                }
                error={footer.length > 60 || footer.length === 0}
              />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="btn_global"
            value={btn_global}
            onChange={(e) => setBtn_global(e.target.value)}
            label="Nombre de Boton"
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="ElTexto_mensaje"
            value={mensaje}
            onChange={(e) => {
              /*Para hacerlo mas legible*/
              var codigoValidacion = validarTextoMensajeContenidoEscrito(
                e,
                e.target.value,
                "whatsapp_textoRapido"
              );
              setMensajeCodigoValidacion(codigoValidacion);
              if (codigoValidacion < 1) {
                setMensaje(e.target.value);
              }
            }}
            inputProps={{
              maxLength: mensajeTextoLongitudLimites["whatsapp_textoRapido"],
            }}
            label="Mensaje"
            variant="outlined"
            fullWidth
            multiline
            disabled={datos.permisos.abcbot.bloques !== true}
            error={mensajeCodigoValidacion != -1}
            helperText={mensajeTextoContenidoErrores(
              mensajeCodigoValidacion,
              "whatsapp_textoRapido"
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="ElTexto_mensaje"
            value={mensajeError}
            onChange={(e) => {
              var codigoValidacion = validarTextoMensajeErrorEscrito(
                e,
                e.target.value,
                "whatsapp_textoRapido",
                null
              );
              setMensajeErrorCodigoValidacion(codigoValidacion);
              if (mensajeErrorCodigoValidacion < 1) {
                setMensajeError(e.target.value);
              }
            }}
            label="Mensaje de error"
            variant="outlined"
            fullWidth
            multiline
            disabled={datos.permisos.abcbot.bloques !== true}
            inputProps={{
              maxLength: mensajeTextoLongitudLimites["whatsapp_textoRapido"],
            }}
            error={mensajeErrorCodigoValidacion != -1}
            helperText={mensajeTextoErrorErrores(
              mensajeErrorCodigoValidacion,
              "whatsapp_textoRapido"
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            id="ElTexto_atributos"
            value={atributo}
            onChange={(e) => setAtributo(e.target.value)}
            label="Atributo"
            variant="outlined"
            disabled={datos.permisos.abcbot.bloques !== true}
            fullWidth
            multiline
            inputProps={{
              maxLength: 50,
            }}
            error={
              atributo.length >= 50 ||
              (atributo.length > 0 && !/^[a-zA-Z0-9_-]{1,30}$/.test(atributo))
            }
            helperText={
              atributo.length >= 50
                ? "El texto no debe ser mayor a 50 caracteres"
                : atributo.length > 0 &&
                  !/^[a-zA-Z0-9_-]{1,30}$/.test(atributo) &&
                  "El atributo solo pude contener numeros, letras y guiones"
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="ElTexto_atributos"
            value={intentos}
            onChange={(e) => setIntentos(e.target.value)}
            variant="outlined"
            fullWidth
            label="Intentos"
            type={"number"}
            disabled={datos.permisos.abcbot.bloques !== true}
            error={intentos < 0}
            helperText={intentos < 0 ? "No puede ser negativo" : ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            disabled={datos.permisos.abcbot.bloques !== true}
          >
            <InputLabel id="select-enviar_a">Enviar a</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined-quil"
              value={enviar_a}
              onClick={cambiarEnviarA}
              label="Enviar"
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>
              {items}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={arregloSeccion.length >= maximaCantidadElementosCarrousel}
            className={classes.button}
            endIcon={<PostAddIcon />}
            onClick={AgregarSeccion}
          >
            Agregar Seccion
          </Button>
          {contador10 && (
            <Typography style={{ color: "red" }}>
              No se puede crear secciones por que ya supero las 10 opciones
              permitidas.
            </Typography>
          )}
        </Grid>

        <BloqueLista
          lista={arregloSeccion}
          editar={setArregloSeccion}
          elemento={props.id_elemento}
          bloque={props.id_bloque}
          actualizar={actualizarArregloSeccion}
          eliminar={EliminarSeccion}
          contador={updateContador}
          contador2={actualizarContador}
        />
      </DialogElement>
    </React.Fragment>
  );
}
