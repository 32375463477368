import React,{useEffect} from "react";
import jwt from "jsonwebtoken";
import {auth,authPersistencia, watchUserChanges,watchDatosUSer, db} from "../services/firebase"
import log_it from './../services/log/log';
//config
import config from "../../src/config";


var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "END_LOAD":
      return { ...state, user:action.user,datos:action.datos,authReady:true,isAuthenticated: true};
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false ,user:null,datos:[],authReady:true};
    case "LOGIN_FAILURE":
        return { ...state, isAuthenticated: false,user:null,datos:[],authReady:true};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("token"),
    user: null,
    datos: [],
    authReady:false,
  });



  useEffect(() =>{
    watchUserChanges((user) =>{
      if(user)
      {
          watchDatosUSer(user.id,(datos) =>{
            dispatch({ type: 'END_LOAD',user:user,datos:datos })
          });
      }else{
        dispatch({ type:'LOGIN_FAILURE'})
      }
  });

  },[]);


  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}


export { UserProvider, useUserState, useUserDispatch, loginUser, signOut,userForgetPassword,userNewPassword};

// ###########################################################

function inicializarDatos(dispatch)
{

}

function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  setErrordetail
) {
  setError(false);
  setIsLoading(true);


  if (!!login && !!password) {

    setError(false);
    setIsLoading(true);

    auth.signInWithEmailAndPassword(login, password).then(
        (userCredential)=>{

          localStorage.setItem('id_token', 1);
          setError(false);
          setIsLoading(false);
        //  console.log('se autentico ');
         // dispatch({ type: 'LOGIN_SUCCESS'});
         var uid = userCredential.user.uid;
         db.ref('usuarios').child(uid).get().then((snapshot)=>{
          if(snapshot.exists()){
            var empresaId = snapshot.val().id_empresa;
            var obj = {
              fecha: + new Date(),
              nombre: "iniciar sesión",
              id_accion: 1,
            }
            log_it(empresaId,uid,obj);
           }
         })
        }
      ).catch(error => {
        setErrordetail(error.message);
          //setErrordetail("error")
        console.error("Error signing in with password and email", error);

        setError(true);
        setIsLoading(false);
    });
  /*  setTimeout(() => {
      localStorage.setItem('id_token', 1)
      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })

      history.push('/app/dashboard')
    }, 2000);*/
  }else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }

/*
  // We check if app runs with backend mode
  if (!config.isBackend) {
    setTimeout(() => {
      setError(null);
      setIsLoading(false);
      receiveToken("token", dispatch);
    }, 2000);
  }*/
}


function signOut(dispatch, history) {
  console.log(auth.currentUser.uid)
  var uid = auth.currentUser.uid;
/*  db.ref('usuarios').child(uid).get().then((snapshot)=>{
    console.log(snapshot.val())
    if(snapshot.exists()){
      console.log(snapshot.val())
      var empresaId = snapshot.val().id_empresa;
      var obj = {
        fecha: + new Date(),
        nombre: "cerrar sesión",
        id_accion: 2,
      }
      log_it(empresaId,uid,obj);
      }
    })*/
    try{
        db.ref("/log_usuarios/"+localStorage.getItem('userData').id_empresa+"/"+auth.currentUser.uid+"/"+localStorage.getItem('id_firebase')).update({
          fecha_fin: + new Date(),
          id_accion_fin: 2,
          nombre_fin: "cerrar sesión"
        });
    }catch(error){
      console.log("erro en el log");
    };


  dispatch({ type: "SIGN_OUT_SUCCESS" });
  auth.signOut();
  localStorage.removeItem("id_token");
  history.push("/login");







  /*
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
  */
}


function userForgetPassword(
  login,
  history,
  setIsLoading,
  setError,
  setErrordetail,
  setSuccsesForget
  )
{
  setError(false);
  setIsLoading(true);
  setSuccsesForget(false);

  auth.sendPasswordResetEmail(login).then(function (){
    setError(false);
    setIsLoading(false);
    setSuccsesForget(true);


  }).catch(function(error){
    console.log("Erro al restablecer el password"+error);
    setError(true);
    setIsLoading(false);
    setErrordetail(error.message);
  })
}


function userNewPassword(
  password,
  setIsLoading,
  setError,
  setErrordetail,
  setSuccsesNewPassword
  )
{
  setError(false);
  setIsLoading(true);
  setSuccsesNewPassword(false);

  var user = auth.currentUser;

  user.updatePassword(password).then(function (){

    setError(false);
    setIsLoading(false);
    setSuccsesNewPassword(true);


  }).catch(function(error){
    console.log("Erro al restablecer el password"+error);
    setError(true);
    setIsLoading(false);
    setErrordetail(error.message);
  })
}


export function receiveToken(token, dispatch) {
  let user;

  // We check if app runs with backend mode
  if (config.isBackend) {
    user = jwt.decode(token).user;
    delete user.id;
  } else {
    user = {
      email: config.auth.email
    };
  }

  delete user.id;
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("theme", "default");
  dispatch({ type: "LOGIN_SUCCESS" });
}
