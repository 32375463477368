import React , { useState, useEffect } from 'react';
import {useAdminBotState} from '../contexto/AdminBotContext'
import { useUserState} from "../../../context/UserContext";
import {useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import useStyles from '../styles';
import {Grid} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Widget from '../../../components/Widget';
import Divider from '@material-ui/core/Divider';
import { Typography } from "../../../components/Wrappers";
import {db} from '../../../services/firebase';
import {Droppable, Draggable} from "react-beautiful-dnd"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

/*Elementos facebook*/
import {
        Texto,
        Imagen,
        TextoRapido,
        PlantillaBoton,
        ChatCenter,
        Carrusel,
        WebService,
        CompraWeb,
        OrdenCompra,
        WebView,
        NoElement,
    } from './elementos'
/*Elementos whatsapp*/
import {
        Whatsapp_Texto,
        Whatsapp_Menu,
        Whatsapp_WebService,
        Whatsapp_ChatCenter,
        Whatsapp_Archivo,
        Whatsapp_textoRapido,
        Whatsapp_ChatCenter2,
        Whatsapp_Lista
} from './elementos/elementos_whatsapp'
/*Elementos instagram*/
import {
  Instagram_Texto,
  Instagram_TextoRapido,
  Instagram_ChatCenter,
  Instagram_Carrusel,
  Instagram_Archivo,
  Instagram_WebService,
  Instagram_Planificador,
  Instagram_Historia,

} from './elementos/elementos_instagram'

/*Elementos webchat*/
import {
  Webchat_ChatCenter,
  WebChat_TextoRapido,
}from './elementos/elementos_webchat'

/*Elementos Telegram*/
import {
  Telegram_texto,
  Telegram_textoRapido
}from './elementos/elementos_telegram'

import log_it from './../../../services/log/log'

function ListaElementos(props){

  var classes = useStyles();
  var {bot_actual,bloque_actual,lista_bloques} =useAdminBotState();
  var { datos,user } = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  var reordenamiento = false;
  const [listaElementos,setListaElementos] = React.useState([]);
  const [cargando,setCargando] = React.useState(false);
  const [moverElementos,setMoverElementos] = React.useState(false);


    useEffect(() =>{


     if(bloque_actual!==""){

           setCargando(true);
           let lista_elementos=[];
           let tamanio=bloque_actual.elementos.length;
                bloque_actual.elementos.forEach((element,index,array) =>{



                  if (bot_actual.conexion==="telegram"){

                  if(element.elemento==="texto"){
                    lista_elementos.push(
                                               <Telegram_texto key={index}
                                                nombre={element.elemento}
                                                id_bloque={bloque_actual.nombre}
                                                id_elemento={index}
                                                datos={element}
                                                eliminar={eliminar_elemento}

                                                tamanio={tamanio}
                                                moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                                />

                                              );
                  } else if(element.elemento==="archivo"){
                       lista_elementos.push(
                                              <Imagen key={index}
                                               nombre={element.elemento}
                                               id_bloque={bloque_actual.nombre}
                                               id_elemento={index}
                                               datos={element}
                                               eliminar={eliminar_elemento}
                                               duplicar_elemento={DuplicarElemento}

                                               tamanio={tamanio}
                                              moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                           />);
                   }else if(element.elemento==="texto_boton"){
                        lista_elementos.push(
                                               <Telegram_textoRapido key={index}
                                                nombre={element.elemento}
                                                id_bloque={bloque_actual.nombre}
                                                id_elemento={index}
                                                datos={element}
                                                eliminar={eliminar_elemento}
                                                duplicar_elemento={DuplicarElemento}

                                                tamanio={tamanio}
                                               moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                            />);
                    }else{
                    lista_elementos.push(
                        <NoElement
                            key={index}
                            nombre={element.elemento}
                            id_elemento={index}
                        />
                        );

                  }

                  }else{

                     if(element.elemento==="texto"){
                       if(bot_actual.conexion=="facebook" || bot_actual.conexion=="instagram" || bot_actual.conexion=="webchat"){
                         lista_elementos.push(
                                                    <Texto key={index}
                                                     nombre={element.elemento}
                                                     id_bloque={bloque_actual.nombre}
                                                     id_elemento={index}
                                                     datos={element}
                                                     eliminar={eliminar_elemento}

                                                     tamanio={tamanio}
                                                     moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                                     />

                                                   );
                         }else if(bot_actual.conexion=="whatsapp"){
                           lista_elementos.push(<Whatsapp_Texto   key={index}
                                                       nombre={"Texto"}
                                                       id_bloque={bloque_actual.nombre}
                                                       id_elemento={index}
                                                       datos={element}
                                                       eliminar={eliminar_elemento}
                                                       moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}

                                                       tamanio={tamanio}

                         />);

                         }
                     }else if(element.elemento==="archivo"){
                         lista_elementos.push(
                                                <Imagen key={index}
                                                 nombre={element.elemento}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                             />);
                     }else if(element.elemento==="texto_rapido" && bot_actual.conexion==="webchat"){
                         lista_elementos.push(
                           <WebChat_TextoRapido key={index}
                                                 nombre={"Texto rapido"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                             />);
                     }else if(element.elemento==="texto_rapido" && (bot_actual.conexion==="facebook" || bot_actual.conexion==="instagram")){
                         lista_elementos.push(
                           <TextoRapido key={index}
                                                 nombre={"Texto rapido"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                             />);

                     }else if(element.elemento==="texto_rapido" && bot_actual.conexion==="whatsapp"){
                         lista_elementos.push(
                           <Whatsapp_textoRapido key={index}
                                                 nombre={"Texto rapido"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                             />);

                     }else if(element.elemento==="texto_boton"){
                         lista_elementos.push(<PlantillaBoton key={index}
                                                 nombre={"Platilla con boton"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                 moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                         />);

                     }else if(bot_actual.conexion==="whatsapp" && element.elemento==="chatcenter2"){
                       lista_elementos.push(<Whatsapp_ChatCenter2 key={index}
                         nombre={element.elemento}
                         id_bloque={bloque_actual.nombre}
                         id_elemento={index}
                         datos={element}
                         eliminar={eliminar_elemento}
                         duplicar_elemento={DuplicarElemento}

                         tamanio={tamanio}
                         moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                       />);
                     }else if(element.elemento==="chatcenter"){

                           if(bot_actual.conexion=="facebook" || bot_actual.conexion=="instagram"){
                             lista_elementos.push(<ChatCenter key={index}
                               nombre={element.elemento}
                               id_bloque={bloque_actual.nombre}
                               id_elemento={index}
                               datos={element}
                               eliminar={eliminar_elemento}
                               duplicar_elemento={DuplicarElemento}

                               tamanio={tamanio}
                               moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                             />);
                           }else if(bot_actual.conexion==="whatsapp"){
                             lista_elementos.push(<Whatsapp_ChatCenter key={index}
                               nombre={element.elemento}
                               id_bloque={bloque_actual.nombre}
                               id_elemento={index}
                               datos={element}
                               eliminar={eliminar_elemento}
                               duplicar_elemento={DuplicarElemento}

                               tamanio={tamanio}
                               moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                             />);
                           }else if(bot_actual.conexion==="webchat"){
                             lista_elementos.push(<Webchat_ChatCenter key={index}
                               nombre={element.elemento}
                               id_bloque={bloque_actual.nombre}
                               id_elemento={index}
                               datos={element}
                               eliminar={eliminar_elemento}
                               duplicar_elemento={DuplicarElemento}

                               tamanio={tamanio}
                               moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                             />);
                           }

                     }else if(element.elemento==="carrusel"){
                         lista_elementos.push(<Carrusel key={index}
                                                 nombre={element.elemento}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                 moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                         />);

                     }
                     else if(element.elemento==="webservice"){
                         lista_elementos.push(<WebService key={index}
                                                 nombre={element.elemento}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                 moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                         />);
                     }else if(element.elemento==="webview_detalle"){
                         lista_elementos.push(<CompraWeb key={index}
                                                 nombre={"Detalle"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                         />);
                     }else if(element.elemento==="webview_showpage"){
                         lista_elementos.push(<WebView key={index}
                                                 nombre={"Webview"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                 moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                         />);
                     }else if(element.elemento==="menu"){
                                                 lista_elementos.push(<Whatsapp_Menu key={index}
                                                 nombre={"Menu"}
                                                 id_bloque={bloque_actual.nombre}
                                                 id_elemento={index}
                                                 datos={element}
                                                 eliminar={eliminar_elemento}
                                                 duplicar_elemento={DuplicarElemento}

                                                 tamanio={tamanio}
                                                 moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                                                 />);
                     }else if(element.elemento=="webservice"){
                       lista_elementos.push(<Whatsapp_WebService key={index}
                         nombre={"webservice"}
                         id_bloque={bloque_actual.nombre}
                         id_elemento={index}
                         datos={element}
                         eliminar={eliminar_elemento}
                         duplicar_elemento={DuplicarElemento}

                         tamanio={tamanio}
                        moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                         />);

                     }else if(element.elemento==="lista" && bot_actual.conexion==="whatsapp"){
                         lista_elementos.push(
                             <Whatsapp_Lista key={index}
                                                   nombre={"Lista"}
                                                   id_bloque={bloque_actual.nombre}
                                                   id_elemento={index}
                                                   datos={element}
                                                   eliminar={eliminar_elemento}
                                                   duplicar_elemento={DuplicarElemento}

                                                   tamanio={tamanio}
                                                   moverElementos={ {duplicar:DuplicarElemento,duplicarEn:DuplicarElementoEn}}
                             />);


                     }else{
                         lista_elementos.push(
                             <NoElement
                                 key={index}
                                 nombre={element.elemento}
                                 id_elemento={index}
                             />
                             );
                     }
                   }

                 });

                 setListaElementos(lista_elementos);
                 setCargando(false);

      }
    },[bloque_actual,lista_bloques]);

    const eliminar_elemento=(id_elemento)=>{

      let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+bloque_actual.nombre+"/elementos";

      let nuevo_arreglo= JSON.parse(JSON.stringify(bloque_actual.elementos));
      
      nuevo_arreglo.splice(id_elemento,1);
      db.ref(ref).set(nuevo_arreglo
          ,function(error) {
              if (error) {

                  enqueueSnackbar('Error al eleminar el elemento', {variant: 'error'});
              }else{
                  enqueueSnackbar('Eliminado correctamente', {variant: 'success'});
                  //console.log(bloque_actual.elementos[id_elemento])
                  //console.log(bloque_actual)
                  //console.log(id_elemento)
                  var obj = {
                    fecha: + new Date(),
                    nombre: "eliminar elemento",
                    id_accion: 133,
                    id_bot: bot_actual.id,
                    red_social: bot_actual.conexion,
                    bloque:bloque_actual.nombre,
                    elemento:bloque_actual.elementos[id_elemento].elemento,
                  }
                  log_it(datos.id_empresa,user.id,obj)
              }
      });

    }




    const DuplicarElemento = (id_elemento) =>{

      let elementos_actuales=bloque_actual.elementos;
      elementos_actuales.push(bloque_actual.elementos[id_elemento]);

      let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+bloque_actual.nombre+"/elementos";
      db.ref(ref)
      .set(elementos_actuales,function(error) {
          if (error) {
              enqueueSnackbar('Error al movel el elemento', {variant: 'error'});
          }

      });

    }

    const DuplicarElementoEn = (id_elemento,bloque_destino,setOpen,setCargarDuplicar) =>{

      new Promise((resolve) => {
      let elementos_actuales=JSON.parse(JSON.stringify(bloque_actual.elementos[id_elemento]));
      if(bloque_destino.info.elementos==undefined){
        bloque_destino.info.elementos=[];
      }
      let elementos_destino= bloque_destino.info.elementos.slice();
      elementos_destino.push(elementos_actuales);

      let ref="/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+bloque_destino.id+"/elementos";
      db.ref(ref)
      .set(elementos_destino,function(error) {
          if (error) {
              enqueueSnackbar('Error al movel el elemento', {variant: 'error'});
          }
          setOpen(false);
          setCargarDuplicar(false);
          resolve();
      });
    });

    }

    const getListStyle = isDraggingOver => ({
      background: isDraggingOver ? "#f5f9fa" : "",
    });

    const getListStyleArrastrar = isDraggingOver => ({
      display: isDraggingOver ? "block" : "none",
    });

    return(

        <Grid item xs={12} className={classes.boxBloque}>
        <Box justifyContent="center">


                      <Grid spacing={2} container>
                          <Grid item xs={12}>
                            <div className={classes.bloqueElementos}>
                                <Typography variant="h3" >
                                   { bloque_actual===""?"": bloque_actual.nombre==="default"?"Default": bloque_actual.nombre }
                                </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                              <Divider />
                          </Grid>

                          <Grid item xs={12}>
                              <Typography variant="h5" >
                                  Elementos
                              </Typography>
                              <div >
                                <Droppable droppableId="Drop_Elementos_actuales"  >
                                {
                                    (droppableProvided,snapshot)=>{
                                      return(
                                        (
                                          <List component="nav"
                                                  aria-label="main mailbox folders"
                                                  {...droppableProvided.droppableProps}
                                                  ref={droppableProvided.innerRef}
                                                  className={classes.listaElementos}
                                                  style={getListStyle(snapshot.isDraggingOver)}
                                                  >
                                                  <div>
    
                                                  {listaElementos.length===0 && <ListItem alignItems="flex-start"   >
                                                     <ListItemText
                                                       primary={<h3>Arrastra aqui el elemento</h3>}
                                                     />
                                                    </ListItem>}
    
                                                {listaElementos}
                                                {droppableProvided.placeholder}
                                                {props.nuevoElemento}
                                                </div>
                                          </List>
                                        )

                                      )


                                    }

                                }
                                </Droppable>
                              </div>
                          </Grid>
                      </Grid>

        </Box>
        </Grid>

    );

}

export default ListaElementos;
