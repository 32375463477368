
export const mensajes_chat='https://bots.automatadg.com/servicio/conexion';

export const mensaje_whatsapp='https://bots.automatadg.com/whatsapp/conexion';
export const mensaje_facebook='https://bots.automatadg.com/conexion/webhook';
export const mensaje_telegram="https://bots.automatadg.com/telegram/conexion";
export const mensaje_instagram='https://bots.automatadg.com/instagram/conexion';
export const mensaje_webchat='https://bots.automatadg.com/webchat/webhook';

/*
export const mensajes_chat='https://dev.automatadg.com/servicio/conexion';
export const mensaje_whatsapp='https://dev.automatadg.com/whatsapp/conexion';
export const mensaje_facebook='https://dev.automatadg.com/conexion/webhook';
export const mensaje_telegram="https://dev.automatadg.com/telegram/conexion"
export const mensaje_instagram='https://dev.automatadg.com/instagram/conexion';
export const mensaje_webchat='https://dev.automatadg.com/webchat/webhook';
*/
