import React, { useState ,useEffect,Component} from 'react'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {
    Grid,
    Card,
    CardHeader,
    Table,
    Chip,
    NativeSelect,
    Switch,
    Container,
    Box,
    Badge,
    TableRow,
    TableCell,
    Checkbox,
    TableHead,
    TableSortLabel,
    Toolbar,
    IconButton,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    TextField,
    RadioGroup,
    Tabs,
    Tab,
    Menu,
    Select,
    Paper,
    Stepper,
    Step,
    StepLabel
} from '@material-ui/core'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import MobileStepper from '@material-ui/core/MobileStepper';
import AppBar from '@material-ui/core/AppBar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
//-------------------------------------------------------------
import { useTheme, makeStyles } from '@material-ui/styles'
import { useThemeDispatch } from "../../context/ThemeContext"
import {Button,  Typography, Link } from '../../components/Wrappers'
import PropTypes from 'prop-types'
import cn from 'classnames'

// styles
import useStyles from './styles'
import Themes from "../../themes"
import { lighten } from '@material-ui/core/styles'

//Images
import original from '../../images/original.png'

// components
import WidgetBlock from '../../components/WidgetBlock'
import WidgetElement from '../../components/WidgetElement'
//import TextElement from './elements/TextElement/TextElement'

// database
import {db} from "../../services/firebase";

//icons-------------------------
import SendIcon from '@material-ui/icons/Send';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import FaceIcon from '@material-ui/icons/Face';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import ReplyIcon from '@material-ui/icons/Reply';
import ChatIcon from '@material-ui/icons/Chat';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
//----table target
import {
  Grid as Griddev,
  Table as Tabledev,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from '@devexpress/dx-react-grid-material-ui';
import { EditingState } from '@devexpress/dx-react-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import ButtonGroup from '@material-ui/core/ButtonGroup';
//---------------Table historial/error---------
import MUIDataTable from "mui-datatables";

//setting of tabs----------------------------
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}
//***************************************************************
//start function main bots
const useStyles1 = makeStyles((theme) => ({
  contenedor:{
    backgroundColor: theme.palette.background.paper,
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tabc: {
   flexGrow: 1,
   backgroundColor: theme.palette.background.paper,
   display: 'flex',
   height: 224,
   width:50,
 },
 root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
//********************************************************
const stylesTab = theme => ({
  lookupEditCell: {
    padding: theme.spacing(1)
  },
  dialog: {
    width: "calc(100% - 16px)"
  },
  inputRoot: {
    width: "100%"
  },
  selectMenu: {
    position: "absolute !important"
  }
});
// buttons in the table target------------------------
const AddButton = ({ onExecute }) => (
  <div style={{ textAlign: "center" }}>
    <IconButton color="primary" onClick={onExecute} title="Create new row">
      <AddCircleIcon/>
    </IconButton>
  </div>
);
const EditButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon />
  </IconButton>
);
const DeleteButton = ({ onExecute }) => (
  <IconButton
    onClick={() => {
      // eslint-disable-next-line
      if (window.confirm("Are you sure you want to delete this row?")) {
        onExecute();
      }
    }}
    title="Delete row"
  >
    <DeleteIcon />
  </IconButton>
);
const CommitButton = ({ onExecute }) => (
  <IconButton onClick={onExecute} title="Save changes">
    <SaveIcon />
  </IconButton>
);
const CancelButton = ({ onExecute }) => (
  <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
    <CancelIcon />
  </IconButton>
);
const commandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton
};
const Command = ({ id, onExecute }) => {
  const CommandButton = commandComponents[id];
  return <CommandButton onExecute={onExecute} />;
};

export const global = {
  atributo: [
    "Nombre",
    "Apellido",
    "Departamento",
    "Municipio",
    "Correo",
    "Salario"
  ],
  formato: [
    "Texto",
    "Numero",
    "Fecha",
    "Moneda",
  ],

  comparar: ["Igual a", "No Igual a", "Mayor a", "Menor a"]
};

const availableValues = {
  atributo: global.atributo,
  formato: global.comparar,
  tipo: global.formato
};
//-------------fields to data table target----------------
const LookupEditCellBase = ({
  availableColumnValues,
  value,
  onValueChange,
  classes
}) => (
  <TableCell className={classes.lookupEditCell}>
    <Select
      value={value}
      onChange={event => onValueChange(event.target.value)}
      MenuProps={{
        className: classes.selectMenu
      }}
      input={<Input classes={{ root: classes.inputRoot }} />}
    >
      {availableColumnValues.map(item => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  </TableCell>
);
export const LookupEditCell = withStyles(stylesTab, {
  name: "ControlledModeDemo"
})(LookupEditCellBase);

const EditCell = props => {
  const { column } = props;
  const availableColumnValues = availableValues[column.name];
  if (availableColumnValues) {
    return (
      <LookupEditCell
        {...props}
        availableColumnValues={availableColumnValues}
      />
    );
  }
  return <TableEditRow.Cell {...props} />;
};

//---data table target----
const tutorialSteps = [
  {
    id:1,
    atributo: "Nombre",
    tipo: "Texto",
    formato: "No igual a",
    valor: "Ariel",
  },
  {
    id:2,
    atributo: "Departamento",
    formato: "Igual a",
    tipo: "Texto",
    valor: "Guatemala",
  },
  {
    id:3,
    atributo: "Nacimiento",
    formato: "Mayor a",
    tipo: "Fecha",
    valor: "01/01/1991",
  },
  {
    id:4,
    atributo: "Salario",
    formato: "Menor a",
    tipo: "Numero",
    valor: "5000",
  },
];
//--data table historial--
const datatableData = [
  ["2020-05-01 15:12:57","exitoso","100","Ariel","Hola feliz dia :D","1 Dias"],
  ["2020-06-15 11:26:25","exitoso","150","Ariel","default","10 Dias"],
  ["2020-06-15 11:45:17","exitoso","150","Alice","Feliz cumpleaños, te desea Alice!","365 Dias"],
  ["2020-06-12 12:47:46","exitoso","150","Alice","Promocion especial del dia del Padre, obten el 30% de descuento en la compra del segundo articulo","Inmediatamente"],
  ["2020-06-12 11:50:35","fallido","0","Alice","Gustos1","3 Horas"],
  ["2020-05-01 15:12:57","exitoso","100","Ariel","Hola feliz dia :D","24 Horas"],
];
const datatableError = [
  ["2020-05-01 15:12:57","Ariel","Hola feliz dia :D","1 Dias","123456789102\n5214785421463","{error:'error de fb'}"],
  ["2020-06-15 11:26:25","Ariel","default","10 Dias","123456789102","{error:'error de fb'}"],
  ["2020-06-15 11:45:17","Alice","Feliz cumpleaños, te desea Alice!","365 Dias","123456789102","{error:'error de fb'}"],
  ["2020-06-12 12:47:46","Alice","Promocion especial del dia del Padre, obten el 30% de descuento en la compra del segundo articulo","Inmediatamente","123456789102","{error:'error de fb'}"],
  ["2020-06-12 11:50:35","Ariel","Gustos1","3 Horas","123456789102","{error:'error de fb'}"],
];
//************************************************************
function Secuencia(props) {
    var classes = useStyles();
    var classes1 = useStyles1();
    var theme = useTheme()
    //control of carousel------
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
      setActiveStep(0);
    };
    const [state, setState] = React.useState({
      status: true,
    });
    const handleChangeStatus = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
    };
  //------------------------------------------------------
  const [tipemsg, setTipemsg] = React.useState('Mensaje');

  const tipeanswer = (event) => {
    setTipemsg(event.target.value);
  };

    // vars to tabs--------------------------------------------
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [valuex, setValuex] = React.useState(0);
    const [datosBd, setDatosBd] = React.useState('');
    /*Obtener datos de la base de datos*/

    useEffect(() =>{
     // console.log('bot ',props.match.params.id)

        if(localStorage.getItem('userData'))
        {
          //get name bot
            let datosUsusario=JSON.parse(localStorage.getItem('userData'));
            db.ref('usuario_bot/'+datosUsusario.id_empresa+"/bots/"+props.match.params.id+"/informacion/nombre").on(
                'value',snapshot => {
                    var datos=snapshot.val();
                    setDatosBd(datos);
            });
          }
        },[]);

// event to numer block badge
  const badgeProps = {
    color: 'primary',
    children: <BookmarksIcon />,
  };
// event to tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
//---------------------------------------------
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

//-------target--------------------------
const [columns] = useState([
  { name: "atributo", title: "Atributo" },
  { name: "tipo", title: "Tipo" },
  { name: "formato", title: "Formato" },
  { name: "valor", title: "Valor" },
  ]);
const [rows, setRows] = useState(tutorialSteps);
const [sorting, getSorting] = useState([]);
const deleteRows = deletedIds => {
  const rowsForDelete = rows.slice();
  deletedIds.forEach(rowId => {
    const index = rowsForDelete.findIndex(row => row.id === rowId);
    if (index > -1) {
      rowsForDelete.splice(index, 1);
    }
  });
  return rowsForDelete;
};
const commitChanges = ({ added, changed, deleted }) => {
  let changedRows;
  if (added) {
    const startingAddedId =
      rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row
      }))
    ];
  }
  if (changed) {
    changedRows = rows.map(row =>
      changed[row.id] ? { ...row, ...changed[row.id] } : row
    );
  }
  if (deleted) {
    changedRows = deleteRows(deleted);
  }
  setRows(changedRows);
};

//show page bots blocks and elements---------------------------------------------
    return (
      <div className={classes.root} >

        <br/>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" className={classes.title}>
                  Secuencias <Badge badgeContent={(2)} {...badgeProps} />
                </Typography>
                <WidgetBlock container className={classes.root}>
                  <Grid item xs={12} md={12}>
                    <Grid container direction="row" justify="center" alignItems="center">

                    </Grid>

                      <Tabs orientation="vertical"
                        variant="scrollable" value={value} onChange={handleChange} aria-label="Vertical tabs example"
                        className={classes.tabs}>
                        {/*---------------create sequence----------------------*/}
                        <Tab component="div" label={
                          <Grid container justify={"space-between"}  alignItems={"flex-start"}>
                              <Grid item xs={10} container direction="row" justify="flex-start"alignItems="flex-start">
                                <Button size="small" color="primary" startIcon={<AddCircleIcon/>}>
                                  Nuevo
                                </Button>
                              </Grid>
                                <Menu id="long-menu" anchorEl={anchorEl} keepMounted
                                  open={open} >
                                  <MenuItem>
                                    <Typography>
                                      more
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                            </Grid>}{...a11yProps(0)}/>
                        {/*---------show block by bot--------------*/}
                        <Tab component="div" label={
                          <Grid container justify={"space-between"}  alignItems={"flex-start"}>
                              <Grid item xs={10} container direction="row" justify="flex-start"alignItems="flex-start">
                                <Link component="button" variant="body2"><strong>Secuencia 2</strong></Link>
                              </Grid>
                                <Menu id="long-menu" anchorEl={anchorEl} keepMounted
                                  open={open} >
                                  <MenuItem>
                                    <Typography>
                                      more
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                            </Grid>}{...a11yProps(1)}/>
                        <Tab component="div" label={
                              <Grid container justify={"space-between"}  alignItems={"flex-start"}>
                                  <Grid item xs={10} container direction="row" justify="flex-start"alignItems="flex-start">
                                    <Link component="button" variant="body2"><strong>Secuencia 1</strong></Link>
                                  </Grid>
                                    <Menu id="long-menu" anchorEl={anchorEl} keepMounted
                                      open={open} >
                                      <MenuItem>
                                        <Typography>
                                          more
                                        </Typography>
                                      </MenuItem>
                                    </Menu>
                                </Grid>}{...a11yProps(2)}/>
                      </Tabs>
                  </Grid>
                </WidgetBlock>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>

                <div className={classes.root}>
                  {/*---------create sequence--------------*/}
                  <TabPanel value={value} index={0} className={classes1.contenedor}>
                    <Grid container spacing={1}>
                      <Grid item xs={1}>
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        <AlarmOnIcon/>
                      </Avatar>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="h5" gutterBottom>Nueva Secuencia</Typography>
                    </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs={7}>
                          <Container fixed >
                            <TextField id="alias" label="Nombre" InputLabelProps={{shrink: true,}}
                              fullWidth/>
                        </Container>
                        </Grid>
                        <Grid item xs={2}>
                          <Container fixed>
                            <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                              id="tiempo" InputLabelProps={{shrink: true,}}
                              label="Tiempo" fullWidth />
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container fixed >
                            <FormControl className={classes.formControl} fullWidth>
                              <InputLabel htmlFor="uncontrolled-native">Periodo </InputLabel>
                              <NativeSelect
                                defaultValue={'Dias'}
                                inputProps={{name: 'periodo', id: 'perdiodo-tiempo',}}>
                                <option value={'Horas'}>Horas</option>
                                <option value={'Dias'}>Días</option>
                                <option value={'Inmediatamente'}>Inmediatamente</option>
                              </NativeSelect>
                            </FormControl>
                          </Container>
                        </Grid>
                        <Grid item xs={6}>
                          <Container fixed >
                            <FormControl className={classes.formControl} fullWidth>
                              <InputLabel htmlFor="uncontrolled-native">Bot </InputLabel>
                              <NativeSelect
                                defaultValue={'Ariel'}
                                inputProps={{name: 'bot', id: 'uncontrolled-native',}}>
                                <option value={'Ariel'}>Ariel</option>
                                <option value={'Alice'}>Alice</option>
                              </NativeSelect>
                            </FormControl>
                        </Container>
                        </Grid>
                        <Grid item xs={6}>
                          <Container fixed >
                            <FormControlLabel label="Estado" control={
                                <Switch checked={state.status} onChange={handleChangeStatus}
                                  name="status" color="primary" /> }
                            />
                          </Container>
                        </Grid>
                        <Grid item xs={6}>
                          <Container fixed >
                            <FormControl className={classes.formControl} fullWidth>
                              <InputLabel htmlFor="uncontrolled-native">Tipo Respuesta </InputLabel>
                              <NativeSelect
                                defaultValue={'Mensaje'} onChange={tipeanswer}
                                inputProps={{name: 'respuesta', id: 'respuesta-tipo',}}>
                                <option value={'Mensaje'}>Mensaje</option>
                                <option value={'Bloque'}>Bloque</option>
                              </NativeSelect>
                            </FormControl>
                          </Container>
                        </Grid>
                        <Grid item xs={6}>
                          <Container fixed >
                            {tipemsg=='Mensaje' ? (
                              <TextField id="alias" multiline rows={1} label="Mensaje" InputLabelProps={{shrink: true,}}
                                fullWidth/>
                              ) : (
                                <FormControl className={classes.formControl} fullWidth>
                                  <InputLabel htmlFor="uncontrolled-native">Bloque </InputLabel>
                                  <NativeSelect
                                    defaultValue={'default'}
                                    inputProps={{name: 'bloque', id: 'respuesta-bloque',}}>
                                    <option value={'default'}>default</option>
                                    <option value={'Salir'}>Salir</option>
                                  </NativeSelect>
                                </FormControl>
                              )}
                          </Container>
                        </Grid>
                      </Grid>
                      <br/><br/>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container spacing={4}>

                            <Grid item xs={6}>
                              <Container fixed >
                              <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="uncontrolled-native">Condición </InputLabel>
                                <NativeSelect
                                  defaultValue={'Or'}
                                  inputProps={{name: 'condicion', id: 'condiciontaget',}}>
                                  <option value={'Or'}>Cumplir cualquier condición</option>
                                  <option value={'And'}>Cumplir todas las condiciones</option>
                                </NativeSelect>
                              </FormControl>
                            </Container>
                            </Grid>
                            <Grid item xs={6}>
                              <Container fixed >
                              <ButtonGroup disableElevation variant="outlined" color="primary">
                                <Button startIcon={<SaveIcon />}>Guardar</Button>
                                <Button startIcon={<SendIcon />}>Guardar y Enviar</Button>
                              </ButtonGroup>
                            </Container>
                            </Grid>
                            <Grid item xs={12} >
                              <Paper className={classes.root}>
                                <Griddev rows={rows} columns={columns}>
                                  <EditingState onCommitChanges={commitChanges}/>
                                  <Tabledev />
                                  <TableHeaderRow />
                                  <TableEditRow cellComponent={EditCell} />
                                    <TableEditColumn
                                    showAddCommand
                                    showEditCommand
                                    showDeleteCommand
                                    commandComponent={Command} />
                                </Griddev>
                              </Paper>
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                  </TabPanel>
                  {/*---------show sequence--------------*/}
                  <TabPanel value={value} index={1} className={classes1.contenedor}>
                      <Grid container spacing={1}>
                          <Grid item xs={7}>
                            <Container fixed >
                              <TextField id="alias" label="Nombre" InputLabelProps={{shrink: true,}}
                                fullWidth/>
                          </Container>
                          </Grid>
                          <Grid item xs={2}>
                            <Container fixed>
                              <TextField type="number" inputProps={{ min: "1", max: "1000", step: "1" }}
                                id="tiempo" InputLabelProps={{shrink: true,}}
                                label="Tiempo" fullWidth value={1}/>
                            </Container>
                          </Grid>
                          <Grid item xs={3}>
                            <Container fixed >
                              <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="uncontrolled-native">Periodo </InputLabel>
                                <NativeSelect
                                  defaultValue={'Dias'}
                                  inputProps={{name: 'periodo', id: 'perdiodo-tiempo',}}>
                                  <option value={'Horas'}>Horas</option>
                                  <option value={'Dias'}>Días</option>
                                  <option value={'Inmediatamente'}>Inmediatamente</option>
                                </NativeSelect>
                              </FormControl>
                            </Container>
                          </Grid>

                          <Grid item xs={6}>
                            <Container fixed >
                              <TextField
                                id="datetime-local"
                                label="Creación"
                                type="datetime-local"
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Container>
                          </Grid>
                          <Grid item xs={6}>
                            <Container fixed >
                              <FormControlLabel label="Estado" control={
                                  <Switch checked={state.status} onChange={handleChangeStatus}
                                    name="status" color="primary" /> }
                              />
                            </Container>
                          </Grid>
                          <Grid item xs={6}>
                            <Container fixed >
                              <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="uncontrolled-native">Bot </InputLabel>
                                <NativeSelect
                                  defaultValue={'Ariel'}
                                  inputProps={{name: 'bot', id: 'uncontrolled-native',}}>
                                  <option value={'Ariel'}>Ariel</option>
                                  <option value={'Alice'}>Alice</option>
                                </NativeSelect>
                              </FormControl>
                          </Container>
                          </Grid>
                          <Grid item xs={6}>
                            <Container fixed >
                                <InputLabel htmlFor="uncontrolled-native">Usuarios Afectados: </InputLabel>
                                <Chip icon={<FaceIcon />} label="250" color="primary" size="small"/>
                            </Container>
                          </Grid>
                          <Grid item xs={6}>
                            <Container fixed >
                              <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="uncontrolled-native">Tipo Respuesta </InputLabel>
                                <NativeSelect
                                  defaultValue={'Mensaje'} onChange={tipeanswer}
                                  inputProps={{name: 'respuesta', id: 'respuesta-tipo',}}>
                                  <option value={'Mensaje'}>Mensaje</option>
                                  <option value={'Bloque'}>Bloque</option>
                                </NativeSelect>
                              </FormControl>
                            </Container>
                          </Grid>
                          <Grid item xs={6}>
                            <Container fixed >
                              {tipemsg=='Mensaje' ? (
                                <TextField id="alias" multiline rows={1} label="Mensaje" InputLabelProps={{shrink: true,}}
                                  fullWidth/>
                                ) : (
                                  <FormControl className={classes.formControl} fullWidth>
                                    <InputLabel htmlFor="uncontrolled-native">Bloque </InputLabel>
                                    <NativeSelect
                                      defaultValue={'default'}
                                      inputProps={{name: 'bloque', id: 'respuesta-bloque',}}>
                                      <option value={'default'}>default</option>
                                      <option value={'Salir'}>Salir</option>
                                    </NativeSelect>
                                  </FormControl>
                                )}
                            </Container>
                          </Grid>
                          <Grid item xs={6}></Grid>
                          <Grid item xs={6} justify="flex-end">
                            <Container fixed>
                              <ButtonGroup disableElevation variant="outlined" color="primary">
                                <Button startIcon={<SendIcon />}>Enviar</Button>
                                <Button startIcon={<HighlightOffIcon />}>Detener</Button>
                              </ButtonGroup>
                            </Container>
                          </Grid>
                        </Grid>
                        <br/><br/>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"
                                    id="panel1a-header">
                                <Typography className={classes.heading}>Público objetivo</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={1}>
                                  <Grid item xs={5}>
                                    <FormControl className={classes.formControl} fullWidth>
                                      <InputLabel htmlFor="uncontrolled-native">Condición </InputLabel>
                                      <NativeSelect
                                        defaultValue={'Or'}
                                        inputProps={{name: 'condicion', id: 'condiciontaget',}}>
                                        <option value={'Or'}>Cumplir cualquier condición</option>
                                        <option value={'And'}>Cumplir todas las condiciones</option>
                                      </NativeSelect>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} >
                                    <Paper className={classes.root}>
                                      <Griddev rows={rows} columns={columns}>
                                        <EditingState onCommitChanges={commitChanges}/>
                                        <Tabledev />
                                        <TableHeaderRow />
                                        <TableEditRow cellComponent={EditCell} />
                                          <TableEditColumn
                                          showAddCommand
                                          showEditCommand
                                          showDeleteCommand
                                          commandComponent={Command} />
                                      </Griddev>
                                    </Paper>
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"
                                    id="panel1a-header">
                                <Typography className={classes.heading}>Historial</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <MUIDataTable
                                        title="Log Secuenias"
                                        data={datatableData}
                                        columns={["Fecha","Estado", "Alcance", "Bot","Respuesta","Periodo"]}
                                        options={{
                                            selectableRows: "none",
                                        }} />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content"
                                    id="panel1a-header">
                                <Typography className={classes.heading}>Errores </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <MUIDataTable
                                        title="Errores en Secuenias"
                                        data={datatableError}
                                        columns={["Fecha", "Bot","Respuesta","Periodo","FBID","Detalle"]}
                                        options={{
                                            selectableRows: "none",
                                        }} />
                                  </Grid>
                                </Grid>
                              </AccordionDetails>
                            </Accordion>

                          </Grid>
                        </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2} className={classes1.contenedor}>
                      Informacion de otra secuencia
                  </TabPanel>
                </div>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      </div>

    )
}

// #######################################################################

export default Secuencia;
