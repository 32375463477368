import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Typography } from "../../../../components/Wrappers";
import AddBoxIcon from '@material-ui/icons/AddBox';

import QuickReplies from "./quick_replies"
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {    
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",
        
    }
  }),
);

export function OrdenCompra(props){

    const classes = useStyles();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);


    /*variables de datos*/
    const [mensaje,setMensaje]=React.useState("")
    const [ia,setIA]=React.useState(true);
    const [intentos,setIntentos]=React.useState("0");
    const [mensajeError,setMensajeError]=React.useState("");
    const [atributos,setAtributos]= React.useState("");
    const [listaReplies,setListaReplies]= React.useState([]); 

    const [nuevoRepliesNombre,setNuevoRepliesNombre] = React.useState("");
    const [nuevoRepliesEnviar,setNuevoRepliesEnviar] = React.useState("");
    /*inicializar vaiables*/
    useEffect(() =>{       
      
   
        if(props.datos)
        {        
            setMensaje(props.datos.mensaje);
            setIA(props.datos.ia);
            setIntentos(props.datos.intento);
            setMensajeError(props.datos.mensaje_error);
            setAtributos(props.datos.atributo);

          let lista=[]
            props.datos.quick_replies.forEach((element, index, array) =>{
                lista.push(
                <QuickReplies key={"replies_"+index}
                    title={element.title}
                    enviar_a={element.enviar_a}
                    id={index}
                />              
                );

            });
            setListaReplies(lista);
        }
        
      
      },[]); 
    
    const cambiarIA=(event)=>{        
        setIA(event.target.checked)
    }  


    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const [age, setAge] = React.useState('');

  

    return(
        <React.Fragment>
            <Chip     
                icon={<ShoppingCartIcon/>}
                label={props.nombre}                
                color="secondary"
                clickable
                onClick={handleClickOpen}
                />

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                <DialogTitle id="form-dialog-title">Elemento: Orden de compra <ShoppingCartIcon/> </DialogTitle>               
                <DialogContent dividers>
                    <Grid spacing={3} container >    
                            
                        <Grid item xs={12}>
                        
                            <Button
                                variant="contained"                        
                                className={classes.colorDelete}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>

                        </Grid>

                        <Grid item xs={12}>
                            <TextField id="ElTexto_mensaje" value={mensaje} onChange={e => setMensaje(e.target.value)} label="Mensaje" variant="outlined"  fullWidth />
                        </Grid>


                        <Grid item xs={12}>
                            <Grid spacing={2} container >                            
                                        
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch color="primary" />}
                                        label="IA"
                                        labelPlacement="top"
                                        checked={ia}
                                        onChange={cambiarIA}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField id="outlined-basic" value={intentos} onChange={e => setIntentos(e.target.value)}  label="Intentos" type={"number"} variant="outlined"  fullWidth />
                                </Grid>                           
                            </Grid>  
                        </Grid> 

                        <Grid item xs={12}>                    
                            <Grid spacing={2} container > 
                                <Grid item xs={12} sm={6}>
                                    <TextField id="outlined-basic" value={mensajeError} onChange={e => setMensajeError(e.target.value)} label="Mensaje de error" variant="outlined"  fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField id="outlined-basic" value={atributos} onChange={e => setAtributos(e.target.value)} label="Atributos" variant="outlined"  fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12}>                    
                            <Typography>Replies</Typography>
                        </Grid>

                        <Grid item xs={12}>                                        
                            <Grid spacing={2} container > 
                                <Grid item xs={12} sm={5}>
                                    <TextField id="outlined-basic" value={nuevoRepliesNombre} onChange={e => setNuevoRepliesNombre(e.target.value)} label="Nombre" variant="outlined"  fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField id="outlined-basic" value={nuevoRepliesEnviar} onChange={e => setNuevoRepliesEnviar(e.target.value)} label="Enviar" variant="outlined"  fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<AddBoxIcon/>}
                                >
                                    Agregar
                                </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                   

                        {listaReplies}

                    </Grid>

         
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Guardar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
       );

}

