import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import { useUserState} from "../../../../context/UserContext";
import {db} from "../../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import DialogElement from './dialogElement';
import {maximaLongitudCarrousel} from './validaciones';


import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites,
  mensajeTextoContenidoErrores,
  validarTextoMensajeErrorEscrito,
  mensajeTextoErrorErrores
} from './validaciones'

import log_it from './../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    }
  }),
);



export function Texto(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);

    /*variables de datos*/
    const [mensaje,setMensaje]=React.useState("")
    const [complemento,setComplemento]=React.useState(false);
    const [ia,setIA]=React.useState(false);
    const [intentos,setIntentos]=React.useState(0);
    const [validacion,setValidacion]=React.useState("0");
    const [mensajeError,setMensajeError]=React.useState("");
    const [atributos,setAtributos]= React.useState("");

    const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);
    const [mensajeErrorCodigoValidacion,setMensajeErrorCodigoValidacion]=React.useState(-1);

    const [moverElementos,setMoverElementos]=React.useState(true);

    const [items,setItems]=React.useState([]);
    const [enviar,setEnviar]=React.useState("");


    useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
      }
    },[bloques]);

    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }

    },[]);

    /*inicializar vaiables*/
    useEffect(() =>{
        if(props.datos)
        {
            setMensaje(props.datos.mensaje);
            setIA(props.datos.ia);
            setEnviar((props.datos.enviar_a===""||props.datos.enviar_a===undefined)?"0":props.datos.enviar_a)
            setComplemento((props.datos.complemento===undefined)?false:props.datos.complemento)
            setIntentos(props.datos.intento);
            setValidacion(props.datos.validacion);
            setMensajeError(props.datos.mensaje_error);
            setAtributos(props.datos.atributo);

        }

      },[open]);

    const cambiarIA=(event)=>{
      var ia = event.target.checked
        setIA(ia)
        var adaptedIaValue = null
        if(ia==true){
          adaptedIaValue = ia
        }
        var codigoValidacion = validarTextoMensajeErrorEscrito(event,mensajeError,adaptedIaValue)
        setMensajeErrorCodigoValidacion(codigoValidacion)

    }
    const cambiarEnviar=(event)=>{
      if(event.target.value!==undefined)
      {
        setEnviar(event.target.value);
      }
    }

    const cambiarComplemento=(event)=>{
      if( event.target.checked!==undefined)
      {
        setComplemento( event.target.checked);
      }
    }

    const cambiarValidacion=(event)=>{
      if(event.target.value!==undefined){
        setValidacion(event.target.value);
      }

    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
      //Complemento afecta a: IA, validaciones y atributo

      var temp_enviar_a = ""
      var temp_intentos = 0
      if(enviar!="0"){
        temp_enviar_a=enviar
      }
      temp_intentos = parseInt(intentos)
      if(isNaN(temp_intentos)){
        temp_intentos=0
      }
      setCargando(true);
      /*Guardar cuando es un nuevo elemento*/

      var info_a_guardar = {
        atributo:atributos,
        elemento:"texto",
        complemento:complemento,
        ia:ia,
        enviar_a:temp_enviar_a,
        intento:temp_intentos,
        mensaje:mensaje,
        mensaje_error:mensajeError,
        validacion: validacion==="0"?"":validacion
      }
      if(complemento){
        info_a_guardar["ia"] = false
        info_a_guardar["validacion"] = ""
        info_a_guardar["atributo"] = ""
      }

      if(props.id_elemento===-1){
        setCargando(false);
        setOpen(false);
        enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});

        props.GuardarNuevoElemento(info_a_guardar,props.new_index);
      }else{
        db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
        .update(info_a_guardar
          ,function(error) {
            if (error) {
                setCargando(false);
                enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
            } else {
                setCargando(false);
                enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                setOpen(false);
                var obj = {
                  fecha: + new Date(),
                  nombre: "modificar elemento",
                  id_accion: 132,
                  id_bot: bot_actual.id,
                  red_social: bot_actual.conexion,
                  bloque:props.id_bloque,
                  elemento:"texto",
                }
                log_it(datos.id_empresa,user.id,obj)
            }
        });

      }


    }

    /*Borrar elemento */
    const EliminarElemento=()=>{

        setOpen(false);
        props.eliminar(props.id_elemento);
    }

    /*Mover DErecha*/
    const Movermenosuno=()=>{


      props.MoverMenosuno(props.id_elemento);
    }

    const MoverMasuno=()=>{


      props.MoverMasuno(props.id_elemento);
    }

    return(
        <React.Fragment>

          <DialogElement
            id_elemento={props.id_elemento}
            moverElementos={props.moverElementos}
            moverMasuno={props.MoverMasuno}
            moverMenosuno={props.MoverMenosuno}
            icono={<AssignmentRoundedIcon/>}
            metodoAbrir={handleClickOpen}
            nombre={props.nombre}
            tamanio={props.tamanio}
            dialogOpen={open}
            dialogClose={handleClose}
            cargando={cargando}
            disabledSave={mensaje.length === 0 || (ia && mensajeError.length===0) || (ia && validacion==="0") || intentos<0 || (ia && intentos===0) || (atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos)))}
            guardar={GuardarCambios}
            eliminar={props.eliminar}
            cerrar={setOpen}
          >
        <Grid item xs={12}>
          <Grid spacing={2} container >
            <Grid item xs={12} sm={2}>
                <FormControlLabel
                    value="top"
                    control={<Switch color="primary" />}
                    label="Complemento"
                    labelPlacement="end"
                    checked={complemento}
                    onChange={cambiarComplemento}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField id="ElTexto_mensaje"
                value={mensaje}
                onChange={
                  (e)=>{
                    /*Para hacerlo mas legible*/
                    var codigoValidacion = validarTextoMensajeContenidoEscrito(e,e.target.value,bot_actual.conexion)
                    setMensajeCodigoValidacion(codigoValidacion)
                    if(codigoValidacion<1){
                      setMensaje(e.target.value)
                    }
                  }
                }
                label="Mensaje"
                variant="outlined"
                fullWidth
                multiline
                rowsMax={5}
                inputProps={{
                  maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                }}
                disabled={datos.permisos.abcbot.bloques!==true}
                error={ mensajeCodigoValidacion != -1}
                helperText={mensajeTextoContenidoErrores(mensajeCodigoValidacion,bot_actual.conexion)}
                />
              </Grid>
          </Grid>
        </Grid>
            <Grid item xs={12}>
              {complemento?
              null:
                <Grid spacing={2} container >
                    <Grid item xs={12} sm={2}>
                      <FormControlLabel
                          value="top"
                          control={<Switch color="primary" />}
                          label="IA"
                          labelPlacement="end"
                          checked={ia}
                          onChange={cambiarIA}
                          disabled={datos.permisos.abcbot.bloques!==true}
                      />
                  </Grid>
                    <Grid item xs={12} sm={5}>
                        <FormControl disabled={datos.permisos.abcbot.bloques!==true} variant="outlined" className={classes.formControl}  error={ ia && validacion==="0"}       >
                        <InputLabel id="demo-simple-select-outlined-label">Validación</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={validacion}
                            onClick={cambiarValidacion}
                            label="Validacion"
                        >
                            <MenuItem value={"0"}>
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"nit"}>NIT</MenuItem>
                            <MenuItem value={"email"}>email</MenuItem>
                            <MenuItem value={"fecha"}>fecha</MenuItem>
                            <MenuItem value={"números"}>números</MenuItem>
                            <MenuItem value={"moneda"}>moneda</MenuItem>
                        </Select>
                        <FormHelperText>{ ia && validacion==="0"?"El campo es obligatorio si la IA esta activa":""}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField
                          id="outlined-basic"
                          value={atributos}
                          onChange={e => setAtributos(e.target.value)}
                          label="Atributo"
                          variant="outlined"
                          fullWidth
                          disabled={datos.permisos.abcbot.bloques!==true}
                          error={atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos))}
                          helperText={ atributos.length>0 && !(/^[a-zA-Z0-9_-]{1,30}$/.test(atributos)) &&
                          "El atributo solo pude contener numeros, letras y guiones"}
                        />
                    </Grid>
                  </Grid>
              }
          <Grid item xs={12}>
          <Grid spacing={2} container >

            <Grid item xs={12} sm={2}>
                <TextField id="outlined-basic" value={intentos}
                onChange={e => setIntentos(e.target.value)}
                label="Intentos" type={"number"}
                variant="outlined"
                fullWidth
                disabled={datos.permisos.abcbot.bloques!==true}
                error={(ia && intentos<=0) || intentos<0 }
                helperText={ ia && intentos<=0? "El campo es obligatorio si la IA esta activa":
                          intentos<0?"El valor debe  ser positivo":"" }
                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                                  <InputLabel id="select-enviar_a">Enviar a</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined-quil"
                                      value={enviar}
                                      onClick={cambiarEnviar}
                                      label="Enviar"
                                  >
                                      <MenuItem value="0">
                                          <em>None</em>
                                      </MenuItem>
                                      {items}
                                  </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                <TextField id="outlined-basic"
                                           value={mensajeError}
                                          //  onChange={e => setMensajeError(e.target.value)}
                                          onChange={
                                            (e)=>{
                                              /*Para hacerlo mas legible*/
                                              var adaptedIaValue = null
                                              if(ia==true){
                                                adaptedIaValue = ia
                                              }
                                              var codigoValidacion = validarTextoMensajeErrorEscrito(e,e.target.value,bot_actual.conexion,adaptedIaValue)
                                              setMensajeErrorCodigoValidacion(codigoValidacion)
                                              if(mensajeErrorCodigoValidacion<1){
                                                setMensajeError(e.target.value)
                                              }
                                            }
                                          }

                                           label="Mensaje de error"
                                           variant="outlined"
                                           multiline
                                           fullWidth
                                           disabled={datos.permisos.abcbot.bloques!==true}
                                           inputProps={{
                                            maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                           }}
                                           error={
                                            (ia && mensajeError.length===0) ||
                                            (mensajeErrorCodigoValidacion!=-1 )
                                            }
                                           helperText={
                                             mensajeTextoErrorErrores(mensajeErrorCodigoValidacion,bot_actual.conexion)
                                          }
                                           />
                            </Grid>

                    </Grid>
                  </Grid>
                </Grid>

        </DialogElement>
  </React.Fragment>
       );

}
