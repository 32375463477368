import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import WebIcon from '@material-ui/icons/Web';
import Typography from '@material-ui/core/Typography';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"

import { useAdminBotDispatch,
    BloqueActual,
} from '../contexto/AdminBotContext'


import Bloque from './Bloque';
const useStyles = makeStyles((theme) => ({
  list: {
    width: "auto",
  },
  fullList: {
    width: 'auto',
  },
}));






export default function VentanaListaBloques( props) {
  const classes = useStyles();
  var adminBotDispach=useAdminBotDispatch();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleListItemClick = (event, index,nombre) => {
    BloqueActual(adminBotDispach,{nombre:nombre,index:index});
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

        <List>
            <ListItem alignItems="flex-start">
              <ListItemText
               primary={<h3>Seleccione un bloque para ver su contenido</h3>}
              />
            </ListItem>
            {
                props.lista_bloques.map((item, i) => (

                  <div   key={"bloqueFacebook"+item.id}>
                  <ListItem

                        button
                        onClick={e =>{ BloqueActual(adminBotDispach,{nombre:item.id,
                                                                     padre:item.info.padre,
                                                                     elementos:item.info.elementos=== undefined?[]:item.info.elementos
                                                                   });
                                }}
                        >
                    <Bloque

                        padre={item.info.padre===undefined?"":item.info.padre}
                        elementos={item.info.elementos===undefined?[]:item.info.elementos}
                        default={item.info.is_default}
                        nombre={item.id}
                        cambiarNombre={props.CambiarNombre}
                        eliminar={props.EliminarBloque}
                    />
                  </ListItem>
                  <Divider/>
                  </div>

                ))
            }
        </List>


    </div>

  );

  return (
    <div>
      <Tooltip title={"Lista bloques"}>
        <IconButton aria-label="delete"  onClick={toggleDrawer('right', true)}>
          <WebIcon  size="large" style={{ color: "#4867aa"
                             }}/>
        </IconButton>
      </Tooltip>
    <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
      {list('right')}
    </Drawer>
    </div>
  );
}
