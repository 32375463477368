import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import {
  ThemeProvider as ThemeChangeProvider,
  ThemeStateContext
} from "./context/ThemeContext";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Root from "./components/Root"

ReactDOM.render(

    <UserProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Root>
        <LayoutProvider>
          <ThemeChangeProvider>
            <ThemeStateContext.Consumer>
              {theme => (
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <App />
                </ThemeProvider>
              )}
            </ThemeStateContext.Consumer>
          </ThemeChangeProvider>
          </LayoutProvider>
          </Root>
      </MuiPickersUtilsProvider>
    </UserProvider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
