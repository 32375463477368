import React, { useEffect } from 'react';
import { SignalCellularNullSharp, VerticalAlignTopRounded } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import {useSnackbar } from 'notistack';
import log_it from './../../../services/log/log'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    }
  }),
);

export default function DeleteBloque(props){

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const[confiracion,setConfirmacion] = React.useState(false);

    const EliminarElemento=()=>{
        //enqueueSnackbar('Procesando la solicitud', {variant: 'info'});
        props.cerrar(false);
        props.eliminar(props.idBloque);
       

    }

    const cancelar=()=>{
        setConfirmacion(false);
    }

    const cambiarConfiracion=()=>{
        setConfirmacion(true);
    }

    return(
        <React.Fragment>
        { confiracion ?
                <Card className={classes.root}>
                <CardActionArea>
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Eliminar
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Esta seguro que desea eliminar este Bloque, esta accion es permanete
                    </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size="small" className={classes.textoDelete} onClick={EliminarElemento}>
                        Delete
                    </Button>
                    <Button size="small" color="primary" onClick={cancelar}>
                        Cancelar
                    </Button>
                </CardActions>
            </Card>
            :
            <Button
            variant="contained"
            className={classes.colorDelete}
            startIcon={<DeleteIcon />}
            onClick={cambiarConfiracion}
            >
            Delete
            </Button>

            }

        </React.Fragment>

    );


}
