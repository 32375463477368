import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  imagenes: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    display:'block',
    margin:'auto',
  },
  imagenesDark: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    display:'block',
    margin:'auto',
    backgroundColor:"#000000 !important",
  },
  iconoEliminar:{
    color:"#CD5C5C"
  },
  iconoEditar:{
    color:"#5dade2"
  },
  colorFondoHead:{
    backgroundColor:  theme.palette.primary.light,  
  },
  TituloUsusarios:{
    textAlign: 'center', 
    backgroundColor:  theme.palette.primary.light,   
      
  },
  textoUsuario:{
      fontWeight:'bold !important' ,
      fontSize: '18px !important',
      color: '#ffff',
  },
  textoHeadEmpresa:{
    fontSize :'18px !important',
    display:'block',
    margin:'auto',
    textAlign:'center',
    color:  theme.palette.primary.main, 
  },
  alinearDerecha:{
    textAlign: 'right'
  },
  magenGrandeTop:{
   margin: ' 25px 0 0 0',
  },
  loginLoader: {
    marginLeft: theme.spacing(4)
  },
  fondoDark: {
    backgroundColor:"#000000 !important",
  },


}));
