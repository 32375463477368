import React , { useState, useEffect, cloneElement } from 'react';
import {
  Grid,
  TextField as Input,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Widget from '../../components/Widget/Widget';
import useStyles from './styles';
import { Button, Typography,Avatar } from "../../components/Wrappers/Wrappers";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ChatIcon from '@material-ui/icons/Chat';
import Alert from '@material-ui/lab/Alert';
import { useUserState} from "../../context/UserContext";
import ReactApexChart from 'react-apexcharts'
import {db} from "../../services/firebase";
import CircularProgress from '@material-ui/core/CircularProgress';

import TelegramIcon from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import log_it from './../../services/log/log';

function ReportsFacebookLog(props)
{
  var classes = useStyles();
  var { datos, user } = useUserState();

  const [sinDatos, setSinDatos] = React.useState(false);
  const [progreso, setProgreso] = React.useState(false);
  const [nombrebot, setNombrebot]=React.useState("");
  const [fechaIni, setFechaIni] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(),1));
  const [fechaFin, setFechaFinal] = React.useState(new Date( new Date().getFullYear(), new Date().getMonth(),new Date().getDate() ));
  const [series,setSeries]=React.useState([]);

  const date_diff_indays = function(date1, date2) {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  const  [options,setOptions]=React.useState({
      chart: {
        height: 350,
        type: 'bubble',
      },
      dataLabels: {
          enabled: false
      },
      fill: {
          opacity: 0.8
      },
      title: {
          text: 'Días en chatcenter por usuario'
      },
      xaxis: {
          type: 'datetime',
          min: fechaIni,
          tickAmount: 1
      },
      yaxis: {
          max: 24,
          tickAmount: 4
      },
      tooltip: {
        enabled: true,
        custom: function({series, seriesIndex, dataPointIndex, w}){
          return (
            '<div className={bubble_tooltip}>' +
            "<span className={bubble_tooltip_line}><strong>Usuario:</strong> " +
            w.globals.seriesNames[dataPointIndex] +
            "<br/><strong>" +
            w.globals.seriesZ[seriesIndex] +
            "</strong> días</span>" +
            "</div>"
          );
        }
      }
    });

  var camibarBot = (event)=> {
      setNombrebot(event.target.value);
  };

  const cambiarFechaIni = (date) => {
    setFechaIni(date);
  };

  const cambiarFechaFin = (date) => {
    setFechaFinal(date);
  };

  const generarReporte=()=>{
  setProgreso(true);
  setSinDatos(false);

    db.ref("log_chatcenter/"+datos.id_empresa+"/"+props.red+"/"+nombrebot.valor).
       once("value",function(snapshot){          
          let usuarios=[];
          snapshot.forEach(function(data){
              let coleccion = data.val();
              let day_diff = 0;
              let fechaInicio = new Date(fechaIni).getTime();
              let fechaFinal = new Date(fechaFin).getTime();
              if(coleccion.fecha_ini >= fechaInicio && coleccion.fecha_ini <= fechaFinal){
                if('fecha_fin' in coleccion && coleccion.fecha_fin!=""){
                  day_diff = date_diff_indays(coleccion.fecha_ini, coleccion.fecha_fin);
                } else {
                  day_diff = date_diff_indays(coleccion.fecha_ini, Date.now());
                }
                let usuario = "";
                if('id' in coleccion){
                  usuario = coleccion.id;
                } else {
                  usuario = coleccion.usuario
                }
                let usuario_formato = {
                  name: usuario,
                  data: [[coleccion.fecha_ini, new Date(coleccion.fecha_ini).getHours(), day_diff]]
                };

                usuarios.push(usuario_formato);
              }
          });
          if(usuarios.length===0){setSinDatos(true)}
            ///grafico de usuarios atendidos
            setSeries(usuarios);
            setProgreso(false);
            var obj = {
              fecha: + new Date(),
              nombre: "generar reporte log chatcenter",
              id_accion: 503,
              id_bot: nombrebot.valor,
              red_social: props.red,
            }
            log_it(datos.id_empresa,user.id,obj)
   });


  }


  return(
    <Widget
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            title={'Generar Reporte'}
    >

      <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>

        { props.red==="telegram" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <TelegramIcon style={{ fontSize: 43,
                                     color: "#279fd3"
                                   }}  />
            </Typography>
          </Grid>
        }
        { props.red==="facebook" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <FacebookIcon style={{ fontSize: 43,
                                     color: "#4867aa"
                                   }}  />
            </Typography>
          </Grid>
        }
        { props.red==="whatsapp" &&
           <Grid item xs={12}  >
            <Typography className={classes.LeyendaPorcentajeTitulo}>
              <WhatsAppIcon style={{ fontSize: 43,
                                     color: "#25cd60"
                                   }}  />
            </Typography>
          </Grid>
        }

          <Grid item xs={12}>
              <Typography className={classes.LeyendaPorcentaje}>Log de usuarios</Typography>
          </Grid>


          <Grid item xs={12}>
            <Grid container spacing={3}  alignItems={"center"} justify={"space-between"} direction={'row'}>

                <Grid item  xs={4} >
                 <FormControl variant="outlined"  className={classes.centrar}  >
                  <KeyboardDatePicker
                     disableToolbar
                     variant="inline"
                     format="dd-MM-yyyy"
                     margin="normal"                     
                     label="Fecha inicio"
                     value={fechaIni}
                     onChange={cambiarFechaIni}
                     KeyboardButtonProps={{
                       'aria-label': 'change date',
                     }}
                   />
                  </FormControl>
                 </Grid>

                <Grid item  xs={4} className={classes.centrar}>
                   <FormControl variant="outlined" >
                     <InputLabel id="demo-simple-select-outlined-label">Bot</InputLabel>
                       <Select
                           labelId="demo-simple-select-outlined-label"
                           id="demo-simple-select-outlined"
                           label="Age"
                           value={nombrebot}
                           onChange={camibarBot}
                       >
                       <MenuItem value="">
                           <em>None</em>
                       </MenuItem>
                         {props.listabot}
                       </Select>
                   </FormControl>
                 </Grid>

                 <Grid item  xs={4}  className={classes.centrar}>
                    <FormControl variant="outlined"  >
                       <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Fecha fin"
                          value={fechaFin}
                          onChange={cambiarFechaFin}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                    </FormControl>
                  </Grid>
            </Grid>
          </Grid>

        {fechaFin<fechaIni &&
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.centrar} >
              <Alert severity="error">La fecha de inicio debe ser menor a la fecha final</Alert>
            </FormControl>
          </Grid>
        }

        {  progreso &&
            <Grid item xs={12}  >
                <FormControl variant="outlined" className={classes.centrar}>
                    <CircularProgress disableShrink/>
                </FormControl>
            </Grid>
        }

        {   progreso===false &&
         <Grid item xs={12}>
             <FormControl variant="outlined" className={classes.centrar}>
               <Button
               variant="contained"
               color="primary"
               size="large"
               startIcon={<ChatIcon />}
               disabled={nombrebot==="" || fechaFin<fechaIni}
               onClick={generarReporte}
               >
               Generar reporte
               </Button>
             </FormControl>
         </Grid>
       }

      {sinDatos &&
        <Grid item xs={12}>
          <Alert severity="warning">No se encontraron datos en el rago de fecha</Alert>
        </Grid>
      }

         <Grid item xs={12}>
          <ReactApexChart options={options} series={series} type="bubble" height={350} />
        </Grid>
      </Grid>
    </Widget>
  );
}

export default ReportsFacebookLog;