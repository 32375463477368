import React,{useState,useEffect} from 'react';
import Widget from '../../../components/Widget';
import {Grid,CircularProgress} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import TextField from '@material-ui/core/TextField';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import useStyles from './styleEmpresas';
import {Avatar,Typography } from "../../../components/Wrappers";
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useUserState} from "../../../context/UserContext";
import {db,storage} from "../../../services/firebase";
import log_it from './../../../services/log/log'

import {useAdminDispatch,
        Ocultar_Editarempresa,
        inicializar_datos,
        editar_empresa,
        useAdminState} from '../contexto/AdminContext'


export default function EditarEmpresa() {
    
    var classes = useStyles();
    var adminDispach=useAdminDispatch();
    var { user,datos } = useUserState();   
    var {id_empresa,empresa } = useAdminState();

  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(false);
  var [errordetail, setErrordetail] = useState(null);

  var [nombre, setNombre] = useState("");
  var [correo, setCorreo] = useState("");
  var [telefono, setTelefono] = useState(0);
  var [nit, setNit] = useState("");
  var [direccion, setDireccion] = useState("");
  var [iamgen, setImagen] = useState([]);
  var [imagenDark, setImagenDark] = useState([]);
  var [guardadoCorrecto,setGuardaroCorrecto]=useState(false);
  var [fechaIni,setFechaIni]=useState("");
  var [fechaFin,setFechaFin]=useState("");

  var [mostrarProgreso,setMostrarProgreso]=useState(false);
  var [progressImagen,setProgressImagen]=useState(0);
  var [progressImagenDark,setProgressImagenDark]=useState(0);

  var [avartDark,setAvatarDark] = React.useState("");
  var [avart,setAvatar] = React.useState("");
  var [errorAvatar,setErrorAvatar] = React.useState(false);
  var [errorAvatarDark,setErrorAvatarDark] = React.useState(false);

  var regresarPantall = () =>{
    inicializar_datos(datos,adminDispach);
    Ocultar_Editarempresa(adminDispach);
  }

  const valorFechaIni = (date) => {
    setFechaIni(date);
  };

  const valorFEchaFin = (date) => {
    setFechaFin(date);
  };

  const inicializar = () =>{
    setNombre("");
    setCorreo("");
    setTelefono("");
    setNit("");
    setDireccion("");
    setImagenDark("");
    setImagen("");
    setFechaIni("");
    setFechaFin("");
    setProgressImagenDark(0);
    setProgressImagen(0);
    setImagen([]);
    setImagenDark([]);
    setGuardaroCorrecto(false);


  };

  function inicializarDatosEmpresa(datos)
  {
    if(datos)
    {    
        if(localStorage.getItem('theme')==='dark')
        {
            setAvatarDark(datos.foto);               
        }else{
            setAvatar(datos.foto);               
        }
    }
  }
  
  //cargar datos de la empresa
  useEffect(() =>{    

    inicializarDatosEmpresa(empresa.find(item => item.id === id_empresa));     

    db.ref("usuario_bot/"+id_empresa+"/informacion").once('value').then(function(snapshot){
        var datos_empresa=snapshot.val();

       // console.log("datos",datos_empresa);
        setNombre(datos_empresa.nombre ? datos_empresa.nombre : "");
        setCorreo(datos_empresa.correo ? datos_empresa.correo : "");
        setTelefono(datos_empresa.telefono ? datos_empresa.telefono :0);
        setNit(datos_empresa.nit ?  datos_empresa.nit : "");
        setDireccion(datos_empresa.direccion_facturacion ? datos_empresa.direccion_facturacion :"" );  
         
      }).then(function(parametros){
          
        
          if(localStorage.getItem('theme')==='dark')
          {
            var id=id_empresa+'/icono.png'; 

          }else{          
            var id=id_empresa+'/dark.png';
          }

          storage.ref('empresas/'+id).getDownloadURL().then(function(url) {                            
            
            if(localStorage.getItem('theme')==='dark')
            {
                setAvatar(url);               
            }else{
                setAvatarDark(url);               
            }

            setMostrarProgreso(false);
           }).catch(function(error) {
            
            if(localStorage.getItem('theme')==='dark')
            {
               setErrorAvatar(true);              
            }else{
                setErrorAvatarDark(true);               
            }
           
            setMostrarProgreso(false);
           
           });
                        

      });
  
  },[]);

  return (



    <React.Fragment>
        <Grid spacing={3} container >  
            <Grid item xs={12}>
                <Widget disableWidgetMenu >                  
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"                      
                            startIcon={<KeyboardBackspaceIcon />}
                            onClick={regresarPantall }                      
                            >                    
                            Regresar
                        </Button>
                </Widget>
            </Grid>
            <Grid item xs={12}>
                
                <Widget disableWidgetMenu >                  
                    <form  noValidate autoComplete="off"> 
                        <Grid spacing={2} container >  
                            <Grid item xs={12}>
                                <Typography variant='h3'>
                                    Editar Empresa 
                                </Typography>
                                { mostrarProgreso && <LinearProgress />}
                            </Grid> 
                        </Grid>
                        <Grid spacing={2} container direction="column" justify="center" >  
                            <Grid item xs={12} md={3}>
                                <TextField disabled={datos.permisos.empresas.editar? false:true} value={nombre} onChange={e => setNombre(e.target.value)} id="outlined-basic" label="Nombre de la empresa" variant="outlined" fullWidth className={classes.magenGrandeTop}/>
                            </Grid> 
                            <Grid item xs={12} md={3}>
                                <TextField disabled={datos.permisos.empresas.editar? false:true} value={correo} onChange={e => setCorreo(e.target.value)} id="outlined-basic" label="Correo elctronico" variant="outlined" fullWidth />
                            </Grid> 
                            <Grid  item xs={12} md={3}>
                            <TextField disabled={datos.permisos.empresas.editar? false:true} error={Number.isInteger(parseInt(telefono, 10)) ? false : true} helperText="Ingrese solo numeros" value={telefono} onChange={e => setTelefono(e.target.value)} id="outlined-basic" label="Telefono" variant="outlined"  type="number" fullWidth/>
                                    
                            </Grid> 
                            <Grid item xs={12} md={3}>
                                <TextField disabled={datos.permisos.empresas.editar? false:true} value={nit} onChange={e => setNit(e.target.value)} id="outlined-basic" label="Nit" variant="outlined" fullWidth />
                            </Grid> 
                            <Grid item xs={12}  md={3}>
                                <TextField disabled={datos.permisos.empresas.editar? false:true} value={direccion} onChange={e => setDireccion(e.target.value)} id="outlined-basic" label="Direccion de facturacío" variant="outlined" fullWidth />
                            </Grid>                  
                           
                        </Grid>

                        <Grid spacing={2} container >                           
                            <Grid item xs={12} md={6}>
                                <Avatar
                                alt="Avatar"
                                variant="square"
                                src={avart}
                                classes={{ root: classes.imagenes }}
                                /> 
                            </Grid>
                        </Grid>
                        {errorAvatar &&
                        <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12} md={6}>    
                               
                                    <Alert severity="error">Error — Al descargar la imagen actual de perfil</Alert>                                                    
                            </Grid>    
                        </Grid> }
                       {datos.permisos.empresas.editar &&
                        <Grid spacing={2} container >                           
                            <Grid item xs={12} md={6}>
                                <DropzoneArea
                                    maxFileSize={500000}
                                    filesLimit={1}
                                    acceptedFiles={['image/png']}
                                    dropzoneText={"Seleccione una imagen para la Empresa"}
                                    value={iamgen}
                                    onChange={(files) => setImagen(files)}
                                />
                            </Grid>    
                        </Grid> 
                        }                        
                        <Grid spacing={2} container >                           
                            <Grid item xs={12} md={6}>
                                <Avatar
                                alt="Avatar"
                                variant="square"
                                src={avartDark}
                                classes={{ root: classes.imagenesDark }}
                                /> 
                            </Grid>
                        </Grid>

                        { errorAvatarDark &&    
                        <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12} md={6}>    
                               
                                    <Alert severity="error">Error — Al descargar la imagen actual de perfil</Alert>                                                    
                            </Grid>    
                        </Grid>}
                        {
                        datos.permisos.empresas.editar &&
                            <Grid spacing={2} container > 
                                <Grid item xs={12} md={6}>
                                    <DropzoneArea
                                        maxFileSize={500000}
                                        filesLimit={1}
                                        className={classes.fondoDark}
                                        value={imagenDark}
                                        acceptedFiles={['image/png']}
                                        dropzoneText={"Seleccione una imagen para la Empresa cuando se utilice el tema Dark"}
                                        onChange={(files) => setImagenDark(files)}
                                    />
                                </Grid>    
                            </Grid> 
                        }

                        <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12} className={classes.alinearDerecha}>                           
                            {isLoading ? (
                                <CircularProgress size={26} className={classes.loginLoader} />
                                ) : (
                            datos.permisos.empresas.editar &&
                            guardadoCorrecto===false &&  <Button
                                    disabled={
                                        nombre.length === 0 ||
                                        correo.length === 0 ||
                                        telefono.length === 0 ||
                                        nit.length === 0              
                                    }
                                    onClick={() => {

                                        editar_empresa(                                            
                                            nombre,
                                            correo,
                                            telefono,
                                            nit,
                                            direccion,
                                            iamgen,
                                            imagenDark,                                         
                                            setGuardaroCorrecto,
                                            setProgressImagen,
                                            setProgressImagenDark,
                                            setIsLoading,                                                                                       
                                            setError,
                                            setErrordetail,
                                            id_empresa
                                        )
                                        var obj = {
                                            fecha: + new Date(),
                                            nombre: "editar empresa",
                                            id_accion: 202,
                                            id_empresa:id_empresa,
                                            id_usuario:user.id,
                                        }
                                        log_it(datos.id_empresa,user.id,obj)
                                    }
                                    
                                    }
                                    variant="contained"
                                    color="primary"
                                    size="large"  
                                    startIcon={<SaveIcon />} 
                                                                
                                    >  
                                
                                    Guardar
                                </Button>
                                )}                       
                            </Grid>
                           
                        </Grid>
                        {
                            isLoading &&

                            <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12}>  
                                <Typography >
                                    Guardando Imagen
                                </Typography>                         
                                <LinearProgress variant="determinate" value={progressImagen} />
                            </Grid>    
                            <Grid item xs={12}>  
                                <Typography >
                                    Guardando Imagen tema Dark
                                </Typography>                            
                                <LinearProgress variant="determinate" value={progressImagenDark} />
                                
                            </Grid>    
                            
                        </Grid>    

                        }    
                       
                       { error && 
                        <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                            <Grid item xs={12}>    
                               
                                    <Alert severity="error">Error —{errordetail}</Alert>                                                    
                            </Grid>    
                        </Grid> 
                        }
                        {guardadoCorrecto &&    
                            <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end"> 
                                 <Grid item xs={12}> 
                                     <Alert severity="success">Datos guardados correctamente</Alert>                                                                                     
                                </Grid>    
                            </Grid> 
                        }
                        
                    </form>
                </Widget>
            </Grid>

        </Grid>  
     </React.Fragment> 

  );
}
