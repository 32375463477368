/* eslint-disable no-use-before-define */
import React, {useState ,useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {db} from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import useStyles from '../styles';

export default function FiltroBot(props) {

  const fixedOptions = [];
  var classes = useStyles();
  var { datos,user } = useUserState();
  const [value, setValue] = React.useState([...fixedOptions]);
  const [listaBot, setListabot] = React.useState([{ title: 'bot1', id: 'facebook' },
  { title: 'bot2', id: 'Whatsapp' },
  { title: 'bot3', id: 'telegram' },]);
  /*const top100Films = [
    { title: 'bot1', id: 'facebook' },
    { title: 'bot2', id: 'Whatsapp' },
    { title: 'bot3', id: 'telegram' },
  ];*/

  //Obtener informacion de los bots
  useEffect(() =>{

     db.ref("bots/"+datos.id_empresa).once('value',function(snapshot){

          /*llenar lista de bots*/
          let lista=[];

          snapshot.forEach(function(data){
          //  console.log("item",{title: data.val().nombre,id:data.val().nombre});
            lista.push({title: data.val().nombre,id:data.key});
          });

          /*seleccionar los bots gurados en el perfil*/
          db.ref('config_user/'+user.id+'/configuraciones/ChatCenter').once("value",(snapshot)=>{
            if(snapshot.val()!==null)
            {
              let arreglo=[]
              snapshot.val().bots.forEach((item, i) => {

                const resultado = lista.findIndex( bot => bot.id === item.id );
                if(resultado>0)
                {
                  arreglo.push(lista[resultado]);
                }

              });
              setValue(arreglo);
              props.lista(arreglo);

            }
          });


          setListabot(lista);


      });

    return ()=>{
        db.ref("bots/"+datos.id_empresa).off();
    }

  },[]);


  return (
    <Autocomplete
      disabled={props.disable}
      multiple
      id="fixed-tags-demo"
      value={value}
      fullWidth={true}
      onChange={(event, newValue) => {
        if(!props.disable){
          props.lista(newValue);
          setValue([
            ...fixedOptions,
            ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
          ]);
        }
      }}
      options={listaBot}
      getOptionLabel={(option) => option.title}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.title}
            {...getTagProps({ index })}
            disabled={fixedOptions.indexOf(option) !== -1}
          />
        ))
      }
      className={classes.tamanoFiltro}
      renderInput={(params) => (
        <TextField fullWidth={true} {...params} label="Bot" variant="outlined" placeholder="bot" />
      )}
    />
  );
}
