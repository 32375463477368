import React, { useState ,useEffect,Component} from 'react'
import {
    Avatar,  
    ListItem,
    ListItemAvatar,
    ListItemText,
    List,
    Box,
} from '@material-ui/core'
import {Button,  Typography, Link } from '../../../components/Wrappers';
import useStyles from '../styles';
import { useTheme, makeStyles } from '@material-ui/styles';
import { lightBlue,red,green, deepPurple } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import {useChatState,useChatDispatch,GetConversation,OcultarCargarDatos} from '../context/ChatContext'
import TabPanel from './TabPanel';
import Chip from '@material-ui/core/Chip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ListaUsuarios(props) {

    var classes = useStyles();
    var {datos_bot,bot_actual,cargado_datos} = useChatState();
    var chatDispach=useChatDispatch();

    const [datosClientes,setDatosClientes] = React.useState("");
    const [verDatos,setVetDatos]=React.useState(false);
    const [buscarChat,setBuscarChat] = React.useState([]);
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [value, setValue] = React.useState(0);


    function compare(a, b) {      
    
      let aa=0;
      let bb=0;
      if (a[1].atributos.chatcenter===true){aa=1;}

      if (b[1].atributos.chatcenter===true){bb=1;}
      //a es menor que b según criterio de ordenamiento
      if (bb<aa) {       
        return -1;
      }
      //a es mayor que b según criterio de ordenamiento
      if (bb>aa) {      
        return 1;
      }    
      // a debe ser igual b
      return 0;
    }

    useEffect(() =>{
     
      //if(datos_bot !=="") {setDatosClientes(JSON.stringify(datos_bot));}
      if(datos_bot!="")
      {   
          setVetDatos(true);           
          const arreglo = Object.entries(datos_bot).sort(compare);           
          let lista_usuaruios=[];
          let buscar=[];

            arreglo.map(item => {
              var key = item[0];
              var dato = item[1];           
                lista_usuaruios.push(
                <ListItem alignItems="flex-start" button key={key+'fbau'} 
                    selected={selectedIndex === key}
                    onClick={(event, index) =>{        
                         setSelectedIndex(key);                          
                          //console.log('selectindexOnclick',selectedIndex);
                          GetConversation(chatDispach,{key:key,user_name:dato.atributos.tg_nombre});
                        }                     

                          } >                     
                    <ListItemAvatar>
                      <Avatar alt={dato.atributos.tg_nombre}
                      src={dato.atributos.fb_picture} />                      
                    </ListItemAvatar>                   
                    <ListItemText className={classes.listaChatTitulo} id={key} primary={dato.atributos.tg_nombre} secondary={
                    <React.Fragment>                       
                      <Typography component="span"
                         className={classes.usuarioActualSubTitulo} >
                        {key}<br/>{dato.atributos.chatcenter && dato.atributos.fecha_chatcenter?new Date(dato.atributos.fecha_chatcenter).toLocaleString('es-MX'):""}
                      </Typography> 
                    </React.Fragment>} />
                    <ListItemSecondaryAction>
                    {dato.atributos.chatcenter && <Chip color="primary" size="small" label="Chat center" />}
                    </ListItemSecondaryAction>
                </ListItem>); 
                
                buscar.push({nombre:dato.atributos.tg_nombre,
                            fecha_ingreso:dato.atributos.fecha_chatcenter?new Date(dato.atributos.fecha_chatcenter).toLocaleString('es-MX'):"",
                            key:key,
                            chatcenter:dato.atributos.chatcenter===true? true:false});           
          });  
          setVetDatos(false);  
          setBuscarChat(buscar);
          OcultarCargarDatos(chatDispach);
          setDatosClientes(lista_usuaruios);
    }else{
      setDatosClientes("");
      setBuscarChat([]);
    }

    },[datos_bot]);
  

    const cambiar_actual=(event, newValue) =>{

      if(newValue!=null)
      {
        GetConversation(chatDispach,{key:newValue.key,user_name:newValue.nombre});
       
      /*  console.log('selectindex',newValue);
        console.log('selectindex',selectedIndex);
        new Promise((resolve) => {  setSelectedIndex(newValue.key); resolve();});
        console.log('selectindex',selectedIndex);*/
      }
      
    }

    
    return(  
      <React.Fragment>
        {verDatos && <CircularProgress />}               
        <Autocomplete         
            fullWidth    
            id="free-solo-demo"             
            loading={cargado_datos}
            getOptionSelected={(option, value) => option.nombre === value.nombre}
            onChange={cambiar_actual}            
            getOptionLabel={(option) => option.nombre}
            options={buscarChat}
            renderInput={(params) => (
              <TextField {...params} label="Buscar" margin="normal" variant="outlined" />
            )}            
       /*     renderOption={(option, { List  }) => (
              <React.Fragment>                                      
                  <ListItemAvatar>
                    <Avatar  alt={option.nombre}>{option.nombre.charAt(0)}</Avatar>                      
                  </ListItemAvatar>                   
                  <ListItemText className={classes.listaChatTitulo} id={option.key} primary={option.nombre} secondary={
                  <React.Fragment>                       
                    <Typography component="span"
                      className={classes.usuarioActualSubTitulo} >
                      {option.key}<br/>{option.chatcenter && option.fecha_ingreso}
                    </Typography> 
                  </React.Fragment>} />                
                  {option.chatcenter && <Chip color="primary" size="small" label="Chat center" />}                 
              </React.Fragment>
            )}*/
            
            />  
         <List className={classes.rootlist}
         >
            {datosClientes}
                  </List>
      </React.Fragment>
            
  );
}

