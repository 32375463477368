import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

//components
import { Button, Typography } from "../../components/Wrappers";

// logo
//import logo from "./logo.png";

export default function OtraSesion() {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>

      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}
        >
          Error
        </Typography>
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Ya tienes una sesión activa funcionando, cierra la otra sesión y recarga la página para continuar
        </Typography>   

      </Paper>
    </Grid>
  );
}
