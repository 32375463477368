import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {
  useAdminBotDispatch,
  botActual,
  useAdminBotState,
} from "../../../contexto/AdminBotContext";
import Tooltip from "@material-ui/core/Tooltip";
import { useUserState } from "../../../../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",

      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      flexWrap: "nowrap",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: "translateZ(0)",
    },
    title: {
      color: theme.palette.primary.light,
    },
    titleBar: {
      background:
        "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    formControl: {
      width: "100%",
    },

    margen: {
      padding: theme.spacing(1),
    },

    colorDelete: {
      color: "#dc004e",
    },
  })
);

function Boton(props) {
  const classes = useStyles();
  var { bot_actual, bloques } = useAdminBotState();
  var { datos } = useUserState();

  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [enviara, setEnviara] = React.useState("0");
  const [items, setItems] = React.useState("");

  const [errorTitulo, setErrorTitulo] = React.useState(false);
  const [mensajeCodigoValidacion, setMensajeCodigoValidacion] = React.useState(
    false
  );
  const [
    descripcionCodigoValidacion,
    setDescripcionCodigoValidacion,
  ] = React.useState(false);

  useEffect(() => {
    if (bloques !== null) {
      let lista_items = [];
      bloques.forEach((element, index, array) => {
        lista_items.push(
          <MenuItem key={"item" + props.id + "_" + index} value={element}>
            {element}
          </MenuItem>
        );
      });
      setItems(lista_items);
    }
  }, [bloques]);

  useEffect(() => {
    setErrorTitulo(false);
    setTitle(props.title);
    setDescription(props.description);
    setType(props.type);
    setEnviara(props.enviar_a === "" ? "0" : props.enviar_a);
  }, [props.title, props.description, props.type, props.enviar_a]);

  const CambiarTitulo = (e) => {
    if (e.target.value.length >= 24) {
      setMensajeCodigoValidacion(true);
      return;
    } else {
      setMensajeCodigoValidacion(false);
    }
    setTitle(e.target.value);
    setErrorTitulo(false);

    props.editar((prevState) => {
      const resultado = prevState[props.index].options.find(
        (bns) => bns.title.toLowerCase() === e.target.value.toLowerCase()
      );
      if (resultado === undefined) {
        prevState[props.index].options[props.index_boton].title =
          e.target.value;
      } else {
        setErrorTitulo(true);
      }
      return prevState;
    });
  };

  const CambiarDescripcion = (e) => {
    if (e.target.value.length >= 72) {
      setDescripcionCodigoValidacion(true);
      return;
    } else {
      setDescripcionCodigoValidacion(false);
    }
    setDescription(e.target.value);

    props.editar((prevState) => {
      prevState[props.index].options[props.index_boton].description =
        e.target.value;
      return prevState;
    });
  };

  const CambiarEnviar = (e) => {
    setEnviara(e.target.value);

    props.editar((prevState) => {
      prevState[props.index].options[props.index_boton].enviar_a =
        e.target.value === "0" ? "" : e.target.value;
      return prevState;
    });
  };

  const Eliminar = () => {
    props.eliminar({ index: props.index, id: props.index_boton });
  };

  return (
    <Grid item xs={12}>
      <Grid spacing={2} container>
        <Grid item xs={12} sm={3}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            disabled={datos.permisos.abcbot.bloques !== true}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              Enviar
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined-quil"
              label="Enviar"
              value={enviara}
              onChange={CambiarEnviar}
            >
              <MenuItem value="0">
                <em>None</em>
              </MenuItem>
              {items}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            id="outlined-basic"
            label="title"
            variant="outlined"
            fullWidth
            multiline
            value={title}
            onChange={CambiarTitulo}
            error={errorTitulo || mensajeCodigoValidacion}
            inputProps={{
              maxLength: 24,
            }}
            helperText={
              errorTitulo
                ? "El titulo debe ser unico"
                : mensajeCodigoValidacion
                ? "El titulo no debe ser mayor a 24 caracteres"
                : ""
            }
            disabled={datos.permisos.abcbot.bloques !== true}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            id="outlined-basic"
            label="Descripcion"
            variant="outlined"
            fullWidth
            multiline
            value={description}
            inputProps={{
              maxLength: 72,
            }}
            helperText={
              descripcionCodigoValidacion
                ? "El texto no debe ser mayor a 72 caracteres"
                : ""
            }
            error={descripcionCodigoValidacion}
            onChange={CambiarDescripcion}
          />
        </Grid>

        {datos.permisos.abcbot.bloques === true && (
          <Grid item xs={12} sm={1}>
            <Tooltip title="Elminar">
              <IconButton
                className={classes.colorDelete}
                aria-label="delete"
                onClick={Eliminar}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Boton;
