import React, { useState ,useEffect,Component} from 'react';
import { useUserState} from "../../../context/UserContext";
import {useChatState,useChatDispatch,AtenderUsuario,EnviarMensaje} from '../context/ChatContext';
import {mensajes_chat} from "../../../services/automata"
import FavoriteIcon from '@material-ui/icons/Favorite';
import {IconButton} from '@material-ui/core';

function LikeHeart(){

  var { datos ,user} = useUserState();
  var chatDispach=useChatDispatch();
  var {bot_actual,usuario_actual,atender_usuario,datos_bot,datos_chat} = useChatState();

  const [enviando,setEnviando] = React.useState(false);
  const [error,setError] = React.useState(false);

  const ReiniciarVariables=()=>{
    console.log("");
  }

  const SendLikeHeart=()=>{

    EnviarMensaje(chatDispach,
      "like_heart",
      "like", //tipo
      '', //nombre
      '', //tamano
      '', //propietario
      usuario_actual,
      mensajes_chat,
      user,
      datos,
      setError,
      setEnviando,
      ReiniciarVariables,
    );
  }

  return (
  <React.Fragment>
   {
     usuario_actual.info!== undefined &&

      usuario_actual.info.red === "instagram" &&
         <IconButton onClick={SendLikeHeart} >
         <FavoriteIcon style={{color: "#d20056"}}/>
        </IconButton>

    }
  </React.Fragment>
  )

  }

export default LikeHeart;
