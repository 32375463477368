import React , { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import {useAdminBotState} from '../../../contexto/AdminBotContext';
import { useUserState} from "../../../../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#dc004e",

    },
    textoDelete:{
        color:"#dc004e",

    }
  }),
);


function Respuesta(props){

  const classes = useStyles();
  var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();

  const [items,setItems]=React.useState([]);

  const [tipo,setTipo] = React.useState("texto");
  const [nombre,setNombre]= React.useState("");
  const [mensaje,setMensaje]= React.useState("");
  const [enviara,setEnviara]= React.useState("");

  useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
          setEnviara(props.enviar_a!=undefined?
                    props.enviar_a===""?"0":props.enviar_a
                    :"0"
                  ); }
    },[bloques]);

  useEffect(() =>{
    setEnviara(props.enviar_a!=undefined?
              props.enviar_a===""?"0":props.enviar_a
              :"0"
            );
    setNombre(props.valor!=undefined?props.valor:"");
    setMensaje(props.mensaje!=undefined?props.mensaje:"");
    setTipo(props.enviar_a!=undefined?"bloque":"texto")

  },[props.enviar_a,props.valor,props.mensaje]);

  const CambiarNombre=(e)=>{
    setNombre(e.target.value);
    props.editar((prevState)=>{
      prevState[props.index].valor=e.target.value;
      return prevState;
    });
  }

  const CambiarMensaje=(e)=>{
    setMensaje(e.target.value);
    props.editar((prevState)=>{
      prevState[props.index].mensaje=e.target.value;
      return prevState;
    });
  }

  const CambiarEnviar=(e)=>{

    if(e.target.value!==undefined)
    {
      setEnviara(e.target.value);
      props.editar((prevState)=>{
        prevState[props.index].enviar_a=(e.target.value==="0"?"":e.target.value);
        return prevState;
      });
    }
  }

  const Eliminar=()=>{
    props.eliminar({id:props.i})
  }

  const CambiarTipo=(e)=>{


      setTipo(e.target.value);

      if(e.target.value==="texto")
      {
        props.editar((prevState)=>{
          prevState[props.index]={mensaje:mensaje,valor:nombre};
          return prevState;
        });
      }else{
        props.editar((prevState)=>{
          prevState[props.index]={enviar_a:(enviara==="0"?"":enviara),valor:nombre};
          return prevState;
        });
      }



  }

  return(

      <Grid item xs={12} >
          <Grid spacing={2} container >


              <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                  <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
                  <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined-quil"
                      value={tipo}
                      onChange={CambiarTipo}
                      label="Tipo"
                  >
                      <MenuItem value={"bloque"}>Bloque</MenuItem>
                      <MenuItem value={"texto"}>Texto</MenuItem>
                  </Select>
                  </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                  <TextField
                      id="outlined-basic"
                      value={nombre}
                      label="Nombre"
                      variant="outlined"
                      fullWidth
                      onChange={CambiarNombre}
                      error={nombre.length===0}
                      helperText={nombre.length===0?"El campo es obligatorio":""}
                      disabled={datos.permisos.abcbot.bloques!==true}

                      />
              </Grid>

              {tipo==="texto" &&
              <Grid item xs={12} sm={4}>
                  <TextField
                    id="outlined-basic"
                    value={mensaje}
                    label="Mensaje"
                    variant="outlined"
                    fullWidth
                    disabled={datos.permisos.abcbot.bloques!==true}
                    onChange={CambiarMensaje}
                    error={tipo==="texto" && mensaje.length===0}
                    helperText={tipo==="texto" && mensaje.length===0?"El campo es obligatorio":""}
                    />
              </Grid>
              }

             {tipo==="bloque" &&
              <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                      <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                      <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined-quil"
                          value={enviara}
                          onClick={CambiarEnviar}
                          label="Enviar"
                      >
                          <MenuItem value="0">
                              <em>None</em>
                          </MenuItem>
                          {items}
                      </Select>
                  </FormControl>
              </Grid>}

              {datos.permisos.abcbot.bloques===true &&
              <Grid item xs={6} sm={2}>
                <Tooltip title="Borrar">
                  <IconButton
                        aria-label="delete"
                        className={classes.colorDelete}
                        onClick={Eliminar}
                        >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>}

          </Grid>
      </Grid>
);

}

export default Respuesta;
