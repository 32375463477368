import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import Variables from './variables'
import { Button, Typography,Avatar } from "../../components/Wrappers";

import LanguageIcon from '@material-ui/icons/Language';

function ReportsWebChat ()
{
   var classes = useStyles();

   return (
      <Variables
        red={"webchat"}
        icono={<Typography className={classes.LeyendaPorcentajeTitulo}>
          <LanguageIcon style={{ fontSize: 43,
                                 color: "#056179"
                               }}  />
        </Typography>}
      />
   );

}



export default ReportsWebChat;
