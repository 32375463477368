import React, { useState ,useEffect,Component} from 'react'
import {Button,  Typography, Link } from '../../../components/Wrappers';
import {ListItemText,Avatar,List,ListItem,ListItemAvatar,TextField ,IconButton, Chip, Divider} from '@material-ui/core';
import useStyles from '../styles';
import InputMensaje from './InputMensaje';
import {useChatState,useChatDispatch,MostrarCargarChat,OcultarCargarChat,ReferenciaActual} from '../context/ChatContext';
import original from '../../../images/original.png';
import ChatIcon from '@material-ui/icons/Chat';
import MenuBotones from './MenuBotones';
import {db, fecha_hora} from '../../../services/firebase';
import { useUserState} from "../../../context/UserContext";
import {CircularProgress,Paper,Grid} from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import SendIcon from '@material-ui/icons/Send';
import FormControl from '@material-ui/core/FormControl';
import Alert from '@material-ui/lab/Alert';


import IconDoc from '../../../images/documentoss.png';
import IconNon from '../../../images/documento.png';
import IconMusic from '../../../images/musica.png';
import IconVideo from '../../../images/video.png';

import Mensaje from './Mensaje';

const ex_imagen=['jpg','jpeg','png'];
const ex_doc=['txt','doc','docx','pptx','ppt','xlsx','xls','odt','pdf'];
const ex_video=['mp4'];
const ex_audio=['mp3'];


function ChatUSer(props){

    var classes = useStyles();
    var {usuario_actual,datos_bot,bot_actual} = useChatState();
    var chatDispach=useChatDispatch();
    var { datos } = useUserState();

    const[listaChat, setListaChat] = React.useState("");
    const[limiteChat,setLimiteChat] = React.useState(10);
    const[cargando,setCargando] = React.useState(false);
    const[estaSiendoAtendido,setEstaSiendoAtendido] = React.useState(false);

    useEffect(() =>{

          if(usuario_actual!==""){
            if(props.usuario_actual && props.usuario_actual_chatcenter){
              setEstaSiendoAtendido(true)
            }
            //setear la fecha actual como atributo en el usuario actual
            setCargando(true);
            setListaChat([]);
            ReferenciaActual(chatDispach,db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key));
            let conexion=db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).limitToLast(50)
            .on("value",(snapshot)=>{



              let conversation=[];
              MostrarCargarChat(chatDispach);
              snapshot.forEach(function(data) {
                var contenido = data.val().contenido
                // var nombre = "Nombre sin formato"
                var nombre = "";
                if(contenido[0]=="*"){
                  let temp = obtenerNombreDelEmisor(contenido);
                  nombre=temp.nombre;
                  if(usuario_actual.info.red=='facebook'){
                    contenido=temp.cadena;
                  }else{
                    contenido=temp.cadena.replaceAll('/(\\n)','\n');
                  }
                }


              let esurl=isUrl(contenido.trim());
              let mostrardoc=false;

              if(data.val().tipo_mensaje==="image" || data.val().tipo_mensaje==="audio" ||data.val().tipo_mensaje==="video"  ||data.val().tipo_mensaje==="file" )
              {
                mostrardoc=true;
              }else{
                mostrardoc=esurl.url;
              }
              if(usuario_actual.info.red!='facebook'){
                contenido = contenido.replaceAll('\\n','\n')
              }


              if(data.val().tipo_usuario === "usuario"){
                conversation.push(
                    <ListItem key={data.key} className={classes.mensajeIzquierda}>
                      <Mensaje
                        avatar={usuario_actual.user_name}
                        contenido={contenido.trim()}
                        caption={data.val().caption===undefined?"":data.val().caption}
                        usuario="usuario"
                        fecha={data.val().fecha_hora}
                        tipo={data.val().tipo_mensaje}
                        esUrl={esurl}
                        mostrardoc={mostrardoc}
                       />
                  </ListItem>
                );

              }else if(data.val().tipo_usuario === "bot" || data.val().tipo_usuario === "watson") {
                conversation.push(
                    <ListItem key={data.key} className={classes.mensajeDerecha}>
                      <Mensaje
                        avatar={"Bot"}
                        contenido={contenido.trim()}
                        caption={data.val().caption===undefined?"":data.val().caption}
                        usuario="bot"
                        fecha={data.val().fecha_hora}
                        tipo={data.val().tipo_mensaje}
                        esUrl={esurl}
                        mostrardoc={mostrardoc}
                       />
                  </ListItem>
                );

              }else if(data.val().tipo_usuario === "chatcenter"){
                conversation.push(
                    <ListItem key={data.key} className={classes.mensajeDerecha}>
                      <Mensaje
                        avatar={nombre}
                        contenido={contenido.trim()}
                        caption={data.val().caption===undefined?"":data.val().caption}
                        usuario="chatcenter"
                        fecha={data.val().fecha_hora}
                        tipo={data.val().tipo_mensaje}
                        esUrl={esurl}
                        mostrardoc={mostrardoc}
                        />
                  </ListItem>
                );

              }



              });

              setListaChat(conversation);
              OcultarCargarChat(chatDispach);
              setCargando(false);


            });
          }else{
            setListaChat([]);
            setCargando(false);
          }

        return ()=>{
          //db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
        }

      },[usuario_actual]);


    useEffect(() =>{
    document.getElementById("contenedor-lista-chat")
    .scroll({ top: document.getElementById("contenedor-lista-chat").scrollHeight, behavior: 'auto' });
     },[listaChat]);


    return(
        <Grid container spacing={3}>
            {/* { datos_bot!=="" && usuario_actual!==""  &&
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
              <ListItem className={classes.usuarioActual}>
                <ListItemAvatar>
                    <Avatar className={classes.usuarioActualAvatar} alt={usuario_actual.user_name}>{usuario_actual.user_name.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText className={classes.usuarioActualTitulo} primary={usuario_actual.user_name} secondary={
                  <React.Fragment>
                    <Typography component="span"
                      className={classes.usuarioActualSubTitulo}>
                      {usuario_actual.key}XDDDDD
                    </Typography>
                  </React.Fragment>} />
                </ListItem>
              </Grid>
            </Grid>
            } */}
            <Grid container spacing={3}  alignItems="flex-end" >
                <Grid item xs={12} md={12} >
                  <List id={'contenedor-lista-chat'}
                    className={classes.Chatlist}
                  //  onScroll={loadMoreItems}
                  >
              {cargando &&    <ListItem>
                  <CircularProgress disableShrink />
                  </ListItem>}

                     {listaChat}
                  </List>
                </Grid>

            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <InputMensaje setDisabled_finalizarAtencion={props.setDisabled_finalizarAtencion} />
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.MargenTop2}>
                <Grid item xs={12} md={12}>
                    <MenuBotones/>
                </Grid>
            </Grid>
       </Grid>
    );

}

function getPositionOfString(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

function obtenerNombreDelEmisor(contenido){

  let regex=/\*\[([^\]]+)]\*/;
  let nombre =regex.exec(contenido);
  let cadena= contenido.replace(regex, "")

  return {
    nombre:nombre===null? "" : nombre[1],
    cadena:cadena,
  }
}


function isUrl(s) {


    var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/

    if(regexp.test(s)){ //si es url

      let url=regexp.exec(s);
      if(url===null)
      {

        return {url:false,tipo:""};
      }

      if(url[0].length!==s.length)
      {
        return {url:false,tipo:""};
      }

      let arreglo = s.split("?");
      let indice= arreglo[0].lastIndexOf(".");
      let nombre= arreglo[0].split("/");
      let nombre_par=nombre[nombre.length-1].split('%2F');

      if(nombre_par.length>0)
      {
        nombre=nombre_par;
      }
      if(indice===-1){
        return  {url:true,tipo:0,nombre:nombre[nombre.length-1]};//archivo
      }

      let tipo= arreglo[0].substring(indice+1,arreglo[0].length).toLowerCase();

      let isimagen =ex_imagen.indexOf(tipo);
      if(isimagen!==-1)
      {
          return  {url:true,tipo:1,nombre:nombre[nombre.length-1]};//imagen
      }

      let isdoc=ex_doc.indexOf(tipo);
      if(isdoc!==-1)
      {
          return  {url:true,tipo:2,nombre:nombre[nombre.length-1]};//archivo
      }

      let isvideo=ex_video.indexOf(tipo);
      if(isvideo!==-1)
      {
          return  {url:true,tipo:3,nombre:nombre[nombre.length-1]};//video
      }

      let isaudio=ex_audio.indexOf(tipo);
      if(isaudio!==-1)
      {
          return  {url:true,tipo:4,nombre:nombre[nombre.length-1]};//audio
      }


      return  {url:true,tipo:0,nombre:""};//archivo

    }else{
      return {url:false,tipo:""};
    }
}


export default ChatUSer;
