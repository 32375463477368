import {auth,db,fecha_hora} from "./../firebase/setup";

const log_it = (empresa,usuario,obj) => {
    let ref="/log_usuarios/"+empresa+"/"+usuario+"/";
    db.ref(ref)
    .push(obj,
        function(error) {
            if (error) {
 //               console.log("Error en el log")
 //               console.log(error)
            }
        }
    );
}

export default log_it;