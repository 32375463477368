import React , { useState, useEffect } from 'react';
import {db} from "../../services/firebase";
import { useUserState} from "../../context/UserContext";
import {Grid} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
/*Contexto*/
import {AdminBotProvider,
        useAdminBotDispatch,
        useAdminBotState,
        Asesores} from './contexto/AdminBotContext';
/*Componentes*/
import BotAdmin from "./componenetes/botAdmin";
import EditorBloques from './componenetes/EditoBloques';
import NuevoAsistente from './componenetes/NuevoAsistente'
/*Alertas*/
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Typography } from "../../components/Wrappers";

import SnackMessage from "./SnackMessage";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const LayoutBot = () => {
  const { enqueueSnackbar,closeSnackbar } = useSnackbar();
  var adminBotDispach=useAdminBotDispatch();
  var {bot_actual} =useAdminBotState();
  var { datos,user } = useUserState();


  const [listadoBots,setListadoBots] = React.useState("");
  const [loader,setLoader]=React.useState(false);
  const [arrayBots,setArrayBots] = React.useState([]);



  useEffect(() =>{


    setLoader(true);
    let empresa=db.ref('bots/'+datos.id_empresa).on('value',function(snapshot){

      let lista_bots=[];
      // reccorer el arrayBots
      let arrayB = [];
      snapshot.forEach((item, i) => {
          arrayB[item.key] = item.val();
      });
      setArrayBots(arrayB);
      snapshot.forEach(function(data){
        lista_bots.push(<BotAdmin
          key={"bot_admin_"+data.key}
          id={data.key}
          datos={data.val()}
          array={snapshot.val()}
          eliminar={EliminarAsistente}
        />);
      });
      setListadoBots(lista_bots);
      setLoader(false);
    });

    return ()=>{
      db.ref('bots/'+datos.id_empresa).off("value",empresa);
    }
  },[]);

/*asesores*/
  useEffect(() =>{

    let asesores=db.ref("usuarios/").orderByChild("id_empresa").equalTo(datos.id_empresa.toString()).on("value",function(snapshot){

      let asesores=[];
      snapshot.forEach(function(data){
        asesores.push({
           nombre:data.val().nombre,
           key:data.key
        });
      });
      Asesores(adminBotDispach,asesores);
    });

    return ()=>{
      db.ref("usuarios").off("value",asesores);
    }

  },[]);

  const EliminarAsistente=(id)=>
  {
    if(datos.permisos.abcbot.eliminarbot!==true)
    {
      enqueueSnackbar('Permiso denegado', {variant: 'error'});
      return false;
    }
    const id_mensaje=enqueueSnackbar('Eliminando asistente', {variant: 'info'});

    db.ref("/bots/"+datos.id_empresa+"/"+id)
    .set(null,function(error) {
        if (error) {
            closeSnackbar(id_mensaje);
            enqueueSnackbar('Error al eliminar los datos', {variant: 'error'});
        } else {

            //eliminar bloques y elementos_bloques
            db.ref("/bloques/"+datos.id_empresa+"/facebook/"+id).set(null);
            db.ref("/bloques/"+datos.id_empresa+"/whatsapp/"+id).set(null);
            db.ref("/bloques/"+datos.id_empresa+"/telegram/"+id).set(null);
            db.ref("/bloques/"+datos.id_empresa+"/instagram/"+id).set(null);
            db.ref("/bloques/"+datos.id_empresa+"/webchat/"+id).set(null);
            //eliminar IA
            db.ref("/IA/"+datos.id_empresa+"/"+id).set(null);
            //eliminar llave
            db.ref("/llaves/"+datos.id_empresa+"/facebook/"+id).set(null);
            db.ref("/llaves/"+datos.id_empresa+"/whatsapp/"+id).set(null);
            db.ref("/llaves/"+datos.id_empresa+"/telegram/"+id).set(null);
            db.ref("/llaves/"+datos.id_empresa+"/instagram/"+id).set(null);
            db.ref("/bloques/"+datos.id_empresa+"/webchat/"+id).set(null);
            closeSnackbar(id_mensaje);
            enqueueSnackbar('Asistente eliminado correctamente', {variant: 'success'});

        }
    });
  }

    return (
      <React.Fragment>
       {loader && <LinearProgress />}
       {bot_actual===null &&
        <Grid spacing={2} container >
          {loader===false &&  datos.permisos.abcbot!==undefined && datos.permisos.abcbot.bot===true && <NuevoAsistente arrayBots={arrayBots} index={-1}/>}
          {listadoBots}
        </Grid>
       }

      {bot_actual!==null &&
        <EditorBloques bot_actual={bot_actual}/>
       }

      </React.Fragment>

    );
};

/*----------------------------------------------------------Snackbar Provider*/

const useStyles = makeStyles((theme) => ({
  colorClose: {
    color: "#ffff",
  },
}));


function  EstructuraBot (){

  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
       notistackRef.current.closeSnackbar(key);
  }

    return (
      <AdminBotProvider>
        <SnackbarProvider maxSnack={3}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}

        ref={notistackRef}
      /*  content={(key, message) => (
             <SnackMessage id={key} message={message} options={options}/>
         )}*/
         action={(key) => (
        <IconButton aria-label="delete"  onClick={onClickDismiss(key)}>
          <CloseIcon className={classes.colorClose}/>
        </IconButton>
    )}

        >
          <LayoutBot/>
        </SnackbarProvider>
      </AdminBotProvider>
    );

}

export default EstructuraBot;
