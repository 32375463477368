import { makeStyles } from "@material-ui/styles";
import { lightBlue,red,green, deepPurple } from '@material-ui/core/colors';

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  progressSection: {
    marginBottom: theme.spacing(1)
  },
  progressTitle: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1)
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    justifyContent: "space-between",
    width: "100%"
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  alignStandaloneElement: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "center"
  },
  tableWidget: {
    overflowX: "auto"
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2)
  },
  legendElementText: {
    marginLeft: theme.spacing(1)
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%"
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2)
  },
  serverOverviewElementChartWrapper: {
    width: "100%"
  },
  mainChartBody: {
    overflowX: "auto"
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap"
    }
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3)
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + "80 !important"
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25
  },
  mainChartLegentElement: {
    fontSize: "14px !important",
    marginLeft: theme.spacing(1)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  fixIconRight: {
    "& .MuiSelect-icon": {
      right: 4
    }
  },
  checkboxColor: {
    color: "rgb(185, 185, 185)"
  },
  errorMessage: {
    textAlign: "center",
    color : "#ef4056",
  },
  succesMessage: {
    textAlign: "center",
    color : theme.palette.primary.color
  },
  LeyendaPorcentaje: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 'bolder !important',
    fontSize: "26px !important",
  },
  LeyendaPorcentajeBot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    fontWeight: 'bolder !important',
    fontSize: "16px !important",
  },
  Titulos: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    fontWeight: 'bolder !important',
    fontSize: "20px !important",
    marginTop:"50px",
    marginBottom:"50px",
  },
  centrar:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  rootlist: {
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    paddingLeft:'5%',
    paddingRight:'5%',
    overflowY:"auto",
    overflowX: 'hidden',
    height:'45vh',
    maxHeight:'45vh',
  },
  rootlistItem:{
    "&.Mui-selected, &:hover": {
      backgroundColor: "#CDCED7 !important",
      color: "white"
    }
  },
  rootlistItemAtendido:{
    "&.Mui-selected, &:hover": {
      backgroundColor: "#CDCED7 !important",
      color: "white"
    },
    backgroundColor: "#f6fff5"
  },
  rootlistItemEspera:{
    "&.Mui-selected, &:hover": {
      backgroundColor: "#CDCED7 !important",
      color: "white"
    },
    backgroundColor: "#fff5f5"
  },

  Chatlist: {
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    height:'60vh',
    minHeight: 'calc(100%)'
    // maxHeight:'20vh',
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  ColorButton:{
    borderColor: lightBlue[500],
  },
  StopButtom:{
    borderColor: red[300],
  },
  MargenTop2:{
    marginTop: theme.spacing(2),
  },
  paper:{
    width: "10% !important",
    [theme.breakpoints.only("xs")]: {
      width: "100% !important",
    }
  },
  UserchatCenter:{
    backgroundColor: theme.palette.primary.main
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  usuarioActual:{
    borderBottomColor: theme.palette.primary.light,
    borderBottom: "2px solid",

  },
  usuarioActualTitulo:{
    color: theme.palette.secondary.main,
    fontWeight: 'bolder !important',
  },
  usuarioActualSubTitulo:{
    color: theme.palette.text.primary,
    fontSize:"12px",

  },
  usuarioActualAvatar:{
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),

  },
  listaChatTitulo:{
    color: theme.palette.primary.main,
    fontWeight: 'bolder !important',
  },
  tamanoFiltro:{
    width:"100% !important",
  },
  mensajeDerecha:{
    margin: 'auto',
    display: 'flex',
    outline: 0,
    position: 'relative',
    justifyContent: 'flex-end',
  },
  mensajeIzquierda:{
    margin: 'auto',
    display: 'flex',
    outline: 0,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  navegatorChat:{
    "&.Mui-selected": {
      backgroundColor:  theme.palette.primary.main +"!important",
      color: "white"
    }
  },

  navegatorChatSecondary:{
    "&.Mui-selected": {
      backgroundColor:  theme.palette.secondary.main +"!important",
      color: "white"
    }
  },

  imagenes: {
    width: theme.spacing(25),
    height: theme.spacing(25),
    display:'block',
    margin:'auto',
  },

}));
