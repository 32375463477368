import React,{ forwardRef,useEffect } from 'react';
import MaterialTable from 'material-table';
import { Grid,} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import {Avatar,Typography } from "../../../components/Wrappers";
import useStyles from './styleEmpresas';
import Widget from '../../../components/Widget';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import {useAdminDispatch,OcultarEditar_usuario,useAdminState} from '../contexto/AdminContext'
import {db,funciones_fb} from "../../../services/firebase";
import { useUserState} from "../../../context/UserContext";
import log_it from './../../../services/log/log'

export default function ManagerUser(props) {
  var classes = useStyles();
  var adminDispach=useAdminDispatch();
  var {id_empresa,empresa } = useAdminState();
  var { datos,user } = useUserState();

  const [nombreEmpresa,setNombreEmpresa]=React.useState("");
  const [fotoEmpresa,setFotoEmpresa]= React.useState("");

  const[usuariosChat,setUsuariosChat]= React.useState(0);
  const[usuariosAdmin,setUsuariosadmin]= React.useState(0);

  var[contarAdmin,setContarAdmin]=React.useState(0);
  var[contarChat,setContarChat]=React.useState(0);
  //const lista_empresas = empresa.map((empresa) =>  empresa.id === id_empresa && inicializarDatosEmpresa(empresa.nombre,empresa.foto));

   var[estatus,setStatus]=React.useState(false);
  function inicializarDatosEmpresa(datos)
  {
    if(datos)
    {
      setNombreEmpresa(datos.nombre);
      setFotoEmpresa(datos.foto);
      setUsuariosChat(datos.usuarios_chat);
      setUsuariosadmin(datos.usuarios_admin);
      setContarAdmin(datos.contador_admin);
      setContarChat(datos.contador_chat);
      setStatus(datos.estatus==="activo" ? true:false);
    }

  }

  //cargar datos de usuarios
  useEffect(() =>{

    inicializarDatosEmpresa(empresa.find(item => item.id === id_empresa));
      //let db_usuarios= db.ref("usuarios").orderByChild("id_empresa").equalTo(id_empresa.toString()).once('value').then(function(snapshot){
       let db_usuarios= db.ref("usuarios").orderByChild("id_empresa").equalTo(id_empresa.toString()).on('value',function(snapshot){

         let data=[];
         snapshot.forEach((item, i) => {

           if(item.val().admin===false || datos.admin===true)
           {
               var newData={id_user:item.key,
               nombre:item.val().nombre,
               correo:item.val().correo,
               cuenta:item.val().rol==="admin"?1:2,
               lastauth:"",
           /*    lastauth:item.val().configuraciones.ultima_conexion===""?
                        "":
                        new Date(snapshot.val().configuraciones.ultima_conexion).toLocaleString('es-MX')*/};
             data.push(newData);
           }

         });

           setState((prevState) => {
            return { ...prevState, data };
           });

         });


     return ()=>{
       db.ref("usuarios").off("child_added",db_usuarios);
     }

  },[]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };



  const [state, setState] = React.useState({
    //editable?: ('always' | 'onUpdate' | 'onAdd' | 'never');
    columns: [
    { title: 'id', field: 'id_user',editable: 'never' },
    { title: 'Nombre', field: 'nombre' ,initialEditValue:"nombre",  validate : rowData => rowData.nombre.length >1  ?true : { isValid: false, helperText: 'El nombre no puede esta vacio' } },
    { title: 'Correo', field: 'correo', editable: 'onAdd', initialEditValue:"correo",  validate : rowData =>  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(rowData.correo) ? true :{ isValid: false, helperText: 'El correo no tiene el formato correcto' }  },
    { title: 'Tipo de Cuenta', field: 'cuenta',initialEditValue:1,
      lookup: { 1: 'Administrador', 2: 'Chat center' }
    },
    { title: 'Last Auth', field: 'lastauth',editable: 'never' },
    ],
    data: [

    ],
  });

  var [error,setError]=React.useState(false);
  var [mensajeError,setMensajeError]=React.useState("");

  var [correcto,setCorrecto]=React.useState(false);
  var [mensajeCorrecto,setMensajeCorrecto]=React.useState("usuario creado exitosamente");


  var nuevo_usuario= (newData) =>
      new Promise((resolve) => {
        setError(false);
        setMensajeError("");
        setCorrecto(false);
      /*Comprobar permsisos apra cerar usuarios*/
      if(datos.permisos.usuarios.crear)
      {
        try{


          funciones_fb.httpsCallable("crearUSuarios")({
            empresa_id: id_empresa.toString(),
            correo: newData.correo,
            nombre: newData.nombre,
            tipo:  newData.cuenta===1 || newData.cuenta==='1' ? "admin" : "chat_center"
          }).then(function(result){
              var codigo = result.data.codigo;
              var errorCode= result.data.mensaje;
              if(codigo===1)
              {
                var obj = {
                    fecha: + new Date(),
                    nombre: "crear usuario",
                    id_accion: 211,
                    id_empresa: datos.id_empresa,
                    id_usuario: user.id,
                }
                log_it(datos.id_empresa,user.id,obj)

                setCorrecto("true");
                setMensajeCorrecto("usuario generado correctamente, se envio un correo a la direccion especificada con el password temporal para ingresar a la consola");
                resolve();
              }else{
                if(errorCode === "auth/email-already-exists"){
                  setMensajeError("El correo electronico ingresado ya esta en uso");
                }else if (errorCode === "auth/invalid-email"){
                  setMensajeError("Correo electronico no valido");
                }else if (errorCode === "auth/operation-not-allowed"){
                  setMensajeError("Operacion no permitida");
                }else if (errorCode === "auth/weak-password"){
                  setMensajeError("Error al generar el correo intente de nuevo");
                }else{
                  setMensajeError(errorCode);
                }
                setError(true);
                resolve();
              }

          }).catch(function (error){

            console.log("erro",error);
            setError(true);
            resolve();

          });


        }catch(e)
        {
          setError(true);
          resolve();
          console.log("error",e);
        }


      }else{
        setError(true);
        setMensajeError("No tiene permiso para crear nuevos usuarios");
        resolve();
      }


  });


  var editar_usuario =   (newData, oldData) =>
    new Promise((resolve) => {
      setError(false);
      setMensajeError("");
      setCorrecto(false);

      if(datos.permisos.usuarios.editar){
        funciones_fb.httpsCallable("editarUsuarios")({
          id_empresa: id_empresa.toString(),
          correo: newData.correo,
          nombre: newData.nombre,
          tipo:  newData.cuenta===1 || newData.cuenta==='1' ? "admin" : "chat_center",
          id: newData.id_user
        }).then(function(result){
            var codigo = result.data.codigo;
            var errorCode= result.data.mensaje;

            if(codigo===1)
            {
              var obj = {
                fecha: + new Date(),
                nombre: "editar usuario",
                id_accion: 212,
                id_empresa: id_empresa.toString(),
                id_usuario: user.id,
            }
            log_it(datos.id_empresa,user.id,obj)

            setCorrecto("true");
            setMensajeCorrecto("datos actualizados correctamente");
            resolve();
            setState((prevState) => {
              const data = [...prevState.data];
              data[data.indexOf(oldData)] = newData;
              return { ...prevState, data };
            });

            }else{
              setError(true);
              setMensajeError(errorCode);
              resolve();
            }
        });


      }else{
        setError(true);
        setMensajeError("No tiene permiso para crear nuevos usuarios");
        resolve();
    }

   /* setTimeout(() => {
      resolve();
      if (oldData) {
        setState((prevState) => {
          const data = [...prevState.data];
          data[data.indexOf(oldData)] = newData;
          return { ...prevState, data };
        });
      }
    }, 600);*/
  });

  var eliminar_usuario =(oldData) =>
  new Promise((resolve) => {
    setError(false);
    setMensajeError("");
    setCorrecto(false);

    if(oldData.id_user===user.id)
    {
      setError(true);
      setMensajeError("No se puede eleminar al usuario actual");
      resolve();
    } else{
        if(datos.permisos.usuarios.eliminar){

          funciones_fb.httpsCallable("borrarUsuario")({
            id_empresa: id_empresa.toString(),
            tipo:  oldData.cuenta===1? "admin" : "chat_center",
            id: oldData.id_user
          }).then(function(result){
              var codigo = result.data.codigo;
              var errorCode= result.data.mensaje;

              if(codigo===1) {

                var obj = {
                    fecha: + new Date(),
                    nombre: "eliminar usuario",
                    id_accion: 213,
                    id_empresa: id_empresa.toString(),
                    id_usuario: user.id,
                }
                log_it(datos.id_empresa,user.id,obj)
    

                setCorrecto("true");
                setMensajeCorrecto("Usuario eliminado correctamente");
                resolve();
                setState((prevState) => {
                  const data = [...prevState.data];
                  data.splice(data.indexOf(oldData), 1);
                  return { ...prevState, data };
                });

              }else{
                setError(true);
                setMensajeError(errorCode);
                resolve();
              }
          });


        }else{
          setError(true);
          setMensajeError("No tiene permiso para eliminar usuarios");
          resolve();
      }
    }


/*

    setTimeout(() => {
      resolve();
      setState((prevState) => {
        const data = [...prevState.data];
        data.splice(data.indexOf(oldData), 1);
        return { ...prevState, data };
      });
    }, 600);*/
  });


  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Widget
                disableWidgetMenu
                >
                  <Grid item xs={12}>
                  <Avatar
                      alt="Avatar"
                      variant="square"
                      src={fotoEmpresa}
                      classes={{ root: classes.imagenes }}
                  />
                  </Grid>
                  <Typography  variant="h3" >
                            ID:{id_empresa}
                        </Typography>
                        <Typography  variant="h3">
                            Nombre:{nombreEmpresa}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<KeyboardBackspaceIcon />}
                      onClick={() => OcultarEditar_usuario(adminDispach)}
                    >
                      Regresar
                    </Button>

            </Widget>
          </Grid>

          <Grid item xs={12}>
            <Widget
                disableWidgetMenu
                >
                  <Grid spacing={2} container direction="row" justify="center" >
                  <Grid item xs={12} md={3}>
                     <Typography>Cantidad de usuaruios permitidos</Typography>
                  </Grid>
                </Grid>
              <Grid spacing={2} container direction="row" justify="center" >

                <Grid item xs={12} md={3}>
                    <TextField disabled={true} id="outlined-basic" label="Usuarios Admin" variant="outlined" fullWidth value={usuariosAdmin}/>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField disabled={true}   id="outlined-basic" label="Usuarios Chat center" variant="outlined" fullWidth value={usuariosChat}/>
                </Grid>
                {/*datos.admin === true &&

                  <Grid item xs={12} md={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          size="large"
                        >
                          Guardar
                      </Button>
                  </Grid>*/
                }

              </Grid>
            </Widget>
          </Grid>

          { error &&
            <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end">
                <Grid item xs={12}>
                        <Alert severity="error" onClose={() => {setError(false)}}>Error —{mensajeError}</Alert>
                </Grid>
            </Grid>
            }
          {correcto &&
              <Grid spacing={2} container alignContent="flex-end" alignItems="flex-end">
                    <Grid item xs={12}>
                      <Alert severity="success" onClose={() => {setCorrecto(false)}}>
                        {mensajeCorrecto}
                        </Alert>
                  </Grid>

              </Grid>
            }

          <Grid item xs={12}>
              <MaterialTable
              icons={tableIcons}
              title="Usuarios"
              columns={state.columns}
              data={state.data}
              editable={{

                onRowAdd: datos.permisos.usuarios.crear && estatus ? nuevo_usuario : null/* (newData) =>
                  new Promise((resolve) => {
                  //  setTimeout(() => {
                      resolve();
                      setState((prevState) => {
                        const data = [...prevState.data];
                        data.push(newData);
                        return { ...prevState, data };
                      });
                  //  }, 600);
                  })*/,
                onRowUpdate: datos.permisos.usuarios.crear  && estatus ? editar_usuario : null /*(newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      if (oldData) {
                        setState((prevState) => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }, 600);
                  })*/,
                onRowDelete: datos.permisos.usuarios.eliminar  && estatus ? eliminar_usuario : null/*(oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      setState((prevState) => {
                        const data = [...prevState.data];
                        data.splice(data.indexOf(oldData), 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  })*/
              }}
            />
          </Grid>

        </Grid>
       </Grid>
    </Grid>

  );
}
