import React , { useState, useEffect } from 'react';
import Widget from '../../../components/Widget';
import imagen from '../../../images/imagen.jpg';
import useStyles from './styleEmpresas';
import {Avatar,Typography } from "../../../components/Wrappers";
import Chip from '@material-ui/core/Chip';
import {
    Grid,
  } from "@material-ui/core"; 

import MUIDataTable from "mui-datatables";
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import CardContent from '@material-ui/core/CardContent';
import AgregarIcon from '@material-ui/icons/AddCircle';
import ManagerUser from './ManagerUser';
import {useAdminDispatch,Editar_usuario,inicializar_datos,Editar_empresa} from '../contexto/AdminContext';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useUserState} from "../../../context/UserContext";

import {useAdminState} from '../contexto/AdminContext';
import Alert from '@material-ui/lab/Alert';
import {db,funciones_fb} from "../../../services/firebase";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Slide from '@material-ui/core/Slide';
import Contrato from "./Contrato";
import EditarEmpresa from './EditarEmpresa';
import log_it from './../../../services/log/log'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Empresas(props) {
    var classes = useStyles();
    var adminDispach=useAdminDispatch();
    var { datos,user } = useUserState();   
    var {empresa} = useAdminState();

    /*modal confiramcion*/    
    const [loadDelete,setLoadDElete] = React.useState(false);
    const [error,setError]=React.useState(false);
    const [mensajeError,setMensajeError]=React.useState("");
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setError(false);
        setOpen(true);
      };
    
      //eliminar empresa
      const handleClose = () => {
        setError(false);
              setOpen(false);
      };

      const eliminarEmpresa =() => {
            setError(false);
            setLoadDElete(true);

            var obj = {
                fecha: + new Date(),
                nombre: "deshabilitar empresa",
                id_accion: 203,
                id_empresa: props.id,
                id_usuario: user.id,
            }
            log_it(datos.id_empresa,user.id,obj)
           // console.log("prpos",props.id);
           // console.log("empresa",datos.id_empresa);
            if(props.id.toString() === datos.id_empresa.toString())
            {
                setMensajeError("No es posible eliminar la empresa a la que el usaurio pertenece");
                setLoadDElete(false);
                setError(true)


            }else{
              //deshabilitar empresa
                funciones_fb.httpsCallable("deshabilitarUsuarios")({
                  id_empresa:props.id,
                }).then(function(respuesta){
                   // console.log("respuesta",respuesta.data)
                    setLoadDElete(false);
                    if(respuesta.codigo===0)
                    {
                        setError(true);
                        setMensajeError(respuesta.data.mensaje);
                    }else{
                        inicializar_datos(datos,adminDispach);
                    }
                }).catch(function(error){
                    setLoadDElete(false);
                    setError(true);
                    setMensajeError("Error al realizar la peticion");
                });

            }

      }

      const activarEmpresa =() =>{
          setError(false);
          setLoadDElete(true);
          var obj = {
            fecha: + new Date(),
            nombre: "habilitar empresa",
            id_accion: 204,
            id_empresa: props.id,
            id_usuario: user.id,
        }
        log_it(datos.id_empresa,user.id,obj)
          funciones_fb.httpsCallable("habilitarEmpresa")({
              id_empresa:props.id,
          }).then(function(respuesta){
              //console.log("respuesta",respuesta.data);
              setLoadDElete(false);
              if(respuesta.codigo===0)
              {
                  setError(true);
                  setMensajeError(respuesta.data.mensaje);
              }else{
                  inicializar_datos(datos,adminDispach);
              }
          }).catch(function(erro){
              setLoadDElete(false);
              setError(true);
              setMensajeError("Error al realizar la peticion");
          });


      }

  return (

    <Grid item sm={12} md={6}  lg={3}>
        <Widget
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            disableWidgetMenu
            >
       
            <Grid container  justify="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography className={classes.textoHeadEmpresa} variant="body2" >
                        ID: {props.id}
                    </Typography>
                    <Typography className={classes.textoHeadEmpresa} variant="body2">
                        Nombre: {props.nombre}
                    </Typography>
                    {props.estado==='activo'? <Chip label="Activo" color="primary" /> : <Chip  disabled  label="Inactivo" color="primary" /> }
                    <Avatar
                        alt="Avatar"
                        variant="square"
                        src={props.foto}
                        classes={{ root: classes.imagenes }}
                    /> 

                    <Grid container  alignItems="flex-end" >
                        
                     
                      <Grid item xs={2}>                         
                            <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                <Tooltip title="Informacion de la empresa">
                                    <IconButton aria-label="Editar" onClick={() => Editar_empresa(adminDispach,props.id)}>
                                        <Edit className={classes.iconoEditar}/>                                                   
                                    </IconButton>     
                                </Tooltip>
                            </Typography>                                                    
                        </Grid>
                    

                        { datos.permisos.empresas.eliminar && 
                            <Grid item xs={2}>                           
                                <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"} >
                                    { props.estado==='activo'?
                                        <Tooltip title="Deshabilitar empresa">
                                            <IconButton aria-label="Borrar" onClick={handleClickOpen}>
                                                <CancelIcon className={classes.iconoEliminar}/>
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title="Habilitar Empresa">
                                            <IconButton aria-label="Borrar" onClick={handleClickOpen}>
                                                <CheckCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Typography>                                                      
                            </Grid>  
                        }
                        <Contrato id={props.id} estado={props.estado} />
                        <Grid item xs={6} >
                            <Typography className={classes.alinearDerecha} >                                                            
                                <IconButton aria-label="Borrar" onClick={() => Editar_usuario(adminDispach,props.id)}>
                                    <Typography>
                                        Usuarios
                                    </Typography>
                                    <AccountCircleIcon className={classes.iconoEditar}/>                                                  
                                </IconButton> 
                            </Typography>                                 
                        </Grid>     
                    </Grid> 
                </Grid>                 
            </Grid>  



        </Widget> 

        <div>      
            <Dialog
                TransitionComponent={Transition}
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{ props.estado==='activo'? "¿Desea desactivar la empresa?": "¿Desea activar la empresa?"}</DialogTitle>
                <DialogContent>
                { props.estado==='activo'? 
                    <DialogContentText id="alert-dialog-description">
                        Este proeceso deshabilitiara la emprea "{props.nombre}" id={props.id}, con todos sus usuarios
                    </DialogContentText>
                    :
                    <DialogContentText id="alert-dialog-description">
                       Desea habilitar la empresa "{props.nombre}" id={props.id}, con todos sus usuarios
                    </DialogContentText>
                
                }
                { error && <Alert severity="error">{mensajeError}</Alert>}
                { loadDelete && <LinearProgress />}
                </DialogContent>

                {
                    loadDelete===false &&

                    <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    {
                        props.estado==='activo'?
                            <Button onClick={eliminarEmpresa} color="primary" autoFocus>
                              Deshabilitar
                           </Button>
                        :
                            <Button onClick={activarEmpresa} color="primary" autoFocus>
                               Activar
                            </Button>
                    }
                    </DialogActions>
                }

            </Dialog>
        </div>

              
      
    </Grid>
  );
}