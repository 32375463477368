import React , { useState, useEffect } from 'react';
import Widget from '../../components/Widget';
import useStyles from './styles';
import {
    Grid,
    CircularProgress,
    Fade,
    TextField as Input,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    MenuItem,
    Select,
  } from "@material-ui/core";
import { Button, Typography,Avatar } from "../../components/Wrappers";
import { useUserState,userNewPassword} from "../../context/UserContext";
import {storage,db} from "../../services/firebase";
import Themes from "../../themes"
import { useThemeDispatch } from "../../context/ThemeContext";
import log_it from './../../services/log/log';

const Perfil = () => {

    var classes = useStyles();
    var { user,datos } = useUserState();
    var [avatarImagen, setAvatarImagen] = useState('');
    var [empresa, setEmpresa] = useState('');
    var themeDispatch = useThemeDispatch();
    const [value, setValue] = React.useState(localStorage.getItem("theme"));
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(null);
    var [errordetail, setErrordetail] = useState(null);
    var [passwordValue, setPasswordValue] = useState("");
    var [newpassword, setNewpassword] = useState("");
    var [succsesForget, setSuccsesForget] = useState(false);

    function listado_empresas()
    {
        return new Promise((resolve) =>{

            db.ref('empresas').once('value').then((sanp)=>{
                return sanp.val();
            }).then((arreglo) =>{

                arreglo.forEach((element)=>{
                   // console.log("element",element);
                    db.ref('/usuario_bot/'+element+"/informacion").once('value').then((valores)=>{
                        var info=valores.val();

                            if(info){  
                                setNombreEmpresas( (prevState)=> {
                                    prevState.push(<MenuItem key={info.id} value={{id:info.id,nombre:info.nombre}}>{info.nombre}</MenuItem>);
                                    return prevState;
                                    });   
                            }
                     
                                                         
                       /* if( datos.id_empresa===info.id ){
                            setListaEmpresas({id:info.id,nombre:info.nombre}); 
                        }*/
                    });
                });
                   
            });
                              
            resolve();
        });
    }

    useEffect(() =>{

        let isSubscribed = true;
        if(datos.admin)
        {
           listado_empresas();
        }

          //nombre de la empresa
          var id_empresa='';
          if(localStorage.getItem('userData'))
          {
              let datosUsusario =JSON.parse(localStorage.getItem('userData'));
              setEmpresa(datosUsusario.nombre_empresa);
              id_empresa=datosUsusario.id_empresa;

          }else{
              setEmpresa("Error al obtener el nombre")
          }

         if(localStorage.getItem('theme')==='dark')
         {
            var id_empresa=id_empresa+'/dark.png';
         }else{
            var id_empresa=id_empresa+'/icono.png';
         }
        //imagen de perfil
        var storageRef=storage.ref('empresas/'+id_empresa);
        storageRef.getDownloadURL().then(function(url) {
            if(isSubscribed){
                setAvatarImagen(url);
            }
          
        }).catch(function(error) {
          console.log("ërror al descargar imagen de perfil");
          console.log(error);
        });

        return () => {
            isSubscribed=false;            
           
          }


    },[]);

    const handleChange = (event) => {
        setValue(event.target.value);
        localStorage.setItem("theme", event.target.value);
        if (event.target.value === "default"){themeDispatch(Themes.default);}
        if (event.target.value === "secondary"){themeDispatch(Themes.secondary);}
        if (event.target.value === "dark"){themeDispatch(Themes.dark);}
        var obj = {
            fecha: + new Date(),
            nombre: "cambiar tema perfil",
            id_accion:601
        }
        log_it(datos.id_empresa,user.id,obj)

        /*escribir en la base de datos*/

        db.ref('usuarios/'+user.id+"/configuraciones").update(
            {
                tema:event.target.value
            }
        )

      };
    var [nombreEmpresas,setNombreEmpresas]=useState([]);
    var [listaEmpresas,setListaEmpresas]= useState("0");
    
    var [isLoadingEmpresa,setIsLoadingEmpresa] = useState(false);
    var [errorEmpresa,setErrorEmpresa]=useState(false);
      
    /*Lista de empresas*/
    const abirLista = (event) => {
        setListaEmpresas(event.target.value);
       // console.log('nuevos datos',event.target.value);
      };
    
    const cambiar_empresa= () =>{

        if(listaEmpresas!== '0')
        {
            setIsLoadingEmpresa(true);
            setErrorEmpresa(false);
    
            new Promise((resolve) =>{
                
                db.ref('usuarios/'+user.id).update({
                    "id_empresa":listaEmpresas.id.toString(),
                    "nombre_empresa":listaEmpresas.nombre.toString()
                },function(error) {
                    if (error) {
                      // The write failed...
                      setErrorEmpresa(true);
                      resolve();
                    } else {
                      resolve();
                    }
                  });
                  setIsLoadingEmpresa(false);
    
            }).then(() =>{              
               // window.location.reload(true);                
               //setIsLoadingEmpresa(false);
            });
        }      

     
    }

    return (
        <div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Widget
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu
                    >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>

                                <Grid container spacing={3}  direction={'column'} justify={"space-between"} alignItems={"center"}>
                                        <Grid item xs={12}    >
                                            <Avatar
                                                alt="Avatar"
                                                src={avatarImagen}
                                                classes={{ root: classes.headerIcon }}
                                            />
                                        </Grid>
                                  </Grid>

                                    <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                        <Grid item xs={6}   >
                                            <Typography >
                                            Nombre
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} >
                                            <form className={classes.root} noValidate autoComplete="off">
                                            <Input fullWidth id="nombre" value={user && user.displayName} disabled/>
                                            </form>
                                        </Grid>
                                    </Grid>


                                    <Grid container spacing={3}  justify={"space-between"}  alignItems={"flex-start"}>
                                        <Grid item xs={6}  >
                                            <Typography>
                                                Correo
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6}  >
                                            <form className={classes.root} noValidate autoComplete="off">
                                            <Input fullWidth id="correo" value={user && user.email} disabled/>
                                            </form>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3} justify={"space-between"}  alignItems={"flex-start"}>
                                        <Grid item xs={6}  >
                                            <Typography>
                                                Empresa
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6} >
                                            <form className={classes.root} noValidate autoComplete="off">
                                            <Input fullWidth id="correo" value={empresa} disabled/>
                                            </form>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>

                        </Widget>
                </Grid>


                <Grid item xs={12} >
                    <Widget
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu
                        >
                        <Grid container spacing={3}  style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                    <Grid item xs={12}   >
                                        <Typography variant="h6">
                                                Cambiar Tema
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 1+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                    <Grid item xs={12}    >
                                        <Typography >
                                                Para cambiar el color del tema de la consola administrativa solo selccione el tema deseado en la siguiente lista.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                    <Grid item xs={12}   >
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Tema</FormLabel>
                                                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                                    <FormControlLabel value="default" control={<Radio />} label="Default" />
                                                    <FormControlLabel value="secondary" control={<Radio />} label="Secondary" />
                                                    <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                                                </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        { datos.admin &&
                         
                            <Grid container>
                                <Grid container spacing={3}  style={{marginBottom: 0.5+'em'}}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                            <Grid item xs={12}   >
                                                <Typography variant="h6">
                                                        Cambiar Empresa
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                
                                <Grid container spacing={3} style={{marginBottom: 1+'em'}}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={3}  justify={"space-between"} alignItems={"center"}>
                                            <Grid item xs={12} >                                     
                                            <FormControl variant="outlined" className={classes.centrar}>
                                                <Select
                                                    labelId="demo-simple-select-filled-label-empresas"
                                                    id="demo-simple-select-outlined"
                                                    value={listaEmpresas} 
                                                    onChange={abirLista}
                                                    label="Empresa"
                                                > 
                                                <MenuItem  value="0"> <em>Seleccione una empresa </em></MenuItem>
                                                {nombreEmpresas}
                                                </Select>
                                        </FormControl>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={3}  justify={"space-between"}  alignItems={"flex-start"}>
                                            <Grid item xs={12}  >
                                                <Fade
                                                    in={errorEmpresa}
                                                    style={
                                                    !errorEmpresa ? { display: "none" } : { display: "inline-block" }
                                                    }
                                                >
                                                    <Typography  className={classes.errorMessage}>
                                                    Error al cambiar de empresa, intente nuevamente
                                                    </Typography>
                                                </Fade>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                            <Grid item xs={12}  >
                                            {isLoadingEmpresa ? (
                                                <CircularProgress size={26} className={classes.loginLoader} />
                                                ) : (
                                                <Button 
                                                disabled={
                                                    listaEmpresas==='0' || listaEmpresas.id===datos.id_empresa
                                                }                                           
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={cambiar_empresa}
                                                    >
                                                    Cambiar empresa
                                                </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid> 
                        }

                    </Widget>
                </Grid>


                <Grid item xs={12} >
                    <Widget
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu
                        >
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"}  alignItems={"center"}>
                                    <Grid item xs={12}    >
                                        <Typography variant="h6">
                                                Cambiar Contraseña
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"}  alignItems={"flex-start"}>
                                    <Grid item xs={12} >
                                    <Input
                                        fullWidth
                                        id="password"
                                        label="Contraseña Nueva"
                                        type='password'
                                        onChange={e => setPasswordValue(e.target.value)}
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                    <Grid item xs={12}  >
                                    <Input
                                        fullWidth
                                        id="newpassword"
                                        label="Repetir Contraseña"
                                        type='password'
                                        onChange={e => setNewpassword(e.target.value)}
                                    />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}
                             style={
                                passwordValue === newpassword ? { display: "none" } : { display: "inline-block" }
                             }>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                    <Grid item xs={12}  >
                                        <Typography  className={classes.errorMessage}>
                                            Las contraseñas no coinciden.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}
                             style={
                                succsesForget ? { display: "inline-block" } : { display: "none" }
                             }>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                    <Grid item xs={12}  >
                                        <Typography  className={classes.succesMessage}>
                                           La contraseña se actualizo correctamente.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"}  alignItems={"flex-start"}>
                                    <Grid item xs={12}  >
                                        <Fade
                                            in={error}
                                            style={
                                            !error ? { display: "none" } : { display: "inline-block" }
                                            }
                                        >
                                            <Typography  className={classes.errorMessage}>
                                            Error:{errordetail}
                                            </Typography>
                                        </Fade>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{marginBottom: 0.5+'em'}}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={3}  justify={"space-between"} alignItems={"flex-start"}>
                                    <Grid item xs={12}  >
                                    {isLoading ? (
                                        <CircularProgress size={26} className={classes.loginLoader} />
                                        ) : (
                                        <Button
                                            disabled={
                                                (passwordValue.length === 0 && newpassword.length === 0) || (passwordValue !== newpassword)
                                            }
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            onClick={() =>
                                                {
                                                    userNewPassword(
                                                    passwordValue,
                                                    setIsLoading,
                                                    setError,
                                                    setErrordetail,
                                                    setSuccsesForget
                                                    )
                                                    var obj = {
                                                        fecha: + new Date(),
                                                        nombre: "cambiar contraseña perfil",
                                                        id_accion:600
                                                    }
                                                    log_it(datos.id_empresa,user.id,obj)
                                                }
                                              }
                                            >
                                            Cambiar
                                        </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Widget>
                </Grid>


        </Grid>
    </div>
    );
};

export default Perfil;
