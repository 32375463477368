import React , { useState, useEffect } from 'react';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import BackspaceIcon from '@material-ui/icons/Backspace';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import { useUserState} from "../../../../context/UserContext";

import { useAdminBotDispatch,
    botActual,
    useAdminBotState,
    bloques
} from '../../contexto/AdminBotContext';

import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites,
  mensajeTextoContenidoErrores,

  validarTextoBotonesContenidoEscrito,
  maximaCantidadCaracteresEnBotones,
  mensajeTextoBotonErrores
} from './validaciones'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#dc004e",
    }
  }),
);
function QuickReplies(props){

    const classes = useStyles();
    var { datos,user } = useUserState();
    var adminBotDispach=useAdminBotDispatch();
    var {bot_actual,bloques} =useAdminBotState();
    const [id,setId] = React.useState(0);
    const [enviar,setEnviar]=React.useState("0");
    const [items,setItems]=React.useState([]);
    const [titulo,setTitulo]=React.useState("");
    const [maxIndex,setMaxIndex]=React.useState(0)
    const [deshabilitadoAbajo,setDeshabilitadoAbajo]=React.useState(false)
    const [deshabilitadoArriba,setDeshabilitadoArriba]=React.useState(false)
    const [mensajeCodigoValidacionBoton,setMensajeCodigoValidacionBoton]=React.useState(-1);


    useEffect(() =>{
        setDeshabilitadoAbajo(props.deshabilitadoAbajo)
        setDeshabilitadoArriba(props.deshabilitadoArriba)
        setId(props.id)
        setMaxIndex(props.maxIndex)
        setTitulo(props.title);
        setEnviar((props.enviar_a===""||props.enviar_a===undefined)?"0":props.enviar_a)

      },[props.title,props.arreglo]);

      useEffect(() =>{
          if(bloques!==null)
          {
              let lista_items=[]
              bloques.forEach((element, index, array) => {
                  lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
              });
              setItems(lista_items);
              setEnviar(props.enviar_a===""?"0":props.enviar_a);
          }
        },[bloques]);

    const cambiarEnviar=(event)=>{
        if(event.target.value!==undefined)
        {
            setEnviar(event.target.value);
            props.editar((prevState) => {
              prevState[props.id].enviar_a=event.target.value==="0"?"":event.target.value;
              return prevState;
           });
        }

    }

    const editar=(e)=>{
      // setTitulo(e.target.value);
  //    props.arreglo[props.id].title=e.target.value;
    //  props.editar({id:props.id,title:e.target.value})

    var codigoValidacionBoton = validarTextoBotonesContenidoEscrito(e,e.target.value,bot_actual.conexion)
    setMensajeCodigoValidacionBoton(codigoValidacionBoton)
    if(codigoValidacionBoton<1){
      setTitulo(e.target.value)
    }

      props.editar((prevState) => {
        prevState[props.id].title=e.target.value;
        return prevState;
     });

      if(e.target.value.length===0){props.error(true)}else{props.error(false)}
    }

    const eliminar=()=>{
      props.eliminar({id:props.id})
      /*  props.arreglo.splice(props.id,1);
        props.actualizar();*/

    /*    props.editar((prevState) => {
          prevState.splice(props.id,1);
          return prevState;
       });
         props.actualizar();*/
    }

    return(

        <Grid item xs={12} >
            <Grid spacing={2} container >
                <Grid item xs={12} sm={5}>
                    <TextField  id="outlined-basic"
                                value={titulo}
                                label="Nombre"
                                variant="outlined"
                                onChange={editar}
                                // error={titulo.length===0}
                                // helperText={titulo.length===0?"El campo es obligatorio":""}
                                error={ (mensajeCodigoValidacionBoton != -1)} 
                                helperText={mensajeTextoBotonErrores(mensajeCodigoValidacionBoton)}
                                inputProps={{
                                  maxLength:maximaCantidadCaracteresEnBotones
                                }}
                                fullWidth
                                disabled={datos.permisos.abcbot.bloques!==true}
                                />
                </Grid>
                <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                    <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined-quil"
                        value={enviar}
                        onClick={cambiarEnviar}
                        label="Enviar"
                    >
                        <MenuItem value="0">
                            <em>None</em>
                        </MenuItem>
                        {items}
                    </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4} sm={1}>
                  {props.deshabilitadoAbajo?
                    <IconButton
                          disabled={props.deshabilitadoAbajo}
                          aria-label="Hacia abajo"
                          onClick={()=>{props.mover(id,false)}}
                          >
                      <KeyboardArrowDown />
                    </IconButton>
                  :
                  <Tooltip title="Bajar posición de respuesta">
                    <IconButton
                          disabled={props.deshabilitadoAbajo}
                          aria-label="Hacia abajo"
                          onClick={()=>{props.mover(id,false)}}
                          >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Tooltip>
                  }
                  
                </Grid>
                <Grid item xs={4} sm={1}>
                  {props.deshabilitadoArriba?
                    <IconButton
                    disabled={props.deshabilitadoArriba}
                    aria-label="Hacia arriba"
                    onClick={()=>{props.mover(id,true)}}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                  :
                  <Tooltip title="Subir posición de respuesta">
                    <IconButton
                    disabled={props.deshabilitadoArriba}
                    aria-label="Hacia arriba"
                    onClick={()=>{props.mover(id,true)}}
                    >
                      <KeyboardArrowUp />
                    </IconButton>
                  </Tooltip>
                  }
                </Grid>
              {props.payload!==undefined && props.payload==="cancelar" ?
                <Grid item xs={4} sm={1}>
                  <Tooltip title="Borrar">
                    <IconButton
                          aria-label="Borrar"
                          className={classes.colorDelete}
                          >
                      <BlockIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                :
              datos.permisos.abcbot.bloques===true &&
                <Grid item xs={4} sm={1}>
                  <Tooltip title="Borrar">
                    <IconButton
                          aria-label="delete"
                          className={classes.colorDelete}
                          onClick={eliminar}
                          >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                            }

            </Grid>
        </Grid>
);


}

export default QuickReplies;
