import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ChatIcon from '@material-ui/icons/Chat';
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

import {useAdminBotState} from '../../../contexto/AdminBotContext'
import { useUserState} from "../../../../../context/UserContext";
import { Typography } from "../../../../../components/Wrappers";
import Asesores from './../asesores';
import {db} from "../../../../../services/firebase";
import DeleteElementos from '../../DeleteElementos';
import DialogElement from './../dialogElement';

import {
  validarTextoMensajeContenidoEscrito,
  mensajeTextoLongitudLimites
  ,mensajeTextoContenidoErrores
} from './../validaciones'

import log_it from './../../../../../services/log/log'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",

    }
  }),
);

export function Webchat_ChatCenter(props){

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    var { datos,user } = useUserState();
    var {bot_actual,bloques} =useAdminBotState();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);


    /*variables de datos*/
    const [mensaje,setMensaje]=React.useState("");
    const [espera,setEspera]=React.useState("");
    const [asesor,setAsesor]=React.useState(false);
    const [respuestaPositiva,setRespuestaPositiva]= React.useState("");
    const [respuestaNegativa,setRespuestaNegativa]= React.useState("");
    const [listaAsesores,setListaAsesores]=React.useState([]);
    const [arrayAsesores,setArrayAsesores]=React.useState([]);

    const [idRespuestaPositiva,setIdRespuestaPositiva]= React.useState(-1);
    const [idRespuestaNegativa,setIdRespuestaNegativa]= React.useState(-1);
    const [items,setItems]=React.useState([]);
    const [enviar,setEnviar]=React.useState("0");
    const [url,setUrl]=React.useState("");

    const [mensajeCodigoValidacion,setMensajeCodigoValidacion]=React.useState(-1);


    useEffect(() =>{
      /*Guardar nuevo elemento*/
      if(props.abrir)
      {
        setOpen(true);
      }
    },[]);


    /*inicializar vaiables*/
    useEffect(() =>{

        if(props.datos)
        {
          var mod_mensaje = props.datos.mensaje;
          var mod_mensajeError = props.datos.mensaje_espera;

          setMensaje(mod_mensaje);
          setEspera(mod_mensajeError);
          setAsesor(props.datos.asignar_asesor);


          props.datos.opciones.forEach((element,index,array) =>{
            if(element.respuesta)
            {
                setRespuestaPositiva(element.texto);
                setIdRespuestaPositiva(index);
            }else{
                setEnviar(element.enviar_a===undefined?"0":element.enviar_a);
                setRespuestaNegativa(element.texto);
                setIdRespuestaNegativa(index);
            }

          });
          /*asesores*/
          if(props.datos.asesores){
            let lista_asesores=[];
            props.datos.asesores.forEach((element,index,array) =>{
              lista_asesores.push(element);
            });
            setListaAsesores(lista_asesores);
          }
        }

      },[open]);
    useEffect(() =>{
      if(bloques!==null)
      {
          let lista_items=[]
          bloques.forEach((element, index, array) => {
              lista_items.push(  <MenuItem key={"item"+props.id+"_"+index} value={element}>{element}</MenuItem>);
          });
          setItems(lista_items);
      }
    },[bloques]);

    const cambiarAsesor=(event)=>{
        setAsesor(event.target.checked)
    }

    const cambiarEnviar=(event)=>{
      if(event.target.value!==undefined)
      {
          setEnviar(event.target.value);
      }
    }
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      if(props.id_elemento===-1)
      {
        setOpen(false);
        props.cerrarElmento();
      }
      setOpen(false);
    };


    const Guardar=()=>{
      setCargando(true);

      if(asesor && arrayAsesores.length===0){
        setCargando(false);
        enqueueSnackbar('Debe de existir almenos un asesor', {variant: 'error'});
        return false;
      }

      let temp=[];
      arrayAsesores.forEach((item, i) => {
        temp.push(item.key)
      });
      var mod_mensaje = mensaje;
      var mod_mensajeEspera = espera;

      if(props.id_elemento===-1){
        setCargando(false);
        setOpen(false);

        enqueueSnackbar('Guardando nuevo elemento', {variant: 'info'});
        props.GuardarNuevoElemento({
          asesores:temp,
          asignar_asesor:asesor,
          elemento:"chatcenter",
          mensaje:mod_mensaje,
          mensaje_espera:mod_mensajeEspera,
          opciones:[
              {respuesta:true,texto:respuestaPositiva},
              {respuesta:false,enviar_a:enviar==="0"?"":enviar,texto:respuestaNegativa}
            ],

        },props.new_index);

      }else{
         db.ref("/bloques/"+datos.id_empresa+"/"+bot_actual.conexion+"/"+bot_actual.id+"/"+props.id_bloque+"/elementos/"+props.id_elemento)
          .set({
            asesores:temp,
            asignar_asesor:asesor,
            elemento:"chatcenter",
            mensaje:mod_mensaje,
            mensaje_espera:mod_mensajeEspera,
            opciones:[
                {respuesta:true,texto:respuestaPositiva},
                {respuesta:false,enviar_a:enviar==="0"?"":enviar,texto:respuestaNegativa}
              ],

            },function(error) {
              if (error) {
                  setCargando(false);
                  enqueueSnackbar('Error al guardar los datos', {variant: 'error'});
              } else {
                  setCargando(false);
                  enqueueSnackbar('Guardado exitoso', {variant: 'success'});
                  setOpen(false);
                  var obj = {
                    fecha: + new Date(),
                    nombre: "modificar elemento",
                    id_accion: 132,
                    id_bot: bot_actual.id,
                    red_social: bot_actual.conexion,
                    bloque:props.id_bloque,
                    elemento:"chatcenter",
                  }
                  log_it(datos.id_empresa,user.id,obj)
              }
          });
      }

    }

    return(
        <React.Fragment>

        <DialogElement
          id_elemento={props.id_elemento}
          moverElementos={props.moverElementos}
          moverMasuno={props.MoverMasuno}
          moverMenosuno={props.MoverMenosuno}
          icono={<ChatIcon/>}
          metodoAbrir={handleClickOpen}
          nombre={props.nombre}
          tamanio={props.tamanio}
          dialogOpen={open}
          dialogClose={handleClose}
          cargando={cargando}
          disabledSave={ mensaje.length===0 || respuestaPositiva.length===0 || respuestaPositiva.length>20
                              || respuestaNegativa.length===0 || respuestaNegativa.length>20
                        }
          guardar={Guardar}
          eliminar={props.eliminar}
          cerrar={setOpen}
        >

                        <Grid item xs={12}>
                            <TextField
                                id="ElTexto_mensaje"
                                value={mensaje}
                                // onChange={e => setMensaje(e.target.value)}
                                onChange={
                                  (e)=>{
                                    /*Para hacerlo mas legible*/
                                    var codigoValidacion = validarTextoMensajeContenidoEscrito(e,e.target.value,bot_actual.conexion)
                                    setMensajeCodigoValidacion(codigoValidacion)
                                    if(codigoValidacion<1){
                                      setMensaje(e.target.value)
                                    }
                                  }
                                }
                                inputProps={{
                                  maxLength:mensajeTextoLongitudLimites[bot_actual.conexion]
                                }}

                                label="Mensaje"
                                variant="outlined"
                                fullWidth
                                multiline
                                disabled={datos.permisos.abcbot.bloques!==true}
                                // error={mensaje.length===0}
                                // helperText={mensaje.length===0?"El campo es obligatorio":""}
                                error={ mensajeCodigoValidacion != -1}
                                helperText={mensajeTextoContenidoErrores(mensajeCodigoValidacion,bot_actual.conexion)}

                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="ElTexto_mensaje"
                                label="Mensaje de espera"
                                variant="outlined"
                                fullWidth
                                multiline
                                value={espera}
                                disabled={datos.permisos.abcbot.bloques!==true}
                                onChange={e => setEspera(e.target.value)}
                                />
                        </Grid>


                        <Grid item xs={12}>
                            <Grid spacing={2} container >

                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch color="primary" />}
                                        label="Asignar asesor"
                                        labelPlacement="top"
                                        checked={asesor}
                                        onChange={cambiarAsesor}
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid spacing={2} container >
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Respuesta positiva"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                        value={respuestaPositiva}
                                        onChange={e => setRespuestaPositiva(e.target.value)}
                                        error={respuestaPositiva.length===0 || respuestaPositiva.length>20}
                                        helperText={respuestaPositiva.length===0?"El campo es obligatorio":
                                                      respuestaPositiva.length>20?"La longitud no puede ser mayor a 20 caracteres":""}
                                        />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Respuesta negativa"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        disabled={datos.permisos.abcbot.bloques!==true}
                                        value={respuestaNegativa}
                                          error={respuestaNegativa.length===0 || respuestaNegativa.length>20}
                                        onChange={e => setRespuestaNegativa(e.target.value)}
                                        helperText={respuestaNegativa.length===0?"El campo es obligatorio":
                                                      respuestaNegativa.length>20?"La longitud no puede ser mayor a 20 caracteres":""}
                                        />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControl variant="outlined" className={classes.formControl} disabled={datos.permisos.abcbot.bloques!==true}>
                                      <InputLabel id="demo-simple-select-outlined-label">Enviar</InputLabel>
                                      <Select
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined-quil"
                                          value={enviar}
                                          onClick={cambiarEnviar}
                                          label="Enviar"
                                      >
                                          <MenuItem value="0">
                                              <em>None</em>
                                          </MenuItem>
                                          {items}
                                      </Select>
                                  </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12}>
                            <Typography>Asesores</Typography>
                        </Grid>

                        {asesor && <Asesores lista={listaAsesores} editar={setArrayAsesores}/>}

              </DialogElement>

        </React.Fragment>
       );

}
