import React, { useState ,useEffect,Component} from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {Button,  Typography, Link } from '../../../components/Wrappers';
import { withStyles } from "@material-ui/core/styles";
import useStyles from '../styles';
import { lightBlue,red,green, deepPurple } from '@material-ui/core/colors';
import {useChatState,
        useChatDispatch,
        MostrarAtributios,
        AtenderUsuario,
        CerarSalirChat,
        AbrirSalirChat} from '../context/ChatContext';
import { useUserState} from "../../../context/UserContext";
import {db,fecha_hora} from "../../../services/firebase";

const ColorButton = withStyles((theme) => ({
    root: {
      //color: theme.palette.getContrastText(purple[500]),
      borderColor: lightBlue[500],
    },
  }))(Button);
  const StopButton = withStyles((theme) => ({
    root: {
      //color: theme.palette.getContrastText(purple[500]),
      borderColor: red[300],
    },
  }))(Button);

  
function MenuBotones ()
{
    var classes = useStyles();
    var chatDispach=useChatDispatch();
    var {usuario_actual,datos_bot,bot_actual} = useChatState();
    var { datos } = useUserState();

    const mostrarAtributos=()=>{
        //console.log("mostrar atributos");
          if(usuario_actual!=="" && datos_bot!=="")
          {
            MostrarAtributios(chatDispach);
          }
    }

    const IniciarChat=()=>{

      if(usuario_actual!=="" && datos_bot!=="")
      {
        db.ref("cliente_chat/"+datos.id_empresa+"/telegram/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
          chatcenter:true,
          fecha_chatcenter:fecha_hora.database.ServerValue.TIMESTAMP
        });

        AtenderUsuario(chatDispach);
      }
    };

    const MostrarSalir=()=>{

      if(usuario_actual!=="" && datos_bot!=="")
      {
        if(datos_bot[usuario_actual.key].atributos.chatcenter)
        {   
          //console.log("mostrar salir");
          AbrirSalirChat(chatDispach);
        }     
      }
      
    }

    return(
        <ButtonGroup size="small" aria-label="small outlined button group">
        <Button  color={"yellow"} onClick={mostrarAtributos}>Atributos</Button>
        <StopButton onClick={MostrarSalir} >Detener Chat</StopButton>
        <ColorButton onClick={IniciarChat} >Iniciar Chat</ColorButton>
      </ButtonGroup>
    );
}

export default MenuBotones;