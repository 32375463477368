import React, { useState ,useEffect,useCallback ,Component} from 'react'
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    List,
    Box,
    MenuItem,
    Grid
} from '@material-ui/core'
import {Button,  Typography, Link } from '../../../components/Wrappers';
import useStyles from '../styles';
import { useTheme, makeStyles } from '@material-ui/styles';
import { lightBlue,red,green, deepPurple } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import {useChatState,
        useChatDispatch,
        GetConversation,
        OcultarCargarDatos,
        AumentarLimite,
        CambiarIndexUsuarios,
        CambiarIndexChat,
        AumentarLimiteChat,
        AumentarLimiteUsuario,
      } from '../context/ChatContext'
import TabPanel from './TabPanel';
import Chip from '@material-ui/core/Chip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import InstagramIcon from '@material-ui/icons/Instagram';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Icon from '@material-ui/core/Icon';
import SmsIcon from '@material-ui/icons/Sms';
import {db, fecha_hora} from '../../../services/firebase';
import { useUserState} from "../../../context/UserContext";
import LanguageIcon from '@material-ui/icons/Language';
import log_it from './../../../services/log/log'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/Error';

export default function ListaUsuarios(props) {

    var classes = useStyles();
    var {datos_bot,
        bot_actual,
        cargado_datos,
        usuario_actual,
        estado_usuario_chatcenter,
        datos_chat,
        index_listaUsuarios,
        index_listaChat,
        limite_chat,
        limite_usuario,
      } = useChatState();
    var chatDispach=useChatDispatch();
    var { datos,user } = useUserState();

    const [datosClientes,setDatosClientes] = React.useState("");
    const [verDatos,setVetDatos]=React.useState(false);
    const [buscarChat,setBuscarChat] = React.useState([]);
  //  const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [value, setValue] = React.useState(0);

    const [limite,setLimite]= React.useState(20);
    const [limiteChat,setLimiteChat] = React.useState(20);
    function compare(a, b) {

      let aa=0;
      let bb=0;
      if (a[1].atributos.chatcenter===true){aa=1;}

      if (b[1].atributos.chatcenter===true){bb=1;}
      //a es menor que b según criterio de ordenamiento
      if (bb<aa) {
        return -1;
      }
      //a es mayor que b según criterio de ordenamiento
      if (bb>aa) {
        return 1;
      }
      // a debe ser igual b
      return 0;
    }



/*
    useEffect(() =>{

      //if(datos_bot !=="") {setDatosClientes(JSON.stringify(datos_bot));}
      if(datos_bot!="")
      {
        console.log("datos",datos_bot);
        setVetDatos(true);
      //  const arreglo = Object.entries(datos_bot);//.sort(compare);

          let lista_usuaruios=[];
          let buscar=[];

            datos_bot.map(item => {
              var key = item[0];
              var dato = item[1];
                lista_usuaruios.push(
                <ListItem alignItems="flex-start" button key={key+'fbau'}
                    selected={selectedIndex === key}
                    onClick={(event, index) =>{
                         setSelectedIndex(key);
                          GetConversation(chatDispach,{key:key,user_name:dato.atributos.fb_username});
                        }
                          } >
                    <ListItemAvatar>
                      <Avatar alt={dato.atributos.fb_username}
                      src={dato.atributos.fb_picture} />
                    </ListItemAvatar>
                    <ListItemText className={classes.listaChatTitulo} id={key} primary={dato.atributos.fb_username} secondary={
                    <React.Fragment>
                      <Typography component="span"
                         className={classes.usuarioActualSubTitulo} >
                         {dato.info.red==="facebook" && <FacebookIcon style={{color: "#4867aa"}} />}
                         {dato.info.red==="telegram" && <TelegramIcon style={{color: "#279fd3"}}/>}
                         {dato.info.red==="whatsapp" && <WhatsAppIcon style={{color: "#25cd60"}}/>}
                        [{dato.info.nombre}]
                        <br/>
                        {key}<br/>{dato.atributos.chatcenter && dato.atributos.fecha_chatcenter?new Date(dato.atributos.fecha_chatcenter).toLocaleString('es-MX'):""}
                      </Typography>
                    </React.Fragment>} />
                    <ListItemSecondaryAction>

                    {dato.atributos.chatcenter?
                    <Grid
                    container
                    spacing={2}
                    direction="column"

                    >
                      <Grid
                      item>
                        <Chip color="primary" size="small" label={dato.atributos.asesor_name} />
                      </Grid>

                      <Grid
                      item>
                        <Chip color="secondary" size="small" label="Chat center" />
                      </Grid>

                    </Grid>
                    :
                    null
                    }
                    </ListItemSecondaryAction>
                </ListItem>);

                buscar.push({nombre:dato.atributos.fb_username,
                            fecha_ingreso:dato.atributos.fecha_chatcenter?new Date(dato.atributos.fecha_chatcenter).toLocaleString('es-MX'):"",
                            key:key,
                            chatcenter:dato.atributos.chatcenter===true? true:false});
          });
          setVetDatos(false);
          setBuscarChat(buscar);
          OcultarCargarDatos(chatDispach);
          setDatosClientes(lista_usuaruios);
    }else{
      setDatosClientes("");
      setBuscarChat([]);
    }

    },[datos_bot]);

*/
    const cambiar_actual=(event, newValue) =>{

      if(newValue!=null)
      {

        if(usuario_actual!==""){db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();}

        if(newValue.dato.info.red==="facebook"){GetConversation(chatDispach,{id:newValue.id,
                                                                            key:newValue.user_id ,
                                                                            user_name:newValue.dato.atributos.fb_username,
                                                                            chatcenter:newValue.dato.atributos.chatcenter,
                                                                            info:newValue.dato.info,
                                                                            asesor:newValue.dato.atributos.asesor_id===undefined? "":newValue.dato.atributos.asesor_id,
                                                                            log_chatcenter:newValue.dato.atributos.log_chatcenter===undefined? "":newValue.dato.atributos.log_chatcenter
                                                                          });}
        if(newValue.dato.info.red==="telegram"){GetConversation(chatDispach,{id:newValue.id,
                                                                             key:newValue.user_id ,
                                                                             user_name:newValue.dato.atributos.tg_nombre,
                                                                             tg_usermane:newValue.dato.atributos.tg_usermane,
                                                                             chatcenter:newValue.dato.atributos.chatcenter,
                                                                             info:newValue.dato.info,
                                                                             asesor:newValue.dato.atributos.asesor_id===undefined? "":newValue.dato.atributos.asesor_id,
                                                                             log_chatcenter:newValue.dato.atributos.log_chatcenter===undefined? "":newValue.dato.atributos.log_chatcenter
                                                                           });}
        if(newValue.dato.info.red==="whatsapp"){GetConversation(chatDispach,{id:newValue.id,
                                                                            key:newValue.user_id,
                                                                            user_name:newValue.dato.atributos.ws_username,
                                                                            chatcenter:newValue.dato.atributos.chatcenter,
                                                                            info:newValue.dato.info,
                                                                            asesor:newValue.dato.atributos.asesor_id===undefined? "":newValue.dato.atributos.asesor_id,
                                                                            log_chatcenter:newValue.dato.atributos.log_chatcenter===undefined? "":newValue.dato.atributos.log_chatcenter
                                                                          });}
        if(newValue.dato.info.red==="instagram"){GetConversation(chatDispach,{id:newValue.id,
                                                                            key:newValue.user_id,
                                                                            user_name:newValue.dato.atributos.ig_username,
                                                                            chatcenter:newValue.dato.atributos.chatcenter,
                                                                            info:newValue.dato.info,
                                                                            asesor:newValue.dato.atributos.asesor_id===undefined? "":newValue.dato.atributos.asesor_id,
                                                                            log_chatcenter:newValue.dato.atributos.log_chatcenter===undefined? "":newValue.dato.atributos.log_chatcenter
        });}

        if(newValue.dato.info.red==="chatweb"){GetConversation(chatDispach,{id:newValue.id,
                                                                            key:newValue.user_id,
                                                                            user_name:newValue.dato.atributos.wb_username,
                                                                            chatcenter:newValue.dato.atributos.chatcenter,
                                                                            info:newValue.dato.info,
                                                                            asesor:newValue.dato.atributos.asesor_id===undefined? "":newValue.dato.atributos.asesor_id,
                                                                            log_chatcenter:newValue.dato.atributos.log_chatcenter===undefined? "":newValue.dato.atributos.log_chatcenter
        });}

      }

    }

    const handleListItemClick = (event, index) => {
      props.setSeleccionar(index);
    };

    const ChangeIndexChat =(event,index)=>{
      CambiarIndexChat(chatDispach,index);
    }


    const ChangeIndexUser =(event,index)=>{
      CambiarIndexUsuarios(chatDispach,index);

    }

    //Aumentar usuarios no chatcenter
    const Aumentar = ()=>{
      AumentarLimiteUsuario(chatDispach);
    }

    //Aumtnera usuarios chat center
    const AumentarChat = ()=>{
      AumentarLimiteChat(chatDispach);
    }

    const convertDate = (ultimo_msj) => {
      var unix_timestamp = ultimo_msj
      var date = new Date(unix_timestamp );
      // Hours part from the timestamp
      var hours = date.getHours();
      // Minutes part from the timestamp
      var minutes = "0" + date.getMinutes();
      // Seconds part from the timestamp
      var seconds = "0" + date.getSeconds();
      var formattedTime = date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear() + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) ;
      return formattedTime
    }

    const EstadoAtendido = (atributos) =>{
      if(atributos.acceso_asesor === undefined){
        return classes.rootlistItem;
      }else if( atributos.acceso_asesor === atributos.ultima_fecha){
        return classes.rootlistItemAtendido;
      }else{
        return classes.rootlistItemEspera;
      }
    }

    return(
      <React.Fragment>
        {verDatos && <CircularProgress />}

       { /*--------------------------------------Buscar en el chatcenter---------------------------------*/
         estado_usuario_chatcenter===0 &&
              <Autocomplete
                  fullWidth
                  id="free-solo-demo"
                  loading={cargado_datos}
                  getOptionSelected={(option, value) => option.dato.info.red === "facebook"?   option.dato.atributos.fb_username === value.dato.atributos.fb_username :
                  option.dato.info.red === "whatsapp"?  option.dato.atributos.ws_username === value.dato.atributos.ws_username :
                  option.dato.info.red === "instagram"?  option.dato.atributos.ig_username === value.dato.atributos.ws_username :
                  option.dato.info.red === "telegram"? option.dato.atributos.tg_nombre === value.dato.atributos.tg_nombre :
                          option.dato.atributos.wb_username === value.dato.atributos.wb_username
                                    }
                  onChange={cambiar_actual}
                  getOptionLabel={(option) => option.dato.info.red === "facebook"?   option.dato.atributos.fb_username === undefined ? '' : option.dato.atributos.fb_username  :
                  option.dato.info.red === "whatsapp"?  option.dato.atributos.ws_username === undefined ? '' : option.dato.atributos.ws_username :
                  option.dato.info.red === "instagram"?  option.dato.atributos.ig_username === undefined ? '' : option.dato.atributos.ig_username :
                  option.dato.info.red === "telegram"? option.dato.atributos.tg_nombre  === undefined ? '' : option.dato.atributos.tg_nombre :
                            option.dato.atributos.wb_username === undefined ? '' : option.dato.atributos.wb_username
                                  }
                  options={datos_chat}
                  renderInput={(params) => (
                    <TextField {...params} label="Buscar" margin="normal" variant="outlined" />
                  )}
                />
          }


          { /*--------------------------------------Buscar en la lista chat---------------------------------*/
             estado_usuario_chatcenter===1 &&
                 <Autocomplete
                     fullWidth
                     id="free-solo-demo"
                     loading={cargado_datos}
                     getOptionSelected={(option, value) => option.dato.info.red === "facebook"?   option.dato.atributos.fb_username === value.dato.atributos.fb_username :
                                                            option.dato.info.red === "whatsapp"?  option.dato.atributos.ws_username === value.dato.atributos.ws_username :
                                                            option.dato.info.red === "telegram"?  option.dato.atributos.tg_nombre === value.dato.atributos.tg_nombre:
                                                            option.dato.info.red === "instagram"?  option.dato.atributos.ig_username === value.dato.atributos.ig_username:
                                                                option.dato.atributos.wb_username === value.dato.atributos.wb_username
                                       }
                     onChange={cambiar_actual}
                     getOptionLabel={(option) => option.dato.info.red === "facebook"?   option.dato.atributos.fb_username === undefined ? '' : option.dato.atributos.fb_username  :
                     option.dato.info.red === "whatsapp"?  option.dato.atributos.ws_username === undefined ? '' : option.dato.atributos.ws_username :
                     option.dato.info.red === "instagram"?  option.dato.atributos.ig_username === undefined ? '' : option.dato.atributos.ig_username :
                     option.dato.info.red === "telegram"? option.dato.atributos.tg_nombre  === undefined ? '' : option.dato.atributos.tg_nombre :
                               option.dato.atributos.wb_username === undefined ? '' : option.dato.atributos.wb_username
                                     }
                     options={datos_bot}
                     renderInput={(params) => (
                       <TextField {...params} label="Buscar" margin="normal" variant="outlined" />
                     )}
                  />
             }


         <List className={classes.rootlist}

         >

           {
             estado_usuario_chatcenter===0 && datos_chat.map((item,index) => (
             index<limite_chat && (
             <ListItem alignItems="flex-start" button key={item.id}
                 className={EstadoAtendido(item.dato.atributos)}
                 selected={index_listaChat === item.id}
                 onClick={(event,newValue) => {
                    // console.log(item)
                    // console.log(datos_chat)

                       ChangeIndexChat(event, item.id);
                       //cerrar el escuchador anterior
                       if(usuario_actual!==""){
                         db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();
                         //setear la variable acceso asesor al usuario anterior
                         let index_ultima_actual =  datos_chat.findIndex(item => item.id === usuario_actual.id);
                         if(index_ultima_actual !== -1)
                         {
                           db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/acceso_asesor").set(datos_chat[index_ultima_actual].dato.atributos.ultima_fecha);
                         }
                       }



                        //setear la variable acceso asesor cuando se seleccione el nuevo usuario
                        db.ref("cliente_chat/"+datos.id_empresa+"/"+item.dato.info.red+"/"+item.dato.info.bot+"/"+item.user_id+"/atributos/acceso_asesor").set(item.dato.atributos.ultima_fecha);


                       if(item.dato.info.red==="facebook"){GetConversation(chatDispach,{id:item.id,
                                                                                        key:item.user_id ,
                                                                                        user_name:item.dato.atributos.fb_username,
                                                                                        chatcenter:item.dato.atributos.chatcenter,
                                                                                        info:item.dato.info,
                                                                                        asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                        log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                                                                                      });
                                                                                      }
                       if(item.dato.info.red==="telegram"){GetConversation(chatDispach,{id:item.id,
                                                                                        key:item.user_id ,
                                                                                        user_name:item.dato.atributos.tg_nombre,
                                                                                        tg_usermane:item.dato.atributos.tg_usermane,
                                                                                        chatcenter:item.dato.atributos.chatcenter,
                                                                                        info:item.dato.info,
                                                                                        asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                        log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                                                                                      });
                                                                                      }
                        if(item.dato.info.red==="whatsapp"){GetConversation(chatDispach,{id:item.id,
                                                                                        key:item.user_id ,
                                                                                        user_name:item.dato.atributos.ws_username,
                                                                                        chatcenter:item.dato.atributos.chatcenter,
                                                                                        info:item.dato.info,
                                                                                        asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                        log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                                                                                      });}
                        if(item.dato.info.red==="instagram"){GetConversation(chatDispach,{id:item.id,
                                                                                        key:item.user_id ,
                                                                                        user_name:item.dato.atributos.ws_username,
                                                                                        chatcenter:item.dato.atributos.chatcenter,
                                                                                        info:item.dato.info,
                                                                                        asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                        log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                        });}

                        if(item.dato.info.red==="webchat"){GetConversation(chatDispach,{id:item.id,
                                                                                        key:item.user_id ,
                                                                                        user_name:item.dato.atributos.wb_username,
                                                                                        chatcenter:item.dato.atributos.chatcenter,
                                                                                        info:item.dato.info,
                                                                                        asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                        log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                        });}

                 }
                 }
              >
                 <ListItemAvatar>
                   <Avatar alt={item.dato.atributos.fb_username}/>
                 </ListItemAvatar>
                 <ListItemText className={classes.listaChatTitulo} id={item.id} primary={item.dato.info.red==="facebook"?
                                                                                            item.dato.atributos.fb_username :
                                                                                              item.dato.info.red==="whatsapp"?
                                                                                                item.dato.atributos.ws_username:
                                                                                                item.dato.info.red === "telegram"?
                                                                                                 item.dato.atributos.tg_nombre:
                                                                                                 item.dato.info.red === "instagram"?
                                                                                                  item.dato.atributos.ig_username:
                                                                                                      item.dato.atributos.wb_username
                                                                                              }
                 secondary={
                   <React.Fragment>

                        {item.dato.info.red==="webchat" && <LanguageIcon style={{color: "#054874"}} />}
                        {item.dato.info.red==="facebook" && <FacebookIcon style={{color: "#4867aa"}} />}
                        {item.dato.info.red==="telegram" && <TelegramIcon style={{color: "#279fd3"}}/>}
                        {item.dato.info.red==="whatsapp" && <WhatsAppIcon style={{color: "#25cd60"}}/>}
                        {item.dato.info.red==="instagram" && <InstagramIcon style={{
                            colorIg:{
                              borderRadius: '8px',
                              border:'2px',
                              background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                              color:"#FAFAFA"
                            }
                        }}/>}
                        {item.dato.atributos.chatcenter && <SmsIcon color="secondary" size="small"/>}
                       [{item.dato.info.nombre} ]
                       <br/>
                       {item.dato.atributos.chatcenter && item.dato.atributos.asesor_name!==undefined && <Typography
                                                                                                            component="span"
                                                                                                            variant="body2"
                                                                                                            className={classes.inline}
                                                                                                            color="secondary"
                                                                                                          >
                                                                                                            Asesor:{item.dato.atributos.asesor_name}
                                                                                                          </Typography>}
                       <br/>
                       {item.dato.atributos.chatcenter && item.dato.atributos.fecha_chatcenter?new Date(item.dato.atributos.fecha_chatcenter).toLocaleString('es-MX'):""}
                   </React.Fragment>
                  } />
                {item.dato.atributos.acceso_asesor !== undefined &&
                  <ListItemSecondaryAction>
                    { item.dato.atributos.acceso_asesor === item.dato.atributos.ultima_fecha ?
                      <Tooltip title="Cliente atendido">
                          <IconButton>
                              <CheckCircleOutlineIcon style={{ color: "#19b533"}}/>
                          </IconButton>
                      </Tooltip>
                    :
                      item.id !== index_listaChat ?
                        <Tooltip title="Mensajes nuevos">
                            <IconButton>
                                <ErrorIcon style={{ color: "#fec565"}} />
                            </IconButton>
                        </Tooltip>
                        :
                        null
                    }
                  </ListItemSecondaryAction>
                }



             </ListItem>
           )
           ))}


           {
             estado_usuario_chatcenter===1 && datos_bot.map((item,index) => (

             index<limite_usuario && (
             <ListItem alignItems="flex-start" button key={item.id}
                 className={classes.rootlistItem}
                 selected={index_listaUsuarios === index}
                 onClick={(event,newValue) => {
                    var user_name = ""
                     ChangeIndexUser(event, index);
                     if(usuario_actual!==""){db.ref('conversaciones/'+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key).off();}
                     if(item.dato.info.red==="facebook"){GetConversation(chatDispach,{id:item.id,
                                                                                      key:item.user_id ,
                                                                                      user_name:item.dato.atributos.fb_username,
                                                                                      chatcenter:item.dato.atributos.chatcenter,
                                                                                      info:item.dato.info,
                                                                                      asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                      log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                                                                                    });
                                                                                    user_name = item.dato.atributos.fb_username;
                                                                                    }
                     if(item.dato.info.red==="telegram"){GetConversation(chatDispach,{id:item.id,
                                                                                      key:item.user_id ,
                                                                                      user_name:item.dato.atributos.tg_nombre,
                                                                                      tg_usermane:item.dato.atributos.tg_usermane ,
                                                                                      chatcenter:item.dato.atributos.chatcenter,
                                                                                      info:item.dato.info,
                                                                                      asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                      log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                                                                                    });
                                                                                    user_name = item.dato.atributos.tg_nombre;
                      }
                      if(item.dato.info.red==="whatsapp"){GetConversation(chatDispach,{id:item.id,
                                                                                      key:item.user_id ,
                                                                                      user_name:item.dato.atributos.ws_username,
                                                                                      chatcenter:item.dato.atributos.chatcenter,
                                                                                      info:item.dato.info,
                                                                                      asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                      log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                      });
                      user_name = item.dato.atributos.ws_username;
                      }
                      if(item.dato.info.red==="instagram"){GetConversation(chatDispach,{id:item.id,
                                                                                      key:item.user_id ,
                                                                                      user_name:item.dato.atributos.ig_username,
                                                                                      chatcenter:item.dato.atributos.chatcenter,
                                                                                      info:item.dato.info,
                                                                                      asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                      log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                      });
                      user_name = item.dato.atributos.ig_username;
                    }

                      if(item.dato.info.red==="webchat"){GetConversation(chatDispach,{id:item.id,
                                                                                      key:item.user_id ,
                                                                                      user_name:item.dato.atributos.wb_username,
                                                                                      chatcenter:item.dato.atributos.chatcenter,
                                                                                      info:item.dato.info,
                                                                                      asesor:item.dato.atributos.asesor_id===undefined? "":item.dato.atributos.asesor_id,
                                                                                      log_chatcenter:item.dato.atributos.log_chatcenter===undefined? "":item.dato.atributos.log_chatcenter
                      });
                      user_name = item.dato.atributos.wb_username;
                    }
                    var obj = {
                      fecha: + new Date(),
                      nombre: "supervisar conversación",
                      id_accion: 401,
                      id_bot: item.dato.info.bot,
                      red_social: item.dato.info.red,
                      usuario:item.user_id,
                      nombre_usuario:user_name === undefined ? '' : user_name
                    }

                    log_it(datos.id_empresa,user.id,obj)
                    //cerrar el escuchador anterior
//                     console.log(item)
//                    console.log(usuario_actual)


                 }
                 }
              >
                 <ListItemAvatar>
                   <Avatar alt={item.dato.atributos.fb_username}/>
                 </ListItemAvatar>
                 <ListItemText className={classes.listaChatTitulo} id={item.id} primary={item.dato.info.red==="facebook"?
                                                                                            item.dato.atributos.fb_username :
                                                                                              item.dato.info.red==="whatsapp"?
                                                                                                item.dato.atributos.ws_username:
                                                                                                item.dato.info.red === "telegram"?
                                                                                                 item.dato.atributos.tg_nombre:
                                                                                                 item.dato.info.red === "instagram"?
                                                                                                    item.dato.atributos.ig_username:
                                                                                                    item.dato.atributos.wb_username
                                                                                              }
                 secondary={
                   <React.Fragment>
                     <Typography component="span"
                        className={classes.usuarioActualSubTitulo} >
                        {item.dato.info.red==="webchat" && <LanguageIcon style={{color: "#054874"}} />}
                        {item.dato.info.red==="facebook" && <FacebookIcon style={{color: "#4867aa"}} />}
                        {item.dato.info.red==="telegram" && <TelegramIcon style={{color: "#279fd3"}}/>}
                        {item.dato.info.red==="whatsapp" && <WhatsAppIcon style={{color: "#25cd60"}}/>}
                        {item.dato.info.red==="instagram" && <InstagramIcon style={{
                            colorIg:{
                              borderRadius: '8px',
                              border:'2px',
                              background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                              color:"#FAFAFA"
                            }
                        }}/>}
                        {item.dato.atributos.chatcenter && <SmsIcon color="secondary" size="small"/>}
                       [{item.dato.info.nombre}]
                       <br></br>
                       <Typography component="span" variant="body2"
                           color="textPrimary" >
                          <br/>
                          {/* {(item.dato.atributos.ultima_fecha==0 ||item.dato.atributos.ultima_fecha===undefined)?null:
                       convertDate(item.dato.ultima_fecha)
                       } */}
                       {
                         item.dato.atributos.ultima_fecha==0?
                          null
                         :
                          convertDate(item.dato.atributos.ultima_fecha)

                       }
                        </Typography>


                     </Typography>
                   </React.Fragment>
                  } />
             </ListItem>
           )
           ))}


          {   estado_usuario_chatcenter===1 ?

            limite_usuario< datos_bot.length &&(
               <ListItem alignItems="flex-start" button alignItems="flex-start"   onClick={Aumentar}>
                 <ListItemText primary="Cargar mas..." />
               </ListItem>
              )
             :

             limite_chat < datos_chat.length &&(
               <ListItem alignItems="flex-start" button alignItems="flex-start"   onClick={AumentarChat}>
                <ListItemText primary="Cargar mas..." />
               </ListItem>
            )

           }

         </List>
      </React.Fragment>

  );
}
