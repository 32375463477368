import React , { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import {Grid} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from "../../../components/Wrappers";
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useAdminBotState} from '../contexto/AdminBotContext'
import { useUserState} from "../../../context/UserContext";
import {db} from "../../../services/firebase";
import {useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { DropzoneArea } from 'material-ui-dropzone';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TelegramIcon from '@material-ui/icons/Telegram';
import Checkbox from '@material-ui/core/Checkbox';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import log_it from './../../../services/log/log';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
    },
    colorDelete:{
        color:"#ffff",
        backgroundColor:"#dc004e",
    },
    textoDelete:{
        color:"#dc004e",
    },
    iconoEliminar:{
      color:"#CD5C5C"
    },

  }),
);


export default function EliminarAsistente(props){

    const classes = useStyles();
    var {bot_actual,bloques} =useAdminBotState();
    var { datos,user } = useUserState();
    const { enqueueSnackbar } = useSnackbar();

    /*abrir y cerrar modal*/
    const [open, setOpen] = React.useState(false);
    const [cargando,setCargando] =React.useState(false);






    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };


    /*Guardar Cambios*/
    const GuardarCambios=()=>{
      //setCargando(true);
      var obj = {
        fecha: + new Date(),
        nombre: "eliminar asistente",
        id_accion: 103,
        id_bot: props.index,
      }
      log_it(datos.id_empresa,user.id,obj)
      props.eliminar(props.index)
    }


    return(
        <React.Fragment>
          <Typography className={classes.textoHeadEmpresa} variant="body2" weight={"bold"}>
              <Tooltip title="Eliminar bot" >
                <span>
                    <IconButton
                        aria-label="delete"
                        className={classes.iconoEliminar}
                        onClick={handleClickOpen}
                        disabled={datos.permisos.abcbot.eliminarbot!==true}
                      >
                        <DeleteIcon fontSize="large" />

                    </IconButton>
                </span>
              </Tooltip>
          </Typography>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
                  <DialogTitle id="form-dialog-title">Eliminar Asistente<DeleteIcon/></DialogTitle>

                <DialogContent dividers>


                <Grid spacing={3} container >



                <Grid item xs={12}>
                    <Typography >Esta seguro que desea eliminar el asistente: </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant={"h5"}>{"nombre:"+props.nombre+"  ID:"+props.index}</Typography>
                </Grid>





                </Grid>
                </DialogContent>
               {cargando ?
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                : <DialogActions>
                    <Button onClick={GuardarCambios} color="secondary"
                    >
                        Eliminar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                </DialogActions>}

            </Dialog>

        </React.Fragment>
       );

}
