import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {IconButton} from '@material-ui/core';

import IconDoc from '../../../images/documentoss.png';
import IconNon from '../../../images/documento.png';
import IconMusic from '../../../images/musica.png';
import IconVideo from '../../../images/video.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles({
  root: {
    maxWidth: "80%",
    boxShadow: "none",
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function Mensaje(props) {
  const classes = useStyles();


  return (
    <React.Fragment>
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Box fontSize="fontSize" fontWeight="fontWeightBold" m={1}> {"["+props.avatar+"]"} </Box>
        {
          props.tipo==="like"?
          <FavoriteIcon style={{color: "#d20056"}} fontSize="large" />
          :

          props.tipo==="image"?
            <img src={props.contenido} style={{maxWidth:100}}/>
          :
              props.tipo==="audio"?
              <audio controls>
                <source src={props.contenido} />
                    Tu navegador no soporta audio HTML5.
              </audio>
             :
                props.tipo==="video"?
                <video style={{maxWidth:150}} controls>
                  <source src={props.contenido} />
                </video>
                :

                  props.tipo==="file"?
                      <img src={IconNon} style={{maxWidth:100}}/>
                  :

                    props.esUrl.url?


                        props.esUrl.tipo===1?
                        <img src={props.contenido} style={{maxWidth:100}}/>
                        :

                        props.esUrl.tipo===2?
                        <React.Fragment>
                          <img src={IconDoc} style={{maxWidth:100}}/>
                          <Typography variant="body2" component="p">
                            {props.esUrl.nombre}
                          </Typography>
                        </React.Fragment>

                        :
                        props.esUrl.tipo===3?
                        <video style={{maxWidth:150}} controls>
                          <source src={props.contenido} />
                        </video>
                        :
                        props.esUrl.tipo===4?
                        <audio controls>
                          <source src={props.contenido} />
                              Tu navegador no soporta audio HTML5.
                        </audio>
                        :
                          <React.Fragment>
                            <img src={IconNon} style={{maxWidth:100}}/>
                            <Typography variant="body2" component="p">
                              {props.esUrl.nombre}
                            </Typography>
                          </React.Fragment>


                    :

                    <Typography style={{whiteSpace:'pre-line'}}>
                      {props.contenido}
                    </Typography>

        }
        <Typography variant="body2" component="p">
          {props.tipo!=="like" && props.caption}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.fecha}
        </Typography>
      </CardContent>
    </Card>

    {props.mostrardoc &&
      <IconButton href={props.contenido} target="_blank">
      <VisibilityIcon />
    </IconButton>}

    </React.Fragment>
  );
}
