import React , { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useUserState} from "../../../../context/UserContext";
import {Grid} from "@material-ui/core";
import DeleteElementos from '../DeleteElementos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { makeStyles } from '@material-ui/core/styles';
import {useAdminBotState} from '../../contexto/AdminBotContext'
import ListItem from '@material-ui/core/ListItem';
import {Droppable, Draggable} from "react-beautiful-dnd"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import EditIcon from '@material-ui/icons/Edit';

import log_it from './../../../../services/log/log'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow:'none',
  },
  menuitem:{
    fontSize: '0.8rem',
  },
  progreso:{
    color:"#ffff"
  },
  formControl: {
    width: "100%",
  },
  borderItem:{
        /*boxShadow: '1px 1px 5px #999',*/
        paddingTop: '15px',
        paddingBottom:'15px',
        marginBottom:'10px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.secondary.main,
        color:"white",

     },
}));

export default function DialogElement(props)
{
  var { datos,user } = useUserState();
  const classes = useStyles();
  var {bot_actual,bloques,lista_bloques,bloque_actual} =useAdminBotState();
  const [progreso,setProgreso] = React.useState(false);

  const [bloque,setBloque] = React.useState("0");
  const [cargando,setCargando] = React.useState(false);
  const [open,setOpen] = React.useState(false);
  const [cargarDuplicar,setCargarDuplicar] = React.useState(false);
/*
  id_elemento
  moverElemento
  icono(Elemento)
  Abrir(onClick)
  Nombre
  tamanio
  moverMasuno
  moverMenosuno
  variable open
  oncloce metodo
  disabled guardar

*/

  const handleClickOpenDialog = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const SeleccionarBloque = (e) =>{
    if(e!==null)
    {
      setBloque(e.target.value);
    }
  }

  const DuplicarEn =()=>{
        if(lista_bloques[bloque]!=undefined){
        setCargarDuplicar(true);
        props.moverElementos.duplicarEn(props.id_elemento,lista_bloques[bloque],setOpen,setCargarDuplicar);
        //console.log(lista_bloques[bloque])
        var obj = {
          fecha: + new Date(),
          nombre: "crear elemento",
          id_accion: 131,
          id_bot: bot_actual.id,
          red_social: bot_actual.conexion,
          bloque:lista_bloques[bloque].id,
          elemento:props.nombre
        }
        log_it(datos.id_empresa,user.id,obj)
          
      }
    }

  const Movermenosuno=()=>{
      setAnchorEl(null);
    props.moverMenosuno(props.id_elemento);
  }

  const MoverMasuno=()=>{
      setAnchorEl(null);
    props.moverMasuno(props.id_elemento);
  }

  const DuplicarElemento=()=>{
      setAnchorEl(null);
    props.moverElementos.duplicar(props.id_elemento);
    var obj = {
      fecha: + new Date(),
      nombre: "crear elemento",
      id_accion: 131,
      id_bot: bot_actual.id,
      red_social: bot_actual.conexion,
      bloque:bloque_actual.nombre,
      elemento:props.nombre
    }
    log_it(datos.id_empresa,user.id,obj)
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const EditarElemento = ()=>{
    setAnchorEl(null);
    props.metodoAbrir();
  }

return (

  <React.Fragment>
  <Draggable draggableId={"componente_bloqueActual_"+props.id_elemento} index={props.id_elemento} isDragDisabled={!datos.permisos.abcbot.bloques}>
  {
    (draggableProvided)=>(

        <ListItem className={classes.borderItem}
        {...draggableProvided.draggableProps}
        ref={draggableProvided.innerRef}
        {...draggableProvided.dragHandleProps}>
                        <ListItemIcon style={{color:"#ffff"}}>
                          {props.icono}
                        </ListItemIcon>
                        <ListItemText primary={"["+props.id_elemento+"] "+props.nombre} />
                          <ListItemSecondaryAction>
                           <IconButton  style={{color:"#ffff"}} edge="end" aria-label="delete" onClick={handleClick}>
                             <ArrowDropDownIcon />
                           </IconButton>
                         </ListItemSecondaryAction>
        </ListItem>
    )
  }
    </Draggable>

    <Menu
         disableAutoFocusItem
         id="simple-menu"
         anchorEl={anchorEl}
         keepMounted
         open={Boolean(anchorEl)}
         onClose={handleClose}
         classes={{paper:classes.paper}}
       >
         <MenuItem className={classes.menuitem}  onClick={EditarElemento}><EditIcon fontSize={"small"}/>Editar</MenuItem>
         <MenuItem className={classes.menuitem} disabled={!datos.permisos.abcbot.bloques} onClick={DuplicarElemento}><CallSplitIcon fontSize={"small"}/>Duplicar</MenuItem>
         <MenuItem className={classes.menuitem} disabled={!datos.permisos.abcbot.bloques} onClick={handleClickOpenDialog}><CallSplitIcon fontSize={"small"}/>Duplicar en</MenuItem>
       </Menu>

  <Dialog open={props.dialogOpen} onClose={props.dialogClose} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
      <DialogTitle id="form-dialog-title">Elemento:{props.nombre}{props.icono}</DialogTitle>
      <DialogContent dividers>
        <Grid spacing={3} container >
        {props.id_elemento !==-1 &&
              <Grid item xs={12}>
                <DeleteElementos eliminar={props.eliminar} cerrar={props.cerrar} id_elemento={props.id_elemento} />
            </Grid>
        }
        {props.children}
        </Grid>


      </DialogContent>
     {props.cargando ?
          <DialogActions>
              <CircularProgress />
          </DialogActions>
      :

      datos.permisos.abcbot.bloques===true?
      <DialogActions>
          <Button onClick={props.guardar} color="secondary"
                disabled={props.disabledSave}
          >
              Guardar
          </Button>
          <Button onClick={props.dialogClose} color="primary">
              Cancelar
          </Button>
      </DialogActions>
      :
      <DialogActions>
          <Button onClick={props.dialogClose} color="primary">
              Cerrar
          </Button>
      </DialogActions>

    }

  </Dialog>

{/*Dialogo duplicar en */}

<Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth={"lg"}>
    <DialogTitle id="form-dialog-title">Duplicar Elemento:{props.nombre}{props.icono}</DialogTitle>
    <DialogContent dividers>
      <Grid spacing={3} container >
            <Grid item xs={12}>
            <FormControl  variant="outlined" className={classes.formControl} >
            <InputLabel id="demo-simple-select-outlined-label">Bloque</InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={bloque}
                onClick={SeleccionarBloque}
                label="Validacion"
            >
                <MenuItem value={{nombre:"0"}}>
                    <em>None</em>
                </MenuItem>
              {
                bloques.map((item, i) => (

                  <MenuItem key={"item_duplicar_en"+item} name={item} value={i}>
                      {item}
                  </MenuItem>
                ))
              }
            </Select>
            </FormControl>
          </Grid>
      </Grid>


    </DialogContent>

    { cargarDuplicar?
      <DialogActions>
          <CircularProgress />
      </DialogActions>
      :
      <DialogActions>
        <Button onClick={DuplicarEn} color="secondary" disabled={bloque==="0"?true:false}>
          Duplicar
        </Button>
        <Button onClick={handleCloseDialog} color="primary">
            Cancelar
        </Button>
    </DialogActions>
  }
</Dialog>

  </React.Fragment>)



}
