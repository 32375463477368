import React, { useState ,useEffect,Component} from 'react';

import {
    NativeSelect,
    InputLabel,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    FormControl,
} from '@material-ui/core'
import {Button,  Typography, Link } from '../../../components/Wrappers';
import useStyles from '../styles';
import {useChatState,useChatDispatch,CerarSalirChat,DetenerAtenederUsuario} from '../context/ChatContext';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useUserState} from "../../../context/UserContext";
import {db,fecha_hora} from "../../../services/firebase";
import {mensaje_whatsapp,mensaje_facebook,mensaje_telegram,mensaje_instagram,mensaje_webchat} from "../../../services/automata";
import Alert from '@material-ui/lab/Alert';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function SalirChatCenter (){

    var classes = useStyles();
    var {usuario_actual,datos_bot,salir_chat,bot_actual,datos_chat} = useChatState();
    var chatDispach=useChatDispatch();
    var { datos,user } = useUserState();

    const [opcion,setOpcion]= React.useState("10");
    const [cargado,setCargando]= React.useState(false);
    const [error,setError]=React.useState(false);
    const [simular,setSimular]= React.useState(true);
    const [reiniciarVar, setReiniciarVar] = React.useState(false);

    const CambiarReiniciarVar = (event) =>{
        setReiniciarVar(event.target.checked);
    }

    const CambiarSimularMensaje=(event)=>{
      setSimular(event.target.checked);
    }

    const CambiarValor= (event)=>{
      if(event.target.value==="10"){setSimular(true);setReiniciarVar(false)}
      if(event.target.value==="20"){setSimular(false);}
        setOpcion(event.target.value);
    }

    const handleCloseChat=()=>{
        CerarSalirChat(chatDispach);
    }


    function simularMensaje(opcion){

      if(usuario_actual!=="")
      {  setError(false);

        handle_ChatcenterActualizarTerminarAtencion(
          user,
          datos,
          usuario_actual===""?"":JSON.stringify(usuario_actual),
        );



        db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos").update({
          chatcenter:false,
          acceso_asesor: null
        });
        if(opcion==="20"){
          db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/user_posicion").update({
            bloque:"default",
            posicion:0
          });
        }

        if(!simular)
        {
            DetenerAtenederUsuario(chatDispach);
            ReiniciarVariables();
            CerarSalirChat(chatDispach);
             setCargando(false);

        }else{

          if(usuario_actual.info.red === "facebook")
          {
              //metodo de envio de mensaje
              fetch(mensaje_facebook+'?bot='+usuario_actual.info.bot+'&id='+datos.id_empresa, {
                method: 'POST',
                body: JSON.stringify({
                  "entry": [
                    {
                      "messaging": [
                        {
                          "message": {
                            "mid": datos.id_empresa ,
                            "text": "nextElement"
                          },
                          "sender": {
                            "id": usuario_actual.key
                          }
                        }
                      ]
                    }
                  ]
                }),
              }).then((response) => {
                    return response;
              }).then((empleados) => {
                    DetenerAtenederUsuario(chatDispach);
                    setCargando(false);
                    ReiniciarVariables();
                    CerarSalirChat(chatDispach);
              }).catch( (error)=>{
                    setError(true);
                    setCargando(false);
              });
          }else if(usuario_actual.info.red === "whatsapp"){

              fetch(mensaje_whatsapp+'?bot='+usuario_actual.info.bot+'&id='+datos.id_empresa, {
                method: 'POST',
                body: JSON.stringify({
                  "payload": {
                    "id": "AUTOMATADIGITal-aVIBOTwhats2",
                    "payload": {
                      "text": "nextElement"
                    },
                    "sender": { // esto esta en cliente chat el id del cliente aparece con el codigo de pais y el numero
                      "country_code": "502",
                      "dial_code": usuario_actual.key.substring(3),
                      "name": usuario_actual.user_name,
                      "phone": usuario_actual.key,
                    },
                    "source": usuario_actual.key,
                    "type": "text"
                  },
                  "timestamp": fecha_hora.database.ServerValue.TIMESTAMP,
                  "type": "message",
                  "version": 2
                }), // data can be `string` or {object}!
              }).then((response) => {
              //   console.log('responmse',response);
                    return response;
                  })
                  .then((empleados) => {


                    DetenerAtenederUsuario(chatDispach);
                    setCargando(false);
                    ReiniciarVariables();
                    CerarSalirChat(chatDispach);
                  }).catch( (error)=>{
                    setError(true);
                    setCargando(false);
                  });

          }else if(usuario_actual.info.red === "instagram"){

            fetch(mensaje_instagram+'?bot='+usuario_actual.info.bot+'&id='+datos.id_empresa, {
              method: 'POST',
              body: JSON.stringify( {'entry': [{'id': '97118105',
                          'messaging': [{'message': {'mid': '97118105',
                                                     'text': 'nextElement'},
                                         'recipient': {'id': '97118105'},
                                         'sender': {'id': usuario_actual.key},
                                         'timestamp': fecha_hora.database.ServerValue.TIMESTAMP}],
                          'time': fecha_hora.database.ServerValue.TIMESTAMP}],
               'object': 'instagram'}
             ), // data can be `string` or {object}!
            }).then((response) => {
            //   console.log('responmse',response);
                  return response;
                })
                .then((empleados) => {


                  DetenerAtenederUsuario(chatDispach);
                  setCargando(false);
                  ReiniciarVariables();
                  CerarSalirChat(chatDispach);
                }).catch( (error)=>{
                  setError(true);
                  setCargando(false);
                });

          }else if(usuario_actual.info.red === "telegram"){ ///Telegram
                        let name = usuario_actual.user_name.split(" ");
                        let firstname = (name[0]!=null?name[0]:"");
                        let lastname = (name[1]!=null?name[1]:"");
                  fetch(mensaje_telegram+'?info='+usuario_actual.info.bot+','+datos.id_empresa, {
                          method: 'POST',
                          body: JSON.stringify({
                            "message":{
                                "chat":{
                                    "first_name": firstname,
                                    "id": usuario_actual.key,
                                    "last_name": lastname,
                                    "username": usuario_actual.tg_username
                                  },
                                  "text": "nextElement"
                            }
                          }), // data can be `string` or {object}!
                        }).then((response) => {
                          //console.log('responmse',response);
                              return response;
                            })
                            .then((empleados) => {


                              DetenerAtenederUsuario(chatDispach);
                              setCargando(false);
                              ReiniciarVariables();
                              CerarSalirChat(chatDispach);
                            }).catch( (error)=>{
                              //console.log("error al enviar",error);
                              setCargando(false);
                              setError(true);
                            });


          }else{ //chatweb

            fetch(mensaje_webchat+'?bot='+usuario_actual.info.bot+'&id='+datos.id_empresa, {
              method: 'POST',
              body: JSON.stringify(
                {
                    "msg": [
                        {
                            "id": "AV1ADW3BCH4T",
                            "messaging": [
                                {
                                    "message": {
                                        "type": "text",
                                        "text": "nextElement"
                                    },
                                    "user": {
                                        "id":  usuario_actual.key
                                    }
                                }
                            ],
                            "date": fecha_hora.database.ServerValue.TIMESTAMP,
                        }
                    ]
                }
            ),
            }).then((response) => {
                  return response;
            }).then((empleados) => {
                  DetenerAtenederUsuario(chatDispach);
                  setCargando(false);
                  ReiniciarVariables();
                  CerarSalirChat(chatDispach);
            }).catch( (error)=>{
                  setError(true);
                  setCargando(false);
            });

          }



        }//fin else simular
      }
    }

    const EjecutarAccion=()=>{

        if(usuario_actual!=="")
        {
          //continuar con el flujo normal del bot
          if(opcion==="10")
          {
           /*   setCargando(true);
              db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
                chatcenter:false
              });*/
              setCargando(true);
              simularMensaje(opcion);
            //  DetenerAtenederUsuario(chatDispach);

          }
           //enviar al inicio del flujo
           //user position 0 bloque default
          if(opcion==="20")
          {
           /* setCargando(true);
            db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
              chatcenter:false
            });

            db.ref("cliente_chat/"+datos.id_empresa+"/facebook/"+bot_actual+"/"+usuario_actual.key+"/atributos").update({
              bloque:"default",
              posicion:0
            });
          */
              setCargando(true);
              simularMensaje(opcion);
             // DetenerAtenederUsuario(chatDispach);

          }

        }


    }

    function handle_ChatcenterActualizarTerminarAtencion(user,datos,usuario_act){


        return new Promise(function (fulfill, reject){

          if(usuario_act==="")
          {
            fulfill();
            return 0;
          }

          let usuario_actual=JSON.parse(usuario_act);
          if(usuario_actual.log_chatcenter === "")
          {
            fulfill();
            return 0;
          }


          db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).once('value').then(function(snap){

            return snap.val();
          }).then(function (log_chatcenter_reg){

            let historial=[];
            if(log_chatcenter_reg===null){

              fulfill();
              return 0;
            }
            if('historial' in log_chatcenter_reg){
              historial=log_chatcenter_reg.historial;
            }

            historial.push({
              fecha_ini:log_chatcenter_reg.fecha_usuario,
              fecha_fin:fecha_hora.database.ServerValue.TIMESTAMP,
              usuario:log_chatcenter_reg.usuario,
              nombre:log_chatcenter_reg.nombre
            })

            db.ref("log_chatcenter/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.log_chatcenter).update({
              estado: false,
              usuario: null,
              nombre: null,
              fecha_usuario: null,
              fecha_fin:fecha_hora.database.ServerValue.TIMESTAMP,
              historial:historial
            });

            db.ref("cliente_chat/"+datos.id_empresa+"/"+usuario_actual.info.red+"/"+usuario_actual.info.bot+"/"+usuario_actual.key+"/atributos/").update(
              {asesor_id:null,
                asesor_name:null,
                log_chatcenter:null,
                fecha_chatcenter:null,
              });
              fulfill();
          });


        });
    }

    const ReiniciarVariables = () => {
      if(reiniciarVar){
            let formData = new FormData();
            formData.append("empresa",datos.id_empresa);
            formData.append("bot", usuario_actual.info.bot);
            formData.append("red", usuario_actual.info.red);
            formData.append("userid", usuario_actual.key);
            fetch('https://bots.automatadg.com/servicio/restart_var', {
              method: 'POST',
              body:formData
            }).then((response) => {
                console.log('respuesta');
            }).catch( (error)=>{
                console.log("error al borrar las variables");
            });
      }
    };

    return(

        <Dialog open={salir_chat} maxWidth={'sm'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Iniciar Chat</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccione una de las opciones para regresar al usuario al flujo del chat.
            </DialogContentText>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">Acción</InputLabel>
                <Select
                native
                value={opcion}
                onChange={CambiarValor}
                label="accion"
                fullWidth
                >
                <option value={"10"}>Continuar el flujo del chat</option>
                <option value={"20"}>Mover al inicio del flujo</option>
                </Select>
                <FormControlLabel
                  control={
                      <Switch
                        name="checkedB"
                        color="primary"
                        checked={simular}
                        onChange={CambiarSimularMensaje}
                      />
                  }
                 label="Simular mensaje"
                 />
                 <FormControlLabel
                   control={
                       <Switch
                         name="Reiniciar"
                         color="primary"
                         checked={reiniciarVar}
                         onChange= {CambiarReiniciarVar}
                         disabled = {opcion === '10'}
                       />
                   }
                  label="Reiniciar variables"
                  />
            </FormControl>
          </DialogContent>
          {error && <Alert severity="error">Error al procesar la solicitud</Alert> }
          <DialogActions>
            {cargado && <CircularProgress />}
            {cargado===false && <Button onClick={handleCloseChat} color="primary">
              Cancelar
            </Button>}
            {cargado===false && <Button onClick={EjecutarAccion} color="primary">
              Ejecutar
            </Button>}
          </DialogActions>
        </Dialog>);
}




export default SalirChatCenter;
